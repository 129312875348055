import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FlatSchedule } from 'client-shared/components/schedulegrid';
import { GridAllocations } from 'client-shared/components/schedulegrid/GridAllocations';
import {
    getColumns,
    getCellValue,
    Layout,
    Scroll,
    withAllocations,
    HeaderCell
} from 'client-shared/components/schedulegrid/schedulehelpers';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { getFilter, parseDate, usePrivilege, useReportTotals, reportExcel } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import { flow, get, filter, find, sortBy, flowRight as compose } from 'lodash/fp';
import { Resource } from './Resource';
import { CellDragDrop, AllocationDragDrop, Actions } from './dragdrop';
// import { Totals } from 'client-shared/components/totals';
import { ExcelExport } from 'client-shared/components/ExcelExport';
import { useApolloClient } from '@apollo/client';
import Divider from '@mui/material/Divider';
import { JobList } from 'client-shared/components/JobList';
import { ClipBoard } from 'client-shared/components/schedulegrid/ClipBoard';
import { ToolBar } from 'client-shared/components/schedulegrid/ToolBar';
import { ToolBarActions } from 'client-shared/components/schedulegrid/ToolBarActions';
import { ToolBarDelete } from 'client-shared/components/schedulegrid/ToolBarDelete';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { withGrid } from 'client-shared/grids/withGrid';
import { withEditor } from 'client-shared/entities/withEditor';
import { isSameDay } from 'date-fns/fp';

const map = require('lodash/fp/map').convert({ cap: false });

// /**
//  * comparison function to filter allocations for the row.
//  * @param row
//  * @param columnName
//  * @param allocation
//  * @returns {unknown}
//  */
// const comparator = (row, columnName, allocation) => {
//     // check if allocation contains taskDays for columnName date.
//     return allocation.TaskDays.get(columnName);
// };

/**
 * comparison function to filter allocations for the row.
 * @param row
 * @param columnName
 * @param allocation
 * @returns {unknown}
 */
const comparator = (row, columnName, allocation) => {
    // check if allocation contains taskDays for columnName date.
    const taskDays = allocation.TaskDays.get(columnName);
    if (!taskDays) {
        return false;
    }
    // check if any taskday resource id matches the row resource id.
    return find((taskDay) => {
        return find((resource) => resource.Id === row.Id, taskDay.Resources);
    }, taskDays);
};

const sortComparator = (allocations) => (a, b) => {
    let valueA = get(a.props.columnName, a.props.row);
    let valueB = get(b.props.columnName, b.props.row);
    if (a.props.columnName !== 'DisplayName') {
        const allocationA = find((allocation) => comparator(a.props.row, a.props.columnName, allocation), allocations);
        const allocationB = find((allocation) => comparator(b.props.row, b.props.columnName, allocation), allocations);

        valueA = get('Job.DisplayName', allocationA) || ' ';
        valueB = get('Job.DisplayName', allocationB) || ' ';
    }
    if (!valueA) {
        throw new Error(`Missing sort property ${a.props.columnName}`);
    }
    if (!valueB) {
        throw new Error(`Missing sort property ${b.props.columnName}`);
    }
    if (valueA === valueB) {
        return 0;
    }
    return valueA < valueB ? -1 : 1;
};

/**
 * get the rows to display in the grid.
 * @param cxResources
 * @returns {*}
 */
const getRows = (cxResources) => {
    return sortBy('DisplayName', cxResources);
};

/**
 * get the columns to display in the grid.
 * @param values
 * @returns {[{name: string, title: string},...*]}
 */
const getCols = (values) => {
    return [
        {
            name: 'DisplayName',
            title: 'Resource'
        },
        ...getColumns({
            values
        })
    ];
};

/**
 * get the rows to export.
 * @param values
 * @param cxJobs
 * @returns {*}
 */
const getExcelRows = (cxResources) => {
    return [{ headerRow: true }, ...cxResources];
};

/**
 * get the columns to export.
 * @param columns
 * @returns {[{name: string, title: string},...*]}
 */
const getExcelCols = (columns) => {
    // add additional column to export.
    columns = [...columns];
    columns.splice(1, 0, { title: 'Description', name: 'Description' });
    return columns;
};

/**
 * populate each cell of the spreadsheet.
 * @param values
 * @param allocations
 * @returns {(function(*, *, *, *): (*|undefined))|*}
 */
const addExcelCell = (values, allocations, reportIntervals) => {
    return ({ row, rowNumber, column, colNumber, colName, cell }) => {
        cell.alignment = { wrapText: true };
        // add total row
        if (row.headerRow) {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DCDCDC' } };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: {
                    style: 'thin'
                }
            };
            cell.value = get('title', column);
            if (isNaN(new Date(colName))) {
                return;
            }
            const reportInterval = find(
                (reportInterval) => isSameDay(parseDate(reportInterval.Range.Start), parseDate(colName)),
                reportIntervals
            );
            cell.value = {
                richText: [
                    {
                        font: { bold: true },
                        text: `${colName}\n`
                    },
                    ...reportExcel({
                        reportProperties: values._reportIntervalProperties,
                        reportObject: get('Report', reportInterval),
                        unit: { Name: 'Hrs', Scalar: 60 }
                    })
                ]
            };
            return;
        }
        if (colName === 'DisplayName') {
            if (!row._root) {
                const color = get('ForeColor', row).slice(1);
                const backColor = get('BackColor', row).slice(1);
                cell.font = { color: { argb: color } };
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
                cell.value = `    ${row.DisplayName}`;
            } else {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DCDCDC' } };
                cell.border = {
                    top: { style: 'medium' },
                    left: { style: 'medium' },
                    bottom: { style: 'medium' },
                    right: { style: 'medium' }
                };
                cell.value = row.DisplayName;
            }
            return;
        }
        if (colName === 'Description') {
            return (cell.value = row.Description);
        }
        if (row._root) {
            return;
        }

        flow(
            filter((allocation) => comparator(row, colName, allocation)),
            map((allocation) => {
                return {
                    font: { color: { argb: get('ForeColor', allocation.Job).slice(1) } },
                    text: `${allocation.Job.DisplayName}:${allocation.JobActivity.DisplayName}\n`
                };
            }),
            (richText) => {
                if (!richText.length) {
                    return;
                }
                //cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: get('BackColor', allocation.Job).slice(1)  } };
                cell.border = {
                    top: { style: 'medium' },
                    left: { style: 'medium' },
                    bottom: { style: 'medium' },
                    right: { style: 'medium' }
                };
                cell.value = { richText: richText };
            }
        )(allocations);
    };
};

const getTargetProperties = (row, columnName) => {
    return {
        Resource: {
            Id: row.Id
        },
        CurrentLifespan: {
            Start: columnName,
            End: columnName
        },
        Auxiliaries: row.Auxiliaries,
        ObjectType: row.ObjectType
    };
};

const TableCellComponent = ({ onOpenEditor, ...other }) => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    const targetProperties = getTargetProperties(other.row, other.column.name);
    const cellDragDrop = new CellDragDrop(client, privilege, targetProperties, onOpenEditor);
    return (
        <VirtualTable.Cell
            {...other}
            onDrop={(event) => {
                cellDragDrop.handleCellDrop(event);
            }}
            onDragOver={cellDragDrop.allowDrop}
        />
    );
};

/**
 * component to display a cell in the grid.
 * @param row - a row from the grid.
 * @param columnName - name of column
 * @param items - collection of allocations
 * @param onOpenEditor - function to open an edit form.
 * @param onOpenGrid - function to display a grid.
 * @returns {JSX.Element|undefined}
 * @constructor
 */
const Cell = ({ row, columnName, items, onOpenEditor, onOpenGrid }) => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    if (columnName === 'DisplayName') {
        return <Resource row={row} onOpenEditor={onOpenEditor} onOpenGrid={onOpenGrid} />;
    }
    const targetProperties = getTargetProperties(row, columnName);
    const cellDragDrop = new CellDragDrop(client, privilege, targetProperties, onOpenEditor);
    return (
        <div
            onDrop={(event) => {
                cellDragDrop.handleCellDrop(event);
            }}
            onDragOver={cellDragDrop.allowDrop}
        >
            <GridAllocations
                client={client}
                privilege={privilege}
                items={items}
                targetProperties={targetProperties}
                cellDragDrop={cellDragDrop}
                AllocationDragDrop={AllocationDragDrop}
                onOpenEditor={onOpenEditor}
                renderAllocationName={(item, selected) => {
                    return (
                        <span
                            style={{
                                wordWrap: 'break-word',
                                whiteSpace: 'pre',
                                color: get('Job.ForeColor', item),
                                backgroundColor: selected ? 'Gainsboro' : get('Job.BackColor', item) || '#FFFFFF'
                            }}
                        >
                            {item.DisplayName}
                        </span>
                    );
                }}
            />
        </div>
    );
};

Cell.propTypes = {
    row: PropTypes.object.isRequired,
    columnName: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    onOpenEditor: PropTypes.func.isRequired,
    onOpenGrid: PropTypes.func.isRequired
};

/**
 * component to display a header cell in the grid.
 * @param availableEmployees - true if available employees should be calculated and displayed.
 * @param column - column title and name.
 * @param items - collection of allocations.
 * @returns {JSX.Element}
 * @constructor
 */
// const HeaderCell = ({ values, column, items, reportIntervals }) => {
//     const allocations = filter((allocation) => allocation.TaskDays.get(column.name), items);
//     const [selected, toggleSelected] = useSelected(allocations, SCHEDULECACHE);
//     const reportInterval = find(
//         (reportInterval) => isSameDay(parseDate(reportInterval.Range.Start), parseDate(column.name)),
//         reportIntervals
//     );
//     if (isNaN(new Date(column.name))) {
//         return (
//             <Typography fontSize="small" component="div">
//                 {column.title}
//             </Typography>
//         );
//     }
//
//     return (
//         <Typography
//             fontSize="small"
//             sx={{ backgroundColor: selected ? 'Gainsboro' : 'transparent' }}
//             onClick={() =>
//                 toggleSelected(allocations, {
//                     CurrentLifespan: {
//                         Start: column.name
//                     }
//                 })
//             }
//             component="div"
//         >
//             {column.title}
//             <Report
//                 reportProperties={values._reportIntervalProperties}
//                 reportObject={get('Report', reportInterval)}
//                 label={true}
//                 unit={{ Name: 'Hrs', Scalar: 60 }}
//                 style={{ fontSize: '10px' }}
//             />
//         </Typography>
//     );
// };

HeaderCell.propTypes = {
    values: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    reportIntervals: PropTypes.array
};

const Grid_ = ({
    values,
    onSubmit,
    excelRows,
    excelColumns,
    filterFormDefinition,
    rows,
    columns,
    allocations,
    onOpenEditor,
    onOpenGrid
}) => {
    const sortColumnNames = map((column) => column.name, columns);
    const [reportIntervals] = useReportTotals({
        reportProperties: values._reportIntervalProperties,
        periods: values._periods,
        start: values._start,
        stratify: false,
        interval: 'Days',
        values
    });
    return (
        <React.Fragment>
            <Scroll values={values} onSubmit={onSubmit} />
            <ExcelExport
                rows={excelRows}
                getColumns={() => excelColumns}
                addExcelCell={addExcelCell(values, allocations, reportIntervals)}
            />
            <FlatSchedule
                title={filterFormDefinition.title}
                rows={rows}
                columns={columns}
                getRowId={(row) => {
                    return row.Id;
                }}
                getCellValue={getCellValue({
                    items: allocations,
                    comparator: comparator,
                    render: ({ row, columnName, items }) => {
                        return (
                            <Cell
                                row={row}
                                columnName={columnName}
                                items={items}
                                rowSummary={values._rowSummary}
                                onOpenEditor={onOpenEditor}
                                onOpenGrid={onOpenGrid}
                            />
                        );
                    }
                })}
                tableHeaderCell={(props) => {
                    return (
                        <HeaderCell
                            values={values}
                            column={props.column}
                            items={allocations}
                            reportIntervals={reportIntervals}
                        />
                    );
                }}
                tableCellComponent={(props) => <TableCellComponent onOpenEditor={onOpenEditor} {...props} />}
                fixedColumnNames={['DisplayName']}
                sortColumnNames={sortColumnNames}
                sortComparator={sortComparator(allocations)}
            />
        </React.Fragment>
    );
};

const Grid = compose([withGrid, withEditor, withAllocations])(Grid_);

const Schedule_ = () => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.pages', 'resourceschedulesheet.js');
    const handleFormatters = (values, client) => {
        return {
            formatters: [
                { propertyName: 'employeetaskdayformat', format: 'config.resourceschedule.employeetaskdayformat' },
                { propertyName: 'equipmenttaskdayformat', format: 'config.resourceschedule.equipmenttaskdayformat' },
                { propertyName: 'materialtaskdayformat', format: 'config.resourceschedule.materialtaskdayformat' },
                { propertyName: 'groupallocformat', format: 'config.crewschedule.crewallocformat' },
                { propertyName: 'crewallocformat', format: 'config.crewschedule.crewallocformat' },
                { propertyName: 'generictaskdayformat', format: 'config.crewschedule.generictaskdayformat' }
            ]
        };
    };
    return (
        <div>
            <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
                {({ values, data, onSubmit }) => {
                    const rows = getRows(data);
                    const columns = getCols(values);
                    const excelRows = getExcelRows(rows);
                    const excelColumns = getExcelCols(columns);
                    // filters allocations
                    values._resourceDivisionIds = map((division_id) => division_id.Id, get('divisions_id', values));
                    return (
                        <Grid
                            values={values}
                            onSubmit={onSubmit}
                            excelRows={excelRows}
                            excelColumns={excelColumns}
                            filterFormDefinition={filterFormDefinition}
                            rows={rows}
                            columns={columns}
                            onFormatters={handleFormatters}
                        />
                    );
                }}
            </FilterFormFromJson>
        </div>
    );
};

const Tools = () => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    const actions = new Actions(client, privilege);
    return (
        <ToolBar>
            <div>
                <ToolBarDelete />
            </div>
            <ToolBarActions actions={actions} />
            <JobList />
            <Divider />
            <ClipBoard />
        </ToolBar>
    );
};

export const ResourceScheduleSheet = () => <Layout Schedule={Schedule_} ToolBar={Tools} />;
