import React from 'react';
import PropTypes from 'prop-types';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { TextField, Button } from '@mui/material';
import { find, get, last, map, split } from 'lodash/fp';
import Checkbox from '@mui/material/Checkbox';
import anchorme from 'anchorme';

const components = {
    SpanFormatter: ({ name, title, getCellValue, row, value, component, style }) => {
        return <span {...style}>{value}</span>;
    },
    TextFormatter: ({ name, title, getCellValue, row, value, component, ...other }) => {
        return <TextField {...other} disabled InputProps={{ readOnly: true }} defaultValue={value} />;
    },
    DateFormatter: ({ name, title, getCellValue, row, value, component, ...other }) => {
        return <span>{value}</span>;
    },
    TypographyFormatter: ({ name, title, getCellValue, row, value, component, ...other }) => {
        return <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: value }}></div>;
    },
    ArrayFormatter: ({ name, title, getCellValue, row, value, component, ...other }) => {
        return <div style={{ whiteSpace: 'pre-line' }}>{value}</div>;
    },
    TagFormatter: ({ name, title, getCellValue, row, value, component, ...other }) => {
        const tag = find((each) => last(split('.', each.Name)) === title, value);
        return <div style={{ whiteSpace: 'pre-line' }}>{get('DisplayValue', tag)}</div>;
    },
    CurrencyFormatter: ({ name, title, getCellValue, row, value, component, maximumFractionDigits = 2, ...other }) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: maximumFractionDigits
        });
        return <span style={{ whiteSpace: 'pre-line' }}>{formatter.format(value)}</span>;
    },
    NumberFormatter: ({ name, title, getCellValue, row, value, component, maximumFractionDigits = 2, ...other }) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal', // Use the decimal style
            //minimumFractionDigits: 2, // Specify the minimum number of decimal places
            maximumFractionDigits: 2 // Specify the maximum number of decimal places
        });
        return <span style={{ whiteSpace: 'pre-line' }}>{formatter.format(value)}</span>;
    },
    BooleanFormatter: ({ name, title, getCellValue, row, value, component, ...other }) => (
        <Checkbox
            checked={value}
            inputProps={{
                readOnly: true
            }}
            style={{ padding: 0 }}
        />
    ),
    DangerousFormatter: ({ name, title, getCellValue, row, value, component, ...other }) => {
        const text = anchorme(value, { attributes: [{ name: 'target', value: 'blank' }] });
        return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
    },
    CustomFormatter: ({ row, column, value, formatterFunction }) => {
        if (formatterFunction) {
            return (
                <span style={{ whiteSpace: 'pre-line' }}>
                    {formatterFunction(get(column.name, row) || value, row, column)}
                </span>
            );
        }
        return <span>{value}</span>;
    },
    ButtonFormatter: ({
        name,
        title,
        getCellValue,
        row,
        value,
        component,
        text,
        onClick,
        onOpenGrid,
        onOpenEditor,
        ...other
    }) => {
        return (
            <Button
                sx={{
                    padding: 0,
                    margin: 0,
                    fontSize: '.75rem'
                }}
                onClick={(event) => {
                    event.stopPropagation();
                    onClick(value, row, undefined, onOpenGrid, onOpenEditor);
                }}
                size="small"
            >
                {text}
            </Button>
        );
    },
    SmsFormatter: ({ name, row, toggleChat, user }) => {
        const userNumbers = map(get('NumericGroupNumber'), get('congistics.user.Party.PartyGroups', user));
        let partyNumber;
        const toInfo = {};
        if (name === 'From.NumericPhoneNumber') {
            partyNumber = find((each) => each === get('To.NumericPhoneNumber', row), userNumbers);
            toInfo.number = get('From.NumericPhoneNumber', row);
            toInfo.label = `${get('From.NumericPhoneNumber', row)} ${get('Sender.DisplayName', row) || ''}`;
        }
        if (name === 'To.NumericPhoneNumber') {
            partyNumber = find((each) => each === get('From.NumericPhoneNumber', row), userNumbers);
            toInfo.number = get('To.NumericPhoneNumber', row);
            toInfo.label = `${get('To.NumericPhoneNumber', row)} ${get('Recipient.DisplayName', row) || ''}`;
        }
        if (partyNumber)
            return (
                <Button
                    sx={{
                        padding: 0,
                        margin: 0,
                        fontSize: '.75rem'
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        return toggleChat(true, partyNumber, toInfo)(e);
                    }}
                    size="small"
                >
                    {toInfo.label}
                </Button>
            );
        return toInfo.label;
    }
};

const _formatter = ({ column, row, value, component, onOpenGrid, onOpenEditor, ...rest }) => {
    return component({ ...column, row, column, value, component, onOpenGrid, onOpenEditor, ...rest });
};

const Formatter = ({ column, row, value, onOpenGrid, onOpenEditor, toggleChat, user }) => {
    const { formatter = _formatter, formatterType, ...otherColumn } = column;
    const component = get(formatterType, components);
    if (!component) {
        throw new Error(`The grid column formatterType ${formatterType} is invalid`);
    }
    return formatter({ column: otherColumn, row, value, component, onOpenGrid, onOpenEditor, toggleChat, user });
};

export const TextFormatterProvider = ({ columns, onOpenGrid, onOpenEditor, toggleChat, user }) => {
    // const columnNames = filter(columns, {formatterType: FieldTypes.TEXT }).map((column) => column.name);
    const columnNames = columns.filter((column) => column.formatterType).map((column) => column.name);
    return (
        <DataTypeProvider
            formatterComponent={(props) => Formatter({ ...props, onOpenGrid, onOpenEditor, toggleChat, user })}
            for={columnNames}
        />
    );
};

TextFormatterProvider.propTypes = {
    columns: PropTypes.array.isRequired,
    onOpenGrid: PropTypes.func.isRequired,
    onOpenEditor: PropTypes.func.isRequired
};
