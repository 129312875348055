import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FilterFormFromJson } from './FilterFormFromJson';
import { CrudGrid } from 'client-shared/components/crudgrid';
import { get, values as valuesFp } from 'lodash/fp';
import { UserContext } from 'client-shared/utility/context';

export const FilterFormGridFromJson = ({ filterFormGridDefinition }) => {
    const [user] = useContext(UserContext);
    const {
        columns,
        editForm,
        bulkEditForm,
        enableNew,
        enableDeletion = user.userId === 'queequeg@congistics.com' ? true : false,
        columnExtensions,
        actionButtons,
        totalItems = [],
        getSelectedRowId,
        rowStyle,
        estimatedRowHeight,
        defaultSorting,
        freezeLeftColumns,
        wrapColumnHeaders,
        refresh = true,
        onColumns = (data, values, columns) => columns,
        onTotalItems = (data, values, columns, totalItems) => totalItems,
        filterFormDefinition
    } = filterFormGridDefinition;
    return (
        <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
            {({ values, data, rawData, onRefresh }) => {
                let _columns = onColumns(get('0', valuesFp(rawData)), values, columns, rawData);
                let _totalItems = onTotalItems(get('0', valuesFp(rawData)), values, columns, totalItems, rawData);
                return (
                    <CrudGrid
                        title={filterFormDefinition.title}
                        columns={_columns}
                        rows={data}
                        values={values}
                        editForm={editForm}
                        bulkEditForm={bulkEditForm}
                        enableNew={enableNew}
                        enableDeletion={enableDeletion}
                        columnExtensions={columnExtensions}
                        actionButtons={actionButtons}
                        totalItems={_totalItems}
                        estimatedRowHeight={estimatedRowHeight}
                        defaultSorting={defaultSorting}
                        freezeLeftColumns={freezeLeftColumns}
                        wrapColumnHeaders={wrapColumnHeaders}
                        getSelectedRowId={getSelectedRowId}
                        onRefresh={refresh ? onRefresh : () => null}
                        rowStyle={rowStyle}
                    />
                );
            }}
        </FilterFormFromJson>
    );
};

FilterFormGridFromJson.propTypes = {
    filterFormGridDefinition: PropTypes.shape({
        columns: PropTypes.array.isRequired,
        editForm: PropTypes.string,
        bulkEditForm: PropTypes.string,
        enableNew: PropTypes.bool,
        enableDeletion: PropTypes.bool,
        columnExtensions: PropTypes.array,
        actionButtons: PropTypes.array,
        totalItems: PropTypes.array,
        getSelectedRowId: PropTypes.func,
        rowStyle: PropTypes.func,
        defaultSorting: PropTypes.array,
        freezeLeftColumns: PropTypes.array,
        wrapColumnHeaders: PropTypes.bool,
        refresh: PropTypes.bool,
        onColumns: PropTypes.func,
        onTotalItems: PropTypes.func,
        filterFormDefinition: PropTypes.object.isRequired
    }).isRequired
};
