import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { createFilters, instantiateBuild } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import { jobEvent as dataEventQuery } from 'client-shared/gqlhelpers/dataEventQueries';
import { gql } from '@apollo/client';
const { fragments } = require('client-shared/utility/fragments');

function build(props) {
    const { format, object, number, date, get, user } = props;
    const validationSchema = object({
        _start: date().required('Required'),
        _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(365, 'Must be less than 365 days')
    });

    const dataQuery = gql`
        ${fragments.CxJobFragment}
        query ($filters: [FilterInput], $methodsByItem: [methodsByItemInput]) {
            cxJobs(filters: $filters, methodsByItem: $methodsByItem) {
                ...CxJobFragment
            }
        }
    `;

    return {
        title: 'Daily Job Schedule',
        dataQuery,
        validationSchema,
        divisionFilter: true,
        dataEventQueries: [dataEventQuery],
        initialValues: {
            _start: format('yyyy-MM-dd', new Date()),
            _periods: 1,
            _x_unit: 'minute',
            range: [],
            _barColor: 'ActionStatus'
        },
        onFilter: (values) => {
            if (!values._barColor) values._barColor = 'ActionStatus.BackColor';
            return createFilters(values);
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Name',
                name: 'string__displayName',
                component: 'TextField',
                className: 'shortTextField'
            },
            {
                type: 'text',
                name: 'scheduled',
                component: 'ScheduledEditor',
                initialValue: 'All'
            },
            {
                type: 'text',
                label: 'Status',
                name: 'jobStatus_id',
                component: 'LookupValueEditor',
                className: 'shortSelectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobStatus',
                optionIdProperty: 'Id'
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'time',
                label: 'Start Time',
                name: '_startTime',
                component: 'TextField',
                initialValue: '06:00'
            },
            {
                type: 'time',
                label: 'End Time',
                name: '_endTime',
                component: 'TextField',
                initialValue: '18:00'
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'string',
                label: 'Allocation Color',
                name: '_barColor',
                component: 'AutoComplete',
                className: 'shortSelectField',
                initialValue: 'ActionStatus.BackColor',
                options: [
                    { Id: '_', Name: 'Allocation' },
                    { Id: 'ActionStatus', Name: 'Action Status' },
                    { Id: 'PrincipalType', Name: 'Resource' }
                ],
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            },
            {
                type: 'number',
                label: 'Timeline Spacing',
                name: '_dy',
                component: 'AutoComplete',
                className: 'shortSelectField',
                initialValue: 36,
                options: [
                    { Id: 46, Name: 'Big' },
                    { Id: 36, Name: 'Comfortable' },
                    { Id: 26, Name: 'Tight' }
                ],
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            },
            {
                type: 'number',
                label: 'Column Width (scrolled)',
                name: '_column_width_',
                component: 'TextField',
                className: 'textField',
                initialValue: 200
            },
            {
                type: 'checkbox',
                Label: { label: 'Scroll Timeline' },
                name: '_scrollable',
                component: 'CheckboxWithLabel',
                initialValue: false
            }
        ]
    };
}

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
