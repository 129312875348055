import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Field } from 'formik';
import { array, object } from 'yup';
import differenceBy from 'lodash/fp/differenceBy';
import { TextField } from 'formik-mui';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import { AutoComplete } from './AutoComplete';

export const tagValidationSchema = array().of(
    object({
        //AttributeId: number().moreThan(0, 'Required'),
        //Value: string().required('Required')
    })
);

const CheckboxWithLabel_ = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue, touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    Label: { label },
    ...props
}) => (
    <FormControlLabel
        control={
            <Checkbox
                checked={field.value === 'true'}
                onChange={(event) => {
                    setFieldValue(field.name, event.target.checked.toString());
                }}
                color="primary"
            />
        }
        label={label}
    />
);

/**
 *
 * @param name
 * @param customProperty - CustomProperty {Id, _Name, _AttributeType}
 * @param classes
 * @returns {JSX.Element}
 * @constructor
 */
const TagField = ({ name, customProperty, classes, form }) => {
    //console.log('>>>>>>>', customProperty);
    switch (customProperty._AttributeType) {
        case 'String':
            return (
                <Field
                    type="text"
                    label={customProperty._Name}
                    name={name}
                    component={TextField}
                    className={classes.longTextField}
                    multiline
                />
            );
        case 'Double':
        case 'Int':
            return (
                <Field
                    type="number"
                    label={customProperty._Name}
                    name={name}
                    component={TextField}
                    className={classes.textField}
                    onChange={(event) => form.setFieldValue(name, event.target.value.toString())}
                />
            );
        case 'DateTime':
            return (
                <Field
                    type="datetime-local"
                    label={customProperty._Name}
                    name={name}
                    component={TextField}
                    className={classes.dateTimeField}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            );
        case 'Lookup': {
            return (
                <Field
                    type="text"
                    label={customProperty._Name}
                    optionIdProperty="Id"
                    name={name}
                    component={AutoComplete}
                    className={classes.selectField}
                    options={customProperty.CustomLookups}
                    // disableClearable
                    onChange={(event) => console.log(event)}
                />
            );
        }

        case 'MultiLookup': {
            //console.log('>>>>>>>>', name)
            return (
                <Field
                    label={customProperty._Name}
                    optionIdProperty="Id"
                    name={name}
                    multiple={true}
                    component={AutoComplete}
                    className={classes.selectField}
                    options={customProperty.CustomLookups}
                    // disableClearable
                    initialize={false}
                    //onChange={(event) => console.log(event)}
                />
            );
        }

        case 'Date':
            return (
                // <Field
                //     type="date"
                //     label={customProperty._Name}
                //     name={name}
                //     component={DateEditor}
                //     className={classes.dateTimeField}
                //     InputLabelProps={{
                //         shrink: true
                //     }}
                // />
                <Field
                    type="date"
                    label={customProperty._Name}
                    name={name}
                    component={TextField}
                    className={classes.dateTimeField}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            );
        case 'Boolean':
            return (
                <Field
                    type="checkbox"
                    Label={{ label: customProperty._Name }}
                    name={name}
                    component={CheckboxWithLabel_}
                />
            );
        default:
            return null;
    }
};

TagField.propTypes = {
    name: PropTypes.string.isRequired,
    customProperty: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    form: PropTypes.object
};

const TagFields = ({ field, form, classes, customProperties, arrayHelpers }) => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        let tags = customProperties.map((customProperty) => ({ Id: 0, CustomProperty: customProperty, Value: '' }));
        tags = differenceBy('CustomProperty.Id', tags, field.value);
        tags.forEach((tag) => {
            arrayHelpers.push({
                Id: tag.Id,
                CustomProperty: tag.CustomProperty,
                Value: '',
                ArrayValue: []
            });
        });
    }, []);
    return (
        <div style={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap' }}>
            {field.value.map((tag, index) => (
                <div key={index}>
                    <TagField
                        name={`${field.name}.${index}.${tag.CustomProperty._AttributeType === 'MultiLookup' ? 'ArrayValue' : 'Value'}`}
                        customProperty={tag.CustomProperty}
                        classes={classes}
                        form={form}
                    />
                    {/* <button type="button" onClick={() => arrayHelpers.remove(index)}>
                        -
                    </button>*/}
                </div>
            ))}
            {/* <button
                type="button"
                onClick={() => {
                    arrayHelpers.push({
                        Id: 0,
                        CustomProperty: [{ Id: 0 }],
                        Value: ''
                    });
                }}
            >
                +
            </button>*/}
        </div>
    );
};
//tag.CustomProperty._AttributeType === 'MultiLookup' ? [] : ''
TagFields.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    customProperties: PropTypes.array.isRequired,
    arrayHelpers: PropTypes.object.isRequired
};

export const TagEditor = (props) => {
    //console.log('------', props);
    const { field } = props;
    return (
        <div>
            <Typography variant="caption" component="div" gutterBottom>
                Custom Properties
            </Typography>
            <FieldArray
                name={field.name}
                render={(arrayHelpers) => {
                    return <TagFields arrayHelpers={arrayHelpers} {...props} />;
                }}
            />
        </div>
    );
};

TagEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    customProperties: PropTypes.array.isRequired
};
