import { useSubscription, useApolloClient } from '@apollo/client';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'client-shared/utility/context';

let eventId = 0;
const getEventId = () => {
    return eventId++;
};

export const SubscriptionToCache = ({ subscription, subscriptionQuery }) => {
    let [userState] = useContext(UserContext);
    if (!userState) return null;
    return <CacheSubscription subscription={subscription} subscriptionQuery={subscriptionQuery} />;
};

const CacheSubscription = ({ subscription, subscriptionQuery }) => {
    const client = useApolloClient();
    const { data /*loading, error*/ } = useSubscription(subscription);
    if (data) {
        const eventName = Object.keys(data)[0];
        const events = data[eventName].map((event) => ({ ...event, differentiator: getEventId() }));
        client.writeQuery({
            query: subscriptionQuery,
            data: {
                [eventName]: events
            }
        });
    }

    return null;
};

SubscriptionToCache.propTypes = {
    subscription: PropTypes.object.isRequired,
    subscriptionQuery: PropTypes.object.isRequired
};
