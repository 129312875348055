/* eslint-disable react-hooks/exhaustive-deps*/
import { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { get } from 'lodash/fp';
import isOnline from 'is-online';

/**
 *
 * @param dataQuery - graphql query to get collection data.
 * @param values - object to pass to graphql query.
 * @param fetchData - only fetch data if true.
 * @returns {{loading: boolean}}
 */
export const useQuery = (dataQuery, values, fetchData = true) => {
    const client = useApolloClient();
    const [data, setData] = useState({ loading: true, error: false });
    useEffect(() => {
        let active = true;
        if (!fetchData) {
            return;
        }
        setData({ loading: true });
        client
            .query({
                query: dataQuery,
                variables: values,
                fetchPolicy: 'no-cache'
            })
            .then((data) => {
                if (active) {
                    setData(data);
                }
            })
            .catch((error) => {
                if (active) {
                    setData({ loading: false, error: error });
                }
            });
        return () => {
            active = false;
        };
    }, [JSON.stringify(values), fetchData]);
    return data;
};

export const query = async (
    client,
    dataQuery,
    variables,
    cache = false,
    cachePolicy = ['network-only', 'cache-first']
) => {
    try {
        let fetchPolicy = 'no-cache';
        if (cache) {
            const online = await isOnline();
            fetchPolicy = online ? get('0', cachePolicy) : get('1', cachePolicy);
        }
        const data = await client.query({
            query: dataQuery,
            variables: variables,
            fetchPolicy: fetchPolicy
        });
        return data;
    } catch (error) {
        console.log(error);
        throw new Error(error);
    }
};
