import React from 'react';
import { Link } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { map } from 'lodash/fp'




function TopNavigationBar(props) {

    const { smartMenu } = props;

    return (
        <div>
            {/* Place the BottomNavigation component at the top of this div */}
            <BottomNavigation
                sx={{
                    width: '100%',
                    justifyContent: 'flex-start' // Align items to the left
                }}
                showLabels
            >
                {map(
                    (each) => (
                        <BottomNavigationAction
                            component={Link}
                            to={each.route}
                            label={each.name}
                            key={each.name}
                            icon={<BookmarkIcon />}
                            sx={{ color: 'rgb(25, 118, 210)' }}
                        />
                    ),
                    smartMenu
                )}
            </BottomNavigation>

            {/* Other content of your page goes here */}
        </div>
    );
}

export default TopNavigationBar;
