import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { Paper, useTheme } from '@mui/material';
import { isObject, isEmpty, isArray } from 'lodash/fp';

export const useStyles = makeStyles(
    (theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        textField: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: 225
        },
        dateTimeField: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: 250
        },
        textBoxField: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            //marginTop: 0,
            width: '99%'
        },
        selectField: {
            //marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: 225
        },
        shortTextField: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: 140
        },
        extraShortTextField: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: 75
        },
        megaShortTextField: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: 42
        },
        shortSelectField: {
            //marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: 175
        },
        longTextField: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
            width: 325
        },
        dense: {
            marginTop: 19
        },
        menu: {
            width: 200
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        button: {
            margin: theme.spacing(5),
            marginBottom: theme.spacing(5)
        },
        expansionPanelDetails: {
            flexDirection: 'column'
        },
        speedDial: {
            position: 'absolute',
            '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                bottom: theme.spacing(2),
                right: theme.spacing(2)
            }
        },
        maxWidth: {
            width: 500
        }
    }),
    // keep this following line or optimized production builds will not contain the class.
    { name: 'HookGlobalStyles', index: 2 }
);

export const _styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 225
    },
    dateTimeField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 250
    },
    textBoxField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        //marginTop: 0,
        width: '99%'
    },
    selectField: {
        //marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 225
    },
    shortTextField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 140
    },
    extraShortTextField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 75
    },
    megaShortTextField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 42
    },
    shortSelectField: {
        //marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 140
    },
    longTextField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 325
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        margin: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    expansionPanelDetails: {
        flexDirection: 'column'
    },
    speedDial: {
        position: 'absolute',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(2),
            right: theme.spacing(2)
        }
    }
});

// const getUpdatedKeys = (oldData, newData) => {
//     const data = uniq([...Object.keys(oldData), ...Object.keys(newData)]);
//     const keys = {};
//     for (const key of data) {
//         if (!isEqual(oldData[key], newData[key])) {
//             keys[key] = true;
//         }
//     }
//     return keys;
// };

const getChangedValues = (object1, object2) => {
    const objKeys1 = Object.keys(object1);
    const keys = {};

    for (const key of objKeys1) {
        const value1 = object1[key];
        const value2 = object2[key];
        // if array sizes differ, cannot compare the arrays and flag that the arrays changed.
        if (isArray(value1) && isArray(value2)) {
            if (value1.length !== value2.length) {
                keys[key] = key;
                continue;
            }
        }

        if (isObject(value1) && isObject(value2)) {
            const _keys = getChangedValues(value1, value2);
            if (!isEmpty(_keys)) {
                keys[key] = _keys;
            }
            continue;
        }
        if (value1 !== value2) {
            keys[key] = true;
        }
    }
    return keys;
};

/**
 *
 * @param Form - formik form.
 * @param validationSchema - yup schema to validate the form.
 * @param initialValues - initial values to display on the form.
 * @param data - external data passed to the form for things like lookup values, other.
 * @param onSubmit - called by formik when form is submitted
 * @param getSubmitForm - returns the formik submitForm call to the calling function. this allows an outside invocation.
 * @returns {*}
 * @constructor
 */
export const FormContainer = ({
    Form, // decided to pass form and buttons instead of using children. children need to be cloned and passed props on every keystroke.
    validationSchema,
    initialValues,
    data,
    onSubmit,
    getSubmitForm,
    ...other
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const styles = _styles(theme);
    // needed to determine what changed on an allocation. the dbserver requires evals to update an allocation.
    // let _touched = {};

    return (
        <Paper {...other}>
            <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={async (values, formikBag) => {
                    return await onSubmit(
                        values,
                        formikBag,
                        getChangedValues(values, initialValues) /*getUpdatedKeys(values, initialValues)*/
                    );
                    // return Promise.resolve(onSubmit(values, formikBag, _touched));
                }}
            >
                {(props) => {
                    if (getSubmitForm) {
                        getSubmitForm(props.submitForm, props);
                    }
                    // if (!props.isSubmitting) {
                    //     _touched = props.dirty ? { ..._touched, ...props.touched } : {};
                    // }
                    return (
                        <React.Fragment>
                            <Form
                                {...{
                                    classes,
                                    styles,
                                    ...props,
                                    data
                                }}
                            />
                            {props.isSubmitting && <LinearProgress style={{ zIndex: 10000 }} />}
                        </React.Fragment>
                    );
                }}
            </Formik>
        </Paper>
    );
};

FormContainer.propTypes = {
    Form: PropTypes.func.isRequired,
    validationSchema: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    data: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    getSubmitForm: PropTypes.func,
    propLifter: PropTypes.object
};

export const FormContainerLite = (props) => {
    const { setInitialValues, setState, propLifter, fields, state } = props;
    return (
        <Formik
            initialValues={setInitialValues ? setInitialValues(state) : state}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                setState(values);
                setSubmitting(false);
            }}
            {...props}
            render={(props) => {
                if (propLifter) propLifter.go(props);
                return (
                    <Form>
                        {fields}
                        <br />
                    </Form>
                );
            }}
        />
    );
};

FormContainerLite.propTypes = {
    setState: PropTypes.func.isRequired,
    state: PropTypes.object,
    propLifter: PropTypes.object.isRequired,
    setInitialValues: PropTypes.func,
    fields: PropTypes.array.isRequired
};
