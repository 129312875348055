/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import anchorme from 'anchorme';
import { TextField } from 'formik-mui';
import { Field, NoteEditor } from 'client-shared/components/form';
import { object, string } from 'yup';
import { withSave } from 'client-shared/components/withSave';

export const TaskDayDescriptionEditor = ({ id, objectName, Description, disabled = true }) => {
    const validationSchema = object({
        Notes: string().max(2500, 'The notes should be less than 2500 characters.')
    });
    const Form = withSave((props) => (
        <Field
            type="text"
            label="Description"
            name="Description"
            component={TextField}
            className={props.classes.textBoxField}
            multiline
            variant="outlined"
            margin="normal"
            disabled={disabled}
        />
    ));
    return (
        <Form
            id={id}
            objectName={objectName}
            inputModel={{ Description: '' }}
            initialValues={{ Description }}
            validationSchema={validationSchema}
        />
    );
};

TaskDayDescriptionEditor.propTypes = {
    id: PropTypes.string.isRequired,
    objectName: PropTypes.string.isRequired,
    Description: PropTypes.string,
    disabled: PropTypes.bool
};

export const NoteMobileEditor = ({ id, objectName, CurrentNote, NoteHistory = [] }) => {
    const validationSchema = object({
        Notes: string().max(2500, 'The notes should be less than 2500 characters.')
    });
    const Form = withSave((props) => (
        <Field
            disabled={false}
            type="text"
            label="Notes"
            name="CurrentNote"
            component={NoteEditor}
            classes={props.classes}
        />
    ));
    return (
        <Form
            id={id}
            objectName={objectName}
            inputModel={{ CurrentNote: '' }}
            initialValues={{ CurrentNote, NoteHistory }}
            validationSchema={validationSchema}
        />
    );
};

NoteMobileEditor.propTypes = {
    id: PropTypes.string.isRequired,
    objectName: PropTypes.string.isRequired,
    CurrentNote: PropTypes.string,
    NoteHistory: PropTypes.array.isRequired
};

export const Description = ({ description, ...other }) => {
    if (!description) {
        return null;
    }
    const text = anchorme(description, { attributes: [{ name: 'target', value: 'blank' }] });
    return (
        <div style={{ whiteSpace: 'pre-wrap' }}>
            <Typography component="span" variant="body2" style={{ fontSize: 'small', fontStyle: 'italic' }}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </Typography>
        </div>
    );
};

Description.propTypes = {
    description: PropTypes.string.isRequired
};
