import {
    parseISO,
    format,
    isDate,
    addDays,
    differenceInHours,
    eachDayOfInterval,
    eachWeekOfIntervalWithOptions,
    eachMonthOfInterval,
    eachYearOfInterval,
    startOfWeekWithOptions,
    endOfWeekWithOptions,
    startOfMonth,
    endOfMonth,
    startOfYear,
    endOfYear,
    add,
    getDay,
    differenceInDays,
    compareAsc
} from 'date-fns/fp';
import { map, filter, pipe, split } from 'lodash/fp';

export const Intervals = {
    YEARS: 'years',
    MONTHS: 'months',
    WEEKS: 'weeks',
    DAYS: 'days',
    MINUTES: 'minutes'
};

export const DATE_FORMAT = 'yyyy-MM-dd';

export const serverDate = (date = new Date()) => format(DATE_FORMAT, date);

// return default date if date is invalid.
export const parseDate = (date) => {
    if (isDate(date)) {
        return date;
    }
    if (!date) {
        return new Date();
    }
    const isoDate = parseISO(date);
    if (isoDate instanceof Date && !isNaN(isoDate)) {
        return isoDate;
    }
    return new Date();
};

/**
 * Takes something that looks like a date and adds 1
 * @param number
 * @param date Something that will parse as a date
 */
export const plusDays = (number, date) => {
    let _date = null;
    if (!isDate(date)) _date = parseISO(date);
    if ((!_date) instanceof Date || isNaN(_date)) {
        throw new Error('This is probably way wrong');
    }
    return addDays(number, _date);
};

/**
 * Take a real date and truncate to midnight
 * @param _in
 */
export const formatMidnight = format("yyyy-MM-dd'T'00:00:00");

export const formatStandard = pipe(parseISO, format('MM/dd/yy,  hh:mm a'));

/**
 *  * Get a a range for one 1 graphql queries
 * @param date, either a date ot parsable string
 * @returns {{Start, End}}
 */
export const getSingleDateRange = (date) => {
    const Start = formatMidnight(plusDays(0, date));
    const End = formatMidnight(plusDays(1, date));
    return {
        Start,
        End
    };
};

// return a date that does not span more than 24 hours from the start date and is not less than the start date.
export const get24HourEndDate = (start, end) => {
    let startDateTime = parseDate(start);
    let endDateTime = parseDate(end);
    // let duration = endDateTime.diff(startDateTime, 'minutes') / 60;
    let duration = differenceInHours(startDateTime, endDateTime);
    if (Math.abs(duration) > 24) {
        endDateTime = parseDate(`${format('yyyy-MM-dd', startDateTime)}T${format('HH:mm', endDateTime)}`);
    }
    duration = differenceInHours(startDateTime, endDateTime);
    if (duration < 0) {
        endDateTime = addDays(1, endDateTime);
    }
    return endDateTime;
};

/**
 * return the first and last day of the range.
 * example: if the start is the middle of a month, interval is 'month', the periods is 1, the first and last day of the month
 * will be returned. if the start is the middle of a week, the interval is 'week', the periods is 1, and
 * weeks start on monday (1), the dates for monday and sunday will be returned.
 * @param start - start date of range.
 * @param periods - number of periods (e.g. days, weeks, months, other).
 * @param options - {
 *   interval - date-fns interval (e.g. days, weeks, months, years)
 *   weekStartsOn - applies only to interval weeks, 0-sunday...6-saturday
 *   periodMultiple - set the periods to the next multiple of periodMultiple, useful for calculating say, a
 *   7 day period when we stratify 7 periods on each row of a grid.
 * }
 * @returns {{start: string, end: string}}
 */
export const getRangeStartEnd = (start, periods, options = {}) => {
    const { interval = Intervals.DAYS, weekStartsOn = 1, periodMultiple = 1, absoluteStart = false } = options;
    // adjust the period to the periodMultiple, if 1, no change to the periods.
    periods = periodMultiple * Math.ceil(periods / periodMultiple);
    let end = add({ [interval.toLowerCase()]: periods - 1 }, parseDate(start));
    switch (interval.toLowerCase()) {
        case Intervals.YEARS:
            start = absoluteStart ? parseDate(start) : startOfYear(parseDate(start));
            end = endOfYear(parseDate(end));
            break;
        case Intervals.MONTHS:
            start = absoluteStart ? parseDate(start) : startOfMonth(parseDate(start));
            end = endOfMonth(parseDate(end));
            break;
        case Intervals.WEEKS:
            start = absoluteStart
                ? parseDate(start)
                : startOfWeekWithOptions({ weekStartsOn: weekStartsOn }, parseDate(start));
            end = endOfWeekWithOptions({ weekStartsOn: weekStartsOn }, parseDate(end));
            break;
        case Intervals.DAYS:
        default:
            break;
    }
    return {
        start: format('yyyy-MM-dd', parseDate(start)),
        end: format('yyyy-MM-dd', parseDate(end))
    };
};

/**
 * return a range of dates as an array.
 * example: if the start is 2021-01-05, interval is 'month', and the periods is 2, the first day of each
 * month will be return for a total of 2 months (e.g. [2021-01-01, 2021-02-01]).
 . if the start is 2022-04-19, the interval is 'week', the periods is 2, and
 * weeksStartOn is 1 (monday), then two weeks will be returned each starting on monday (e.g. [2022-04-18, 2022-04-25]).
 * @param start - start date of range.
 * @param periods - number of periods (e.g. days, weeks, months, other).
 * @param options - {
 *   interval - date-fns interval (e.g. days, weeks, months, year)
 *   skipDays - days of week to exclude - 0-sunday...6-saturday. only applies to interval days.
 *   weekStartsOn - applies only to interval weeks, 0-sunday...6-saturday
 *   periodMultiple - set the periods to the next multiple of periodMultiple, useful for calculating say, a
 *   7 day period when we stratify 7 periods on each row of a grid.
 *   format - format of returned date
 *   absoluteStart - use the passed start as the first range date for years, months, weeks,
 *   otherwise, adjust the date to the beginning of the interval.
 * }
 * @returns {string[]|*[]}
 */
export const getRange = (start, periods, options = {}) => {
    const {
        interval = Intervals.DAYS,
        skipDays = [],
        weekStartsOn = 1,
        periodMultiple,
        format: dateFormat = 'yyyy-MM-dd',
        absoluteStart = false
    } = options;
    if (!periods) {
        return [];
    }
    const startEnd = getRangeStartEnd(start, periods, { interval, weekStartsOn, periodMultiple });
    let range = [];

    switch (interval.toLowerCase()) {
        case Intervals.YEARS:
            range = eachYearOfInterval({
                start: parseDate(startEnd.start),
                end: parseDate(startEnd.end)
            });
            if (range.length && absoluteStart) {
                range[0] = parseDate(start);
            }
            break;
        case Intervals.MONTHS:
            range = eachMonthOfInterval({
                start: parseDate(startEnd.start),
                end: parseDate(startEnd.end)
            });
            if (range.length && absoluteStart) {
                range[0] = parseDate(start);
            }
            break;
        case Intervals.WEEKS:
            range = eachWeekOfIntervalWithOptions(
                { weekStartsOn: weekStartsOn },
                {
                    start: parseDate(startEnd.start),
                    end: parseDate(startEnd.end)
                }
            );
            if (range.length && absoluteStart) {
                range[0] = parseDate(start);
            }
            break;
        case Intervals.DAYS:
        default:
            range = eachDayOfInterval({
                start: parseDate(startEnd.start),
                end: parseDate(startEnd.end)
            });
            range = filter((date) => !skipDays.includes(getDay(parseDate(date))), range);
            break;
    }
    return map((date) => format(dateFormat, date), range);
};

/**
 * adjust the date to the first day of the interval. return null if the date should be skipped.
 * example: if the date is 2021-01-20 and the interval is months, then 2021-01-01 is returned.
 * @param date - date to adjust.
 * @param options - {
 *   interval - date-fns interval (e.g. days, weeks, months, other)
 *   skipDays - days of week to exclude - 0-sunday...6-saturday. only applies to interval days.
 *   weekStartsOn - applies only to interval weeks, 0-sunday...6-saturday
 *   format - format of returned date
 *   floor - if the returned date is less than the floor date use the floor date.
 * }
 * @returns {string|null}
 */
export const adjustToInterval = (date, options = {}) => {
    const {
        interval = Intervals.DAYS,
        skipDays = [],
        weekStartsOn = 1,
        format: dateFormat = 'yyyy-MM-dd',
        floor
    } = options;
    if (skipDays.includes(getDay(parseDate(date)))) {
        return null;
    }
    const adjustedDate = getRange(date, 1, { interval, skipDays, weekStartsOn, dateFormat })[0];
    if (floor) {
        return compareAsc(parseDate(floor), parseDate(adjustedDate)) < 0
            ? format(dateFormat, parseDate(floor))
            : adjustedDate;
    }
    return adjustedDate;
};

/**
 * return the number of days in the range not counting skipDays.
 * @param start
 * @param periods - number of intervals
 * @param options - {
 *   interval - date-fns interval (e.g. days, weeks, months, other)
 *   skipDays - days of week to exclude - 0-sunday...6-saturday. only applies to interval days.
 *   weekStartsOn - applies only to interval weeks, 0-sunday...6-saturday
 *   format - format of returned date
 *   absoluteStart - use the start date passed without adjusting to the first day of the interval. useful for
 *   calculating remaining days in the interval.
 * }
 * @returns {number}
 */
export const daysInRange = (start, periods, options = {}) => {
    const {
        interval = Intervals.DAYS,
        skipDays = [],
        weekStartsOn = 1,
        // format: dateFormat = 'yyyy-MM-dd',
        absoluteStart = false
    } = options;
    let { start: _start, end } = getRangeStartEnd(start, periods, { interval, weekStartsOn });
    if (absoluteStart) {
        _start = start;
    }
    const days = differenceInDays(parseDate(_start), parseDate(end)) + 1;
    const range = getRange(_start, days, { interval: Intervals.DAYS, skipDays, weekStartsOn });
    return range.length;
};

/**
 * return the number of periods (e.g. days, weeks, ...) between two dates
 * @param start
 * @param end
 * @param options - {
 *   interval - date-fns interval (e.g. days, weeks, months, other)
 *   skipDays - days of week to exclude - 0-sunday...6-saturday. only applies to interval days.
 *   weekStartsOn - applies only to interval weeks, 0-sunday...6-saturday
 * @returns {string[]}
 */
export const getPeriods = (start, end, options = {}) => {
    const { interval = Intervals.DAYS, skipDays = [], weekStartsOn = 1 } = options;
    let range = [];
    switch (interval.toLowerCase()) {
        case Intervals.YEARS:
            range = eachYearOfInterval({
                start: parseDate(start),
                end: parseDate(end)
            });
            break;
        case Intervals.MONTHS:
            range = eachMonthOfInterval({
                start: parseDate(start),
                end: parseDate(end)
            });
            break;
        case Intervals.WEEKS:
            range = eachWeekOfIntervalWithOptions(
                { weekStartsOn: weekStartsOn },
                {
                    start: parseDate(start),
                    end: parseDate(end)
                }
            );

            break;
        case Intervals.DAYS:
        default:
            range = eachDayOfInterval({
                start: parseDate(start),
                end: parseDate(end)
            });
            range = filter((date) => !skipDays.includes(getDay(parseDate(date))), range);
            break;
    }
    return range.length;
};

/**
 * Returns a date at a certain time for a day
 * @param day
 * @param when 24 hour representation of hours:minutes, eg 15:30
 */
export const todayAt = (day, when) => {
    const date = parseDate(day);
    const [hours, min] = split(':', when);
    date.setHours(hours, min);
    return date;
};
