/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/fp/get';

export const ResourceName = ({ resource, ...other }) => {
    if (!resource) {
        return null;
    }
    return (
        <span style={{ color: get('ForeColor', resource), backgroundColor: get('BackColor', resource) }} {...other}>
            {resource.DisplayName}
        </span>
    );
};

ResourceName.propTypes = {
    resource: PropTypes.object
};
