import dot from 'dot-object';
// import deepFilter from 'deep-filter';
import { transform, isObject, isEmpty, isArray } from 'lodash';
import { map, keys, flow, curry } from 'lodash/fp';

/**
 * transform the properties of an object into an array of properties to be used as input to
 * the keepProperties() function.
 * @param object containing the properties to transform
 * @returns {*[]}
 */
export const getProperties = (object) => {
    // remove brackets contained in the string.
    const regexpBrackets = /\[.*?\]/g;
    return flow(
        keys,
        map((item) => item.replace(regexpBrackets, ''))
    )(dot.dot(object));
};
/**
 * deep clean an object, keeping only the specified properties, including collections of collections of objects.
 * @param object - object to be cleaned.
 * @param properties - array of property paths to keep. ['sample.property.name', 'sample.property.name2', ...]
 * @param parentKeys - do NOT supply a value, only used by keepProperties recursion.
 * @returns {*}
 */
export const keepProperties = (object, properties, parentKeys = []) => {
    const o = transform(object, (result, val, key) => {
        let keys = [];
        keys = [...parentKeys]; // make a copy.
        if (isNaN(key)) {
            keys.push(key);
        }
        if (isObject(val)) {
            const v = keepProperties(val, properties, keys);
            if (Array.isArray(v) && v.length === 0) {
                // special handling for empty arrays when the property list includes the Id property.
                if (properties.includes(keys.join('.')) || properties.includes(`${keys.join('.')}.Id`)) {
                    result[key] = val;
                }
                return;
            }
            if (Object.keys(v).length === 0 && v.constructor === Object) {
                return;
            }
            result[key] = v;
        } else {
            //console.log('keys', keys.join('.'))
            if (properties.includes(keys.join('.'))) {
                result[key] = val;
            }
        }
        if (isNaN(key)) {
            keys.pop();
        }
    });
    return o;
};

/**
 * keep ONLY properties of the object that are in the template.
 * @param object - object with properties to be removed.
 * @param template - template object that is used as source of properties to keep.
 * @returns {*}
 */
export const pickFromTemplate = (object, template) => {
    return keepProperties(object, getProperties(template));
};

export const pickFromTemplateFp = curry((template, object) => {
    return pickFromTemplate(object, template);
});

/**
 * remove empty properties and properties names that beging with '_'.
 * @param object
 * @returns {unknown}
 */
export const omitEmptyProperties = (object) =>
    transform(object, (result, val, key) => {
        if (typeof key === 'string' && (val === '' || val === undefined || key.charAt(0) === '_')) {
            return;
        }
        if (Array.isArray(val) && val.length === 0) {
            return;
        }
        if (isObject(val) && !isArray(val)) {
            if (isEmpty(val)) {
                return;
            }
        }
        const v = isObject(val) ? omitEmptyProperties(val) : val;
        if (v === {}) {
            return;
        }
        result[key] = v;
    });

/**
 * Removes all '_" and null properties from an object
 * @param object
 * @returns {*|*|*}
 */
export const cleanObject = (object) =>
    transform(object, (result, val, key) => {
        if (val === null) {
            return;
        }
        if (typeof key === 'string' && key.charAt(0) === '_') {
            return;
        }
        const v = isObject(val) ? cleanObject(val) : val;
        result[key] = v;
    });
// export const cleanObject = (object) =>
//     omitEmptyProperties(object)
// deepFilter(object, (value, prop, subject) => {
//     if (value === null /*|| isEmpty(value)*/) {
//         return false;
//     }
//     // if (prop === 'ObjectType') {
//     //     return false;
//     // }
//     if (typeof prop === 'string') {
//         return prop.charAt(0) !== '_';
//     }
//     return true;
// });
