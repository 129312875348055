import React, { useState, useContext, useEffect } from 'react';
import { filterFieldsFromJson, initialValuesFromJson } from './filterformhelpers';
import { get, map, split, flow, filter, sortBy, replace, toLower } from 'lodash/fp';
import { UserContext, usePersistFilter, getKkv, getConfigValue } from 'client-shared/utility';
import { FilterQuery } from './FilterQuery';
import { FilterSearch } from './FilterSearch';
import { ErrorBoundary } from './ErrorBoundary';
import { useApolloClient } from '@apollo/client';
import { TextField, Autocomplete, InputAdornment, IconButton, Stack } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';

const getTag = (filterName) =>
    flow(split('#'), (values) => {
        return get('1', values) || '';
    })(filterName);

const NamedFilters = ({ filterName, sx, onFilter, onDeleteFilter }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const [options, setOptions] = useState([]);
    const baseName = flow(split('#'), get('0'))(filterName);
    const [tag, setTag] = useState(getTag(filterName));
    /* eslint-disable  react-hooks/exhaustive-deps */
    useEffect(() => {
        (async () => {
            const { kkvFetch: kkvFetch1 } = await getKkv(client, get('userId', user), `${baseName}%`);
            const { kkvFetch: kkvFetch2 } = await getKkv(client, '*', `${baseName}%`);
            const options = flow(
                map((filter) => getTag(filter.kk)),
                filter((option) => option),
                sortBy([])
            )([...kkvFetch1, ...kkvFetch2]);
            setOptions(options);
            setTag(getTag(filterName));
        })();
    }, [filterName]);

    const handleFilter = (newValue) => {
        const filterName = !newValue || newValue === baseName ? baseName : `${baseName}#${newValue}`;
        return onFilter(filterName);
    };

    const handleDeleteFilter = () => {
        onDeleteFilter();
        onFilter(baseName);
    };
    const toolTip = `Enter a name for your filter and click Apply in the Filter Bar to save a filter or
    select a previously saved filter from the drop down list`;
    return (
        <Stack direction="row" sx={sx}>
            <Autocomplete
                sx={{ ...{ component: 'span', width: 200 } }}
                freeSolo
                value={tag}
                options={options}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            className="no-print"
                            placeholder={'Select a filter'}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={toolTip}>
                                            <FilterAltOutlinedIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    );
                }}
                onInputChange={(event, newValue) => {
                    handleFilter(newValue);
                }}
                onChange={(event, newValue) => {
                    handleFilter(newValue);
                }}
            />
            <Tooltip title="Click to delete the selected filter">
                <IconButton onClick={handleDeleteFilter}>
                    <DeleteOutlinedIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

/**
 * decorate a component with the ComponentProps below to enable filtering.
 * Example accordion component:
 * const AccordionFilter = withFilterFormFromJson(AccordionForm);
 * const AccordionForm = ({
 *     title,
 *     enableShowAll,
 *     validationSchema,
 *     Form,
 *     values,
 *     initialValues,
 *     _showAll,
 *     onSubmit,
 *     onShowAll
 * }) => {
 *     const [expanded, setExpanded] = useState(false);
 *     const WrappedForm = (props) => {
 *         const handleApply = () => {
 *             props
 *                 .submitForm()
 *                 .catch((error) => console.log('FilterForm: FormWithExpansion: props.submitform()', error));
 *         };
 *         const handleReset = () => {
 *             props.setValues(initialValues);
 *         };
 *         return (
 *             <AccordionFilterBody
 *                 enableShowAll={enableShowAll}
 *                 onApply={handleApply}
 *                 onReset={handleReset}
 *                 onShowAll={() => {
 *                     onShowAll();
 *                     setExpanded(false);
 *                 }}
 *             >
 *                 <Form {...props} />
 *             </AccordionFilterBody>
 *         );
 *     };
 *     return (
 *         <AccordionFilter onExpanded={setExpanded} title={title} expanded={expanded}>
 *             <FormContainer
 *                 Form={WrappedForm}
 *                 initialValues={values}
 *                 validationSchema={validationSchema}
 *                 onSubmit={(values, formikBag, _touched) => {
 *                     onSubmit(values);
 *                     setExpanded(false);
 *                 }}
 *                 className="no-print"
 *                 //style={styles.root}
 *             />
 *         </AccordionFilter>
 *     );
 * };
 * @param Component
 * @returns {(function(*): (null|*))|*}
 */
export const withFilterFormFromJson = (Component) => (props) => {
    const [user] = useContext(UserContext);
    const { filterFormDefinition, style, children } = props;
    const configTitle = flow(replace(/ /g, '_'), toLower)(filterFormDefinition.title);
    const config_configValues = getConfigValue(`config.filters.${configTitle}`, user);
    const {
        fieldDefinitions,
        title,
        validationSchema,
        initialValues,
        firstTimeValues,
        dataQuery,
        dataEventQueries,
        sortProperties,
        sourceMutationObjectTypes,
        filterProperties,
        filterOnServer,
        persistFilter = true,
        propertiesNotPersisted = [],
        divisionFilter = false,
        enableShowAll,
        namedFilters = false,
        cache,
        onFilter,
        onData,
        onMethodsByItem,
        onFormatters
    } = { ...filterFormDefinition, ...config_configValues };
    const _initialValues = { ...initialValues, ...initialValuesFromJson(fieldDefinitions) };
    const [firstTime, setFirstTime] = useState(true);
    const [filterName, setFilterName] = useState(title);
    const t = getTag(filterName);
    const k = t.charAt(0) === '*' ? '*' : undefined;
    const [values, setValues, , loadingValues, deleteValues] = usePersistFilter(
        persistFilter ? filterName : null,
        _initialValues,
        undefined,
        k
    );
    const [showAll, setShowAll] = useState(false);
    const [divisions] = useState(
        divisionFilter ? map((division) => division.Id, get('congistics.user.Divisions', user)) : []
    );
    const handleSubmit = (formValues, showAll = false) => {
        setValues(formValues, propertiesNotPersisted, k);
        setFirstTime(false);
        setShowAll(showAll);
    };
    const handleShowAll = () => {
        setFirstTime(false);
        setShowAll(true);
    };
    if (loadingValues) {
        return null;
    }

    const componentProps = {
        style: style,
        title: filterName,
        enableShowAll: enableShowAll,
        validationSchema: validationSchema,
        Form: filterFieldsFromJson(fieldDefinitions),
        values: values,
        initialValues: _initialValues,
        _showAll: showAll,
        onSubmit: handleSubmit,
        onShowAll: handleShowAll
    };

    return (
        <ErrorBoundary title={filterName}>
            <Component {...componentProps} {...props} />
            {namedFilters && (
                <NamedFilters
                    filterName={filterName}
                    onFilter={setFilterName}
                    onDeleteFilter={deleteValues}
                    sx={{ position: 'absolute', right: '2%' }}
                />
            )}
            <FilterQuery
                title={filterName}
                style={style}
                values={
                    firstTime && firstTimeValues
                        ? { ...firstTimeValues, _showAll: showAll }
                        : { ...values, defaultdivisionsid: divisions, _showAll: showAll }
                }
                dataQuery={dataQuery}
                dataEventQueries={dataEventQueries}
                sortProperties={sortProperties}
                sourceMutationObjectTypes={sourceMutationObjectTypes}
                cache={cache}
                onFilter={onFilter}
                onData={onData}
                onMethodsByItem={onMethodsByItem}
                onFormatters={onFormatters}
            >
                {({ values, data, setQuickFilter, onRefresh }) => (
                    <React.Fragment>
                        <FilterSearch
                            values={values}
                            filterProperties={filterProperties}
                            filterOnServer={filterOnServer}
                            data={data}
                            setQuickFilter={setQuickFilter}
                        >
                            {(filteredData) =>
                                children({
                                    data: filteredData,
                                    rawData: data.rawData,
                                    values: values, // ??the values need to be immutable for downstream manipulation.
                                    event: data.event,
                                    onSubmit: handleSubmit,
                                    onRefresh: onRefresh
                                })
                            }
                        </FilterSearch>
                    </React.Fragment>
                )}
            </FilterQuery>
        </ErrorBoundary>
    );
};
