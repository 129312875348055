import React, { useContext } from 'react';
import { Filter } from './Filter';
import { map, get, flatMap, identity, first, split, pipe, sortBy, curry, flatten, filter, keyBy } from 'lodash/fp';
import Box from '@mui/material/Box';
import Scheduler, {
    getConfig,
    spanToEvent,
    prepareTimelineDate,
    getCalendarDays
} from 'client-shared/components/timeline';
import {
    extractLocalConfigs,
    useCollectionSubscription,
    AllocationEventQuery,
    getRangeStartEnd,
    usePrivilege,
    UserContext
} from 'client-shared/utility';
import { AllocationsQuery } from './queries.js';
import { mapWithKey } from 'global-shared/utils/utils';
import { evalQuery } from 'client-shared/gqlhelpers/queryHelpers';
import { useApolloClient } from '@apollo/client';
import { withEditor } from 'client-shared/entities/withEditor';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { getShift } from 'client-shared/utility/authHelpers';

const Index = () => {
    return (
        <React.Fragment>
            <Filter>
                {({ values: variables, data: resources }) => {
                    const { end } = getRangeStartEnd(variables._start, variables._periods, {
                        interval: variables._interval
                    });
                    variables.start = variables._start;
                    variables.end = end;
                    variables._periods = getCalendarDays(variables);
                    variables._x_unit = variables._interval.toLowerCase().slice(0, -1);
                    const config = getConfig(extractLocalConfigs(variables), {
                        x_size: variables._periods,
                        y_property: 'section_id',
                        scrollable: variables._scrollable
                    });
                    return <InnerScheduler {...{ config, variables, resources }} />;
                }}
            </Filter>
        </React.Fragment>
    );
};
export default Index;

const InnerScheduler_ = (props) => {
    const { config, variables, resources, onOpenEditor } = props;
    variables.resourceIds = map(get('Id'), resources);
    const resourceMap = keyBy('Id', resources);
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const { data: allocations, event } = useCollectionSubscription({
        dataQuery: AllocationsQuery,
        eventQueries: [AllocationEventQuery],
        values: variables
    });

    const rez = flatten(flatMap(allocationToEvent(get('resourceType_family', variables)), allocations));
    /*    const CONFIG_sort_resources_by = getOr(
        false,
        ['congistics', 'configValues', 'plan.resourceTimeline.sort_resources_by'],
        user
    );*/

    //console.log('=====>', rez)

    const _config = { ...config, ...{ y_unit: pipe(sortBy(false), map(mapTop))(resources) } };
    const [privilege] = usePrivilege('Allocation');

    function editCallback(e, more) {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: [first(split('_', e))] }]
            }
        });
    }

    const openEditor = (e) => {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    };

    const schedulerEvents = {
        onEventChanged,
        onBeforeEventChanged: (ev, e, is_new, original) => !privilege,
        onEmptyClick
    };

    async function onEventChanged(id, event) {
        const { items, methodsByItem } = eventToMutation(event);

        await evalQuery(client, null, 'CxAllocation', items, methodsByItem).catch(() => {});
    }

    async function onEmptyClick(date, e) {
        const el = e.target.closest('.dhx_matrix_line');
        const resourceId = get('dataset.sectionId', el);
        if (!resourceId) return;
        const [Start, End] = getShift(date, user);
        onOpenEditor('EditCxAllocation', {
            initialValues: {
                CurrentLifespan: { Start, End },
                ResourceSpans: [
                    {
                        Principal: { Id: resourceId, _DisplayName: resourceMap[resourceId]['DisplayName'] },
                        Range: { Start, End },
                        Auxiliaries: []
                    }
                ]
            },
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    }

    const schedulerProps = {
        editCallback,
        onEventDeleted: identity,
        event
    };
    if (rez.length < 1) return <p>Loading...</p>;

    return (
        <Box pb={5}>
            <Scheduler
                height="100%"
                width="100%"
                startDate={get('start', variables)}
                title="Allocation Timeline"
                data={rez}
                timelineConfig={_config}
                {...schedulerProps}
                {...schedulerEvents}
            />
            <Fab color="primary" aria-label="add" style={{ float: 'right', marginRight: 10 }} onClick={openEditor}>
                <AddIcon />
            </Fab>
        </Box>
    );
};

const InnerScheduler = withEditor(InnerScheduler_);

export const mapTop = (_in) => ({
    key: get('Id', _in),
    label: `${get('DisplayName', _in)}/${get('ResourceType.DisplayName', _in)}`
});

export const allocationToEvent = curry((family, _in) =>
    mapWithKey((each, idx) => pullUpAuxiliaries(family, _in, each, idx), _in.ResourceSpans)
);

/**
 * Sometimes if the resource is of the wrong type
 */
export const pullUpAuxiliaries = (family, _in, each, idx) => {
    if (get('Principal.ResourceType.Family', each) !== family) {
        return mapWithKey(
            (inner, idx) => ({
                ...spanToEvent(get('Range', each)),
                allocationId: _in.Id,
                JobActivityId: get('JobActivity.Id', _in),
                id: `${_in.Id}_${inner.Id}_${idx}`,
                resourceId: inner.Id,
                originalResourceId: inner.Id,
                section_id: `${inner.Id}`,
                text: get('Job.DisplayName', _in)
            }),
            filter((each) => each.ResourceType.Family === family, each.Auxiliaries)
        );
    }
    return {
        ...spanToEvent(get('Range', each)),
        allocationId: _in.Id,
        JobActivityId: get('JobActivity.Id', _in),
        id: `${_in.Id}_${each.Principal.Id}_${idx}`,
        resourceId: each.Principal.Id,
        originalResourceId: each.Principal.Id,
        section_id: `${each.Principal.Id}`,
        color: get('Job.ForeColor', _in),
        text: get('Job.DisplayName', _in)
    };
};

export const eventToMutation = (event) => {
    const methodsByItem = [];
    const _allocation = { Id: event.allocationId, JobActivity: { Id: event.JobActivityId } };
    methodsByItem.push({
        indices: [0],
        methods: [{ name: 'setRange', args: prepareTimelineDate(event) }]
    });
    if (event.section_id !== event.resourceId)
        methodsByItem.push({
            indices: [0],
            methods: [
                {
                    name: 'replaceResources',
                    args: {
                        originalIds: [event.resourceId],
                        newIds: [event.section_id]
                    }
                }
            ]
        });
    return {
        methodsByItem: methodsByItem,
        items: [_allocation]
    };
};
