import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'client-shared/components/Accordion';
import { getConfigValue, getSelectedItems, parseDate, SCHEDULECACHE, UserContext } from 'client-shared/utility';
import { SelectableItem } from 'client-shared/components/SelectableItem';
import StopIcon from '@mui/icons-material/Stop';
import { Hover } from 'client-shared/components/Hover';
import { format, addDays, subDays } from 'date-fns/fp';
import { map, flow, find, sortBy } from 'lodash/fp';
import { Typography, Box } from '@mui/material';
import get from 'lodash/fp/get';
import { DisplayAllocationTotals } from 'client-shared/components/totals';

const renderAllocation =
    (targetProperties, renderAllocationName, config_configValues) =>
    ({ item, selected }) => {
        const taskDays = item.TaskDays.get(targetProperties.CurrentLifespan.Start);
        const conflicts = find((taskDay) => taskDay.ConflictIds.length, taskDays);
        const shiftSupervisor = find((taskDay) => taskDay.ShiftSupervisor, taskDays);
        const more = find((taskDay) => taskDay.Description !== '', taskDays) ? ' more...' : '';
        const actionStatusBackColor = conflicts
            ? '#FF0000'
            : item.ActionStatus.BackColor === '#FFFFFF'
              ? null
              : item.ActionStatus.BackColor;
        return (
            <Box sx={{ display: 'flex' }}>
                {actionStatusBackColor && <StopIcon fontSize="small" sx={{ color: actionStatusBackColor }} />}
                <Typography
                    component="span"
                    fontSize="small"
                    sx={{
                        overflow: 'hidden',
                        margin: 0,
                        padding: 0,
                        // color: get('ForeColor', item) || '#000000',
                        // backgroundColor: selected ? 'Gainsboro' : get('BackColor', item) || '#FFFFFF',
                        fontWeight: shiftSupervisor ? 'bold' : 'normal'
                    }}
                >
                    {renderAllocationName(item, selected)}
                    <DisplayAllocationTotals
                        allocationDays={item.AllocationDays}
                        start={targetProperties.CurrentLifespan.Start}
                    />
                </Typography>
                <div>
                    <Hover
                        message={`${item.Description}${
                            get('Job.Description.display', config_configValues) ? item.Job.Description : ''
                        }${more}`}
                    />
                </div>
            </Box>
        );
    };

const renderTaskDay =
    (targetProperties, renderTaskDayName, config_configValues) =>
    ({ item, selected }) => {
        const conflicts = item.ConflictIds.length;
        const actionStatusBackColor = conflicts
            ? '#FF0000'
            : item.ActionStatus.BackColor === '#FFFFFF'
              ? null
              : item.ActionStatus.BackColor;
        return (
            <Box sx={{ display: 'flex' }}>
                {actionStatusBackColor && <StopIcon fontSize="small" sx={{ color: actionStatusBackColor }} />}
                <Typography
                    component="span"
                    fontSize="small"
                    sx={{
                        overflow: 'hidden',
                        margin: 0,
                        padding: 0,
                        fontWeight: get('ShiftSupervisor', item) ? 'bold' : 'normal'
                    }}
                >
                    {renderTaskDayName(item, selected)}
                </Typography>
                <div>
                    <Hover message={item.Description} />
                </div>
            </Box>
        );
    };

export const GridAllocations = ({
    client,
    values,
    privilege,
    items,
    targetProperties,
    cellDragDrop,
    AllocationDragDrop,
    renderAllocationName = (item, selected) => {
        return (
            <span
                style={{
                    color: get('ForeColor', item) || '#000000',
                    backgroundColor: selected ? 'Gainsboro' : get('BackColor', item) || '#FFFFFF',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre'
                }}
            >
                {item.DisplayName}
            </span>
        );
    },
    renderTaskDayName = (item, selected) => {
        return (
            <span
                style={{
                    color: get('ForeColor', item) || '#000000',
                    backgroundColor: selected ? 'Gainsboro' : get('BackColor', item) || '#FFFFFF'
                }}
            >
                {item.DisplayName}
            </span>
        );
    },
    onOpenEditor
}) => {
    const [user] = useContext(UserContext);
    const config_configValues = getConfigValue('config.schedule.gridallocations', user);
    const allocations = map(
        (allocation) => {
            const taskDays = allocation.TaskDays.get(targetProperties.CurrentLifespan.Start);
            if (!taskDays) {
                return null;
            }
            const allocationDragDrop = new AllocationDragDrop(
                client,
                privilege,
                targetProperties,
                allocation,
                onOpenEditor
            );
            const Allocation = (props) =>
                renderAllocation(targetProperties, renderAllocationName, config_configValues)(props);
            const TaskDay = (props) => renderTaskDay(targetProperties, renderTaskDayName, config_configValues)(props);
            const className =
                allocation.ActionType === 'Employee'
                    ? 'column1'
                    : allocation.ActionType === 'Equipment'
                      ? 'column2'
                      : 'column3';
            return (
                <Box key={`${allocation.Id}${targetProperties.CurrentLifespan.Start}`} className={className}>
                    <Accordion
                        unmountOnExit={true}
                        items={
                            <Box
                                sx={{ width: '80%' }}
                                onDoubleClick={() =>
                                    onOpenEditor(`EditCx${allocation.ObjectType}`, {
                                        formQueryValues: {
                                            filters: [{ name: 'Id', values: [allocation.Id] }],
                                            start: flow(
                                                subDays(30),
                                                format("yyyy-MM-dd'T'00:00:00")
                                            )(parseDate(new Date())),
                                            end: flow(
                                                addDays(300),
                                                format("yyyy-MM-dd'T'00:00:00")
                                            )(parseDate(new Date()))
                                        }
                                    })
                                }
                                onDrop={(event) => allocationDragDrop.handleAllocationDrop(event)}
                                draggable={true}
                                onDragStart={(ev) => {
                                    const items = getSelectedItems(
                                        client,
                                        SCHEDULECACHE,
                                        [allocation],
                                        targetProperties
                                    );
                                    cellDragDrop.handleDrag(items)(ev);
                                }}
                            >
                                <SelectableItem
                                    item={allocation}
                                    cacheName={SCHEDULECACHE}
                                    selectedItemProperties={targetProperties}
                                    component={Allocation}
                                />
                            </Box>
                        }
                    >
                        {map(
                            (taskDay) => {
                                return (
                                    <Box
                                        key={`${allocation.Id}${taskDay.Id}`}
                                        sx={{
                                            paddingLeft: '25px',
                                            margin: 0
                                        }}
                                        onDoubleClick={() =>
                                            onOpenEditor(`EditCx${taskDay.ObjectType}`, {
                                                formQueryValues: {
                                                    filters: [{ name: 'Id', values: [taskDay.Id] }],
                                                    start: flow(
                                                        subDays(30),
                                                        format("yyyy-MM-dd'T'00:00:00")
                                                    )(parseDate(new Date())),
                                                    end: flow(
                                                        addDays(300),
                                                        format("yyyy-MM-dd'T'00:00:00")
                                                    )(parseDate(new Date()))
                                                }
                                            })
                                        }
                                        draggable={true}
                                        onDragStart={(ev) => {
                                            const items = getSelectedItems(
                                                client,
                                                SCHEDULECACHE,
                                                [taskDay],
                                                targetProperties
                                            );
                                            cellDragDrop.handleDrag(items)(ev);
                                        }}
                                        onDrop={(event) => allocationDragDrop.handleTaskDayDrop(event, taskDay)}
                                    >
                                        <SelectableItem
                                            item={taskDay}
                                            cacheName={SCHEDULECACHE}
                                            selectedItemProperties={targetProperties}
                                            component={TaskDay}
                                        />
                                    </Box>
                                );
                            },
                            sortBy(['ActionType', 'DisplayName'], taskDays)
                        )}
                    </Accordion>
                </Box>
            );
        },
        sortBy(['ActionType', 'DisplayName'], items)
    );
    const gridStyle = get('_groupCell', values)
        ? {
              '.grid-container': {
                  display: 'grid',
                  gridTemplateColumns: 'auto auto auto',
                  gridAutoFlow: 'column'
              },
              '.column1': {
                  gridColumn: 1
              },
              '.column2': {
                  gridColumn: 2
              },
              '.column3': {
                  gridColumn: 3
              }
          }
        : undefined;
    return (
        <Box
            sx={{
                maxHeight: '300px',
                overflow: 'auto',
                overflowX: 'hidden',
                ...gridStyle
            }}
            onDrop={(event) => {
                cellDragDrop.handleCellDrop(event);
            }}
            onDragOver={cellDragDrop.allowDrop}
        >
            <div className={'grid-container'}>{allocations}</div>
        </Box>
    );
};

GridAllocations.propTypes = {
    client: PropTypes.object.isRequired,
    values: PropTypes.object,
    privilege: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    targetProperties: PropTypes.object.isRequired,
    cellDragDrop: PropTypes.object.isRequired,
    AllocationDragDrop: PropTypes.func.isRequired,
    renderAllocationName: PropTypes.func,
    renderTaskDayName: PropTypes.func,
    onOpenEditor: PropTypes.func
};
