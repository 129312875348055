/* eslint-disable react-hooks/exhaustive-deps*/
import { useState, useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { watchQueries } from './watchqueries';
import { isData } from './isdata';
import { query } from './usequery';
import { map, filter, get } from 'lodash/fp';

/**
 * user defined hook that returns a collection and optionally keeps it up-to-date based on events.
 * example: const data = useCollectionSubscription(dataQuery, eventQueries, variables, onFilter);
 * example dataQuery:
 query($filters: [FilterInput]) {
        cxJobs(filters: $filters) {
    ) {
        Id
        DisplayName
        ObjectType
    }
}
};




 * @param dataQuery - graphql query to get collection data.
 * @param eventQueries - optional array of graphql queries to listen for events that trigger an update to the collection.
 * currently, only one event listener is supported.
 * @param values - values passed to the dataQuery.
 * @param sourceMutationObjectTypes - optional string array of objectTypes of mutations that generated the event
 * dependencies. this can be used to listen for say, job events that were generated from a job
 * mutation or job events generated from an allocation mutation.
 * @param onFilter - optional function to transform the object values passed to the dataQuery.
 * @param forceQuery - optional value to force a new query even if values are the same. must be a unique value
 * each time useCollectionSubscription is called.
 * @param cache - if true, cache the results of the query if offline.
 * @param onMethodsByItem -  - optional function to add eval methods to the query.
 * @param onFormatters - optional function to add formatters to the query.
 * @returns {Object} - returns the updated collection.
 */
export const useCollectionSubscription = ({
    dataQuery,
    eventQueries = [],
    values,
    sourceMutationObjectTypes = [],
    onFilter = (values) => values,
    forceQuery,
    cache,
    onMethodsByItem = (values, client) => ({ methodsByItem: [] }),
    onFormatters = (values, client) => ({ formatters: [] })
}) => {
    const client = useApolloClient();
    const [collection, setCollection] = useState({ counter: 0, data: [], rawData: [], event: false, loading: true });
    const _collection = useRef(collection);
    const _values = useRef();
    const _counter = useRef(1);
    useEffect(() => {
        (async () => {
            if (!dataQuery) {
                _collection.current = {
                    counter: _counter.current++,
                    data: [],
                    rawData: [],
                    event: false,
                    loading: false
                };
                setCollection(_collection.current);
                return;
            }
            try {
                setCollection({ counter: 0, data: [], event: false, loading: true });
                const filters = await onFilter({ ...values }, undefined, client);
                const methodsByItem = await onMethodsByItem(values, client);
                const formatters = await onFormatters(values, client);
                const data = await query(client, dataQuery, { ...filters, ...methodsByItem, ...formatters }, cache);
                const newCollection = Object.values(get('data', data))[0];
                _collection.current = {
                    counter: _counter.current++,
                    data: newCollection,
                    rawData: data.data,
                    event: false,
                    loading: false
                };
                setCollection(_collection.current);
            } catch (error) {
                console.log(error);
                _collection.current = { counter: _counter.current++, data: [], error, event: false, loading: false };
                setCollection(_collection.current);
            }
        })();
        // use a ref to the values so the observable below can use the values while avoiding an
        // unnecessary initialization when the values change.
        // an observable.subscribe call triggers the first time it is called. if it finds a previous event
        // in the cache, it will perform an unnecessary query to the controlboard server.
        _values.current = values;
    }, [JSON.stringify(values), forceQuery, dataQuery]);

    useEffect(() => {
        if (!dataQuery || eventQueries.length === 0) {
            return;
        }
        const observable = watchQueries(client, eventQueries);
        const subscription = observable.subscribe((subscriptionData) => {
            if (!isData(subscriptionData)) {
                return;
            }

            const events = Object.values(get('data', subscriptionData))[0];
            // each event contains an array of dependencies.
            // each dependency contains a sourceMutationObjectType of the mutation that generated it,
            // (i.e. Allocation, Job, Resource, ActualTaskDay, VirtualTaskDay)
            const dependencies = filter(
                (dependency) => {
                    if (!sourceMutationObjectTypes.length) {
                        return true;
                    }
                    return sourceMutationObjectTypes.includes(dependency.SourceMutationObjectType);
                },
                get('0.Dependencies', events)
            );
            if (!dependencies.length) {
                return;
            }

            // replace items in the collection that were modified with the most recent copy
            // from the dbserver.
            const updateCollection = async () => {
                try {
                    // call the onFilter function with the default values, and the dependencies array.
                    // in most cases the dependencies array is ignored, but can be used to construct
                    // custom filters when there are two eventQueries that return different
                    // dependencies (e.g. allocation ids, and job ids). onFilter should return
                    // a FilterInput graphql type.
                    const filters = await onFilter(
                        {
                            ..._values.current,
                            id: _values.current._showAll ? [] : map((dependency) => dependency.Id, dependencies)
                        },
                        dependencies,
                        client
                    );
                    const methodsByItem = await onMethodsByItem(values, client);
                    const formatters = await onFormatters(values, client);
                    const data = await query(client, dataQuery, { ...filters, ...methodsByItem, ...formatters }, cache);

                    const newItems = Object.values(get('data', data))[0];
                    // remove items that were modified. always use the dependencies if it is the primary
                    // eventQuery (i.e. subscriptionData.index === 0), otherwise, we must infer
                    // what was modified from the items returned from the query. this is the case when there
                    // is more than one eventQuery and custom filters are being constructed since the subsequent
                    // eventQueries may not be the same object type of the _collection.current.data.
                    let modifiedItemsIds =
                        subscriptionData.index === 0
                            ? map((dependency) => dependency.Id, dependencies)
                            : map((newItem) => newItem.Id, newItems);

                    // if showall, empty the current collection since all values were retrieved but,
                    // the modifiedItemsIds will only be for the items that changed which is
                    // invalid when showall is true.
                    if (_values.current._showAll) {
                        _collection.current.data = [];
                    }
                    const unmodifiedItems = filter(
                        (item) => !modifiedItemsIds.includes(item.Id),
                        _collection.current.data
                    );
                    _collection.current = {
                        counter: _counter.current++,
                        data: [...unmodifiedItems, ...newItems],
                        rawData: _collection.current.rawData,
                        event: true,
                        loading: false
                    };
                    setCollection(_collection.current);
                } catch (error) {
                    console.log(error);
                }
            };
            updateCollection();
        });
        return () => subscription.unsubscribe();
    }, [dataQuery]);

    return collection;
};
