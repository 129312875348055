/**
 * Created by rob on fall 2022.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxWithLabel } from 'formik-mui';
import { Field, TemplateFormFileEditor, AutoComplete } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxTemplateForm';
import inputModel from 'cb-schema/inputmodelfull/CxTemplateForm';
import { object, string } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import { get } from 'lodash/fp';
import cloneDeep from 'lodash/cloneDeep';

import CxTemplateForm from 'cb-schema/jsonschema/CxTemplateForm';

// initialize model properties.
const initialValues = cloneDeep(model);

const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Key: string().min(2, 'Too Short').required('Upload a PDF file')
});
const Form = (props) => {
    const importTypes = get('properties.SupportedTypes.items.enum', CxTemplateForm);
    const { classes } = props;

    return (
        <React.Fragment>
            <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
            <form autoComplete="off">
                <Field
                    type="text"
                    label="Supported Types"
                    name="SupportedTypes"
                    component={AutoComplete}
                    className={classes.selectField}
                    options={importTypes}
                    multiple
                    initialize={false}
                    disableClearable
                />
                <Field
                    type="text"
                    label="File"
                    name="Key"
                    component={TemplateFormFileEditor}
                    mimeTypes="application/pdf"
                />

                <Field type="checkbox" Label={{ label: 'Required' }} name="Required" component={CheckboxWithLabel} />
                <Field
                    type="checkbox"
                    Label={{ label: 'MultiInstance' }}
                    name="MultiInstance"
                    component={CheckboxWithLabel}
                />
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxTemplateForm = ({ open, formQueryValues, onClose }) => {
    return (
        <DialogForm
            open={open}
            title="Forms"
            validationSchema={validationSchema}
            Form={Form}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxTemplateForm"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxTemplateForm.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
