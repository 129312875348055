import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { FormControl, FormHelperText, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import {
    Field,
    AutoComplete,
    CurrentLifespanTimeEditor,
    TagEditor,
    tagValidationSchema
} from 'client-shared/components/form';
import { array, number, object, string } from 'yup';
import { get } from 'lodash/fp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Section } from 'client-shared/components/Section';
import { gql } from 'graphql-tag';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import { getConfigValue, UserContext } from 'client-shared/utility';

export const jobActivitiesValidationSchema = array()
    .min(1, 'At Least One Job Activity Is Required')
    .of(
        object({
            Name: string().min(2, 'Too Short').max(65, 'Too Long').nullable().required('Required'),
            DefaultShift: object({
                Id: number().moreThan(0, 'Required')
            })
                .nullable()
                .required('Required'),
            //CurrentLifespan: currentLifespanTimeValidationSchema,
            Tags: tagValidationSchema
        })
    );

export const JobActivitiesEditor = ({ form, field, classes, label = '', data, ...other }) => {
    const [user] = useContext(UserContext);
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    const model = {
        Completed: false,
        // SortOrder: 0,
        Id: 0,
        Name: getConfigValue('jobactivity', user) || 'Miscellaneous',
        Description: '',
        CurrentLifespan: form.values.CurrentLifespan,
        EstimatedLaborHours: 0,
        ForeColor: '#000000',
        BackColor: '#FFFFFF',
        Tags: [],
        DefaultShift: {}
    };
    return (
        <Section title="Job Activities">
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((activity, index) => {
                                    return (
                                        <Section key={index}>
                                            {/*<Field*/}
                                            {/*    type="text"*/}
                                            {/*    label="Name"*/}
                                            {/*    name={`${field.name}.${index}.Name`}*/}
                                            {/*    component={AutoComplete}*/}
                                            {/*    className={classes.selectField}*/}
                                            {/*    optionsQuery={gql`*/}
                                            {/*        query ($searchString: String) {*/}
                                            {/*            cxTemplateJobActivitys(*/}
                                            {/*                filters: [*/}
                                            {/*                    {*/}
                                            {/*                        name: "String"*/}
                                            {/*                        values: ["DisplayName", $searchString]*/}
                                            {/*                    }*/}
                                            {/*                ]*/}
                                            {/*            ) {*/}
                                            {/*                DisplayName*/}
                                            {/*            }*/}
                                            {/*        }*/}
                                            {/*    `}*/}
                                            {/*    freeSolo={true}*/}
                                            {/*    optionLabelProperty="DisplayName"*/}
                                            {/*/>*/}
                                            <Field
                                                type="text"
                                                label="Name"
                                                name={`${field.name}.${index}.Name`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                optionsQuery={gql`
                                                    query ($searchString: String) {
                                                        cxCaches(
                                                            objectType: "Cache"
                                                            filters: [
                                                                {
                                                                    name: "String"
                                                                    values: ["JobActivities.Name", $searchString]
                                                                }
                                                            ]
                                                        ) {
                                                            JobActivities {
                                                                Name
                                                            }
                                                        }
                                                    }
                                                `}
                                                // getValue={(event, value) => (value ? value.Name : value)}
                                                getOptions={(options) => get('0.JobActivities', options)}
                                                freeSolo={true}
                                                optionLabelProperty="Name"
                                            />
                                            <Field
                                                type="text"
                                                name={`${field.name}.${index}.CurrentLifespan`}
                                                component={CurrentLifespanTimeEditor}
                                                classes={classes}
                                            />
                                            <Field
                                                type="number"
                                                label="Estimated Labor Hours"
                                                name={`${field.name}.${index}.EstimatedLaborHours`}
                                                component={TextField}
                                                className={classes.shortTextField}
                                            />
                                            <Field
                                                type="checkbox"
                                                Label={{ label: 'Completed' }}
                                                name={`${field.name}.${index}.Completed`}
                                                component={CheckboxWithLabel}
                                                style={{ paddingLeft: '30px' }}
                                            />
                                            <Field
                                                type="text"
                                                label="Default Shift"
                                                name={`${field.name}.${index}.DefaultShift`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                optionsQuery={gql`
                                                    query ($searchString: String) {
                                                        cxShifts(
                                                            filters: [
                                                                {
                                                                    name: "String"
                                                                    values: ["DisplayName", $searchString]
                                                                }
                                                            ]
                                                        ) {
                                                            Id
                                                            _DisplayName: DisplayName
                                                            _Default: Default
                                                        }
                                                    }
                                                `}
                                                optionLabelProperty="_DisplayName"
                                                disableClearable
                                                initialize={true}
                                            />
                                            <div>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography>Job Activity Details</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Field
                                                            type="text"
                                                            label="Description"
                                                            name={`${field.name}.${index}.Description`}
                                                            component={TextField}
                                                            className={classes.textBoxField}
                                                            multiline
                                                            variant="outlined"
                                                            margin="normal"
                                                        />
                                                        <Field
                                                            type="color"
                                                            label="ForeColor"
                                                            name={`${field.name}.${index}.ForeColor`}
                                                            component={TextField}
                                                            className={classes.textField}
                                                        />
                                                        <Field
                                                            type="color"
                                                            label="BackColor"
                                                            name={`${field.name}.${index}.BackColor`}
                                                            component={TextField}
                                                            className={classes.textField}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <Field
                                                type="text"
                                                name={`${field.name}.${index}.Tags`}
                                                component={TagEditor}
                                                classes={classes}
                                                customProperties={data.CustomPropertysJobActivity}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            {index > 0 && (
                                                <IconButton
                                                    onClick={() => {
                                                        arrayHelpers.swap(index, index - 1);
                                                    }}
                                                >
                                                    <ArrowUpwardIcon />
                                                </IconButton>
                                            )}
                                            {index < field.value.length - 1 && (
                                                <IconButton
                                                    onClick={() => {
                                                        arrayHelpers.swap(index, index + 1);
                                                    }}
                                                >
                                                    <ArrowDownwardIcon />
                                                </IconButton>
                                            )}
                                        </Section>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

JobActivitiesEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    data: PropTypes.object
};
