import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

export const ToolBar = ({ children }) => {
    return (
        <Paper
            sx={{
                padding: '5px 5px 5px 5px',
                textAlign: 'center'
            }}
        >
            {children}
        </Paper>
    );
};

ToolBar.propTypes = {
    height: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};
