/**
 * Use this to lift properties functions out of child component
 * @param propName
 * @returns {{propName: *}}
 */

export const propLifter = (propName) => new PropLifter(propName);

function PropLifter(propName) {
    this.go = (props) => (this.fn = props[propName]);
}

/**
 * Wrap a useState Setter with a more functional idiom
 * @param setter
 * @param getter
 * @returns {function(*=): *}
 */
export const setState = (setter, getter) => (_in) => {
    setter(getter(_in));
    return _in;
};
