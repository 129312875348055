import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Tab, Tabs, Tooltip } from '@mui/material';
import { find, forEach } from 'lodash/fp';
import { ResourceList } from 'client-shared/components/ResourceList';
import PersonIcon from '@mui/icons-material/Person';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SpokeIcon from '@mui/icons-material/Spoke';
import { ResourceTypeList } from 'client-shared/components/ResourceTypeList';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { SuppliersList } from 'client-shared/components/SupplierList';
import { JobList } from 'client-shared/components/JobList';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const _masterTabs = [
    {
        label: 'Jobs',
        component: <JobList />,
        icon: <LocationOnIcon />
    },
    {
        label: 'Resource',
        component: <ResourceList filterFormDefinitionName={'resourcelist.js'} />,
        icon: <PersonIcon />
    },
    {
        label: 'Labor',
        component: <ResourceList filterFormDefinitionName={'laborlist.js'} />,
        icon: <PersonIcon />
    },
    {
        label: 'Equipment',
        component: <ResourceList filterFormDefinitionName={'equipmentlist.js'} />,
        icon: <AgricultureIcon />
    },
    {
        label: 'Crew',
        component: <ResourceList filterFormDefinitionName={'crewlist.js'} />,
        icon: <PeopleAltIcon />
    },
    {
        label: 'Material',
        component: <ResourceList filterFormDefinitionName={'materiallist.js'} />,
        icon: <SpokeIcon />
    },
    {
        label: 'Plan',
        component: <ResourceTypeList filterFormDefinitionName={'resourcetypelist.js'} />,
        icon: <CalendarMonthIcon />
    },
    {
        label: 'Subcontractor',
        component: <SuppliersList filterFormDefinitionName="subcontractorlist.js" />,
        icon: <LocalShippingIcon />
    },
    {
        label: 'Plant',
        component: <SuppliersList filterFormDefinitionName="plantlist.js" />,
        icon: <LocationCityIcon />
    }
];

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff'
    }
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    '&:hover': {
        color: '#40a9ff',
        opacity: 1
    },
    '&.Mui-selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff'
    }
}));

export const TabsMenu = ({
    masterTabs = _masterTabs,
    labels = ['Labor', 'Equipment', 'Crew', 'Material', 'Plan', 'Subcontractor', 'Plant']
}) => {
    const [tabNo, setTabNo] = useState(0);
    const handleTab = (event, tab) => {
        setTabNo(tab);
    };
    const tabs = [];
    const tabComponents = [];
    forEach((label) => {
        const tab = find((masterTab) => masterTab.label.toLowerCase() === label.toLowerCase(), masterTabs);
        if (!tab) {
            throw new Error(`Invalid ResourceTab label defined: ${label}`);
        }
        const T = <Tooltip title={tab.label}>{tab.icon}</Tooltip>;
        // tabs.push(<SmallTab label={tab.label} />);
        tabs.push(<AntTab key={tab.label} icon={T} />);
        tabComponents.push(tab);
    }, labels);
    return (
        <>
            <AntTabs value={tabNo} indicatorColor="secondary" variant="fullWidth" onChange={handleTab}>
                {tabs}
            </AntTabs>
            {tabComponents[tabNo].component}
        </>
    );
};

TabsMenu.propTypes = {
    masterTabs: PropTypes.array,
    labels: PropTypes.array
};
