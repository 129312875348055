import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "Route", $filters: [FilterInput]) {
        cxRoutes(objectType: $objectType, filters: $filters) {
            Id
            Iterations
            CurrentLifespan {
                Start
                End
            }
            Driver {
                Id
                _DisplayName: DisplayName
            }
            Rig {
                Id
                _DisplayName: DisplayName
            }
            Description
            ForeColor
            BackColor
            Stops {
                Id
                Start
                Jobs {
                    Id
                    _DisplayName: DisplayName
                }
                Address {
                    Id
                    Autocode
                    Street
                    StreetDetails
                    City
                    State {
                        Id
                        _Description: Description
                    }
                    ZipCode
                    Geocode {
                        Id
                        Lat
                        Lng
                    }
                }
                Dropoffs {
                    Id
                    Resources {
                        Id
                        _DisplayName: DisplayName
                        _ResourceType: ResourceType {
                            Family
                            Unit {
                                Name
                            }
                        }
                    }
                    Quantity {
                        Unit {
                            Id
                            _DisplayName: DisplayName
                        }
                        Quantity
                    }
                    Description
                    Completed
                }
                Pickups {
                    Id
                    Resources {
                        Id
                        _DisplayName: DisplayName
                        _ResourceType: ResourceType {
                            Family
                            Unit {
                                Name
                            }
                        }
                    }
                    Quantity {
                        Unit {
                            Id
                            _DisplayName: DisplayName
                        }
                        Quantity
                    }
                    Description
                    Completed
                }
            }
        }
    }
`;

export const CxJobsQuery = gql`
    query ($searchString: String, $start: String, $end: String) {
        cxJobs(
            objectType: "Job"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            _Address: Address {
                Id
                Autocode
                Street
                StreetDetails
                City
                State {
                    Id
                    _Description: Description
                }
                ZipCode
                Geocode {
                    Id
                    Lat
                    Lng
                }
            }
        }
    }
`;

export const CxResourcesQuery = gql`
    query ($searchString: String, $start: String, $end: String) {
        cxResources(
            objectType: "Resource"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
                { name: "ObjectType", values: ["Equipment", "Material"] }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            _ObjectType: ObjectType
            _ResourceType: ResourceType {
                Family
                Unit {
                    Name
                }
            }
        }
    }
`;
