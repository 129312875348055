import { gql } from 'graphql-tag';

export const AllocationsQuery = gql`
    query ($resourceIds: [String!] = [], $start: String!, $end: String!) {
        cxAllocations(
            objectType: "Allocation"
            filters: [
                { name: "ResourceIds", values: $resourceIds }
                { name: "Range", values: [$start, $end] }
                { name: "Range", values: [$start, $end], child: "TaskDays" }
            ]
        ) {
            ObjectType
            Id
            Name
            DisplayName
            JobActivity {
                Id
            }
            Tags {
                Name
                Value
            }
            ForeColor
            BackColor
            PrincipalType {
                ForeColor
                BackColor
            }
            ActionStatus {
                Completed
                Cancelled
                Active
                BackColor
                DisplayName
            }
            Job {
                DisplayName
                Name
                BackColor
                ForeColor
                Address {
                    State {
                        Id
                        Description
                    }
                    Street
                    StreetDetails
                    ZipCode
                    City
                }
                Tags {
                    Name
                    Value
                }
            }
            ResourceSpans {
                Range {
                    Start
                    End
                }
                Principal {
                    DisplayName
                    Id
                    ResourceType {
                        DisplayName
                        Name
                    }
                    ResourceSubtype {
                        Description
                    }
                }
            }
        }
    }
`;
