import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field } from 'client-shared/components/form';
import { Section } from 'client-shared/components/Section';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { array, string } from 'yup';
import { get } from 'lodash/fp';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { TextField } from 'formik-mui';

export const forwardingEmailsValidationSchema = array().of(string().required('Required').email());
export const ForwardingEmailsEditor = ({ form, field, classes, label = '' }) => {
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    const model = '';
    return (
        <Section title="Forwarding Emails">
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((value, index) => {
                                    return (
                                        <div key={index}>
                                            <Field
                                                type="text"
                                                label="Email"
                                                name={`${field.name}.${index}`}
                                                component={TextField}
                                                className={classes.textField}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                    form.setFieldTouched(field.name, true);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

ForwardingEmailsEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string
};
