import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Schedule } from 'client-shared/components/schedulegrid';
import { GridAllocations } from 'client-shared/components/schedulegrid/GridAllocations';
import {
    getColumns,
    getCellValue,
    Layout,
    Scroll,
    withAllocations,
    HeaderCell
} from 'client-shared/components/schedulegrid/schedulehelpers';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { getFilter, usePrivilege, useReportTotals, parseDate, reportExcel } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import { flow, get, filter, flatMap, find, sortBy, flowRight as compose, omit } from 'lodash/fp';
import { Crew } from './Crew';
import { CellDragDrop, AllocationDragDrop, Actions } from './dragdrop';
import { ExcelExport } from 'client-shared/components/ExcelExport';
import { useApolloClient } from '@apollo/client';
import Divider from '@mui/material/Divider';
import { GridTaskDays } from './GridTaskDays';
import { ClipBoard } from 'client-shared/components/schedulegrid/ClipBoard';
import { ToolBar } from 'client-shared/components/schedulegrid/ToolBar';
import { ToolBarActions } from 'client-shared/components/schedulegrid/ToolBarActions';
import { ToolBarDelete } from 'client-shared/components/schedulegrid/ToolBarDelete';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { withGrid } from 'client-shared/grids/withGrid';
import { withEditor } from 'client-shared/entities/withEditor';
import { isSameDay } from 'date-fns/fp';
import { TabsMenu } from 'client-shared/components/TabsMenu';
import { taskDaysExcel } from 'client-shared/components/serverobjects/TaskDays2';

const map = require('lodash/fp/map').convert({ cap: false });

/**
 * comparison function to filter allocations for the row.
 * @param row
 * @param columnName
 * @param allocation
 * @returns {unknown}
 */
const comparator = (row, columnName, allocation) => {
    if (row._root) {
        if (get('Crew.Id', allocation) !== row.Id) {
            return false;
        }
    } else {
        if (!find((resource) => get('Id', resource) === row.Id, allocation.Resources)) {
            return false;
        }
    }

    // check if allocation contains taskDays for columnName date.
    return allocation.TaskDays.get(columnName);
};

/**
 * get the rows to display in the grid.
 * @param cxCrews
 * @returns {*}
 */
const getRows = (cxCrews) => {
    const rows = map(
        (cxCrew) => ({
            _root: true,
            ...cxCrew
        }),
        cxCrews
    );
    return sortBy('DisplayName', rows);
};

/**
 * get the columns to display in the grid.
 * @param values
 * @returns {[{name: string, title: string},...*]}
 */
const getCols = (values) => {
    return [
        {
            name: 'DisplayName',
            title: 'Crew'
        },
        ...getColumns({
            values
        })
    ];
};

/**
 * get the rows to export.
 * @param cxCrews
 * @returns {*}
 */
const getExcelRows = (cxCrews) => {
    return [{ headerRow: true }, ...cxCrews];
};

/**
 * get the columns to export.
 * @param columns
 * @returns {[{name: string, title: string},...*]}
 */
const getExcelCols = (columns) => {
    // make a copy.
    columns = [...columns];
    // add additional column to export.
    columns.splice(1, 0, { title: 'Description', name: 'Description' });
    return columns;
};

/**
 * populate each row of the spreadsheet.
 * @param values
 * @param allocations
 * @returns {(function(*, *, *, *): (*|undefined))|*}
 */
// const addExcelRow = (values, allocations) => {
//     return (row, rowNumber, columns, workSheet) => {
//         const workSheetRow = workSheet.addRow(Array(columns.length).fill(''));
//         workSheetRow.eachCell((cell, colNumber) => {
//             cell.alignment = { wrapText: true };
//             if (colNumber === 1) {
//                 let color = get('ForeColor', row).slice(1);
//                 //color = color === '000000' ? 'f0f0f0' : color;
//                 cell.font = { color: { argb: color } };
//                 return (cell.value = row.DisplayName);
//             }
//             if (colNumber === 2) {
//                 return (cell.value = row.Description);
//             }
//             flow(
//                 filter((allocation) => comparator(row, columns[colNumber - 1].name, allocation)),
//                 map((allocation) => {
//                     return {
//                         font: { color: { argb: get('ForeColor', allocation.Job).slice(1) } },
//                         text: `${allocation.Job.DisplayName}:${allocation.JobActivity.DisplayName}\n`
//                     };
//                 }),
//                 (richText) => {
//                     if (!richText.length) {
//                         return;
//                     }
//                     cell.border = {
//                         top: { style: 'medium' },
//                         left: { style: 'medium' },
//                         bottom: { style: 'medium' },
//                         right: { style: 'medium' }
//                     };
//                     cell.value = { richText: richText };
//                 }
//             )(allocations);
//         });
//     };
// };

const addExcelCell = (values, allocations, reportIntervals) => {
    return ({ row, rowNumber, column, colNumber, colName, cell }) => {
        cell.alignment = { wrapText: true };
        // add total row
        if (row.headerRow) {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DCDCDC' } };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: {
                    style: 'thin'
                }
            };
            cell.value = get('title', column);
            if (isNaN(new Date(colName))) {
                return;
            }
            const reportInterval = find(
                (reportInterval) => isSameDay(parseDate(reportInterval.Range.Start), parseDate(colName)),
                reportIntervals
            );
            cell.value = {
                richText: [
                    {
                        font: { bold: true },
                        text: `${colName}\n`
                    },
                    ...reportExcel({
                        reportProperties: values._reportIntervalProperties,
                        reportObject: get('Report', reportInterval),
                        unit: { Name: 'Hrs', Scalar: 60 }
                    })
                ]
            };
            return;
        }
        if (colName === 'DisplayName') {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DCDCDC' } };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            const color = get('ForeColor', row).slice(1);
            cell.font = { color: { argb: color } };
            return (cell.value = row.DisplayName);
        }
        if (colName === 'Description') {
            return (cell.value = row.Description);
        }
        flow(
            filter((allocation) => comparator(row, colName, allocation)),
            (allocations) => {
                if (!values._members) {
                    const richText = map(
                        (allocation) => ({
                            font: { color: { argb: get('ForeColor', allocation.Job).slice(1) } },
                            text: `${allocation.DisplayName}\n`
                        }),
                        allocations
                    );
                    cell.border = {
                        top: { style: 'medium' },
                        left: { style: 'medium' },
                        bottom: { style: 'medium' },
                        right: { style: 'medium' }
                    };
                    cell.value = { richText: richText };
                    return;
                }
                const taskDays = flatMap((allocation) => {
                    return map((taskDay) => {
                        return {
                            Allocation: omit(['TaskDays'], allocation),
                            ...taskDay
                        };
                    }, allocation.TaskDays.get(colName));
                }, allocations);
                if (!taskDays.length) {
                    return;
                }
                cell.border = {
                    top: { style: 'medium' },
                    left: { style: 'medium' },
                    bottom: { style: 'medium' },
                    right: { style: 'medium' }
                };
                cell.value = { richText: taskDaysExcel({ taskDays: taskDays, description: values._description }) };
            }
        )(allocations);
    };
};

const getTargetProperties = (row, columnName) => {
    if (row._root) {
        return {
            Resource: {
                Id: row.Id
            },
            CurrentLifespan: {
                Start: columnName,
                End: columnName
            },
            ObjectType: row.ObjectType
        };
    }
    return {
        Resource: {
            Id: row.Id
        },
        CurrentLifespan: {
            Start: columnName,
            End: columnName
        },
        ObjectType: row.ObjectType
    };
};

const TableCellComponent = ({ onOpenEditor, ...other }) => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    const targetProperties = getTargetProperties(other.row, other.column.name);
    const cellDragDrop = new CellDragDrop(client, privilege, targetProperties, onOpenEditor);
    return (
        <VirtualTable.Cell
            {...other}
            onDrop={(event) => {
                cellDragDrop.handleCellDrop(event);
            }}
            onDragOver={cellDragDrop.allowDrop}
        />
    );
};

/**
 * component to display a cell in the grid.
 * @param row - a row from the grid.
 * @param columnName - name of column
 * @param items - collection of allocations
 * @param rowSummary - true if only totals are displayed on the job row.
 * @param onOpenEditor - function to open an edit form.
 * @param onOpenGrid - function to display a grid.
 * @returns {JSX.Element|undefined}
 * @constructor
 */
const Cell = ({ row, columnName, items, rowSummary = false, onOpenEditor, onOpenGrid }) => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    if (columnName === 'DisplayName') {
        return <Crew row={row} onOpenEditor={onOpenEditor} onOpenGrid={onOpenGrid} />;
    }

    const targetProperties = getTargetProperties(row, columnName);
    const cellDragDrop = new CellDragDrop(client, privilege, targetProperties, onOpenEditor);
    if (row._root) {
        return (
            <div
                onDrop={(event) => {
                    cellDragDrop.handleCellDrop(event);
                }}
                onDragOver={cellDragDrop.allowDrop}
            >
                <GridAllocations
                    client={client}
                    privilege={privilege}
                    items={items}
                    targetProperties={targetProperties}
                    cellDragDrop={cellDragDrop}
                    AllocationDragDrop={AllocationDragDrop}
                    onOpenEditor={onOpenEditor}
                    renderAllocationName={(item, selected) => {
                        return (
                            <span
                                style={{
                                    //wordWrap: 'break-word',
                                    whiteSpace: 'pre',
                                    padding: 0,
                                    color:
                                        get('JobActivity.ForeColor', item) === '#000000'
                                            ? get('Job.ForeColor', item)
                                            : get('JobActivity.ForeColor', item),
                                    backgroundColor: selected
                                        ? 'Gainsboro'
                                        : get('JobActivity.BackColor', item) === '#FFFFFF'
                                          ? get('Job.BackColor', item)
                                          : get('JobActivity.BackColor', item)
                                }}
                            >
                                {item.DisplayName}
                            </span>
                        );
                    }}
                />
            </div>
        );
    }
    // display taskDays for this resource and day
    let taskDays = flow(
        flatMap((allocation) => {
            return map(
                (taskDay) => ({ ...taskDay, Job: allocation.Job, JobActivity: allocation.JobActivity }),
                allocation.TaskDays.get(targetProperties.CurrentLifespan.Start)
            );
        }),
        filter((taskDay) => {
            if (taskDay.ObjectType === 'VirtualTaskDay') {
                return false;
            }
            return find((resource) => {
                return resource.Id === targetProperties.Resource.Id;
            }, taskDay.Resources);
        })
    )(items);
    return (
        <div
            onDrop={(event) => {
                cellDragDrop.handleCellDrop(event);
            }}
            onDragOver={cellDragDrop.allowDrop}
        >
            <GridTaskDays
                client={client}
                targetProperties={targetProperties}
                taskDays={taskDays}
                cellDragDrop={cellDragDrop}
                sortOrder={['CurrentLifespan.Start', 'DisplayName']}
                display={(item) => {
                    return `${item.jobDisplayName}:${item.DisplayName}`;
                }}
                onOpenEditor={onOpenEditor}
            />
        </div>
    );
};

Cell.propTypes = {
    row: PropTypes.object.isRequired,
    columnName: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    rowSummary: PropTypes.bool,
    onOpenEditor: PropTypes.func.isRequired,
    onOpenGrid: PropTypes.func.isRequired
};

/**
 * component to display a header cell in the grid.
 * @param availableEmployees - true if available employees should be calculated and displayed.
 * @param column - column title and name.
 * @param items - collection of allocations.
 * @returns {JSX.Element}
 * @constructor
 */
// const HeaderCell = ({ availableEmployees, column, items }) => {
//     const allocations = filter((allocation) => allocation.TaskDays.get(column.name), items);
//     return (
//         <Typography fontSize="small" component={'div'}>
//             <span style={{ fontSize: '10px' }}>
//                 <div>{column.title}</div>
//                 {availableEmployees ? (
//                     <HeaderTotals start={column.name} allocations={allocations} />
//                 ) : (
//                     <Totals allocations={allocations} start={column.name} />
//                 )}
//             </span>
//         </Typography>
//     );
// };

HeaderCell.propTypes = {
    column: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired
};

const Grid_ = ({
    values,
    onSubmit,
    excelRows,
    excelColumns,
    filterFormDefinition,
    rows,
    columns,
    allocations,
    onOpenEditor,
    onOpenGrid
}) => {
    const [reportIntervals] = useReportTotals({
        reportProperties: values._reportIntervalProperties,
        periods: values._periods,
        start: values._start,
        stratify: false,
        interval: 'Days',
        values
    });
    return (
        <React.Fragment>
            <Scroll values={values} onSubmit={onSubmit} />
            <ExcelExport
                rows={excelRows}
                getColumns={() => excelColumns}
                addExcelCell={addExcelCell(values, allocations, reportIntervals)}
            />
            <Schedule
                title={filterFormDefinition.title}
                rows={rows}
                columns={columns}
                getRowId={(row) => {
                    return row._root ? row.Id : `c${row.Id}`;
                }}
                getChildRows={(row, rootRows) => {
                    if (row) {
                        if (row._root) {
                            return row.Resources;
                        }
                        return null;
                    }
                    return rootRows;
                }}
                getCellValue={getCellValue({
                    items: allocations,
                    comparator: comparator,
                    render: ({ row, columnName, items }) => {
                        return (
                            <Cell
                                row={row}
                                columnName={columnName}
                                items={items}
                                rowSummary={values._rowSummary}
                                onOpenEditor={onOpenEditor}
                                onOpenGrid={onOpenGrid}
                            />
                        );
                    }
                })}
                tableHeaderCell={(props) => {
                    return (
                        <HeaderCell
                            values={values}
                            column={props.column}
                            items={allocations}
                            reportIntervals={reportIntervals}
                        />
                    );
                }}
                tableCellComponent={(props) => <TableCellComponent onOpenEditor={onOpenEditor} {...props} />}
                fixedColumnNames={['DisplayName']}
            />
        </React.Fragment>
    );
};

const Grid = compose([withGrid, withEditor, withAllocations])(Grid_);

const Schedule_ = () => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.pages', 'crewschedule.js');
    const handleFormatters = (values, client) => {
        return {
            formatters: [
                { propertyName: 'crewallocformat', format: 'config.crewschedule.crewallocformat' },
                { propertyName: 'generictaskdayformat', format: 'config.crewschedule.generictaskdayformat' },
                { propertyName: 'planttaskdayformat', format: 'config.crewschedule.planttaskdayformat' },
                { propertyName: 'subtaskdayformat', format: 'config.crewschedule.subtaskdayformat' },
                { propertyName: 'materialtaskdayformat', format: 'config.crewschedule.materialtaskdayformat' },
                { propertyName: 'employeetaskdayformat', format: 'config.crewschedule.employeetaskdayformat' },
                { propertyName: 'equipmenttaskdayformat', format: 'config.crewschedule.equipmenttaskdayformat' },
                { propertyName: 'groupallocformat', format: 'config.crewschedule.crewallocformat' }
            ]
        };
    };

    return (
        <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
            {({ values, data, onSubmit }) => {
                const rows = getRows(data);
                const columns = getCols(values);
                const excelRows = getExcelRows(rows);
                const excelColumns = getExcelCols(columns);
                return (
                    <Grid
                        values={values}
                        onSubmit={onSubmit}
                        excelRows={excelRows}
                        excelColumns={excelColumns}
                        filterFormDefinition={filterFormDefinition}
                        rows={rows}
                        columns={columns}
                        onFormatters={handleFormatters}
                    />
                );
            }}
        </FilterFormFromJson>
    );
};

const Tools = () => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    const actions = new Actions(client, privilege);
    return (
        <ToolBar>
            <div>
                <ToolBarDelete />
            </div>
            <ToolBarActions actions={actions} />
            <TabsMenu labels={['Jobs', 'Labor', 'Equipment', 'Subcontractor', 'Plant']} />
            <Divider />
            <ClipBoard />
        </ToolBar>
    );
};

export const CrewSchedule = () => <Layout Schedule={Schedule_} ToolBar={Tools} />;
