import React, { useState, useRef } from 'react';
import { Editor } from './index';

export const withEditor = (Component) => (props) => {
    const [editor, setOpenEditor] = useState({ editor: undefined, props: {} });
    const onClose = useRef(() => null);
    const handleOpenEditor = (editor, props, onCloseCallBack) => {
        onClose.current = onCloseCallBack;
        setOpenEditor({ editor: editor, props: props });
    };

    const handleCloseEditor = (props) => {
        setOpenEditor({ editor: undefined, props: {} });
        if (onClose.current) {
            onClose.current(props);
        }
    };
    return (
        <React.Fragment>
            <Editor open={!!editor.editor} form={editor.editor} onClose={handleCloseEditor} {...editor.props} />
            <Component onOpenEditor={handleOpenEditor} {...props} />
        </React.Fragment>
    );
};
