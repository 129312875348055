import React, { useContext } from 'react';
import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { get } from 'lodash/fp';
import { useQuery, UserContext } from 'client-shared/utility';

import gql from 'graphql-tag';

const GET_CREDZ = gql`
    {
        fetchApiCredentials {
            userPoolApiClientId
            userPoolApiClientSecret
            tokenEndpoint
            accessToken
        }
    }
`;
export const OauthCreds = (props) => {
    const [user] = useContext(UserContext);
    const { loading, data } = useQuery(GET_CREDZ, [], true);
    const theme = useTheme();

    if (loading) return <p>Loading ...</p>;

    const credz = data.fetchApiCredentials;
    const CBD_ENV = get('customerInfo.CBD_ENV', user);
    const schemaLink = `https://studio.apollographql.com/graph/controlboard-api/home?variant=${CBD_ENV}`;
    const tokenExpiry = new Date(Date.now() + 1 * (60 * 60 * 1000));
    const localLink = 'localhost' === CBD_ENV ? 'http://localhost:3001/graphql' : `${window.location.origin}/graphql`;

    const curl = `curl --request POST \\
  --url '${credz.tokenEndpoint}' \\
  --header 'content-type: application/x-www-form-urlencoded' \\
  --data grant_type=client_credentials \\
  --data client_id=${credz.userPoolApiClientId}\\
  --data client_secret=${credz.userPoolApiClientSecret}
    `;

    const query = `curl --request POST \\
  --url ${localLink} \\
  --header 'content-type: application/json' \\
  --header 'Authorization: ${credz.accessToken}' \\
  --data '{"query":"{cxJobs { DisplayName }}"}'`;

    console.log(theme.palette.secondary);
    //

    if (!credz.accessToken) {
        return (
            <div>
                <h1>Developer Credentials</h1>
                <p>
                    Looks like you haven't been set up for developer access. Contact{' '}
                    <a href="mailto:rob@congistics.com">rob@congistics.com</a> and he'll hook you up.
                </p>
            </div>
        );
    }

    return (
        <div>
            <h1>Developer Credentials</h1>
            <Box
                component="form"
                sx={{
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 2,
                    minWidth: 450,
                    '& .MuiTextField-root': { m: 1, width: '70ch' },
                    '& .MuiInputBase-root': { backgroundColor: theme.palette.background.code }
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    fullWidth
                    id="clientId"
                    label="Client Id"
                    defaultValue={credz.userPoolApiClientId}
                    InputProps={{
                        readOnly: true
                    }}
                />
                <TextField
                    fullWidth
                    id="secret"
                    label="Client Secret Id"
                    defaultValue={credz.userPoolApiClientSecret}
                    InputProps={{
                        readOnly: true
                    }}
                />

                <TextField
                    fullWidth
                    id="endpoint"
                    label="Oauth Endpoint"
                    defaultValue={credz.tokenEndpoint}
                    InputProps={{
                        readOnly: true
                    }}
                />

                <p>
                    You can use the above endpoint to generate the token programmatically, following the instructions
                    here:&nbsp;
                    <a href="https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html">
                        https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
                    </a>
                </p>
                <p>Here's an example using curl:</p>
                <TextField fullWidth id="curl1" multiline rows={6} defaultValue={curl} />
                <p>
                    Or you can use the token we generated for you below. This token expires in an hour. Contact us and
                    we can extend it's expiration for up to one day. If You need long running access to the api, use the
                    credentials above to regenerate a token as needed.
                </p>
                <TextField
                    fullWidth
                    id="token"
                    label="Access Token"
                    multiline
                    rows={15}
                    defaultValue={credz.accessToken}
                />
                <p>
                    Once you have a token you can use it to make queries against your ControlBoard instance:&nbsp;
                    <a href={localLink}>{localLink}</a>. Simply add it as an "Authorization" header to any requests
                    against the server. Here's an example that simply returns all your Job descriptions:
                </p>
                <TextField fullWidth id="curl2" multiline rows={20} defaultValue={query} />
                <p>Expires {tokenExpiry.toLocaleTimeString()} </p>
                {CBD_ENV !== 'localhost' && (
                    <p>
                        Lastly, you can ask to be invited to our developer portal &nbsp;
                        <a href={schemaLink}>{schemaLink}</a>. Contact{' '}
                        <a href="mailto:rob@congistics.com">rob@congistics.com</a> and he'll hook you up.
                    </p>
                )}
            </Box>
        </div>
    );
    /*    return (

        );*/
};
