/**
 * Created by mark on 1/3/2019.
 */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { getConfigValue, query, UserContext } from 'client-shared/utility';
import { TextField } from 'formik-mui';
import { FormQuery, CxEmployeesQuery, CxPartysQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxJob';
import inputModel from 'cb-schema/inputmodelfull/CxJob';
import { object, string, number, array } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import {
    Field,
    AutoComplete,
    DescriptionEditor,
    AddressEditor,
    addressValidationSchema,
    ContactEditor,
    contactValidationSchema,
    CurrentLifespanTimeEditor,
    currentLifespanTimeValidationSchema,
    TagEditor,
    tagValidationSchema,
    UnitQuantityEditor,
    unitQuantityValidationSchema,
    LookupValueEditor,
    TimedLookupValueEditor,
    timedLookupValueValidationSchema
} from 'client-shared/components/form';
import { Formwidget } from 'client-shared/components/attachableforms/formwidget';
import { JobActivitiesEditor, jobActivitiesValidationSchema } from './JobActivitiesEditor';
import { JobCostsEditor, jobCostsValidationSchema } from './JobCostsEditor';
import { NoteEditor } from 'client-shared/components/form';
import cloneDeep from 'lodash/cloneDeep';
import { get, flow, sortBy } from 'lodash/fp';
import { addDays, format } from 'date-fns/fp';
import { AttachmentButton } from 'client-shared/components/attachments/button';
import { Section } from 'client-shared/components/Section';
import { makeStyles } from '@mui/styles';
import { Tabs, Tab, FormControl, FormHelperText, Box, Button } from '@mui/material';
import { withEditor } from 'client-shared/entities/withEditor';
import { gql } from 'graphql-tag';
import { useApolloClient } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
    tab: {
        fontSize: 'small',
        minWidth: '10px'
    }
}));

const validationSchema = (user) => {
    const config_configValues = getConfigValue('config.editcxjob', user);
    return object({
        Name: string().min(2, 'Too Short').max(120, 'Too Long').required('Required'),
        AccountCode: string().min(1, 'Too Short').max(65, 'Too Long').required('Required'),
        Priority: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        Client: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        Manager: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        JobStatus: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        DefaultShift: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        // Types: array().required('Required'),
        // Subtypes: array().required('Required'),
        Contacts: contactValidationSchema,
        Address: addressValidationSchema,
        JobActivities: jobActivitiesValidationSchema,
        JobCosts: jobCostsValidationSchema,
        CurrentLifespan: currentLifespanTimeValidationSchema,
        Tags: tagValidationSchema,
        TotalCost: unitQuantityValidationSchema,
        ContractValue: unitQuantityValidationSchema,
        Certifications: timedLookupValueValidationSchema,
        Divisions: get('Divisions.required', config_configValues) ? array().min(1).required('Required') : undefined
    });
};

const General = ({ classes, values, setFieldValue, onOpenEditor }) => {
    return (
        <div>
            <Section>
                <div>
                    <AttachmentButton pk={values.Id} objectType="CxJob" />
                    <Button
                        variant="contained"
                        size="small"
                        style={{ marginRight: '10px' }}
                        color="primary"
                        onClick={() => {
                            onOpenEditor('EditCxForecast', {
                                formQueryValues: {
                                    filters: { name: 'job.id', values: [get('Id', values)] }
                                },
                                initialValues: {
                                    Job: { Id: get('Id', values), _DisplayName: get('DisplayName', values) }
                                }
                            });
                        }}
                        disabled={!values.Id}
                    >
                        Estimate
                    </Button>
                    <Field
                        type="text"
                        label="Job #"
                        name="AccountCode"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="text"
                        label="Name"
                        name="Name"
                        component={TextField}
                        className={classes.longTextField}
                    />
                    <Field
                        type="text"
                        label="Status"
                        name="JobStatus"
                        component={LookupValueEditor}
                        className={classes.selectField}
                        objectType="JobStatus"
                        disableClearable={true}
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Client/Company"
                        name="Client"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="_DisplayName"
                        optionsQuery={CxPartysQuery}
                        onChange={(event, value) => {
                            if (!value) {
                                return;
                            }
                            if (values.Id !== 0) {
                                return;
                            }
                            if (get('Address.Street', values) !== '') {
                                return;
                            }
                            setFieldValue('Address', {
                                ...value.Address,
                                Id: 0,
                                Autocode: true,
                                Geocode: { Id: 0, Lat: 0, Lng: 0 }
                            });
                        }}
                    />
                    <input
                        style={{ margin: '20px 25px 0px 0px' }}
                        type="button"
                        value="..."
                        onClick={() => {
                            onOpenEditor('EditCxCompany', {
                                formQueryValues: {
                                    filters: [{ name: 'Id', values: ['-1'] }]
                                }
                            });
                        }}
                    />
                    <Field
                        type="text"
                        label="Manager"
                        name="Manager"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={CxEmployeesQuery}
                        optionLabelProperty="_DisplayName"
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                            end: flow(addDays(300), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                        getOptions={(options) => {
                            return options.map((option) => ({
                                Id: option.Person.Id,
                                _DisplayName: option.Person._DisplayName
                            }));
                        }}
                    />
                    <Field
                        type="text"
                        label="Priority"
                        name="Priority"
                        component={LookupValueEditor}
                        className={classes.selectField}
                        objectType="JobPriority"
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Types"
                        name="Types"
                        component={LookupValueEditor}
                        className={classes.selectField}
                        objectType="JobType"
                        initialize={true}
                        multiple
                    />
                    <Field
                        type="text"
                        label="Subtypes"
                        name="Subtypes"
                        component={LookupValueEditor}
                        className={classes.selectField}
                        objectType="JobSubtype"
                        initialize={true}
                        multiple
                    />
                    <Field
                        type="text"
                        label="Default Shift"
                        name="DefaultShift"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String) {
                                cxShifts(filters: [{ name: "String", values: ["DisplayName", $searchString] }]) {
                                    Id
                                    _DisplayName: DisplayName
                                    _Default: Default
                                }
                            }
                        `}
                        optionLabelProperty="_DisplayName"
                        disableClearable
                        initialize={true}
                    />
                </div>
                <div>
                    <Field
                        type="color"
                        label="Color"
                        name="ForeColor"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="color"
                        label="BackColor"
                        name="BackColor"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="text"
                        label="Divisions"
                        name="Divisions"
                        component={LookupValueEditor}
                        className={classes.selectField}
                        objectType="Division"
                        initialize={false}
                        multiple
                    />
                </div>
            </Section>
            <Section title="Lifespan">
                <Field type="text" name="CurrentLifespan" component={CurrentLifespanTimeEditor} classes={classes} />
            </Section>
            <Field
                type="text"
                label="Certifications"
                name="Certifications"
                component={TimedLookupValueEditor}
                classes={classes}
                objectType="JobCertification"
                initialize={false}
                multiple
            />
            <Field type="text" label="Address" name="Address" component={AddressEditor} classes={classes} />
            <div>
                <Field
                    type="text"
                    label="Description"
                    name="Description"
                    component={DescriptionEditor}
                    classes={classes}
                />
                {values.Id > 0 && <Formwidget entityId={values.Id} clazz="CxJob" />}
                <Field
                    disabled={false}
                    type="text"
                    label="Notes"
                    name="CurrentNote"
                    component={NoteEditor}
                    classes={classes}
                />

                {/*<Field*/}
                {/*    disabled={false}*/}
                {/*    type="text"*/}
                {/*    label="Notes"*/}
                {/*    name="CurrentNote"*/}
                {/*    component={DescriptionEditor}*/}
                {/*    classes={classes}*/}
                {/*/>*/}
            </div>
        </div>
    );
};

const JobActivities = ({ classes, data }) => (
    <Box sx={{ paddingTop: '10px' }}>
        <Field
            type="text"
            label="Job Activities"
            name="JobActivities"
            component={JobActivitiesEditor}
            classes={classes}
            data={data}
        />
    </Box>
);

const ContactsAndCosts = ({ classes, data }) => (
    <Box sx={{ paddingTop: '10px' }}>
        <Field
            type="number"
            label="Estimated Labor Hours"
            name="EstimatedLaborHours"
            component={TextField}
            className={classes.shortTextField}
        />
        <Field
            type="text"
            label="Total Cost"
            name="TotalCost"
            component={UnitQuantityEditor}
            unitTypes={['Money']}
            classes={classes}
        />
        <Field
            type="text"
            label="Contract Value"
            name="ContractValue"
            component={UnitQuantityEditor}
            unitTypes={['Money']}
            classes={classes}
        />
        <Field type="text" label="Job Costs" name="JobCosts" component={JobCostsEditor} classes={classes} />
        <Field type="text" label="Contacts" name="Contacts" component={ContactEditor} classes={classes} />
        <Field
            type="text"
            name="Tags"
            component={TagEditor}
            classes={classes}
            customProperties={data.CustomPropertysJob}
        />
    </Box>
);

// const Forecast = ({ classes, values }) => {
//     const [forecastId, setForecastId] = useState(values.Id);
//     return (
//         <Box>
//             <Field
//                 type="text"
//                 label="Choose Forecast"
//                 name="_forecast"
//                 component={AutoComplete}
//                 className={classes.selectField}
//                 optionsQuery={gql`
//                     query ($searchString: String, $forecastId: String) {
//                         cxForecasts(
//                             filters: [
//                                 { name: "String", values: ["DisplayName", $searchString] }
//                                 { name: "job.id", values: [$forecastId] }
//                             ]
//                         ) {
//                             Id
//                             DisplayName
//                         }
//                     }
//                 `}
//                 optionLabelProperty="DisplayName"
//                 queryVariables={() => ({
//                     forecastId: get('Id', values)
//                 })}
//                 onChange={(event, value) => {
//                     setForecastId(get('Id', value));
//                 }}
//                 initialize={false}
//                 disableClearable
//             />
//             <EditCxForecastFlatForm formQueryValues={{ filters: { name: 'id', values: [forecastId] } }} />
//         </Box>
//     );
// };

//
const Form = ({ tab, onTab, onOpenEditor, ...props }) => {
    const { classes, data, values, setFieldValue } = props;
    const sectionClasses = useStyles();
    const showError = [];
    for (const [key, value] of Object.entries(props.errors)) {
        if (Array.isArray(value)) {
            if (value.find((v) => v !== undefined)) {
                showError.push(key);
            }
        } else {
            showError.push(key);
        }
    }
    const error = showError.length ? `There are errors in ${showError.join(', ')}` : null;
    return (
        <form noValidate autoComplete="off">
            <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={onTab}>
                        <Tab classes={{ root: sectionClasses.tab }} label="General" />
                        <Tab classes={{ root: sectionClasses.tab }} label="Job Activities" />
                        <Tab classes={{ root: sectionClasses.tab }} label="Contacts and Costs" />
                    </Tabs>
                </Box>
                <FormControl error={showError.length !== 0}>
                    <FormHelperText>{error}</FormHelperText>
                </FormControl>
                {tab === 0 && (
                    <General
                        classes={classes}
                        data={data}
                        values={values}
                        setFieldValue={setFieldValue}
                        onOpenEditor={onOpenEditor}
                    />
                )}
                {tab === 1 && <JobActivities classes={classes} data={data} />}
                {tab === 2 && <ContactsAndCosts classes={classes} data={data} />}
            </div>
        </form>
    );
};

const handleCopy = (client, formQueryValues) => async (values) => {
    const { data } = await query(client, FormQuery, {
        ...formQueryValues,
        methodsByItem: [
            {
                methods: [
                    {
                        name: 'copyasnew'
                    }
                ]
            }
        ]
    });
    return { ...values, ...get('cxJobs.0', data) };
};

export const EditCxJob_ = ({ open, formQueryValues, onClose, onOpenEditor }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const config_configValues = getConfigValue('config.editcxjob', user);
    const days =
        get('CurrentLifespan.days', config_configValues) === undefined
            ? 365
            : get('CurrentLifespan.days', config_configValues);
    const [tab, setTab] = useState(0);

    const handleTab = (event, tab) => {
        setTab(tab);
    };
    let initialValues = cloneDeep(model);
    delete initialValues.Attachments;
    initialValues.DefaultShift = {};
    initialValues.Owner.Id = get('congistics.defaultCompany.Id', user);
    initialValues.JobStatus = {};
    initialValues.Client = { Id: '0', _DisplayName: '' };
    initialValues.Manager = { Id: '0', _DisplayName: '' };
    initialValues.Priority = {};
    initialValues.Address.Autocode = true;
    initialValues.Address.State = get('congistics.defaultCompany.Address.State', user);
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'08:00:00", new Date());
    initialValues.CurrentLifespan.End = flow(addDays(days), format("yyyy-MM-dd'T'17:00:00"))(new Date());
    initialValues.StatusDue = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    initialValues.TotalCost = {
        Unit: {
            Id: 12,
            _DisplayName: 'dollar'
        },
        Quantity: 0
    };
    initialValues.ContractValue = {
        Unit: {
            Id: 12,
            _DisplayName: 'dollar'
        },
        Quantity: 0
    };
    initialValues.JobActivities = [
        {
            Completed: false,
            // SortOrder: 0,
            Id: 0,
            Name: getConfigValue('jobactivity', user) || 'Miscellaneous',
            Description: '',
            CurrentLifespan: initialValues.CurrentLifespan,
            ForeColor: '#000000',
            BackColor: '#FFFFFF',
            Tags: [],
            DefaultShift: {}
        }
    ];
    return (
        <DialogForm
            open={open}
            title="Job"
            Form={(props) => <Form onTab={handleTab} tab={tab} onOpenEditor={onOpenEditor} {...props} />}
            validationSchema={validationSchema(user)}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxJob"
            inputModel={inputModel}
            closeOnSave={false}
            onClose={onClose}
            onCopy={handleCopy(client, formQueryValues)}
            onData={(data) => {
                data.JobCosts = sortBy(['Name'], data.JobCosts);
                return data;
            }}
        />
    );
};

EditCxJob_.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

// this is needed to avoid state reset when the job editor opens the forecast editor and
// an event triggers a rerender. The rerender will reinitialize state, such as an open state for a dialog
// if the job editor component is within the child component. editors are typically instantiated above the
// parent component that uses it such as the grids.
export const EditCxJob = (props) => withEditor(EditCxJob_)(props);
