/**
 * Created by mark on 1/3/2019.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'client-shared/utility/context';
import { Field } from 'client-shared/components/form';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import { DrawerForm } from 'client-shared/components/editform';
import { get } from 'lodash/fp';
import { Actions } from './actions';
import { useApolloClient } from '@apollo/client';

const handleSubmit =
    ({ client, objectName, objectType, ids }) =>
    (properties, formikBag, touched) => {
        if (ids.length === 0) return;
        const actions = new Actions(client);
        return actions.handleBulkEdit({
            objectName: objectName,
            ids: ids,
            properties: properties,
            objectType: objectType,
            touched: touched
        });
    };

const Form = (props) => {
    return (
        <React.Fragment>
            <form autoComplete="off">
                <div>
                    <div>
                        <Field
                            type="checkbox"
                            Label={{ label: 'Approved' }}
                            name="_approved"
                            component={CheckboxWithLabel}
                        />
                    </div>
                    <div>
                        <Field type="number" label="Add/Subtract Minutes" name="_minutes" component={TextField} />
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};
export const BulkEditCxTimeEntry = ({ open, formQueryValues, ids, onClose }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const initialValues = { _approved: false, _partyId: get('congistics.user.Party.Id', user), _minutes: 0 };
    return (
        <DrawerForm
            open={open}
            title="Bulk Edit Time Entries"
            Form={Form}
            //validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxTimeEntry"
            inputModel={initialValues}
            ids={ids}
            onClose={onClose}
            onSubmit={handleSubmit({ client, objectName: 'CxTimeEntry', objectType: formQueryValues.objectType, ids })}
        />
    );
};

BulkEditCxTimeEntry.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    ids: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired
};
