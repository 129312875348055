import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($filters: [FilterInput]) {
        cxTemplateJobActivitys(filters: $filters) {
            ObjectType
            Id
            Name
            Description
            ForeColor
            BackColor
        }
    }
`;

export const CxEmployeesQuery = gql`
    query ($searchString: String, $start: String, $end: String) {
        cxEmployees(
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
            ]
        ) {
            Person {
                Id
                _DisplayName: DisplayName
            }
        }
    }
`;

export const CxPartysQuery = gql`
    query ($searchString: String) {
        cxPartys(
            objectType: "Party"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "ObjectType", values: ["Company", "Subcontractor"] }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            Address {
                Id
                Street
                StreetDetails
                City
                State {
                    Id
                    _Description: Description
                }
                ZipCode
            }
        }
    }
`;
