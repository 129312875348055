import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field } from 'client-shared/components/form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { AutoComplete } from 'client-shared/components/form';
import { array, number, object } from 'yup';
import { flow, get, map, join } from 'lodash/fp';
import format from 'date-fns/fp/format';
import { Section } from 'client-shared/components/Section';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { gql } from 'graphql-tag';

const model = {
    Id: 0,
    _DisplayName: ''
};

export const crewMemberValidationSchema = array()
    .min(1, 'At Least One Crew Member Is Required')
    .of(
        object({
            Resource: object({
                Id: number().moreThan(0, 'Required')
            })
        })
            .nullable()
            .required('Required')
    );

export const CrewMemberEditor = ({ form, field, classes }) => {
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    return (
        <Section title="Members">
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((resource, index) => {
                                    return (
                                        <div key={index}>
                                            <Field
                                                type="text"
                                                label="Resource"
                                                name={`${field.name}.${index}`}
                                                component={AutoComplete}
                                                className={classes.longTextField}
                                                optionsQuery={gql`
                                                    query ($searchString: String, $start: String) {
                                                        cxResources(
                                                            objectType: "Resource"
                                                            filters: [
                                                                {
                                                                    name: "String"
                                                                    values: ["DisplayName", $searchString]
                                                                }
                                                                { name: "Range", values: [$start] }
                                                                {
                                                                    name: "ObjectType"
                                                                    values: ["Employee", "Equipment"]
                                                                }
                                                            ]
                                                        ) {
                                                            Id
                                                            _DisplayName: DisplayName
                                                            _Crews: Crews {
                                                                DisplayName
                                                            }
                                                        }
                                                    }
                                                `}
                                                optionLabelProperty="_DisplayName"
                                                queryVariables={() => ({
                                                    start: format("yyyy-MM-dd'T'00:00:00", new Date())
                                                })}
                                                getOptions={(options) => {
                                                    return map((option) => {
                                                        const crews = flow(
                                                            map((crew) => get('DisplayName', crew)),
                                                            join(', ')
                                                        )(option._Crews);
                                                        return {
                                                            Id: option.Id,
                                                            _DisplayName: `${option._DisplayName} ${
                                                                crews.length ? '-' : ''
                                                            } ${crews}`
                                                        };
                                                    }, options);
                                                }}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

CrewMemberEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};
