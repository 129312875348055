/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { map, flow, filter } from 'lodash/fp';

export const Tags = ({ tags = [], tagNames = [], ...other }) => {
    if (!tags.length) {
        return null;
    }
    const tagList = flow(
        filter((tag) => tagNames.length === 0 || tagNames.includes(tag.Name)),
        map((tag) => {
            return (
                <Typography key={tag.Name} sx={{paddingRight: '10px'}} fontSize="small">
                    {`${tag.Name}:${tag.Value}`}
                </Typography>
            );
        })
    )(tags);

    return <span {...other}>{tagList}</span>;
};

Tags.propTypes = {
    tags: PropTypes.array,
    tagNames: PropTypes.array
};
