import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { UserContext } from 'client-shared/utility';
import { deleteKkv } from 'client-shared/utility';
import { get } from 'lodash/fp';

export const ResetFilters = ({ title }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    useEffect(() => {
        deleteKkv(client, get('userId', user), title);
        //     .then(() => {
        //     window.location.reload();
        // });
    });
    return <div>Filter error, try refreshing your browser.</div>;
};

ResetFilters.propTypes = {
    title: PropTypes.string.isRequired
};

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error, state) {
        console.log(error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ResetFilters title={this.props.title} />;
        }
        return this.props.children;
    }
}
