import React from 'react';
import PropTypes from 'prop-types';
import { CellDragDrop } from './dragdrop';
import { Typography, Box } from '@mui/material';
import { Hover } from 'client-shared/components/Hover';
import HistoryIcon from '@mui/icons-material/History';
import { get } from 'lodash/fp';

export const Crew = ({ row, onOpenEditor, onOpenGrid }) => {
    const cellDragDrop = new CellDragDrop();
    return (
        <div
            style={{ display: 'flex', whiteSpace: 'normal', overflowWrap: 'break-word' }}
            draggable={true}
            onDragStart={cellDragDrop.handleDrag([
                {
                    selectedItemProperties: { crewMember: true },
                    item: row,
                    cacheName: null,
                    selected: true
                }
            ])}
            onDoubleClick={() =>
                onOpenEditor('EditCxCrew', {
                    open: row._root,
                    formQueryValues: {
                        filters: [{ name: 'Id', values: [row.Id] }]
                    }
                })
            }
        >
            <Typography fontSize="small">
                <Box
                    component="span"
                    sx={{
                        ':hover': {
                            backgroundColor: 'lightgray'
                        }
                    }}
                >
                    <span
                        style={{
                            color: get('ForeColor', row),
                            backgroundColor: get('BackColor', row)
                        }}
                    >
                        {get('DisplayName', row)}
                    </span>
                </Box>
                <span>
                    {row._root && (
                        <span>
                            <Hover message={row.Description} />
                            <HistoryIcon
                                sx={{ fontSize: '17px' }}
                                onClick={() =>
                                    onOpenGrid('CxAllocations', {
                                        firstTimeValues: { crew_id: row.Id }
                                    })
                                }
                            />
                        </span>
                    )}
                </span>
            </Typography>
        </div>
    );
};

Crew.propTypes = {
    row: PropTypes.object.isRequired,
    onOpenEditor: PropTypes.func.isRequired,
    onOpenGrid: PropTypes.func.isRequired
};
