import { gql } from 'graphql-tag';
import { fragments } from 'client-shared/utility/fragments';

export const FormQuery = gql`
    ${fragments.CxTimeEntryPunchFragment}
    query ($objectType: String = "TimeEntry", $filters: [FilterInput]) {
        timeEntry(objectType: $objectType, filters: $filters) {
            ...CxTimeEntryPunchFragment
        }
    }
`;
