/**
 * Created by mark on 1/3/2019.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, getIn } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxResourceType';
import inputModel from 'cb-schema/inputmodelfull/CxResourceType';
import { Mutation } from 'client-shared/utility';
import { object, string, array } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import {
    AutoComplete,
    UnitEditor,
    TagEditor,
    tagValidationSchema,
    RatesEditor,
    ratesValidationSchema
} from 'client-shared/components/form';
import FormHelperText from '@mui/material/FormHelperText';
import CxResourceType from 'cb-schema/jsonschema/CxResourceType';
import cloneDeep from 'lodash/cloneDeep';
import { Section } from 'client-shared/components/Section';
import { get, map } from 'lodash/fp';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { SubtypeQuantitiesEditor } from './SubtypeQuantitiesEditor';
import { useApolloClient } from '@apollo/client';
import { pickFromTemplateFp } from '../../utility';

const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    ResourceSubtypes: array()
        .of(
            object({
                Name: string().required('Required')
            })
        )
        .min(1, 'Must have at least one Subtype'),
    Tags: tagValidationSchema,
    Rates: ratesValidationSchema
});

const getMethods = (_in, touched) => {
    const setSubtypeQuantities = {
        name: 'SetSubtypeQuantities',
        args: {
            resourceSubtypeIds: map(get('ResourceSubtype.Id'), _in.SubtypeQuantities),
            amounts: map(get('Amount'), _in.SubtypeQuantities)
        }
    };

    return {
        operation: 'edit',
        methodsByItem: [
            {
                indices: [0],
                methods: [setSubtypeQuantities]
            }
        ],
        items: [pickFromTemplateFp(inputModel, _in)]
    };
};

const handleSubmit = (client) => (formValues, formikBag, touched) => {
    const allocation = cloneDeep(formValues);
    const mutation = new Mutation(client);
    const { methodsByItem, items } = getMethods(allocation, touched);
    return mutation.save('CxResourceType', items, methodsByItem).catch((error) => console.log('save error', error));
};

const Form = (props) => {
    const { classes, values, data, errors } = props;
    const fieldError = Array.isArray(getIn(errors, 'ResourceSubtypes')) ? false : getIn(errors, 'ResourceSubtypes');
    console.log(values);
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Field
                    type="text"
                    label="Family"
                    name="Family"
                    component={AutoComplete}
                    className={classes.selectField}
                    options={get('properties.Family.enum', CxResourceType)}
                    disableClearable
                />
                <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
                <Field
                    type="text"
                    label="Description"
                    name="Description"
                    component={TextField}
                    className={classes.textField}
                />
                {/*<Field Label={{ label: 'Move Vehicle' }} name="MoveVehicle" component={CheckboxWithLabel} />*/}
                <Field type="text" label="Unit" name="Unit" component={UnitEditor} classes={classes} />
                <Field
                    type="color"
                    label="ForeColor"
                    name="ForeColor"
                    component={TextField}
                    className={classes.textField}
                />
                <Field
                    type="color"
                    label="BackColor"
                    name="BackColor"
                    component={TextField}
                    className={classes.textField}
                />
                <Field
                    type="checkbox"
                    Label={{ label: 'Allocatable' }}
                    name="Allocatable"
                    component={CheckboxWithLabel}
                />
                <Field
                    type="text"
                    name="Tags"
                    component={TagEditor}
                    classes={classes}
                    customProperties={data.cxCustomPropertys}
                />
                <Field type="text" label="Rates" name="Rates" component={RatesEditor} classes={classes} />
                <Section title="Resource Subtypes">
                    <FieldArray
                        name="ResourceSubtypes"
                        render={(arrayHelpers) => (
                            <div>
                                {values.ResourceSubtypes.map((resourceSubType, index) => (
                                    <div key={index}>
                                        <Field
                                            type="text"
                                            label="Name"
                                            name={`ResourceSubtypes.${index}.Name`}
                                            component={TextField}
                                            className={classes.textField}
                                        />
                                        <Field
                                            type="text"
                                            label="Description"
                                            name={`ResourceSubtypes.${index}.Description`}
                                            component={TextField}
                                            className={classes.textField}
                                        />
                                        <IconButton
                                            onClick={() => {
                                                arrayHelpers.remove(index);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                ))}
                                <IconButton
                                    onClick={() => {
                                        arrayHelpers.push({
                                            Id: 0,
                                            Name: '',
                                            Description: ''
                                        });
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        )}
                    />
                    {fieldError && <FormHelperText error={fieldError !== undefined}>{fieldError}</FormHelperText>}
                </Section>
                {values.Family === 'Group' && (
                    <Field
                        type="text"
                        name="SubtypeQuantities"
                        component={SubtypeQuantitiesEditor}
                        classes={classes}
                        data={data}
                        disabled={false}
                    />
                )}
            </form>
        </React.Fragment>
    );
};

export const EditCxResourceType = ({ open, formQueryValues, onClose }) => {
    const client = useApolloClient();
    let initialValues = cloneDeep(model);
    initialValues.Unit = {
        Id: '15',
        _DisplayName: 'each'
    };
    initialValues.SubtypeQuantities = [];
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    initialValues.Allocatable = true;
    return (
        <DialogForm
            open={open}
            title="Resource Type"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxResourceType"
            inputModel={inputModel}
            onClose={onClose}
            onSubmit={handleSubmit(client)}
        />
    );
};

EditCxResourceType.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
