import React, { Children } from 'react';
import { pipe, filter, curry, split, first, find, startsWith, groupBy, size } from 'lodash/fp';
import { Route, Routes } from 'react-router-dom';
import { NoRoute } from '../pages/404';
import LoadingRoute from '../pages/loading';
import { pLog } from 'global-shared/utils/utils';

/**
 * A wrapper component that checks for permissions and filters a child list.
 * @param props
 * @returns {*}
 * @constructor
 */

export const WithFeatures = ({ children, features }) => {
    return (
        <Routes>
            {pipe(Children.toArray, filter(isFeature(features)), pLog)(children)}
            {features ? <Route component={NoRoute} /> : <Route component={LoadingRoute} />}
        </Routes>
    );
};

export const isFeature = curry((features, route) => {
    const [parts, normalized] = pathToFeature(route);
    if (['sso', 'login', '', '.'].includes(normalized)) return true;
    if (size(features) === 0) return false;
    if (features.includes(normalized)) return true;
    if (parts.length === 1) return find(startsWith(`${first(parts)}.`), features);
    if (parts.length > 2) if (features.includes(`${parts[0]}.${parts[1]}`)) return true;
    if (parts.length > 1) {
        if (haveOnlyTopLevel(features, parts[0])) return true;
    }
    //console.log(features)
    //console.log('returnning false', route)

    return false;
});

const haveOnlyTopLevel = (features, top) => {
    const grouped = groupBy((each) => first(split('.', each)), features);
    if (grouped[top]) if (grouped[top].length === 1 && grouped[top][0] === top) return true;
    return false;
};

const pathToFeature = (_in) => {
    const normalized = _in[0].replace(new RegExp('/', 'g'), '.');
    const parts = split('.', normalized);
    return [parts, normalized];
};
