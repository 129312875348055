/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxJobStatus';
import inputModel from 'cb-schema/inputmodelfull/CxJobStatus';
import { object, string } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import CxJobStatus from 'cb-schema/jsonschema/CxJobStatus';
import { AutoComplete } from '../../components/form';
import { get } from 'lodash/fp';

const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Description: string().required('Required')
});

const Form = (props) => {
    const { classes } = props;
    return (
        <form noValidate autoComplete="off">
            <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
            <Field
                type="text"
                label="Description"
                name="Description"
                component={TextField}
                className={classes.textField}
            />
            <Field
                type="color"
                label="ForeColor"
                name="ForeColor"
                component={TextField}
                className={classes.textField}
            />
            <Field
                type="color"
                label="BackColor"
                name="BackColor"
                component={TextField}
                className={classes.textField}
            />
            <Field
                type="text"
                label="Job Status Type"
                name="StatusType"
                component={AutoComplete}
                className={classes.selectField}
                options={get('properties.StatusType.enum', CxJobStatus)}
                disableClearable
            />
            <Field type="checkbox" Label={{ label: 'Active' }} name="Active" component={CheckboxWithLabel} />
            <Field type="checkbox" Label={{ label: 'Default' }} name="Default" component={CheckboxWithLabel} />
        </form>
    );
};

export const EditCxJobStatus = ({ open, formQueryValues, onClose }) => {
    let initialValues = cloneDeep(model);
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    return (
        <DialogForm
            open={open}
            title="Job Status"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxJobStatus"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxJobStatus.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
