import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Filter } from './Filter';
import { set, map, get, flatten, split, curry } from 'lodash/fp';
import { pLog, renameKeys } from 'global-shared/utils/utils';
import Scheduler, {
    effectiveEntityToEvent,
    entityToEvent,
    getCalendarDays,
    getConfig
} from 'client-shared/components/timeline';
import { extractLocalConfigs, getRangeStartEnd } from 'client-shared/utility';
import { withEditor } from 'client-shared/entities/withEditor';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const Index_ = ({ onOpenEditor }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    function editCallback(e, more) {
        //console.log('>>>>>>>>>>>>>', e, more)
        const jobId = split('_', e)[0];
        onOpenEditor('EditCxJob', {
            formQueryValues: {
                filters: [{ name: 'Id', values: [jobId] }]
            }
        });
    }

    async function onEventChanged(id, event) {
        //await mutateOne(client, jobGQL, [], eventToEntity(event));
    }

    const schedulerEvents = {
        onEventChanged,
        onEventDeleted: pLog,
        onAfterFolderToggle: pLog,
        onBeforeEventChanged: (ev, e, is_new, original) => false //Don't let the job be moved vertically!
    };

    const schedulerProps = {
        editCallback,
        drag: 'target',
        master: true
    };

    return (
        <React.Fragment>
            <Filter>
                {({ values: variables, data: cxJobs, event }) => {
                    const { end } = getRangeStartEnd(variables._start, variables._periods, {
                        interval: variables._interval
                    });
                    variables.start = variables._start;
                    variables.end = end;
                    variables._periods = getCalendarDays(variables);
                    variables._x_unit = variables._interval.toLowerCase().slice(0, -1);
                    const config = getConfig(extractLocalConfigs(variables), {
                        x_size: variables._periods,
                        scrollable: variables._scrollable,
                        y_property: 'JobId'
                    });
                    const showEffectiveSpans = variables._showEffectiveSpans;
                    const mapFn = variables._showJobActivities
                        ? jobActivityToEvent(showEffectiveSpans)
                        : jobToEvent(showEffectiveSpans);
                    if (cxJobs.length < 1) return <p>doing</p>;
                    return (
                        <div className={classes.root}>
                            <Scheduler
                                height="calc(100vh - 225px)"
                                width="100%"
                                title="Job Timeline"
                                startDate={get('start', variables)}
                                data={flatten(map(mapFn, cxJobs))}
                                timelineConfig={set(
                                    'y_unit',
                                    map(renameKeys({ DisplayName: 'label', Id: 'key' }), cxJobs),
                                    config
                                )}
                                event={event}
                                {...schedulerProps}
                                {...schedulerEvents}
                            />
                        </div>
                    );
                }}
            </Filter>
        </React.Fragment>
    );
};

const Index = withEditor(Index_);

export default Index;

export const jobToEvent = curry((showEffective, _in) => ({
    text: get('DisplayName', _in),
    ...(showEffective ? effectiveEntityToEvent(_in) : entityToEvent(_in)),
    JobId: get('Id', _in)
}));

export const jobActivityToEvent = curry((showEffective, _in) => {
    return map(
        (each) => ({
            text: get('DisplayName', each),
            ...(showEffective ? effectiveEntityToEvent(each) : entityToEvent(each)),
            id: `${get('Id', _in)}_${get('Id', each)}`,
            JobId: get('Id', _in)
        }),
        _in.JobActivities
    );
});
