import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { UnitEditor } from './UnitEditor';
import { object, number } from 'yup';

/*
"UnitCost": {
    "Unit": {
        "Id": "11"
    },
    "Cost": {
        "Unit": {
            "Id": "12"
        },
        "Quantity": 0
    }
}
 */

export const unitCostValidationSchema = object({
    Cost: object({
        Quantity: number()
            .typeError('Must be currency')
            .min(0, 'Invalid negative value')
            .max(999999999, 'Must be less than $999,999,999')
    }),
    Unit: object()
        .when('Cost.Quantity', {
            is: (value) => value > 0,
            then: () =>
                object({
                    Id: number().moreThan(0, 'Required')
                })
                    .nullable()
                    .required('Required')
        })
        .nullable()
        .required('Required')
});

export const UnitCostEditor = ({ form, field, classes, label = 'Cost per Unit', unitTypes, displayName, ...other }) => {
    return (
        <React.Fragment>
            <Field
                type="number"
                label={label}
                name={`${field.name}.Cost.Quantity`}
                component={TextField}
                className={classes.textField}
            />
            <Field
                type="text"
                label="Unit"
                name={`${field.name}.Unit`}
                component={UnitEditor}
                classes={classes}
                unitTypes={unitTypes}
                displayName={displayName}
            />
        </React.Fragment>
    );
};

UnitCostEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    unitTypes: PropTypes.array
};
