import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field, ResourceSubtypeEditor } from 'client-shared/components/form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { array, number, object } from 'yup';
import { get } from 'lodash/fp';
import { Section } from 'client-shared/components/Section';
import { TextField } from 'formik-mui';
import TextFieldMUI from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export const subtypeQuantitiesValidationSchema = array().of(
    object({
        Supplier: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        ResourceSubtype: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        Amount: number().min(0.0001, 'Must be greater than 0').max(999999999, 'Must be less than 999,999,999')
    })
);

export const SubtypeQuantitiesEditor = ({
    form,
    field,
    classes,
    label = '',
    data,
    disabled,
    cache = false,
    ...other
}) => {
    const families = ['Equipment', 'Labor']; //We only do this for groups for now
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;

    console.log('>>>>>>>>>', field);

    const model = {
        _ResourceType: {
            Unit: {
                Name: ''
            }
        },
        ResourceSubtype: {
            Id: '0',
            _Name: ''
        },
        Amount: 3,
        Description: ''
    };

    // test
    return (
        <Section title="SubtypeQuantities">
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((value, index) => {
                                    return (
                                        <div key={index}>
                                            <Field
                                                type="text"
                                                label="Subtype"
                                                name={`${field.name}.${index}.ResourceSubtype`}
                                                component={ResourceSubtypeEditor}
                                                className={classes.shortSelectField}
                                                family={families}
                                                onResourceTypeChange={(value) => {
                                                    form.setFieldValue(
                                                        `${field.name}.${index}._ResourceType.Unit`,
                                                        get('Unit', value)
                                                    );
                                                }}
                                                disabled={disabled}
                                                cache={cache}
                                            />
                                            <Field
                                                type="number"
                                                label="Amount"
                                                name={`${field.name}.${index}.Amount`}
                                                component={TextField}
                                                className={classes.extraShortTextField}
                                                disabled={disabled}
                                            />
                                            <TextFieldMUI
                                                label="Unit"
                                                value={get('_ResourceType.Unit.Name', value)}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                className={classes.extraShortTextField}
                                                disabled={disabled}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                    form.setFieldTouched(field.name, true);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

SubtypeQuantitiesEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    cache: PropTypes.bool
};
