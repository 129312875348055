/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import PhoneIcon from '@mui/icons-material/Phone';
import { get } from 'lodash/fp';
const map = require('lodash/fp/map').convert({ cap: false });

const handleClick = (phoneNumber) => {
    window.location.href = 'tel:' + phoneNumber;
};

export const Contacts = ({ contacts, ...other }) => {
    const phoneNumbers = map(
        (contact) =>
            map(
                (phoneNumber, index) => (
                    <Chip
                        key={`${contact.Id}:${index}`}
                        icon={<PhoneIcon />}
                        label={`${get('DisplayName', contact)} ${phoneNumber.PhoneNumber}`}
                        size="small"
                        onClick={() => handleClick(phoneNumber.PhoneNumber)}
                    />
                ),
                contact.PhoneNumbers
            ),
        contacts
    );
    return <div {...other}>{phoneNumbers}</div>;
};

Contacts.propTypes = {
    contacts: PropTypes.array.isRequired
};
