import { useApolloClient } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { filter, get, groupBy, map, pipe, size, values } from 'lodash/fp';
import { pipeP } from 'global-shared/utils/utils';
import { setState } from 'client-shared/utility/reactHelpers';
import { getRangeStartEnd, isData, UserContext, UxContext, watchQueries } from 'client-shared/utility';
import { TextMessageEventQuery } from '../../utility/fragments';
import { textMessageQuery } from './smsHelpers';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isYesterday from 'date-fns/isYesterday';

import { addDays } from 'date-fns/fp';
import { smsAvatar } from '../../utility/avatars';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250
    }
}));

export function SmsNotifier() {
    const client = useApolloClient();

    const [anchorEl, setAnchorEl] = useState(null);
    const [user] = useContext(UserContext);
    const [toggleChat] = useContext(UxContext);
    const [messages, setMessages] = useState([]);
    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        const fetchData = async () => {
            if (!user) return { cxTextMessages: [] };
            const recipient = get('congistics.user.smsNumber', user);
            if (!recipient) return { cxTextMessages: [] };

            const result = await client.query({
                query: textMessageQuery,
                fetchPolicy: 'network-only',
                variables: {
                    filters: [
                        {
                            name: 'To.NumericPhoneNumber',
                            values: [recipient]
                        },
                        {
                            name: 'Readers.Reader.Id',
                            values: [get('congistics.user.Party.Id', user)],
                            invert: true
                        },
                        {
                            name: 'Range',
                            properties: 'Sent',
                            values: values(getRangeStartEnd(addDays(-10, new Date()), 92))
                        }
                    ]
                }
            });
            return result.data;
            //return {cxTextMessages:[{From:{NumericPhoneNumber:'55'}, Sender:{DisplayName:'Robert Moskal'}},
            //{From:{NumericPhoneNumber:'22'}, Sender:{DisplayName:''}}]}
        };
        pipeP(fetchData, setState(setMessages, get(['cxTextMessages'])))();
    }, [messages]);

    useEffect(() => {
        if (!user) return;
        const observable = watchQueries(client, [TextMessageEventQuery]);
        const subscription = observable.subscribe((data) => {
            if (isData(data)) setMessages([]);
        });
        return () => subscription.unsubscribe();
    }, [client, user]);

    const handleClick = (event) => {
        if (messages?.length < 1) {
            return toggleChat(true, get('congistics.user.smsNumber', user))(event);
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const recentMessages = filter((each) => {
        return (
            isToday(new Date(each.Sent + 'Z')) ||
            isTomorrow(new Date(each.Sent + 'Z')) ||
            isYesterday(new Date(each.Sent + 'Z'))
        );
    }, messages);

    return (
        <div>
            <IconButton color="inherit" onClick={handleClick}>
                <Badge badgeContent={recentMessages.length} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Incoming {...{ toggleChat, messages, user }} />
            </Popover>
        </div>
    );
}

export function Incoming(props) {
    const { toggleChat, messages, user } = props;

    const classes = useStyles();

    const t = (_in) => {
        return pipe(
            groupBy('From.NumericPhoneNumber'),
            map((each) => {
                return { count: size(each), from: get('0.From', each), sender: get('0.Sender.DisplayName', each) };
            })
        )(_in);
    };

    const sideList = () => (
        <div className={classes.list} role="presentation">
            <List>
                {t(messages).map(({ count, from, sender }) => (
                    <ListItem
                        key={from.NumericPhoneNumber}
                        button
                        onClick={toggleChat(true, get('congistics.user.smsNumber', user), {
                            number: from.NumericPhoneNumber,
                            label: from.NumericPhoneNumber
                        })}
                    >
                        <ListItemAvatar>
                            <Avatar {...smsAvatar(from, sender)} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${sender || from.NumericPhoneNumber}`}
                            secondary={` ${sender ? from.NumericPhoneNumber : 'Unread'} (${count})`}
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return <div>{sideList()}</div>;
}
