import React from 'react';
import { Typography } from '@mui/material';

export const NoRoute = (props) => {
    return (
        <Typography variant="h4" gutterBottom>
            Sorry... This is not the page you are looking for.
        </Typography>
    );
};
