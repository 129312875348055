import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { Filter } from './Filter';
import { map, get, filter, flow, values as valuesFp, flatMap, sortBy, minBy, maxBy } from 'lodash/fp';
import { pLog } from 'global-shared/utils/utils';
import { groupBy, parseDate, UserContext, query, fragments, createFilters } from 'client-shared/utility';
import Calendar from 'client-shared/components/calendar';
import { format, addDays, differenceInCalendarDays } from 'date-fns/fp';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import { Job } from 'client-shared/components/serverobjects/Job';
import { JobActivity } from 'client-shared/components/serverobjects/JobActivity';
import { TaskDaysByDate } from 'client-shared/components/serverobjects/TaskDays2';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { filterComparator } from './schedulehelpers';
import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

/**
 * if this filter returns true for any item in the group, the entire group of taskDays is true.
 * @param scope - determines what data a particular user can view. manager can view everything,
 * job can view the jobs the user manages.
 * @param user
 * @param showAll
 * @returns {function(*): (boolean|*)}
 */
// export const filterComparator = (scope, user, showAll) => (taskDay) => {
//     // exclude all tasks whose status name begins with an asterisk.
//     if (get('ActionStatus.Name.0', taskDay) === '*') return false;
//
//     return true;
// };

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        overflow: 'auto'
    }
}));

const groupByDateJob = {
    start: (item) => format('yyyy-MM-dd', parseDate(item.CurrentLifespan.Start)),
    id: 'Job.Id'
};

const groupByJobSegment = {
    displayName: 'Job.DisplayName',
    id: 'Job.Id',
    dateSegment: 'dateSegment'
};

const groupByDateJobActivity = {
    start: (item) => format('yyyy-MM-dd', parseDate(item.CurrentLifespan.Start)),
    id: 'JobActivity.Id'
};

const groupByJobActivitySegment = {
    displayName: 'Job.DisplayName',
    id: 'JobActivity.Id',
    dateSegment: 'dateSegment'
};

const groupByDateAllocation = {
    start: (item) => format('yyyy-MM-dd', parseDate(item.CurrentLifespan.Start)),
    id: 'Allocation.Id'
};

const groupByAllocationSegment = {
    displayName: 'Allocation.DisplayName',
    id: 'Allocation.Id',
    dateSegment: 'dateSegment'
};

const defaultColor = (color) => (color === '#FFFFFF' ? 'lightgrey' : color);

const getGroupBy = (values) => {
    if (values._level === 'Crew') {
        return [groupByDateAllocation, groupByAllocationSegment];
    } else if (values._level === 'Activity') {
        return [groupByDateJobActivity, groupByJobActivitySegment];
    }
    return [groupByDateJob, groupByJobSegment];
};

let id = 0;
const getEvent = (values, groupedTaskDayBySegment) => {
    let text, color, textColor;
    if (values._level === 'Crew') {
        text = `${get('0.Job.DisplayName', groupedTaskDayBySegment)}:${get(
            '0.Allocation.DisplayName',
            groupedTaskDayBySegment
        )}`;
        color = get('0.Crew.BackColor', groupedTaskDayBySegment);
        textColor = get('0.Crew.ForeColor', groupedTaskDayBySegment);
    } else if (values._level === 'Activity') {
        text = `${get('0.Job.DisplayName', groupedTaskDayBySegment)}:${get(
            '0.JobActivity.DisplayName',
            groupedTaskDayBySegment
        )}`;
        color = get('0.JobActivity.BackColor', groupedTaskDayBySegment);
        textColor = get('0.JobActivity.ForeColor', groupedTaskDayBySegment);
    } else {
        text = `${get('0.Job.DisplayName', groupedTaskDayBySegment)}`;
        color = get('0.Job.BackColor', groupedTaskDayBySegment);
        textColor = get('0.Job.ForeColor', groupedTaskDayBySegment);
    }
    return {
        id: id++,
        start_date: minBy('CurrentLifespan.Start', groupedTaskDayBySegment).CurrentLifespan.Start,
        end_date: maxBy('CurrentLifespan.End', groupedTaskDayBySegment).CurrentLifespan.End,
        text: text,
        color: color,
        textColor: textColor,
        jobId: get('0.Job.Id', groupedTaskDayBySegment)
    };
};

/**
 * create events by grouping taskdays by job then parsing by non-contiguous (missing a day) task days.
 * a calendar event is, {id, start_date, end_date, text, color, textColor}
 * @param scope
 * @param taskDays
 * @param user
 * @param showAll
 * @param crews
 * @returns {{allDay: boolean, jobTaskDays: *, backColor: undefined, endDate: *, title: string, foreColor: undefined, startDate: *}[]|*[]}
 */
const getEvents = (scope, taskDays, user, values) => {
    const { _level, _showAll, _mode } = values;

    let dateSegment = 1;

    let filteredTaskDays = _level === 'Crew' ? filter((taskDay) => get('Crew.Id', taskDay), taskDays) : taskDays;
    if (!filteredTaskDays.length) {
        return [];
    }
    const [groupByProperty, groupBySegment] = getGroupBy(values);
    filteredTaskDays = groupBy(filteredTaskDays, groupByProperty, filterComparator(scope, user, _showAll));
    filteredTaskDays = flow(
        valuesFp,
        flatMap((taskDays) => taskDays),
        sortBy(['Job.Id', 'CurrentLifespan.Start'])
    )(filteredTaskDays);
    if (!filteredTaskDays.length) {
        return [];
    }
    filteredTaskDays[0].dateSegment = dateSegment;
    for (let i = 0; i < filteredTaskDays.length - 1; ++i) {
        if (
            Math.abs(
                differenceInCalendarDays(
                    parseDate(filteredTaskDays[i].CurrentLifespan.Start),
                    parseDate(filteredTaskDays[i + 1].CurrentLifespan.Start)
                )
            ) > 1
        ) {
            dateSegment++;
        }
        filteredTaskDays[i + 1].dateSegment = dateSegment;
    }
    const groupedTaskDaysBySegment = groupBy(filteredTaskDays, groupBySegment);
    return flow(
        map((groupedTaskDayBySegment) => {
            return getEvent(values, groupedTaskDayBySegment);
        }),
        map((event) => {
            return {
                ...event,
                start_date: _mode === 'month' ? format('yyyy-MM-dd', parseDate(event.start_date)) : event.start_date,
                end_date:
                    _mode === 'month'
                        ? flow(addDays(1), format('yyyy-MM-dd'))(parseDate(event.end_date))
                        : event.end_date,
                color: defaultColor(event.color)
            };
        }),
        sortBy(['text'])
    )(groupedTaskDaysBySegment);
};

const taskDayQuery = gql`
    ${fragments.CxTaskDayFragment}
    query ($filters: [FilterInput]) {
        cxTaskDays(filters: $filters) {
            ...CxTaskDayFragment
        }
    }
`;

const Content = ({ open, data, onClose }) => {
    const client = useApolloClient();
    const [taskDays, setTaskDays] = useState();
    useEffect(() => {
        if (!data) {
            return;
        }
        const id = get('jobId', data);
        const filters = createFilters({
            job_id: id,
            range: [],
            _start: format('yyyy-MM-dd', data.start_date),
            _periods: differenceInCalendarDays(data.start_date, data.end_date)
        });
        (async () => {
            const { data } = await query(client, taskDayQuery, { ...filters });
            setTaskDays(get('cxTaskDays', data) || []);
        })();
    }, [data]);
    if (!taskDays) {
        return null;
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                {taskDays && (
                    <Paper style={{ padding: '10px', marginBottom: '50px' }}>
                        <Job job={get('0.Allocation.Job', taskDays)} />
                        <div>
                            <JobActivity
                                jobActivity={get('0.Allocation.JobActivity', taskDays)}
                                style={{ fontWeight: 'bold' }}
                            />
                            <TaskDaysByDate style={{ paddingLeft: '15px' }} taskDays={taskDays} />
                        </div>
                    </Paper>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

// const Scroll = ({ values, view, onSubmit }) => {
//     // const periods = {
//     //     day: 1,
//     //     week: 7,
//     //     month: 31
//     // };
//     useEffect(() => {
//         if (!view) {
//             return;
//         }
//         let values_ = { ...values };
//         values_._start = format('yyyy-MM-dd', parseDate(view.date));
//         // values._periods = get(view.mode, periods);
//         values_._mode = view.mode;
//         onSubmit(values_);
//     }, [view]);
// };

const Calendar_ = ({ setOpenContent, widgetConfig, path, filterName, scope = 'manager' }) => {
    const [user] = useContext(UserContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    function editCallback(e) {
        setOpenContent({ open: true, data: e });
    }

    async function onEventChanged(id, event) {
        //await mutateOne(client, jobGQL, [], eventToEntity(event));
    }

    // const widgetConfig = isWidget
    //     ? {
    //           header: ['date', 'prev', 'today', 'next'],
    //           height: '80%',
    //           width: '100%'
    //       }
    //     : {};

    const schedulerEvents = {
        onEventChanged,
        onEventDeleted: pLog,
        onAfterFolderToggle: pLog,
        onBeforeEventChanged: (ev, e, is_new, original) => false //Don't let the job be moved vertically!
    };

    const schedulerProps = {
        editCallback,
        drag: 'target',
        master: true,
        view: 'month'
    };
    return (
        <Filter path={path} filterName={filterName} scope={scope}>
            {({ values, data: taskDays, onSubmit }) => {
                async function onBeforeViewChange(old_mode, old_date, mode, date) {
                    //setView({ old_mode, old_date, mode, date });
                    let values_ = { ...values };
                    values_._start = format('yyyy-MM-dd', parseDate(date));
                    // values._periods = get(view.mode, periods);
                    values_._mode = mode;
                    onSubmit(values_);
                    await onSubmit(values_);
                    return true;
                }
                const events = getEvents(scope, taskDays, get('congistics.user', user), values);
                //setEvents(getEvents(scope, taskDays, get('congistics.user', user), values))
                return (
                    <div className={classes.root}>
                        {/*<Scroll values={values} view={view} onSubmit={onSubmit} />*/}
                        <Calendar
                            data={events}
                            values={values}
                            {...widgetConfig}
                            {...{ ...schedulerProps, date: parseDate(values._start), view: values._mode }}
                            {...{ ...schedulerEvents, onBeforeViewChange }}
                        />
                    </div>
                );
            }}
        </Filter>
    );
};

const MemoizedCalendar = React.memo(Calendar_);

export const JobCalendar = ({ widgetConfig, path, filterName, scope }) => {
    const [openContent, setOpenContent] = useState({ open: false, data: undefined });

    const handleClose = () => {
        setOpenContent({ open: false, data: undefined });
    };
    return (
        <React.Fragment>
            <Content open={openContent.open} onClose={handleClose} data={openContent.data} />
            <MemoizedCalendar
                setOpenContent={setOpenContent}
                widgetConfig={widgetConfig}
                path={path}
                filterName={filterName}
                scope={scope}
            />
        </React.Fragment>
    );
};

JobCalendar.propTypes = {
    widgetConfig: PropTypes.object,
    path: PropTypes.string,
    filterName: PropTypes.string,
    scope: PropTypes.string
};
