import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { instantiateBuild } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';

function build(props) {
    const { format, get, object, number, user, gql } = props;
    const validationSchema = object({
        _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(366, 'Must be less than 366 days')
    });

    const dataQuery = gql`
        query ($filters: [FilterInput]) {
            cxJobs(filters: $filters) {
                Id
                AccountCode
                DisplayName
                CurrentNote
                Description
                JobActivities {
                    Id
                    DisplayName
                }
                CurrentLifespan {
                    Start
                    End
                }
            }
        }
    `;

    return {
        title: 'Allocation Timeline',
        dataQuery,
        validationSchema,
        divisionFilter: true,
        filterProperties: ['DisplayName'],
        namedFilters: true,
        initialValues: {
            range: []
        },
        fieldDefinitions: [
            {
                type: 'text',
                name: 'scheduled',
                component: 'ScheduledEditor',
                initialValue: 'All'
            },
            {
                type: 'text',
                label: 'Manager',
                name: 'manager_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: `
                    query ($searchString: String) {
                        cxEmployees(
                            objectType: "Employee"
                            filters: [{ name: "String", values: ["DisplayName", $searchString] }]
                        ) {
                            Person {
                                Id
                                Name
                            }
                        }
                    }
                `,
                optionLabelProperty: 'Name',
                multiple: true,
                initialValue: [],
                getOptions: (options) => {
                    return options.map((option) => ({ Id: option.Person.Id, Name: option.Person.Name }));
                }
            },
            {
                type: 'text',
                label: 'Contacts',
                name: 'contacts_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxCaches(
                            objectType: "cache"
                            filters: [{ name: "String", values: ["Contacts.DisplayName", $searchString] }]
                        ) {
                            Contacts {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => {
                    return get('0.Contacts', options);
                },
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Type',
                name: 'types_id',
                component: 'LookupValueEditor',
                className: 'selectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobType'
            },
            {
                type: 'text',
                label: 'SubType',
                name: 'subTypes_id',
                component: 'LookupValueEditor',
                className: 'selectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobSubType'
            },
            {
                type: 'text',
                label: 'Status',
                name: 'jobStatus_id',
                component: 'LookupValueEditor',
                className: 'selectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobStatus'
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'text',
                label: 'Interval',
                name: '_interval',
                component: 'AutoComplete',
                options: ['Days', 'Weeks', 'Months'],
                className: 'shortSelectField',
                initialValue: 'Days',
                optionLabelProperty: 'Name'
            },
            {
                type: 'number',
                label: 'Periods',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'number',
                label: 'Timeline Spacing',
                name: '_dy',
                component: 'AutoComplete',
                initialValue: 36,
                options: [
                    { Id: 46, Name: 'Big' },
                    { Id: 36, Name: 'Comfortable' },
                    { Id: 26, Name: 'Tight' }
                ],
                className: 'shortSelectField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            },
            {
                type: 'number',
                label: 'Column Width (scrolled)',
                name: '_column_width_',
                component: 'TextField',
                className: 'textField',
                initialValue: 200
            },
            {
                type: 'checkbox',
                Label: { label: 'Scroll Timeline' },
                name: '_scrollable',
                component: 'CheckboxWithLabel',
                initialValue: true
            },
            {
                type: 'checkbox',
                Label: { label: 'Show Resource Spans' },
                name: '_showResourceSpans',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'checkbox',
                Label: { label: 'Roll-over show merged hours' },
                name: '_mergedHours',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'checkbox',
                Label: { label: 'Order by Allocations' },
                name: '_orderByAllocations',
                component: 'CheckboxWithLabel',
                initialValue: false
            }
            // {
            //     type: 'text',
            //     label: 'Timeline Units',
            //     name: '_x_unit',
            //     component: 'AutoComplete',
            //     initialValue: 'day',
            //     options: [
            //         { Id: 'day', Name: 'Days' },
            //         { Id: 'week', Name: 'Weeks' },
            //         { Id: 'month', Name: 'Months' }
            //     ],
            //     className: 'shortSelectField',
            //     optionLabelProperty: 'Name',
            //     optionIdProperty: 'Id'
            // }
        ]
    };
}

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
