import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($filters: [FilterInput]) {
        cxPlants(filters: $filters) {
            Id
            ForeColor
            BackColor
            CompanyType {
                Id
                _DisplayName: DisplayName
            }
            AccountCode
            Name
            CurrentLifespan {
                Start
                End
            }
            Capacities {
                _ResourceType: ResourceType {
                    Unit {
                        Name
                    }
                }
                ResourceSubtype {
                    Id
                    _Name: Name
                }
                Amount
            }
            Description
            Contacts {
                Id
                Name
                Title
                PhoneNumbers {
                    Id
                    Name
                    PhoneNumber
                }
                Emails {
                    Id
                    Name
                    Email
                }
            }
            Emails {
                Id
                Name
                Email
            }
            PhoneNumbers {
                Id
                Name
                PhoneNumber
            }
            Address {
                Id
                Autocode
                Street
                StreetDetails
                City
                State {
                    Id
                    _Description: Description
                }
                ZipCode
                Geocode {
                    Id
                    Lng
                    Lat
                }
            }
        }
    }
`;
