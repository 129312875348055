import React from 'react';
import PropTypes from 'prop-types';
import { CellDragDrop } from './dragdrop';
import { Typography, Box } from '@mui/material';
import { Hover } from 'client-shared/components/Hover';
import HistoryIcon from '@mui/icons-material/History';
import { get } from 'lodash/fp';
import { Expiration } from 'client-shared/components/Expiration';

export const Resource = ({ row, onOpenEditor, onOpenGrid }) => {
    const cellDragDrop = new CellDragDrop();
    return (
        <div
            style={{ display: 'flex', whiteSpace: 'normal', overflowWrap: 'break-word' }}
            draggable={true}
            onDragStart={cellDragDrop.handleDrag([
                {
                    selectedItemProperties: null,
                    item: row._root
                        ? {
                              Id: row.Id,
                              ObjectType: row.ObjectType,
                              ResourceSubtype: { Id: row.ResourceType.ResourceSubtypes[0].Id }
                          }
                        : row,
                    cacheName: null,
                    selected: true
                }
            ])}
            onDoubleClick={() =>
                onOpenEditor(`EditCx${row.ObjectType}`, {
                    open: !row._root,
                    formQueryValues: {
                        filters: [{ name: 'Id', values: [row.Id] }]
                    }
                })
            }
        >
            <Typography fontSize="small">
                {row._root && (
                    <span
                        style={{
                            color: get('ResourceType.ForeColor', row),
                            backgroundColor: get('ResourceType.BackColor', row)
                        }}
                    >
                        {get('DisplayName', row)}
                    </span>
                )}
                {!row._root && (
                    <span>
                        <Box
                            component="span"
                            sx={{
                                ':hover': {
                                    backgroundColor: 'lightgray'
                                }
                            }}
                        >
                            <span style={{ color: row.ForeColor, backgroundColor: row.BackColor }}>
                                {get('DisplayName', row)}
                            </span>
                        </Box>
                        {/*<Expiration end={get('CurrentLifespan.End', row)} />*/}
                        <Expiration
                            expirationsToCheck={[
                                { message: 'Ending in', expirationDate: get('CurrentLifespan.End', row) }
                            ]}
                        />
                        <Hover message={row.Description} />
                        <HistoryIcon
                            sx={{ fontSize: '17px' }}
                            onClick={() =>
                                onOpenGrid('CxTaskDays', {
                                    firstTimeValues: { resourceids: [row.Id] }
                                })
                            }
                        />
                    </span>
                )}
            </Typography>
        </div>
    );
};

Resource.propTypes = {
    row: PropTypes.object.isRequired,
    onOpenEditor: PropTypes.func.isRequired,
    onOpenGrid: PropTypes.func.isRequired
};
