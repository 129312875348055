import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { array, number, object } from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { addDays, format } from 'date-fns/fp';
import { flow, get } from 'lodash/fp';
import { getRangeStartEnd, parseDate } from 'client-shared/utility';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    TextField as TextFieldMUI,
    IconButton,
    Typography,
    Grid
} from '@mui/material';

export const intervalAmountsValidationSchema = array().of(
    object({
        IntervalAmount: object({
            Amount: number()
        })
    })
        .nullable()
        .required('Required')
);

const dateFormat = {
    Days: 'EEEEEE, MM/dd/yyyy',
    Weeks: 'EEEEEE, MM/dd/yyyy',
    Months: 'MMM yyyy',
    Years: 'yyyy'
};

const IntervalAmounts = ({ form, field, classes, label, interval, start, arrayHelpers }) => {
    return (
        <React.Fragment>
            <Grid container spacing={2} columns={2}>
                {field.value.map((intervalAmount, index) => {
                    return (
                        <Grid item key={index}>
                            <TextFieldMUI
                                type="text"
                                value={format(get(interval, dateFormat), parseDate(intervalAmount.Range.Start))}
                                label="Date"
                                size="small"
                                InputProps={{ sx: { fontSize: '12px' } }}
                            />
                            <Field
                                type="number"
                                label="Amount"
                                name={`${field.name}.${index}.Amount`}
                                component={TextField}
                                className={classes.ShortTextField}
                                size="small"
                                InputProps={{ sx: { fontSize: '12px' } }}
                            />
                            {field.value.length === index + 1 && (
                                <IconButton
                                    onClick={() => {
                                        arrayHelpers.remove(index);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Grid>
                    );
                })}
                {/*<IconButton*/}
                {/*    onClick={() => {*/}
                {/*        handlePush(arrayHelpers, start, field.value);*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <AddIcon />*/}
                {/*</IconButton>*/}
            </Grid>
        </React.Fragment>
    );
};

export const IntervalAmountsEditor = ({ form, field, classes, label = '', interval, ...other }) => {
    const [start, setStart] = useState(format('yyyy-MM-dd', new Date()));
    const handlePush = (arrayHelpers, start, intervalAmounts) => {
        if (!interval) {
            return;
        }
        const length = intervalAmounts.length;
        start = length ? get(`${length - 1}.Range.End`, intervalAmounts) : start;
        const range = getRangeStartEnd(start, 1, { interval: interval });
        arrayHelpers.push({
            Range: {
                Start: format("yyyy-MM-dd'T'00:00:00", parseDate(range.start)),
                End: flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(range.end)
            },
            Amount: 0
        });
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Interval Amounts</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <div>
                                <Stack direction="row" spacing={4}>
                                    <TextFieldMUI
                                        type="date"
                                        value={start}
                                        label="Start"
                                        size="small"
                                        onChange={(event) => {
                                            setStart(format('yyyy-MM-dd', parseDate(event.target.value)));
                                            form.setFieldValue(field.name, []);
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            handlePush(arrayHelpers, start, field.value);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Stack>
                                <IntervalAmounts
                                    form={form}
                                    field={field}
                                    classes={classes}
                                    label={label}
                                    interval={interval}
                                    start={start}
                                    arrayHelpers={arrayHelpers}
                                    {...other}
                                />
                            </div>
                        );
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );
};

IntervalAmountsEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    interval: PropTypes.string
};
