/**
 * Created by mark on 12/13/2022.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'client-shared/utility/context';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxTimeEntry';
import inputModel from 'cb-schema/inputmodelfull/CxTimeEntry';
import {
    Field,
    DurationEditor,
    currentLifespanValidationSchema,
    DateEditor,
    AutoComplete,
    LookupValueEditor,
    TagEditor,
    tagValidationSchema
} from 'client-shared/components/form';
import { object, number, date, ref } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import CxTimeEntry from 'cb-schema/jsonschema/CxTimeEntry';
import { AttachmentButton } from 'client-shared/components/attachments/button';
import { Section } from 'client-shared/components/Section';
import { get, flow } from 'lodash/fp';
import { addDays, differenceInMinutes, format, isSameDay } from 'date-fns/fp';
import {
    Mutation,
    pickFromTemplate,
    notApproved,
    approved,
    parseDate,
    getConfigValue,
    query
} from 'client-shared/utility';
import { gql, useApolloClient } from '@apollo/client';
import { Button } from '@mui/material';
import { SubtypeQuantitiesEditor, subtypeQuantitiesValidationSchema } from './SubtypeQuantitiesEditor';

const PUNCHTIME = '2000-01-01T00:00:00';

const validationSchema = object({
    Party: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    _Job: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    JobActivity: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    EntryType: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    // JobCosts: array()
    //     .min(1, 'Required')
    //     // .of(
    //     //     object({
    //     //         Id: number().moreThan(0, 'Required')
    //     //     })
    //     // )
    //     .nullable()
    //     .required('Required'),
    CurrentLifespan: currentLifespanValidationSchema,
    Minutes: number().moreThan(-1, 'Required'),
    PunchStart: date().test('match', 'Punch Start must match the Entry Date', function (PunchStart) {
        if (this.resolve(ref('Punch')) === 'Manual') {
            return true;
        }
        return isSameDay(parseDate(PunchStart), parseDate(this.resolve(ref('CurrentLifespan.Start'))));
    }),
    PunchEnd: date().test('match', 'Punch End must not exceed 24 hours from Punch Start', function (PunchEnd) {
        if (this.resolve(ref('Punch')) === 'Manual') {
            return true;
        }
        return differenceInMinutes(parseDate(this.resolve(ref('PunchStart'))), parseDate(PunchEnd)) < 24 * 60;
    }),
    SubtypeQuantities: subtypeQuantitiesValidationSchema,
    Tags: tagValidationSchema
});

// method
const getMethods = (timeEntry) => {
    let methods = [];
    const approve = {
        name: 'approve',
        args: {
            partyIds: [timeEntry._partyId]
        }
    };
    const unApprove = {
        name: 'unapprove',
        args: {
            partyIds: [timeEntry._partyId]
        }
    };

    // remove properties that are set by the evals.
    delete timeEntry.Approvals;
    if (timeEntry._approved) {
        methods.push(approve);
    } else {
        methods.push(unApprove);
    }
    return {
        operation: 'edit',
        methodsByItem: [
            {
                indices: [0],
                methods: methods
            }
        ],
        items: [pickFromTemplate(timeEntry, inputModel)]
    };
};

const handleSubmit = (client) => async (formValues, formikBag, touched) => {
    const timeEntry = cloneDeep(formValues);
    const mutation = new Mutation(client);
    const { methodsByItem, items } = getMethods(timeEntry, touched);
    try {
        return await mutation.save('CxTimeEntry', items, methodsByItem);
    } catch (error) {
        console.log('save error', error);
    }
};

const Form = (props) => {
    const { classes, values, setFieldValue, data } = props;
    const [user] = useContext(UserContext);
    const config_configValues = getConfigValue('config.accounting.timeentries', user);
    const partyType = get('Party.PartyType', config_configValues) || ['Employee', 'Plant', 'Subcontractor'];
    const punches = get('properties.Punch.enum', CxTimeEntry);
    const disableApproved = notApproved(values._partyId, values.Approvals) === false;
    const disabled = values._approved === true;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <div>
                    <AttachmentButton pk={values.Id} objectType="CxTimeEntry" />
                    <Field
                        type="text"
                        label="Punch"
                        name="Punch"
                        component={AutoComplete}
                        className={classes.selectField}
                        options={punches}
                        disabled={disabled}
                        disableClearable
                    />
                    <Field
                        type="text"
                        label="Resource"
                        name="Party"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String, $partyType: [String]) {
                                cxPartys(
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "PartyType", values: $partyType }
                                    ]
                                ) {
                                    Id
                                    DisplayName
                                }
                            }
                        `}
                        optionLabelProperty="DisplayName"
                        queryVariables={() => ({
                            partyType: partyType
                        })}
                        disabled={disabled}
                    />
                    <Field
                        type="text"
                        label="Job"
                        name="_Job"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="DisplayName"
                        optionsQuery={gql`
                            query ($searchString: String, $start: String, $end: String) {
                                cxJobs(
                                    objectType: "Job"
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Range", values: [$start, $end] }
                                    ]
                                ) {
                                    Id
                                    DisplayName
                                    JobActivities {
                                        Id
                                        DisplayName
                                    }
                                }
                            }
                        `}
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00", new Date()),
                            end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                        onChange={(event, value) => {
                            setFieldValue('JobCosts', []);
                            if (value) {
                                setFieldValue('JobActivity', get('JobActivities.0', value));
                            } else {
                                setFieldValue('JobActivity', { Id: '0', DisplayName: '' });
                            }
                        }}
                        disabled={disabled}
                    />
                    <Field
                        type="text"
                        label="Job Activity"
                        name="JobActivity"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="DisplayName"
                        optionsQuery={gql`
                            query ($searchString: String, $id: String) {
                                cxJobActivitys(
                                    objectType: "JobActivity"
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Job.Id", values: [$id] }
                                    ]
                                ) {
                                    Id
                                    DisplayName
                                }
                            }
                        `}
                        queryVariables={(values) => ({
                            id: get('_Job.Id', values) || '-1'
                        })}
                        disableClearable
                        disabled={disabled}
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Entry Type"
                        name="EntryType"
                        optionLabelProperty="DisplayName"
                        component={LookupValueEditor}
                        className={classes.selectField}
                        disabled={disabled}
                        disableClearable
                        objectType={'TimeEntryType'}
                    />
                    <Field
                        type="text"
                        label="Job Cost"
                        name="JobCosts"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="DisplayName"
                        initialize={false}
                        optionsQuery={gql`
                            query ($searchString: String, $id: String) {
                                cxJobs(
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Id", values: [$id] }
                                    ]
                                ) {
                                    GlobalJobCosts {
                                        Id
                                        Description
                                        DisplayName
                                    }
                                }
                            }
                        `}
                        queryVariables={(values) => ({
                            id: get('_Job.Id', values) || '-1'
                        })}
                        getOptions={(options) => get('0.GlobalJobCosts', options)}
                        // disableClearable
                        disabled={disabled}
                    />
                    <Field
                        type="text"
                        label="Labor Class"
                        name="LaborClass"
                        component={LookupValueEditor}
                        optionLabelProperty="DisplayName"
                        className={classes.selectField}
                        disabled={disabled}
                        disableClearable
                        objectType={'LaborClass'}
                        initialize={false}
                    />
                    <Field
                        type="text"
                        label="Equipment"
                        name="Auxiliaries"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="DisplayName"
                        allOptions={false}
                        optionsQuery={gql`
                            query ($searchString: String, $start: String, $end: String) {
                                cxEquipments(
                                    filters: [
                                        { name: "String", values: ["Name", $searchString] }
                                        { name: "Range", values: [$start, $end] }
                                    ]
                                ) {
                                    Id
                                    DisplayName
                                }
                            }
                        `}
                        queryVariables={(values) => ({
                            start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                            end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                        multiple
                        initialize={false}
                        disabled={disabled}
                        cache={true}
                    />
                </div>
                <div>
                    <Field
                        type="number"
                        label="Minutes"
                        name="Minutes"
                        component={DurationEditor}
                        className={classes.textField}
                        disabled={disabled}
                    />
                    {values.Punch !== 'Manual' && (
                        <React.Fragment>
                            <Field
                                type="datetime-local"
                                label="Punch Start"
                                name="PunchStart"
                                component={TextField}
                                className={classes.dateTimeField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                disabled={disabled}
                                variant="standard"
                            />
                            <Field
                                type="datetime-local"
                                label="Punch End"
                                name="PunchEnd"
                                component={TextField}
                                className={classes.dateTimeField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                disabled={disabled}
                                variant="standard"
                            />
                        </React.Fragment>
                    )}
                    {values.Punch === 'PunchedOut' && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                const minutes = differenceInMinutes(
                                    parseDate(values.PunchStart),
                                    parseDate(values.PunchEnd)
                                );
                                setFieldValue('Minutes', minutes);
                            }}
                            disabled={disabled}
                        >
                            Calc. Time
                        </Button>
                    )}
                </div>
                <div>
                    <Section title="Entry Date">
                        {/*<Field*/}
                        {/*    type="text"*/}
                        {/*    name="CurrentLifespan"*/}
                        {/*    component={CurrentLifespanEditor}*/}
                        {/*    classes={classes}*/}
                        {/*    disabled={disabled}*/}
                        {/*    onChange={(event, dateTime) => {*/}
                        {/*        setFieldValue('CurrentLifespan.End', flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(parseDate(dateTime)));*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Field
                            type="date"
                            name="CurrentLifespan.Start"
                            component={DateEditor}
                            classes={classes}
                            disabled={disabled}
                            onChange={(event, dateTime) => {
                                setFieldValue(
                                    'CurrentLifespan.End',
                                    flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(parseDate(dateTime))
                                );
                            }}
                        />
                    </Section>
                </div>
                <Field
                    type="text"
                    name="SubtypeQuantities"
                    component={SubtypeQuantitiesEditor}
                    classes={classes}
                    data={data}
                    disabled={disabled}
                />
                {/*does not work on mobile...must work offline*/}
                <Field
                    type="text"
                    name="Tags"
                    component={TagEditor}
                    classes={classes}
                    customProperties={data.cxCustomPropertys}
                />
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                        disabled={disabled}
                    />
                </div>
                <div>
                    <Field
                        type="checkbox"
                        Label={{ label: 'Approved' }}
                        name="_approved"
                        component={CheckboxWithLabel}
                        disabled={disableApproved}
                    />
                    <Field
                        type="checkbox"
                        Label={{ label: 'Void' }}
                        name="IsVoid"
                        component={CheckboxWithLabel}
                        disabled={disabled}
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

// const handleCopy = (values) => {
//     values.Id = 0;
//     values.StartLocation = { Id: 0, Lat: 0, Lng: 0 };
//     values.EndLocation = { Id: 0, Lat: 0, Lng: 0 };
//     values.Approvals = [];
//     return values;
// };

const handleCopy = (client, formQueryValues) => async (values) => {
    const { data } = await query(client, FormQuery, {
        ...formQueryValues,
        methodsByItem: [
            {
                methods: [
                    {
                        name: 'copyasnew'
                    }
                ]
            }
        ]
    });
    values._approved = false;
    return { ...values, ...get('cxTimeEntrys.0', data) };
};

export const EditCxTimeEntry = ({ open, formQueryValues, onClose }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.Punch = 'Manual';
    initialValues.PunchStart = PUNCHTIME;
    initialValues.PunchEnd = PUNCHTIME;
    initialValues.Party = {
        Id: '0',
        DisplayName: ''
    };
    initialValues.EntryType = {};
    initialValues.JobActivity = {
        Id: '0',
        DisplayName: ''
    };
    initialValues._Job = {
        Id: '0',
        DisplayName: ''
    };
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.End = flow(addDays(1), format("yyyy-MM-dd'T'00:00:00"))(new Date());
    initialValues._partyId = get('congistics.user.Party.Id', user);
    initialValues._approved = false;
    initialValues.StartLocation = { Id: 0, Lat: 0, Lng: 0 };
    initialValues.EndLocation = { Id: 0, Lat: 0, Lng: 0 };
    initialValues.LaborClass = {};
    // delete initialValues.PunchStart;
    // delete initialValues.PunchEnd;
    // delete initialValues.EntryLaborClass;
    // delete initialValues.Punch;
    delete initialValues.Cost;
    delete initialValues.StartDistance;
    delete initialValues.EndDistance;
    delete initialValues.Hours;
    delete initialValues.Owner;
    const onData = (initialValues) => {
        if (!initialValues) {
            return initialValues;
        }
        initialValues._approved = approved(initialValues._partyId, initialValues.Approvals);
        delete initialValues.Guid;
        return initialValues;
    };
    return (
        <DialogForm
            open={open}
            title="Time Entry"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxTimeEntry"
            inputModel={inputModel}
            onClose={onClose}
            onData={onData}
            onSubmit={handleSubmit(client)}
            onCopy={handleCopy(client, formQueryValues)}
        />
    );
};

EditCxTimeEntry.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
