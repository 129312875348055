/**
 * Created by mark on 1/3/2019.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    AddressEditor,
    Field,
    LookupValueEditor,
    RatesEditor,
    ratesValidationSchema,
    ResourceSubtypeEditor,
    TimedLookupValueEditor,
    timedLookupValueValidationSchema
} from 'client-shared/components/form';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxEquipment';
import inputModel from 'cb-schema/inputmodelfull/CxEquipment';
import {
    AutoComplete,
    DateEditor,
    CurrentLifespanEditor,
    UnitQuantityEditor,
    currentLifespanValidationSchema,
    unitQuantityValidationSchema,
    TagEditor,
    tagValidationSchema
} from 'client-shared/components/form';
import { object, string, number } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { UserContext } from 'client-shared/utility';
import { format, addDays } from 'date-fns/fp';
import { AttachmentButton } from 'client-shared/components/attachments/button';
import { Section } from 'client-shared/components/Section';
import { flow, get, join, map } from 'lodash/fp';
import { gql } from 'graphql-tag';
import TextFieldMUI from '@mui/material/TextField';

const validationSchema = object({
    Make: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Model: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    AccountCode: string().min(1, 'Too Short').max(65, 'Too Long').required('Required'),
    ResourceSubtype: object({
        Id: number().moreThan(0, 'Required')
    }),
    CurrentLifespan: currentLifespanValidationSchema,
    Owner: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required(),
    Height: unitQuantityValidationSchema,
    Weight: unitQuantityValidationSchema,
    Width: unitQuantityValidationSchema,
    Capacity: unitQuantityValidationSchema,
    Rates: ratesValidationSchema,
    Tags: tagValidationSchema,
    Certifications: timedLookupValueValidationSchema,
    Maintenance: timedLookupValueValidationSchema
    //Divisions: array().required('Required')
});

const Form = (props) => {
    const { classes, data, values } = props;
    const crews = flow(
        map((crew) => get('DisplayName', crew)),
        join(', ')
    )(values.Crews);
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <div>
                    <AttachmentButton pk={values.Id} objectType="CxEquipment" />
                    <Field
                        type="text"
                        label="Equipment #"
                        name="AccountCode"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="text"
                        label="Subtype"
                        name="ResourceSubtype"
                        component={ResourceSubtypeEditor}
                        className={classes.selectField}
                        family={['Equipment']}
                    />
                    <Field
                        type="checkbox"
                        Label={{ label: 'Return' }}
                        name="ReturnFromCurrentLocation"
                        component={CheckboxWithLabel}
                    />
                </div>
                <div>
                    <Field type="text" label="Make" name="Make" component={TextField} className={classes.textField} />
                    <Field type="text" label="Model" name="Model" component={TextField} className={classes.textField} />
                    <Field
                        type="text"
                        label="Serial #"
                        name="SerialNumber"
                        component={TextField}
                        className={classes.textField}
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Owner"
                        name="Owner"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String) {
                                cxPartys(
                                    objectType: "Party"
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "ObjectType", values: ["Company", "Subcontractor"] }
                                    ]
                                ) {
                                    Id
                                    _Name: Name
                                }
                            }
                        `}
                        optionLabelProperty="_Name"
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00", new Date()),
                            end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                    />
                    <Field
                        type="text"
                        label="Operators"
                        name="Auxiliaries"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String, $start: String) {
                                cxEmployees(
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Range", values: [$start] }
                                    ]
                                ) {
                                    Id
                                    _DisplayName: DisplayName
                                }
                            }
                        `}
                        optionLabelProperty="_DisplayName"
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00", new Date()),
                            end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                        multiple
                        initialize={false}
                    />
                    <Field type="date" label="Year" name="Year" component={DateEditor} className={classes.textField} />
                </div>
                <div>
                    <Field type="checkbox" Label={{ label: 'Rental' }} name="Temporary" component={CheckboxWithLabel} />
                    <Field
                        type="color"
                        label="ForeColor"
                        name="ForeColor"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="color"
                        label="BackColor"
                        name="BackColor"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="text"
                        label="Divisions"
                        name="Divisions"
                        component={LookupValueEditor}
                        className={classes.selectField}
                        objectType="Division"
                        initialize={false}
                        multiple
                    />
                </div>
                <Section title="Lifespan">
                    <Field type="text" name="CurrentLifespan" component={CurrentLifespanEditor} classes={classes} />
                </Section>
                <div>
                    <Section title="Costs and Capacities">
                        <div>
                            <Field
                                type="text"
                                label="Height"
                                name="Height"
                                component={UnitQuantityEditor}
                                classes={classes}
                                unitTypes={['Length']}
                            />
                            <Field
                                type="text"
                                label="Weight"
                                name="Weight"
                                component={UnitQuantityEditor}
                                classes={classes}
                                unitTypes={['Weight']}
                            />
                        </div>
                        <div>
                            <Field
                                type="text"
                                label="Width"
                                name="Width"
                                component={UnitQuantityEditor}
                                classes={classes}
                                unitTypes={['Length']}
                            />
                            <Field
                                type="text"
                                label="Capacity"
                                name="Capacity"
                                component={UnitQuantityEditor}
                                classes={classes}
                            />
                        </div>
                        <Field
                            type="text"
                            label="Certifications"
                            name="Certifications"
                            component={TimedLookupValueEditor}
                            classes={classes}
                            objectType="EquipmentCertification"
                        />
                        <Field
                            type="text"
                            label="Maintenance"
                            name="Maintenance"
                            component={TimedLookupValueEditor}
                            classes={classes}
                            objectType="Maintenance"
                        />
                        <div>
                            <Field type="text" label="Rates" name="Rates" component={RatesEditor} classes={classes} />
                        </div>
                    </Section>
                </div>
                <Field
                    type="text"
                    name="Tags"
                    component={TagEditor}
                    classes={classes}
                    customProperties={data.cxCustomPropertys}
                />
                <TextFieldMUI
                    label="Crews"
                    sx={{ margin: '5px' }}
                    value={crews}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: true
                    }}
                />
                <div>
                    <Field
                        type="text"
                        label="Move Notes"
                        name="MoveNotes"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
                <Field
                    type="text"
                    label="Location"
                    name="_Location.Name"
                    component={TextField}
                    classes={classes}
                    disabled={true}
                />
                <Field
                    type="text"
                    label=""
                    name="_Location.Address"
                    component={AddressEditor}
                    classes={classes}
                    oneLineFormat={true}
                    disabled={true}
                />
            </form>
        </React.Fragment>
    );
};

export const EditCxEquipment = ({ open, formQueryValues, onClose }) => {
    const [user] = useContext(UserContext);
    // initialize model properties.
    let initialValues = cloneDeep(model);
    delete initialValues.Attachments;
    initialValues._ResourceType = { Id: '0', _DisplayName: '' };
    initialValues.ResourceSubtype = { Id: '0', _Name: '' };
    initialValues.Owner = { Id: user.congistics.defaultCompany.Id, _Name: user.congistics.defaultCompany.Name };
    initialValues.Operators = [];
    initialValues.UnitCost = {
        Unit: {
            Id: '3',
            _DisplayName: 'hour'
        },
        Cost: {
            Unit: {
                Id: '12',
                _DisplayName: 'dollar'
            },
            Quantity: 0
        }
    };
    initialValues.Year = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.End = '2999-01-01T00:00:00';
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    initialValues.Width = {
        Unit: {
            Id: '5',
            _DisplayName: 'foot'
        },
        Quantity: 0
    };
    initialValues.Height = {
        Unit: {
            Id: '5',
            _DisplayName: 'foot'
        },
        Quantity: 0
    };
    initialValues.Weight = {
        Unit: {
            Id: '9',
            _DisplayName: 'ton'
        },
        Quantity: 0
    };
    initialValues.Capacity = {
        Unit: {
            Id: '9',
            _DisplayName: 'ton'
        },
        Quantity: 0
    };
    return (
        <DialogForm
            open={open}
            title="Equipment"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxEquipment"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxEquipment.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
