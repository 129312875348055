import { gql } from 'graphql-tag';

export const allocationEvent = gql`
    query {
        allocationEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const jobEvent = gql`
    query {
        jobEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const timeEntryEvent = gql`
    query {
        timeEntryEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;
