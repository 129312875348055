import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "User", $filters: [FilterInput]) {
        cxUsers(objectType: $objectType, filters: $filters) {
            Id
            ObjectType
            Active
            Party {
                Id
                _DisplayName: DisplayName
            }
            Level {
                Id
                _DisplayName: DisplayName
            }
            Features
            PasswordStatus
            Login
            Description
            Divisions {
                Id
                _DisplayName: DisplayName
            }
            #            PartyGroups {
            #                Id
            #                _DisplayName: DisplayName
            #            }
        }
        cxPartyGroups {
            Id
            Name
        }
    }
`;
