/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxToDo';
import inputModel from 'cb-schema/inputmodelfull/CxToDo';
import {
    AutoComplete,
    CurrentLifespanEditor,
    currentLifespanValidationSchema,
    Field,
    LookupValueEditor
} from 'client-shared/components/form';
import { object, string, number } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import format from 'date-fns/fp/format';
import { Section } from 'client-shared/components/Section';
import flow from 'lodash/fp/flow';
import addDays from 'date-fns/fp/addDays';
import { gql } from 'graphql-tag';

const validationSchema = object({
    Status: object({
        Id: number().moreThan(0, 'Required')
    }),
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Job: object({
        Id: number().moreThan(0, 'Required')
    }),
    CurrentLifespan: currentLifespanValidationSchema
});

const Form = (props) => {
    const { classes } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Field
                    type="text"
                    label="Status"
                    name="Status"
                    component={LookupValueEditor}
                    className={classes.selectField}
                    objectType="ToDoStatus"
                />
                {/*<Field*/}
                {/*    type="text"*/}
                {/*    label="Owner"*/}
                {/*    name="Owner"*/}
                {/*    component={AutoComplete}*/}
                {/*    className={classes.selectField}*/}
                {/*    optionsQuery={gql`*/}
                {/*        query ($searchString: String) {*/}
                {/*            cxPartys(*/}
                {/*                objectType: "Party"*/}
                {/*                filters: [{ name: "String", values: ["DisplayName", $searchString] }]*/}
                {/*            ) {*/}
                {/*                Id*/}
                {/*                _DisplayName: DisplayName*/}
                {/*            }*/}
                {/*        }*/}
                {/*    `}*/}
                {/*    optionLabelProperty="_DisplayName"*/}
                {/*/>*/}
                <Field type="text" label="Task Name" name="Name" component={TextField} className={classes.textField} />
                <Field
                    type="text"
                    label="Job"
                    name="Job"
                    component={AutoComplete}
                    className={classes.selectField}
                    optionsQuery={gql`
                        query ($searchString: String, $start: String, $end: String) {
                            cxJobs(
                                objectType: "Job"
                                filters: [
                                    { name: "String", values: ["DisplayName", $searchString] }
                                    { name: "Range", values: [$start, $end] }
                                ]
                            ) {
                                Id
                                _DisplayName: DisplayName
                            }
                        }
                    `}
                    optionLabelProperty="_DisplayName"
                    queryVariables={() => ({
                        start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                        end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                    })}
                    initialize={false}
                />
                <Field
                    type="text"
                    label="Resources"
                    name="Resources"
                    component={AutoComplete}
                    className={classes.selectField}
                    optionsQuery={gql`
                        query ($searchString: String, $start: String, $end: String) {
                            cxResources(
                                objectType: "Resource"
                                filters: [
                                    { name: "String", values: ["DisplayName", $searchString] }
                                    { name: "Range", values: [$start, $end] }
                                ]
                            ) {
                                Id
                                _DisplayName: DisplayName
                            }
                        }
                    `}
                    optionLabelProperty="_DisplayName"
                    queryVariables={() => ({
                        start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                        end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                    })}
                    multiple
                    initialize={false}
                />
                <Field
                    type="text"
                    label="Priority"
                    name="Priority"
                    component={LookupValueEditor}
                    className={classes.selectField}
                    objectType="ToDoPriority"
                />
                <Section title="Lifespan">
                    <Field type="text" name="CurrentLifespan" component={CurrentLifespanEditor} classes={classes} />
                </Section>
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="FieldNotes"
                        name="FieldNotes"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxToDo = ({ open, formQueryValues, onClose, ...other }) => {
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.Status = {};
    initialValues.Job = { Id: 0, _DisplayName: '' };
    initialValues.Resources = [];
    initialValues.Priority = {};
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.End = '2999-01-01T00:00:00';
    // test
    initialValues = { ...initialValues, ...other.formValues };

    return (
        <DialogForm
            open={open}
            title="ToDo"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxToDo"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxToDo.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
