/**
 * Created by mark on 12/3/2019.
 */
import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { object, number } from 'yup';
import format from 'date-fns/fp/format';
import {
    formatFromTemplate,
    getSelectedItems,
    parseDate,
    GRIDCACHE,
    UserContext,
    getConfigValue
} from 'client-shared/utility';
import flatMap from 'lodash/fp/flatMap';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';
import { useApolloClient } from '@apollo/client';
import { pipeP } from 'global-shared/utils/utils';
import { getSharableAttachmentLink } from 'client-shared/components/attachments/useAttachments';
import { formatContacts } from 'client-shared/components/SMSBatchSend';

const dataQuery = `
    query($filters: [FilterInput]) {
        cxRoutes(objectType: "route", filters: $filters) {
            Id
            Driver {
                Name
            }
            SMSNumbers {
                Name
                PhoneNumber
            }
            Rig {
                DisplayName
            }
            Attachments {
                Key
                Name
            }
            Stops {
                Start
                Jobs {
                    DisplayName
                    Client {
                        Name
                    }
                    Contacts {
                        Name
                        PhoneNumbers {
                            PhoneNumber
                        }
                    }
                }
                Address {
                    Street
                    StreetDetails
                    City
                    State {
                        Description
                    }
                    ZipCode
                    Geocode {
                        Id
                        Lat
                        Lng
                    }
                }
                Pickups {
                    Resources {
                        DisplayName
                    }
                    Description
                    Quantity {
                        Quantity
                        Unit {
                            Name
                        }
                    }
                }
                Dropoffs {
                    Resources {
                        DisplayName
                    }
                    Description
                    Quantity {
                        Quantity
                        Unit {
                            Name
                        }
                    }
                }
            }
        }
    }
`;

const filterFormDefinition = {
    title: 'Send Route',
    persistFilter: false,
    dataQuery: dataQuery,
    filterProperties: ['Driver.Name'],
    initialValues: {
        range: []
    },
    fieldDefinitions: [
        {
            type: 'text',
            label: 'Driver',
            name: 'driver_id',
            component: 'AutoComplete',
            className: 'selectField',
            optionLabelProperty: 'Name',
            optionsQuery: `
                query CxPartysQuery ($searchString: String) {
                    cxPartys(
                        filters: [{ name: "String", values: ["DisplayName", $searchString] }]
                    ) {
                        Id
                        Name
                    }
                }
            `,
            minLength: 0,
            initialValue: [],
            multiple: true
        },
        {
            type: 'date',
            label: ' ',
            name: '_start',
            component: 'TextField',
            className: 'textField',
            initialValue: format('yyyy-MM-dd', new Date())
        },
        {
            type: 'number',
            label: 'Days',
            name: '_periods',
            component: 'TextField',
            className: 'textField',
            initialValue: 1
        }
    ]
};

const validationSchema = object({
    _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(8, 'Must be less than 8 days')
});

const getSelectedRouteIds = (client) => {
    let ids = getSelectedItems(client, GRIDCACHE);
    ids = map((id) => id.split('_')[0], ids);
    return ids.length ? { Id: uniq(ids) } : { Id: '-1' };
};

/* eslint-disable no-template-curly-in-string*/
const defaultTemplateLiteral =
    '${date}, ' +
    '${stop.stop.Jobs.map(job => job.DisplayName).join()} ' +
    '${stop.stop.Address.Street} ' +
    '${stop.stop.Address.StreetDetails}' +
    '${stop.stop.Address.City}, ' +
    '${stop.stop.Address.State.Description} ' +
    '${formatPickups(stop.stop.Pickups, "Pickup")} \n' +
    '${formatPickups(stop.stop.Dropoffs, "Dropoff")} \n' +
    '${formatContacts(stop.stop.Jobs, "Contacts")} \n' +
    '${map}\n' +
    'Files: ${files.map(file => file.url).join("\\n")}';
const pickupTemplate = (pickup) => `\
${pickup.Resources.map((resource) => resource.DisplayName).join()} \
${pickup.Description} \
${pickup.Quantity.Quantity} \
${pickup.Quantity.Unit.Name}\
`;

const formatPickups = (pickups, label) => {
    const s = pickups.map((pickup) => pickupTemplate(pickup)).join(', ');
    return s.length ? `${label}: ${s}` : '';
};

const getRows = (templateLiteral) => (routes) => {
    const getStops = flatMap((route) => {
        // create an empty sms phone number.
        if (!route.SMSNumbers.length) {
            route.SMSNumbers = [
                {
                    Name: route.Driver.Name,
                    PhoneNumber: ''
                }
            ];
        }
        return route.Stops.map((stop) => ({
            ...route,
            stop: stop
        }));
    });

    const getMessages = (routes) =>
        map(async (route) => {
            const shortURLs = await Promise.all(
                flow(
                    map((attachment) => attachment.Key),
                    map(getSharableAttachmentLink)
                )(route.Attachments)
            );
            const message = formatFromTemplate({
                values: {
                    date: format('EEEE, MMM do hh:mm aaaa', parseDate(route.stop.Start)),
                    day: format('EEEE', parseDate(route.stop.Start)),
                    time: format('hh:mm aaaa', parseDate(route.stop.Start)),
                    stop: { route: route, stop: route.stop },
                    files: shortURLs,
                    map: `https://www.google.com/maps/search/?api=1&query=${route.stop.Address.Geocode.Lat},${route.stop.Address.Geocode.Lng}`
                },
                templateLiteral: templateLiteral,
                options: {
                    imports: {
                        formatPickups: formatPickups,
                        formatContacts: formatContacts,
                        format: format,
                        parseDate: parseDate
                    }
                }
            });
            return {
                id: route.Id,
                phoneNumber: route.SMSNumbers[0].PhoneNumber,
                name: route.SMSNumbers[0].Name,
                start: route.stop.Start,
                message: message
            };
        }, routes);
    return pipeP(getStops, getMessages, (_in) => Promise.all(_in))(routes);
};

// const GetMessages = ({ data, templateLiteral, children }) => {
//     const [messages, setMessages] = useState([]);
//     useEffect(() => {
//         getRows(templateLiteral)(data).then((data) => {
//             setMessages(data);
//         });
//     }, [data, templateLiteral]);
//
//     //getRows(templateLiteral)(data);
//     return <React.Fragment>{children({ data: messages })}</React.Fragment>;
// };

export const SMSFilter = ({ children }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const config_templateLiteral =
        getConfigValue('config.schedule.truckroutes.smsfilter', user) || defaultTemplateLiteral;
    return (
        <FilterFormFromJson
            filterFormDefinition={{
                ...filterFormDefinition,
                validationSchema: validationSchema,
                onData: getRows(config_templateLiteral),
                firstTimeValues: getSelectedRouteIds(client)
            }}
            children={children}
        />
    );
};
