import { gql, useApolloClient } from '@apollo/client';
import React, { useState, useEffect, useContext } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import { useNavigate, useParams, useLocation } from 'react-router-dom';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { makeStyles, useTheme } from '@mui/styles';
import { deleteQuery } from '../../gqlhelpers/queryHelpers';

import { Grid, Table, TableHeaderRow, Toolbar, TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';
import { TimeEntryLegend } from '../../components/timekeeping/TimeEntryLegend';

import {
    cbDateToDhtmlx,
    transformPropertyArray,
    pipeP,
    breakOnTruthy,
    setF,
    syncCollections,
    pLog
    //pLog
} from 'global-shared/utils/utils';
import { UserContext } from '../../utility';
import {
    get,
    map,
    sortBy,
    startsWith,
    curry,
    differenceBy,
    pipe,
    set,
    pick,
    filter,
    reject,
    omit,
    fromPairs,
    groupBy,
    flatten,
    toPairs,
    size,
    sumBy,
    every,
    any,
    head,
    uniqBy,
    compact
} from 'lodash/fp';
import { cleanObject } from '../../utility';
import { usePrivilege } from '../../utility';
import {
    initializeSheet,
    mutateTimesheet,
    createEmptyEntry,
    approveTimesheet,
    timeEntryToInputObject,
    cleanTimesheet
} from './crudz';
import { Detail } from './Detail';
import { useImperative } from 'client-shared/utility/useimperative';

import { TagForm } from './TagForm';
import { useNotifications } from '../../components/notifier2';
import { DialogYesNo, useModal } from '../../components/Dialogs';
import { showAttachmentDrawer } from 'client-shared/components/attachments/attachments';
import { Formwidget } from '../../components/attachableforms/formwidget';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

const isApproved = (_in) => {
    return size(get('Approvals', _in)) > 0;
};

const score = (_in) => {
    if (get('ScheduledEntries.length', _in) === 0 && get('Entries.length', _in) === 0)
        return set('score', 'not started', _in);

    if (get('Entries.length', _in) > 0 && every(isApproved, get('Entries', _in))) {
        return set('score', 'submitted', _in);
    }
    if (any(isApproved, get('Entries', _in))) return set('score', 'needs correction', _in);
    if (get('Entries.length', _in) > 0) return set('score', 'in progress', _in);
    return set('score', 'scheduled', _in);
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw'
    },
    carsChip: {
        margin: theme.spacing(0.5)
    },
    headerColumn: {
        flexBasis: '100%',
        textAlign: 'center'
    },
    footerColumn: {
        flexBasis: '100%',
        textAlign: 'left',
        paddingBottom: 40
    },
    heading: {
        fontSize: theme.typography.pxToRem(18)
    },
    icons: {
        paddingLeft: '300px'
    },
    customizedButton: {
        position: 'absolute',
        left: '90%',
        top: theme.spacing(2),
        backgroundColor: 'lightgray',
        color: 'gray'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    speedDial: {
        position: 'absolute',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(2),
            right: theme.spacing(2)
        }
    }
}));

export const Index = (props) => {
    const { projectId, forDate, crewId } = props;
    const [user] = useContext(UserContext);
    const client = useApolloClient();
    const [entryTypes, setEntryTypes] = useState([]);
    const [principals, setPrincipals] = useState([]);
    const [sheet, setSheet] = useState();
    const [privilege] = usePrivilege('TimeSheet');
    const [open, setOpen] = React.useState(false);
    const [showDetail, toggleDetail] = useImperative();
    const { openSBTransientF } = useNotifications();
    const navigate = useNavigate();
    const parentPath = useLocation().pathname.split('/').slice(0, 3).join('/');
    const theme = useTheme();
    const classes = useStyles(theme);
    const fetchEntryTypes = async () => {
        const result = await client.query({
            query: gql`
                query ($objectType: String = "LookupValue", $filters: [FilterInput]) {
                    cxLookupValues(objectType: $objectType, filters: $filters) {
                        Id
                        BackColor
                        Name
                        __typename
                    }
                    cxEmployees {
                        Id
                        DisplayName
                        Person {
                            Id
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: { objectType: 'TimeEntryType' }
        });
        setEntryTypes(sortBy('Id', get(['data', 'cxLookupValues'], result)));
        setPrincipals(
            pipe(
                get(['data', 'cxEmployees']),
                map((o) => ({ Id: o.Person.Id, DisplayName: o.DisplayName }))
            )(result)
        );
    };
    const [showRevert, toggleRevert] = useModal();

    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        fetchEntryTypes();
        initializeSheet(client, setSheet, projectId, forDate, crewId);
    }, []);

    const extractCostCode = (_in) => {
        const res = pipe(
            map(get('JobCosts')),
            map(head),
            map((o) => o || { id: -1, name: 'No JobCost' }),
            compact,
            uniqBy((each) => each.Id),
            map((each) => ({
                name: each.Id,
                title: each.Name
            }))
        )(_in);
        return res;
    };

    const entries = get('allEntries', sheet);

    const columns = [{ name: 'name', title: 'Name' }, ...extractCostCode(entries)];
    const rows = entriesToTableData(entries);
    const totals = entriesToTotals(entries);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const Cell = (props) => {
        const { column, value, row } = props;
        if (!startsWith('name', column.name)) {
            return <ChipCell {...props} />;
        }
        return (
            <Table.Cell {...props}>
                {value} / {get(get('id', row), totals)}
            </Table.Cell>
        );
    };

    const updateSheet = curry((sheet, _in) => {
        const newItems = map(timeEntryToInputObject, filter({ Id: '0' }, get('items', _in)));
        const existingItems = map(timeEntryToInputObject, reject({ Id: '0' }, get('items', _in)));
        const deletions = differenceBy('Id', get('originalValues', _in), existingItems);
        const deletionIds = map('Id', reject({ Id: '0' }, deletions));
        let candidates = [];

        if (get('items', _in) && get('items', _in).length === 0) {
            candidates = pipe(
                get('originalValues'),
                filter({ Id: '0' }),
                map(timeEntryToInputObject),
                map(set('IsVoid', true))
            )(_in);
        }

        if (deletionIds.length > 0) deleteQuery(client, 'CxTimeEntry', null, deletionIds);

        const cleanSheet = pipe(get('Entries'), map(timeEntryToInputObject), (_in) =>
            differenceBy('Id', _in, deletions)
        )(sheet);
        let entries = [...syncCollections('Id', existingItems, cleanSheet), ...newItems, ...candidates];
        //console.log(entries)
        entries = map(set('CurrentLifespan', sheet.CurrentLifespan), entries);

        return cleanTimesheet(crewId, sheet, entries);
    });

    const mutate = (_in) => {
        prepareMutation(sheet, _in);
    };

    const prepareMutation = (sheet, _in) => {
        return pipeP(updateSheet(sheet), (_in) => mutateTimesheet(client, setSheet, _in))(_in);
    };

    const addEntry = pipeP(
        () =>
            toggleDetail(mutate, {
                principals,
                modelFn: createEmptyEntry(sheet)
            }),
        handleClose
    );

    /**
     * Take all scheduled entries and make them actual
     * @returns {*}
     */
    const actualizeEntries = () =>
        pipeP(
            () => [...sheet.ScheduledEntries, ...sheet.Entries],
            map(timeEntryToInputObject),
            map(set('CurrentLifespan', sheet.CurrentLifespan)),
            cleanTimesheet(crewId, sheet),
            (_in) => mutateTimesheet(client, setSheet, _in),
            handleClose
        )(sheet);

    const resetEntries = () =>
        pipeP(
            () => [...sheet.ScheduledEntries, ...sheet.Entries],
            map(get('Id')),
            deleteQuery(client, 'CxTimeEntry', false),
            () => initializeSheet(client, setSheet, projectId, forDate, crewId),
            handleClose
        )(sheet).catch(console.error);

    const mutateTags = (_in) =>
        pipeP(
            set('Tags', map(pick(['Id', 'Value', 'CustomProperty.Id', 'ArrayValue']), _in)),
            transformPropertyArray(timeEntryToInputObject, 'Entries'),
            transformPropertyArray(set('CurrentLifespan', sheet.CurrentLifespan), 'Entries'),
            omit([
                'allEntries',
                'ScheduledEntries',
                'Description',
                '__typename',
                'ObjectType',
                'Crews',
                'Owner',
                'voided',
                'TemplateFormPool',
                'Forms'
            ]),
            setF('Job', pick('Id')),
            cleanObject,
            (_in) => mutateTimesheet(client, setSheet, _in)
        )(sheet);

    const submitSheet = () =>
        pipeP(
            breakOnTruthy((_in) => _in.ScheduledEntries.length > 0, 'All entries must be saved to submit a timesheet'),
            get('Entries'),
            map(timeEntryToInputObject),
            map(set('CurrentLifespan', sheet.CurrentLifespan)),
            cleanTimesheet(crewId, sheet),
            (_in) => approveTimesheet(get('congistics.user.Party.Id', user), client, setSheet, _in),
            handleClose
        )(sheet).catch((e) => openSBTransientF({ variant: 'warning' }, e.message));

    const ChipCell = ({ value, style, column, row, ...restProps }) => (
        <Table.Cell
            {...restProps}
            onClick={() => {
                if (privilege) return;
                toggleDetail(mutate, {
                    row,
                    column,
                    value,
                    modelFn: createEmptyEntry(sheet)
                });
            }}
            style={{
                cursor: 'pointer',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'lightgray'
            }}
        >
            {map(
                (each) => (
                    <Tooltip key={get('Id', each)} title={`${get('Id', each)}__${get('EntryType.Name', each)}`}>
                        {get('Id', each) === '0' ? (
                            <Chip
                                style={{ backgroundColor: 'white', display: 'block', cursor: 'pointer' }}
                                size="small"
                                variant="outlined"
                                label={`${get('Hours', each)} hr/${get('JobActivity.DisplayName', each)}`}
                            />
                        ) : (
                            <Chip
                                style={{
                                    backgroundColor: get('EntryType.BackColor', each),
                                    display: 'block',
                                    cursor: 'pointer'
                                }}
                                size="small"
                                label={`${get('Hours', each)} hr/${get('JobActivity.DisplayName', each)}`}
                            />
                        )}
                    </Tooltip>
                ),
                sortBy('EntryType.Id', value)
            )}
        </Table.Cell>
    );
    return (
        <Paper className={classes.root}>
            {/*
            <ButtonGroup  color="primary" size="small" aria-label="Timesheet actions">
                <IconButton
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                        padding: '8px',
                        borderRadius: '4px',
                        boxShadow: 3,
                    }}
                ><EventRepeatIcon/>Reset to Scheduled</IconButton>
                <IconButton><PersonAddIcon />Add Entry</IconButton>
                <IconButton><ShareIcon />Make Actual</IconButton>
                <IconButton><ThumbUpIcon/>Submit for Approval</IconButton>
                <IconButton><AttachFileIcon />Attach File</IconButton>
            </ButtonGroup>
*/}
            <IconButton className={classes.customizedButton} color="primary" onClick={() => navigate(parentPath)}>
                <ArrowBackIosNewIcon />
            </IconButton>
            <div className={classes.headerColumn}>
                <Typography className={classes.heading}>{get('Job.DisplayName', sheet)}</Typography>

                <Typography className={classes.heading}>{get('Crews.0.DisplayName', sheet)}</Typography>
                <Typography className={classes.heading}>
                    {cbDateToDhtmlx(get('CurrentLifespan.Start', sheet))}
                </Typography>
                <TimeEntryLegend entries={entryTypes} />
                <Typography className={classes.heading}>{get('score', score(sheet))}</Typography>
            </div>
            <div></div>
            <Detail
                state={showDetail}
                left="Save"
                data={{ sheet, entryTypes }}
                hide={toggleDetail}
                setSheet={setSheet}
            />
            <Grid rows={rows} columns={columns} getRowId={get('id')}>
                <Table
                    cellComponent={Cell}
                    messages={{
                        noData: "The job isn't scheduled for today, but you can still add time entries."
                    }}
                />

                <TableHeaderRow />
                <Toolbar />
                <TableFixedColumns leftColumns={['name']} />
            </Grid>
            <div className={classes.footerColumn}>
                {sheet && (
                    <>
                        <TagForm
                            items={get('Tags', sheet)}
                            customProperties={get('_CustomProperties', sheet)}
                            fn={mutateTags}
                        />
                        <Formwidget entityId={sheet.Id} clazz="CxTimeSheet" />
                    </>
                )}
            </div>
            <DialogYesNo
                state={showRevert}
                text="You'll lose all your edits if you do this."
                left="Cancel"
                right="OK"
                hide={toggleRevert}
            />
            <SpeedDial
                ariaLabel="Timesheet Management"
                className={classes.speedDial}
                icon={<SpeedDialIcon />}
                hidden={privilege}
                onOpen={handleOpen}
                onClose={handleClose}
                open={open}
            >
                <SpeedDialAction
                    key="4"
                    icon={<AttachFileIcon />}
                    tooltipTitle="Attach File"
                    onClick={(event) => showAttachmentDrawer(client, true, sheet.Id, 'CxTimeSheet')}
                />
                <SpeedDialAction
                    key="0"
                    icon={<ThumbUpIcon />}
                    tooltipTitle="Submit for approval"
                    onClick={submitSheet}
                />
                <SpeedDialAction key="1" icon={<ShareIcon />} tooltipTitle="Make Actual" onClick={actualizeEntries} />
                {!crewId && (
                    <SpeedDialAction key="2" icon={<PersonAddIcon />} tooltipTitle="Add entry" onClick={addEntry} />
                )}
                {sheet && sheet.Entries.length > 0 && (
                    <SpeedDialAction
                        key="3"
                        icon={<EventRepeatIcon />}
                        tooltipTitle="Reset to Scheduled"
                        onClick={() => toggleRevert((res) => (res === 'right' ? resetEntries() : handleClose()))}
                    />
                )}
                )
            </SpeedDial>
        </Paper>
    );
};

const EntryPoint = (props) => {
    let { projectId, forDate, crewId } = useParams();
    return <Index projectId={projectId} forDate={forDate} crewId={crewId} {...props} />;
};

export default EntryPoint;

/** Data Transforms...*/

export const hasCostCodes = (sheet) => {
    return size(get('Job.JobCosts', sheet)) > 0;
};

/**
 * Does the initial grouping by resources
 *
 * @_in
 */
const liftResource = map((each) => {
    //console.log('<<<<<<<<<>>>>>>>>>', each);
    return {
        id: get('Party.DisplayName', each),
        name: get('Party.DisplayName', each),
        resourceId: get('Party.Id', each),
        costCode: { id: get('JobCosts.0.Id', each), value: each }
    };
});

/**
 * Make
 * @
 */
const enkeyActivities = pipe(
    groupBy('costCode.id'),
    toPairs,
    map(([k, v]) => [k, flatten(map(get('costCode.value'), v))]),
    fromPairs
);

const entriesToTotals = pipe(
    groupBy('Party.DisplayName'),
    toPairs,
    map(([k, v]) => {
        const total = pipe(reject({ Id: '0' }), sumBy('Hours'))(v);
        return [k, total];
    }),
    fromPairs
);

const entriesToTableData = pipe(
    liftResource, //Make the resource a top level key
    groupBy('id'), //Group By User
    toPairs, //convert to [k,v] for ease of processing
    map(([k, v]) => {
        // return final collection with activity keys for grid
        return {
            id: k,
            resourceId: get('0.costCode.value.Resource.Id', v),
            name: k,
            ...enkeyActivities(v)
        };
    }),
    sortBy('id')
);
