/**
 * Created by mark on 09/28/2018.
 */

export const gridContainerStyle = (styles) => {
    const { rows = 1, columns = 1, ...other } = styles;
    return {
        display: 'grid',
        gridTemplateRows: 'auto '.repeat(rows),
        gridTemplateColumns: 'auto '.repeat(columns),
        ...other
    };
};

export const gridHeaderItemStyle = (styles) => {
    return {
        backgroundColor: 'whitesmoke',
        margin: '0px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'lightgray',
        fontWeight: 'bold',
        textAlign: 'center',
        ...styles
    };
};

export const gridItemStyle = (styles) => {
    return {
        margin: '0px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'lightgray',
        ...styles
    };
};
