import { useEffect, useContext, useState } from 'react';
import { UserContext } from './context';
import { get, find } from 'lodash/fp';

// todo: reverse meaning of privilege from this hook!! currently privilege = false if user has features.
export const usePrivilege = (objectType) => {
    const [privilege, setPrivilege] = useState(false);
    const [user] = useContext(UserContext);
    useEffect(() => {
        if (!user) {
            setPrivilege(false);
            return;
        }
        try {
            const objectPrivilege = find(
                (objectPrivilege) => objectPrivilege.ObjectType === objectType,
                get('congistics.user.ObjectPermissions', user)
            ) || { ObjectPermissions: ['Edit'] };
            const disabled = objectType === 'User' ? false : !objectPrivilege.ObjectPermissions.includes('Edit');
            setPrivilege(disabled);
        } catch (error) {
            throw new Error(error);
        }
    }, [objectType, user]);

    return [privilege];
};
