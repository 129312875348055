import { Auth } from 'aws-amplify';
import { gql } from '@apollo/client';
import { buildMobileMenus } from 'global-shared/menus';
import { cloneDeep, every } from 'lodash/fp';
import { handleFetchErrors } from './httpHelpers';
import { get, pipe, includes } from 'lodash/fp';
import { dateToCBDateExact } from 'global-shared/utils/utils';
import { todayAt } from './dateutilities';

export const userQuery = gql`
    query userQuery($userId: String!) {
        cxUsers(
            objectType: "User"
            filters: [{ name: "login", values: [$userId] }, { name: "active", values: ["true"] }]
            methodsByItem: [{ indices: [], methods: [{ name: "UpdatePasswordStatus" }] }]
        ) {
            Id
            Administrator
            DisplayName
            ObjectType
            Features
            Level {
                Id
                Name
            }
            Menus {
                Menu
                Screens {
                    Name
                    Count
                }
            }
            ObjectPermissions {
                ObjectType
                ObjectPermissions
            }
            Divisions {
                Id
                Name
                _Name: Name
                DisplayName
                Description
            }
            ResourceId
            Party {
                PartyGroups {
                    Id
                    DisplayName
                    NumericGroupNumber
                }
                Id
                Name
                DisplayName
                Emails {
                    Email
                }
                SMSGroupNumbers {
                    NumericPhoneNumber
                }
                PhoneNumbers {
                    PhoneNumber
                }
            }
        }
        cxCompanys(objectType: "Company", filters: [{ name: "default", values: ["true"] }]) {
            Id
            Name
            Address {
                State {
                    Id
                    _Description: Description
                }
                Geocode {
                    Lat
                    Lng
                }
            }
        }
        cxConfigValues {
            Key
            Value
        }
        cxShifts(objectType: "Shift", filters: [{ name: "default", values: ["true"] }]) {
            Id
            Days {
                Day
                Start
                End
            }
        }

        cxApplicationLicenses {
            Id
            Expiration
            AllowedFullUsers
            AllowedReadOnlyUsers
            AllowedMobileUsers
        }
    }
`;

export const announceUser = async (client, userId) => {
    return client.query({
        query: userQuery,
        fetchPolicy: 'network-only',
        variables: {
            userId
        }
    });
};

export const getAccessToken = () => {
    if (localStorage.getItem('APP_IN_TEST') === 'true') return Promise.resolve({ authorization: 'hey' });
    return Auth.currentAuthenticatedUser().then((user) => ({
        authorization: user.signInUserSession.accessToken.jwtToken,
        identity: user.signInUserSession.idToken.jwtToken,
        impersonating: sessionStorage.getItem('impersonating') || 'no one',
        href: window.location.href
    }));
};

/**
 * Returns true if a user is MobileUser only
 * @param the user object off the context
 */
export const isMobileOnly = (user) =>
    user ? pipe(get('congistics.user.Features'), every(includes('mobile')))(user) : false;

export const mobileMenuForUser = (user) =>
    buildMobileMenus(cloneDeep(get('customerInfo.ux.menus.mobileMenu', user)), get('congistics.user.Features', user));
/**
 * Just add our auth header to a fetch request
 * @param url
 * @param opts
 * @returns {Promise<Response>}
 */
export const pFetch = (url, opts) =>
    getAccessToken()
        .then((authHeaders) => {
            const headers = { ...get('headers', opts), ...authHeaders };
            return fetch(url, { ...opts, ...{ headers } });
        })
        .then(handleFetchErrors);

export const setNodebbSessionCookie = async (user) =>
    pFetch('/api/nodebb/makesession', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });

export const getShift = (when, user) => {
    const _shifts = get('congistics.shifts.Days', user);
    const s = _shifts[new Date(when).getDay()] || { Start: '08:00', End: '17:00' };
    return [dateToCBDateExact(todayAt(when, s.Start)), dateToCBDateExact(todayAt(when, s.End))];
};
