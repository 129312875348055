/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxRoute';
import inputModel from 'cb-schema/inputmodelfull/CxRoute';
import { object, number, array } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import {
    Field,
    AutoComplete,
    CurrentLifespanTimeEditor,
    currentLifespanTimeValidationSchema
} from 'client-shared/components/form';
import { StopsEditor, stopsValidationSchema } from './components/StopsEditor';
import cloneDeep from 'lodash/cloneDeep';
import { AttachmentButton } from 'client-shared/components/attachments/button';
import { Section } from 'client-shared/components/Section';
import { format, addDays } from 'date-fns/fp';
import { flow } from 'lodash/fp';
import { gql } from 'graphql-tag';

const validationSchema = object({
    Iterations: number().moreThan(0, 'Must be more than 0'),
    CurrentLifespan: currentLifespanTimeValidationSchema,
    Driver: object({
        Id: number().moreThan(0, 'Required')
    }),
    Rig: array()
        .min(1, 'At Least One Rig Is Required')
        .of(
            object({
                Id: number().moreThan(0, 'Required')
            })
        )
        .nullable()
        .required('Required'),
    Stops: stopsValidationSchema
});

const Form = (props) => {
    const { classes, data, values } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Section title="Route">
                    <div>
                        <AttachmentButton pk={values.Id} objectType="CxRoute" />
                        <Field
                            type="number"
                            label="Iterations"
                            name="Iterations"
                            component={TextField}
                            className={classes.extraShortTextField}
                        />
                        <Field
                            type="text"
                            name="CurrentLifespan"
                            component={CurrentLifespanTimeEditor}
                            classes={classes}
                            startLabel="Start in yard"
                            endLabel="End in yard"
                        />
                        <Field
                            type="text"
                            label="Driver"
                            name="Driver"
                            component={AutoComplete}
                            className={classes.selectField}
                            optionsQuery={gql`
                                query ($searchString: String, $start: String, $end: String) {
                                    cxPartys(
                                        objectType: "Party"
                                        filters: [
                                            { name: "String", values: ["DisplayName", $searchString] }
                                            { name: "Range", values: [$start, $end] }
                                            { name: "PartyType", values: ["Employee", "Subcontractor"] }
                                        ]
                                    ) {
                                        Id
                                        _DisplayName: DisplayName
                                    }
                                }
                            `}
                            optionLabelProperty="_DisplayName"
                            queryVariables={() => ({
                                start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                                end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                            })}
                        />
                        <Field
                            type="text"
                            label="Rig"
                            name="Rig"
                            component={AutoComplete}
                            className={classes.selectField}
                            optionsQuery={gql`
                                query ($searchString: String, $start: String, $end: String) {
                                    cxEquipments(
                                        objectType: "Equipment"
                                        filters: [
                                            { name: "String", values: ["DisplayName", $searchString] }
                                            { name: "Range", values: [$start, $end] }
                                        ]
                                    ) {
                                        Id
                                        _DisplayName: DisplayName
                                    }
                                }
                            `}
                            optionLabelProperty="_DisplayName"
                            queryVariables={() => ({
                                start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                                end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                            })}
                            multiple
                            initialize={false}
                        />
                        <Field
                            type="color"
                            label="ForeColor"
                            name="ForeColor"
                            component={TextField}
                            className={classes.textField}
                        />
                    </div>
                </Section>
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
                <div style={{ paddingTop: '10px' }}>
                    <Field
                        type="text"
                        label="Stops"
                        name="Stops"
                        component={StopsEditor}
                        classes={classes}
                        data={data}
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxRoute = ({ open, formQueryValues, onClose }) => {
    let initialValues = cloneDeep(model);
    initialValues.Iterations = 1;
    initialValues.Driver = {
        Id: 0,
        _DisplayName: ''
    };
    initialValues.Rig = [];
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'08:00:00", new Date());
    initialValues.CurrentLifespan.End = format("yyyy-MM-dd'T'17:00:00", new Date());
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    return (
        <DialogForm
            open={open}
            title="Route"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxRoute"
            inputModel={inputModel}
            closeOnSave={false}
            onClose={onClose}
        />
    );
};

EditCxRoute.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
