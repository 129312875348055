/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Field, LookupValueEditor } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { CxEmployeesQuery, FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxJob';
import inputModel from 'cb-schema/inputmodelfull/CxJob';
import { DrawerForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { Section } from 'client-shared/components/Section';
import { format, addDays } from 'date-fns/fp';
import { flow, pick } from 'lodash/fp';

const Form = (props) => {
    const { classes } = props;
    return (
        <React.Fragment>
            <form autoComplete="off">
                <div>
                    <Section title="General">
                        <Field
                            type="text"
                            label="Status"
                            name="JobStatus"
                            component={LookupValueEditor}
                            className={classes.selectField}
                            objectType="JobStatus"
                            initialize={false}
                        />
                        <Field
                            type="text"
                            label="Manager"
                            name="Manager"
                            component={AutoComplete}
                            className={classes.selectField}
                            optionsQuery={CxEmployeesQuery}
                            optionLabelProperty="_DisplayName"
                            queryVariables={() => ({
                                start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                                end: flow(addDays(300), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                            })}
                            getOptions={(options) => {
                                return options.map((option) => ({
                                    Id: option.Person.Id,
                                    _DisplayName: option.Person._DisplayName
                                }));
                            }}
                            initialize={false}
                        />
                        <Field
                            type="text"
                            label="Priority"
                            name="Priority"
                            component={LookupValueEditor}
                            className={classes.selectField}
                            objectType="JobPriority"
                            initialize={false}
                        />
                        <Field
                            type="text"
                            label="Types"
                            name="Types"
                            component={LookupValueEditor}
                            className={classes.selectField}
                            objectType="JobType"
                            multiple
                            initialize={false}
                        />
                        <Field
                            type="text"
                            label="Subtypes"
                            name="Subtypes"
                            component={LookupValueEditor}
                            className={classes.selectField}
                            objectType="JobSubtype"
                            multiple
                            initialize={false}
                        />
                        <Field
                            type="text"
                            label="Certifications"
                            name="Certifications"
                            component={LookupValueEditor}
                            className={classes.selectField}
                            objectType="JobCertification"
                            multiple
                            initialize={false}
                        />
                        <Field
                            type="color"
                            label="Color"
                            name="ForeColor"
                            component={TextField}
                            className={classes.textField}
                        />
                        <Field
                            type="text"
                            label="Divisions"
                            name="Divisions"
                            component={LookupValueEditor}
                            className={classes.selectField}
                            objectType="Division"
                            multiple
                            initialize={false}
                        />
                    </Section>
                    <Section title="Lifespan">
                        <Field
                            type="datetime-local"
                            label="Start"
                            name="CurrentLifespan.Start"
                            component={TextField}
                            className={classes.dateTimeField}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <Field
                            type="datetime-local"
                            label="End"
                            name="CurrentLifespan.End"
                            component={TextField}
                            className={classes.dateTimeField}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Section>
                </div>
            </form>
        </React.Fragment>
    );
};
export const BulkEditCxJob = ({ open, formQueryValues, ids, onClose }) => {
    let initialValues = cloneDeep(model);
    initialValues = pick(['Types', 'Subtypes', 'Certifications', 'Divisions', 'CurrentLifespan'], initialValues);
    // initialValues.JobStatus = {};
    // initialValues.Manager = { Id: '0', _DisplayName: '' };
    // initialValues.Priority = {};
    // initialValues.ForeColor = '#000000';

    return (
        <DrawerForm
            open={open}
            title="Bulk Edit Job"
            Form={Form}
            //validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxJob"
            inputModel={inputModel}
            ids={ids}
            onClose={onClose}
        />
    );
};

BulkEditCxJob.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    ids: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired
};
