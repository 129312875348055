/**
 *
 *
 * Copyright 2002-2018 Congistics Corporation
 */
/*todo: expandable might replace?*/
import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const styles = {
    nested: {
        paddingLeft: 1
    },
    listItem: {
        paddingLeft: 0,
        paddingTop: 2,
        paddingBottom: 4,
        fontSize: '14px'
    },
    button: {
        paddingTop: 0,
        paddingRight: '5px'
    },
    icon: {
        fontSize: '15px'
    }
};

export const Accordion = ({ items, unmountOnExit = false, children }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
    };
    const ExpandMoreButton = ({ handleExpand }) => (
        <IconButton sx={styles.button} aria-label="Expand" size="small" onClick={handleExpand}>
            <ExpandMore sx={styles.icon} />
        </IconButton>
    );
    const ExpandLessButton = ({ handleExpand }) => (
        <IconButton sx={styles.button} aria-label="Collapse" size="small" onClick={handleExpand}>
            <ExpandLess sx={styles.icon} />
        </IconButton>
    );
    return (
        <React.Fragment>
            <div style={{ display: 'flex', padding: 0, margin: 0 }}>
                {expanded ? (
                    <ExpandLessButton handleExpand={handleChange} />
                ) : (
                    <ExpandMoreButton handleExpand={handleChange} />
                )}
                {items}
            </div>
            <Collapse in={expanded} sx={styles.nested} unmountOnExit={unmountOnExit}>
                {children}
            </Collapse>
        </React.Fragment>
    );
};

Accordion.propTypes = {
    items: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    unmountOnExit: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};
