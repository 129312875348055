/**
 * Created by mark on 2/28/2021.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { object } from 'yup';
import { FormContainer } from 'client-shared/components/form';
import { usePrivilege, parseDate } from 'client-shared/utility';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { flow } from 'lodash/fp';
import { format, addDays } from 'date-fns/fp';

export const validationSchema = object({});

const Form = (props) => {
    const { classes } = props;
    return (
        <form noValidate autoComplete="off">
            <Field
                type="date"
                label="Start"
                name="start"
                component={TextField}
                className={classes.dateTimeField}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <Field
                type="date"
                label="End"
                name="end"
                component={TextField}
                className={classes.dateTimeField}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <Field
                type="checkbox"
                Label={{ label: 'Reset Partial Amount', labelPlacement: 'start' }}
                name="resetPartials"
                component={CheckboxWithLabel}
                sx={{ paddingLeft: '25px' }}
            />
        </form>
    );
};

export const EditResolveConflicts = ({ actions, open, onClose }) => {
    const [privilege] = usePrivilege('Allocation');
    let _submitForm;
    const getSubmitForm = (submitForm) => {
        _submitForm = submitForm;
    };
    const submitForm = () => {
        if (_submitForm) {
            _submitForm();
        }
    };
    return (
        <Dialog open={open}>
            <DialogContent>This will resolve all allocation conflicts within the range.</DialogContent>
            <DialogContent>
                <FormContainer
                    Form={Form}
                    initialValues={{
                        start: format('yyyy-MM-dd', new Date()),
                        end: flow(parseDate, addDays(1), format('yyyy-MM-dd'))(new Date()),
                        resetPartials: true
                    }}
                    data={{}}
                    onSubmit={(formValues) => {
                        actions.handleResolveConflicts(formValues);
                        onClose();
                    }}
                    getSubmitForm={getSubmitForm}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitForm} color="primary" disabled={privilege}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditResolveConflicts.propTypes = {
    actions: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
