import { gql } from 'graphql-tag';

export const AllocationsQuery = gql`
    query ($resourceIds: [String!] = [], $start: String!, $end: String!) {
        cxAllocations(
            objectType: "Allocation"
            filters: [{ name: "ResourceIds", values: $resourceIds }, { name: "Range", values: [$start, $end] }]
        ) {
            ObjectType
            Id
            JobActivity {
                Id
            }
            ForeColor
            BackColor
            Job {
                Id
                DisplayName
                BackColor
                ForeColor
            }
            ResourceSpans {
                Range {
                    Start
                    End
                }
                Principal {
                    DisplayName
                    Id
                    ResourceType {
                        DisplayName
                        Name
                        Family
                    }
                    ResourceSubtype {
                        Description
                    }
                }
                Auxiliaries {
                    DisplayName
                    Id
                    ResourceType {
                        Id
                        Family
                    }
                }
            }
        }
    }
`;
