import { gql } from 'graphql-tag';

export const ResourcesQuery = gql`
    query ($filters: [FilterInput]) {
        cxResources(objectType: "Resource", filters: $filters) {
            Id
            ResourceType {
                Id
                DisplayName
            }
            CurrentLifespan {
                Start
                End
            }
        }
    }
`;
