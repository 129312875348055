import React from 'react';
import PropTypes from 'prop-types';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { ExcelIcon } from './Icons';
import IconButton from '@mui/material/IconButton';
import { get } from 'lodash/fp';
// import { filter, flow, get } from 'lodash/fp';
const map = require('lodash/fp/map').convert({ cap: false });

/**
 * populate each row of the spreadsheet.
 * @param values
 * @param allocations
 * @returns {(function(*, *, *, *): (*|undefined))|*}
 */
const _addExcelRow = ({ row, rowNumber, columns, workSheet, addExcelCell }) => {
    const workSheetRow = workSheet.addRow(Array(columns.length).fill(''));
    workSheetRow.eachCell((cell, colNumber) => {
        const column = get(colNumber - 1, columns);
        const colName = get('name', column);
        cell.alignment = { wrapText: true };
        addExcelCell({ row, rowNumber, column, colNumber, colName, cell });
    });
};

/**
 *
 * @param rows - the collection to be exported. example: [['a', 'b'], ['c', 'd'], ...]. where each row is
 * an array of column values.
 * @param fileName - optional file name of exported excel spreadsheet.
 * @param addExcelRow - optional function to return an array of row columns.
 * @param addExcelCell - function to format the cell. it is passed a row, row number, column, column number,
 * column name, cell reference for the row number and column number. refer to exceljs for the cell reference documentation.
 * @param getColumns - the collection of column titles and names. example:
 * const columns - [{ title: 'column1',  name: 'col1' }, title: 'column2',  name: 'col2' }, ...]
 */
const excelExport = ({
    rows = [],
    fileName = 'DataGrid.xlsx',
    addExcelRow = _addExcelRow,
    addExcelCell,
    getColumns
}) => {
    const workBook = new ExcelJS.Workbook();
    const workSheet = workBook.addWorksheet();
    workSheet.properties.defaultColWidth = 20;
    // workSheet.columns = columns;
    map((row, rowNumber) => {
        addExcelRow({ row, rowNumber, columns: getColumns(row), workSheet, addExcelCell });
    }, rows);
    workBook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
    });
};

export const ExcelExport = ({ rows, fileName, addExcelRow, addExcelCell, getColumns }) => {
    const handleExcelExport = () => {
        excelExport({ rows, fileName, addExcelRow, addExcelCell, getColumns });
    };
    return (
        <IconButton size="small" onClick={handleExcelExport}>
            <ExcelIcon />
        </IconButton>
    );
};

ExcelExport.propTypes = {
    rows: PropTypes.array.isRequired,
    fileName: PropTypes.string,
    addExcelRow: PropTypes.func,
    addExcelCell: PropTypes.func.isRequired,
    getColumns: PropTypes.func.isRequired
};
