import React, { useState, useRef, useContext } from 'react';
import { GridDialog } from './index';
import { UserContext, UxContext } from '../utility/context';

export const withGrid = (Component) => (props) => {
    const [grid, setOpenGrid] = useState({ grid: undefined, props: {} });
    const [user] = useContext(UserContext);
    const [toggleChat] = useContext(UxContext);

    const onClose = useRef(() => null);
    const handleOpenGrid = (grid, props, onCloseCallBack) => {
        onClose.current = onCloseCallBack;
        setOpenGrid({ grid: grid, props: props });
    };

    const handleCloseGrid = () => {
        setOpenGrid({ grid: undefined, props: {} });
        if (onClose.current) {
            onClose.current();
        }
    };
    return (
        <React.Fragment>
            <GridDialog open={!!grid.grid} gridName={grid.grid} onClose={handleCloseGrid} {...grid.props} />
            <Component user={user} toggleChat={toggleChat} onOpenGrid={handleOpenGrid} {...props} />
        </React.Fragment>
    );
};
