import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { getReportFragment } from './reporthelpers';
import { query } from './usequery';
import { createFilters } from './queryfilter';
import { getRangeStartEnd, parseDate } from './dateutilities';
import { flow, get } from 'lodash/fp';
import { format, addDays } from 'date-fns/fp';
import { gql } from 'graphql-tag';
import PropTypes from 'prop-types';

const dataQuery = (cxReportFragment) => gql`
    query ($filters: [FilterInput], $methodsByItem: [methodsByItemInput]) {
        cxCaches(filters: $filters, methodsByItem: $methodsByItem) {
            ReportIntervals {
                Report {
                    ${cxReportFragment}
                }
                Range {
                    Start
                    End
                }
                __typename
            }
        }
    }
`;

const getFilters = ({ start, periods, interval, stratify, values }) => {
    const _values = {
        ...values,
        _start: start,
        _periods: periods,
        _interval: interval,
        _stratify: stratify
        // range__child__taskdays: [],
        // range__child__allocations: []
    };
    return createFilters(_values);
};

const getMethodsByItem = ({ start, periods, interval, stratify, absoluteStart }) => {
    const rangeStartEnd = getRangeStartEnd(start, periods, {
        interval: interval,
        periodMultiple: stratify ? 7 : undefined,
        absoluteStart
    });
    const methodsByItem = [
        {
            methods: [
                {
                    name: 'MakeReportIntervals',
                    args: {
                        interval: interval,
                        start: rangeStartEnd.start,
                        end: flow(addDays(1), format('yyyy-MM-dd'))(parseDate(rangeStartEnd.end))
                    }
                }
            ]
        }
    ];
    return { methodsByItem: methodsByItem };
};

export const useReportTotals = ({ reportProperties, start, periods, interval, stratify, values, ...rest }) => {
    const client = useApolloClient();
    const [reportIntervals, setReportIntervals] = useState([]);
    values = { ...values, ...rest };
    /*eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        let unmounted = false;
        (async () => {
            try {
                // if (!reportProperties.length) {
                //     return;
                // }
                const filters = getFilters({ start, periods, interval, stratify, values });
                const methodsByItems = getMethodsByItem({
                    start,
                    periods,
                    interval,
                    stratify,
                    absoluteStart: values._absoluteStart
                });

                const { data } = await query(
                    client,
                    dataQuery(getReportFragment(reportProperties)),
                    { ...filters, ...methodsByItems },
                    'network-only'
                );
                if (!unmounted) {
                    setReportIntervals(get('cxCaches.0.ReportIntervals', data));
                }
            } catch (error) {
                console.log(error);
            }
        })();
        return () => {
            unmounted = true;
        };
    });
    //
    return [reportIntervals];
};

useReportTotals.propTypes = {
    reportProperties: PropTypes.array.isRequired,
    start: PropTypes.string.isRequired,
    periods: PropTypes.number.isRequired,
    interval: PropTypes.string.isRequired,
    stratify: PropTypes.bool,
    values: PropTypes.object
};
