/* eslint-disable react-hooks/exhaustive-deps*/
import { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { putKkv, getKkv, deleteKkv } from './domainkkv';
import { UserContext } from './context';
import { get, omit, keys, pick } from 'lodash/fp';

/**
 *
 * Return the persisted values.
 * @param componentName - key in store
 * @param values - values to store
 * @param initializeComparator - optional function to compare the persisted values with the values passed to this function.
 * if it returns true then return the values passed to this function,otherwise, return the persisted values.
 * @returns {[unknown,((function(*): Promise<void>)|*),resetValues,boolean,((function(): Promise<void>)|*)]}
 */
export const usePersistFilter = (
    componentName,
    values,
    initializeComparator = (persistedValues, values) => (persistedValues ? false : true),
    k
) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const [persistedValues, setPersistedValues] = useState(values);
    const [loading, setLoading] = useState(true);

    const setValues = async (values, propertiesNotPersisted = [], k = get('userId', user)) => {
        if (componentName && user) {
            try {
                await putKkv(client, {
                    k: k,
                    kk: componentName,
                    data: omit(propertiesNotPersisted, values)
                });
            } catch (error) {
                console.log(error);
            }
        }
        setPersistedValues(values);
    };

    const deleteValues = async () => {
        if (componentName && user) {
            try {
                await deleteKkv(client, get('userId', user), componentName);
            } catch (error) {
                console.log(error);
            }
        }
        //setPersistedValues(undefined);
    };

    const resetValues = () => {
        setPersistedValues(values);
    };

    useEffect(() => {
        (async () => {
            if (componentName && user) {
                const data = await getKkv(client, k || get('userId', user), componentName);
                let persistedValues = get('kkvFetch.0.data', data);
                // remove persistedValues properties not in values.
                persistedValues = pick(keys(values), persistedValues);
                if (initializeComparator(persistedValues, values)) {
                    setPersistedValues(values);
                } else {
                    setPersistedValues({ ...values, ...persistedValues });
                }
                setLoading(false);
                return;
            }
            setPersistedValues(values);
            setLoading(false);
        })();
    }, [componentName]);
    return [persistedValues, setValues, resetValues, loading, deleteValues];
};
