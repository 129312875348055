/**
 * Created by mark on 11/2/2020.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Field, KVEditor } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxCustomProperty';
import inputModel from 'cb-schema/inputmodelfull/CxCustomProperty';
import CxCustomProperty from 'cb-schema/jsonschema/CxCustomProperty';
import { object, string } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { get, set, includes } from 'lodash/fp';

const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Description: string().required('Required')
});

const decoratedInputModel = set('CustomLookups.0.Description', '', inputModel);

const Form = (props) => {
    const { classes, values } = props;
    // const supportedTypes = CxCustomProperty.properties.SupportedTypes.items.enum.map((supportedType, index) => (
    //     <MenuItem key={index} value={supportedType}>
    //         {supportedType}
    //     </MenuItem>
    // ));

    return (
        <form noValidate autoComplete="off">
            <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
            <Field
                type="text"
                label="Description"
                name="Description"
                component={TextField}
                className={classes.textField}
            />
            <Field
                type="text"
                label="Attribute Type"
                name="AttributeType"
                component={AutoComplete}
                className={classes.selectField}
                options={get('properties.AttributeType.enum', CxCustomProperty)}
                disableClearable
            />
            {includes(values.AttributeType, ['Lookup', 'MultiLookup']) && (
                <Field type="text" label="Choices" name="CustomLookups" component={KVEditor} classes={classes} />
            )}

            <Field
                type="text"
                label="Supported Types"
                name="SupportedTypes"
                component={AutoComplete}
                className={classes.selectField}
                options={get('properties.SupportedTypes.items.enum', CxCustomProperty)}
                multiple
                initialize={false}
                disableClearable
            />
            <div>
                <Field
                    type="color"
                    label="ForeColor"
                    name="ForeColor"
                    component={TextField}
                    className={classes.textField}
                />
                <Field
                    type="color"
                    label="BackColor"
                    name="BackColor"
                    component={TextField}
                    className={classes.textField}
                />
            </div>
        </form>
    );
};

export const EditCxCustomProperty = ({ open, formQueryValues, onClose }) => {
    let initialValues = cloneDeep(model);
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    return (
        <DialogForm
            open={open}
            title="Custom Property"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxCustomProperty"
            inputModel={decoratedInputModel}
            onClose={onClose}
        />
    );
};

EditCxCustomProperty.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
