import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'client-shared/utility/context';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { SelectableItem } from 'client-shared/components/SelectableItem';
import { getFilter, getSelectedItems, DragDropBase, TOOLBARCACHE } from 'client-shared/utility';
import { useApolloClient } from '@apollo/client';
import { Hover } from 'client-shared/components/Hover';
import { Expiration } from 'client-shared/components/Expiration';
import { get, map, sortBy } from 'lodash/fp';
import { withEditor } from 'client-shared/entities/withEditor';
import { Typography } from '@mui/material';

const styles = {
    root: {
        flexGrow: 1,
        flexWrap: 'nowrap',
        textAlign: 'left',
        height: '300px',
        overflowY: 'auto'
    }
};

const Resources_ = ({ data: cxResources, onOpenEditor }) => {
    const client = useApolloClient();
    const dragDropBase = new DragDropBase();
    return (
        <div style={styles.root}>
            {map(
                (resource) => {
                    const expirationsToCheck = map(
                        (timedLookup) => {
                            return {
                                message: `${timedLookup.LookupType} ${timedLookup.LookupValue.DisplayName}`,
                                expirationDate: timedLookup.CurrentLifespan.End
                            };
                        },
                        get('TimedLookups', resource)
                    );
                    return (
                        <Typography
                            component={'div'}
                            sx={{ display: 'flex', alignItems: 'center', lineHeight: '1rem' }}
                            key={resource.Id}
                            draggable={true}
                            onDragStart={(ev) => {
                                const items = getSelectedItems(client, TOOLBARCACHE, [resource], null);
                                dragDropBase.handleDrag(items)(ev);
                            }}
                            onDoubleClick={() => {
                                onOpenEditor(`EditCx${resource.ObjectType}`, {
                                    formQueryValues: {
                                        filters: [{ name: 'Id', values: [resource.Id] }]
                                    }
                                });
                            }}
                        >
                            <SelectableItem item={resource} cacheName={TOOLBARCACHE} selectedItemProperties={null} />
                            {/*<Expiration end={get('CurrentLifespan.End', resource)} />*/}
                            <Expiration
                                expirationsToCheck={[
                                    { message: 'Ending in', expirationDate: get('CurrentLifespan.End', resource) },
                                    ...expirationsToCheck
                                ]}
                            />
                            <Hover
                                message={
                                    <React.Fragment>
                                        <div>{resource.Description}</div>
                                        <div>Location: {resource.Location.Name}</div>
                                    </React.Fragment>
                                }
                            />
                        </Typography>
                    );
                },
                sortBy(['ResourceType.Family', 'DisplayName'], cxResources)
            )}
        </div>
    );
};

Resources_.propTypes = {
    data: PropTypes.array.isRequired,
    onOpenEditor: PropTypes.func.isRequired
};

const Resources = withEditor(Resources_);

export const ResourceList = ({ filterFormDefinitionName = 'resourcelist.js' }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.components', filterFormDefinitionName);
    if (!filterFormDefinition) return null;
    return (
        <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
            {({ values, data }) => {
                return <Resources data={data} />;
            }}
        </FilterFormFromJson>
    );
};
