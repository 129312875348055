/**
 * Created by mark on 12/3/2019.
 */
import React, { useState } from 'react';
import { TaskDayFilter } from './TaskdayFilter';
import { PartyFilter } from './PartyFilter';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export const SMSFilter = ({ children }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Schedule" />
                    <Tab label="Resources" />
                </Tabs>
            </Box>
            {value === 0 && <TaskDayFilter children={children} />}
            {value === 1 && <PartyFilter children={children} />}
        </Box>
    );
};
