import React, { useState } from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import { get, isEmpty } from 'lodash/fp';
import { uploadFile } from '../../attachments/attachments';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { useNotifications } from 'client-shared/components/notifier2';
import { woExtension } from 'global-shared/utils/files';
import { pFetch } from '../../../utility/authHelpers';
import { handleFetchErrors, saveFile, toBlob } from '../../../utility/httpHelpers';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';

//import { pLog } from 'global-shared/utils/utils';

const useStyles = makeStyles((theme) => ({
    file: {
        marginTop: theme.spacing(2)
    }
}));

/**
 * FileEditor uploads a file to aws.
 * @param form
 * @param {string} field
 * @param  bucketName {(string|boolean)} if a string that name will be used as the key.
 * a string in the form foo* will prefix an auto generated key: prefix__UUID.ext
 * False indicates the server should provide a unique name.
 * True means keep the original filename
 * @param mimeTypes
 * @param onChange -  optional change handler
 * @param other
 * @returns {*}
 * @constructor
 */
export const FileEditor = ({ form, field, onChange, bucketName, mimeTypes, ...other }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [fileName, setFile] = useState(isEmpty(field.value) ? 'Upload File' : 'Replace File');
    const { openSBTransientP } = useNotifications();

    const downloadForm = (e) =>
        pFetch(`/api/file/${encodeURIComponent(get('value.0.Key', field))}`)
            .then(handleFetchErrors)
            .then(toBlob)
            .then(saveFile(get('value.0.Key', field)))
            .catch(openSBTransientP('message', { variant: 'error' }));

    const handleChange = (event) => {
        if (event.target.value === '') return null;
        const upload = event.currentTarget.files[0];
        setFile(upload.name);
        const niceName = bucketName || `${woExtension(upload.name)}*`;
        return uploadFile(openSBTransientP, niceName, upload).then((res) =>
            // form.setFieldValue(field.name, get('0.Key', res))

            {
                console.log('%%%%%%%', field.name);
                form.setFieldValue(field.name, [{ Id: 0, Key: get('0.Key', res) }]);
            }
        );
    };
    const buttonColor = get('errors.file', form) ? 'secondary' : 'primary';

    // const { value /*...fieldToTextFieldProps*/ } = fieldToTextField({ form, field, ...other });
    return (
        <label htmlFor="upload-import">
            <input
                id="upload-import"
                name="upload-import"
                style={{ display: 'none' }}
                type="file"
                onChange={handleChange}
                accept={mimeTypes}
            />
            <Chip size="medium" color={buttonColor} className={classes.file} label={fileName} />
            {get('value.0.Key', field) && (
                <IconButton color="secondary" onClick={downloadForm} aria-label="Download the form template">
                    <DownloadIcon />
                </IconButton>
            )}
        </label>
    );
};

FileEditor.propTypes = {
    form: PropTypes.object.isRequired,
    bucketName: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.any]),
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func // optional change handler
};
