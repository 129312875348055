import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Field } from 'client-shared/components/form';
import { gql } from 'graphql-tag';
import { get } from 'lodash/fp';
import { useApolloClient } from '@apollo/client';
import { query } from 'client-shared/utility';

const CxResourceTypesQuery = gql`
    query ($searchString: String = "", $family: [String] = [], $subTypeId: [String] = []) {
        cxResourceTypes(
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Family", values: $family }
                { name: "ResourceSubtypes.Id", values: $subTypeId }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            Unit {
                Name
            }
            ResourceSubtypes {
                Id
                _Name: Name
            }
        }
    }
`;

const CxResourceSubtypesQuery = gql`
    query ($searchString: String = "", $id: [String!]!) {
        cxResourceSubtypes(
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "ResourceType.Id", values: $id }
            ]
        ) {
            Id
            _Name: Name
        }
    }
`;

export const ResourceSubtypeEditor = ({
    family = [],
    onResourceTypeChange = () => null,
    disabled,
    cache = false,
    ...other
}) => {
    const client = useApolloClient();
    const [type, setType] = useState({ Id: '0', _DisplayName: '' });
    const { form, field, className } = other;
    const fieldError = Array.isArray(get(`${field.name}.Id`, form.errors)) ? '' : get(`${field.name}.Id`, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;

    // set the resource type based on the subtype. most objects only contain the subtype so must lookup the type.
    /* eslint-disable  react-hooks/exhaustive-deps*/
    useEffect(() => {
        (async () => {
            try {
                const { data } = await query(
                    client,
                    CxResourceTypesQuery,
                    {
                        family: family,
                        subTypeId: [get('Id', field.value)]
                    },
                    cache
                );
                const value = get(`${Object.keys(data)[0]}.0`, data) || { Id: '-1', _DisplayName: '' };
                setType(value);
            } catch (error) {
                console.log(error);
                //throw new Error(error)
            }
        })();
    }, []);
    return (
        <React.Fragment>
            <Field
                type="text"
                label="Type"
                //name="_ResourceType"
                component={AutoComplete}
                className={className}
                queryVariables={() => ({
                    family: family
                })}
                optionsQuery={CxResourceTypesQuery}
                optionLabelProperty="_DisplayName"
                onChange={(event, value) => {
                    setType(value);
                    form.setFieldTouched(field.name, true);
                    form.setFieldValue(
                        field.name,
                        get('ResourceSubtypes.0', value) || {
                            id: '0',
                            _Name: ''
                        }
                    );
                    onResourceTypeChange(value);
                }}
                value={type}
                disableClearable
                textFieldProps={{ error: showError, helperText: fieldError }}
                disabled={disabled}
                cache={cache}
            />
            <Field
                component={AutoComplete}
                queryVariables={() => ({
                    id: get('Id', type) || '-1'
                })}
                optionsQuery={CxResourceSubtypesQuery}
                optionLabelProperty="_Name"
                {...other}
                disableClearable
                disabled={disabled}
                cache={cache}
            />
        </React.Fragment>
    );
};

ResourceSubtypeEditor.propTypes = {
    family: PropTypes.array,
    disabled: PropTypes.bool,
    onResourceTypeChange: PropTypes.func
};
