import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "TemplateForm", $filters: [FilterInput]) {
        cxTemplateForms(objectType: $objectType, filters: $filters) {
            Id
            Name
            Key
            Description
            MultiInstance
            Required
            SupportedTypes
            __typename
        }
    }
`;
