import { gql } from 'graphql-tag';
export const FormQuery = gql`
    query ($objectType: String = "ConfigValue", $filters: [FilterInput]) {
        cxConfigValues(objectType: $objectType, filters: $filters) {
            Id
            Key
            Value
            Editable
            Required
            Description
        }
    }
`;
