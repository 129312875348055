/**
 * Created by mark on 12/3/2019.
 */
import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { object, date, number } from 'yup';
import { addDays, format, isSameDay } from 'date-fns/fp';
import {
    formatFromTemplate,
    getConfigValue,
    getSelectedItems,
    parseDate,
    SCHEDULECACHE,
    UserContext
} from 'client-shared/utility';
import { flatMap, flow, map, sortBy, filter } from 'lodash/fp';
import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { formatContacts } from 'client-shared/components/SMSBatchSend';
import { getSharableAttachmentLink } from 'client-shared/components/attachments/useAttachments';

/* eslint-disable no-template-curly-in-string*/
// const defaultTemplate =
//     '--${day}-- \n' +
//     '${taskDay.Job.Client.Name} ' +
//     'Job: ${taskDay.Job.DisplayName} \n' +
//     'PO: ${get(find(allocation.Tags, ["Name", "PO"]), "Value", "")} \n' +
//     '${taskDay.Job.Address.Street}' +
//     '${taskDay.Job.Address.StreetDetails} ' +
//     '${taskDay.Job.Address.City}, ' +
//     '${taskDay.Job.Address.State.Description} ' +
//     '${taskDay.Job.Address.ZipCode}\n ' +
//     '${map} ${taskDay.Job.Description} \n' +
//     '${time} ' +
//     '${formatContacts(taskDay.Job.Contacts)}';
// console.log(JSON.stringify(defaultTemplate))

/*
{
"templateLiteral":
"${date},
*${allocation.Description}
${taskDay.Description}*
Company: ${taskDay.Job.Client.Name}
Job: ${taskDay.Job.DisplayName}
${taskDay.Job.Address.Street} \n
${taskDay.Job.Address.StreetDetails}
${taskDay.Job.Address.City},
${taskDay.Job.Address.State.Description} \n
${formatContacts([taskDay.Job.Manager])} \n
${formatContacts(taskDay.Job.Contacts)} \n${map}"
}


{"templateLiteral":
"${date},
Job: ${taskDay.Job.DisplayName}
${taskDay.Job.Address.Street} \n
${formatContacts([taskDay.Job.Manager])} \n
${formatContacts(taskDay.Job.Contacts)} \n
${map}"}

 */

// template to be used for thomas grace.
// const defaultTemplate =
//     '${cachedLifespan.Start}-${cachedLifespan.End}, ' +
//     '*${allocation.Description}  ' +
//     '${taskDay.Description}* ' +
//     'Job: ${taskDay.Job.DisplayName} ' +
//     '${taskDay.Job.Address.Street} \n' +
//     '${taskDay.Job.Address.StreetDetails}' +
//     '${taskDay.Job.Address.City}, ' +
//     '${taskDay.Job.Address.State.Description} \n' +
//     '${formatContacts([taskDay.Job.Manager])} \n' +
//     '${formatContacts(taskDay.Job.Contacts)} \n' +
//     '${map}';
// console.log(JSON.stringify({templateLiteral: defaultTemplate}));

const defaultTemplateLiteral =
    '${date}, ' +
    '*${allocation.Description}  ' +
    '${taskDay.Description}* ' +
    'Company: ${taskDay.Job.Client.Name} ' +
    'Job: ${taskDay.Job.DisplayName} ' +
    '${taskDay.Job.Address.Street} \n' +
    '${taskDay.Job.Address.StreetDetails}' +
    '${taskDay.Job.Address.City}, ' +
    '${taskDay.Job.Address.State.Description} \n' +
    '${formatContacts([taskDay.Job.Manager])} \n' +
    '${formatContacts(taskDay.Job.Contacts)} \n' +
    '${_.flow(_.map(sibling=> sibling.DisplayName), _.join(":"))(taskDay.Siblings)} \n' +
    'Directions: ${map} \n\n';
// 'Files: \n${value._attach ? _.flow(_.map(file=>file.url), _.join("\\n\\n\\n"))(files) : ""}';

const validationSchema = object({
    _start: date().required('Required'),
    _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(8, 'Must be less than 8 day')
});

const dataQuery = gql`
    query ($filters: [FilterInput]) {
        cxTaskDays(objectType: "taskday", filters: $filters) {
            Id
            Allocation {
                Description
                Tags {
                    Name
                    Value
                }
                Crew {
                    DisplayName
                    Foreman {
                        Name
                        PhoneNumbers {
                            PhoneNumber
                        }
                    }
                }
                JobCosts {
                    DisplayName
                }
            }
            ShiftSupervisor
            ObjectType
            Id
            ActionType
            Description
            SMSNumbers {
                Name
                PhoneNumber
                NumericPhoneNumber
            }
            Tags {
                Name
                Value
            }
            Job {
                Id
                Client {
                    Name
                }
                Name
                DisplayName
                AccountCode
                Address {
                    Street
                    StreetDetails
                    City
                    State {
                        Description
                    }
                    ZipCode
                    Geocode {
                        Id
                        Lat
                        Lng
                    }
                }
                Description
                Tags {
                    Name
                    Value
                }
                Manager {
                    Name
                    PhoneNumbers {
                        PhoneNumber
                    }
                }
                Contacts {
                    Name
                    Title
                    PhoneNumbers {
                        PhoneNumber
                    }
                }
                PublicAttachments {
                    Key
                    Name
                }
            }
            JobActivity {
                Id
                DisplayName
            }
            Divisions {
                DisplayName
            }
            DisplayName
            CurrentLifespan {
                Start
                End
            }
            Auxiliaries {
                DisplayName
            }
            Siblings {
                ActionType
                ShiftSupervisor
                Divisions {
                    DisplayName
                }
                DisplayName
                SMSNumbers {
                    NumericPhoneNumber
                }
            }
        }
    }
`;

const filterFormDefinition = {
    title: 'Text Schedule',
    persistFilter: false,
    dataQuery: dataQuery,
    divisionFilter: true,
    validationSchema: validationSchema,
    filterProperties: ['name', 'phoneNumber'],
    filterOnServer: false,
    initialValues: {
        range: []
    },
    fieldDefinitions: [
        {
            type: 'text',
            label: 'Job',
            name: 'job_id',
            component: 'AutoComplete',
            className: 'selectField',
            optionsQuery: `
                query CxJobs($searchString: String) {
                    cxJobs(
                        filters: [
                            { name: "String", values: ["DisplayName", $searchString] }
                        ]
                    ) {
                        Id
                        _DisplayName: DisplayName
                    }
                }
            `,
            optionLabelProperty: '_DisplayName',
            initialValue: [],
            multiple: true
        },
        {
            type: 'text',
            label: 'Resources',
            name: 'resources_id',
            component: 'AutoComplete',
            className: 'selectField',
            optionsQuery: `
                query ($searchString: String, $start: String, $end: String) {
                    cxEmployees(
                        filters: [{ name: "String", values: ["DisplayName", $searchString]},
                        { name: "Range", values: [$start, $end] }]
                    ) {
                        Id
                        _DisplayName: DisplayName
                    }
                }
            `,
            queryVariables: () => ({
                start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
            }),
            optionLabelProperty: '_DisplayName',
            initialValue: [],
            multiple: true
        },
        {
            type: 'date',
            label: ' ',
            name: '_start',
            component: 'TextField',
            className: 'textField',
            initialValue: format('yyyy-MM-dd', new Date())
        },
        {
            type: 'number',
            label: 'Days',
            name: '_periods',
            component: 'TextField',
            className: 'textField',
            initialValue: 1
        }
        // {
        //     type: 'checkbox',
        //     Label: { label: 'Include Job Attachments' },
        //     name: '_attach',
        //     component: 'CheckboxWithLabel',
        //     initialValue: false
        // }
    ]
};

const getSelectedTaskDayIds = (client) => {
    let ids = [];
    const selectedItems = getSelectedItems(client, SCHEDULECACHE);
    selectedItems.forEach((selectedItem) => {
        const { item, selectedItemProperties } = selectedItem;
        if (item.ObjectType === 'Allocation') {
            const taskDays = item.TaskDays.get(selectedItemProperties.CurrentLifespan.Start);
            const x = map((taskDay) => taskDay.Id, taskDays);
            ids.push(...x);
            return;
        }
        ids.push(item.Id);
    });
    return ids;
};

//console.log(defaultTemplateLiteral);

const getRows = (templateLiteral) => async (taskDays, value) => {
    const getMessages = (taskDays) =>
        flatMap(
            async (taskDay) => {
                const shortURLs = await Promise.all(
                    flow(
                        map((attachment) => attachment.Key),
                        map(getSharableAttachmentLink)
                    )(taskDay.Job.PublicAttachments)
                );
                if (!taskDay.SMSNumbers.length) {
                    // create a sms message if the taskday is the appropriate type.
                    if (['Employee', 'Subcontractor', 'Plant'].includes(taskDay.ActionType)) {
                        taskDay.SMSNumbers = [
                            {
                                Name: taskDay.DisplayName,
                                PhoneNumber: ''
                            }
                        ];
                    }
                }
                const _taskDays = filter(
                    (_taskDay) =>
                        _taskDay.Job.Id === taskDay.Job.Id &&
                        isSameDay(parseDate(_taskDay.CurrentLifespan.Start), parseDate(taskDay.CurrentLifespan.Start)),
                    taskDays
                );
                return map((smsNumber) => {
                    const message = formatFromTemplate({
                        values: {
                            value: value,
                            _date: parseDate(taskDay.CurrentLifespan.Start),
                            date: format('EEEE, MMM do hh:mm aaaa', parseDate(taskDay.CurrentLifespan.Start)),
                            day: format('EEEE', parseDate(taskDay.CurrentLifespan.Start)),
                            time: format('hh:mm aaaa', parseDate(taskDay.CurrentLifespan.Start)),
                            cachedLifespan: {
                                Start: format('E, MMM do', parseDate(taskDay.CurrentLifespan.Start)),
                                End: format('E, MMM do', parseDate(taskDay.CurrentLifespan.End))
                            },
                            allocation: { ...taskDay.Allocation, taskDay: taskDay },
                            taskDay: taskDay,
                            taskDays: _taskDays,
                            files: shortURLs,
                            map: `https://www.google.com/maps/search/?api=1&query=${taskDay.Job.Address.Geocode.Lat},${taskDay.Job.Address.Geocode.Lng}`
                        },
                        templateLiteral: templateLiteral,
                        options: { imports: { formatContacts: formatContacts } }
                    });
                    return {
                        id: taskDay.Id,
                        phoneNumber: smsNumber.NumericPhoneNumber,
                        name: smsNumber.Name,
                        start: taskDay.CurrentLifespan.Start,
                        message: message
                    };
                }, taskDay.SMSNumbers);
            },
            sortBy(['CurrentLifespan.Start'], taskDays)
        );
    return flatMap((message) => message, await Promise.all(getMessages(taskDays)));
};

export const TaskDayFilter = ({ children }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const config_templateLiteral = getConfigValue('config.schedule.smsfilter', user) || defaultTemplateLiteral;
    const ids = getSelectedTaskDayIds(client);
    filterFormDefinition.onData = getRows(config_templateLiteral);
    filterFormDefinition.firstTimeValues = ids.length ? { id: ids } : undefined;
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition} children={children} />;
};
