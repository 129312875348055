// note, none fp versions to make it easier to use 3rd parameter for defaults.
import { template, get, find } from 'lodash';
import _ from 'lodash/fp';
import merge from 'lodash/fp/merge';
import { format } from 'date-fns/fp';
import { parseDate } from 'client-shared/utility';

export const formatFromTemplate = ({ values, templateLiteral, options = { imports: {} } }) => {
    options.imports = merge({ get, find, format, parseDate, _ }, options.imports);
    const compiledTemplate = template(templateLiteral, options);
    try {
        return compiledTemplate(values);
    } catch (error) {
        console.log('formatFromTemplate: ', error);
    }
};
