import React from 'react';
import PropTypes from 'prop-types';
import { get, flow, curry, sortBy, find, chunk, filter, flatMap } from 'lodash/fp';
import { padStart } from 'lodash';
import {
    adjustToInterval,
    groupByFp,
    groupCollectionByInterval,
    parseDate,
    useReportTotals
} from 'client-shared/utility';
import { gridContainerStyle, gridHeaderItemStyle } from './styles';
import { compareAsc, isSameDay } from 'date-fns/fp';
import { ExcelExport } from 'client-shared/components/ExcelExport';
import { DateTime } from 'client-shared/components/serverobjects/DateTime';
import { Report } from 'client-shared/components/Report';

const map = require('lodash/fp/map').convert({ cap: false });

const STRATIFYCOLUMNS = 7;

/**
 * create a rangeRow
 * @param id - unique id for RangeRows
 * @param headerRow - true if header of collection, often used to display summaries.
 * @param items - the data used to create the range intervals.
 * @param start - start date of range
 * @param periods - number of periods in the range.
 * @param datePropertyName - full path to property name of date to group by interval.
 * @param interval - days, weeks, months
 * @param skipDays - array of weekday numbers to skip [0,6] equals Sunday and Saturday.
 * @param periodMultiple - number of columns across if stratifying rows.
 * @returns {{headerRow: boolean, intervalData: CurriedFunction4<unknown, unknown, unknown, unknown, Map<unknown, unknown>>, readonly range: *, id, items: *[]}|*[]}
 * @constructor
 */
export const rangeRow = ({
    id,
    headerRow = false,
    items = [],
    start,
    periods,
    datePropertyName,
    interval,
    skipDays,
    periodMultiple
}) => {
    const _items = datePropertyName ? items : [];
    const groupByInterval = {
        start: (item) => {
            if (compareAsc(parseDate(start), parseDate(get(datePropertyName, item))) < 0) {
                return null;
            }
            return adjustToInterval(get(datePropertyName, item), { interval, skipDays, floor: start });
        }
    };
    return {
        id: id,
        headerRow: headerRow,
        items: items,
        intervalData: groupCollectionByInterval(
            start,
            periods,
            groupByInterval,
            { interval, skipDays, absoluteStart: true, periodMultiple: periodMultiple },
            _items
        ),
        getRange: function () {
            return [...this.intervalData.keys()];
        }
    };
};
/**
 * return a collection of rangeRows that contains a total row grouped by interval,
 * followed by one or more detail rows grouped by the groupByCriteria with the items grouped by interval.
 * where: each rangeRow is an object that contains the property, intervalData.
 * this is an ordered array (Map) of key value pairs, where each pair is date,items, and the order corresponds
 * to a column in a grid of X date coordinates. the first key/value pair is the first date column, the second
 * is the second date column, ...
 * [{id: rowId, intervalData: {2021-09-28: items, 2021-09-29: items, ...}}, ...]
 * @param groupByCriteria - criteria used to group each row
 * @param datePropertyName - full path to property name of date to group by interval.
 * @param start - start date of range
 * @param periods - number of period in range.
 * @param interval - days, weeks, months
 * @param skipDays - array of weekday numbers to skip [0,6] equals Sunday and Saturday.
 * @param data - collection to group.
 * @returns {Object|*}
 */
export const getRangeRows = curry(
    ({ groupByCriteria, datePropertyName, start, periods, interval, skipDays, stratify }, data) => {
        // const { start: startInterval } = getRangeStartEnd(start, periods, { interval: interval });
        const startInterval = start;
        const rangeRows = flow(
            // create object whose properties are rows and values are items for the row.
            groupByFp(groupByCriteria, null, null),
            // iterate the grouped object.
            map((items, key) =>
                rangeRow({
                    id: key,
                    headerRow: false,
                    items,
                    start: startInterval,
                    periods,
                    datePropertyName,
                    interval,
                    skipDays,
                    periodMultiple: stratify ? STRATIFYCOLUMNS : undefined
                })
            )
        )(data);

        // add a total row that contains all items for all dates.
        return [
            rangeRow({
                id: '*$&header',
                headerRow: true,
                items: data,
                start: startInterval,
                periods,
                datePropertyName,
                interval,
                skipDays,
                periodMultiple: stratify ? STRATIFYCOLUMNS : undefined
            }),
            ...rangeRows
        ];
    }
);

const getExcelColumns = (rangeRow) => {
    // add additional columns to export.
    const ranges = map((range) => ({ title: range, name: range }), rangeRow.getRange());
    return [{ title: 'Name', name: 'DisplayName' }, ...ranges];
};

/**
 * split rows created by rangeRows() (i.e. [{id,items ,intervalData}]) onto equal intervalData
 * property lengths. if there is one element in the rows array with an intervalData property of 14 elements,
 * and length is 7, then return two rows, each with a intervalData property of 7 elements.
 * @param length - number of period in each row
 * @param scheduledOnly - remove rows with no items.
 * @param rows
 * @returns {*}
 */
const stratifyRow = curry(({ length, scheduledOnly = false }, rangeRows) => {
    if (!rangeRows) {
        return [];
    }
    const rows = [];
    map(
        (rangeRow) => {
            if (!scheduledOnly || rangeRow.headerRow) {
                map(
                    (intervalData, index) => {
                        rows.push({
                            ...rangeRow,
                            ...{
                                id: `iteration$&${padStart(index, 4, '0')}$!${rangeRow.id}`,
                                intervalData: new Map(intervalData)
                            }
                        });
                    },
                    chunk(length, [...rangeRow.intervalData])
                );
                return;
            }

            map(
                (intervalData, index) => {
                    // filter empty rows if there is an empty taskDay property. todo: this is hacky.
                    if (
                        scheduledOnly &&
                        find(
                            (date) => {
                                return rangeRow.intervalData.get(date).length > 1;
                            },
                            [...new Map(intervalData).keys()]
                        )
                    ) {
                        rows.push({
                            ...rangeRow,
                            ...{
                                id: `iteration$&${padStart(index, 4, '0')}$!${rangeRow.id}`,
                                intervalData: new Map(intervalData)
                            }
                        });
                    }
                },
                chunk(length, [...rangeRow.intervalData])
            );
        },
        sortBy('id', rangeRows)
    );
    return sortBy('id', rows);
});

/**
 *
 * @param RowHeader - component to render the columns in the first row.
 * @param Row - component to render the
 * @param rangeRows
 * @returns {*}
 * @constructor
 */
const Rows = ({ RowHeader, Row, rangeRows }) => {
    return flatMap((rangeRow) => {
        if (rangeRow.headerRow) {
            return <RowHeader key={rangeRow.id} rangeRow={rangeRow} />;
        }
        return <Row key={rangeRow.id} rangeRow={rangeRow} />;
    }, rangeRows);
};

Rows.propTypes = {
    RowHeader: PropTypes.elementType.isRequired,
    Row: PropTypes.elementType.isRequired,
    rangeRows: PropTypes.array.isRequired
};

/**
 * called by the GridDashBoard to render the row header.
 * @param rangeRow - an object that contains the data for the row in the property.
 * see dashBoardhelpers.js for details.
 * @param values - user entered values from the filter bar.
 * @returns {JSX.Element}
 * @constructor
 */
export const _rowHeader =
    (values, reportIntervals) =>
    ({ rangeRow }) => {
        const gridColumnHeaderItem = gridHeaderItemStyle({ marginTop: '10px', position: 'sticky', top: 0 });
        const dateRow = map((date) => {
            const reportInterval = find(
                (reportInterval) => isSameDay(parseDate(reportInterval.Range.Start), parseDate(date)),
                reportIntervals
            );
            if (!reportInterval) {
                return null;
            }
            const reportObject = { ...reportInterval.Report /* ...reportObject */ };
            return (
                <div style={gridColumnHeaderItem} key={date}>
                    <DateTime dateTime={date} dateTimeFormat="EEEEEE, MM/dd/yyyy" />
                    <div style={{ textAlign: 'left' }}>
                        <Report
                            reportProperties={values._reportIntervalProperties}
                            reportObject={reportObject}
                            label={true}
                            unit={{ Name: 'Hrs', Scalar: 60 }}
                        />
                    </div>
                </div>
            );
        }, rangeRow.getRange());

        return (
            <React.Fragment>
                <div style={gridColumnHeaderItem}></div>
                {dateRow}
            </React.Fragment>
        );
    };

/**
 * render a css grid that displays items grouped by various properties (e.g. job, resource, resource type, other)
 * by period (e.g. day, week, month, other) where each grouping is a row and each interval is a column.
 * @param rangeRows - a collection of rangeRow objects that contain intervalData. created by getRangeRow().
 * @param periods - number of intervals
 * @param interval - string - 'days', 'weeks', 'months'
 * @param skipDays - days of week to exclude - 0-sunday...6-saturday. only applies to interval days.
 * @param stratify - boolean, true if rows should be segmented into equal lengths down the grid.
 * example, 14 dates across the page will display 7 across on one row and the next 7 on subsequent rows.
 * @param - orientation - pivots the display by 'row' or 'column'
 * @param rowHeader -component to render the first row. the props are:
 *   range - array of dates for the row being rendered.
 *   rangeRow - an object that contains the data for the row in the property, intervalData.
 *   values - user entered values from the filter bar.
 * @param row -react component to render detail row columns. where the props are:
 *   range - array of dates for the row being rendered.
 *   rangeRow - an object that contains the data for the row in the property, intervalData.
 *   values - user entered values from the filter bar.
 * must return rangeRows - this must be an asynchronous function that returns a promise.
 * @param addExcelCell - optional function to format the data in an excel cell during export.
 * @returns {JSX.Element}
 * @constructor
 */
export const GridDashBoard = ({
    values,
    rangeRows,
    periods,
    interval,
    skipDays = [],
    stratify = false,
    orientation = 'row',
    rowHeader = _rowHeader,
    row,
    scheduledOnly,
    addExcelCell,
    addExcelRow
}) => {
    const [reportIntervals] = useReportTotals({
        reportProperties: values._reportIntervalProperties,
        periods: periods,
        start: values._start,
        stratify: stratify,
        interval: interval,
        absoluteStart: values._absoluteStart,
        values
    });
    let stratifyLength = stratify ? STRATIFYCOLUMNS : periods;
    if (stratify && interval.toLowerCase() === 'days' && filter((day) => [0, 6].includes(day), skipDays)) {
        stratifyLength = STRATIFYCOLUMNS - skipDays.length;
    }
    const stratifiedRangeRows = stratify
        ? stratifyRow(
              {
                  length: stratifyLength,
                  scheduledOnly: scheduledOnly
              },
              rangeRows
          )
        : rangeRows;

    const columnCount = (rangeRows) => {
        if (!rangeRows.length) {
            return 0;
        }
        return rangeRows[0].getRange().length;
    };

    const gridContainer = gridContainerStyle({
        columns: columnCount(stratifiedRangeRows) + 1, //inclusive of 1st column description.
        rows: columnCount(stratifiedRangeRows) + 1,
        gridAutoFlow: orientation,
        overflow: 'auto',
        height: 'calc(100vh - 250px)',
        width: 'calc(100vw - 10px)'
    });
    return (
        <>
            {' '}
            <ExcelExport
                rows={stratifiedRangeRows}
                getColumns={getExcelColumns}
                addExcelCell={addExcelCell(values, reportIntervals)}
                addExcelRow={addExcelRow}
            />
            <div>
                <div style={gridContainer} className={'print'}>
                    <Rows RowHeader={rowHeader(values, reportIntervals)} Row={row} rangeRows={stratifiedRangeRows} />
                </div>
            </div>
        </>
    );
};

GridDashBoard.propTypes = {
    values: PropTypes.object.isRequired,
    rangeRows: PropTypes.array.isRequired,
    periods: PropTypes.number.isRequired,
    interval: PropTypes.string.isRequired,
    skipDays: PropTypes.array,
    stratify: PropTypes.bool,
    orientation: PropTypes.string,
    rowHeader: PropTypes.elementType,
    row: PropTypes.elementType.isRequired,
    scheduledOnly: PropTypes.bool,
    addExcelCell: PropTypes.func.isRequired,
    addExcelRow: PropTypes.func
};

export const cxTaskDayFragment = `
        Allocation {
            Id
            ActionType
            DisplayName
            Description
            ActionStatus {
                Id
                DisplayName
            }
            ActualCrew
            Crew {
                Id
                DisplayName
                ForeColor
                BackColor
            }
            Job {
                Id
                DisplayName
                ForeColor
                BackColor
                AccountCode
                Manager {
                    Id
                    DisplayName
                    PhoneNumbers {
                        Name
                        PhoneNumber
                    }
                }
                Contacts {
                    Id
                    DisplayName
                    PhoneNumbers {
                        Name
                        PhoneNumber
                    }
                }
                Address {
                    Street
                    StreetDetails
                    City
                    State {
                        Name
                    }
                    ZipCode
                    Geocode {
                        Lat
                        Lng
                    }
                }
                Description
                NoteHistory {
                    AsOf
                    Party {
                        DisplayName
                    }
                    Note
                }
            }
            JobActivity {
                Id
                DisplayName
                Description
            }
            Tags {
                Name
                Value
            }
        }
        Id
        ActionType
        Amount
        ObjectType
        DisplayName
        ForeColor
        BackColor
        ActionStatus {
            BackColor
        }
        SMSNumbers {
            Name
            PhoneNumber
        }
        Description
        CurrentLifespan {
            Start
            End
        }
        ConflictIds
        ResourceType {
            Family
            DisplayName
            Unit {
                Name
            }
        }
        Principals {
            Id
            DisplayName
            ForeColor
            BackColor
        }
        Tags {
            Name
            Value
        }
        Auxiliaries {
            Id
            DisplayName
            ObjectType
            ResourceType {
                Family
                DisplayName
                Unit {
                    Name
                }
            }
        }
        __typename
`;
