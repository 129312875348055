import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'client-shared/utility/context';
import { Accordion } from 'client-shared/components/Accordion';
import Typography from '@mui/material/Typography';
import { getFilter, DragDropBase } from 'client-shared/utility';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { Hover } from 'client-shared/components/Hover';
import { get, map } from 'lodash/fp';
import { Expiration } from 'client-shared/components/Expiration';
import { withEditor } from 'client-shared/entities/withEditor';

const styles = {
    root: {
        flexGrow: 1,
        flexWrap: 'nowrap',
        textAlign: 'left',
        height: '300px',
        overflowY: 'auto'
    }
};

const Jobs_ = ({ data: cxJobs, onOpenEditor }) => {
    const dragDropBase = new DragDropBase();
    return (
        <div style={styles.root}>
            {map((job) => {
                return (
                    <Accordion
                        key={job.Id}
                        items={
                            <Typography
                                component="span"
                                fontSize="small"
                                sx={{
                                    margin: 0,
                                    padding: 0,
                                    color: get('ForeColor', job) || '#000000',
                                    backgroundColor: get('BackColor', job) || '#FFFFFF',
                                    wordBreak: 'break-all',
                                    cursor: 'pointer'
                                }}
                                draggable={true}
                                onDragStart={(ev) => {
                                    dragDropBase.handleDrag([
                                        {
                                            selectedItemProperties: null,
                                            item: job.JobActivities[0],
                                            cacheName: null,
                                            selected: false
                                        }
                                    ])(ev);
                                }}
                                onDoubleClick={() => {
                                    onOpenEditor('EditCxJob', {
                                        formQueryValues: {
                                            filters: [{ name: 'Id', values: [job.Id] }]
                                        }
                                    });
                                }}
                            >
                                {job.DisplayName}
                                <Expiration
                                    expirationsToCheck={[
                                        { message: 'Ending in', expirationDate: get('CurrentLifespan.End', job) }
                                    ]}
                                />
                                <Hover message={job.Description} />
                            </Typography>
                        }
                    >
                        {job.JobActivities.map((jobActivity) => {
                            return (
                                <div key={jobActivity.Id}>
                                    <Typography
                                        component="span"
                                        fontSize="small"
                                        sx={{
                                            margin: 0,
                                            paddingLeft: '8px',
                                            color: get('ForeColor', jobActivity) || '#000000',
                                            backgroundColor: get('BackColor', jobActivity) || '#FFFFFF',
                                            wordBreak: 'break-all',
                                            cursor: 'pointer'
                                        }}
                                        draggable={true}
                                        onDragStart={(ev) => {
                                            dragDropBase.handleDrag([
                                                {
                                                    selectedItemProperties: null,
                                                    item: jobActivity,
                                                    cacheName: null,
                                                    selected: false
                                                }
                                            ])(ev);
                                        }}
                                    >
                                        {jobActivity.DisplayName}
                                    </Typography>
                                    <Hover message={job.Description} />
                                </div>
                            );
                        })}
                    </Accordion>
                );
            }, cxJobs)}
        </div>
    );
};

Jobs_.propTypes = {
    data: PropTypes.array.isRequired,
    onOpenEditor: PropTypes.func.isRequired
};

const Jobs = withEditor(Jobs_);

export const JobList = () => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.components', 'joblist.js');
    if (!filterFormDefinition) return null;
    return (
        <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
            {({ values, data }) => {
                return <Jobs data={data} />;
            }}
        </FilterFormFromJson>
    );
};
