import { ApolloProvider, ApolloProvider as ApolloHooksProvider, ApolloError } from '@apollo/client';
import React, { useEffect, useState, Suspense } from 'react';
import './css/App.css';
import { useLocation } from 'react-router-dom';
import { getAuth } from './Auth';
import ReactGA from 'react-ga';
/*import { Route, Routes } from 'react-router-dom';*/
import { getClient } from 'client-shared/utility';
import { ShowError } from 'client-shared/components/ShowError';
import { get } from 'lodash/fp';
import { UserContext } from 'client-shared/utility/context';
import { SubscriptionToCache } from 'client-shared/components/SubscriptionToCache';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNotifications } from 'client-shared/components/notifier2';

import {
    AllocationEventQuery,
    AllocationEventSubscription,
    TaskDayEventQuery,
    TaskDayEventSubscription,
    JobEventQuery,
    JobEventSubscription,
    ResourceEventQuery,
    ResourceEventSubscription,
    TimeEntryEventSubscription,
    TimeEntryEventQuery,
    ForecastEventQuery,
    ForecastEventSubscription,
    PartyEventSubscription,
    PartyEventQuery,
    TextMessageEventSubscription,
    TextMessageEventQuery
} from 'client-shared/utility/fragments';

// import Desktop from './desktop';
// import { Help } from './pages/Help';
import { AppRoutes } from './routes';

function App(props) {
    const location = useLocation();
    ReactGA.initialize('UA-54479074-2');

    const [userState, setUserState] = useState(null);
    const { openSBPermanent } = useNotifications();
    const [client, setClient] = useState();

    const massage = (_in) => {
        //_in.congistics.message = 'Hey aaron this be a message';
        const message = get('congistics.message', _in);
        if (message) openSBPermanent(message, { variant: 'info' });
        return _in;
    };
    /* eslint-disable react-hooks/exhaustive-deps*/

    useEffect(() => {
        async function init() {
            setClient(await getClient());
        }
        init().catch(console.error); //maybe show one of the boxes below?
        getAuth(location)
            .then(massage)
            .then(setUserState)
            .catch((e) => {
                if (e.toString() === 'The user is not authenticated') return;
                if (e instanceof ApolloError) return;
                openSBPermanent(e, { variant: 'warning' });
            });
    }, []);

    useEffect(() => {
        if (localStorage.getItem('APP_IN_TEST') !== 'true') ReactGA.pageview(location.pathname);
    }, [location]);

    if (!client) {
        return <h2>Initializing app...</h2>;
    }

    return (
        <UserContext.Provider value={[userState, setUserState]}>
            <ApolloProvider client={client}>
                <ApolloHooksProvider client={client}>
                    <SubscriptionToCache
                        subscription={AllocationEventSubscription}
                        subscriptionQuery={AllocationEventQuery}
                    />
                    <SubscriptionToCache
                        subscription={TaskDayEventSubscription}
                        subscriptionQuery={TaskDayEventQuery}
                    />
                    <SubscriptionToCache
                        subscription={ResourceEventSubscription}
                        subscriptionQuery={ResourceEventQuery}
                    />
                    <SubscriptionToCache subscription={JobEventSubscription} subscriptionQuery={JobEventQuery} />
                    <SubscriptionToCache
                        subscription={TextMessageEventSubscription}
                        subscriptionQuery={TextMessageEventQuery}
                    />
                    <SubscriptionToCache
                        subscription={TimeEntryEventSubscription}
                        subscriptionQuery={TimeEntryEventQuery}
                    />
                    <SubscriptionToCache
                        subscription={ForecastEventSubscription}
                        subscriptionQuery={ForecastEventQuery}
                    />
                    <SubscriptionToCache subscription={PartyEventSubscription} subscriptionQuery={PartyEventQuery} />

                    <ShowError />
                    <div className="App">
                        <Suspense
                            fallback={
                                <Box sx={{ display: 'flex' }}>
                                    <CircularProgress />
                                </Box>
                            }
                        >
                            <AppRoutes />
                        </Suspense>
                    </div>
                </ApolloHooksProvider>
            </ApolloProvider>
        </UserContext.Provider>
    );
}

export default App;
