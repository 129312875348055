import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper } from '@mui/material';

export const Section = ({ title, children, ...other }) => (
    <Paper elevation={1} sx={{ margin: '5px', padding: '8px' }} {...other}>
        {title && (
            <Typography variant="caption" component="div" gutterBottom>
                {title}
            </Typography>
        )}
        {children}
    </Paper>
);

Section.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element, PropTypes.node])
};
