import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { FormControl, FormHelperText, IconButton } from '@mui/material';
import { Field, AutoComplete, UnitQuantityEditor, unitQuantityValidationSchema } from 'client-shared/components/form';
import { array, object, string } from 'yup';
import { get, flow } from 'lodash/fp';
import { Section } from 'client-shared/components/Section';
import { format, addDays } from 'date-fns/fp';
import { CxResourcesQuery } from '../queries.js';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export const pickupValidationSchema = array().of(
    object({
        Quantity: unitQuantityValidationSchema,
        Description: string()
    })
);

export const PickupEditor = ({ form, field, classes, label, data, ...other }) => {
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    const model = {
        Id: 0,
        Resources: [],
        Quantity: {
            Unit: {
                Id: 15,
                _DisplayName: 'each'
            },
            Quantity: 1
        },
        Description: '',
        Completed: false
    };
    //const resources = field.value.map((value) => value.Description).join(', ');
    return (
        <Section title={label}>
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((pickup, index) => {
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                            <Field
                                                type="text"
                                                label="Resource"
                                                name={`${field.name}.${index}.Resources`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                optionsQuery={CxResourcesQuery}
                                                optionLabelProperty="_DisplayName"
                                                queryVariables={() => ({
                                                    start: format("yyyy-MM-dd'T'00:00", new Date()),
                                                    end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                                                })}
                                                //groupBy={(option) => option._ObjectType}
                                                initialize={false}
                                            />
                                            <Field
                                                type="text"
                                                label="Description"
                                                name={`${field.name}.${index}.Description`}
                                                component={TextField}
                                                className={classes.shortTextField}
                                            />
                                            <Field
                                                type="text"
                                                label="Quantity"
                                                name={`${field.name}.${index}.Quantity`}
                                                component={UnitQuantityEditor}
                                                classes={classes}
                                            />
                                            <Field
                                                type="checkbox"
                                                Label={{ label: 'Completed' }}
                                                name={`${field.name}.${index}.Completed`}
                                                component={CheckboxWithLabel}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

PickupEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    data: PropTypes.object
};
