import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GroupBy } from 'client-shared/components/GroupBy';
import { Accordion } from 'client-shared/components/Accordion';
import { Contacts } from 'client-shared/components/serverobjects/Contacts';
import { JobName } from 'client-shared/components/serverobjects/JobName';
import { DateTime } from 'client-shared/components/serverobjects/DateTime';
import { Description, TaskDayDescriptionEditor } from 'client-shared/components/serverobjects/Description';
import { ActionStatusEditor } from 'client-shared/components/serverobjects/ActionStatusEditor';
import { Tags } from 'client-shared/components/serverobjects/Tags';
import { flow, sortBy, get } from 'lodash/fp';
import { format } from 'date-fns/fp';
import { parseDate, UserContext, groupByFp, groupNameParse, sliceFp } from 'client-shared/utility';
import StopIcon from '@mui/icons-material/Stop';
import { Box } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
const map = require('lodash/fp/map').convert({ cap: false });

const INDENT = '.5rem';

const groupByDate = {
    start: (item) => format('yyyy-MM-dd', parseDate(item.CurrentLifespan.Start))
};

const groupByAllocationActionType = {
    allocationActionType: 'Allocation.ActionType'
    // parentId: 'ParentId',
    // actionType: 'ActionType'
};

const groupByAllocation = {
    allocationDisplayName: 'Allocation.DisplayName',
    parentId: 'Allocation.Id'
};

const groupByJob = {
    parentId: 'Allocation.Id',
    job: 'Allocation.Job.DisplayName'
};

const TaskDayDisplay = ({ taskDay, taskDayDescriptionEditor = false, description }) => {
    const [user] = useContext(UserContext);
    const disabled = !(
        taskDay.ObjectType === 'ActualTaskDay' && get('Principals.0.Id', taskDay) === user.congistics.user.ResourceId
    );
    const actionStatusBackColor = get('ConflictIds.length', taskDay)
        ? '#FF0000'
        : taskDay.ActionStatus.BackColor === '#FFFFFF'
          ? null
          : taskDay.ActionStatus.BackColor;
    if (taskDay.ActionType === 'Generic' || taskDay.ActionType !== 'Employee') {
        return (
            <React.Fragment>
                <Box sx={{ display: 'flex', paddingLeft: '10px' }}>
                    {actionStatusBackColor && <StopIcon fontSize="small" sx={{ color: actionStatusBackColor }} />}
                    {['Generic', 'Material', 'Plant', 'Subcontractor'].includes(taskDay.ActionType)
                        ? `${taskDay.DisplayName}: ${taskDay.Amount} ${get('ResourceType.Unit.Name', taskDay)}`
                        : taskDay.DisplayName}
                </Box>
                {description && <Description description={taskDay.Description} />}
            </React.Fragment>
        );
    }
    return (
        <Accordion
            items={
                <>
                    <Box sx={{ display: 'flex' }}>
                        {actionStatusBackColor && <StopIcon fontSize="small" sx={{ color: actionStatusBackColor }} />}
                        <Box
                            sx={{
                                color: taskDay.ForeColor,
                                backgroundColor: taskDay.BackColor
                            }}
                        >
                            {taskDay.DisplayName}
                        </Box>
                    </Box>
                    {taskDay.Description !== '' && description && (
                        <DescriptionIcon sx={{ fontSize: '15px', marginRight: '3px', marginLeft: '3px' }} />
                    )}
                </>
            }
        >
            <Contacts
                contacts={[
                    {
                        Id: 0,
                        DisplayName: '',
                        PhoneNumbers: taskDay.SMSNumbers
                    }
                ]}
            />
            <Description description={taskDay.Description} />
            {taskDayDescriptionEditor && (
                <TaskDayDescriptionEditor
                    id={taskDay.Id}
                    objectName="CxTaskDay"
                    Description={taskDay.Description}
                    disabled={disabled}
                />
            )}
        </Accordion>
    );
};

TaskDayDisplay.propTypes = {
    taskDay: PropTypes.object.isRequired,
    taskDayDescriptionEditor: PropTypes.bool,
    description: PropTypes.bool
};

/**
 * assume the taskday object contains the allocation with job properties.
 * @param style
 * @param taskDays
 * @param config_configValues
 * @param taskDayDescriptionEditor
 * @param other
 * @returns {JSX.Element}
 * @constructor
 */
export const TaskDaysByJob = ({
    style,
    taskDays,
    config_configValues,
    taskDayDescriptionEditor,
    description,
    ...other
}) => {
    return (
        <GroupBy items={taskDays} groupByCriteria={groupByJob}>
            {(taskDays, group, index) => {
                return (
                    <span key={index} style={style} {...other}>
                        <JobName job={get('0.Allocation.Job', taskDays)} />
                        <span key={index} style={{ display: 'block', paddingLeft: INDENT }}>
                            <TaskDays
                                style={style}
                                taskDays={taskDays}
                                config_configValues={config_configValues}
                                taskDayDescriptionEditor={taskDayDescriptionEditor}
                                description={description}
                                {...other}
                            />
                        </span>
                    </span>
                );
            }}
        </GroupBy>
    );
};

TaskDaysByJob.propTypes = {
    style: PropTypes.object,
    taskDays: PropTypes.array.isRequired,
    config_configValues: PropTypes.object,
    taskDayDescriptionEditor: PropTypes.bool
};

export const TaskDaysByDate = ({
    style,
    taskDays,
    config_configValues,
    taskDayDescriptionEditor,
    description,
    ...other
}) => {
    return (
        <GroupBy items={taskDays} groupByCriteria={groupByDate}>
            {(taskDays, group, index) => {
                return (
                    <div key={index} style={style} {...other}>
                        <DateTime
                            style={{ fontSize: '.8em' }}
                            dateTime={get('0.CurrentLifespan.Start', taskDays)}
                            dateTimeFormat="EEE, MM/dd/yyyy"
                        />
                        <span key={index} style={{ display: 'block', paddingLeft: INDENT }}>
                            <TaskDays
                                style={style}
                                taskDays={taskDays}
                                config_configValues={config_configValues}
                                taskDayDescriptionEditor={taskDayDescriptionEditor}
                                description={description}
                                {...other}
                            />
                        </span>
                    </div>
                );
            }}
        </GroupBy>
    );
};

TaskDaysByDate.propTypes = {
    style: PropTypes.object,
    taskDays: PropTypes.array.isRequired,
    config_configValues: PropTypes.object,
    taskDayDescriptionEditor: PropTypes.bool,
    description: PropTypes.bool
};

export const TaskDays = ({
    style,
    taskDays,
    config_configValues,
    taskDayDescriptionEditor,
    description = true,
    ...other
}) => {
    return (
        <GroupBy items={taskDays} groupByCriteria={groupByAllocationActionType}>
            {(taskDays, group1, index) => {
                return (
                    <div>
                        {!['Crew', 'Group', 'Generic'].includes(group1.allocationActionType) && (
                            <div>{group1.allocationActionType}</div>
                        )}
                        <GroupBy items={taskDays} groupByCriteria={groupByAllocation}>
                            {(taskDays, group2, index) => {
                                return (
                                    <React.Fragment>
                                        {['Crew', 'Group', 'Generic'].includes(group1.allocationActionType) && (
                                            <div>{get('0.Allocation.DisplayName', taskDays)}</div>
                                        )}
                                        <div style={{ display: 'flex' }}>
                                            <span key={index} style={{ display: 'block', ...style }} {...other}>
                                                <span key={index} style={{ display: 'block', paddingLeft: INDENT }}>
                                                    {description && (
                                                        <Description
                                                            description={get('0.Allocation.Description', taskDays)}
                                                        />
                                                    )}
                                                    <Tags
                                                        tags={get('0.Allocation.Tags', taskDays)}
                                                        tagNames={get('Allocation.tagNames', config_configValues)}
                                                    />
                                                </span>
                                                {flow(
                                                    sortBy(['DisplayName']),
                                                    map((taskDay, index) => (
                                                        <span
                                                            key={index}
                                                            style={{ display: 'block', paddingLeft: INDENT }}
                                                        >
                                                            <TaskDayDisplay
                                                                taskDay={taskDay}
                                                                taskDayDescriptionEditor={taskDayDescriptionEditor}
                                                                description={description}
                                                            />
                                                        </span>
                                                    ))
                                                )(taskDays)}
                                            </span>
                                            {get('ActionStatusEditor.display', config_configValues) && (
                                                <ActionStatusEditor
                                                    id={get('0.Allocation.Id', taskDays)}
                                                    objectName="CxAllocation"
                                                    ActionStatus={{
                                                        Id: get('0.Allocation.ActionStatus.Id', taskDays),
                                                        _DisplayName: get(
                                                            '0.Allocation.ActionStatus.DisplayName',
                                                            taskDays
                                                        )
                                                    }}
                                                    disabled={false}
                                                />
                                            )}
                                        </div>
                                        <Tags tags={get('0.Tags', taskDays)} style={{ paddingLeft: '1rem' }} />
                                    </React.Fragment>
                                );
                            }}
                        </GroupBy>
                    </div>
                );
            }}
        </GroupBy>
    );
};

TaskDays.propTypes = {
    style: PropTypes.object,
    taskDays: PropTypes.array.isRequired,
    config_configValues: PropTypes.object,
    taskDayDescriptionEditor: PropTypes.bool,
    description: PropTypes.bool
};

export const taskDaysByJobExcel = ({ taskDays, description = true }) => {
    const richText = [];
    const jobs = groupByFp(groupByJob, null, null, taskDays);
    map((taskDays) => {
        richText.push({
            font: { color: { argb: sliceFp(1, undefined, get('0.Allocation.Job.ForeColor', taskDays)) } },
            text: `${get('0.Allocation.Job.DisplayName', taskDays)}\n`
        });
        richText.push(...taskDaysExcel({ taskDays, description }));
    }, jobs);
    return richText;
};

export const taskDaysExcel = ({ taskDays, description = true }) => {
    const richText = [];
    const actionTypes = groupByFp(groupByAllocationActionType, null, null, taskDays);
    map((taskDays, group1) => {
        group1 = groupNameParse(group1);
        if (!['Crew', 'Group', 'Generic'].includes(group1.allocationActionType)) {
            richText.push({
                text: `  ${group1.allocationActionType}\n`
            });
        }
        const allocations = groupByFp(groupByAllocation, null, null, taskDays);
        map((taskDays) => {
            if (['Crew', 'Group', 'Generic'].includes(group1.allocationActionType)) {
                richText.push({
                    font: { color: { argb: sliceFp(1, undefined, get('0.Allocation.Job.ForeColor', taskDays)) } },
                    text: `  ${get('0.Allocation.DisplayName', taskDays)}\n`
                });
            }
            if (description && get('0.Allocation.Description', taskDays)) {
                richText.push({
                    font: { italic: true, size: 10 },
                    text: `    ${get('0.Allocation.Description', taskDays)}\n`
                });
            }
            flow(
                sortBy(['DisplayName']),
                map((taskDay) => {
                    richText.push({
                        font: { color: { argb: sliceFp(1, undefined, get('ForeColor', taskDay)) } },
                        text: ['Generic', 'Material', 'Plant', 'Subcontractor'].includes(taskDay.ActionType)
                            ? `    ${taskDay.DisplayName}: ${taskDay.Amount} ${get('ResourceType.Unit.Name', taskDay)}\n`
                            : `    ${taskDay.DisplayName}\n`
                    });
                    if (description && get('Description', taskDay)) {
                        richText.push({
                            font: { italic: true, size: 10 },
                            text: `     ${get('Description', taskDay)}\n`
                        });
                    }
                })
            )(taskDays);
        }, allocations);
    }, actionTypes);
    return richText;
};
