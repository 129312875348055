import { gql } from 'graphql-tag';
import { TagFragment, CustomPropertyFragment } from 'client-shared/utility/fragments';

export const FormQuery = gql`
    ${TagFragment}
    query ($objectType: String = "Allocation", $filters: [FilterInput], $methodsByItem: [methodsByItemInput]) {
        cxAllocations(objectType: $objectType, filters: $filters, methodsByItem: $methodsByItem) {
            ObjectType
            Id
            Name
            DisplayName
            Description
            DayCount
            ActionStatus {
                Id
                _DisplayName: DisplayName
            }
            Shift {
                Id
                _DisplayName: DisplayName
            }
            Crew {
                Id
                _DisplayName: DisplayName
            }
            Job {
                Id
                _DisplayName: DisplayName
                _Address: Address {
                    Id
                    Street
                    StreetDetails
                    City
                    State {
                        Id
                        _Description: Description
                    }
                    ZipCode
                    Geocode {
                        Id
                        Lat
                        Lng
                    }
                }
            }
            JobActivity {
                Id
                _DisplayName: DisplayName
            }
            CurrentLifespan {
                Start
                End
            }
            ResourceSpans {
                Principal {
                    Id
                    _DisplayName: DisplayName
                    _ObjectType: ObjectType
                    _ResourceType: ResourceType {
                        Unit {
                            Name
                        }
                    }
                    _HasAuxiliaries: HasAuxiliaries
                }
                Range {
                    Start
                    End
                }
                Amount
                Auxiliaries {
                    Id
                    _DisplayName: DisplayName
                }
            }
            ResourceQuantitySpans {
                Supplier {
                    Id
                    _Name: Name
                }
                _ResourceType: ResourceType {
                    Unit {
                        Name
                    }
                }
                ResourceSubtype {
                    Id
                    _Name: Name
                }
                Range {
                    Start
                    End
                }
                Amount
                ResourceIds
            }
            Tags {
                ...TagFragment
            }
            ReturnToOwner
            Locked
            JobCosts {
                Id
                DisplayName
            }
        }
        cxCustomPropertys(objectType: "CustomProperty", filters: [{ name: "SupportedTypes", values: ["Allocation"] }]) {
                             ${CustomPropertyFragment}

        }
    }
`;
export const CxResourcesQuery = gql`
    query ($searchString: String, $start: String, $end: String, $objectType: [String] = [], $id: [String] = []) {
        cxResources(
            objectType: "Resource"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
                { name: "ObjectType", values: $objectType }
                #            { name: "ResourceSubtype.Id", values: $resourceSubtype }
                { name: "Id", values: $id }
                { name: "ResourceType.Allocatable", values: ["true"] }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            _ObjectType: ObjectType
            _HasAuxiliaries: HasAuxiliaries
        }
    }
`;

export const CxAuxiliariesQuery = gql`
    query ($searchString: String, $start: String, $end: String, $objectType: [String] = [], $id: [String] = []) {
        cxResources(
            objectType: "Resource"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
                { name: "ObjectType", values: $objectType }
                { name: "Id", values: $id }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            _ObjectType: ObjectType
            _ResourceType: ResourceType {
                Family
                Unit {
                    Name
                }
            }
            _Auxiliaries: Auxiliaries {
                Id
                _DisplayName: DisplayName
            }
        }
    }
`;

export const CxJobsQuery = gql`
    query ($searchString: String, $start: String, $end: String) {
        cxJobs(
            objectType: "Job"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            _JobActivities: JobActivities {
                Id
                _DisplayName: DisplayName
            }
            _Address: Address {
                Id
                Street
                StreetDetails
                City
                State {
                    Id
                    _Description: Description
                }
                ZipCode
                Geocode {
                    Id
                    Lat
                    Lng
                }
            }
        }
    }
`;
