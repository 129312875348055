import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'client-shared/utility';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { JobSchedule } from '../pages/jobschedule';
import { ResourceSchedule } from '../pages/resourceschedule';
import { ResourceScheduleSheet } from '../pages/resourceschedulesheet';
import { CrewSchedule } from '../pages/crewschedule';
import { OverView } from '../pages/OverView';
import JobTimeline from '../pages/jobtimeline';
import { JobCalendar } from 'client-shared/pages/jobcalendar';
import AllocationTimeline from '../pages/allocation-timeline';
import CrewTimeline from '../pages/crew-timeline';
import DailyTimeline from '../pages/daily-timeline';
import DailyJobTimeline from '../pages/daily-job-timeline';

import ResourceTimeline from '../pages/resource-timeline';
import { getGrids } from 'client-shared/grids';
import { JobsMap } from 'pages/jobs-map';
import { ShowEquipmentMap } from 'pages/ShowEquipmentMap';
import Index from '../pages/login_sso';
import { Report } from '../pages/reports/index';
import { TruckRoutes } from '../pages/truckroutes';

import { CrewGrid } from '../dashboard/crewgrid';
import { ResourceGrid } from '../dashboard/resourcegrid';
import { JobGrid } from '../dashboard/jobgrid';
import { ForeCastsGrid, JobCostsGrid, ResourceTypesGrid, TotalMetricsGraph } from '../dashboard/metrics';
import { ResourceUtilizationGrid } from '../dashboard/resourceutilizationgrid';

import { EditKkvList } from 'client-shared/entities/EditKkvList';
import { applyFeatures } from 'global-shared/menus';
import Enmenu, { addPath } from 'client-shared/components/Enmenu';
import { cloneDeep, filter, get, map, pipe, size } from 'lodash/fp';

import { isFeature } from './WithFeatures';
import Timesheet from 'client-shared/pages/timesheet-daily/index';
import TimeKeepingJobs from 'client-shared/pages/timekeeping-jobs';
import TimeKeepingCrews from 'client-shared/pages/timekeeping-crews';
import TimeKeepingApproval from 'client-shared/pages/timekeeping-approval';
import { JobForecast } from '../pages/jobforecast';
import { OauthCreds } from '../pages/OauthCreds';
import Desktop from '../desktop';
import { NoRoute } from '../pages/404';
import LoadingRoute from '../pages/loading';
import { isMobileOnly } from 'client-shared/utility/authHelpers';
import { getAuth } from '../Auth';
import { matchRouteWithMenuItem } from 'client-shared/utility/menuHelpers';

const PrepareMenu = (props) => {
    const [user] = useContext(UserContext);
    const loc = useLocation();
    const path = loc.pathname;
    if (!user) return null;
    const menu = get('customerInfo.ux.menus.desktopMenu', user);
    const _smartMenu = map(get(0), get(`congistics.user.smartMenus.${path.substring(1)}`, user));
    const items = applyFeatures(get('congistics.user.Features', user), cloneDeep(menu[path.substring(1)]));
    const smartMenu = matchRouteWithMenuItem(_smartMenu, addPath(path, items.items));
    return <Enmenu {...{ path, items, smartMenu }} />;
};

const PrepareReportsMenu = () => {
    const [user] = useContext(UserContext);
    const loc = useLocation();
    const path = loc.pathname;
    const items = cloneDeep(get('customerInfo.ux.reports.menus', user));
    const _smartMenu = map(get(0), get(`congistics.user.smartMenus.reports`, user));
    const smartMenu = matchRouteWithMenuItem(_smartMenu, addPath(path, items.items));
    return <Enmenu {...{ path, items, smartMenu }} />;
};

export const ProtectedComponent = ({ user, children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps*/
        async function f() {
            try {
                const _user = await getAuth(location);
                // console.log(_user);
                if (!_user) return navigate('/login');
            } catch {
                return navigate('/login');
            }
        }

        f();
    }, [user]);

    return children;
};

const RedirectPage = () => {
    React.useEffect(() => {
        window.location.replace(`//${window.location.host}/mobile`);
    }, []);
};

export const AppRoutes = () => {
    const [user] = useContext(UserContext);

    const [grids, setGrids] = useState([]);
    useEffect(() => {
        setGrids(getGrids(user));
    }, [user]);

    if (size(grids) === 0) return;

    if (isMobileOnly(user)) {
        window.location.replace(`//${window.location.host}/mobile`);
        return;
    }
    //

    const routez = [
        ['sso', <Index />],
        ['login', <Index />],
        [
            '/',
            <ProtectedComponent user={user}>
                <OverView />
            </ProtectedComponent>
        ],
        ['schedule', <PrepareMenu />],
        ['plan', <PrepareMenu />],
        ['admin', <PrepareMenu />],
        ['adminonly', <PrepareMenu />],
        ['resources', <PrepareMenu />],
        ['view', <PrepareMenu />],
        ['accounting', <PrepareMenu />],
        ['experimental', <PrepareMenu />],
        ['reports', <PrepareReportsMenu />],
        ['schedule/jobschedule', <JobSchedule />],
        ['schedule/resourceschedule', <ResourceSchedule />],
        ['schedule/crewschedule', <CrewSchedule />],
        ['schedule/resourceschedulesheet', <ResourceScheduleSheet />],
        ['schedule/dailyschedule', <DailyTimeline />],
        ['schedule/dailyjobschedule', <DailyJobTimeline />],
        ['schedule/truckroutes', <TruckRoutes />],
        ['view/companies', <grids.CxCompanys />],
        ['view/jobs', <grids.CxJobs />],
        ['view/jobsnotes', <grids.CxJobsNotes />],
        ['view/jobsmap', <JobsMap />],
        ['view/equipment-map', <ShowEquipmentMap />],
        ['view/contacts', <grids.CxContacts />],
        ['view/crewgrid', <CrewGrid />],
        ['view/resourcegrid', <ResourceGrid />],
        ['view/resourceutilizationgrid', <ResourceUtilizationGrid />],
        ['view/resourcetypesgrid', <ResourceTypesGrid />],
        ['view/totalmetricsgraph', <TotalMetricsGraph />],
        ['view/jobcostsgrid', <JobCostsGrid />],
        ['view/forecastsgrid', <ForeCastsGrid />],
        ['view/jobgrid', <JobGrid />],
        ['view/textmessages', <grids.CxTextMessages />],
        ['view/forms', <grids.CxForms />],
        ['accounting/timesheet/:projectId/:forDate?', <Timesheet />],
        ['accounting/timesheet-crews/:projectId/:crewId/:forDate?', <Timesheet />],
        ['accounting/timesheet', <TimeKeepingJobs />],
        ['accounting/timesheet-crews', <TimeKeepingCrews />],
        ['accounting/timesheetapproval', <TimeKeepingApproval />],
        ['accounting/timeentries', <grids.CxTimeEntrys />],
        ['accounting/costcodes', <grids.CxCostCodes />],
        ['accounting/jobcosts', <grids.CxJobCosts />],
        ['accounting/jobcostssummary', <grids.CxJobCostsSummary />],
        ['accounting/timeentrytypes', <grids.CxTimeEntryTypes />],
        ['accounting/employeepayroll', <grids.EmployeePayroll />],
        ['accounting/adppayroll', <grids.ADPPayroll />],
        ['accounting/equipmentbilling', <grids.EquipmentBilling />],
        ['accounting/subtypequantities', <grids.SubtypeQuantities />],
        ['accounting/activeprojects', <grids.ActiveProjects />],
        ['admin/lookupvalues', <grids.CxLookupValues />],
        ['admin/shifts', <grids.CxShifts />],
        ['admin/holidays', <grids.CxHolidays />],
        ['admin/resourcetypes', <grids.CxResourceTypes />],
        ['admin/users', <grids.CxUsers />],
        ['admin/import', <grids.CxBatch />],
        ['admin/actionstatuss', <grids.CxActionStatuss />],
        ['admin/customproperties', <grids.CxCustomPropertys />],
        ['admin/smspartiesgroups', <grids.CxPartyGroups />],
        ['admin/changes', <grids.CxChanges />],
        ['admin/forms', <grids.CxTemplateForms />],
        ['admin/developercredentials', <OauthCreds />],
        ['admin/templatejobactivitys', <grids.CxTemplateJobActivitys />],
        ['admin/applicationlicenses', <grids.CxApplicationlicenses />],
        ['adminonly/lookupvalues', <grids.CxLookupValues />],
        ['adminonly/shifts', <grids.CxShifts />],
        ['adminonly/holidays', <grids.CxHolidays />],
        ['adminonly/resourcetypes', <grids.CxResourceTypes />],
        ['adminonly/users', <grids.CxUsers />],
        ['adminonly/actionstatuss', <grids.CxActionStatuss />],
        ['adminonly/customproperties', <grids.CxCustomPropertys />],
        ['adminonly/smspartiesgroups', <grids.CxPartyGroups />],
        ['adminonly/changes', <grids.CxChanges />],
        ['adminonly/forms', <grids.CxTemplateForms />],
        ['adminonly/developercredentials', <OauthCreds />],
        ['adminonly/applicationlicenses', <grids.CxApplicationlicenses />],
        ['resources/subcontractors', <grids.CxSubcontractors />],
        ['resources/plants', <grids.CxPlants />],
        ['resources/equipment', <grids.CxEquipments />],
        ['resources/employees', <grids.CxEmployees />],
        ['resources/material', <grids.CxMaterials />],
        ['resources/crews', <grids.CxCrews />],
        ['plan/taskdays', <grids.CxTaskDays />],
        ['plan/jobTimeline', <JobTimeline />],
        ['plan/fieldschedule', <grids.FieldSchedule />],
        ['plan/allocationTimeline', <AllocationTimeline />],
        ['plan/crewTimeline', <CrewTimeline />],
        ['plan/resourceTimeline', <ResourceTimeline />],
        ['plan/forecasts', <grids.CxForecasts />],
        ['plan/jobforecast', <JobForecast />],
        ['plan/jobcalendar', <JobCalendar />],
        ['experimental/todos', <grids.CxToDos />],
        ['experimental/configvalues', <grids.CxConfigValues />],
        ['experimental/editkkvlist', <EditKkvList />],
        ['plan/allocations', <grids.CxAllocations />],
        ['reports/:key', <Report />]
    ];

    const routes = pipe(
        filter(isFeature(get('congistics.user.Features', user))),
        map(([k, v]) => <Route path={k} element={v} key={k} />)
    )(routez);

    return (
        <Routes>
            <Route path="/" element={<Desktop />}>
                {routes}

                <Route path="*" element={user ? <NoRoute /> : <LoadingRoute />} />
            </Route>
            <Route path={'mobile'} element={<RedirectPage />} />
        </Routes>
    );
};
