import React from 'react';
import PropTypes from 'prop-types';
import { Field, AutoComplete } from 'client-shared/components/form';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxTemplateJobActivity';
import inputModel from 'cb-schema/inputmodelfull/CxTemplateJobActivity';
import { object, string } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { get } from 'lodash/fp';
import { gql } from 'graphql-tag';

// initialize model properties.
const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required')
});

const Form = (props) => {
    const { classes } = props;
    return (
        <form noValidate autoComplete="off">
            <Field
                type="text"
                label="Name"
                name="Name"
                component={AutoComplete}
                className={classes.selectField}
                optionsQuery={gql`
                    query ($searchString: String) {
                        cxCaches(
                            objectType: "Cache"
                            filters: [{ name: "String", values: ["JobActivities.Name", $searchString] }]
                        ) {
                            JobActivities {
                                Name
                            }
                        }
                    }
                `}
                // getValue={(event, value) => (value ? value.Name : value)}
                getOptions={(options) => get('0.JobActivities', options)}
                freeSolo={true}
                optionLabelProperty="Name"
            />
            <Field
                type="text"
                label="Description"
                name="Description"
                component={TextField}
                className={classes.textBoxField}
                multiline
                variant="outlined"
                margin="normal"
            />
            <Field
                type="color"
                label="ForeColor"
                name="ForeColor"
                component={TextField}
                className={classes.textField}
            />
            <Field
                type="color"
                label="BackColor"
                name="BackColor"
                component={TextField}
                className={classes.textField}
            />
        </form>
    );
};

export const EditCxTemplateJobActivity = ({ open, formQueryValues, onClose }) => {
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    return (
        <DialogForm
            open={open}
            title="Template Job Activity"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxTemplateJobActivity"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxTemplateJobActivity.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
