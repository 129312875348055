/**
 * Created by mark on 12/3/2019.
 */
import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { getFilter, UserContext } from 'client-shared/utility';
import cxParty from 'cb-schema/jsonschema/CxParty';

export const PartyFilter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.components', 'partylist.js', { cxParty: cxParty });
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
