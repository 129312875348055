/**
 * Created by mark on 6/15/2020.
 */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxPlant';
import inputModel from 'cb-schema/inputmodelfull/CxPlant';
import {
    Field,
    LookupValueEditor,
    EmailEditor,
    emailValidationSchema,
    PhoneEditor,
    phoneValidationSchema,
    AddressEditor,
    addressValidationSchema,
    ContactEditor,
    contactValidationSchema,
    CurrentLifespanEditor,
    currentLifespanValidationSchema
} from 'client-shared/components/form';
import { object, string, number } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import { CapacitiesEditor, capacitiesValidationSchema } from './components/CapacitiesEditor';
import cloneDeep from 'lodash/cloneDeep';
import { UserContext } from 'client-shared/utility';
import format from 'date-fns/fp/format';
import { Section } from 'client-shared/components/Section';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const useStyles = makeStyles((theme) => ({
    tab: {
        fontSize: 'small',
        minWidth: '10px'
    }
}));

// initialize model properties.
const validationSchema = object({
    CompanyType: object({
        Id: number().moreThan(0, 'Required')
    }),
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Emails: emailValidationSchema,
    PhoneNumbers: phoneValidationSchema,
    Address: addressValidationSchema,
    Contacts: contactValidationSchema,
    Capacities: capacitiesValidationSchema,
    CurrentLifespan: currentLifespanValidationSchema
});

const General = (props) => {
    const { classes } = props;
    return (
        <div>
            <Field
                type="text"
                label="Type"
                name="CompanyType"
                component={LookupValueEditor}
                className={classes.selectField}
                objectType="CompanyType"
            />
            <Field type="text" label="Plant #" name="AccountCode" component={TextField} className={classes.textField} />
            <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />

            <div>
                {' '}
                <Field
                    type="color"
                    label="ForeColor"
                    name="ForeColor"
                    component={TextField}
                    className={classes.textField}
                />
                <Field
                    type="color"
                    label="BackColor"
                    name="BackColor"
                    component={TextField}
                    className={classes.textField}
                />
            </div>
            <Section title="Lifespan">
                <Field type="text" name="CurrentLifespan" component={CurrentLifespanEditor} classes={classes} />
            </Section>
            <Field type="text" label="Address" name="Address" component={AddressEditor} classes={classes} />
            <Field type="text" label="Emails" name="Emails" component={EmailEditor} classes={classes} />
            <Field type="text" label="Phone #" name="PhoneNumbers" component={PhoneEditor} classes={classes} />
            <Field type="text" label="Contacts" name="Contacts" component={ContactEditor} classes={classes} />
            <div>
                <Field
                    type="text"
                    label="Description"
                    name="Description"
                    component={TextField}
                    className={classes.textBoxField}
                    multiline
                    variant="outlined"
                    margin="normal"
                />
            </div>
        </div>
    );
};

const Capacities = (props) => {
    const { classes } = props;
    return (
        <div style={{ paddingTop: '10px' }}>
            <Field type="text" label="Capacities" name="Capacities" component={CapacitiesEditor} classes={classes} />
        </div>
    );
};

const Form = (props) => {
    const { classes, data, values, setFieldValue } = props;
    const sectionClasses = useStyles();
    const [tab, setTab] = useState(0);

    const handleChange = (event, tab) => {
        setTab(tab);
    };
    const showError = Object.keys(props.errors);
    const error = showError.length ? `There are errors in ${showError.join(', ')}` : null;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Tabs value={tab} onChange={handleChange}>
                    <Tab classes={{ root: sectionClasses.tab }} label="General" />
                    <Tab classes={{ root: sectionClasses.tab }} label="Capacities" />
                </Tabs>
                <FormControl error={showError.length !== 0}>
                    <FormHelperText>{error}</FormHelperText>
                </FormControl>
                {tab === 0 && <General classes={classes} data={data} values={values} setFieldValue={setFieldValue} />}
                {tab === 1 && (
                    <Capacities classes={classes} data={data} values={values} setFieldValue={setFieldValue} />
                )}
            </form>
        </React.Fragment>
    );
};

export const EditCxPlant = ({ open, formQueryValues, onClose }) => {
    const [user] = useContext(UserContext);
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.CompanyType = { Id: '-1', _DisplayName: '' };
    initialValues.Address.Autocode = true;
    initialValues.Address.State = user.congistics.defaultCompany.Address.State;
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.End = '2999-01-01T00:00:00';
    initialValues.Capabilities = [];
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    return (
        <DialogForm
            open={open}
            title="Plant"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxPlant"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxPlant.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
