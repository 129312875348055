import Auth from '@aws-amplify/auth';
import { getClient, parseDate } from 'client-shared/utility';
import { get, head, set, map, fromPairs, isEmpty, find, pipe, filter, includes } from 'lodash/fp';
import isBefore from 'date-fns/isBefore';
import addDays from 'date-fns/addDays';

import format from 'date-fns/fp/format';
import { retryN } from 'global-shared/utils/utils';
import { getQueryParam } from 'client-shared/utility/httpHelpers';
import {
    extractAllFeatures,
    getAllPaths,
    generateSmartMenus,
    getAllTopLevelSmartMenus,
    extractRoutes
} from 'global-shared/menus';

import { announceUser, setNodebbSessionCookie } from 'client-shared/utility/authHelpers';

let customerInfo;

export const generateSmartQwickMenu = (_in, menus) => {
    const urls = getAllTopLevelSmartMenus(_in);
    return matchRouteWithMenuItem(urls, menus);
};

export function matchRouteWithMenuItem(urls, items) {
    const withRoutes = extractRoutes(items);
    return pipe(filter((each) => includes(each.route, urls)))(withRoutes);
}

export const getDefaultPartyGroup = (user) => {
    const groups = get('Party.PartyGroups', user);
    if (groups.length === 1) return groups[0];
    const number = find({ DisplayName: 'Company Group' }, groups);
    if (number) return number;
    return groups[0];
};

export const decorateWithCongisticsUser = async (_in) => {
    customerInfo = JSON.parse(localStorage.getItem('APP_SETTINGS'));
    const userId = get('attributes.email', _in);
    if (!userId) return { cognito: _in.cognito, congistics: null, customerInfo };
    const client = await getClient();
    const data = await announceUser(client, userId);
    localStorage.setItem('lastUser', JSON.stringify(get('data', data)));

    const expiry = parseDate(get('data.cxApplicationLicenses.0.Expiration', data));
    const user = { ...head(get('data.cxUsers', data)) };

    if (!user || isEmpty(user))
        throw new Error(`Please be sure that there is an user with an email address matching ${userId}.`);

    if (isBefore(expiry, new Date()))
        throw new Error(
            `Your license expired on  ${format(
                'MMM do',
                expiry
            )}.  Renew by contacting us at feedback@congistics.com or call 203 529-1212`
        );
    user.currentPartyGroup = getDefaultPartyGroup(user);
    user.smsNumber = get('currentPartyGroup.NumericGroupNumber', user);
    user.smartMenus = generateSmartMenus(user);
    const meuz = getAllPaths({
        ...get('ux.menus.desktopMenu', customerInfo),
        ...{ reports: get('ux.reports.menus', customerInfo) }
    });

    user.quickMenu = generateSmartQwickMenu(user, meuz);

    if (userId === 'queequeg@congistics.com')
        //Give queequeg every possible feature
        user.Features = extractAllFeatures(get('ux.menus', customerInfo));
    return {
        userId,
        cognito: _in,
        congistics: {
            user,
            message: isBefore(expiry, addDays(new Date(), 14))
                ? `Your license expires on ${format(
                      'MMM do',
                      expiry
                  )}. Renew by contacting us at feedback@congistics.com or call 203 529-1212`
                : null,
            defaultCompany: data.data.cxCompanys[0],
            shifts: data.data.cxShifts[0],
            configValues: fromPairs(map((each) => [each.Key, each.Value], data.data.cxConfigValues)),
            cxApplicationLicenses: data.data.cxApplicationLicenses[0]
        },
        customerInfo
    };
};

export async function getAuth(location) {
    let user;
    if (localStorage.getItem('APP_IN_TEST') === 'true')
        user = {
            attributes: {
                sub: '270fe708-03a0-4695-904f-06fcb48ad8cc',
                email_verified: true,
                email: 'queequeg@congistics.com'
            }
        };
    else user = await retryN(3, 1000, () => Auth.currentAuthenticatedUser())();

    const fakeUser = getQueryParam('impersonate', location);

    if (fakeUser && user.attributes.email === 'queequeg@congistics.com') {
        //let queequeg impersonate someone else
        user = set('attributes.email', fakeUser, user);
        sessionStorage.setItem('impersonating', fakeUser);
    }
    const decoratedUser = await decorateWithCongisticsUser(user);
    console.log(decoratedUser);
    await setNodebbSessionCookie({ userId: decoratedUser.userId, ...get('congistics.user.Party', decoratedUser) });
    doGA({
        eventCategory: get('customerInfo.customer', decoratedUser),
        eventAction: 'load-app',
        eventLabel: get('congistics.user.Id', decoratedUser)
    });
    return decoratedUser;
}

function doGA({ eventCategory, eventAction, eventLabel }) {
    if (!window.ga) return;
    window.ga('send', 'event', {
        eventCategory,
        eventAction,
        eventLabel
    });
}
