import { gql, useApolloClient } from '@apollo/client';
import React, { useEffect, useState, useContext } from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { get, map, reject, find, curry, pipe, pick, last, set, flatten, filter, size } from 'lodash/fp';

import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';

import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNotifications } from 'client-shared/components/notifier2';
import { transformPropertyArray, pipeP, fixProp } from 'global-shared/utils/utils';
import { getExtension, woExtension } from 'global-shared/utils/files';
import { getGraphqlHelper, fetchMinimalEntity, hasProperty } from 'client-shared/gqlhelpers/queryHelpers';
import { cleanObject, isData, watchQueries } from 'client-shared/utility';
import mime from 'mime';
import { isIOS } from 'react-device-detect';

import copy from 'copy-to-clipboard';
import { toJson, handleFetchErrors, saveFile } from 'client-shared/utility/httpHelpers';

/** Dialog  **/

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';

/** MIME icons **/

import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import GridOnIcon from '@mui/icons-material/GridOn';

import { useBusy, Busy } from 'client-shared/components/Busy';
import { DialogYesNo, useModal } from 'client-shared/components/Dialogs';
import { UserContext } from 'client-shared/utility';
import { isMobileOnly, pFetch } from 'client-shared/utility/authHelpers';
import { getSharableAttachmentLink, useAttachments } from 'client-shared/components/attachments/useAttachments';

const DRAWER_WIDTH = 260;

const useStyles = makeStyles((theme) => ({
    stickToBottom: {
        paddingTop: theme.spacing(2),
        width: '100%',
        position: 'absolute',
        bottom: 0
    },
    drawerPaper: {
        width: DRAWER_WIDTH,
        zIndex: 1200
    },
    drawerMobile: {
        width: '100%',
        zIndex: 1200
    },
    topList: {
        'padding-bottom': 50
    },
    fab: {
        float: 'right'
    }
}));

let differentiator = 0;

const drawerQuery = gql`
    query {
        drawerState @client {
            visible
            id
            type
            differentiator
        }
    }
`;

export const showAttachmentDrawer = (client, visible, id, type) => {
    client.writeQuery({
        query: drawerQuery,
        data: {
            drawerState: { differentiator: differentiator++, ...{ visible, id, type } }
        }
    });
};

export function Attachments(props) {
    const [attachmentState, setAttachmentState] = useState({
        visible: false,
        id: null,
        type: null
    });
    const { visible, id, type } = attachmentState;

    const [attachments, setAttachments] = useState([]);
    const [docTypes, setDocTypes] = useState([]);
    const [entity, setEntity] = useState({});
    const [anchorEl, setAnchorEl] = useState(null); //menu state
    const [dialogOpen, setDialogOpen] = useState(false); //dialog state
    const [currentDoc, setCurrentDoc] = useState({
        Key: 'null',
        Name: 'null',
        Description: 'null',
        DocumentTypes: ['null']
    });
    const [isBusy, setBusy, setBusyP] = useBusy();
    const [showOverwrite, toggleOverwrite] = useModal();
    const [user] = useContext(UserContext);
    const { openSBTransient, openSBTransientP } = useNotifications();

    const reallySetAttachments = (_in) => {
        console.log(_in);
        _in = cleanObject(_in);
        const formz = pipe(
            map(get('Attachments')),
            flatten,
            filter((each) => size(each.Approvals) > 0)
        )(get('Forms', _in));
        setAttachments([...get('Attachments', _in), ...formz]);
        return _in;
    };

    const toggleAttachment = (visible, id, type) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        showAttachmentDrawer(client, visible, id, type);
    };

    const client = useApolloClient();
    const [addUpdateAttachment2, _deleteAttachment] = useAttachments(client, type);
    const theme = useTheme();
    const classes = useStyles(theme);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const GQL = getGraphqlHelper(type);
    const hasForm = hasProperty(type, 'Forms');

    const DOCTYPEGQL = gql`
        query {
            docTypes: cxLookupValues(objectType: "DocumentType") {
                Id
                Name
                ObjectType
                __typename
            }
        }
    `;

    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        const observable = watchQueries(client, [drawerQuery]);
        const subscription = observable.subscribe((data) => {
            if (!isData(data)) {
                return;
            }
            setAttachmentState(get('data.drawerState', data));
        });
        return () => subscription.unsubscribe();
    }, []);

    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        if (!GQL) return;
        const queryFragment = hasForm
            ? makeFormAttachmentFragment(GQL.entityClass)
            : makeAttachmentFragment(GQL.entityClass);
        pipeP(fetchMinimalEntity(client, GQL, [queryFragment]), reallySetAttachments, setEntity)(id);
    }, [id, GQL, client]);

    useEffect(() => {
        if (!GQL) return;
        pipeP(
            () =>
                client.query({
                    query: DOCTYPEGQL,
                    fetchPolicy: 'network-only'
                }),
            get('data.docTypes'),
            map(cleanObject),
            setDocTypes
        )(id);
    }, [id, GQL, client, DOCTYPEGQL]);

    const handleReplace = curry((attachment, event) => {
        setAnchorEl(null);
        const files = event.target.files;
        if (!files) return;
        setBusy(true);
        uploadFile(openSBTransientP, attachment.Key, files[0])
            .then(setBusyP(false))
            .then(() => openSBTransient(`Updated ${attachment.Name}`, { variant: 'success' }))
            .catch((e) => {
                setBusy(false);
            });
    });

    const handleUpload = (event) => {
        const files = event.target.files;
        if (!files) return;
        const state = getCurrentState(attachments);
        const exists = findByName(files[0].name, state);
        if (exists && files.length === 1) {
            //toggleOverwrite(doUpload(files));
            toggleOverwrite((res) => (res === 'left' ? doUpload(files, exists) : doUpload(files, null)));
            return;
        }
        doUpload(files, null);
        event.target.value = null;
    };

    const handleDownload = (forceNormal, attachment) => () => {
        const { Key } = attachment;
        pFetch(`/api/file/${encodeURIComponent(Key)}`)
            .then(handleFetchErrors)
            .then(handleDevices(forceNormal, attachment))
            .catch(openSBTransientP('message', { variant: 'error' }));
    };

    const handleGetSharableLink = (key) => () =>
        getSharableAttachmentLink(key)
            .then((res) => copy(res.url))
            .then(() => openSBTransient("The link is on the clipboard. It's valid for 1 week", { variant: 'success' }))
            .catch(openSBTransientP('message', { variant: 'error' }));

    //
    const doUpload = curry((files, existing) => {
        setBusy(true);
        Promise.all(map((each) => uploadFile(openSBTransientP, `${woExtension(each.name)}*`, each), files))
            .then(
                map(([res, file]) =>
                    existing ? set('Key', get('Key', res), existing) : { Id: 0, Key: get('Key', res), Name: file }
                )
            )
            .then(addUpdateAttachments(id))
            .then(reallySetAttachments)
            .then(setBusyP(false))
            .catch((e) => {
                setBusy(false);
                console.error(e);
            });
    });

    const addUpdateAttachments = curry((entityId, documents) => {
        return addUpdateAttachment2(entityId, documents);
    });

    const deleteAttachment = (entityId, documentId) => () => {
        return _deleteAttachment(entityId, documentId);
    };

    const handleDelete = (toDelete) => () => {
        setAnchorEl(null);
        pFetch(`/api/file/${toDelete.Key}`, { method: 'DELETE' })
            .then(deleteAttachment(entity.Id, toDelete.Id))
            .then(reallySetAttachments);
    };

    function submitDialog() {
        addUpdateAttachments(id, [fixName(currentDoc)])
            .then(reallySetAttachments)
            .then(() => setDialogOpen(false))
            .catch(console.error);
    }

    const handleClick = curry((doc, event) => {
        setCurrentDoc(doc);
        setAnchorEl(event.currentTarget);
    });

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleChange(e, v) {
        if (!e) return;
        if (v) {
            setCurrentDoc((state) => ({ ...state, ...{ DocumentTypes: v } }));
            return;
        }
        const key = e.currentTarget.id;
        const value = e.currentTarget.value;
        setCurrentDoc((state) => ({ ...state, ...{ [key]: value } }));
    }

    const hideFromMobile = (_in) => {
        if (!user) return [];
        if (!isMobileOnly(user)) return _in;
        return reject({ Private: true })(_in);
    };

    return (
        <Drawer
            anchor="right"
            className={classes.drawer}
            open={visible}
            onClose={toggleAttachment(false, entity.Id, type)}
            classes={{
                paper: fullScreen ? classes.drawerMobile : classes.drawerPaper
            }}
        >
            <div>
                <ListItem>
                    <IconButton onClick={toggleAttachment(false, entity.Id, type)}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <Typography variant="subtitle2">{get('DisplayName', entity)}</Typography>
                </ListItem>
            </div>
            <List dense={true} className={classes.topList}>
                {pipe(
                    hideFromMobile,
                    map((each) => (
                        <ListItem button onClick={handleDownload(false, each)} key={each.Id}>
                            <ListItemIcon>{getIcon(each)}</ListItemIcon>
                            <Tooltip title={each.Description}>
                                <ListItemText style={{ overflowWrap: 'break-word' }} primary={each.Name} />
                            </Tooltip>
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="more" onClick={handleClick(each)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                )(attachments)}
                <Busy isShowing={isBusy} />
            </List>
            <Menu
                style={{ zIndex: '1400' }}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {!currentDoc.Completed && (
                    <div>
                        <MenuItem onClick={pipe(handleClose, (_) => setDialogOpen(true))}>Edit</MenuItem>
                        <MenuItem onClick={handleDelete(currentDoc)}>Delete</MenuItem>
                        <label htmlFor="replace-document">
                            <input
                                style={{ display: 'none' }}
                                id="replace-document"
                                name="replace-document"
                                type="file"
                                onChange={handleReplace(currentDoc)}
                            />
                            <MenuItem>Replace</MenuItem>
                        </label>
                    </div>
                )}
                <MenuItem onClick={handleDownload(true, currentDoc)}>Download</MenuItem>
                <MenuItem onClick={handleGetSharableLink(currentDoc.Key)}>Copy Sharable Link</MenuItem>
            </Menu>
            <Dialog disablePortal open={dialogOpen} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">Details {currentDoc.Name}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Name"
                        id="Name"
                        onChange={handleChange}
                        value={currentDoc.Name}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        multiline={true}
                        id="Description"
                        onChange={handleChange}
                        value={currentDoc.Description}
                        fullWidth
                    />
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        id="DocumentTypes"
                        value={currentDoc.DocumentTypes || [{ name: 'foo' }]}
                        options={docTypes}
                        getOptionLabel={get('Name')}
                        getOptionSelected={(a, b) => a.Id === b.Id}
                        onChange={handleChange}
                        fullwidth="true"
                        renderInput={(params) => <TextField {...params} label="Document Types" />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={(_) => setDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(_) => submitDialog()} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <DialogYesNo
                state={showOverwrite}
                text="You've already attached a file with the same name."
                left="Overwrite existing"
                right="Create new"
                hide={toggleOverwrite}
            />
            <Box zIndex="tooltip" className={classes.stickToBottom} sx={{ pr: 1, pb: 1 }}>
                <Paper>
                    <label htmlFor="upload-document">
                        <input
                            style={{ display: 'none' }}
                            id="upload-document"
                            name="upload-document"
                            type="file"
                            onChange={handleUpload}
                            multiple
                        />
                        <Fab color="primary" className={classes.fab} size="small" component="span" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </label>
                </Paper>
            </Box>
        </Drawer>
    );
}

export const uploadFile = (notifier, name, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('originalFile', name);
    return pFetch('/api/file', {
        method: 'POST',
        body: formData
    })
        .then(toJson)
        .then((json) => [json, file.name])
        .catch(notifier('message', { variant: 'error' }));
};

export const makeFormAttachmentFragment = (on) => ({
    fragmentName: 'ATTACHMENTS',
    fragment: `fragment ATTACHMENTS on ${on} {
       Attachments{
            Id
            Key
            Name
            Description
            Private,
            Creator {
            Id
            DisplayName
           }
           Completed
           LastModified
           Created
            DocumentTypes {
                Id
                Name
            }

        }
        Forms {
        Attachments{
            Id
            Key
            Name
            Description
            Creator {
            Id
            DisplayName
           }
           Completed
           Created
           LastModified
           Approvals {
                Approver {
                Id
                DisplayName
                }
                Completed
                AsOf
           }
           DocumentTypes {
                Id
                Name
            }
        }
        }
    }`
});

export const makeAttachmentFragment = (on) => ({
    fragmentName: 'ATTACHMENTS',
    fragment: `fragment ATTACHMENTS on ${on} {
       Attachments{
            Id
            Key
            Name
            Description
            Creator {
            Id
            DisplayName
           }
           Private
           Completed
           LastModified
           Created
            DocumentTypes {
                Id
                Name
            }

        }
    }`
});

export const getCurrentState = map(transformPropertyArray(pick('Id'), 'DocumentTypes'));

export const findByName = (Name, state) => find({ Name }, state);

const fixUrl = (attachment) => {
    const { Key, Name } = attachment;
    if (Name) return Name;
    return Key;
};

export const handleDevices = curry((forceNormal, attachment, response) => {
    const { Key } = attachment;
    if (forceNormal || isIOS) return response.blob().then(saveFile(fixUrl(attachment)));
    response.arrayBuffer().then((buf) => {
        const type = mime.getType(Key);
        const blob = new Blob([buf], { type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
    });
});

/**
 * We need to preserve the original extension if the user changes the name
 * @param attachment
 * @returns {*}
 */
const fixName = (attachment) => {
    const newExt = last(attachment.Name.split('.'));
    const canonicalExt = last(attachment.Key.split('.'));
    if (newExt === canonicalExt) return attachment;
    return fixProp('Name', (v) => `${v}.${canonicalExt}`, attachment);
};

export const getIcon = (_in) => {
    //console.log(_in)
    if (_in.Completed) return <ContentPasteIcon />;
    const ext = getExtension(_in.Name);
    if (['png', 'jpeg', 'jpg', 'gif', 'svg'].includes(ext)) return <ImageIcon />;
    if (['pdf'].includes(ext)) return <PictureAsPdfIcon />;
    if (['mp3', 'wav'].includes(ext)) return <LibraryMusicIcon />;
    if (['avi', 'mp4', 'mkv'].includes(ext)) return <VideoLibraryIcon />;
    if (['xls', 'csv'].includes(ext)) return <GridOnIcon />;

    return <LibraryBooksIcon />;
};
