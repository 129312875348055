export const allowDrop = (ev) => {
    ev.preventDefault();
};

export const handleDrag = (draggedItems) => (ev) => {
    ev.dataTransfer.setData('cell', JSON.stringify(draggedItems));
};

export const handleClipBoardDrop = (clipBoardItems, onChange) => (ev) => {
    ev.preventDefault();
    const data = ev.dataTransfer.getData('cell');
    let droppedItems = JSON.parse(data);
    onChange([...clipBoardItems, ...droppedItems]);
};
