/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxShift';
import inputModel from 'cb-schema/inputmodelfull/CxShift';
import { object, string, number, array } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import { DurationEditor, durationValidationSchema, AutoComplete } from 'client-shared/components/form';
import { FormControl, FormHelperText } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import { Section } from 'client-shared/components/Section';
import { differenceBy, get } from 'lodash/fp';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const daysOfWeek = [
    { Day: 0, _Name: 'Sunday' },
    { Day: 1, _Name: 'Monday' },
    { Day: 2, _Name: 'Tuesday' },
    { Day: 3, _Name: 'Wednesday' },
    { Day: 4, _Name: 'Thursday' },
    { Day: 5, _Name: 'Friday' },
    { Day: 6, _Name: 'Saturday' }
];

const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Days: array()
        .of(
            object({
                Day: number().required('Required'),
                Start: string().required('Required'),
                Minutes: durationValidationSchema
            })
        )
        .min(1, 'Must have at least one day')
});
const Form = (props) => {
    const { classes, values, errors, touched } = props;
    const fieldError = Array.isArray(get('Days', errors)) ? '' : get('Days', errors);
    const showError = get('Days', touched) && !!fieldError;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
                <Field
                    type="text"
                    label="Description"
                    name="Description"
                    component={TextField}
                    className={classes.textField}
                />
                <Field type="checkbox" Label={{ label: 'Default' }} name="Default" component={CheckboxWithLabel} />
                <Field
                    type="checkbox"
                    Label={{ label: 'Use Holidays' }}
                    name="UseHolidays"
                    component={CheckboxWithLabel}
                />
                <Section title="Days">
                    <FormControl error={showError}>
                        <FieldArray
                            name="Days"
                            render={(arrayHelpers) => (
                                <div>
                                    {values.Days.map((day, index) => (
                                        <div key={index}>
                                            <Field
                                                type="text"
                                                label="Day"
                                                name={`Days.${index}.Day`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                options={daysOfWeek}
                                                optionLabelProperty="_Name"
                                                optionIdProperty="Day"
                                                filterOptions={(options) => differenceBy('Day', options, values.Days)}
                                                disableClearable
                                            />
                                            <Field
                                                type="time"
                                                label="Start"
                                                name={`Days.${index}.Start`}
                                                component={TextField}
                                                className={classes.textField}
                                            />
                                            <Field
                                                name={`Days.${index}.Minutes`}
                                                component={DurationEditor}
                                                className={classes.extraShortTextField}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    ))}
                                    {values.Days.length < 7 && (
                                        <IconButton
                                            onClick={() => {
                                                arrayHelpers.push({
                                                    Day: differenceBy('Day', daysOfWeek, values.Days)[0].Day,
                                                    _Name: '',
                                                    Start: '08:00',
                                                    Minutes: 8 * 60
                                                });
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    )}
                                </div>
                            )}
                        />
                        <FormHelperText>{fieldError}</FormHelperText>
                    </FormControl>
                </Section>
            </form>
        </React.Fragment>
    );
};

export const EditCxShift = ({ open, formQueryValues, onClose }) => {
    let initialValues = cloneDeep(model);
    return (
        <DialogForm
            open={open}
            title="Shift"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxShift"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxShift.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
