/**
 * Created by mark on 11/25/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery, CxResourcesQuery, CxJobsQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxTaskDay';
import inputModel from 'cb-schema/inputmodelfull/CxTaskDay';
import {
    AutoComplete,
    DateEditor,
    TimeEditor,
    TagEditor,
    tagValidationSchema,
    currentLifespanTimeValidationSchema
} from 'client-shared/components/form';
import { object, number, array } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { flow, get } from 'lodash/fp';
import { parseDate, get24HourEndDate } from 'client-shared/utility';
import addDays from 'date-fns/fp/addDays';
import format from 'date-fns/fp/format';
import { Section } from 'client-shared/components/Section';
import TextFieldMUI from '@mui/material/TextField';
import { gql } from 'graphql-tag';

const validationSchema = object({
    Job: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    JobActivity: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    // ActionStatus: object({
    //     Id: number().moreThan(0, 'Required')
    // }),
    Principals: array().min(1, 'Required'),
    Amount: number().min(0.0001, 'Must be greater than 0').max(999999999, 'Must be less than 999,999,999'),
    // Supplier: object({
    //     Id: number().moreThan(0, 'Required')
    // }),
    // ResourceType: object({
    //     Id: number().moreThan(0, 'Required')
    // }),
    CurrentLifespan: currentLifespanTimeValidationSchema,
    Tags: tagValidationSchema
});

const Form = (props) => {
    const { classes, data, values, setFieldValue } = props;

    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <div>
                    <Field
                        type="text"
                        label="Job"
                        name="Job"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="_DisplayName"
                        optionsQuery={CxJobsQuery}
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00", new Date()),
                            end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                        onChange={(event, value) => {
                            if (value) {
                                setFieldValue('JobActivity', get('_JobActivities.0', value));
                            } else {
                                setFieldValue('JobActivity', { Id: '0', _DisplayName: '' });
                            }
                        }}
                    />
                    <Field
                        type="text"
                        label="Job Activity"
                        name="JobActivity"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="_DisplayName"
                        optionsQuery={gql`
                            query ($searchString: String, $id: String) {
                                cxJobActivitys(
                                    objectType: "JobActivity"
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Job.Id", values: [$id] }
                                    ]
                                ) {
                                    Id
                                    _DisplayName: DisplayName
                                }
                            }
                        `}
                        queryVariables={(values) => ({
                            id: get('Job.Id', values) || '-1'
                        })}
                        disableClearable
                    />
                    {/*<Field*/}
                    {/*    type="text"*/}
                    {/*    label="Status"*/}
                    {/*    name="ActionStatus"*/}
                    {/*    component={AutoComplete}*/}
                    {/*    className={classes.selectField}*/}
                    {/*    optionsQuery={gql`*/}
                    {/*        query {*/}
                    {/*            cxActionStatuss(objectType: "ActionStatus") {*/}
                    {/*                Id*/}
                    {/*                _DisplayName: DisplayName*/}
                    {/*            }*/}
                    {/*        }*/}
                    {/*    `}*/}
                    {/*    optionLabelProperty="_DisplayName"*/}
                    {/*    disableClearable*/}
                    {/*/>*/}
                    <Field
                        type="checkbox"
                        Label={{ label: 'Supervisor' }}
                        name="ShiftSupervisor"
                        component={CheckboxWithLabel}
                        disabled={get('Principals[0]._ObjectType', values) !== 'Employee'}
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Resource"
                        name="Principals"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={CxResourcesQuery}
                        optionLabelProperty="_DisplayName"
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                            end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date()),
                            objectType: ['Employee', 'Equipment', 'Material']
                        })}
                        initialize={false}
                        groupBy={(option) => option._ObjectType}
                    />
                    <Field
                        type="number"
                        label="Amount"
                        name="Amount"
                        component={TextField}
                        className={classes.textField}
                        disabled={get('Principals[0]._ResourceType.Family', values) !== 'Material'}
                    />
                    <TextFieldMUI
                        label="Unit"
                        value={get('Principals[0]._ResourceType.Unit.Name', values)}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </div>
                <div>
                    <Section title="Lifespan">
                        <Field
                            type="date"
                            label="Date"
                            name="CurrentLifespan.Start"
                            component={DateEditor}
                            className={classes.dateTimeField}
                            onChange={(event, newDateTime) => {
                                const endDateTime = get24HourEndDate(newDateTime, values.CurrentLifespan.End);
                                setFieldValue('CurrentLifespan.End', format("yyyy-MM-dd'T'HH:mm", endDateTime));
                            }}
                        />
                        <Field
                            type="time"
                            label="Start"
                            name="CurrentLifespan.Start"
                            component={TimeEditor}
                            className={classes.dateTimeField}
                            onChange={(event, newDateTime) => {
                                const endDateTime = get24HourEndDate(newDateTime, values.CurrentLifespan.End);
                                setFieldValue('CurrentLifespan.End', format("yyyy-MM-dd'T'HH:mm", endDateTime));
                            }}
                        />
                        <Field
                            type="time"
                            label="End"
                            name="CurrentLifespan.End"
                            component={TimeEditor}
                            className={classes.dateTimeField}
                            onChange={(event, newDateTime) => {
                                const endDateTime = get24HourEndDate(values.CurrentLifespan.Start, newDateTime);
                                setFieldValue('CurrentLifespan.End', format("yyyy-MM-dd'T'HH:mm", endDateTime));
                            }}
                        />
                    </Section>
                </div>
                <Field
                    type="text"
                    label="Auxiliary Resources"
                    name="Auxiliaries"
                    component={AutoComplete}
                    className={classes.selectField}
                    optionLabelProperty="_DisplayName"
                    optionsQuery={CxResourcesQuery}
                    queryVariables={(values) => ({
                        start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                        end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date()),
                        objectType: get('Principals[0]._HasAuxiliaries', values) ? [] : ['Employee', 'Equipment'],
                        id: get('Principals[0]._HasAuxiliaries', values) ? [values.Principals[0].Id] : []
                    })}
                    multiple
                    getOptions={(options) => {
                        if (!options.length) {
                            return [];
                        }
                        if (get('Principals[0]._HasAuxiliaries', values)) {
                            return options[0]._Auxiliaries;
                        }
                        return options;
                    }}
                    initialize={false}
                />
                <Field
                    type="text"
                    name="Tags"
                    component={TagEditor}
                    classes={classes}
                    customProperties={data.cxCustomPropertys}
                />
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxActualTaskDay = ({ open, formQueryValues, onClose }) => {
    // initialize model properties.
    // let inputModel = cloneDeep(model);
    // delete inputModel.ResourceType;
    // delete inputModel.Supplier;
    let initialValues = cloneDeep(model);
    delete inputModel.ResourceSubtype;
    delete inputModel.Cost;

    initialValues.Job = { Id: '0', _DisplayName: '' };
    initialValues.JobActivity = { Id: '0', _DisplayName: '' };
    // initialValues.ActionStatus = { Id: '0', _DisplayName: '' };
    initialValues.Amount = 1;
    initialValues.Principals = [];
    // initialValues.Quantity = {
    //     Quantity: 1,
    //     Unit: {
    //         Id: 15
    //     }
    // };
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'08:00:00", new Date());
    initialValues.CurrentLifespan.End = format("yyyy-MM-dd'T'17:00:00", new Date());
    return (
        <DialogForm
            open={open}
            title="Task"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={{
                ...formQueryValues,
                start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                end: flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(new Date())
            }}
            objectName="CxTaskDay"
            inputModel={inputModel}
            allowDelete={true}
            onClose={onClose}
        />
    );
};

EditCxActualTaskDay.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
