/**
 * Created by mark on 2/28/2021.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { object } from 'yup';
import { AutoComplete, FormContainer } from 'client-shared/components/form';
import { usePrivilege } from 'client-shared/utility';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { format } from 'date-fns/fp';
import { gql } from 'graphql-tag';
import { useApolloClient } from '@apollo/client';

export const validationSchema = object({});

export const resetAutoSchedule = async (client, actionStatusId, onClose) => {
    await client.query({
        query: gql`
            mutation {
                cxAllocations_onDelete(filters: [{ name: "ActionStatus.Id", values: ["${actionStatusId}"]}]) {
                    Id
                    ObjectType
                }
            }
        `,
        fetchPolicy: 'no-cache'
    });
    onClose();
};

const Form = (onClose) => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    return (props) => {
        const { classes, values } = props;
        return (
            <form noValidate autoComplete="off">
                <div>
                    <Field
                        type="date"
                        label="Start"
                        name="start"
                        component={TextField}
                        className={classes.dateTimeField}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <Field
                        type="number"
                        label="Days"
                        name="periods"
                        component={TextField}
                        className={classes.extraShortTextField}
                    />
                </div>
                <div>
                    <Field
                        type="number"
                        label="Max Resources Per Job"
                        name="maxResourcesAssignedToJob"
                        component={TextField}
                        className={classes.textField}
                    />
                    {/*<Field*/}
                    {/*    type="number"*/}
                    {/*    label="Hours Required Per Job"*/}
                    {/*    name="requiredCapacity"*/}
                    {/*    component={TextField}*/}
                    {/*    className={classes.textField}*/}
                    {/*/>*/}
                </div>
                <div>
                    {/*<Field*/}
                    {/*    type="number"*/}
                    {/*    label="Resource Capacity Hours"*/}
                    {/*    name="capacity"*/}
                    {/*    component={TextField}*/}
                    {/*    className={classes.textField}*/}
                    {/*/>*/}
                    <Field
                        type="text"
                        label="Action Status"
                        name="actionStatus"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query {
                                cxActionStatuss(filters: [{ name: "StatusType", values: ["InActive"] }]) {
                                    Id
                                    _DisplayName: DisplayName
                                }
                            }
                        `}
                        optionLabelProperty="_DisplayName"
                        disableClearable
                    />
                    <div>
                        <Button
                            onClick={() => resetAutoSchedule(client, values.actionStatus.Id, onClose)}
                            color="primary"
                            disabled={privilege}
                        >
                            Reset AutoScheduled
                        </Button>
                    </div>
                </div>
            </form>
        );
    };
};

export const EditAutoSchedule = ({ actions, open, onClose }) => {
    const [privilege] = usePrivilege('Allocation');
    let _submitForm;
    const getSubmitForm = (submitForm) => {
        _submitForm = submitForm;
    };
    const submitForm = () => {
        if (_submitForm) {
            _submitForm();
        }
    };
    return (
        <Dialog open={open}>
            <DialogContent>This will auto schedule all selected jobs.</DialogContent>
            <DialogContent>
                <FormContainer
                    Form={Form(onClose)}
                    initialValues={{
                        Id: 0,
                        start: format('yyyy-MM-dd', new Date()),
                        periods: 1,
                        capacity: 999999999,
                        maxResourcesAssignedToJob: 1,
                        requiredCapacity: 999999999,
                        actionStatus: {}
                    }}
                    data={{}}
                    onSubmit={(formValues) => {
                        actions.handleAutoSchedule(formValues);
                        onClose();
                    }}
                    getSubmitForm={getSubmitForm}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitForm} color="primary" disabled={privilege}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditAutoSchedule.propTypes = {
    actions: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
