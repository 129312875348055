import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($filters: [FilterInput]) {
        cxPartyGroups(filters: $filters) {
            Id
            Name
            ForwardingEmails
            NumericGroupNumber
            DisplayName
            Parties {
                Id
                DisplayName
            }
            Description
        }
    }
`;
