/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';

export const Address = ({ address, ...other }) => {
    return (
        <span {...other}>
            {`${address.Street}, ${address.StreetDetails}`}
            {`${address.City}, ${address.State.Name} ${address.ZipCode}`}
        </span>
    );
};

Address.propTypes = {
    address: PropTypes.object.isRequired
};
