/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxLookupValue';
import inputModel from 'cb-schema/inputmodelfull/CxLookupValue';
import { object, string } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';

const validationSchema = object({
    Name: string().min(2, 'Too Short!').max(65, 'Too Long!').required('Required'),
    Description: string().required('Required')
});

const Form = (props) => {
    const { classes } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Field
                    type="text"
                    label="ObjectType"
                    name="ObjectType"
                    component={TextField}
                    className={classes.textField}
                    disabled={true}
                />
                <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
                <Field
                    type="text"
                    label="Description"
                    name="Description"
                    component={TextField}
                    className={classes.textField}
                />
                <Field
                    type="color"
                    label="ForeColor"
                    name="ForeColor"
                    component={TextField}
                    className={classes.textField}
                />
                <Field
                    type="color"
                    label="BackColor"
                    name="BackColor"
                    component={TextField}
                    className={classes.textField}
                />
                <Field type="checkbox" Label={{ label: 'Active' }} name="Active" component={CheckboxWithLabel} />
                <Field type="checkbox" Label={{ label: 'Default' }} name="Default" component={CheckboxWithLabel} />
            </form>
        </React.Fragment>
    );
};

export const EditCxLookupValue = ({ open, formQueryValues, onClose }) => {
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    initialValues.Active = true;
    initialValues.Default = false;
    return (
        <DialogForm
            open={open}
            title="Lookup Value"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxLookupValue"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxLookupValue.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
