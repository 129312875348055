import { find } from 'lodash/fp';
import { isBefore } from 'date-fns/fp';
import { parseDate } from './dateutilities';
import inputModel from 'cb-schema/inputmodelfull/CxTimeEntry';
import { pickFromTemplate } from './pickfromtemplate';

/**
 * return true if this partyId, approved the timeentry.
 * @param partyId
 * @param approvals
 */
export const approved = (partyId, approvals) =>
    find((approval) => approval.Approver.Id === partyId, approvals) !== undefined;

/**
 * return true if any party, other than partyId, approved the timeentry.
 * @param partyId
 * @param approvals
 */
export const approvedByOther = (partyId, approvals) =>
    find((approval) => approval.Approver.Id !== partyId, approvals) !== undefined;

/**
 * return true if this party has not approved the timeentry or this party has approved the timeentry and
 * there are no parties with an AsOf date greater than this parties AsOf date.
 * @param partyId
 * @param approvals
 */
export const notApproved = (partyId, approvals) => {
    if (!partyId) {
        return false;
    }
    const approver = find((approval) => approval.Approver.Id === partyId, approvals);
    // party has not approved the timeentry.
    if (!approver) {
        return true;
    }
    // check if any party's AsOf data is greater than this parties AsOf date.
    const approved = find((approval) => isBefore(parseDate(approval.AsOf), parseDate(approver.AsOf)), approvals);
    return approved === undefined;
};

export const getTimeEntryMethods = (timeEntry, touched) => {
    let methods = [];
    const approve = {
        name: 'approve',
        args: {
            partyIds: [timeEntry._partyId]
        }
    };
    const unApprove = {
        name: 'unapprove',
        args: {
            partyIds: [timeEntry._partyId]
        }
    };

    // remove properties that are set by the evals.
    delete timeEntry.Approvals;
    if (timeEntry._approved) {
        methods.push(approve);
    } else {
        methods.push(unApprove);
    }
    return {
        operation: 'edit',
        methodsByItem: [
            {
                indices: [0],
                methods: methods
            }
        ],
        items: [pickFromTemplate(timeEntry, inputModel)]
    };
};
