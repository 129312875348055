import React, { useContext } from 'react';
import { fragments, instantiateBuild, UserContext } from 'client-shared/utility';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { cxTaskDayFragment } from '../dashboardhelpers';

function build(props) {
    const {
        gql,
        format,
        get,
        object,
        number,
        date,
        createFilters,
        user,
        getRangeStartEnd,
        flow,
        parseDate,
        getReportOptions,
        getReportFragment,
        addDays
    } = props;
    const validationSchema = object({
        _start: date().required('Required'),
        _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(100, 'Must be less than 100 days')
    });

    const dataQuery = (values, cxReportFragment) => gql`
        query ($filters: [FilterInput], $methodsByItem: [methodsByItemInput]) {
            cxJobs(filters: $filters, methodsByItem: $methodsByItem) {
                Id
                DisplayName
                AccountCode
                ForeColor
                BackColor
                Manager {
                    DisplayName
                }
                Address {
                    Street
                    State {
                        Description
                    }
                    ZipCode
                }
                ReportIntervals {
                    Report {
                        ${cxReportFragment}
                    }
                    Range {
                        Start
                        End
                    }
                    __typename
                }
                TaskDays {
                    ${values._summary || values._totalOnly ? 'DisplayName' : cxTaskDayFragment}
                }
            }
        }
    `;

    return {
        title: 'Job Grid',
        dataQuery: (values) => dataQuery(values, getReportFragment(values._reportProperties)),
        dataEventQueries: [fragments.JobEventQuery],
        sourceMutationObjectTypes: [],
        validationSchema: validationSchema,
        divisionFilter: true,
        enableShowAll: false,
        filterProperties: ['DisplayName', 'AccountCode'],
        filterOnServer: false,
        namedFilters: true,
        initialValues: {
            range: [],
            range__child__taskdays: [],
            _absoluteStart: true
        },
        onFilter: (values) => {
            values = {
                ...values,
                contacts_id__all__true: values._contacts_id,
                divisions_id__child__taskdays: values.divisions_id,
                resources_contacts_id__child__taskdays__all__true: values._contacts_id,
                divisions_id__child__resources: values.divisions_id,
                contacts_id__child__resources__all__true: values._contacts_id,
                job_id: values.id
            };
            return createFilters(values);
        },
        onMethodsByItem: async (values, client) => {
            const rangeStartEnd = getRangeStartEnd(values._start, values._periods, {
                interval: values._interval,
                periodMultiple: values._stratify ? 7 : undefined,
                absoluteStart: values._absoluteStart
            });
            const methodsByItem = [
                {
                    methods: [
                        {
                            name: 'MakeReportIntervals',
                            args: {
                                interval: values._interval,
                                start: rangeStartEnd.start,
                                end: flow(addDays(1), format('yyyy-MM-dd'))(parseDate(rangeStartEnd.end))
                            }
                        }
                    ]
                }
            ];
            return { methodsByItem: methodsByItem };
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Interval',
                name: '_interval',
                component: 'AutoComplete',
                options: ['Days', 'Weeks', 'Months'],
                className: 'shortSelectField',
                initialValue: 'Days',
                optionLabelProperty: 'Name'
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'number',
                label: 'Periods',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'text',
                name: 'scheduled',
                component: 'ScheduledEditor',
                initialValue: 'All'
            },
            {
                type: 'text',
                label: 'Job',
                name: 'id',
                component: 'AutoComplete',
                className: 'selectField',
                queryVariables: (values) => {
                    const rangeStartEnd = getRangeStartEnd(values._start, values._periods, { interval: 'Days' });
                    return {
                        start: rangeStartEnd.start,
                        end: rangeStartEnd.end
                    };
                },
                optionsQuery: gql`
                    query ($searchString: String, $start: String, $end: String) {
                        cxJobs(
                            filters: [
                                { name: "String", values: ["DisplayName", $searchString] }
                                { name: "range", values: [$start, $end] }
                            ]
                        ) {
                            Id
                            DisplayName
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Manager',
                name: 'manager_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxCaches(
                            objectType: "Cache"
                            filters: [{ name: "String", values: ["JobManagers.DisplayName", $searchString] }]
                        ) {
                            JobManagers {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => get('0.JobManagers', options),
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Type',
                name: 'types_id',
                component: 'LookupValueEditor',
                className: 'selectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobType'
            },
            {
                type: 'text',
                label: 'Status',
                name: 'jobStatus_id',
                component: 'LookupValueEditor',
                className: 'selectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobStatus'
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Contacts',
                name: '_contacts_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxCaches(
                            objectType: "cache"
                            filters: [{ name: "String", values: ["Contacts.DisplayName", $searchString] }]
                        ) {
                            Contacts {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => {
                    return get('0.Contacts', options);
                },
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Orientation',
                name: '_orientation',
                component: 'AutoComplete',
                options: ['row', 'column'],
                className: 'selectField',
                initialValue: 'row'
            },
            {
                type: 'checkbox',
                Label: { label: 'Summary' },
                name: '_summary',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'checkbox',
                Label: { label: 'Stratify' },
                name: '_stratify',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'text',
                label: 'Skip Days',
                name: '_skipDays',
                component: 'AutoComplete',
                className: 'selectField',
                options: [
                    { Id: 6, _DisplayName: 'Saturday' },
                    { Id: 0, _DisplayName: 'Sunday' }
                ],
                optionIdProperty: 'Id',
                optionLabelProperty: '_DisplayName',
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Metrics',
                name: '_reportProperties',
                component: 'AutoComplete',
                options: getReportOptions(user),
                className: 'longTextField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id',
                multiple: true,
                sort: false,
                initialValue: ['ScheduledLaborCount']
            },
            {
                type: 'text',
                label: 'Interval Metrics',
                name: '_reportIntervalProperties',
                component: 'AutoComplete',
                options: getReportOptions(user),
                className: 'longTextField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id',
                multiple: true,
                sort: false,
                initialValue: ['LaborCount', 'ScheduledLaborCount', 'AvailableLaborCount', 'LaborUtilization']
            },
            {
                type: 'checkbox',
                Label: { label: 'Export Labels' },
                name: '_label',
                component: 'CheckboxWithLabel',
                initialValue: true
            },
            {
                type: 'checkbox',
                Label: { label: 'Total Only' },
                name: '_totalOnly',
                component: 'CheckboxWithLabel',
                initialValue: false
            }
        ]
    };
}

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
