import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { createFilters, instantiateBuild } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import { getDayStartStop } from 'global-shared/utils/cbdUtils';
import { gql } from 'graphql-tag';

function build(props) {
    const { format, object, date, get, user } = props;

    const validationSchema = object({
        _start: date().required('Required')
    });

    const [startTime, endTime] = getDayStartStop(user);
    const dataQuery = gql`
        query ($filters: [FilterInput]) {
            cxResources(objectType: "Resource", filters: $filters) {
                Id
                Description
                ResourceType {
                    Id
                    DisplayName
                    Name
                    ObjectType
                    Family
                    Allocatable
                    ForeColor
                    BackColor
                    ResourceSubtypes {
                        Id
                        Name
                        DisplayName
                        ObjectType
                    }
                }
                ForeColor
                BackColor
                DisplayName
                ObjectType
                Divisions {
                    Id
                    Name
                }
                Auxiliaries {
                    Id
                }
            }
        }
    `;

    return {
        title: 'Daily Schedule',
        dataQuery: dataQuery,
        divisionFilter: true,
        validationSchema: validationSchema,
        //sourceMutationObjectTypes: ['Employee', 'Equipment', 'Material'],
        filterProperties: ['ResourceType.Name', 'DisplayName'],
        propertiesNotPersisted: ['_startTime', '_endTime'],
        initialValues: {
            _start: format('yyyy-MM-dd', new Date()),
            _periods: 1,
            _x_unit: 'minute',
            resourcetype_allocatable: 'true',
            range: [],
            _barColor: 'ActionStatus'
        },
        onFilter: (values) => {
            if (!values._barColor) values._barColor = 'ActionStatus';
            return createFilters(values);
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Family',
                name: 'resourceType_family',
                component: 'AutoComplete',
                options: [
                    { Id: 'Labor', Name: 'Labor' },
                    { Id: 'Equipment', Name: 'Equipment' },
                    { Id: 'Material', Name: 'Material' }
                ],
                className: 'selectField',
                initialValue: 'Labor',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            },
            {
                type: 'text',
                name: 'scheduled',
                component: 'ScheduledEditor',
                initialValue: 'All'
            },
            {
                type: 'text',
                label: 'Resources',
                name: 'Id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query CxResourcess($searchString: String) {
                        cxResources(
                            objectType: "Resource"
                            filters: [
                                { name: "String", values: ["DisplayName", $searchString] }
                                { name: "ResourceType.Allocatable", values: ["true"] }
                            ]
                        ) {
                            Id
                            _DisplayName: DisplayName
                            _ResourceType: ResourceType {
                                Allocatable
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Type',
                name: 'resourceType_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: `
                    query ($searchString: String) {
                        cxResourceTypes(
                            objectType: "ResourceType"
                            filters: [
                                { name: "String", values: ["DisplayName", $searchString] }
                                { name: "Allocatable", values: ["true"] }
                            ]
                        ) {
                            Id
                            _DisplayName: DisplayName
                        }
                    }
                `,
                multiple: true,
                initialValue: []
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'time',
                label: 'Start Time',
                name: '_startTime',
                component: 'TextField',
                initialValue: startTime
            },
            {
                type: 'time',
                label: 'End Time',
                name: '_endTime',
                component: 'TextField',
                initialValue: endTime
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'string',
                label: 'Allocation Color',
                name: '_barColor',
                component: 'AutoComplete',
                className: 'shortSelectField',
                initialValue: 'ActionStatus',
                options: [
                    { Id: '_', Name: 'Allocation' },
                    { Id: 'ActionStatus', Name: 'Action Status' },
                    { Id: 'PrincipalType', Name: 'Resource' }
                ],
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            },
            {
                type: 'number',
                label: 'Timeline Spacing',
                name: '_dy',
                component: 'AutoComplete',
                initialValue: 36,
                options: [
                    { Id: 46, Name: 'Big' },
                    { Id: 36, Name: 'Comfortable' },
                    { Id: 26, Name: 'Tight' }
                ],
                className: 'shortSelectField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            },
            {
                type: 'number',
                label: 'Column Width (scrolled)',
                name: '_column_width_',
                component: 'TextField',
                className: 'textField',
                initialValue: 200
            },
            {
                type: 'checkbox',
                Label: { label: 'Scroll Timeline' },
                name: '_scrollable',
                component: 'CheckboxWithLabel',
                initialValue: false
            }
        ]
    };
}

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
