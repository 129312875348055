import { DragDropBase, ActionsMixin } from 'client-shared/utility/mutationutilities';

class DragDrop extends DragDropBase {
    bulkEdit({ ids, properties, touched }) {
        let methods = [];
        const approve = {
            name: 'approve',
            args: {
                partyIds: [properties._partyId]
            }
        };
        const unApprove = {
            name: 'unapprove',
            args: {
                partyIds: [properties._partyId]
            }
        };
        const addMinutes = {
            name: 'addminutes',
            args: {
                minutes: properties._minutes.toString()
            }
        };
        if (touched._approved && properties._approved) {
            methods.push(approve);
        }
        if (touched._approved && !properties._approved) {
            methods.push(unApprove);
        }
        if (properties._minutes !== 0) {
            methods.push(addMinutes);
        }

        return {
            filters: [
                {
                    name: 'Id',
                    values: ids.map((id) => id.toString())
                }
            ],
            methodsByItem: [
                {
                    indices: [],
                    methods: methods
                }
            ],
            items: []
        };
    }
}

export class Actions extends ActionsMixin(DragDrop) {}
