import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { allowDrop, handleDrag, handleClipBoardDrop } from './clipboarddragdrop';
import sortBy from 'lodash/sortBy';
import { Accordion } from 'client-shared/components/Accordion';
import Button from '@mui/material/Button';

const styles = {
    root: {
        flexGrow: 1,
        flexWrap: 'nowrap',
        textAlign: 'left',
        height: '100px',
        overflowY: 'auto'
    },
    allocation: {
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'lightgray'
    },
    taskDay: {
        paddingLeft: '20px'
    }
};

const ShowItems = ({ clipBoardItems, display = (allocation) => allocation.DisplayName }) => {
    if (clipBoardItems.length === 0) {
        return null;
    }
    return (
        <div>
            {clipBoardItems.map((clipBoardItem) => {
                const { selectedItemProperties, item } = clipBoardItem;
                return (
                    <div key={item.Id}>
                        <Accordion
                            items={
                                <div
                                    style={styles.allocation}
                                    draggable={true}
                                    onDragStart={handleDrag([clipBoardItem])}
                                >
                                    {display(item)}
                                </div>
                            }
                        >
                            {sortBy(item.TaskDays, 'DisplayName').map((taskDay, key) => {
                                return (
                                    <div
                                        key={`${item.Id}${taskDay.Id}`}
                                        style={styles.taskDay}
                                        draggable={true}
                                        onDragStart={handleDrag([
                                            {
                                                selectedItemProperties: selectedItemProperties,
                                                items: [taskDay]
                                            }
                                        ])}
                                    >
                                        {taskDay.DisplayName}
                                    </div>
                                );
                            })}
                        </Accordion>
                    </div>
                );
            })}
        </div>
    );
};

ShowItems.propTypes = {
    clipBoardItems: PropTypes.array.isRequired
};

export const ClipBoard = () => {
    const [clipBoardItems, setClipBoardItems] = useState([]);
    return (
        <div>
            Clipboard
            <div
                style={styles.root}
                onDrop={handleClipBoardDrop(clipBoardItems, setClipBoardItems)}
                onDragOver={allowDrop}
            >
                <ShowItems clipBoardItems={clipBoardItems} />
            </div>
            <Button size="small" onClick={() => setClipBoardItems([])}>
                Clear
            </Button>
        </div>
    );
};
