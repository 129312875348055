import { flow, get, pick, sortBy, reduce, keys, forEach, filter, join } from 'lodash/fp';
import { round } from 'lodash';
import { getConfigValue } from 'client-shared/utility';

const map = require('lodash/fp/map').convert({ cap: false });

export const Types = {
    STRING: { type: 'string', display: (value) => value, color: (value) => 'black' },
    MONEY: {
        type: 'money',
        display: (value) => `$${value}`,
        color: (value) => (value < 0 ? 'red' : 'black'),
        excelColor: (value) => (value < 0 ? 'FF0000' : '000000')
    },
    COUNT: {
        type: 'count',
        display: (value) => value,
        color: (value) => (value < 0 ? 'red' : 'black'),
        excelColor: (value) => (value < 0 ? 'FF0000' : '000000')
    },
    COUNTPOSITIVE: {
        type: 'count',
        display: (value) => value,
        color: (value) => (value > 0 ? 'red' : 'black'),
        excelColor: (value) => (value > 0 ? 'FF0000' : '000000')
    },
    TIME: {
        type: 'time',
        display: (value) => value,
        color: (value) => (value < 0 ? 'red' : 'black'),
        excelColor: (value) => (value < 0 ? 'FF0000' : '000000')
    },

    PERCENT: {
        type: 'percent',
        display: (value) => `${value}%`,
        color: (value) => (value < 100 ? 'blue' : value > 100 ? 'red' : 'black'),
        excelColor: (value) => (value < 100 ? '0000FF' : value > 100 ? 'FF0000' : '000000')
    }
};

/**
 * sum the reportObjects collection properties. some properties are recurring totals across items in the reportObjects.
 * by default, all total properties are the same across the collection. however, some reportObjects
 * collection items are updated during eventing and will differ.
 * @param reportObjects - collection of report objects.
 * @param doNotSum - array of property names not to sum. these are usually reserved for properties that contain totals.
 * @returns {*}
 */
export const sumReportProperties = ({ reportObjects = [], doNotSum = [] }) => {
    doNotSum = [...doNotSum, ...['WorkingDays', 'LaborDuration']];
    const properties = keys(get('0', reportObjects));
    const reducer = (accumulator, currentValue) => {
        forEach((property) => {
            if (get(`${property}.Quantity`, currentValue) !== undefined) {
                accumulator[property] = {
                    Quantity: doNotSum.includes(property)
                        ? get(`${property}.Quantity`, currentValue)
                        : (get(`${property}.Quantity`, accumulator) || 0) + get(`${property}.Quantity`, currentValue)
                };
                return;
            }
            accumulator[property] = doNotSum.includes(property)
                ? get(property, currentValue)
                : (get(property, accumulator) || 0) + get(property, currentValue);
        }, properties);
        return accumulator;
    };

    const total = reduce(reducer, {}, reportObjects);
    return total;
};

/**
 *
 * @param reportObject - object of report properties.
 * @param unit - object of unit properties used to name and scale the values.
 * @param summaryIntervalValues - optional object of summaryIntervalValues properties. If passed, assume reportObject
 * contains the total for each property and that remaining prop1 value = reportObject.prop1 - summaryIntervalValues.prop1.
 * For example, reportObject.ForecastedLaborDuration - summaryIntervalValues.ForecastedLaborDuration =
 * remaining ForecastedLaborDuration.
 * @param reportProperties - list of properties to return.
 * @returns {Pick<{LaborCount: {label: string, type: {display: string, type: string}, value: number}, ActualLaborDuration: {title: string, type: {display: string, type: string}, value: number}, ForecastedLaborDuration: {title: string, type: {display: string, type: string}, value: number}, ScheduledLaborCost: {title: string, type: {display: string, type: string}, value: number}, PlannedLaborDuration: {title: string, type: {display: string, type: string}, value: number}, PlannedLaborCost: {title: string, type: {display: string, type: string}, value: number}, ActualLaborCost: {title: string, type: {display: string, type: string}, value: number}, ForecastedLaborCost: {title: string, type: {display: string, type: string}, value: number}, WorkingDays: {title: string, type: {display: string, type: string}, value: number}, ScheduledLaborDuration: {title: string, type: {display: string, type: string}, value: number}}, *>}
 */
const getReportProperties = ({
    reportObject,
    unit = { Name: '', Scalar: 1 },
    summaryIntervalValues,
    reportProperties = []
}) => {
    const properties = {
        ActualLaborCost: {
            label: 'Actual Lbr Cost',
            value: get('ActualLaborCost.Quantity', reportObject) || 0,
            type: Types.MONEY,
            order: 1,
            fragment: 'ActualLaborCost {Quantity}',
            calculated: false
        },
        ActualLaborDuration: {
            label: `Actual Lbr ${unit.Name}`,
            value: get('ActualLaborDuration', reportObject) / unit.Scalar || 0,
            type: Types.TIME,
            order: 1,
            fragment: 'ActualLaborDuration',
            calculated: false
        },
        CrewCount: {
            label: 'Crew Cnt',
            value: get('CrewCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'CrewCount',
            calculated: false
        },
        EquipmentCount: {
            label: 'Eq Cnt',
            value: get('EquipmentCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'EquipmentCount',
            calculated: false
        },
        // EstimatedForecastedEquipmentDuration: {
        //     label: `Est Eq ${unit.Name}`,
        //     value: get('EstimatedForecastedEquipmentDuration', reportObject) / unit.Scalar || 0,
        //     type: Types.TIME,
        //     order: 1,
        //     fragment: 'EstimatedForecastedEquipmentDuration',
        //     calculated: false
        // },
        // EstimatedForecastedLaborDuration: {
        //     label: `Est Labor ${unit.Name}`,
        //     value: get('EstimatedForecastedLaborDuration', reportObject) / unit.Scalar || 0,
        //     type: Types.TIME,
        //     order: 1,
        //     fragment: 'EstimatedForecastedLaborDuration',
        //     calculated: false
        // },
        ForecastedEquipmentCost: {
            label: 'Fcst Eq Cost',
            value: get('ForecastedEquipmentCost.Quantity', reportObject) || 0,
            type: Types.MONEY,
            order: 1,
            fragment: 'ForecastedEquipmentCost {Quantity}',
            calculated: false
        },
        ForecastedEquipmentCount: {
            label: 'Fcst Eq Count',
            value: get('ForecastedEquipmentCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'ForecastedEquipmentCount',
            calculated: false
        },
        ForecastedEquipmentDuration: {
            label: `Fcst Eq ${unit.Name}`,
            value: get('ForecastedEquipmentDuration', reportObject) / unit.Scalar || 0,
            type: Types.TIME,
            order: 1,
            fragment: 'ForecastedEquipmentDuration',
            calculated: false
        },
        ForecastedLaborCost: {
            label: 'Fcst Lbr Cost',
            value: get('ForecastedLaborCost.Quantity', reportObject) || 0,
            type: Types.MONEY,
            order: 1,
            fragment: 'ForecastedLaborCost {Quantity}',
            calculated: false
        },
        ForecastedLaborCount: {
            label: 'Fcst Lbr Count',
            value: get('ForecastedLaborCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'ForecastedLaborCount',
            calculated: false
        },
        ForecastedLaborDuration: {
            label: `Fcst Lbr ${unit.Name}`,
            value: get('ForecastedLaborDuration', reportObject) / unit.Scalar || 0,
            type: Types.TIME,
            order: 1,
            fragment: 'ForecastedLaborDuration',
            calculated: false
        },
        LaborCount: {
            label: 'Lbr Cnt',
            value: get('LaborCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'LaborCount',
            calculated: false
        },
        LaborCapacity: {
            label: `Lbr Capacity ${unit.Name}`,
            value: get('LaborDuration', reportObject) / unit.Scalar || 0,
            type: Types.TIME,
            order: 1,
            fragment: 'LaborDuration',
            calculated: false
        },
        PlannedEquipmentCost: {
            label: 'Plnd Eq Cost',
            value: get('PlannedEquipmentCost.Quantity', reportObject) || 0,
            type: Types.MONEY,
            order: 1,
            fragment: ' PlannedEquipmentCost {Quantity}',
            calculated: false
        },
        PlannedEquipmentCount: {
            label: 'Plnd Eq Cnt',
            value: get('PlannedEquipmentCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'PlannedEquipmentCount',
            calculated: false
        },
        PlannedEquipmentDuration: {
            label: `Plnd Eq ${unit.Name}`,
            value: get('PlannedEquipmentDuration', reportObject) / unit.Scalar || 0,
            type: Types.TIME,
            order: 1,
            fragment: 'PlannedEquipmentDuration',
            calculated: false
        },
        PlannedLaborCost: {
            label: 'Plnd Lbr Cost',
            value: get('PlannedLaborCost.Quantity', reportObject) || 0,
            type: Types.MONEY,
            order: 1,
            fragment: ' PlannedLaborCost {Quantity}',
            calculated: false
        },
        PlannedLaborCount: {
            label: 'Plnd Lbr Cnt',
            value: get('PlannedLaborCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'PlannedLaborCount',
            calculated: false
        },
        PlannedLaborDuration: {
            label: `Plnd Lbr ${unit.Name}`,
            value: get('PlannedLaborDuration', reportObject) / unit.Scalar || 0,
            type: Types.TIME,
            order: 1,
            fragment: 'PlannedLaborDuration',
            calculated: false
        },
        ScheduledEquipmentCost: {
            label: 'Schd Eq Cost',
            value: get('ScheduledEquipmentCost.Quantity', reportObject) || 0,
            type: Types.MONEY,
            order: 1,
            fragment: 'ScheduledEquipmentCost {Quantity}',
            calculated: false
        },
        ScheduledEquipmentCount: {
            label: 'Schd Eq Cnt',
            value: get('ScheduledEquipmentCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'ScheduledEquipmentCount',
            calculated: false
        },
        ScheduledEquipmentDuration: {
            label: `Schd Eq ${unit.Name}`,
            value: get('ScheduledEquipmentDuration', reportObject) / unit.Scalar || 0,
            type: Types.TIME,
            order: 1,
            fragment: 'ScheduledEquipmentDuration',
            calculated: false
        },
        ScheduledLaborCost: {
            label: 'Schd Lbr Cost',
            value: get('ScheduledLaborCost.Quantity', reportObject) || 0,
            type: Types.MONEY,
            order: 1,
            fragment: 'ScheduledLaborCost {Quantity}',
            calculated: false
        },
        ScheduledLaborCount: {
            label: 'Schd Lbr Cnt',
            value: get('ScheduledLaborCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'ScheduledLaborCount',
            calculated: false
        },
        ScheduledLaborDuration: {
            label: `Schd Lbr ${unit.Name}`,
            value: get('ScheduledLaborDuration', reportObject) / unit.Scalar || 0,
            type: Types.TIME,
            order: 1,
            fragment: 'ScheduledLaborDuration',
            calculated: false
        },
        Shift: {
            label: 'Shift',
            value: get('Shift.DisplayName', reportObject) || 0,
            type: Types.STRING,
            order: 0,
            calculated: false
        },
        UnfilledEquipmentCount: {
            label: 'Unfilled Eq Cnt',
            value: get('UnfilledEquipmentCount', reportObject) || 0,
            type: Types.COUNTPOSITIVE,
            order: 0,
            fragment: 'UnfilledEquipmentCount',
            calculated: false
        },
        UnfilledLaborCount: {
            label: 'Unfilled Lbr Cnt',
            value: get('UnfilledLaborCount', reportObject) || 0,
            type: Types.COUNTPOSITIVE,
            order: 1,
            fragment: 'UnfilledLaborCount',
            calculated: false
        },
        UnfilledLaborDuration: {
            label: 'Unfilled Lbr',
            value: get('UnfilledLaborDuration', reportObject) / unit.Scalar || 0,
            type: Types.TIME,
            order: 1,
            fragment: 'UnfilledLaborDuration',
            calculated: false
        },
        // WorkingDays: {
        //     label: 'Working Days',
        //     value: get('WorkingDays', reportObject) || 0,
        //     type: Types.NUMBER,
        //     order: 0,
        //     fragment: 'WorkingDays',
        //     calculated: false
        // },
        // WorkingDuration: {
        //     label: `Working Duration ${unit.Name}`,
        //     value: get('WorkingDuration', reportObject) / unit.Scalar || 0,
        //     type: Types.TIME,
        //     order: 0,
        //     fragment: 'WorkingDuration'
        // },
        // SummaryIntervalForecastedLaborDuration: {
        //     label: `Smry Fcst Lbr ${unit.Name}`,
        //     value: get('ForecastedLaborDuration', summaryIntervalValues) / unit.Scalar || 0,
        //     type: Types.TIME,
        //     order: 0,
        //     fragment: 'ForecastedLaborDuration',
        //     calculated: false
        // },
        ScheduledJobCount: {
            label: 'Schd Job Cnt ',
            value: get('ScheduledJobCount', reportObject) || 0,
            type: Types.COUNT,
            order: 1,
            fragment: 'ScheduledJobCount ',
            calculated: false
        }
    };
    properties.AvailableLaborCount = {
        label: `Avlbl Lbr Cnt`,
        value: properties.LaborCount.value - properties.ScheduledLaborCount.value || 0,
        type: Types.COUNT,
        order: 1,
        fragment: `${properties.LaborCount.fragment} ${properties.ScheduledLaborCount.fragment}`,
        calculated: true
    };
    properties.AvailableLaborCountMinusUnfilledLaborCount = {
        label: `Avlbl - Unflld Lbr`,
        value: properties.AvailableLaborCount.value - properties.UnfilledLaborCount.value || 0,
        type: Types.COUNT,
        order: 1,
        fragment: `${properties.AvailableLaborCount.fragment} ${properties.UnfilledLaborCount.fragment}`,
        calculated: true
    };
    // properties.LaborCapacity = {
    //     label: `Lbr Capacity ${unit.Name}`,
    //     value: properties.LaborCount.value * properties.LaborDuration.value,
    //     type: Types.TIME,
    //     order: 1,
    //     fragment: `${properties.LaborCount.fragment} ${properties.LaborDuration.fragment}`
    // };
    properties.LaborCapacityMinusForecastedLaborDuration = {
        label: `Capacity - Fcst Lbr ${unit.Name}`,
        value: properties.LaborCapacity.value - properties.ForecastedLaborDuration.value || 0,
        type: Types.TIME,
        order: 1,
        fragment: `${properties.LaborCapacity.fragment} ${properties.ForecastedLaborDuration.fragment}`,
        calculated: true
    };
    properties.LaborCapacityMinusScheduledLaborDuration = {
        label: `Capacity - Schd Lbr ${unit.Name}`,
        value: properties.LaborCapacity.value - properties.ScheduledLaborDuration.value || 0,
        type: Types.TIME,
        order: 1,
        fragment: `${properties.LaborCapacity.fragment} ${properties.ScheduledLaborDuration.fragment}`,
        calculated: true
    };
    properties.LaborCapacityMinusScheduledAndPlannedLaborDuration = {
        label: `Capacity - (Schd + Plnd) Lbr ${unit.Name}`,
        value:
            properties.LaborCapacity.value -
                properties.ScheduledLaborDuration.value -
                properties.PlannedLaborDuration.value || 0,
        type: Types.TIME,
        order: 1,
        fragment: `${properties.LaborCapacity.fragment} ${properties.ScheduledLaborDuration.fragment}
        ${properties.PlannedLaborDuration.fragment}`,
        calculated: true
    };
    properties.ScheduledLaborDurationPlusUnfilledLaborDuration = {
        label: `Schd Lbr + Unfilled Lbr ${unit.Name}`,
        value: properties.ScheduledLaborDuration.value + properties.UnfilledLaborDuration.value,
        type: Types.TIME,
        order: 1,
        fragment: `${properties.ScheduledLaborDuration.fragment} ${properties.UnfilledLaborDuration.fragment}`,
        calculated: false
    };
    properties.LaborUtilization = {
        label: `Lbr Utilization`,
        value:
            (properties.ScheduledLaborDurationPlusUnfilledLaborDuration.value / properties.LaborCapacity.value) * 100 ||
            0,
        type: Types.PERCENT,
        order: 1,
        fragment: `${properties.ScheduledLaborDuration.fragment} ${properties.UnfilledLaborDuration.fragment} 
                ${properties.LaborCapacity.fragment}`,
        calculated: true
    };
    // properties.LaborUtilization = {
    //     label: `Lbr Utilization Cnts`,
    //     value:
    //         ((properties.ScheduledLaborCount.value + properties.UnfilledLaborCount.value) /
    //             properties.LaborCount.value) *
    //             100 || 0,
    //     type: Types.PERCENT,
    //     order: 1,
    //     fragment: `${properties.ScheduledLaborCount.fragment} ${properties.UnfilledLaborCount.fragment}
    //             ${properties.LaborCount.fragment}`,
    //     calculated: true
    // };
    properties.ScheduledLaborDurationVsPlannedLaborDuration = {
        label: `Schd vs. Plnd Lbr ${unit.Name}`,
        value: (properties.ScheduledLaborDuration.value / properties.PlannedLaborDuration.value) * 100 || 0,
        type: Types.PERCENT,
        order: 1,
        fragment: `${properties.ScheduledLaborDuration.fragment}  ${properties.PlannedLaborDuration.fragment}`,
        calculated: true
    };
    properties.PlannedLaborDurationMinusScheduledLaborDuration = {
        label: `Plnd - Schd Lbr ${unit.Name}`,
        value: properties.PlannedLaborDuration.value - properties.ScheduledLaborDuration.value || 0,
        type: Types.TIME,
        order: 1,
        fragment: `${properties.PlannedLaborDuration.fragment} ${properties.ScheduledLaborDuration.fragment}`,
        calculated: true
    };
    properties.ScheduledLaborCostVsPlannedLaborCost = {
        label: 'Schd vs. Plnd Lbr Cost',
        value: (properties.ScheduledLaborCost.value / properties.PlannedLaborCost.value) * 100 || 0,
        type: Types.PERCENT,
        order: 1,
        fragment: `${properties.ScheduledLaborCost.fragment} ${properties.PlannedLaborCost.fragment}`,
        calculated: true
    };
    properties.ScheduledLaborDurationVsActualLaborDuration = {
        label: `Schd vs. Actual Lbr ${unit.Name}`,
        value: (properties.ScheduledLaborDuration.value / properties.ActualLaborDuration.value) * 100 || 0,
        type: Types.PERCENT,
        order: 1,
        fragment: `${properties.ScheduledLaborDuration.fragment} ${properties.ActualLaborDuration.fragment}`,
        calculated: true
    };
    properties.ScheduledLaborDurationMinusActualLaborDuration = {
        label: `Schd - Actual Lbr ${unit.Name}`,
        value: properties.ScheduledLaborDuration.value - properties.ActualLaborDuration.value || 0,
        type: Types.TIME,
        order: 1,
        fragment: `${properties.ScheduledLaborDuration.fragment} ${properties.ActualLaborDuration.fragment}`,
        calculated: true
    };
    properties.ScheduledLaborCostVsActualLaborCost = {
        label: 'Schd vs. Actual Lbr Cost',
        value: (properties.ScheduledLaborCost.value / properties.ActualLaborCost.value) * 100 || 0,
        type: Types.PERCENT,
        order: 1,
        fragment: `${properties.ScheduledLaborCost.fragment} ${properties.ActualLaborCost.fragment}`,
        calculated: true
    };
    properties.ScheduledLaborDurationVsForecastedLaborDuration = {
        label: `Schd vs. Fcst Lbr ${unit.Name}`,
        value: (properties.ScheduledLaborDuration.value / properties.ForecastedLaborDuration.value) * 100 || 0,
        type: Types.PERCENT,
        order: 1,
        fragment: `${properties.ScheduledLaborDuration.fragment} ${properties.ForecastedLaborDuration.fragment}`,
        calculated: true
    };
    properties.ForecastedLaborDurationMinusScheduledLaborDuration = {
        label: `Fcst - Schd Lbr ${unit.Name}`,
        value: properties.ForecastedLaborDuration.value - properties.ScheduledLaborDuration.value || 0,
        type: Types.TIME,
        order: 1,
        fragment: `${properties.ForecastedLaborDuration.fragment} ${properties.ScheduledLaborDuration.fragment}`,
        calculated: true
    };
    properties.ScheduledLaborCostVsForecastedLaborCost = {
        label: 'Schd vs. Fcst Lbr Cost',
        value: (properties.ScheduledLaborCost.value / properties.ForecastedLaborCost.value) * 100 || 0,
        type: Types.PERCENT,
        order: 1,
        fragment: `${properties.ScheduledLaborCost.fragment} ${properties.ForecastedLaborCost.fragment}`,
        calculated: true
    };
    if (!reportProperties.length) {
        return properties;
    }
    return pick(reportProperties, properties);
};

/**
 * return an array of the report properties and values from the report object, skipping properties with a
 * zero sort order and returning values that are numbers or zero if not.
 * @param reportProperties
 * @param reportObject
 * @param unit
 * @param summaryIntervalValues
 * @returns {unknown[]|*[]}
 */
export const getSelectedReportProperties = ({
    reportProperties = [],
    reportObject,
    unit = { Name: '', Scalar: 1 },
    summaryIntervalValues
}) => {
    if (!reportProperties.length) {
        return [];
    }
    return flow(
        map((reportProperty, key) => ({
            key: key,
            ...reportProperty,
            ...{ reportProperty: Number.isFinite(reportProperty.value) ? reportProperty.value : 0 }
        })),
        filter((reportProperty) => reportProperty.order !== 0),
        sortBy(['order', 'Name'])
    )(getReportProperties({ reportObject, unit, summaryIntervalValues, reportProperties }));
};

/**
 * return a collection that can be used as options in the autocomplete react control.
 * @param user
 * @returns {unknown[]}
 */
export const getReportOptions = (
    user,
    includeCalculated = true,
    types = ['string', 'money', 'count', 'time', 'percent']
) => {
    const config_configValue = getConfigValue('config.report.properties', user) || [];
    return flow(
        map((reportProperty, key) => ({
            Id: key,
            Name: reportProperty.label,
            order: reportProperty.order,
            type: reportProperty.type,
            calculated: reportProperty.calculated
        })),
        filter((reportProperty) =>
            reportProperty.order !== 0 && includeCalculated
                ? true
                : reportProperty.calculated === false && types.includes(reportProperty.type.type)
        ),
        sortBy(['order', 'Name'])
    )(getReportProperties({ reportProperties: config_configValue }));
};

/**
 * return the report property graphql fragments to query the report object from the dbserver.
 * @param reportProperties
 * @returns {string|string}
 */
export const getReportFragment = (reportProperties) => {
    return (
        flow(
            map((reportProperty) => get('fragment', reportProperty) || ''),
            join(' ')
        )(getSelectedReportProperties({ reportProperties: reportProperties })) || 'ScheduledLaborDuration'
    );
};

/**
 * return the report property values as a collection formatted for an excel cell, font and text.
 * @param reportProperties
 * @param reportObject
 * @param unit
 * @param summaryIntervalValues
 * @returns {*[]}
 */
export const reportExcel = ({ reportProperties, reportObject, unit, summaryIntervalValues, label = true }) => {
    const richText = [];
    if (!reportProperties.length) {
        return richText;
    }
    map(
        (reportProperty, index) => {
            if (!reportProperty) {
                return;
            }
            const reportValue =
                reportProperty.type === Types.STRING
                    ? reportProperty.value
                    : round(reportProperty.value, 2).toLocaleString();
            richText.push({
                font: { color: { argb: reportProperty.type.excelColor(reportProperty.value) } },
                text: `${label ? reportProperty.label + ':' : ''} ${reportProperty.type.display(reportValue)}${
                    index < reportProperties.length - 1 ? '\n' : ''
                }`
            });
        },
        getSelectedReportProperties({ reportProperties: reportProperties, reportObject, unit, summaryIntervalValues })
    );
    return richText;
};
