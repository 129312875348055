import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "Crew", $filters: [FilterInput]) {
        cxCrews(objectType: $objectType, filters: $filters) {
            ObjectType
            Id
            Name
            AccountCode
            ResourceSubtype {
                Id
                _Name: Name
            }
            ForeColor
            BackColor
            Foreman {
                Id
                _DisplayName: DisplayName
            }
            Manager {
                Id
                _DisplayName: DisplayName
            }
            Resources {
                Id
                _DisplayName: DisplayName
            }
            CurrentLifespan {
                Start
                End
            }
            Divisions {
                Id
                _DisplayName: DisplayName
            }
            UpdateFutureAllocations
            Description
        }
    }
`;
