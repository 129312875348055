import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { instantiateBuild } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import { omit, map } from 'lodash/fp';

function build(props) {
    const { gql, format, get, flatMap, object, number, string, date, user, fragments } = props;
    const validationSchema = object({
        _start: date().required('Required'),
        ResourceType_Family__child__Taskdays: string().required('Required').nullable().required('Required'),
        _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(100, 'Must be less than 100 days')
    });

    const dataQuery = gql`
        ${fragments.CxAllocationFragment}
        query ($filters: [FilterInput]) {
            cxAllocations(filters: $filters) {
                ...CxAllocationFragment
            }
        }
    `;

    return {
        title: 'Resource Utilization Grid Old (use Resource Type Grid Instead)',
        dataQuery: dataQuery,
        dataEventQueries: [fragments.AllocationEventQuery],
        sourceMutationObjectTypes: [],
        validationSchema: validationSchema,
        divisionFilter: true,
        enableShowAll: false,
        filterProperties: ['DisplayName', 'Job.DisplayName'],
        filterOnServer: false,
        initialValues: {
            range: [],
            range__child__taskdays: []
        },
        onData: (data) => {
            return flatMap((allocation) => {
                const a = omit(['TaskDays'], allocation);
                return map((taskDay) => {
                    return {
                        Allocation: a,
                        ...taskDay
                    };
                }, allocation.TaskDays);
            }, data);
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Interval',
                name: '_interval',
                component: 'AutoComplete',
                options: ['Days', 'Weeks', 'Months'],
                className: 'shortSelectField',
                initialValue: 'Days',
                optionLabelProperty: 'Name'
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'number',
                label: 'Periods',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'text',
                label: 'Family',
                name: 'ResourceType_Family__child__Taskdays',
                component: 'AutoComplete',
                options: ['Labor', 'Equipment'],
                className: 'shortSelectField',
                initialValue: 'Labor'
            },
            {
                type: 'text',
                label: 'Type',
                name: 'ResourceType_id__child__Taskdays',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'Name',
                queryVariables: (values) => ({
                    family: [get('ResourceType_Family__child__Taskdays', values)]
                }),
                optionsQuery: `
                    query ($searchString: String, $family: [String] = []) {
                        cxResourceTypes(
                            objectType: "ResourceType"
                            filters: [
                                { name: "String", values: ["Name", $searchString] }
                                { name: "family", values: $family }
                            ]
                        ) {
                            Id
                            Name
                        }
                    }
                `,
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Manager',
                name: 'job_manager_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxJobs(
                            objectType: "Job"
                            filters: [{ name: "String", values: ["Manager.DisplayName", $searchString] }]
                        ) {
                            Manager {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => {
                    return options.map((option) => ({
                        Id: option.Manager.Id,
                        DisplayName: option.Manager.DisplayName
                    }));
                },
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Job Type',
                name: 'job_types_id',
                component: 'LookupValueEditor',
                className: 'shortSelectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobType'
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Orientation',
                name: '_orientation',
                component: 'AutoComplete',
                options: ['row', 'column'],
                className: 'shortSelectField',
                initialValue: 'row'
            },
            {
                type: 'checkbox',
                Label: { label: 'Summary' },
                name: '_summary',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'checkbox',
                Label: { label: 'Stratify' },
                name: '_stratify',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'text',
                label: 'Skip Days',
                name: '_skipDays',
                component: 'AutoComplete',
                className: 'selectField',
                options: [
                    { Id: 6, _DisplayName: 'Saturday' },
                    { Id: 0, _DisplayName: 'Sunday' }
                ],
                optionIdProperty: 'Id',
                optionLabelProperty: '_DisplayName',
                multiple: true,
                initialValue: []
            }
        ]
    };
}

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return (
        <FilterFormFromJson
            filterFormDefinition={{
                ...filterFormDefinition
                // initialValues: {
                //     _periods: 6
                // }
            }}
            children={children}
        />
    );
};
