import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "CustomProperty", $filters: [FilterInput]) {
        cxCustomPropertys(objectType: $objectType, filters: $filters) {
            Id
            ObjectType
            SupportedTypes
            AttributeType
            Description
            ForeColor
            BackColor
            Name
            CustomLookups {
                Id
                Name
                Description
                Default
            }
        }
    }
`;
