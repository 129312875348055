import { gql } from 'graphql-tag';
import { CxTimeEntryFragment } from 'client-shared/utility/fragments';

export const FormQuery = gql`
    ${CxTimeEntryFragment}
    query ($objectType: String = "TimeEntry", $filters: [FilterInput]) {
        timeEntry(objectType: $objectType, filters: $filters) {
            ...CxTimeEntryFragment
        }
    }
`;
