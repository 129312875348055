import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Field } from 'client-shared/components/form';
import { gql } from 'graphql-tag';

const query = ({ optionLabelProperty }) => {
    return `query ($objectType: String, $searchString: String, $active: [String]) {
                    cxLookupValues(
                        objectType: $objectType
                        filters: [
                            { name: "String", values: ["DisplayName", $searchString] }
                            { name: "Active", values: $active }
                        ]
                    ) {
                        Id
                        _Name: Name
                        ${optionLabelProperty}: DisplayName
                        _Default: Default
                    }
                }
            `;
};

export const LookupValueEditor = ({
    objectType,
    optionLabelProperty = '_DisplayName',
    disableClearable = true,
    active = ['true'],
    ...other
}) => {
    return (
        <Field
            {...other}
            component={AutoComplete}
            optionsQuery={gql`
                ${query({ optionLabelProperty })}
            `}
            queryVariables={() => ({
                objectType: objectType,
                active: active
            })}
            optionLabelProperty={optionLabelProperty}
            disableClearable={disableClearable}
        />
    );
};

LookupValueEditor.propTypes = {
    objectType: PropTypes.string.isRequired
};
