import React from 'react';
import PropTypes from 'prop-types';
import { Hover } from 'client-shared/components/Hover';
import WarningIcon from '@mui/icons-material/Warning';
import { differenceInCalendarDays } from 'date-fns/fp';
import { parseDate } from 'client-shared/utility';
import { filter, flow, find } from 'lodash/fp';
const map = require('lodash/fp/map').convert({ cap: false });

/**
 *
 * @param expirationsToCheck - a collection of message, expiration dates to check and display if expiring.
 * [{message: '123', expirationDate:'2023-07-01'}, ...]
 * @param daysToWarn - if the expirationDate - now is less than daystowarn, it displays.
 * @returns {JSX.Element|null}
 * @constructor
 */
const getExpirations = (expirationsToCheck, daysToWarn) => {
    return flow(
        map((expirationToCheck) => ({
            ...expirationToCheck,
            daysTillExpiration: differenceInCalendarDays(new Date(), parseDate(expirationToCheck.expirationDate))
        })),
        filter((expiration) => expiration.daysTillExpiration <= daysToWarn)
    )(expirationsToCheck);
};

export const Expiration = ({ expirationsToCheck, daysToWarn = 5 }) => {
    const expirations = getExpirations(expirationsToCheck, daysToWarn);
    const Warning = ({ sx, ...other }) => (
        <WarningIcon
            sx={{
                ...{ color: find((expiration) => expiration.daysTillExpiration < 0, expirations) ? 'red' : 'gold' },
                ...sx
            }}
            {...other}
        />
    );
    if (!expirations.length) {
        return null;
    }
    let messages = map((expiration, index) => {
        return (
            <div key={index}>
                {expiration.message}: {expiration.daysTillExpiration} Days
            </div>
        );
    }, expirations);
    //messages = join(', ', messages);
    return <Hover message={messages} HoverIcon={Warning} />;
};

Expiration.propTypes = {
    expirationsToCheck: PropTypes.array.isRequired,
    daysToWarn: PropTypes.number
};
