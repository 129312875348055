import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { AutoComplete, Field, DateEditor } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { array, number, object } from 'yup';
import { format } from 'date-fns/fp';
import { get } from 'lodash/fp';
import { Section } from 'client-shared/components/Section';
import { gql } from 'graphql-tag';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const model = {
    Id: 0,
    Start: format("yyyy-MM-dd'T'00:00:00", new Date()),
    Vendor: {
        Id: '-1',
        _DisplayName: ''
    },
    Amount: 0,
    AccountCode: '',
    Description: ''
};

export const inventoryValidationSchema = object({
    Items: array()
        .min(1, 'At Least One Inventory Item Is Required')
        .of(
            object({
                Vendor: object({
                    Id: number().moreThan(0, 'Required')
                })
                    .nullable()
                    .required('Required'),
                Amount: number().min(1, 'Must be greater than 0').max(999999999, 'Must be less than 999,999,999')
            })
        )
});

export const InventoryEditor = ({ form, field, classes, label = '', vendors, ...other }) => {
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    return (
        <Section title="Inventory">
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((inventory, index) => {
                                    return (
                                        <div key={index}>
                                            <Field
                                                type="text"
                                                label="Vendor"
                                                name={`${field.name}.${index}.Vendor`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                optionsQuery={gql`
                                                    query ($searchString: String) {
                                                        cxPartys(
                                                            filters: [
                                                                {
                                                                    name: "String"
                                                                    values: ["DisplayName", $searchString]
                                                                }
                                                                {
                                                                    name: "ObjectType"
                                                                    values: ["Company", "Subcontractor"]
                                                                }
                                                            ]
                                                        ) {
                                                            Id
                                                            _DisplayName: DisplayName
                                                        }
                                                    }
                                                `}
                                                optionLabelProperty="_DisplayName"
                                            />
                                            <Field
                                                type="number"
                                                label="Amount"
                                                name={`${field.name}.${index}.Amount`}
                                                component={TextField}
                                                className={classes.textField}
                                            />
                                            <Field
                                                type="date"
                                                label="Trans. Date"
                                                name={`${field.name}.${index}.Start`}
                                                component={DateEditor}
                                                className={classes.textField}
                                            />
                                            <Field
                                                type="text"
                                                label="Ref. #"
                                                name={`${field.name}.${index}.AccountCode`}
                                                component={TextField}
                                                className={classes.textField}
                                            />
                                            <Field
                                                type="text"
                                                label="Description"
                                                name={`${field.name}.${index}.Description`}
                                                component={TextField}
                                                className={classes.textField}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

InventoryEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string
};
