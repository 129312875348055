import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { getFilter } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';

export const Filter = ({ path = 'customerInfo.ux.pages', filterName = 'jobcalendar.js', children }) => {
    const [user] = useContext(UserContext);
    let filterFormDefinition = getFilter(user, path, filterName);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};

Filter.propTypes = {
    path: PropTypes.string,
    filterName: PropTypes.string
};
