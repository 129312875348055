import React from 'react';
import PropTypes from 'prop-types';
import { isSameDay, format } from 'date-fns/fp';
import { parseDate } from 'client-shared/utility';
import { round } from 'lodash'; // lodash fp map only invokes iteratee with the value argument, not the key as documented.
import { get, flow, flatMap, filter, sortBy, join, keys, omit, forEach, find } from 'lodash/fp';
const map = require('lodash/fp/map').convert({ cap: false });

export const Accumulator = () => ({
    Employee: { count: 0, unit: '', name: 'Lbr', order: 10, style: (accumulator) => undefined },
    Equipment: { count: 0, unit: '', name: 'Eq', order: 20, style: (accumulator) => undefined },
    Generic: {
        count: 0,
        unit: '',
        name: 'Plnd',
        order: 4,
        style: (accumulator) => ({
            color:
                accumulator.Filled.count < accumulator.Generic.count
                    ? 'red'
                    : accumulator.Filled.count > accumulator.Generic.count
                      ? 'blue'
                      : undefined
        })
    },
    Material: { count: 0, unit: '', name: 'Mat', order: 40, style: (accumulator) => undefined },
    Plant: { count: 0, unit: '', name: 'Plnt', order: 50, style: (accumulator) => undefined },
    Subcontractor: { count: 0, unit: '', name: 'Sub', order: 60, style: (accumulator) => undefined },
    Filled: { count: 0, unit: '', name: 'Filled', order: 5, style: (accumulator) => undefined },
    Available: { count: 0, unit: '', name: 'Avlble', order: 2, style: (accumulator) => undefined },
    Total: { count: 0, unit: '', name: 'Total', order: 1, style: (accumulator) => undefined },
    Need: {
        count: 0,
        unit: '',
        name: 'Need',
        order: 65,
        style: (accumulator) => ({
            color: 'red'
        })
    },
    Scheduled: { count: 0, unit: '', name: 'Schd', order: 67, style: (accumulator) => undefined },
    Capacity: { count: 0, unit: '', name: 'Capacity', order: 70, style: (accumulator) => undefined },
    Utilization: {
        count: 0,
        unit: '',
        name: 'Utilization%',
        order: 75,
        style: (accumulator) => ({
            color: accumulator.Utilization.count > 100 ? 'red' : undefined
        })
    }
});

/**
 * allocations contain a map of TaskDays keyed by date, and not the same as the TaskDays collection.
 * @param allocations
 * @param start
 * @param counter
 * @param totalResources
 * @param workDays
 * @returns {{Generic: {unit: string, count: number, name: string, style: (function(*): {color: string|undefined}), order: number}, Material: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}, Filled: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}, Scheduled: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}, Plant: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}, Need: {unit: string, count: number, name: string, style: (function(*): {color: string}), order: number}, Employee: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}, Utilization: {unit: string, count: number, name: string, style: (function(*): {color: string|undefined}), order: number}, Equipment: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}, Capacity: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}, Total: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}, Subcontractor: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}, Available: {unit: string, count: number, name: string, style: (function(*): undefined), order: number}}}
 */
export const totals = ({ allocations, start, counter, totalResources, workDays }) => {
    // create one item per taskDay with allocation properties
    const mergedTaskDays = flatMap((allocation) => {
        const taskDays = allocation.TaskDays.get(start);
        if (!taskDays) {
            return [];
        }
        const a = omit(['TaskDays'], allocation);
        return map((taskDay) => {
            return {
                Allocation: a,
                ...taskDay
            };
        }, taskDays);
    }, allocations);

    return mergedTaskDaysTotals({ mergedTaskDays, start, counter, totalResources, workDays });
};

export const mergedTaskDaysTotals = ({
    mergedTaskDays,
    start,
    counter = (taskDay) => taskDay.Amount,
    totalResources,
    workDays
}) => {
    let accumulator = Accumulator();
    let duplicateResources = {};
    // add auxiliary resources to the taskDays.
    mergedTaskDays = flow(
        filter((taskDay) => (start ? isSameDay(parseDate(start), parseDate(taskDay.CurrentLifespan.Start)) : true)),
        flatMap((taskDay) => [
            taskDay,
            ...map((auxiliary) => {
                return {
                    Allocation: taskDay.Allocation,
                    Principals: [{ Id: auxiliary.Id }],
                    ActionType: auxiliary.ObjectType,
                    ResourceType: auxiliary.ResourceType,
                    CurrentLifespan: taskDay.CurrentLifespan,
                    Amount: 1
                };
            }, taskDay.Auxiliaries)
        ])
    )(mergedTaskDays);
    forEach((taskDay) => {
        // do not count scheduled employees or equipment more than one time.
        if (taskDay.ActionType === 'Employee' || taskDay.ActionType === 'Equipment') {
            const duplicateKey = `${taskDay.Principals[0].Id}${format(
                'yyyyMMdd',
                parseDate(taskDay.CurrentLifespan.Start)
            )}`;
            if (duplicateResources[duplicateKey]) {
                return;
            }
            duplicateResources[duplicateKey] = true;
        }
        accumulator[taskDay.ActionType].count += counter(taskDay);
        accumulator[taskDay.ActionType].unit =
            taskDay.ResourceType.Family === 'Material' ? taskDay.ResourceType.Unit.Name : '';
        // calculate how many resources were assigned to the generic.
        if (get('Allocation.ActionType', taskDay) === 'Generic' && get('ActionType', taskDay) !== 'Generic') {
            accumulator.Filled.count += taskDay.Amount;
        }
        // count generic crew allocations that are filled with a crew as 1.
        if (taskDay.Allocation.ActionType === 'Generic' && taskDay.Crew) {
            accumulator.Filled.count = 1;
        }
    }, mergedTaskDays);

    // remove filled if nothing is planned. this can happen when mixed resource types
    // are assigned to a generic and the generic only counts its own amount for the target
    // resource type (e.g. resource type row on the resource schedule).
    if (accumulator.Filled.count !== 0 && accumulator.Generic.count === 0) {
        accumulator.Filled.count = 0;
    }
    // accumulator.Scheduled.count = accumulator.Employee.count + accumulator.Equipment.count + accumulator.Generic.count;
    const planned =
        accumulator.Generic.count - accumulator.Filled.count > 0
            ? accumulator.Generic.count - accumulator.Filled.count
            : 0;
    accumulator.Scheduled.count = accumulator.Employee.count + accumulator.Equipment.count + planned;
    if (totalResources) {
        accumulator.Total.count = totalResources;
        accumulator.Available.count =
            accumulator.Total.count - (accumulator.Employee.count + accumulator.Equipment.count);
        accumulator.Need.count =
            accumulator.Available.count +
                accumulator.Subcontractor.count -
                (accumulator.Generic.count - accumulator.Filled.count) <
            0
                ? -(
                      accumulator.Available.count +
                      accumulator.Subcontractor.count -
                      (accumulator.Generic.count - accumulator.Filled.count)
                  )
                : 0;
        if (workDays) {
            accumulator.Capacity.count = accumulator.Total.count * workDays + accumulator.Subcontractor.count;
            // accumulator.Utilization.count = round((accumulator.Scheduled.count / accumulator.Capacity.count) * 100, 2);
            accumulator.Utilization.count = round((accumulator.Scheduled.count / accumulator.Capacity.count) * 100, 2);
            // const utilizationPercent = round(
            //     ((utilization.scheduledUtilization + utilization.plannedUtilization) / capacity) * 100,
            //     2
            // );
        }
    }

    return accumulator;
};

export const Totals = ({ allocations, start, totalResources, workDays, displayKeys, counter }) => {
    const accumulator = totals({
        allocations,
        start,
        totalResources,
        workDays,
        counter
    });
    return <DisplayTotals accumulator={accumulator} totalResources={totalResources} displayKeys={displayKeys} />;
};

Totals.propTypes = {
    allocations: PropTypes.array.isRequired,
    start: PropTypes.string,
    totalResources: PropTypes.number,
    workDays: PropTypes.number,
    displayKeys: PropTypes.array,
    counter: PropTypes.func
};

export const MergedTaskDaysTotals = ({ mergedTaskDays, start, totalResources, workDays, displayKeys, counter }) => {
    const accumulator = mergedTaskDaysTotals({
        mergedTaskDays,
        start,
        counter,
        totalResources,
        workDays
    });
    return <DisplayTotals accumulator={accumulator} totalResources={totalResources} displayKeys={displayKeys} />;
};

MergedTaskDaysTotals.propTypes = {
    mergedTaskDays: PropTypes.array.isRequired,
    start: PropTypes.string,
    totalResources: PropTypes.number,
    workDays: PropTypes.number,
    displayKeys: PropTypes.array,
    counter: PropTypes.func
};

const DisplayTotals = ({ accumulator, displayKeys = keys(Accumulator()) }) => {
    let accumulators = map((value, key) => {
        return { key: key, value: value };
    }, accumulator);
    accumulators = sortBy(['value.order'], accumulators);
    const Total = map((accumulatorKeyValue, index) => {
        if (!displayKeys.includes(accumulatorKeyValue.key)) {
            return null;
        }
        if (accumulatorKeyValue.key === 'Available' && accumulator.Total.count === 0) {
            return null;
        }
        if (accumulatorKeyValue.value.count === 0 && accumulatorKeyValue.key !== 'Available') {
            return null;
        }
        return (
            <span key={index} style={{ padding: 0, ...accumulatorKeyValue.value.style(accumulator) }}>{`${
                accumulatorKeyValue.value.name
            }:${round(accumulatorKeyValue.value.count, 2)} `}</span>
        );
    }, accumulators);
    return Total;
};

DisplayTotals.propTypes = {
    accumulator: PropTypes.object.isRequired,
    displayKeys: PropTypes.array
};

export const displayTotals = (accumulator, displayKeys = keys(Accumulator())) => {
    let accumulators = map((value, key) => {
        return { key: key, value: value };
    }, accumulator);
    accumulators = sortBy(['value.order'], accumulators);
    let totals = [];
    forEach((accumulatorKeyValue, index) => {
        if (!displayKeys.includes(accumulatorKeyValue.key)) {
            return;
        }
        if (accumulatorKeyValue.key === 'Available' && accumulator.Total.count === 0) {
            return;
        }
        if (accumulatorKeyValue.value.count === 0 && accumulatorKeyValue.key !== 'Available') {
            return;
        }
        totals.push(`${accumulatorKeyValue.value.name}:${round(accumulatorKeyValue.value.count, 2)}`);
    }, accumulators);
    return join('\n', totals);
};

export const DisplayAllocationTotals = ({ allocationDays = [], start }) => {
    const allocationDay = find(
        (allocationDay) =>
            allocationDay.ActionType === 'Generic' && isSameDay(parseDate(start), parseDate(allocationDay.Start)),
        allocationDays
    );
    if (!allocationDay) {
        return null;
    }
    const color =
        allocationDay.FilledAmount < allocationDay.PlannedAmount
            ? 'red'
            : allocationDay.FilledAmount > allocationDay.PlannedAmount
              ? 'blue'
              : undefined;

    return (
        <span style={{ color: color }}>
            {' '}
            Planned: {allocationDay.PlannedAmount} Filled: {allocationDay.FilledAmount}
        </span>
    );
};

DisplayAllocationTotals.propTypes = {
    allocationDays: PropTypes.array.isRequired,
    start: PropTypes.string.isRequired
};
