import React, { useEffect, useState } from 'react';
import { keyBy, isEmpty, map, groupBy, get, curry, size, filter } from 'lodash/fp';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { cbDateToDhtmlx, pipeP, zipObjects } from 'global-shared/utils/utils';
import { useImperative } from 'client-shared/utility/useimperative';
import { FormEditor } from './formeditor';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useApolloClient } from '@apollo/client';
import {
    evalQuery,
    fetchMinimalEntity,
    getGraphqlHelper,
    makeVerbOperation,
    deleteQuery
} from '../../gqlhelpers/queryHelpers';
import { useAttachments } from '../attachments/useAttachments';
import DoneIcon from '@mui/icons-material/Done';
import DoneAll from '@mui/icons-material/DoneAll';
import AddIcon from '@mui/icons-material/Add';
import { cleanObject } from '../../utility';

const itemQuery = `
        Id
        TemplateFormPool {
            Id
            Name
            DisplayName
            Description
            Key
            MultiInstance
            Required
        }
        Forms {
        Id,
        TemplateForm{
            Id
            MultiInstance
            Required
            Key
        }
         Name
         DisplayName
         Description
        Completed
        Attachments{
           Name,
           DisplayName
           Completed
           Creator {
            Id
            DisplayName
           }
           Approvals {
                Approver {
                Id
                DisplayName
                }
                Completed
                AsOf
           }
           Completed
           LastModified
            Id
            Key
            Created
        }  
        }
        `;

export const Formwidget = ({ fn, entityId, clazz, ...props }) => {
    const [showFormEditor, toggleFormEditor] = useImperative();
    const [formEntity, setFormEntity] = useState({});
    const [toggle, setToggle] = useState(false);

    const client = useApolloClient();
    const [, _deleteAttachment] = useAttachments(client, 'CxForm');
    const GQL = getGraphqlHelper(clazz);

    const stateWrapper = (_in) =>
        setFormEntity({
            formsActual: mergeForms(get('Forms', _in), get('TemplateFormPool', _in)),
            forms: get('Forms', _in),
            pool: get('TemplateFormPool', _in)
        });

    useEffect(() => {
        if (!GQL) return;
        pipeP(
            fetchMinimalEntity(client, GQL, [makeAttachmentFragment(GQL.entityClass)]),
            cleanObject,
            stateWrapper
        )(entityId);
    }, [entityId, GQL, client, toggle]);

    const formatChip = (each) => {
        const attachment = get('oneForm.Attachments.0', each);
        const props = {};

        if (size(each?.oneForm?.Attachments) === 1) {
            props['color'] = 'primary';
            props['onDelete'] = handleDelete(each);
        }

        if (each.Required) {
            props['color'] = 'warning';
            if (!get('oneForm', each)) props['color'] = 'error';
        }

        if (size(get('Approvals', attachment)) === 1) {
            props['icon'] = <DoneIcon />;
            props['color'] = 'primary';
        }

        if (size(get('Approvals', attachment)) > 1) {
            props['icon'] = <DoneAll />;
            props['color'] = 'primary';
        }
        return (
            <HtmlTooltip
                key={each.Key}
                title={makeToolTip(
                    get('Creator.DisplayName', attachment),
                    get('LastModified', attachment),
                    get('DisplayName', attachment),
                    get('Description', each),
                    get('Approvals', attachment)
                )}
            >
                <Chip
                    key={each.Id}
                    label={each.DisplayName}
                    onClick={() => {
                        toggleFormEditor(upDateForms, {
                            formTemplate: each,
                            attachment: get('oneForm.Attachments.0', each),
                            entityId
                        });
                    }}
                    variant="outlined"
                    {...props}
                />
            </HtmlTooltip>
        );
    };

    const makeToolTip = (creator, modified, name, description, approval) => {
        return (
            <React.Fragment>
                <Typography color="inherit">{name}</Typography>
                {description && <div>{description}</div>}
                {creator && (
                    <div>
                        {' '}
                        {cbDateToDhtmlx(modified)} By: {creator}
                    </div>
                )}
                {size(approval) > 0 && <Typography color="inherit">Approvals</Typography>}
                {size(approval) > 0 &&
                    map(
                        (each) => (
                            <div>
                                {cbDateToDhtmlx(each.AsOf)}: {get('Approver.DisplayName', each)}
                            </div>
                        ),
                        approval
                    )}
            </React.Fragment>
        );
    };

    const formatAttachmentChip = curry((formTemplate, each) => {
        const props = {};

        props['color'] = 'primary';
        props['onDelete'] = handleMultiDelete(each, formTemplate);

        if (formTemplate.Required) {
            props['color'] = 'warning';
            //if (!get('forms.0', each)) props['color'] = 'error';
        }

        if (size(get('Approvals', each)) === 1) {
            props['icon'] = <DoneIcon />;
            props['color'] = 'primary';
        }

        if (size(get('Approvals', each)) > 1) {
            props['icon'] = <DoneAll />;
            props['color'] = 'primary';
        }

        return (
            <HtmlTooltip
                key={each.Key}
                title={makeToolTip(
                    get('Creator.DisplayName', each),
                    get('LastModified', each),
                    get('DisplayName', formTemplate),
                    null,
                    get('Approvals', each)
                )}
            >
                <Chip
                    key={each.Id}
                    label={each.DisplayName}
                    onClick={() => {
                        toggleFormEditor(upDateForms, { formTemplate, attachment: each, entityId });
                    }}
                    variant="outlined"
                    {...props}
                />
            </HtmlTooltip>
        );
    });

    const displayMultiForm = (each) => {
        // console.log(each)

        return (
            <Stack key={each.Id} direction="row" spacing={0} sx={{ flexWrap: 'wrap', gap: 1 }}>
                <HtmlTooltip key={each.Key} title={<React.Fragment>{each.Description}</React.Fragment>}>
                    <Chip
                        label={each.DisplayName}
                        onClick={() => {
                            toggleFormEditor(upDateForms, { formTemplate: each, entityId });
                        }}
                        icon={<AddIcon />}
                        variant="outlined"
                    />
                </HtmlTooltip>
                {map(formatAttachmentChip(each), get('oneForm.Attachments', each))}
            </Stack>
        );
    };

    const upDateForms = ([form, formId]) => {
        if (form === 'right') return;
        if (form === 'completed') return setToggle(setToggle(!toggle));

        persistForm(entityId, [form]).then(() => setToggle(!toggle));
    };

    const removeAttachment = curry((entityId, documentId) => {
        return _deleteAttachment(entityId, documentId).then(() => setToggle(!toggle));
    });

    const persistForm = curry((entityId, forms) => {
        const { methodsByItem, filters } = makeVerbOperation('AddForms', {
            ids: [entityId],
            args: { forms },
            preserve: true
        });
        return evalQuery(client, itemQuery, GQL.entityClass, [], methodsByItem, filters).then(
            get(`data.${GQL.saveMutation}.0`)
        );
    });

    const singleForms = filter({ MultiInstance: false }, formEntity.formsActual);
    const multiForms = filter({ MultiInstance: true }, formEntity.formsActual);

    const handleDelete = (_in) => async () => {
        const formId = get('oneForm.Id', _in);
        const attachmentId = get('oneForm.Attachments.0.Id', _in);
        await removeAttachment(formId, attachmentId);
        if (get('oneForm.Attachments', _in).length === 1) {
            await deleteQuery(client, 'CxForm', null, [formId]);
        }

        //handleDelete(entityId, { Forms: withDeletion }).then(stateWrapper);
    };

    const handleMultiDelete = (_in, template) => async () => {
        const formId = get('oneForm.Id', template);
        const attachmentId = get('Id', _in);
        removeAttachment(formId, attachmentId);
        if (get('oneForm.Attachments', template).length === 1) {
            await deleteQuery(client, 'CxForm', null, [formId]);
        }
    };

    //console.log(formEntity.formsActual)
    return (
        <div style={{ marginLeft: '5px' }}>
            {!isEmpty(formEntity.pool) && (
                <div>
                    <h3>Forms</h3>
                    <Stack direction="row" spacing={0} sx={{ flexWrap: 'wrap', gap: 1 }}>
                        {map(formatChip, singleForms)}
                    </Stack>
                    {map(displayMultiForm, multiForms)}
                    <FormEditor state={showFormEditor} left="Cancel" right="OK" hide={toggleFormEditor} />
                </div>
            )}
        </div>
    );
};

export const mergeForms = (forms, pool) => {
    const keyedForms = groupBy('TemplateForm.Id', forms);
    const kkeyedForms = keyBy('TemplateForm.Id', forms);
    const keyedTemplates = keyBy('Id', pool);
    let foo = zipObjects('forms', keyedTemplates, keyedForms);
    return zipObjects('oneForm', foo, kkeyedForms);
};

export const makeAttachmentFragment = (on) => ({
    fragmentName: 'FORMS',
    fragment: `fragment FORMS on ${on} {
    ${itemQuery}
    }`
});

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9'
        }
    })
);
