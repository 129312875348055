import React, { useEffect, useState } from 'react';
import { readCache, writeCache, OPTIONSCACHE } from 'client-shared/utility';
import { useApolloClient } from '@apollo/client';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const initialToolBarOptions = {
    copy: false,
    merge: false
};

export const getToolBarOptions = (client) => {
    return readCache(client, OPTIONSCACHE, initialToolBarOptions);
};

const saveToolBarOptions = (client, key, value) => {
    const newToolBarOptions = {
        ...initialToolBarOptions,
        [key]: value
    };
    writeCache(client, OPTIONSCACHE, newToolBarOptions);
    return newToolBarOptions;
};

export const ToolBarOptions = (props) => {
    const client = useApolloClient();
    const [copyMode, setCopyMode] = useState(false);
    useEffect(() => {
        saveToolBarOptions(client);
    }, [client]);

    const handleCopyMode = (event) => {
        setCopyMode(event.target.checked);
        saveToolBarOptions(client, 'copy', event.target.checked);
    };
    return (
        <FormControlLabel
            control={<Switch size="small" checked={copyMode} onChange={handleCopyMode} />}
            label={
                <Typography variant="button" fontSize=".75rem">
                    Copy
                </Typography>
            }
            {...props}
        />
    );
};
