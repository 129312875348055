/* eslint-disable no-lone-blocks */
import { getSelectedItems } from './useselected';
import { readCache, SCHEDULECACHE, OPTIONSCACHE, TOOLBARCACHE, CONFLICTCACHE } from './readwritecache';
import { getRangeStartEnd, parseDate } from './dateutilities';
import { Mutation } from './mutation';
import { pickFromTemplate, omitEmptyProperties } from './pickfromtemplate';
import {
    format,
    addDays,
    isSameDay,
    getYear,
    getMonth,
    getDate,
    getHours,
    getMinutes,
    differenceInCalendarDays
} from 'date-fns/fp';
import { flow, map, uniq, sortBy, filter, forEach } from 'lodash/fp';
import model from 'cb-schema/inputmodelfull/CxAllocation.json';
import { getRange, createSchedule, getResourcesToSchedule, getJobsToSchedule } from './';

// handle Map in json stringify
function replacer(key, value) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()) // or with spread: value: [...value]
        };
    } else {
        return value;
    }
}

// handle Map in json parse
function reviver(key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}

/**
 * create methodByItems collection to manipulate objects
 * using the controlboard server eval syntax. the routine allows a collection of methodsByItems and
 * a collection of items to be passed and returns an indice adjusted
 * this.methodsByItem and this.items collection.
 * @constructor
 */
export function MethodsByItem() {
    let index = 0;
    this.methodsByItem = [];
    this.items = [];
    // allow multiple items. this makes sense for a cell drop.
    this.addMethodsByItem = (methodsByItem, items) => {
        if (!methodsByItem || methodsByItem.length === 0) {
            return;
        }
        forEach((methodByItem) => {
            methodByItem.indices = map((indice) => (indice += index), methodByItem.indices);
            this.methodsByItem.push(methodByItem);
        }, methodsByItem);
        this.items.push(...items);
        index += items.length;
    };
    // simple version, always assume one item being operated on. this makes sense for
    // dropping on an allocation, since only one drop target, the allocation.
    this.addMethodsBySingleItem = (methodsByItem, items) => {
        if (!methodsByItem || methodsByItem.length === 0) {
            return;
        }
        this.methodsByItem.push(...methodsByItem);
        this.items = items;
    };
}

const getToolBarOptions = (client) => {
    return readCache(client, OPTIONSCACHE, {
        copy: false,
        merge: false
    });
};

export class DragDropBase extends Mutation {
    constructor(client, privilege, targetProperties) {
        super(client, privilege);
        this.targetProperties = targetProperties;
    }

    allowDrop = (ev) => {
        if (this.privilege) {
            return;
        }
        ev.preventDefault();
    };

    handleDrag = (draggedItems) => (ev) => {
        //ev.dataTransfer.setDragImage(elem, 0, 0);
        ev.dataTransfer.setData('cell', JSON.stringify(draggedItems, replacer));
    };

    cutPasteAllocationOnCell({ selectedItemProperties, targetProperties, allocation, toolBarOptions, selected }) {
        throw new Error('class DragDropBase: Missing cutPasteAllocationOnCell implementation');
    }

    cutPasteTaskDayOnCell({ selectedItemProperties, targetProperties, taskDay, toolBarOptions, selected }) {
        throw new Error('class DragDropBase: Missing cutPasteTaskDayOnCell implementation');
    }

    pasteJobActivityOnCell({ selectedItemProperties, targetProperties, jobActivity, toolBarOptions, selected }) {
        throw new Error('class DragDropBase: Missing pasteJobActivityOnCell implementation');
    }

    pasteJobActivityOnAllocation({ selectedItemProperties, targetProperties, jobActivity, toolBarOptions, selected }) {
        return this.pasteJobActivityOnCell({
            selectedItemProperties: selectedItemProperties,
            targetProperties: this.targetProperties,
            jobActivity: jobActivity,
            toolBarOptions: toolBarOptions,
            selected: selected
        });
    }

    pasteResourceOnCell({ selectedItemProperties, targetProperties, resource, toolBarOptions, selected }) {
        throw new Error('class DragDropBase: Missing pasteResourceOnCell implementation');
    }

    pasteSupplierOnCell({ selectedItemProperties, targetProperties, supplier, toolBarOptions, selected }) {
        throw new Error('class DragDropBase: Missing pasteSupplierOnCell implementation');
    }

    pasteResourceTypeOnCell({ selectedItemProperties, targetProperties, resourceType, toolBarOptions, selected }) {
        throw new Error('class DragDropBase: Missing pasteResourceTypeOnCell implementation');
    }

    cutPasteCrewAllocation({ allocation, crew_Id, start, copy }) {
        throw new Error('class DragDropBase: Missing cutPasteCrewAllocation implementation');
    }

    pasteResourceTypeOnTaskDay({
        selectedItemProperties,
        targetProperties,
        resourceType,
        allocation,
        toolBarOptions,
        selected
    }) {
        const _taskDay = {
            ParentId: allocation.Id,
            Id: 0,
            CurrentLifespan: {
                start: format("yyyy-MM-dd'T'08:00:00", parseDate(targetProperties.CurrentLifespan.Start)),
                end: format("yyyy-MM-dd'T'17:00:00", parseDate(targetProperties.CurrentLifespan.Start))
            },
            Supplier: { Id: targetProperties.defaultCompanyId },
            ResourceType: { Id: resourceType.Id },
            ResourceSubtype: { Id: resourceType.ResourceSubtype.Id },
            Amount: 1
        };
        const methods = [
            {
                name: 'addTaskDays',
                args: {
                    taskDays: [_taskDay]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [
                {
                    Id: allocation.Id,
                    JobActivity: { Id: allocation.JobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    pasteResourceOnAllocation({
        selectedItemProperties,
        targetProperties,
        resource,
        allocation,
        toolBarOptions,
        selected
    }) {
        const methods = [
            {
                name: 'addResources',
                args: {
                    resourceIds: [resource.Id]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [
                {
                    Id: allocation.Id,
                    JobActivity: { Id: allocation.JobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    pasteResourceOnTaskDay({
        selectedItemProperties,
        targetProperties,
        resource,
        allocation,
        toolBarOptions,
        selected,
        taskDay
    }) {
        const methods = [
            {
                name: 'addResources',
                args: {
                    resourceIds: [resource.Id],
                    start: format("yyyy-MM-dd'T'00:00:00", parseDate(targetProperties.CurrentLifespan.Start)),
                    end: flow(
                        parseDate,
                        addDays(1),
                        format("yyyy-MM-dd'T'00:00:00")
                    )(targetProperties.CurrentLifespan.Start)
                }
            }
        ];

        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [
                {
                    Id: allocation.Id,
                    JobActivity: { Id: allocation.JobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    cutPasteTaskDayOnAllocation({
        selectedItemProperties,
        targetProperties,
        allocation,
        taskDay,
        toolBarOptions,
        selected
    }) {
        const addTaskDays = () => {
            const cellStart = parseDate(targetProperties.CurrentLifespan.Start);
            const allocationStart = parseDate(allocation.CurrentLifespan.Start);
            const allocationEnd = parseDate(allocation.CurrentLifespan.End);
            const start = new Date(
                getYear(cellStart),
                getMonth(cellStart),
                getDate(cellStart),
                getHours(allocationStart),
                getMinutes(allocationStart)
            );
            let end = new Date(
                getYear(cellStart),
                getMonth(cellStart),
                getDate(cellStart),
                getHours(allocationEnd),
                getMinutes(allocationEnd)
            );
            if (start.getTime() > end.getTime()) {
                end = addDays(1, end);
            }

            const currentLifespan = {
                Start: format("yyyy-MM-dd'T'HH:mm:00", start),
                End: format("yyyy-MM-dd'T'HH:mm:00", end)
            };
            if (taskDay.ObjectType === 'ActualTaskDay') {
                return [
                    {
                        name: 'addTaskDays',
                        args: {
                            taskDays: [
                                {
                                    ParentId: allocation.Id, // add taskDay to allocation.
                                    Id: toolBarOptions.copy ? 0 : taskDay.Id,
                                    Principals: [{ Id: taskDay.Principals[0].Id }],
                                    CurrentLifespan: currentLifespan
                                }
                            ]
                        }
                    }
                ];
            }

            return [
                {
                    name: 'addTaskDays',
                    args: {
                        taskDays: [
                            {
                                ParentId: allocation.Id, // add taskDay to allocation.
                                Id: toolBarOptions.copy ? 0 : taskDay.Id,
                                Supplier: taskDay.Supplier,
                                ResourceSubtype: { Id: taskDay.ResourceSubtype.Id },
                                Quantity: taskDay.Quantity,
                                CurrentLifespan: currentLifespan
                            }
                        ]
                    }
                }
            ];
        };

        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: addTaskDays()
                }
            ],
            items: [
                {
                    Id: allocation.Id,
                    JobActivity: { Id: allocation.JobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    /**
     *  Shift allocations within a job activity.
     * @param daysToShift - number of days to shift.
     * @param shiftAll - shift all allocations across resources
     * @param selectedItems - list of  allocations, and taskDays to shift.
     */
    shiftAllocation({ daysToShift, shiftAll, selectedItems }) {
        throw new Error('class DragDropBase: Missing shiftAllocation implementation');
    }

    copyAllocation({ ids, start, end, daysToShift }) {
        const methods = [
            {
                name: 'copy',
                args: {
                    daysToShift: daysToShift,
                    start: start,
                    end: end
                }
            }
        ];
        return {
            filters: [
                {
                    name: 'Id',
                    values: ids
                }
            ],
            methodsByItem: [
                {
                    indices: [],
                    methods: methods
                }
            ],
            items: []
        };
    }

    /**
     * merge two allocations into one grouped allocation.
     * @param allocation - target allocation.
     * @param allocationToMerge - allocation to merge into target allocation.
     * @returns {{methodsByItem: {indices: [number], methods: *}[], items: {JobActivity: {Id: *}, ObjectType: string, Id: *}[]}}
     */
    mergeAllocation({ allocation, allocationToMerge }) {
        const methods = [
            {
                name: 'merge',
                args: {
                    allocationIds: [allocationToMerge.Id]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [
                {
                    Id: allocation.Id,
                    JobActivity: { Id: allocation.JobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    /**
     * Set the lifespan of a set of allocations.
     * @param ids - allocation ids to set the range on.
     * @param start - optional start date of allocation lifespan.
     * @param end - optional end date of allocation lifespan.
     * @param changeTimes - optional boolean to indicate if the time should be set with the date.
     * @returns {{filters: {values: *, name: string}[], methodsByItem: {indices: [], methods: *}[], items: []}}
     */
    setRangeAllocation({ ids, start, end, changeTimes }) {
        const methods = [
            {
                name: 'setRange',
                args: {
                    start: start
                        ? changeTimes
                            ? start
                            : flow(parseDate, format("yyyy-MM-dd'T'00:00:00"))(start)
                        : undefined,
                    end: end
                        ? changeTimes
                            ? end
                            : flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(end)
                        : undefined
                }
            }
        ];
        methods[0].args = omitEmptyProperties(methods[0].args);
        return {
            filters: [
                {
                    name: 'Id',
                    values: map((id) => id.toString(), ids)
                }
            ],
            methodsByItem: [
                {
                    indices: [],
                    methods: methods
                }
            ],
            items: []
        };
    }

    /**
     * set the actionstatus of allocations
     *  @param ids - allocation ids to set the action status on.
     * @param actionStatusId
     * @returns {{filters: [{values: *, name: string}], methodsByItem: [{indices: [], methods: [{args: {start: *, end: *}, name: string}]}], items: []}}
     */
    setActionStatusAllocation({ ids, actionStatusId }) {
        const methods = [
            {
                name: 'set',
                args: {
                    properties: { ActionStatus: { Id: actionStatusId } }
                }
            }
        ];
        // const methods = [
        //
        //     {
        //         name: 'set',
        //         args: {
        //             actionStatusId: actionStatusId
        //         }
        //     }
        // ];
        return {
            filters: [
                {
                    name: 'Id',
                    values: map((id) => id.toString(), ids)
                }
            ],
            methodsByItem: [
                {
                    indices: [],
                    methods: methods
                }
            ],
            items: []
        };
    }

    bulkEdit({ ids, properties, touched }) {
        const methods = [
            {
                name: 'set',
                args: {
                    properties: properties
                }
            }
        ];
        return {
            filters: [
                {
                    name: 'Id',
                    values: map((id) => id.toString(), ids)
                }
            ],
            methodsByItem: [
                {
                    indices: [],
                    methods: methods
                }
            ],
            items: []
        };
    }

    autoSchedule({ day, schedule, actionStatus }) {
        const methods = [
            {
                name: 'addResources',
                args: {
                    resourceIds: [schedule.resource],
                    start: flow(parseDate, format("yyyy-MM-dd'T'00:00:00"))(day),
                    end: flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(day)
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: schedule.job },
                    ActionStatus: { Id: actionStatus.Id }
                }
            ]
        };
    }

    resolveConflicts({ client, start, end, resetPartials }) {
        const items = readCache(client, CONFLICTCACHE);
        const methods = [
            {
                name: 'ResolveConflicts',
                args: {
                    start: start,
                    end: end,
                    resetPartials: resetPartials,
                    jobIds: flow(
                        filter((item) => item.ObjectType === 'Job'),
                        map((item) => item.Id)
                    )(items),
                    resourceIds: flow(
                        filter((item) => ['Crew', 'Resource'].includes(item.ObjectType)),
                        map((item) => item.Id)
                    )(items)
                }
            }
        ];
        return {
            filters: [],
            methodsByItem: [
                {
                    indices: [],
                    methods: methods
                }
            ],
            items: []
        };
    }
}

// drop on cell, not on allocation.
export const CellDragDropMixin = (superclass) =>
    class extends superclass {
        constructor(client, privilege, targetProperties, handleOpenEditor) {
            super(client, privilege, targetProperties);
            this.handleOpenEditor = handleOpenEditor;
        }
        handleCellDrop(ev) {
            ev.stopPropagation();
            ev.preventDefault();
            const toolBarOptions = getToolBarOptions(this.client);
            const data = ev.dataTransfer.getData('cell');
            let droppedItems = JSON.parse(data, reviver);
            const methodsByItem = new MethodsByItem();
            // check type of object(s) that were dropped. could be resources, allocations, or taskdays
            // set the object properties that were dropped to match targetProperties.
            forEach((droppedItem) => {
                const { selectedItemProperties, item, /*cacheName,*/ selected } = droppedItem;
                switch (item.ObjectType) {
                    case 'Allocation':
                        {
                            const v = this.cutPasteAllocationOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                allocation: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'ActualTaskDay':
                    case 'VirtualTaskDay':
                        {
                            const v = this.cutPasteTaskDayOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                taskDay: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'Employee':
                    case 'Equipment':
                    case 'Crew':
                    case 'Material':
                        {
                            const v = this.pasteResourceOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                resource: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'JobActivity':
                        {
                            const v = this.pasteJobActivityOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                jobActivity: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'ResourceType':
                        {
                            const v = this.pasteResourceTypeOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                resourceType: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'Plant':
                    case 'Subcontractor':
                        {
                            const v = this.pasteSupplierOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                supplier: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    default:
                        break;
                }
            }, droppedItems);
            this.save('CxAllocation', pickFromTemplate(methodsByItem.items, model), methodsByItem.methodsByItem).catch(
                (error) => console.log(error)
            );
        }
    };

// drop on allocation, not cell.
export const AllocationDragDropMixin = (superclass) =>
    class extends superclass {
        constructor(client, privilege, targetProperties, targetAllocation, handleOpenEditor) {
            super(client, privilege, targetProperties);
            this.targetAllocation = targetAllocation;
            this.handleOpenEditor = handleOpenEditor;
        }

        handleAllocationDrop(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            const toolBarOptions = getToolBarOptions(this.client);
            const data = ev.dataTransfer.getData('cell');
            let droppedItems = JSON.parse(data, reviver);
            const methodsByItem = new MethodsByItem();
            // check type of object(s) that were dropped. could be resources, allocations, or taskdays
            // set droppedItems properties to match targetProperties.
            forEach((droppedItem) => {
                const { selectedItemProperties, item, /*cacheName,*/ selected } = droppedItem;
                switch (item.ObjectType) {
                    case 'Allocation':
                        if (toolBarOptions.merge) {
                            const v = this.mergeAllocation({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                allocation: this.targetAllocation,
                                allocationToMerge: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsBySingleItem(v.methodsByItem, v.items);
                        } else {
                            const v = this.cutPasteAllocationOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                allocation: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'ActualTaskDay':
                    case 'VirtualTaskDay':
                        {
                            const v = this.cutPasteTaskDayOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                allocation: this.targetAllocation,
                                taskDay: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            // methodsByItem.addMethodsBySingleItem(v.methodsByItem, v.items);
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'Employee':
                    case 'Equipment':
                    case 'Crew':
                    case 'Material':
                        {
                            if (this.targetAllocation.ActionType === 'Generic') {
                                const v = this.pasteResourceOnAllocation({
                                    selectedItemProperties: selectedItemProperties,
                                    targetProperties: this.targetProperties,
                                    resource: item,
                                    allocation: this.targetAllocation,
                                    toolBarOptions: toolBarOptions,
                                    selected: selected
                                });
                                methodsByItem.addMethodsBySingleItem(v.methodsByItem, v.items);
                            } else {
                                const v = this.pasteResourceOnCell({
                                    selectedItemProperties: selectedItemProperties,
                                    targetProperties: this.targetProperties,
                                    resource: item,
                                    toolBarOptions: toolBarOptions,
                                    selected: selected
                                });
                                methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                            }
                        }
                        break;
                    case 'ResourceType':
                        {
                            const v = this.pasteResourceTypeOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                resourceType: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'Plant':
                    case 'Subcontractor':
                        {
                            const v = this.pasteSupplierOnCell({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                supplier: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsByItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'JobActivity':
                        {
                            const v = this.pasteJobActivityOnAllocation({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                allocation: this.targetAllocation,
                                jobActivity: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsBySingleItem(v.methodsByItem, v.items);
                        }
                        break;
                    default:
                        break;
                }
            }, droppedItems);
            this.save('CxAllocation', pickFromTemplate(methodsByItem.items, model), methodsByItem.methodsByItem).catch(
                (error) => console.log(error)
            );
        }

        handleTaskDayDrop(ev, taskDay) {
            ev.preventDefault();
            ev.stopPropagation();
            const toolBarOptions = getToolBarOptions(this.client);
            const data = ev.dataTransfer.getData('cell');
            let droppedItems = JSON.parse(data, reviver);
            const methodsByItem = new MethodsByItem();
            // check type of object(s) that were dropped. could be resources, allocations, or taskdays
            // set droppedItems properties to match targetProperties.
            forEach((droppedItem) => {
                const { selectedItemProperties, item, /*cacheName,*/ selected } = droppedItem;
                switch (item.ObjectType) {
                    case 'Employee':
                    case 'Equipment':
                    case 'Crew':
                    case 'Material':
                        {
                            const v = this.pasteResourceOnTaskDay({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                resource: item,
                                allocation: this.targetAllocation,
                                toolBarOptions: toolBarOptions,
                                selected: selected,
                                taskDay: taskDay
                            });
                            methodsByItem.addMethodsBySingleItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'ResourceType':
                        {
                            const v = this.pasteResourceTypeOnTaskDay({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                resourceType: item,
                                allocation: this.targetAllocation,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsBySingleItem(v.methodsByItem, v.items);
                        }
                        break;
                    case 'ActualTaskDay':
                    case 'VirtualTaskDay':
                        {
                            const v = this.cutPasteTaskDayOnAllocation({
                                selectedItemProperties: selectedItemProperties,
                                targetProperties: this.targetProperties,
                                allocation: this.targetAllocation,
                                taskDay: item,
                                toolBarOptions: toolBarOptions,
                                selected: selected
                            });
                            methodsByItem.addMethodsBySingleItem(v.methodsByItem, v.items);
                        }
                        break;
                    default:
                        this.handleAllocationDrop(ev);
                        break;
                }
            }, droppedItems);
            this.save('CxAllocation', pickFromTemplate(methodsByItem.items, model), methodsByItem.methodsByItem).catch(
                (error) => console.log(error)
            );
        }
    };

export const ActionsMixin = (superclass) =>
    class extends superclass {
        handleDeleteDrop(ev) {
            ev.preventDefault();
            const data = ev.dataTransfer.getData('cell');
            let droppedItems = JSON.parse(data, reviver);
            const allocationIds = [];
            const taskDayIds = [];
            // check type of object(s) that were dropped. could be allocations, or taskdays
            forEach((droppedItem) => {
                const { selectedItemProperties, item, /* cacheName,*/ selected } = droppedItem;
                switch (item.ObjectType) {
                    case 'Allocation':
                        if (selected) {
                            allocationIds.push(item.Id);
                        } else {
                            const ids = map(
                                (taskDay) => taskDay.Id,
                                item.TaskDays.get(selectedItemProperties.CurrentLifespan.Start)
                            );
                            taskDayIds.push(...ids);
                        }
                        break;
                    case 'ActualTaskDay':
                    case 'VirtualTaskDay':
                        taskDayIds.push(item.Id);
                        break;
                    default:
                        break;
                }
            }, droppedItems);
            if (allocationIds.length) {
                this.delete('CxAllocation', allocationIds).catch((error) => console.log(error));
            }
            if (taskDayIds.length) {
                this.delete('CxTaskDay', taskDayIds).catch((error) => console.log(error));
            }
        }

        handleShiftAllocation({ daysToShift, shiftAll }) {
            const selectedItems = getSelectedItems(this.client, SCHEDULECACHE);
            if (selectedItems.length) {
                this.shiftAllocation({ daysToShift, shiftAll, selectedItems });
            }
        }

        handleSetRangeAllocation({ start, end, changeTimes }) {
            let selectedItems = getSelectedItems(this.client, SCHEDULECACHE);
            selectedItems = filter((selectedItem) => selectedItem.item.ObjectType === 'Allocation', selectedItems);
            if (selectedItems.length) {
                const v = this.setRangeAllocation({
                    ids: map((selectedItem) => selectedItem.item.Id, selectedItems),
                    start: start,
                    end: end,
                    changeTimes: changeTimes
                });
                this.save('CxAllocation', pickFromTemplate(v.items, model), v.methodsByItem, v.filters).catch((error) =>
                    console.log(error)
                );
            }
        }

        handleCopyAllocation({ start, days }) {
            let selectedItems = getSelectedItems(this.client, SCHEDULECACHE);
            selectedItems = filter((selectedItem) => selectedItem.item.ObjectType === 'Allocation', selectedItems);
            if (!selectedItems.length) {
                return;
            }
            // get a unique set of selected item dates in ascending order.
            const dates = flow(
                map((selectedItem) =>
                    format("yyyy-MM-dd'T'00:00:00", parseDate(selectedItem.item.CurrentLifespan.Start))
                ),
                uniq,
                sortBy([])
            )(selectedItems);
            // iterate each date and call copy for the allocations in the date.
            const daysToShift = differenceInCalendarDays(parseDate(dates[0]), parseDate(start));
            forEach((date) => {
                const ids = flow(
                    filter((selectedItem) =>
                        isSameDay(parseDate(selectedItem.item.CurrentLifespan.Start), parseDate(date))
                    ),
                    map((selectedItem) => selectedItem.item.Id)
                )(selectedItems);
                const v = this.copyAllocation({
                    ids: ids,
                    start: date,
                    end: flow(addDays(days), format("yyyy-MM-dd'T'00:00:00"))(parseDate(date)),
                    daysToShift: daysToShift
                });
                this.save('CxAllocation', pickFromTemplate(v.items, model), v.methodsByItem, v.filters).catch((error) =>
                    console.log(error)
                );
            }, dates);
        }

        handleSetActionStatusAllocation({ Id }) {
            let selectedItems = getSelectedItems(this.client, SCHEDULECACHE);
            selectedItems = filter((selectedItem) => selectedItem.item.ObjectType === 'Allocation', selectedItems);
            if (selectedItems.length) {
                const v = this.setActionStatusAllocation({
                    ids: map((selectedItem) => selectedItem.item.Id, selectedItems),
                    actionStatusId: Id
                });
                this.save('CxAllocation', pickFromTemplate(v.items, model), v.methodsByItem, v.filters).catch((error) =>
                    console.log(error)
                );
            }
        }

        handleBulkEdit({ objectName, ids, properties, objectType, touched }) {
            if (ids.length) {
                // const v = makeSetOperation({ ids, properties });
                const v = this.bulkEdit({ ids, properties, touched });
                return this.save(objectName, v.items, v.methodsByItem, v.filters, objectType);
            }
        }

        async handleAutoSchedule({
            start,
            periods,
            capacity,
            maxResourcesAssignedToJob,
            requiredCapacity,
            actionStatus
        }) {
            const selectedJobs = getSelectedItems(this.client, SCHEDULECACHE);
            const selectedResources = getSelectedItems(this.client, TOOLBARCACHE);
            if (!selectedJobs.length || !selectedResources.length) {
                return;
            }
            // const values = get('0.selectedItemProperties', selectedJobs);
            const range = getRange(start, periods, { interval: 'days' });
            const rangeStartEnd = getRangeStartEnd(start, periods, { interval: 'days' });
            const resourceIds = map((selectedResource) => selectedResource.item.Id, selectedResources);
            const jobIds = map((selectedJob) => selectedJob.item.Id, selectedJobs);
            const resources = await getResourcesToSchedule({
                client: this.client,
                resourceIds: resourceIds,
                start: rangeStartEnd.start,
                end: flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(rangeStartEnd.end),
                capacity: capacity
            });
            const jobs = await getJobsToSchedule({
                client: this.client,
                jobIds: jobIds,
                start: rangeStartEnd.start,
                end: flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(rangeStartEnd.end),
                requiredCapacity: requiredCapacity
            });
            const [scheduledDays] = createSchedule({ range, jobs, resources, maxResourcesAssignedToJob });
            for (const day in scheduledDays) {
                forEach((schedule) => {
                    const v = this.autoSchedule({ day: day, schedule: schedule, actionStatus: actionStatus });
                    this.save('CxAllocation', v.items, v.methodsByItem).catch((error) => console.log(error));
                }, scheduledDays[day]);
            }
        }

        handleResolveConflicts({ start, end, resetPartials }) {
            const v = this.resolveConflicts({
                client: this.client,
                start: start,
                end: end,
                resetPartials: resetPartials
            });
            this.save('CxCache', v.items, v.methodsByItem, v.filters).catch((error) => console.log(error));
        }
    };
