import React from 'react';
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { showAttachmentDrawer } from 'client-shared/components/attachments/attachments';

export const AttachmentButton = (props) => {
    const { pk, objectType } = props;
    const client = useApolloClient();

    const handleToggleAttachment = (id, object) => (event) => {
        showAttachmentDrawer(client, true, parseInt(id), object);
    };
    return (
        <Button
            variant="contained"
            size="small"
            style={{ marginRight: '10px' }}
            color="primary"
            startIcon={<AttachFileIcon />}
            onClick={(event) => handleToggleAttachment(pk, objectType)(event)}
            disabled={pk === 0}
        >
            Attachments
        </Button>
    );
};

AttachmentButton.propTypes = {
    pk: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    objectType: PropTypes.string.isRequired
};
