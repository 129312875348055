import React from 'react';
import Auth from '@aws-amplify/auth';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib-esm/types';
import LockIcon from '@mui/icons-material/LockOutlined';
import { makeStyles, useTheme } from '@mui/styles';
import { Paper, Button, Avatar } from '@mui/material';
import Image from 'images/controlboard_transparent.png';

const useStyles = makeStyles((theme) => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        marginTop: theme.spacing(3)
    },
    Chip: {
        marginTop: theme.spacing(2),
        align: 'right'
    }
}));

const Index = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <main className={classes.main}>
            <Paper className={classes.paper}>
                <img alt="" src={Image} style={{ width: '20vw', height: 'auto' }} />
                <Avatar className={classes.avatar}>
                    <LockIcon />
                </Avatar>
            </Paper>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                    Auth.federatedSignIn({
                        provider: CognitoHostedUIIdentityProvider.Cognito
                    });
                }}
            >
                Sign in
            </Button>
        </main>
    );
};

export default Index;
