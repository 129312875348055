import { gql } from 'graphql-tag';

export const AllocationsQuery = gql`
    query ($id: [String!] = [], $start: String!, $end: String!) {
        cxAllocations(
            objectType: "Allocation"
            filters: [{ name: "Id", values: $id }, { name: "Range", values: [$start, $end] }]
        ) {
            ObjectType
            Id
            Name
            DisplayName
            TotalHours
            TotalMergedHours
            Crew {
                Id
            }
            ResourceSpans {
                Range {
                    Start
                    End
                }
                Principal {
                    DisplayName
                    Id
                    ResourceType {
                        DisplayName
                        Name
                    }
                    ResourceSubtype {
                        Description
                    }
                }
            }
            ForeColor
            BackColor
            ActionStatus {
                Id
                BackColor
                Default
            }
            Description
            Job {
                Id
                DisplayName
                ForeColor
            }
            JobActivity {
                Id
                Name
                ForeColor
            }
            CurrentLifespan {
                Start
                End
            }
        }
    }
`;
