import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'client-shared/utility/context';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { SelectableItem } from 'client-shared/components/SelectableItem';
import { getFilter, getSelectedItems, DragDropBase, TOOLBARCACHE } from 'client-shared/utility';
import { useApolloClient } from '@apollo/client';
import { Hover } from 'client-shared/components/Hover';
import { withEditor } from 'client-shared/entities/withEditor';

const styles = {
    root: {
        flexGrow: 1,
        flexWrap: 'nowrap',
        textAlign: 'left',
        height: '300px',
        overflowY: 'auto'
    }
};

const Suppliers_ = ({ data: suppliers, onOpenEditor }) => {
    const client = useApolloClient();
    const dragDropBase = new DragDropBase();

    return (
        <div style={styles.root}>
            {suppliers.map((supplier, index) => {
                return (
                    <div
                        style={{ lineHeight: '1rem' }}
                        key={supplier.Id}
                        draggable={true}
                        onDragStart={(ev) => {
                            const items = getSelectedItems(client, TOOLBARCACHE, [supplier], null);
                            dragDropBase.handleDrag(items)(ev);
                        }}
                        onDoubleClick={() => {
                            onOpenEditor(`EditCx${supplier.ObjectType}`, {
                                formQueryValues: {
                                    filters: [{ name: 'Id', values: [supplier.Id] }]
                                }
                            });
                        }}
                    >
                        <SelectableItem item={supplier} cacheName={TOOLBARCACHE} selectedItemProperties={null} />
                        <Hover message={supplier.Description} />
                    </div>
                );
            })}
        </div>
    );
};

Suppliers_.propTypes = {
    data: PropTypes.array.isRequired,
    onOpenEditor: PropTypes.func.isRequired
};

const Suppliers = withEditor(Suppliers_);

export const SuppliersList = ({ filterFormDefinitionName }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.components', filterFormDefinitionName);
    if (!filterFormDefinition) return null;
    return (
        <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
            {({ values, data }) => {
                return <Suppliers data={data} />;
            }}
        </FilterFormFromJson>
    );
};

SuppliersList.propTypes = {
    filterFormDefinitionName: PropTypes.string.isRequired
};

// export const SuppliersList = withEditor(SuppliersList_);
