import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const options = {
    palette: {
        background: {
            code: '#B2B2B21A'
        }
    },
    customShadows: {
        widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
    },
    components: {
        // Name of the component
        MuiTextField: {
            defaultProps: {
                // The props to change the default for.
                variant: 'standard',
                style: { marginLeft: '2px', marginRight: '2px' }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 42,
                    minHeight: 42
                }
            }
        }
    },
    typography: {
        // fontSize: 7,
    }
};

const theme = createTheme(options);

fetch('/disco/boot')
    .then((res) => res.json())
    .then((_in) => {
        Auth.configure(_in.cognito);
        localStorage.setItem('APP_IN_TEST', _in.APP_IN_TEST);
        localStorage.setItem('APP_SETTINGS', JSON.stringify(_in));
        localStorage.setItem('Foo', '1111');
    })
    .then(() => {
        const container = document.getElementById('root');
        const root = createRoot(container);
        return root.render(
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider maxSnack={3}>
                        <App />
                    </SnackbarProvider>
                </ThemeProvider>
            </BrowserRouter>
        );
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.rregister();
// serviceWorkerRegistration.register();
