import React from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import QueryBuilder from '@mui/icons-material/QueryBuilder';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ChartIcon from '@mui/icons-material/BarChart';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HelpIcon from '@mui/icons-material/Help';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PublicIcon from '@mui/icons-material/Public';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

import { getOr } from 'lodash/fp';

const ICONS = {
    SettingsIcon,
    ScheduleIcon,
    PeopleIcon,
    DateRangeIcon,
    InsertEmoticonIcon,
    AssignmentIndIcon,
    AssignmentIcon,
    AssignmentTurnedInIcon,
    DesktopWindowsIcon,
    HighlightOffIcon,
    MapIcon,
    PermMediaIcon,
    QueryBuilder,
    HomeIcon,
    CalendarTodayIcon,
    ViewQuiltIcon,
    AttachMoneyIcon,
    ChartIcon,
    PhoneAndroidIcon,
    HelpIcon,
    NewReleasesIcon,
    PersonAddIcon,
    AddLocationIcon,
    GroupAddIcon,
    PublicIcon,
    LocalShippingIcon,
    EngineeringIcon,
    FolderSpecialIcon,
    FolderSharedIcon,
    BookmarksIcon
};

export const IconSet = (name) => {
    const Type = getOr(InsertEmoticonIcon, name, ICONS);
    return <Type />;
};
