import { useContext, useEffect, useState } from 'react';
import { differenceBy, get, keyBy, unionBy, map, /* pick, keys, flow,*/ values } from 'lodash/fp';
import { useApolloClient } from '@apollo/client';
import { deleteKkv, getKkv, putKkv } from './domainkkv';
import { UserContext } from './context';
import { getDay, isValid } from 'date-fns/fp';
import { parseDate } from 'client-shared/utility';

const DEFAULTCOLUMNWIDTH = 150;

const initializeColumnWidths = (columns = [], columnWidths = []) => {
    let x = map(
        (column) => ({
            columnName: isValid(new Date(column.name)) ? getDay(parseDate(column.name)) : column.name,
            width: DEFAULTCOLUMNWIDTH
        }),
        columns
    );
    x = keyBy('columnName', x);
    x = values(x);
    return unionBy('columnName', columnWidths, x);
};

/**
 * strip columns from columnWidths that are no longer being used.
 * @param columns - collection of devextreme grid columns.
 * @param columnWidths - includes columnName/width pairs, 7 of the columnNames are days of the week (0-6).
 * Example [{columnName: 'job', width: 490}, {columnName: 0, width: 100}, {columnName: 1, width: 250}, ...{columnName: 6, width: 250}]
 * @returns {*}
 */
// const stripColumnWidths = (columns, columnWidths) => {
//     columns = flow(
//         map((column) => ({
//             name: isValid(new Date(column.name)) ? getDay(parseDate(column.name)) : column.name,
//             width: column.width
//         })),
//         keyBy('name'),
//         keys
//     )(columns);
//     columnWidths = flow(keyBy('columnName'), pick(columns), values)(columnWidths);
//     return columnWidths;
// };

/**
 * transform persisted columns widths to the devExtreme format. This applies daily column widths (0-6) to date columns
 * from devextreme.
 * @param columns - collection of devextreme grid columns.
 * @param columnWidths - includes columnName/width pairs, 7 of the columnNames are days of the week (0-6).
 * Example [{columnName: 'job', width: 490}, {columnName: 0, width: 100}, {columnName: 1, width: 250}, ...{columnName: 6, width: 250}]
 * @returns {*}
 */
const transformColumnWidths = (columns, columnWidths) => {
    columnWidths = keyBy('columnName', columnWidths);
    const newColumnWidths = map((column) => {
        if (isValid(new Date(column.name))) {
            return {
                columnName: column.name,
                width: get(`${getDay(parseDate(column.name))}.width`, columnWidths) || DEFAULTCOLUMNWIDTH
            };
        }
        return {
            columnName: column.name,
            width: get([column.name, 'width'], columnWidths) || DEFAULTCOLUMNWIDTH
        };
    }, columns);
    return newColumnWidths;
};

/**
 * persist the modified column width, there will be only one from the newColumnWidths since the grid events on every
 * column width change.
 * @param newColumnWidths
 * @param columnWidths
 * @param setColumnWidth
 */
const handleColumnWidths = (columnWidths, setColumnWidth) => (newColumnWidths) => {
    const newDailyColumnWidths = map((newColumnWidth) => {
        return {
            columnName: isValid(new Date(newColumnWidth.columnName))
                ? getDay(parseDate(newColumnWidth.columnName))
                : newColumnWidth.columnName,
            width: newColumnWidth.width
        };
    }, newColumnWidths);
    // get the column that was modified.
    let modifiedColumnWidth = get(
        '0',
        differenceBy(
            (value) => {
                return `${value.columnName}${value.width}`;
            },
            newDailyColumnWidths,
            columnWidths
        )
    );
    if (!modifiedColumnWidth) {
        return;
    }
    // persist the column widths collection.
    setColumnWidth(modifiedColumnWidth);
};

/**
 * user defined hook to persist devextreme column widths.
 * @param componentName - config value name to store in kkv.
 * @param columns - collection of devextreme columns.
 * @param transformColumnWidths - optional function to transform the collection of persisted column widths to
 * a devextreme column width collection. this is used when the collection of persisted column widths does not correspond
 * to the devextreme columns collection. as an example, the schedule grid persists the days of the week widths,
 * transformColumnWidth converts the persisted column widths in days to the devextreme columns collection.
 * @returns {[*,handleColumnWidths]}
 */
export const useColumnWidths = (componentName, columns) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const [columnWidths, setColumnWidths] = useState([]);
    /*eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        getKkv(client, get('userId', user), componentName).then(({ kkvFetch }) => {
            const persistedValues = kkvFetch.length ? kkvFetch[0].data : undefined;
            // if (!persistedValues) {
            //     return;
            // }
            const x = initializeColumnWidths(columns, get('columnWidths', persistedValues));
            setColumnWidths(x);
        });
    }, [JSON.stringify(columns)]);

    const setColumnWidth = (columnWidth) => {
        const newColumnWidths = unionBy('columnName', [columnWidth], columnWidths);
        // set the state variable to return the devextreme column widths.
        setColumnWidths(newColumnWidths);
        try {
            putKkv(client, { k: get('userId', user), kk: componentName, data: { columnWidths: newColumnWidths } });
        } catch (error) {
            console.log(error);
        }
    };

    const deleteColumnWidths = async () => {
        if (componentName && user) {
            try {
                await deleteKkv(client, get('userId', user), componentName);
            } catch (error) {
                console.log(error);
            }
        }
    };

    return [
        transformColumnWidths(columns, columnWidths),
        handleColumnWidths(columnWidths, setColumnWidth),
        deleteColumnWidths
    ];
};
