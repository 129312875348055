import React from 'react';
import Button from '@mui/material/Button';
import { isEmpty } from 'lodash/fp';
import { Formik, Form } from 'formik';
import { useTheme } from '@mui/styles';
import { TagEditor, Field } from 'client-shared/components/form';
import { useStyles } from 'client-shared/components/form/components';

export const TagForm = ({ items, customProperties, fn, ...props }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const initialValues = { tags: items };
    return (
        <div>
            {!isEmpty(customProperties) && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={(_in, { setSubmitting }) => {
                        setSubmitting(false);
                        fn(_in.tags);
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <Form>
                            <Field
                                type="text"
                                name="tags"
                                component={TagEditor}
                                classes={classes}
                                customProperties={customProperties}
                            />
                            <Button color="primary" size="small" onClick={handleSubmit}>
                                Save Custom Properties
                            </Button>
                        </Form>
                    )}
                </Formik>
            )}
            {/* only render when there are custom props*/}
        </div>
    );
};
