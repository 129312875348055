import React, { useContext } from 'react';
import { instantiateBuild, UserContext } from 'client-shared/utility';
import { FilterFormFromJson } from 'client-shared/components/filterbar';

function build(props) {
    const {
        gql,
        format,
        get,
        object,
        number,
        date,
        user,
        getRangeStartEnd,
        flow,
        parseDate,
        getReportOptions,
        getReportFragment,
        addDays,
        createFilters
    } = props;
    const validationSchema = object({
        _start: date().required('Required'),
        _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(100, 'Must be less than 100 days')
    });

    const dataQuery = (cxReportFragment) => gql`
        query ($filters: [FilterInput], $methodsByItem: [methodsByItemInput]) {
            cxForecasts(filters: $filters, methodsByItem: $methodsByItem) {
                Id
                DisplayName
                Report {
                    ${cxReportFragment}
                }
                Job {
                    JobStatus {
                        DisplayName
                    }
                    Manager {
                        DisplayName
                    }
                }
                ReportIntervals {
                    Report {
                        ${cxReportFragment}
                    }
                    Range {
                        Start
                        End
                    }
                    __typename
                }
            }
        }
    `;

    return {
        title: 'Forecast Grid',
        dataQuery: (values) => dataQuery(getReportFragment(values._reportProperties)),
        // dataEventQueries: [fragments.JobEventQuery],
        sourceMutationObjectTypes: [],
        validationSchema: validationSchema,
        divisionFilter: true,
        enableShowAll: false,
        filterProperties: ['DisplayName'],
        filterOnServer: false,
        namedFilters: true,
        initialValues: {
            range: [],
            range__child__taskdays: [],
            _absoluteStart: true
        },
        onFilter: (values) => {
            values = {
                ...values,
                job_contacts_id__all__true: values._contacts_id,
                divisions_id__child__taskdays: values.divisions_id,
                resources_contacts_id__child__taskdays__all__true: values._contacts_id,
                divisions_id__child__resources: values.divisions_id,
                contacts_id__child__resources__all__true: values._contacts_id,
                forecast_id: values.id,
                contacts_id__all__true: values._contacts_id
            };
            return createFilters(values);
        },
        onMethodsByItem: async (values, client) => {
            const rangeStartEnd = getRangeStartEnd(values._start, values._periods, {
                interval: values._interval,
                periodMultiple: values._stratify ? 7 : undefined,
                absoluteStart: values._absoluteStart
            });
            const methodsByItem = [
                {
                    methods: [
                        {
                            name: 'MakeReportIntervals',
                            args: {
                                interval: values._interval,
                                start: rangeStartEnd.start,
                                end: flow(addDays(1), format('yyyy-MM-dd'))(parseDate(rangeStartEnd.end)),
                                shiftId: get('_shift.Id', values)
                            }
                        }
                    ]
                }
            ];
            return { methodsByItem: methodsByItem };
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Interval',
                name: '_interval',
                component: 'AutoComplete',
                options: ['Days', 'Weeks', 'Months'],
                className: 'shortSelectField',
                initialValue: 'Days',
                optionLabelProperty: 'Name'
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'number',
                label: 'Periods',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'text',
                label: 'Forecast Name',
                name: 'id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxForecasts(filters: [{ name: "String", values: ["Name", $searchString] }]) {
                            Id
                            DisplayName
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Job Name',
                name: 'string__job_displayName',
                component: 'TextField',
                className: 'textField'
            },
            {
                type: 'text',
                label: 'Status',
                name: 'job_jobStatus_id',
                component: 'LookupValueEditor',
                className: 'selectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobStatus'
            },
            {
                type: 'text',
                label: 'Shift',
                name: '_shift',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: '_DisplayName',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxShifts(
                            objectType: "Shift"
                            filters: [{ name: "String", values: ["DisplayName", $searchString] }]
                        ) {
                            Id
                            _DisplayName: DisplayName
                            _Days: Days {
                                Day
                            }
                        }
                    }
                `
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Orientation',
                name: '_orientation',
                component: 'AutoComplete',
                options: ['row', 'column'],
                className: 'selectField',
                initialValue: 'row'
            },
            {
                type: 'checkbox',
                Label: { label: 'Stratify' },
                name: '_stratify',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'text',
                label: 'Metrics',
                name: '_reportProperties',
                component: 'AutoComplete',
                options: getReportOptions(user),
                className: 'longTextField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id',
                multiple: true,
                sort: false,
                initialValue: ['ForecastedLaborDuration', 'ScheduledLaborDuration']
            },
            {
                type: 'text',
                label: 'Interval Metrics',
                name: '_reportIntervalProperties',
                component: 'AutoComplete',
                options: getReportOptions(user),
                className: 'longTextField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id',
                multiple: true,
                sort: false,
                initialValue: [
                    'LaborCount',
                    'LaborCapacity',
                    'ForecastedLaborDuration',
                    'ScheduledLaborDuration',
                    'LaborCapacityMinusForecastedLaborDuration'
                ]
            },
            {
                type: 'checkbox',
                Label: { label: 'Export Labels' },
                name: '_label',
                component: 'CheckboxWithLabel',
                initialValue: true
            },
            {
                type: 'text',
                label: 'Contacts',
                name: '_contacts_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxCaches(
                            objectType: "cache"
                            filters: [{ name: "String", values: ["Contacts.DisplayName", $searchString] }]
                        ) {
                            Contacts {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => {
                    return get('0.Contacts', options);
                },
                optionLabelProperty: 'DisplayName'
            }
        ]
    };
}

export const FilterForecasts = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
