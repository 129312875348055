import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { getRangeStartEnd, instantiateBuild } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import { gql } from 'graphql-tag';

function build(props) {
    const { format, object, number, date, get, user } = props;

    const validationSchema = object({
        _start: date().required('Required'),
        _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(101, 'Must be less than 100 days')
    });

    const dataQuery = `
        query ($filters: [FilterInput]) {
            cxResources(objectType: "Resource", filters: $filters) {
                Id
                Description
                Allocations {
                    Job {
                        Id
                    }
                }
                ResourceType {
                    Id
                    DisplayName
                    Name
                    ObjectType
                    Allocatable
                    ForeColor
                    Family
                    ResourceSubtypes {
                        Id
                        Name
                        DisplayName
                        ObjectType
                    }
                }
                ForeColor
                BackColor
                DisplayName
                ObjectType
                Divisions {
                    Id
                    Name
                }
                Auxiliaries {
                    Id
                }
            }
        }
    `;

    return {
        title: 'Resource Timeline',
        dataQuery: dataQuery,
        divisionFilter: true,
        validationSchema: validationSchema,
        //sourceMutationObjectTypes: ['Employee', 'Equipment', 'Material'],
        filterProperties: ['ResourceType.Name', 'DisplayName'],
        namedFilters: true,
        initialValues: {
            'resourcetype.allocatable': true,
            range: []
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Family',
                name: 'resourceType_family',
                component: 'AutoComplete',
                options: [
                    { Id: 'Labor', Name: 'Labor' },
                    { Id: 'Equipment', Name: 'Equipment' },
                    { Id: 'Material', Name: 'Material' }
                ],
                className: 'selectField',
                initialValue: 'Labor',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            },
            {
                type: 'text',
                label: 'Type',
                name: 'resourceType_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'Name',
                queryVariables: (values) => ({
                    family: [get('resourceType_family', values)]
                }),
                optionsQuery: `
                    query ($searchString: String, $family: [String] = []) {
                        cxResourceTypes(
                            objectType: "ResourceType"
                            filters: [
                                { name: "String", values: ["Name", $searchString] }
                                { name: "family", values: $family }
                            ]
                        ) {
                            Id
                            Name
                        }
                    }
                `,
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Contacts',
                name: 'contacts_id__all__true',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxCaches(
                            objectType: "cache"
                            filters: [{ name: "String", values: ["Contacts.DisplayName", $searchString] }]
                        ) {
                            Contacts {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => {
                    return get('0.Contacts', options);
                },
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Job',
                name: 'Allocations_Job_Id',
                component: 'AutoComplete',
                className: 'selectField',
                queryVariables: (values) => {
                    const rangeStartEnd = getRangeStartEnd(values._start, values._periods, { interval: 'Days' });
                    return {
                        start: rangeStartEnd.start,
                        end: rangeStartEnd.end
                    };
                },
                optionsQuery: gql`
                    query ($searchString: String, $start: String, $end: String) {
                        cxJobs(
                            filters: [
                                { name: "String", values: ["DisplayName", $searchString] }
                                { name: "range", values: [$start, $end] }
                            ]
                        ) {
                            Id
                            DisplayName
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                name: 'scheduled',
                component: 'ScheduledEditor',
                initialValue: 'All'
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'text',
                label: 'Interval',
                name: '_interval',
                component: 'AutoComplete',
                options: ['Days', 'Weeks', 'Months'],
                className: 'shortSelectField',
                initialValue: 'Days',
                optionLabelProperty: 'Name'
            },
            {
                type: 'number',
                label: 'Periods',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'number',
                label: 'Timeline Spacing',
                name: '_dy',
                component: 'AutoComplete',
                initialValue: 36,
                options: [
                    { Id: 46, Name: 'Big' },
                    { Id: 36, Name: 'Comfortable' },
                    { Id: 26, Name: 'Tight' }
                ],
                className: 'shortSelectField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            },
            {
                type: 'number',
                label: 'Column Width (scrolled)',
                name: '_column_width_',
                component: 'TextField',
                className: 'textField',
                initialValue: 200
            },
            {
                type: 'checkbox',
                Label: { label: 'Scroll Timeline' },
                name: '_scrollable',
                component: 'CheckboxWithLabel',
                initialValue: false
            }
        ]
    };
}

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
