import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "Material", $filters: [FilterInput]) {
        cxMaterials(objectType: $objectType, filters: $filters) {
            Id
            AccountCode
            ForeColor
            BackColor
            ResourceSubtype {
                Id
                _Name: Name
            }
            _ResourceType: ResourceType {
                Unit {
                    Name
                }
            }
            CurrentLifespan {
                Start
                End
            }
            Reusable
            Owner {
                Id
            }
            ForeColor
            BackColor
            Description
            Inventory {
                Items {
                    Id
                    Amount
                    Start
                    Vendor {
                        Id
                        _DisplayName: DisplayName
                    }
                    AccountCode
                    Description
                }
            }
            Divisions {
                Id
                _DisplayName: DisplayName
            }
        }
    }
`;
