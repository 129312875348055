import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { FormContainer } from '../form';
import { withFilterFormFromJson } from './withFilterFormFromJson';

const AccordionFilter = ({ title, expanded, onExpanded, children }) => {
    const handleChange = (event, isExpanded) => {
        onExpanded(isExpanded);
    };

    return (
        <div className="no-print">
            <div
                style={{
                    width: '100%',
                    paddingBottom: 1
                }}
            >
                <Accordion expanded={expanded} onChange={handleChange} disableGutters={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ minHeight: 30, maxHeight: 30 }}>
                        <div
                            style={{
                                flexBasis: '100%',
                                textAlign: 'center'
                            }}
                        >
                            <Typography sx={{ fontSize: (theme) => theme.typography.pxToRem(18) }}>{title}</Typography>
                        </div>
                    </AccordionSummary>
                    {children}
                </Accordion>
            </div>
        </div>
    );
};

AccordionFilter.propTypes = {
    title: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
    onExpanded: PropTypes.func.isRequired
};

const AccordionFilterBody = ({ title, enableShowAll = true, expanded, onApply, onReset, onShowAll, children }) => {
    const ref = useRef(null);
    const keyDown = (event) => {
        if (event.key === 'Enter') {
            onApply(event);
        }
    };

    /*eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        // Create event listener that calls handler function stored in ref
        const element = ref.current;
        if (expanded) {
            element.addEventListener('keydown', keyDown /*, { once: true }*/);
        }

        // Remove event listener on cleanup
        return () => {
            element.removeEventListener('keydown', keyDown);
        };
    }, []);

    return (
        <div ref={ref}>
            <AccordionDetails>{children}</AccordionDetails>
            <Divider />
            <AccordionActions disableSpacing sx={{ justifyContent: 'flex-start' }}>
                {enableShowAll && (
                    <Button size="small" onClick={onShowAll}>
                        Show All
                    </Button>
                )}
                <Button size="small" onClick={onReset}>
                    Reset
                </Button>
                <Button size="small" color="primary" onClick={onApply}>
                    Apply
                </Button>
            </AccordionActions>
        </div>
    );
};

AccordionFilterBody.propTypes = {
    title: PropTypes.string.isRequired,
    enableShowAll: PropTypes.bool,
    expanded: PropTypes.bool,
    onApply: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onShowAll: PropTypes.func.isRequired
};

const AccordionForm = ({
    title,
    enableShowAll,
    validationSchema,
    Form,
    values,
    initialValues,
    _showAll,
    onSubmit,
    onShowAll
}) => {
    const [expanded, setExpanded] = useState(false);
    //const [, , deleteColumnWidth] = useColumnWidths(`${title}_columnwidths`, []);
    const WrappedForm = (props) => {
        const handleApply = () => {
            props
                .submitForm()
                .catch((error) => console.log('FilterForm: FormWithExpansion: props.submitform()', error));
        };
        const handleReset = () => {
            props.setValues(initialValues);
            // deleteColumnWidth();
        };

        return (
            <AccordionFilterBody
                title={title}
                enableShowAll={enableShowAll}
                expanded={expanded}
                onApply={handleApply}
                onReset={handleReset}
                onShowAll={() => {
                    onShowAll();
                    setExpanded(false);
                }}
            >
                <Form {...props} />
            </AccordionFilterBody>
        );
    };
    return (
        <AccordionFilter onExpanded={setExpanded} title={title} expanded={expanded}>
            <FormContainer
                Form={WrappedForm}
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={(values, formikBag, _touched) => {
                    onSubmit(values);
                    setExpanded(false);
                }}
                className="no-print"
                //style={styles.root}
            />
        </AccordionFilter>
    );
};

AccordionForm.propTypes = {
    enableShowAll: PropTypes.bool,
    validationSchema: PropTypes.object,
    Form: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    _showAll: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onShowAll: PropTypes.func.isRequired
};

export const FilterFormFromJson = ({ filterFormDefinition, style, children }) => {
    const FormFilter = withFilterFormFromJson(AccordionForm);
    return (
        <FormFilter filterFormDefinition={filterFormDefinition} style={style}>
            {children}
        </FormFilter>
    );
};

FilterFormFromJson.propTypes = {
    filterFormDefinition: PropTypes.shape({
        fieldDefinitions: PropTypes.array.isRequired,
        queryName: PropTypes.string,
        title: PropTypes.string.isRequired,
        validationSchema: PropTypes.object,
        initialValues: PropTypes.object,
        firstTimeValues: PropTypes.object,
        dataQuery: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
        dataEventQueries: PropTypes.array,
        sortProperties: PropTypes.array,
        sourceMutationObjectTypes: PropTypes.array,
        filterProperties: PropTypes.array,
        filterOnServer: PropTypes.bool,
        persistFilter: PropTypes.bool,
        propertiesNotPersisted: PropTypes.array,
        divisionFilter: PropTypes.bool,
        enableShowAll: PropTypes.bool,
        namedFilters: PropTypes.bool,
        onFilter: PropTypes.func,
        onData: PropTypes.func,
        onMethodsByItem: PropTypes.func,
        onFormatters: PropTypes.func
    }).isRequired,
    style: PropTypes.object
};
