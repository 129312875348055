import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useApolloClient } from '@apollo/client';
import { ObjectsMapped } from 'client-shared/components/mapping';
import { Filter } from './Filter';
import { inlineFetchQuery } from 'client-shared/gqlhelpers/queryHelpers';
import { cleanObject, plusDays } from 'client-shared/utility';
import { query, objectType } from 'cb-schema/minimalinputfragment/CxAllocation';
import { get, groupBy, toPairs, map, pipe, keyBy, fromPairs, values } from 'lodash/fp';
import { groupV, zipObjects } from 'global-shared/utils/utils';

const useStyles = makeStyles((theme) => ({
    root: { width: '100%', height: '80vh' }
}));

export const InnerFilter = ({ variables, jobs }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const client = useApolloClient();
    const [allocations, setAllocations] = useState([]);

    const fetchData = async () => {
        const result = await client.query({
            query: inlineFetchQuery(
                query,
                objectType,
                `DisplayName
                        Id,
                        Job {
                         Id
                        }
                        ActionType`
            ),
            fetchPolicy: 'network-only',
            variables: {
                filters: [{ name: 'Range', values: [variables._start, plusDays(variables._periods, variables._start)] }]
            }
        });
        setAllocations(get(['data', 'cxAllocations'], result));
    };

    useEffect(() => {
        fetchData();
    });

    const kkv = pipe(map(cleanObject), groupBy('Job.Id'), toPairs, map(groupV('ActionType')), fromPairs)(allocations);

    const realData = zipObjects('allocations', keyBy('Id', jobs), kkv);
    return (
        <div className={classes.root}>
            <ObjectsMapped data={values(realData)} />
        </div>
    );
};

export const JobsMap = () => (
    <Filter>
        {({ values: variables, data: jobs }) => {
            console.log(variables);
            return <InnerFilter {...{ variables, jobs }} />;
        }}
    </Filter>
);
