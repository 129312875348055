import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { TextField } from 'formik-mui';
import {
    Field,
    EmailEditor,
    emailValidationSchema,
    PhoneEditor,
    phoneValidationSchema,
    AutoComplete
} from 'client-shared/components/form';
import model from 'cb-schema/emptymodel/CxParty';
import { array, object, string } from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import { Section } from 'client-shared/components/Section';
import { gql } from 'graphql-tag';

const CxPartysQuery = gql`
    query CxPartysQuery($searchString: String) {
        cxPartys(
            objectType: "Party"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "ObjectType", values: ["Party"] }
            ]
        ) {
            Id
            Name
            Title
            PhoneNumbers {
                Id
                Name
                PhoneNumber
            }
            Emails {
                Id
                Name
                Email
            }
        }
    }
`;
const contact = cloneDeep(model);
contact.ObjectType = 'Party';
delete contact.CurrentLifespan;
delete contact.Address;

export const contactValidationSchema = array().of(
    object({
        Name: string().min(2, 'Too short').max(65, 'Too long').required('Required'),
        Emails: emailValidationSchema,
        PhoneNumbers: phoneValidationSchema
        // Address: addressValidationSchema
    })
);

export const ContactEditor = ({ form, field, classes, type, children, ...other }) => {
    return (
        <Section title="Contacts">
            <FieldArray
                name={field.name}
                render={(arrayHelpers) => {
                    return (
                        <React.Fragment>
                            {field.value.map((contact, index) => (
                                <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                    <Field
                                        type="text"
                                        label="Name"
                                        name={`${field.name}.${index}.Name`}
                                        component={AutoComplete}
                                        className={classes.selectField}
                                        optionsQuery={CxPartysQuery}
                                        optionLabelProperty="Name"
                                        // getValue={(event, value) => {
                                        //     return value ? value.Name : value;
                                        // }}
                                        freeSolo={true}
                                        onChange={(event, value) => {
                                            if (!value) {
                                                return;
                                            }
                                            // value can be an object of the selected party (e.g. name, phonenumbers, emails, other)
                                            // or a string in the case a new contact is being created none was select from the
                                            // existing parties.
                                            form.setFieldValue(`${field.name}.${index}`, {
                                                Id: value.Id || 0,
                                                Name: value.Name || value,
                                                Title: value.Title || '',
                                                PhoneNumbers: value.PhoneNumbers || [],
                                                Emails: value.Emails || []
                                            });
                                        }}
                                        initialize={false}
                                    />
                                    <Field
                                        type="text"
                                        label="Title"
                                        name={`${field.name}.${index}.Title`}
                                        component={TextField}
                                        className={classes.shortTextField}
                                    />
                                    <Field
                                        type="text"
                                        label="Phone Numbers"
                                        name={`${field.name}.${index}.PhoneNumbers`}
                                        component={PhoneEditor}
                                        classes={classes}
                                    />
                                    <Field
                                        name={`${field.name}.${index}.Emails`}
                                        type="text"
                                        label="Emails"
                                        component={EmailEditor}
                                        classes={classes}
                                    />
                                    {/*<Field*/}
                                    {/*    type="text"*/}
                                    {/*    label="Address"*/}
                                    {/*    name={`${field.name}.${index}.Address`}*/}
                                    {/*    component={AddressEditor}*/}
                                    {/*    classes={classes}*/}
                                    {/*    states={states}*/}
                                    {/*/>*/}
                                    <div>
                                        <button type="button" onClick={() => arrayHelpers.remove(index)}>
                                            -
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={() => {
                                    arrayHelpers.push(contact);
                                }}
                            >
                                +
                            </button>
                        </React.Fragment>
                    );
                }}
            />
        </Section>
    );
};

ContactEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};
