import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'client-shared/utility/context';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { Accordion } from 'client-shared/components/Accordion';
import Typography from '@mui/material/Typography';
import { DragDropBase, getFilter } from 'client-shared/utility';
import { get, map, sortBy } from 'lodash/fp';
import { Hover } from 'client-shared/components/Hover';

// export const handleDrag = (draggedItems) => (ev) => {
//     ev.dataTransfer.setData('cell', JSON.stringify(draggedItems));
// };

const styles = {
    root: {
        flexGrow: 1,
        flexWrap: 'nowrap',
        textAlign: 'left',
        height: '300px',
        overflowY: 'auto'
    }
};

const ResourceTypes = ({ data: cxResourceTypes }) => {
    const dragDropBase = new DragDropBase();
    const resourceTypes = sortBy(['Name'], cxResourceTypes);
    return (
        <div style={styles.root}>
            {map((resourceType) => {
                return (
                    <Accordion
                        key={resourceType.Id}
                        items={
                            <div>
                                <Typography
                                    component="span"
                                    fontSize="small"
                                    sx={{
                                        margin: 0,
                                        padding: 0,
                                        color: get('ForeColor', resourceType) || '#000000',
                                        backgroundColor: get('BackColor', resourceType) || '#FFFFFF',
                                        wordBreak: 'break-all',
                                        cursor: 'pointer'
                                    }}
                                    draggable={true}
                                    onDragStart={(ev) => {
                                        dragDropBase.handleDrag([
                                            {
                                                selectedItemProperties: null,
                                                item: {
                                                    Id: resourceType.Id,
                                                    ObjectType: resourceType.ObjectType,
                                                    ResourceSubtype: { Id: resourceType.ResourceSubtypes[0].Id }
                                                },
                                                cacheName: null,
                                                selected: false
                                            }
                                        ])(ev);
                                    }}
                                >
                                    {resourceType.Name}
                                </Typography>
                                <Hover message={resourceType.Description} />
                            </div>
                        }
                    >
                        {map((resourceSubtype) => {
                            return (
                                <div key={resourceSubtype.Id}>
                                    <Typography
                                        component="span"
                                        fontSize="small"
                                        sx={{
                                            margin: 0,
                                            paddingLeft: '8px',
                                            color: get('ForeColor', resourceType) || '#000000',
                                            backgroundColor: get('BackColor', resourceType) || '#FFFFFF',
                                            wordBreak: 'break-all',
                                            cursor: 'pointer'
                                        }}
                                        draggable={true}
                                        onDragStart={(ev) => {
                                            dragDropBase.handleDrag([
                                                {
                                                    selectedItemProperties: null,
                                                    item: {
                                                        Id: resourceType.Id,
                                                        ObjectType: resourceType.ObjectType,
                                                        ResourceSubtype: { Id: resourceSubtype.Id }
                                                    },
                                                    cacheName: null,
                                                    selected: false
                                                }
                                            ])(ev);
                                        }}
                                    >
                                        {resourceSubtype.Name}
                                    </Typography>
                                    <Hover message={resourceSubtype.Description} />
                                </div>
                            );
                        }, resourceType.ResourceSubtypes)}
                    </Accordion>
                );
            }, resourceTypes)}
        </div>
    );
};

ResourceTypes.propTypes = {
    data: PropTypes.array.isRequired
};

export const ResourceTypeList = () => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.components', 'resourcetypelist.js');
    if (!filterFormDefinition) return null;
    return (
        <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
            {({ values, data }) => {
                return <ResourceTypes data={data} />;
            }}
        </FilterFormFromJson>
    );
};
