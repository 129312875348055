import { EditCxActionStatus } from './EditCxActionStatus';
import { EditCxAllocation } from './EditCxAllocation';
import { EditCxCompany } from './EditCxCompany';
import { EditCxCostCode } from './EditCxCostCode';
import { EditCxEmployee } from './EditCxEmployee';
import { EditCxEquipment } from './EditCxEquipment';
import { EditCxHoliday } from './EditCxHoliday';
import { EditCxJob } from './EditCxJob';
import { EditCxJobStatus } from './EditCxJobStatus';
import { BulkEditCxJob } from './EditCxJob/BulkEditCxJob';
import { EditCxLookupValue } from './EditCxLookupValue';
import { EditCxMaterial } from './EditCxMaterial';
import { EditCxCrew } from './EditCxCrew';
import { EditCxResourceType } from './EditCxResourceType';
import { EditCxSubcontractor } from './EditCxSubcontractor';
import { EditCxPlant } from './EditCxPlant';
import { EditCxActualTaskDay } from './EditCxActualTaskDay';
import { EditCxVirtualTaskDay } from './EditCxVirtualTaskDay';
import { EditCxUser } from './EditCxUser';
import { EditCxBatch } from './EditCxBatch';
import { EditCxTemplateForm } from './EditCxTemplateForm';
import { EditCxShift } from './EditCxShift';
import { EditCxToDo } from './EditCxToDo';
import { EditCxRoute } from './EditCxRoute';
import { EditCxCustomProperty } from './EditCxCustomProperty';
import { EditCxTimeEntry } from './EditCxTimeEntry';
import { BulkEditCxTimeEntry } from './EditCxTimeEntry/BulkEditCxTimeEntry';
import { EditCxConfigValue } from './EditCxConfigValue';
import { EditCxTimeEntryType } from './EditCxTimeEntryType';
import { EditCxForecast /*EditCxForecastFlatForm*/ } from './EditCxForecast';
import { EditCxForm } from 'client-shared/components/attachableforms/formeditor';
import { EditCxTimeEntryMobile } from './EditCxTimeEntryMobile';
import { EditCxTimeEntryMobilePunch } from './EditCxTimeEntryMobilePunch';
import { EditCxPartyGroup } from './EditCxPartyGroup';
import { EditCxDocumentType } from './EditCxDocumentType';
import { EditCxTemplateJobActivity } from './EditCxTemplateJobActivity';
import { get } from 'lodash/fp';

// db server object type to component cross reference.
// this is used in the grid list forms to lookup the editor by the objectType enabling editing
// for heterogeneous items in the list. Where each name below is 'EditCx'[db server object type]
export const editors = {
    EditCxActionStatus,
    EditCxActualTaskDay,
    EditCxAllocation,
    EditCxCompany,
    EditCxCostCode,
    EditCxCrew,
    EditCxLookupValue,
    EditCxEmployee,
    EditCxEquipment,
    EditCxTemplateForm,
    EditCxHoliday,
    EditCxJob,
    EditCxJobStatus,
    BulkEditCxJob,
    EditCxMaterial,
    EditCxResourceType,
    EditCxShift,
    EditCxSubcontractor,
    EditCxPlant,
    EditCxUser,
    EditCxVirtualTaskDay,
    EditCxBatch,
    EditCxToDo,
    EditCxRoute,
    EditCxCustomProperty,
    EditCxTimeEntry,
    BulkEditCxTimeEntry,
    EditCxConfigValue,
    EditCxTimeEntryType,
    EditCxForecast,
    /*EditCxForecastFlatForm,*/
    EditCxForm,
    EditCxTimeEntryMobile,
    EditCxTimeEntryMobilePunch,
    EditCxPartyGroup,
    EditCxDocumentType,
    EditCxTemplateJobActivity
};

// export const withEditor = (Component) => (props) => {
//     const [editor, setOpenEditor] = useState({ editor: undefined, props: {} });
//     const handleOpenEditor = (editor, props) => {
//         setOpenEditor({ editor: editor, props: props });
//     };
//     const handleCloseEditor = () => setOpenEditor({ editor: undefined, props: { open: false } });
//     return (
//         <div>
//             <Editor open={!!editor.editor} form={editor.editor} onClose={handleCloseEditor} {...editor.props} />
//             <Component handleOpenEditor={handleOpenEditor} {...props} />;
//         </div>
//     );
// };

export const getEditor = (form) => get(form, editors);

export const Editor = ({ form, ...other }) => {
    const editor = getEditor(form);
    if (!editor) {
        return null;
    }
    return editor({ ...other });
};
