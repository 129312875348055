import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($filters: [FilterInput]) {
        cxShifts(filters: $filters) {
            Id
            Name
            Description
            Default
            UseHolidays
            Days {
                Day
                _Name: DayByName
                Start
                Minutes
            }
        }
    }
`;
