import { get, keyBy, set } from 'lodash/fp';
import { gql } from 'graphql-tag';
import { cbDateToDhtmlx } from 'global-shared/utils/utils';

export const mapStatus = (_in) => {
    const statusMap = {
        Approved: 'approved',
        Scheduled: 'scheduled',
        Submitted: 'submitted',
        InProgress: 'in progress',
        NotStarted: '-'
    };
    return set('Status', statusMap[_in.Status], _in);
};

export const gridQuery = gql`
    query ($filters: [FilterInput]) {
        cxJobs(filters: $filters) {
            Name
            DisplayName
            ForeColor
            CurrentLifespan {
                Start
                End
            }
            Id
            TimeSheets {
                ScheduledEntries {
                    Id
                }
                Crews {
                    Id
                    DisplayName
                    Foreman {
                        Id
                    }
                    Manager {
                        Id
                    }
                }
                Status
                Entries {
                    Id
                    ApprovedByOwner
                    Approvals {
                        Approver {
                            Id
                        }
                        AsOf
                    }
                }
                CurrentLifespan {
                    Start
                    End
                }
            }
            TaskDays {
                Id
                Crew {
                    Id
                    DisplayName
                    Foreman {
                        Id
                    }
                    Manager {
                        Id
                    }
                }
                CurrentLifespan {
                    Start
                    End
                }
            }
        }
    }
`;

export const keyByLifeSpan = (_in) => keyBy((a) => cbDateToDhtmlx(get('CurrentLifespan.Start', a)), _in);
