import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { query, UserContext, usePrivilege } from 'client-shared/utility';
import { FormContainer } from 'client-shared/components/form';
import { format, addDays, subDays } from 'date-fns/fp';
import { flow, cloneDeep, lowerFirst, get } from 'lodash/fp';
import { useApolloClient } from '@apollo/client';

export const EditForm = ({
    Form,
    validationSchema,
    initialValues,
    formQuery,
    formQueryValues,
    objectName, // eg. CxCompany
    queryName, // if not supplied, use objectName
    cache,
    onSubmit,
    onData = (data) => data
}) => {
    const client = useApolloClient();
    const [userState, setUserState] = useContext(UserContext);
    const [privilege] = usePrivilege(objectName.slice(2));
    const [formData, setFormData] = useState({
        data: { loading: true },
        initialValues: initialValues
    });
    const values = {
        ...formQueryValues,
        start: formQueryValues.start
            ? formQueryValues.start
            : flow(subDays(30), format("yyyy-MM-dd'T'00:00:00"))(new Date()), // optional form query values
        end: formQueryValues.end ? formQueryValues.end : flow(addDays(300), format("yyyy-MM-dd'T'00:00:00"))(new Date()) // optional form query values
    };
    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        (async () => {
            // get the form values from the local cache if true.
            const { data } = await query(client, formQuery, values, cache, ['cache-only', 'cache-only']);
            queryName = queryName || `${lowerFirst(objectName)}s`;
            const mergedInitialValues = {
                ...initialValues,
                ...get(`${queryName}.0`, data),
                // merge the objectType from the formQueryValues, if any. handles special case of
                // cxLookupValues where the objectType is user selectable on the same graphql object.
                ObjectType: formQueryValues.objectType
            };
            setFormData({ data: data, initialValues: await onData(cloneDeep(mergedInitialValues), data) });
        })();
    }, [formQueryValues]);
    if (formData.data.loading) return 'Loading...';

    return (
        <UserContext.Provider value={[userState, setUserState, privilege]}>
            <FormContainer
                Form={Form}
                initialValues={formData.initialValues}
                validationSchema={validationSchema}
                data={formData.data}
                onSubmit={onSubmit}
            />
        </UserContext.Provider>
    );
};

EditForm.propTypes = {
    Form: PropTypes.func.isRequired,
    validationSchema: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    formQuery: PropTypes.object,
    formQueryValues: PropTypes.object,
    objectName: PropTypes.string.isRequired,
    queryName: PropTypes.string,
    cache: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onData: PropTypes.func
};
