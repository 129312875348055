import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { object, date, ref } from 'yup';
import { DateEditor } from './DateEditor';

export const currentLifespanValidationSchema = object({
    Start: date().required('Required'),
    End: date().test('match', 'End date must be greater than start date', function (End) {
        return End > this.resolve(ref('Start'));
    })
});

export const CurrentLifespanEditor = ({
    form,
    field,
    classes,
    component = 'div',
    children,
    onChange,
    style,
    ...other
}) => {
    return (
        <span style={style}>
            <Field
                type="date"
                label="Start"
                name={`${field.name}.Start`}
                component={DateEditor}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true
                }}
                onChange={onChange}
            />
            <Field
                type="date"
                label="End"
                name={`${field.name}.End`}
                component={DateEditor}
                className={classes.dateTimeField}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </span>
    );
};

CurrentLifespanEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    component: PropTypes.string,
    onChange: PropTypes.func
};
