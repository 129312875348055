/**
 * Created by mark on 1/3/2019.
 */
import React, { useContext, useState /*useEffect, useRef */ } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'client-shared/utility/context';
import { gql } from '@apollo/client';
import { DateEditor, Field, FileEditor, LookupValueEditor } from 'client-shared/components/form';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxTimeEntry';
import inputModel from 'cb-schema/inputmodelfull/CxTimeEntry';
import { ErrorMessage } from 'formik';
import {
    DurationEditor,
    durationValidationSchema,
    currentLifespanTimeValidationSchema,
    AutoComplete
} from 'client-shared/components/form';
import { object, number, bool } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { format, addDays } from 'date-fns/fp';
import { get, flow, find, isEmpty, filter } from 'lodash/fp';
import {
    Mutation,
    parseDate,
    approvedByOther,
    CxTimeEntryFragment,
    getTimeEntryMethods,
    createFilters,
    offlineLinkOpen,
    getConfigValue
} from 'client-shared/utility';
import { useApolloClient } from '@apollo/client';
import { v4 } from 'uuid';
import isOnline from 'is-online';
import { SubtypeQuantitiesEditor, subtypeQuantitiesValidationSchema } from '../EditCxTimeEntry/SubtypeQuantitiesEditor';
/*import SignatureCanvas from 'react-signature-canvas';
import { Typography } from '@mui/material';*/

const PUNCHTIME = '2000-01-01T00:00:00';

/*const useStyles = makeStyles((theme) => ({
    sigPad: {
        border: '1px solid',
        width: '100%',
        height: '100px'
    }
}));*/

const dataQuery = gql`
    ${CxTimeEntryFragment}
    query ($filters: [FilterInput]) {
        scheduledTimeEntrys(filters: $filters) {
            ...CxTimeEntryFragment
        }
    }
`;

const validationSchema = object({
    _Job: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    JobActivity: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    // JobCosts: array()
    //     .min(1, 'At Least One Cost Code Is Required')
    //     .of(
    //         object({
    //             Id: number().moreThan(0, 'Required')
    //         })
    //     )
    //     .nullable()
    //     .required('Required'),
    CurrentLifespan: currentLifespanTimeValidationSchema,
    Minutes: durationValidationSchema,
    _approved: bool().oneOf([true], 'Required'),
    SubtypeQuantities: subtypeQuantitiesValidationSchema
    // _signature: string().min(1, 'Signature required').required('Signature required')
});

/**
 * update/insert the scheduledTimeEntrys collection in the local graphql cache for offline editing.
 * properties to be saved to the server are queued using the graphql apollo-link-queue-persist.
 * The timeEntry mutation is queued, which contains values from a single scheduledTimeEntrys item read from
 * the local graphql cache. New timeEntries are simply initialized here.
 * @param client
 * @param values
 * @returns {(function(*, *, *): Promise<T|void|undefined>)|*}
 */
const handleSubmit = (client, values, onClose) => async (formValues, formikBag, touched) => {
    const mutation = new Mutation(client);
    const timeEntry = cloneDeep(formValues);
    try {
        if (isEmpty(timeEntry.Guid)) {
            timeEntry.Guid = get('Guid', timeEntry) || v4();
            const filters = createFilters(values);
            const { scheduledTimeEntrys } = client.readQuery({
                query: dataQuery,
                variables: filters
            });
            client.writeQuery({
                query: dataQuery,
                data: {
                    // Contains the data to write
                    scheduledTimeEntrys: [...scheduledTimeEntrys, timeEntry]
                },
                variables: filters
            });
        } else {
            client.writeFragment({
                id: `CxTimeEntry:${timeEntry.Guid}`,
                fragment: CxTimeEntryFragment,
                data: timeEntry
            });
        }
        const online = await isOnline();
        if (!online) {
            onClose();
        }
        const { methodsByItem, items } = getTimeEntryMethods(timeEntry, touched);
        return await mutation.save(
            'CxTimeEntry',
            items,
            methodsByItem,
            undefined,
            undefined,
            undefined,
            offlineLinkOpen
        );
    } catch (error) {
        console.log('save error', error);
    }
};

const Form = (props) => {
    const { classes, values, setFieldValue, data } = props;
    const [user] = useContext(UserContext);
    const config_configValues = getConfigValue('config.mobile.mobiletimeentries', user);
    const auxiliariesDisplay = get('Auxiliaries.display', config_configValues);
    const resourceDisplay = get('Resource.display', config_configValues);
    const entryTypeDisplay = get('EntryType.display', config_configValues);
    const partyType = get('Party.PartyType', config_configValues) || ['Subcontractor'];
    const subtypeQuantitiesDisplay = get('SubtypeQuantities.display', config_configValues);
    const laborClassDisplay = get('LaborClass.display', config_configValues);
    // const sigCanvas = useRef({});
    const disableApproved = approvedByOther(values._partyId, values.Approvals);
    const disabled = values._approved === true;
    const [jobActivities, setJobActivities] = useState([]);
    // const getSignature = () => sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    // const signatureClass = useStyles();
    // useEffect(() => {
    //     if (disabled) {
    //         sigCanvas.current.off();
    //     } else {
    //         sigCanvas.current.on();
    //     }
    // }, [disabled]);

    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <div>
                    {/*<AttachmentButton pk={values.Id} objectType="CxTimeEntry" />*/}
                    <div>
                        <Field
                            type="date"
                            label="Date"
                            name="CurrentLifespan.Start"
                            component={DateEditor}
                            className={classes.textField}
                            onChange={(event, newDateTime) => {
                                setFieldValue(
                                    'CurrentLifespan.End',
                                    flow(addDays(1), format("yyyy-MM-dd'T'00:00:00"))(parseDate(newDateTime))
                                );
                            }}
                            disabled={disabled}
                        />
                    </div>
                    {resourceDisplay && (
                        <div>
                            <Field
                                type="text"
                                label="Resource"
                                name="Party"
                                component={AutoComplete}
                                className={classes.selectField}
                                optionsQuery={gql`
                                    query ($searchString: String, $partyType: [String]) {
                                        cxPartys(
                                            filters: [
                                                { name: "String", values: ["DisplayName", $searchString] }
                                                { name: "PartyType", values: $partyType }
                                            ]
                                        ) {
                                            Id
                                            DisplayName
                                        }
                                    }
                                `}
                                optionLabelProperty="DisplayName"
                                queryVariables={() => ({
                                    partyType: partyType
                                })}
                                getOptions={(options, field, form, data) => {
                                    const mergedOptions = [
                                        ...options,
                                        {
                                            Id: get('congistics.user.Party.Id', user),
                                            DisplayName: get('congistics.user.Party.Name', user)
                                        }
                                    ];
                                    return mergedOptions;
                                }}
                                disabled={disabled}
                            />
                        </div>
                    )}
                    <div>
                        <Field
                            type="text"
                            label="Job"
                            name="_Job"
                            component={AutoComplete}
                            className={classes.selectField}
                            optionLabelProperty="DisplayName"
                            optionsQuery={gql`
                                query ($searchString: String, $start: String, $end: String) {
                                    cxJobs(
                                        objectType: "Job"
                                        filters: [
                                            { name: "String", values: ["Name", $searchString] }
                                            { name: "Range", values: [$start, $end] }
                                        ]
                                    ) {
                                        Id
                                        DisplayName
                                        JobActivities {
                                            Id
                                            DisplayName
                                        }
                                        GlobalJobCosts {
                                            Id
                                            DisplayName
                                        }
                                    }
                                }
                            `}
                            queryVariables={() => ({
                                start: format("yyyy-MM-dd'T'00:00", new Date()),
                                end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                            })}
                            onChange={(event, value) => {
                                setFieldValue('JobCosts', []);
                                if (value) {
                                    setJobActivities(get('JobActivities', value));
                                    setFieldValue('JobActivity', get('JobActivities.0', value));
                                } else {
                                    setJobActivities([]);
                                    setFieldValue('JobActivity', { Id: 0, DisplayName: '' });
                                }
                            }}
                            disabled={disabled}
                            cache={true}
                        />
                    </div>
                    <div>
                        <Field
                            type="text"
                            label="Job Activity"
                            name="JobActivity"
                            component={AutoComplete}
                            className={classes.selectField}
                            optionLabelProperty="DisplayName"
                            options={jobActivities}
                            disabled={disabled}
                            disableClearable
                            cache={true}
                        />
                    </div>
                    <div>
                        <Field
                            type="text"
                            label="Job Costs"
                            name="JobCosts"
                            component={AutoComplete}
                            className={classes.selectField}
                            optionLabelProperty="DisplayName"
                            optionsQuery={gql`
                                query ($start: String, $end: String) {
                                    cxJobs(objectType: "Job", filters: [{ name: "Range", values: [$start, $end] }]) {
                                        Id
                                        GlobalJobCosts {
                                            Id
                                            DisplayName
                                        }
                                    }
                                }
                            `}
                            queryVariables={() => ({
                                start: format("yyyy-MM-dd'T'00:00", new Date()),
                                end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                            })}
                            getOptions={(options) => {
                                options = filter((options) => options.Id === values._Job.Id, options);
                                return get('0.GlobalJobCosts', options) || [];
                            }}
                            disabled={disabled}
                            cache={true}
                        />
                    </div>
                    {entryTypeDisplay && (
                        <div>
                            <Field
                                type="text"
                                label="Entry Type"
                                name="EntryType"
                                optionLabelProperty="DisplayName"
                                component={LookupValueEditor}
                                className={classes.selectField}
                                disabled={disabled}
                                disableClearable
                                cache={true}
                                hide={true}
                                objectType={'TimeEntryType'}
                            />
                        </div>
                    )}
                    <div>
                        <Field
                            type="number"
                            name="Minutes"
                            component={DurationEditor}
                            className={classes.extraShortTextField}
                            disabled={disabled}
                        />
                    </div>
                    {auxiliariesDisplay && (
                        <div>
                            <Field
                                type="text"
                                label="Equipment"
                                name="Auxiliaries"
                                component={AutoComplete}
                                className={classes.selectField}
                                optionLabelProperty="DisplayName"
                                optionsQuery={gql`
                                    query ($searchString: String, $start: String, $end: String) {
                                        cxEquipments(
                                            filters: [
                                                { name: "String", values: ["Name", $searchString] }
                                                { name: "Range", values: [$start, $end] }
                                            ]
                                        ) {
                                            Id
                                            DisplayName
                                        }
                                    }
                                `}
                                queryVariables={(values) => ({
                                    start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                                    end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                                })}
                                initialize={false}
                                disabled={disabled}
                                cache={true}
                            />
                        </div>
                    )}
                    {laborClassDisplay && (
                        <div>
                            <Field
                                type="text"
                                label="Labor Class"
                                name="LaborClass"
                                optionLabelProperty="DisplayName"
                                component={LookupValueEditor}
                                className={classes.selectField}
                                disabled={disabled}
                                disableClearable
                                objectType={'LaborClass'}
                                initialize={false}
                            />
                        </div>
                    )}
                    <div>
                        <Field
                            type="text"
                            label="Description"
                            name="Description"
                            component={TextField}
                            className={classes.textBoxField}
                            multiline
                            variant="outlined"
                            margin="normal"
                            disabled={disabled}
                        />
                    </div>
                    {subtypeQuantitiesDisplay && (
                        <Field
                            type="text"
                            name="SubtypeQuantities"
                            component={SubtypeQuantitiesEditor}
                            classes={classes}
                            data={data}
                            disabled={disabled}
                            cache={true}
                        />
                    )}

                    <div>
                        <Field type="text" label="File" name="Attachments" component={FileEditor} />
                    </div>
                    <div>
                        {/*<Typography>Sign Below</Typography>*/}
                        {/*<SignatureCanvas*/}
                        {/*    ref={sigCanvas}*/}
                        {/*    canvasProps={{ className: signatureClass.sigPad, disabled: true }}*/}
                        {/*    onEnd={() => {*/}
                        {/*        const signature = getSignature();*/}
                        {/*        setFieldValue('_signature', signature);*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<div style={{ color: 'red', fontSize: '.75rem' }}>*/}
                        {/*    <ErrorMessage name="_signature" />*/}
                        {/*</div>*/}
                        <Field
                            type="checkbox"
                            Label={{ label: 'Approved' }}
                            name="_approved"
                            component={CheckboxWithLabel}
                            disabled={disableApproved}
                            sx={{ paddingTop: '10px' }}
                        />
                        <div style={{ color: 'red', fontSize: '.75rem' }}>
                            <ErrorMessage name="_approved" />
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxTimeEntryMobile = ({ open, formQueryValues, onClose, values }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.LaborClass = { Id: 0, DisplayName: '' };
    delete initialValues.Hours;
    delete initialValues.Cost;
    initialValues.Approvals = [];
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.End = flow(addDays(1), format("yyyy-MM-dd'T'00:00:00"))(new Date());
    delete initialValues.Owner;
    initialValues.Party = {
        Id: get('congistics.user.Party.Id', user),
        DisplayName: get('congistics.user.Party.Name', user)
    };
    initialValues.JobActivity = {
        Id: '0',
        DisplayName: ''
    };
    initialValues._Job = {
        Id: '0',
        DisplayName: ''
    };
    initialValues.EntryType = {
        Id: '0',
        DisplayName: ''
    };
    initialValues._partyId = get('congistics.user.Party.Id', user);
    initialValues._approved = false;
    initialValues.JobCosts = [];
    initialValues.Punch = 'Manual';
    initialValues.PunchStart = PUNCHTIME;
    initialValues.PunchEnd = PUNCHTIME;
    initialValues.Auxiliaries = [];
    const onData = (timeEntry) => {
        timeEntry._approved =
            find((approval) => approval.Approver.Id === timeEntry._partyId, timeEntry.Approvals) !== undefined;
        /* eslint-disable eqeqeq */
        if (timeEntry.Id != 0) {
            return timeEntry;
        }
        // set properties for virtual timeentries because they do not contain valid values.
        timeEntry.EntryType = {
            Id: '0',
            DisplayName: ''
        };
        return timeEntry;
    };
    return (
        <DialogForm
            open={open}
            title="Time Entry"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxTimeEntry"
            queryName="timeEntry"
            inputModel={inputModel}
            cache={true}
            onClose={onClose}
            onData={onData}
            onSubmit={handleSubmit(client, values, onClose)}
        />
    );
};

EditCxTimeEntryMobile.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
