import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { instantiateBuild } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import { jobEvent as dataEventQuery } from 'client-shared/gqlhelpers/dataEventQueries';
import { gql } from 'graphql-tag';

function build(props) {
    const { format, object, number, date, get, user } = props;
    const validationSchema = object({
        _start: date().required('Required'),
        _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(365, 'Must be less than 365 days')
    });

    const dataQuery = `
        query ($filters: [FilterInput]) {
            cxJobs(filters: $filters) {
                Name
                DisplayName
                ForeColor
                BackColor
                CurrentLifespan {
                    Start
                    End
                }
                EffectiveLifespan {
                    Start
                    End
                }
                JobActivities {
                    Id
                    BackColor
                    ForeColor
                    DisplayName
                    Completed
                    CurrentLifespan {
                        Start
                        End
                    }
                    EffectiveLifespan {
                        Start
                        End
                }
                }
                Id
            }
        }
    `;

    return {
        title: 'Job Timeline',
        dataQuery,
        validationSchema,
        divisionFilter: true,
        dataEventQueries: [dataEventQuery],
        initialValues: {
            range: []
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Name',
                name: 'string__displayName',
                component: 'TextField',
                className: 'shortTextField'
            },
            {
                type: 'text',
                name: 'scheduled',
                component: 'ScheduledEditor',
                initialValue: 'All'
            },
            {
                type: 'text',
                label: 'Status',
                name: 'jobStatus_id',
                component: 'LookupValueEditor',
                className: 'shortSelectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobStatus',
                optionIdProperty: 'Id'
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Manager',
                name: 'manager_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxJobs(
                            objectType: "Job"
                            filters: [{ name: "String", values: ["Manager.DisplayName", $searchString] }]
                        ) {
                            Manager {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => {
                    return options.map((option) => ({
                        Id: option.Manager.Id,
                        DisplayName: option.Manager.DisplayName
                    }));
                },
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Contacts',
                name: 'contacts_id__all__true',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxCaches(
                            objectType: "cache"
                            filters: [{ name: "String", values: ["Contacts.DisplayName", $searchString] }]
                        ) {
                            Contacts {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => {
                    return get('0.Contacts', options);
                },
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'text',
                label: 'Interval',
                name: '_interval',
                component: 'AutoComplete',
                options: ['Days', 'Weeks', 'Months'],
                className: 'shortSelectField',
                initialValue: 'Days',
                optionLabelProperty: 'Name'
            },
            {
                type: 'number',
                label: 'Periods',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'checkbox',
                Label: { label: 'Show Job Activities' },
                name: '_showJobActivities',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'checkbox',
                Label: { label: 'Show Effective Spans' },
                name: '_showEffectiveSpans',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'number',
                label: 'Timeline Spacing',
                name: '_dy',
                component: 'AutoComplete',
                className: 'shortSelectField',
                initialValue: 36,
                options: [
                    { Id: 46, Name: 'Big' },
                    { Id: 36, Name: 'Comfortable' },
                    { Id: 26, Name: 'Tight' }
                ],
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            },
            {
                type: 'checkbox',
                Label: { label: 'Scroll Timeline' },
                name: '_scrollable',
                component: 'CheckboxWithLabel',
                initialValue: false
            }
        ]
    };
}

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
