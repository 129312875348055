import { gql } from 'graphql-tag';
import { fragments } from 'client-shared/utility';

export const AllocationsQuery = gql`
    ${fragments.CxAllocationScheduleFragment}
    query ($filters: [FilterInput], $formatters: [FormatInput]) {
        cxAllocations(objectType: "Allocation", filters: $filters, formatters: $formatters) {
            ...CxAllocationScheduleFragment
        }
    }
`;

export const HolidayQuery = gql`
    query {
        cxHolidays(objectType: "Holiday") {
            Holiday
        }
    }
`;
