import React from 'react';
import TextField from '@mui/material/TextField';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';

const TextEditor = ({
    type,
    sx = { width: '400px' },
    multiline,
    onChange,
    value,
    onValueChange,
    column,
    row,
    ...other
}) => {
    return (
        <TextField
            type={type}
            sx={sx}
            multiline={multiline}
            value={value}
            //onChange={(event) => onValueChange(event.target.value)}
            onChange={(event) => {
                // check if the native, onValueChange should be overridden.
                if (onChange) {
                    onChange(event.target.value, onValueChange, column, row);
                    return;
                }
                // onValueChange(value ? getOptionLabel(value) : value);
                onValueChange(event.target.value);
            }}
            {...other}
        />
    );
};

export const TextEditorProvider = ({ type, sx, multiline, onChange, ...other }) => {
    return (
        <DataTypeProvider
            editorComponent={(props) =>
                TextEditor({
                    type,
                    sx,
                    multiline,
                    onChange,
                    ...props
                })
            }
            {...other}
        />
    );
};

TextEditorProvider.propTypes = {
    type: PropTypes.string,
    sx: PropTypes.object,
    multiline: PropTypes.bool,
    onChange: PropTypes.func
};
