import { Mutation } from 'client-shared/utility';

/**
 * undo an allocation or taskday delete
 */
export const undo = (client, userId) => {
    const mutation = new Mutation(client);
    const filters = [
        {
            name: 'login',
            values: [userId]
        }
    ];
    const methodsByItem = [
        {
            indices: [],
            methods: [
                {
                    name: 'RestoreDeletedItems'
                }
            ]
        }
    ];
    return mutation
        .save('CxUser', undefined, methodsByItem, filters)
        .catch((error) => console.log('save error', error));
};
