import { get } from 'lodash/fp';

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const getConfigValue = (key, user) => {
    const configValue = get(['congistics', 'configValues', key], user);
    if (isJsonString(configValue)) {
        return JSON.parse(configValue);
    }
    return configValue;
};
