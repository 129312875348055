import React from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { makeStyles, useTheme } from '@mui/styles';
import { getOr, curry, includes, values, flatten, size, reject } from 'lodash/fp';
import { Grid, Table, TableHeaderRow, TableFixedColumns, TableGroupRow } from '@devexpress/dx-react-grid-material-ui';
import { GroupingState, IntegratedGrouping } from '@devexpress/dx-react-grid';
import { get, map, pipe, groupBy } from 'lodash/fp';
import { Filter } from './Filter';
import { getRange } from 'client-shared/utility/dateutilities';
import { keyByLifeSpan, mapStatus } from '../../utility/timekeepingHelpers';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    carsChip: {
        margin: theme.spacing(0.5)
    },
    headerColumn: {
        flexBasis: '100%',
        textAlign: 'center'
    },
    heading: {
        fontSize: theme.typography.pxToRem(18)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    legend: { listStyle: 'none' },
    legendItem: { display: 'inline' }
}));

const bakeDataPie = (range, jobs) => {
    return pipe(
        map(buildDataPie(range)),
        flatten,
        groupBy('Crew.Id'),
        values,
        map((_inner) => {
            return {
                Id: `${get('0.jobId', _inner)}_${get('0.Crew.Id', _inner)}`,
                crew: get('0.Crew.DisplayName', _inner),
                crewId: get('0.Crew.Id', _inner),
                job: get('0.job', _inner),
                jobId: get('0.jobId', _inner),
                ...keyByLifeSpan(_inner)
            };
        })
    )(jobs);
};

const buildDataPie = curry((range, job) => {
    const timeSheets = pipe(
        reject((o) => size(o.Crews) === 0),
        map(mapStatus),
        map((o) => ({
            ...o,
            ...{
                Crew: get('Crews.0', o),
                job: get('DisplayName', job),
                jobId: get('Id', job)
            }
        })),
        keyByLifeSpan
    )(job.TimeSheets);
    const taskDays = pipe(
        reject({ Crew: null }),
        map((o) => ({
            ...o,
            ...{
                Status: 'scheduled',
                job: get('DisplayName', job),
                jobId: get('Id', job)
            }
        })),
        keyByLifeSpan
    )(job.TaskDays);

    const items = { ...taskDays, ...timeSheets };
    return values(values(items));
});

export const EntryPoint = () => {
    return (
        <React.Fragment>
            <Filter>
                {({ values: variables, data: jobs }) => {
                    if (size(jobs) > 0) return <InnerTable {...{ variables, jobs }} />;
                    return <CircularProgress color="inherit" size={20} />;
                }}
            </Filter>
        </React.Fragment>
    );
};
export default EntryPoint;

const InnerTable = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { jobs, variables } = props;

    const range = getRange(variables._start, variables._periods);
    const columns = pipe(
        map((each) => ({ name: each, title: each })),
        (_in) => [{ name: 'crew', title: 'Crew' }, { name: 'job', title: 'Job' }, ..._in]
    )(range);

    const xtensions = map(
        (each) =>
            each.name === 'job' ? { columnName: each.name, width: '30%' } : { columnName: each.name, align: 'center' },
        columns
    );

    const toRender = bakeDataPie(range, jobs);
    //console.log(toRender)

    return (
        <Paper>
            <Grid rows={toRender} columns={columns} getRowId={get('Id')}>
                <TimeEntryLegend classes={classes} />
                <GroupingState grouping={[{ columnName: 'crew' }]} expandedGroups={map(get('crew'), toRender)} />
                <IntegratedGrouping />
                <Table cellComponent={Cell} columnExtensions={xtensions} />
                <TableHeaderRow />
                <TableGroupRow />
                <TableFixedColumns leftColumns={['crew']} />
            </Grid>
        </Paper>
    );
};

const TimeEntryLegend = ({ classes, ...rest }) => {
    return (
        <ul className={classes.legend}>
            <li key="notscheduled" className={classes.legendItem}>
                <Chip style={{ backgroundColor: 'white' }} label="not started" variant="outlined" />
            </li>
            <li key="inprogress" className={classes.legendItem}>
                <Chip style={{ backgroundColor: '#64b5f6' }} label="in progress" />
            </li>
            <li key="submitted" className={classes.legendItem}>
                <Chip style={{ backgroundColor: '#81c784' }} label="submitted" />
            </li>
            <li key="approved" className={classes.legendItem}>
                <Chip style={{ backgroundColor: '#388e3c' }} label="approved" />
            </li>
            {/*            <li key="needs_correction" className={classes.legendItem}>
                <Chip style={{ backgroundColor: '#e57373' }} label="needs correction" />
            </li>*/}
        </ul>
    );
};

const HighlightedCell = ({ value, style, column, row, ...restProps }) => {
    const navigate = useNavigate();
    return (
        <Table.Cell
            {...restProps}
            onClick={(_) => navigate(`${row.jobId}/${row.crewId}/${column.name}`)} // console.log(column, row)}
            style={{
                backgroundColor: getOr('white', get('Status', value), {
                    'in progress': '#64b5f6',
                    submitted: '#81c784',
                    approved: '#388e3c',
                    'needs correction': 'e57373'
                }),
                cursor: 'pointer',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'lightgray',
                ...style
            }}
        >
            {getOr('—', 'Status', value)}
        </Table.Cell>
    );
};

const Cell = (props) => {
    const { column } = props;
    if (!includes(column.name, ['crew', 'job'])) {
        return <HighlightedCell {...props} />;
    }
    return <Table.Cell {...props} />;
};
