import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { array, object, string } from 'yup';
import { Section } from 'client-shared/components/Section';

export const phoneValidationSchema = array().of(
    object({
        Name: string().required('Required'),
        PhoneNumber: string().required('Required')
    })
);

export const KVEditor = ({ form, field, classes, type, children, ...other }) => {
    return (
        <Section title="Choices">
            <FieldArray
                name={field.name}
                render={(arrayHelpers) => (
                    <React.Fragment>
                        {field.value.map((phone, index) => (
                            <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                <Field
                                    type="text"
                                    label="Name"
                                    name={`${field.name}.${index}.Name`}
                                    component={TextField}
                                    className={classes.shortTextField}
                                />
                                <Field
                                    type="text"
                                    label="Description"
                                    name={`${field.name}.${index}.Description`}
                                    component={TextField}
                                    className={classes.shortTextField}
                                />
                                <button type="button" onClick={() => arrayHelpers.remove(index)}>
                                    -
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={() => {
                                arrayHelpers.push({
                                    Id: 0,
                                    Name: '',
                                    Description: ''
                                });
                            }}
                        >
                            +
                        </button>
                    </React.Fragment>
                )}
            />
        </Section>
    );
};

KVEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};
