import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($filters: [FilterInput]) {
        cxToDos(filters: $filters) {
            Id
            Status {
                Id
                _DisplayName: DisplayName
            }
            Name
            Job {
                Id
                _DisplayName: DisplayName
            }
            Resources {
                Id
                _DisplayName: DisplayName
            }
            Priority {
                Id
                _DisplayName: DisplayName
            }
            CurrentLifespan {
                Start
                End
            }
            Description
            FieldNotes
        }
    }
`;
