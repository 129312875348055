import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "ActionStatus", $filters: [FilterInput]) {
        cxActionStatuss(objectType: $objectType, filters: $filters) {
            Id
            Name
            StatusType
            ForeColor
            BackColor
            Description
            Default
        }
    }
`;
