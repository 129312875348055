/**
 * Created by mark on 11/25/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormQuery } from './queries.js';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import model from 'cb-schema/emptymodel/CxForecast';
import inputModel from 'cb-schema/inputmodelfull/CxForecast';
import { Field, AutoComplete, UnitEditor } from 'client-shared/components/form';
import { object, string, number } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { flow } from 'lodash/fp';
import { format, addDays } from 'date-fns/fp';
import { gql } from 'graphql-tag';
import {
    ResourceSubTypeForecastEditor,
    resourceSubtypeForecastValidationSchema
} from './ResourceSubtypeForecastEditor';
import { Mutation, pickFromTemplateFp } from 'client-shared/utility';
import { useApolloClient } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

const validationSchema = object({
    Job: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    Unit: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    Interval: string().required('Required'),
    SubtypeForecasts: resourceSubtypeForecastValidationSchema
});

const getMethods = (forecast) => {
    let methods = [];
    const populate = {
        name: 'Populate'
    };
    if (forecast._populate === true) {
        methods.push(populate);
    }
    // methods.push(setStatus);
    return {
        operation: 'edit',
        methodsByItem: [
            {
                indices: [0],
                methods: methods
            }
        ],
        items: [pickFromTemplateFp(inputModel, forecast)]
    };
};

const handleSubmit = (client) => (values, formikBag, touched) => {
    const forecast = cloneDeep(values);
    const mutation = new Mutation(client);
    const { methodsByItem, items } = getMethods(forecast);
    return mutation.save('CxForecast', items, methodsByItem).catch((error) => console.log('save error', error));
};

const handleMakeGenerics = ({ client, object = 'CxForecast', values }) => {
    const mutation = new Mutation(client);
    const methodsByItem = [
        {
            methods: [
                {
                    name: 'MakeGenericAllocations',
                    args: {
                        useIntervals: false
                    }
                }
            ]
        }
    ];
    const data = [pickFromTemplateFp(inputModel, values)];
    return mutation
        .save(object, data, methodsByItem, undefined, undefined)
        .catch((error) => console.log('save error', error));
};

const Form = (props) => {
    const client = useApolloClient();
    const { classes, data, values } = props;
    return (
        <div style={{ zIndex: '100000' }}>
            <form noValidate autoComplete="off">
                <div>
                    <Field
                        type="text"
                        label="Job"
                        name="Job"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="_DisplayName"
                        optionsQuery={gql`
                            query ($searchString: String, $start: String, $end: String) {
                                cxJobs(
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Range", values: [$start, $end] }
                                    ]
                                ) {
                                    Id
                                    _DisplayName: DisplayName
                                }
                            }
                        `}
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00", new Date()),
                            end: flow(addDays(365), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                    />
                    <Field
                        type="text"
                        label="Unit"
                        name="Unit"
                        component={UnitEditor}
                        classes={classes}
                        unitTypes={['time']}
                    />
                    <Field
                        type="text"
                        label="Interval"
                        name="Interval"
                        component={AutoComplete}
                        className={classes.selectField}
                        // options={get('properties.Interval.enum', CxForeCast)}
                        options={['Months', 'Weeks', 'Days']}
                        disableClearable
                    />
                    {values.SubtypeForecasts.length === 0 && (
                        <Field
                            type="checkbox"
                            Label={{ label: 'Populate from schedule' }}
                            name="_populate"
                            component={CheckboxWithLabel}
                        />
                    )}
                </div>
                <Field
                    type="text"
                    name="SubtypeForecasts"
                    component={ResourceSubTypeForecastEditor}
                    classes={classes}
                    data={data}
                />
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
                {values.Id > 0 && (
                    <Tooltip title="Make Generic Allocations, this will modify your schedule">
                        <Button
                            onClick={() => handleMakeGenerics({ client, values })}
                            color="primary"
                            disabled={props.dirty}
                        >
                            Make Generic Allocations
                        </Button>
                    </Tooltip>
                )}
            </form>
        </div>
    );
};

export const EditCxForecast = ({ open, formQueryValues, initialValues, onClose }) => {
    const client = useApolloClient();
    let _initialValues = cloneDeep(model);
    _initialValues.Job = { Id: '0', _DisplayName: '' };
    _initialValues.Unit = {};
    _initialValues.Interval = 'Months';
    _initialValues._populate = false;
    _initialValues = { ..._initialValues, ...initialValues };
    return (
        <DialogForm
            open={open}
            title="Estimate"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={_initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxForecast"
            inputModel={inputModel}
            closeOnSave={false}
            onSubmit={handleSubmit(client)}
            onClose={onClose}
        />
    );
};

EditCxForecast.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    initialValues: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onData: PropTypes.func
};

// export const EditCxForecastFlatForm = ({ formQueryValues, initialValues }) => {
//     const client = useApolloClient();
//     let _initialValues = cloneDeep(model);
//     _initialValues.Job = { Id: '0', _DisplayName: '' };
//     _initialValues.Unit = {};
//     _initialValues.Interval = 'Months';
//     _initialValues._populate = false;
//     _initialValues = { ..._initialValues, ...initialValues };
//     return (
//         <FlatForm
//             title="Estimate"
//             Form={Form}
//             validationSchema={validationSchema}
//             initialValues={_initialValues}
//             formQuery={FormQuery}
//             formQueryValues={formQueryValues}
//             objectName="CxForecast"
//             inputModel={inputModel}
//             closeOnSave={false}
//             onSubmit={handleSubmit(client)}
//         />
//     );
// };
//
// EditCxForecastFlatForm.propTypes = {
//     formQueryValues: PropTypes.object,
//     initialValues: PropTypes.object
// };
