/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DirectionsIcon from '@mui/icons-material/Directions';
import { get } from 'lodash/fp';

export const Directions = ({ geocode, target = '_blank', ...other }) => {
    if (!geocode) {
        return null;
    }
    const handleClick = () => {
        window.open(
            `https://www.google.com/maps/search/?api=1&query=${get('Lat', geocode)},${get('Lng', geocode)}`,
            target
        );
    };
    return (
        <div {...other}>
            <Button
                variant="contained"
                size="small"
                style={{ marginBottom: '10px' }}
                color="primary"
                startIcon={<DirectionsIcon />}
                onClick={handleClick}
            >
                Directions
            </Button>
        </div>
    );
};

Directions.propTypes = {
    geocode: PropTypes.object,
    target: PropTypes.string
};
