import { ApolloClient, ApolloLink, split, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getAccessToken } from './authHelpers';
import { showError } from 'client-shared/components/ShowError';
import { makeGraphqlUrl } from './httpHelpers';
import { map, join, flow, uniqBy, identity, get, isObject, filter, isEmpty } from 'lodash/fp';
// import { persistQueue, LocalStorageWrapper as LocalStorageWrapperLink, QueueLink } from 'apollo-link-queue-persist';
import { InMemoryCache } from '@apollo/client';
import QueueLink from 'apollo-link-queue';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
// todo: replace following with is-url-online to avoid cross domain call.
import isOnline from 'is-online';
import { createClient } from 'graphql-ws';
import { pipeP } from 'global-shared/utils/utils';

let i = -1;
const cache = new InMemoryCache({
    dataIdFromObject: (object) => {
        if (object.__typename === 'CxJobCost') {
            if (object.Id === '0') {
                return `${object.__typename}:${i--}`;
            }
        }
        if (object.EventId) {
            // subscription events ids.
            return `${object.ObjectType}:${object.EventId}`;
        }
        if (!isEmpty(object.Guid)) {
            return `${object.__typename}:${object.Guid}`;
        }
        if (!object.Id) {
            return null;
        }
        return `${object.__typename}:${object.Id}`;
    },
    typePolicies: {
        Query: {
            fields: {
                // this is not mapped in the schema. it matches the query name on the client only.
                timeEntry: {
                    read(_, { args, toReference }) {
                        const guid = filter((filter) => {
                            return filter.name === 'Guid';
                        }, args.filters);
                        return [
                            toReference({
                                __typename: 'CxTimeEntry',
                                Id: get('0.values.0', guid)
                            })
                        ];
                    }
                }
            }
        }
    }
    // cacheRedirects: merge()
    // timeEntry.cacheRedirects,
    // allocation.cacheRedirects
});

const httpLink = new HttpLink({
    uri: process.env.APP_SERVER,
    credentials: 'same-origin'
});

const authMiddleware = setContext(() => getAccessToken().then((headers) => ({ headers })));

const wsLink = new GraphQLWsLink(
    createClient({
        url: makeGraphqlUrl(window.location.href),
        options: {
            reconnect: true
        }
    })
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

const queueLink = new QueueLink();

export const offlineLinkOpen = async () => {
    const onLine = await isOnline();
    if (onLine) {
        console.log('**online');
        queueLink.open();
    } else {
        console.log('**offline');
        queueLink.close();
    }
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        const message = flow(
            map(({ message }) => (typeof message === 'string' ? message : '')),
            uniqBy(identity),
            join(',')
        )(graphQLErrors);
        console.log(message);
        showError(client, message ? message : JSON.stringify(graphQLErrors));
        return;
    }
    if (networkError) {
        try {
            if (networkError.toString() === 'The user is not authenticated') return;
            const res = JSON.parse(networkError.bodyText);
            networkError.message = `${get('statusCode', res)} There was an error communicating with the internet. 
                    Please check your network connection.`;
        } catch (e) {
            console.log(networkError.toString());
            if (isObject(networkError)) {
                networkError.message = `There was an error communicating with the internet. 
                    Please check your network connection.`;
            }
        }
        //showError(client, networkError.message || networkError);
    }
});

// const persistQueriesMutations = curry(async (queueLink, offlineLinkOpen, client) => {
//     const storage = new LocalStorageWrapperLink(window.localStorage);
//     await persistQueue({
//         queueLink,
//         storage: storage,
//         client,
//         onCompleted: (request, response) => {
//             let items = storage.getItem('apollo-link-queue-persist');
//             if (items) {
//                 items = JSON.parse(items);
//                 items.shift();
//                 storage.setItem('apollo-link-queue-persist', JSON.stringify(items));
//             }
//         }
//     });
//     setInterval(offlineLinkOpen, 5000);
//     return client;
// });

let client;

export const getClient = async () => {
    if (client) {
        return client;
    }
    client = await new ApolloClient({
        link: ApolloLink.from([errorLink, authMiddleware, splitLink]),
        cache
    });
    return client;
};

export const getPersistentClient = async () => {
    if (client) {
        return client;
    }
    client = await pipeP(
        () =>
            persistCache({
                cache,
                storage: new LocalStorageWrapper(window.localStorage)
            }),
        () =>
            new ApolloClient({
                link: ApolloLink.from([errorLink, authMiddleware, queueLink, splitLink]),
                cache
            })
        // persistQueriesMutations(queueLink, offlineLinkOpen)
    )();
    setInterval(offlineLinkOpen, 5000);
    return client;
};

// export const getClient = async () => {
//     if (client) {
//         return client;
//     }
//
//     await persistCache({
//         cache,
//         storage: new LocalStorageWrapper(window.localStorage)
//     });
//
//     client = new ApolloClient({
//         link: ApolloLink.from([
//             errorLink,
//             authMiddleware,
//             queueLink,
//             splitLink
//         ]),
//         cache
//     });
//
//     await persistQueriesMutations(queueLink, offlineLinkOpen, client)
//
//     return client;
// };
