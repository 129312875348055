import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'formik-mui';
import { Field } from 'client-shared/components/form';
import { object, date, ref } from 'yup';
// yup.array().transform(function () {
//     const otherValue = this.resolve('otherField')
// })
export const currentLifespanTimeValidationSchema = object({
    Start: date().required('Required'),
    End: date().test('match', 'End date must be greater than start date', function (End) {
        return End > this.resolve(ref('Start'));
    })
});

export const CurrentLifespanTimeEditor = ({
    form,
    field,
    classes,
    component = 'div',
    startLabel = 'Start',
    endLabel = 'End',
    children,
    style,
    ...other
}) => {
    return (
        <span style={style}>
            <Field
                type="datetime-local"
                label={startLabel}
                name={`${field.name}.Start`}
                component={TextField}
                className={classes.dateTimeField}
                InputLabelProps={{
                    shrink: true
                }}
                variant="standard"
            />
            <Field
                type="datetime-local"
                label={endLabel}
                name={`${field.name}.End`}
                component={TextField}
                className={classes.dateTimeField}
                InputLabelProps={{
                    shrink: true
                }}
                variant="standard"
            />
        </span>
    );
};

CurrentLifespanTimeEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    component: PropTypes.string,
    startLabel: PropTypes.string,
    endLabel: PropTypes.string
};
