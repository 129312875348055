import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Field } from 'client-shared/components/form';
import { gql } from 'graphql-tag';

export const UnitEditor = ({ field, classes, label = 'Unit', unitTypes = [], displayName = '_DisplayName' }) => {
    const displayNameFragment = `${displayName}:DisplayName`;
    return (
        <Field
            type="text"
            label={label}
            name={field.name}
            component={AutoComplete}
            className={classes.shortTextField}
            optionsQuery={gql`
                query ($searchString: String, $unitTypes: [String]) {
                    cxUnits(
                        filters: [
                            { name: "String", values: ["DisplayName", $searchString] }
                            { name: "UnitType", values: $unitTypes }
                        ]
                    ) {
                        Id
                        ${displayNameFragment}
                    }
                }
            `}
            optionLabelProperty={displayName}
            queryVariables={() => ({
                unitTypes: unitTypes
            })}
            disableClearable
        />
    );
};

UnitEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    unitTypes: PropTypes.array
};
