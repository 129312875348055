import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "Company", $filters: [FilterInput]) {
        cxCompanys(objectType: $objectType, filters: $filters) {
            Id
            CompanyType {
                Id
                _DisplayName: DisplayName
            }
            AccountCode
            Name
            Description
            CurrentLifespan {
                Start
                End
            }
            Contacts {
                Id
                Name
                Title
                PhoneNumbers {
                    Id
                    Name
                    PhoneNumber
                }
                Emails {
                    Id
                    Name
                    Email
                }
                Address {
                    Id
                    Street
                    StreetDetails
                    City
                    State {
                        Id
                        _Description: Description
                    }
                    ZipCode
                    Geocode {
                        Id
                        Lng
                        Lat
                    }
                }
            }
            Emails {
                Id
                Name
                Email
            }
            PhoneNumbers {
                Id
                Name
                PhoneNumber
            }
            Address {
                Id
                Autocode
                Street
                StreetDetails
                City
                State {
                    Id
                    _Description: Description
                }
                ZipCode
                Geocode {
                    Id
                    Lat
                    Lng
                }
            }
            ForeColor
            BackColor
            Divisions {
                Id
                _DisplayName: DisplayName
            }
        }
    }
`;
