import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "TaskDay", $filters: [FilterInput]) {
        cxTaskDays(objectType: $objectType, filters: $filters) {
            ParentId
            Id
            ObjectType
            ConflictIds
            ShiftSupervisor
            Description
            Job {
                Id
                _DisplayName: DisplayName
            }
            JobActivity {
                Id
                _DisplayName: DisplayName
            }
            CurrentLifespan {
                Start
                End
            }
            Supplier {
                Id
                _DisplayName: DisplayName
            }
            _ResourceType: ResourceType {
                Unit {
                    Name
                }
            }
            ResourceSubtype {
                Id
                _Name: Name
            }
            Amount
            Filled
            #            ActionStatus {
            #                Id
            #                _DisplayName: DisplayName
            #            }
            Description
        }
    }
`;

export const CxJobsQuery = gql`
    query ($searchString: String, $start: String, $end: String) {
        cxJobs(
            objectType: "Job"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            _JobActivities: JobActivities {
                Id
                _DisplayName: DisplayName
            }
        }
    }
`;
