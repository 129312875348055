import { gql } from 'graphql-tag';
export const FormQuery = gql`
    query ($objectType: String = "CostCode", $filters: [FilterInput]) {
        cxCostCodes(objectType: $objectType, filters: $filters) {
            ObjectType
            ForeColor
            BackColor
            UnitCost {
                Unit {
                    Id
                    _DisplayName: DisplayName
                }
                Cost {
                    Unit {
                        Id
                        _DisplayName: DisplayName
                    }
                    Quantity
                }
            }
            Global
            Id
            Name
            Description
        }
    }
`;
