import {
    DragDropBase,
    CellDragDropMixin,
    AllocationDragDropMixin,
    ActionsMixin,
    parseDate
} from 'client-shared/utility';
import { format, addDays, differenceInCalendarDays } from 'date-fns/fp';
import { flow, flatMap, uniqBy, sortBy, groupBy, map, get } from 'lodash/fp';
import { mapToArray } from 'client-shared/components/schedulegrid/schedulehelpers';

class DragDrop extends DragDropBase {
    pasteJobActivityOnCell({ selectedItemProperties, targetProperties, jobActivity, toolBarOptions, selected }) {
        return {
            methodsByItem: [],
            items: null
        };
    }

    /**
     * cut and paste an allocation by creating a new allocation in the target cell and
     * removing the allocation from the source cell.
     * @param targetProperties
     * @returns {*}
     * @private
     * @param selectedItemProperties
     * @param allocation
     * @param toolBarOptions
     * @param selected
     */
    cutPasteAllocationOnCell({ selectedItemProperties, targetProperties, allocation, toolBarOptions, selected }) {
        if (!get('CurrentLifespan.End', selectedItemProperties)) {
            return [];
        }
        let _allocation = {
            // retain id if selected or one day allocation.
            Id:
                selected ||
                differenceInCalendarDays(
                    parseDate(allocation.CurrentLifespan.Start),
                    parseDate(allocation.CurrentLifespan.End)
                ) === 0
                    ? allocation.Id
                    : 0,
            ObjectType: 'Allocation',
            Name: allocation.Name,
            Shift: { Id: allocation.Shift.Id },
            Description: allocation.Description,
            Tags: allocation.Tags,
            Crew: allocation.Crew ? { Id: allocation.Crew.Id } : undefined
            // JobCosts: allocation.JobCosts
        };

        if (toolBarOptions.copy) {
            _allocation.Id = 0;
            _allocation.Name = allocation.Name;
        }
        if (_allocation.Id === 0) {
            _allocation.Tags = map((tag) => ({ ...tag, Id: 0 }), _allocation.Tags);
        }

        // retain allocation.JobActivity.Id if dropped on job level cell, and
        // allocation.Job.Id matches the targetProperties.Job.Id. this handles the case
        // where the allocations are being moved between dates on the same job.
        if (targetProperties._root && allocation.Job.Id === targetProperties.Job.Id) {
            _allocation.JobActivity = { Id: allocation.JobActivity.Id };
        } else {
            //_allocation.Id = allocation.JobActivity.Id !== targetProperties.JobActivity.Id ? 0 : allocation.Id;
            _allocation.JobActivity = { Id: targetProperties.JobActivity.Id };
        }
        const methods = [
            {
                name: 'paste',
                args: {
                    allocationId: allocation.Id,
                    start: flow(
                        parseDate,
                        format("yyyy-MM-dd'T'00:00:00")
                    )(selectedItemProperties.CurrentLifespan.Start),
                    end: selected
                        ? flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(allocation.CurrentLifespan.End)
                        : flow(
                              parseDate,
                              addDays(1),
                              format("yyyy-MM-dd'T'00:00:00")
                          )(selectedItemProperties.CurrentLifespan.End),
                    target: flow(parseDate, format("yyyy-MM-dd'T'00:00:00"))(targetProperties.CurrentLifespan.Start),
                    copy: toolBarOptions.copy
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [_allocation]
        };
    }

    /**
     * cut and paste taskDay by creating a new allocation in the target cell and
     * removing the taskDays from the source allocations.
     * @param selectedItemProperties
     * @param targetProperties
     * @returns {*}
     * @private
     * @param taskDay
     * @param toolBarOptions
     * @param selected
     */
    cutPasteTaskDayOnCell({ selectedItemProperties, targetProperties, taskDay, toolBarOptions, selected }) {
        let _taskDay;
        if (taskDay.ObjectType === 'ActualTaskDay') {
            _taskDay = {
                ParentId: 0,
                Id: toolBarOptions.copy ? 0 : taskDay.Id,
                Principals: taskDay.Principals,
                CurrentLifespan: {}
            };
        } else {
            _taskDay = {
                ParentId: 0,
                Id: toolBarOptions.copy ? 0 : taskDay.Id,
                Supplier: taskDay.Supplier,
                ResourceSubtype: { Id: taskDay.ResourceSubtype.Id },
                Amount: taskDay.Amount,
                CurrentLifespan: {}
            };
        }

        const days = flow(
            parseDate,
            differenceInCalendarDays(parseDate(taskDay.CurrentLifespan.Start))
        )(targetProperties.CurrentLifespan.Start);
        _taskDay.CurrentLifespan.Start = flow(
            parseDate,
            addDays(days),
            format("yyyy-MM-dd'T'HH:mm:00")
        )(taskDay.CurrentLifespan.Start);
        _taskDay.CurrentLifespan.End = flow(
            parseDate,
            addDays(days),
            format("yyyy-MM-dd'T'HH:mm:00")
        )(taskDay.CurrentLifespan.End);
        const methods = [
            {
                name: 'addTaskDays',
                args: {
                    taskDays: [_taskDay]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: targetProperties.JobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    /**
     * creating a new allocation from the resource dropped on the target cell.
     * @param targetProperties
     * @returns {{ParentId: number, JobActivity: {Id: (* | number)}, CurrentLifespan: {Start: string, End: string}, Id: number, Resources: {Id: *}[]}}
     * @private
     * @param resource
     * @param toolBarOptions
     * @param selected
     */
    pasteResourceOnCell({ targetProperties, resource, toolBarOptions, selected }) {
        const resourceAllocation = [
            {
                name: 'setSpans',
                args: {
                    resourceSpans: [
                        {
                            Principal: {
                                Id: resource.Id
                            },
                            Range: {
                                start: flow(
                                    parseDate,
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start),
                                end: flow(
                                    parseDate,
                                    addDays(1),
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start)
                            },
                            Auxiliaries: [
                                {
                                    Id: resource.Auxiliaries.length === 1 ? resource.Auxiliaries[0].Id : undefined
                                }
                            ]
                        }
                    ]
                }
            }
        ];
        const x = {
            methodsByItem: [
                {
                    indices: [0],
                    methods: resourceAllocation
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: targetProperties.JobActivity.Id },
                    ObjectType: 'Allocation',
                    Crew: resource.ObjectType === 'Crew' ? { Id: resource.Id } : undefined
                }
            ]
        };
        return x;
    }

    /**
     * paste a resourcetype by creating a new virtualTaskDay in the target cell and
     * @param targetProperties
     * @returns {*}
     * @private
     * @param selectedItemProperties
     * @param resourceType
     * @param toolBarOptions
     * @param selected
     */
    pasteResourceTypeOnCell({ selectedItemProperties, targetProperties, resourceType, toolBarOptions, selected }) {
        const resourceAllocation = [
            {
                name: 'setSpans',
                args: {
                    resourceQuantitySpans: [
                        {
                            //SupplierId: targetProperties.defaultCompanyId,
                            ResourceSubtypeId: resourceType.ResourceSubtype.Id,
                            Amount: 1,
                            Range: {
                                start: flow(
                                    parseDate,
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start),
                                end: flow(
                                    parseDate,
                                    addDays(1),
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start)
                            },
                            ResourceIds: []
                        }
                    ]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: resourceAllocation
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: targetProperties.JobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    /**
     * paste a subcontractor or plant by creating a new virtualTaskDay in the target cell and
     * @param targetProperties
     * @returns {*}
     * @private
     * @param selectedItemProperties
     * @param resourceSubtype
     * @param toolBarOptions
     * @param selected
     */
    pasteSupplierOnCell({ selectedItemProperties, targetProperties, supplier, toolBarOptions, selected }) {
        const resourceAllocation = [
            {
                name: 'setSpans',
                args: {
                    resourceQuantitySpans: [
                        {
                            SupplierId: supplier.Id,
                            ResourceSubtypeId: supplier.DefaultSubType.ResourceSubtype.Id,
                            Amount: 1,
                            Range: {
                                start: flow(
                                    parseDate,
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start),
                                end: flow(
                                    parseDate,
                                    addDays(1),
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start)
                            },
                            ResourceIds: []
                        }
                    ]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: resourceAllocation
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: targetProperties.JobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    /**
     *  Shift allocations within a job activity.
     * @param daysToShift - number of days to shift.
     * @param shiftAll - shift all allocations across resources
     * @param selectedItems - list of  allocations, and taskDays to shift.
     */
    shiftAllocation({ daysToShift, shiftAll, selectedItems }) {
        const shift = ({
            selectedItemProperties,
            jobIds,
            jobActivityIds,
            daysToShift,
            resourceIds,
            resourceSubtypeIds
        }) => {
            const methods = [
                {
                    name: 'move',
                    args: {
                        source: format(
                            "yyyy-MM-dd'T'00:00:00",
                            parseDate(selectedItemProperties.CurrentLifespan.Start)
                        ),
                        daysToShift: daysToShift
                    }
                }
            ];
            // shift all resources contained in the fetch of future allocations. if resourceIds were passed, then shift
            // only these resources.
            return {
                filters: [
                    {
                        name: 'Range',
                        values: [
                            format("yyyy-MM-dd'T'00:00:00", parseDate(selectedItemProperties.CurrentLifespan.Start)),
                            '2999-01-01T00:00:00'
                        ]
                    },
                    {
                        name: 'Job.Id',
                        values: jobIds
                    },
                    {
                        name: 'JobActivity.Id',
                        values: jobActivityIds
                    },
                    {
                        name: 'ResourceIds',
                        values: resourceIds
                    },
                    {
                        name: 'TaskDays.ResourceSubtypeId',
                        values: resourceSubtypeIds
                    },
                    {
                        name: 'TaskDays.Filled',
                        values: resourceSubtypeIds.length ? ['false'] : []
                    }
                ],

                methodsByItem: [
                    {
                        indices: [],
                        methods: methods
                    }
                ],
                items: []
            };
        };

        // create a list of taskDays grouped by job activity that is unique by job activity and resource or resourcesubtype.
        const groupedTaskDays = flow(
            flatMap((selectedItem) => {
                if (selectedItem.item.ObjectType === 'Allocation') {
                    return map(
                        (taskDay) => ({
                            selectedItemProperties: selectedItem.selectedItemProperties,
                            ...taskDay
                        }),
                        mapToArray(selectedItem.item.TaskDays)
                    );
                }
                return [{ selectedItemProperties: selectedItem.selectedItemProperties, ...selectedItem.item }];
            }),
            uniqBy((taskDay) =>
                [
                    taskDay.JobActivity.Id,
                    taskDay.Principals.length ? taskDay.Principals[0].Id : taskDay.ResourceSubtype.Id
                ].join()
            ),
            sortBy(['JobActivity.Id', 'selectedItemProperties.CurrentLifespan.Start']),
            groupBy((taskDay) => `${taskDay.JobActivity.Id},${taskDay.ObjectType}`)
        )(selectedItems);
        for (let [, taskDays] of Object.entries(groupedTaskDays)) {
            const v = shift({
                selectedItemProperties: taskDays[0].selectedItemProperties,
                jobIds: shiftAll ? [taskDays[0].Job.Id] : [],
                jobActivityIds: shiftAll ? [] : [taskDays[0].JobActivity.Id],
                daysToShift: daysToShift,
                resourceIds: shiftAll
                    ? []
                    : taskDays
                          .filter((taskDay) => taskDay.ObjectType === 'ActualTaskDay')
                          .map((taskDay) => taskDay.Principals[0].Id),
                resourceSubtypeIds: shiftAll
                    ? []
                    : taskDays
                          .filter((taskDay) => taskDay.ObjectType === 'VirtualTaskDay')
                          .map((taskDay) => taskDay.ResourceSubtype.Id)
            });
            this.save('CxAllocation', v.items, v.methodsByItem, v.filters).catch((error) => console.log(error));
        }
    }
}

export class CellDragDrop extends CellDragDropMixin(DragDrop) {}
export class AllocationDragDrop extends AllocationDragDropMixin(DragDrop) {}
export class Actions extends ActionsMixin(DragDrop) {}
