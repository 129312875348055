import { DragDropBase, CellDragDropMixin, AllocationDragDropMixin, ActionsMixin } from 'client-shared/utility';
import differenceInCalendarDays from 'date-fns/fp/differenceInCalendarDays';
import { format, addDays } from 'date-fns/fp';
import { flow, uniqBy, sortBy, get, filter } from 'lodash/fp';
import { parseDate } from 'client-shared/utility';

class DragDrop extends DragDropBase {
    /**
     * paste resources by creating a new allocation from the resource dropped on the target cell or
     * allocation for this crew.
     * @param targetProperties
     * @returns {{ParentId: number, JobActivity: {Id: (* | number)}, CurrentLifespan: {Start: string, End: string}, Id: number, Resources: {Id: *}[]}}
     * @private
     * @param resource
     * @param toolBarOptions
     * @param selected
     *
     */
    pasteResourceOnCell(props) {
        const { selectedItemProperties, targetProperties, resource, toolBarOptions, selected } = props;
        if (targetProperties.ObjectType === 'Crew') {
            if (this.targetAllocation) {
                if (get('crewMember', selectedItemProperties)) {
                    return this.pasteResourceOnTaskDay({
                        selectedItemProperties: selectedItemProperties,
                        targetProperties: this.targetProperties,
                        resource: resource,
                        allocation: this.targetAllocation,
                        toolBarOptions: toolBarOptions,
                        selected: selected
                    });
                }
                return this.pasteResourceOnAllocation(props);
            }
            this.handleOpenEditor('EditCxAllocation', {
                open: true,
                initialValues: {
                    Job: {
                        Id: this.targetAllocation ? this.targetAllocation.Job.Id : 0,
                        _DisplayName: this.targetAllocation ? this.targetAllocation.Job.DisplayName : ''
                    },
                    JobActivity: {
                        Id: this.targetAllocation ? this.targetAllocation.JobActivity.Id : 0,
                        _DisplayName: this.targetAllocation ? this.targetAllocation.JobActivity.DisplayName : ''
                    },
                    //Name: resource.DisplayName,
                    Crew: {
                        Id: targetProperties.Resource.Id
                    },
                    CurrentLifespan: {
                        Start: format("yyyy-MM-dd'T'08:00:00", parseDate(targetProperties.CurrentLifespan.Start)),
                        End: flow(
                            parseDate,
                            //addDays(1),
                            format("yyyy-MM-dd'T'17:00:00")
                        )(targetProperties.CurrentLifespan.Start)
                    },
                    ResourceSpans: [
                        {
                            Principal: { Id: resource.Id, _DisplayName: resource.DisplayName },
                            Range: {
                                Start: format(
                                    "yyyy-MM-dd'T'08:00:00",
                                    parseDate(targetProperties.CurrentLifespan.Start)
                                ),
                                End: flow(
                                    parseDate,
                                    //addDays(1),
                                    format("yyyy-MM-dd'T'17:00:00")
                                )(targetProperties.CurrentLifespan.Start)
                            },
                            Auxiliaries:
                                get('Auxiliaries.length', resource) === 1 ? [get('Auxiliaries.0', resource)] : []
                        }
                    ]
                },
                formQueryValues: {
                    filters: [{ name: 'Id', values: ['0'] }]
                }
            });
        }
        return {
            methodsByItem: [],
            items: []
        };
    }

    /**
     * paste resources by creating a new allocation from the resource dropped on the target allocation.
     * @param targetProperties
     * @returns {{ParentId: number, JobActivity: {Id: (* | number)}, CurrentLifespan: {Start: string, End: string}, Id: number, Resources: {Id: *}[]}}
     * @private
     * @param resource
     * @param toolBarOptions
     * @param selected
     */
    pasteResourceOnAllocation({ targetProperties, resource, toolBarOptions, selected }) {
        const resourceAllocation = [
            {
                name: 'setSpans',
                args: {
                    resourceSpans: [
                        {
                            Principal: {
                                Id: resource.Id
                            },
                            Range: {
                                start: flow(
                                    parseDate,
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start),
                                end: flow(
                                    parseDate,
                                    addDays(1),
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start)
                            },
                            Auxiliaries: [
                                {
                                    Id: resource.Auxiliaries.length === 1 ? resource.Auxiliaries[0].Id : undefined
                                }
                            ]
                        }
                    ]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: resourceAllocation
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: this.targetAllocation.JobActivity.Id },
                    ObjectType: 'Allocation',
                    Crew: this.targetAllocation.Crew
                }
            ]
        };
    }

    /**
     * replace the resources if a crew allocation is dropped on a crew target.
     * replace the resource if a non-crew, real allocation that has a selectedItemProperties resource property
     * is dropped on a resource target
     * add the resource if a non-crew, real allocation that does not have a selectedItemProperties resource property
     * is dropped on a resource target
     * selectedItemProperties resource property.
     * @param selectedItemProperties
     * @param targetProperties
     * @param allocation
     * @param toolBarOptions
     * @param selected
     * @returns {*[]|{methodsByItem: [], items: [{JobActivity: {Id: *}, Shift: {Id: *}, ObjectType: string, Crew: {Id: *}|any, Description: *, Id: *|number, Name: *}]}}
     */
    cutPasteAllocationOnCell({ selectedItemProperties, targetProperties, allocation, toolBarOptions, selected }) {
        // do not allow dropping a crew or fake allocation on a non-crew target.
        if (allocation.Crew && targetProperties.ObjectType !== 'Crew') {
            return [];
        }
        const methodsByItem = [];
        let _allocation = {
            Id: selected ? allocation.Id : 0,
            JobActivity: { Id: allocation.JobActivity.Id },
            ObjectType: 'Allocation',
            Name: allocation.Name,
            Shift: { Id: allocation.Shift.Id },
            Description: allocation.Description,
            Tags: allocation.Tags,
            Crew:
                targetProperties.ObjectType === 'Crew'
                    ? {
                          Id: targetProperties.Resource.Id
                      }
                    : undefined,
            JobCosts: allocation.JobCosts
        };

        if (toolBarOptions.copy) {
            _allocation.Id = 0;
        }
        if (_allocation.Id === 0) {
            _allocation.Tags = _allocation.Tags.map((tag) => ({ ...tag, Id: 0 }));
        }

        methodsByItem.push({
            indices: [0],
            methods: [
                {
                    name: 'paste',
                    args: {
                        allocationId: allocation.Id,
                        start: flow(
                            parseDate,
                            format("yyyy-MM-dd'T'00:00:00")
                        )(selectedItemProperties.CurrentLifespan.Start),
                        end: selected
                            ? flow(
                                  parseDate,
                                  addDays(1),
                                  format("yyyy-MM-dd'T'00:00:00")
                              )(allocation.CurrentLifespan.End)
                            : flow(
                                  parseDate,
                                  addDays(1),
                                  format("yyyy-MM-dd'T'00:00:00")
                              )(selectedItemProperties.CurrentLifespan.End),
                        target: flow(
                            parseDate,
                            format("yyyy-MM-dd'T'00:00:00")
                        )(targetProperties.CurrentLifespan.Start),
                        copy: toolBarOptions.copy
                    }
                }
            ]
        });
        // replace the resources if a crew allocation is dropped on a crew target.
        if (allocation.ActionType === 'Crew') {
            methodsByItem.push({
                indices: [0],
                methods: [
                    {
                        name: 'replaceResources',
                        args: {
                            originalIds: [allocation.Crew.Id],
                            newIds: [targetProperties.Resource.Id],
                            start: selected
                                ? undefined
                                : flow(
                                      parseDate,
                                      format("yyyy-MM-dd'T'00:00:00")
                                  )(targetProperties.CurrentLifespan.Start),
                            end: selected
                                ? undefined
                                : flow(
                                      parseDate,
                                      addDays(1),
                                      format("yyyy-MM-dd'T'00:00:00")
                                  )(targetProperties.CurrentLifespan.Start),
                            clearAll: false
                        }
                    }
                ]
            });
        }

        // replace the resource if a non-crew, real allocation that has a selectedItemProperties resource property
        // is dropped on a resource target
        if (selectedItemProperties.Resource && !allocation.Crew) {
            methodsByItem.push({
                indices: [0],
                methods: [
                    {
                        name: 'replaceResources',
                        args: {
                            originalIds: [selectedItemProperties.Resource.Id],
                            newIds: [targetProperties.Resource.Id],
                            start: selected
                                ? undefined
                                : flow(
                                      parseDate,
                                      format("yyyy-MM-dd'T'00:00:00")
                                  )(targetProperties.CurrentLifespan.Start),
                            end: selected
                                ? undefined
                                : flow(
                                      parseDate,
                                      addDays(1),
                                      format("yyyy-MM-dd'T'00:00:00")
                                  )(targetProperties.CurrentLifespan.Start),
                            clearAll: false
                        }
                    }
                ]
            });
        }
        // add the resource if a non-crew, real allocation that does not have a selectedItemProperties resource property
        // is dropped on a resource target
        if (!selectedItemProperties.Resource && !allocation.Crew) {
            methodsByItem.push({
                indices: [0],
                methods: [
                    {
                        name: 'addResources',
                        args: {
                            resourceIds: [targetProperties.Resource.Id],
                            start: flow(
                                parseDate,
                                format("yyyy-MM-dd'T'00:00:00")
                            )(targetProperties.CurrentLifespan.Start),
                            end: flow(
                                parseDate,
                                addDays(1),
                                format("yyyy-MM-dd'T'00:00:00")
                            )(targetProperties.CurrentLifespan.Start)
                        }
                    }
                ]
            });
        }

        return {
            methodsByItem: methodsByItem,
            items: [_allocation]
        };
    }

    cutPasteTaskDayOnCell({ selectedItemProperties, targetProperties, taskDay, toolBarOptions, selected }) {
        // do not allow dropping taskdays on the crew summary row.
        if (targetProperties.ObjectType === 'Crew') {
            return [];
        }
        const _taskDay = {
            ParentId: 0, // add taskDay to allocation.
            Id: toolBarOptions.copy ? 0 : taskDay.Id,
            Principals: [{ Id: targetProperties.Resource.Id }],
            CurrentLifespan: {}
        };

        const days = flow(
            parseDate,
            differenceInCalendarDays(parseDate(taskDay.CurrentLifespan.Start))
        )(targetProperties.CurrentLifespan.Start);
        _taskDay.CurrentLifespan.Start = flow(
            parseDate,
            addDays(days),
            format("yyyy-MM-dd'T'HH:mm:00")
        )(taskDay.CurrentLifespan.Start);
        _taskDay.CurrentLifespan.End = flow(
            parseDate,
            addDays(days),
            format("yyyy-MM-dd'T'HH:mm:00")
        )(taskDay.CurrentLifespan.End);
        const methods = [
            {
                name: 'addTaskDays',
                args: {
                    taskDays: [_taskDay]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: taskDay.JobActivity.Id },
                    // Crew: {
                    //     Id: targetProperties.Resource.Id
                    // },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    /**
     * paste jobActivity by creating new allocations from the resource in the target cell.
     * @param targetProperties
     * @param jobActivities
     * @returns {*}
     * @private
     */
    pasteJobActivityOnCell({ selectedItemProperties, targetProperties, jobActivity, toolBarOptions, selected }) {
        // for now, skip drop on ResourceType. This should create a generic allocation in the future.
        // if (targetProperties.ResourceType) {
        //     return {
        //         allocation: null,
        //         methods: []
        //     };
        // }
        const methods = [
            {
                name: 'addResources',
                args: {
                    resourceIds: [targetProperties.Resource.Id],
                    start: flow(parseDate, format("yyyy-MM-dd'T'00:00:00"))(targetProperties.CurrentLifespan.Start),
                    end: flow(
                        parseDate,
                        addDays(1),
                        format("yyyy-MM-dd'T'00:00:00")
                    )(targetProperties.CurrentLifespan.Start)
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: jobActivity.Id },
                    Crew:
                        targetProperties.ObjectType === 'Crew'
                            ? {
                                  Id: targetProperties.Resource.Id
                              }
                            : undefined,
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    /**
     * paste jobActivity by creating new allocations from the target allocation.
     * @param targetProperties
     * @param jobActivities
     * @returns {*}
     * @private
     */
    pasteJobActivityOnAllocation({
        selectedItemProperties,
        targetProperties,
        allocation,
        jobActivity,
        toolBarOptions,
        selected
    }) {
        if (!toolBarOptions.copy) {
            return this.pasteJobActivityOnCell({
                selectedItemProperties,
                targetProperties,
                jobActivity,
                toolBarOptions,
                selected
            });
        }
        selectedItemProperties = {
            Resource: {
                Id: allocation.Crew.Id
            },
            CurrentLifespan: allocation.CurrentLifespan,
            ObjectType: allocation.ObjectType
        };
        allocation.JobActivity.Id = jobActivity.Id;
        return this.cutPasteAllocationOnCell({
            selectedItemProperties,
            targetProperties,
            allocation,
            toolBarOptions,
            selected
        });
    }

    /**
     * paste subcontractor/plant by creating a new allocation from the supplier dropped on the target cell or
     * allocation for this crew.
     * @param targetProperties
     * @returns {{ParentId: number, JobActivity: {Id: (* | number)}, CurrentLifespan: {Start: string, End: string}, Id: number, Resources: {Id: *}[]}}
     * @private
     * @param supplier
     * @param toolBarOptions
     * @param selected
     *
     */
    pasteSupplierOnCell({ selectedItemProperties, targetProperties, supplier, toolBarOptions, selected }) {
        if (targetProperties.ObjectType === 'Crew') {
            this.handleOpenEditor('EditCxAllocation', {
                open: true,
                initialValues: {
                    Job: {
                        Id: this.targetAllocation ? this.targetAllocation.Job.Id : 0,
                        _DisplayName: this.targetAllocation ? this.targetAllocation.Job.DisplayName : ''
                    },
                    JobActivity: {
                        Id: this.targetAllocation ? this.targetAllocation.JobActivity.Id : 0,
                        _DisplayName: this.targetAllocation ? this.targetAllocation.JobActivity.DisplayName : ''
                    },
                    // Name: supplier.DisplayName,
                    Crew: {
                        Id: targetProperties.Resource.Id
                    },
                    CurrentLifespan: {
                        Start: format("yyyy-MM-dd'T'08:00:00", parseDate(targetProperties.CurrentLifespan.Start)),
                        End: flow(
                            parseDate,
                            //addDays(1),
                            format("yyyy-MM-dd'T'17:00:00")
                        )(targetProperties.CurrentLifespan.Start)
                    },
                    ResourceQuantitySpans: [
                        {
                            Supplier: {
                                Id: supplier.Id,
                                _Name: supplier.DisplayName
                            },
                            ResourceSubtype: supplier.DefaultSubType.ResourceSubtype,
                            Range: {
                                Start: format(
                                    "yyyy-MM-dd'T'08:00:00",
                                    parseDate(targetProperties.CurrentLifespan.Start)
                                ),
                                End: flow(
                                    parseDate,
                                    //addDays(1),
                                    format("yyyy-MM-dd'T'17:00:00")
                                )(targetProperties.CurrentLifespan.Start)
                            },
                            Amount: 1,
                            _ResourceType: supplier.DefaultSubType.ResourceSubtype._ResourceType,
                            ResourceIds: []
                        }
                    ]
                },
                formQueryValues: {
                    filters: [{ name: 'Id', values: ['0'] }]
                }
            });
        }
        return {
            methodsByItem: [],
            items: []
        };
    }

    /**
     *  Shift allocations for crews.
     * @param daysToShift - number of days to shift.
     * @param shiftAll - shift all allocations across resources
     * @param selectedItems - list of  allocations, and taskDays to shift.
     */
    shiftAllocation({ daysToShift, shiftAll, selectedItems }) {
        const shift = ({ selectedItem, daysToShift }) => {
            const { selectedItemProperties, item } = selectedItem;
            const methods = [
                {
                    name: 'move',
                    args: {
                        source: format(
                            "yyyy-MM-dd'T'00:00:00",
                            parseDate(selectedItemProperties.CurrentLifespan.Start)
                        ),
                        daysToShift: daysToShift
                        //resourceIds: resourceIds
                    }
                }
            ];

            // get all future allocations that contain the resource
            return {
                filters: [
                    {
                        name: 'Range',
                        values: [
                            format("yyyy-MM-dd'T'00:00:00", parseDate(selectedItemProperties.CurrentLifespan.Start)),
                            '2999-01-01T00:00:00'
                        ]
                    },
                    {
                        name: 'CrewId',
                        values: [item.Crew.Id]
                    }
                ],

                methodsByItem: [
                    {
                        indices: [],
                        methods: methods
                    }
                ],
                items: []
            };
        };

        // create a unique list of crew ids.
        const selectedItemsByCrewId = flow(
            filter((selectedItem) => get('item.Crew.Id', selectedItem)),
            sortBy(['item.Crew.Id', 'selectedItemProperties.CurrentLifespan.Start']),
            uniqBy((selectedItem) => get('item.Crew.Id', selectedItem))
        )(selectedItems);
        selectedItemsByCrewId.forEach((selectedItem) => {
            const v = shift({
                selectedItem: selectedItem,
                daysToShift: daysToShift
            });
            this.save('CxAllocation', v.items, v.methodsByItem, v.filters).catch((error) => console.log(error));
        });
    }
}

export class CellDragDrop extends CellDragDropMixin(DragDrop) {}
export class AllocationDragDrop extends AllocationDragDropMixin(DragDrop) {}
export class Actions extends ActionsMixin(DragDrop) {}
