import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export const JobStart = ({ row }) => {
    return (
        <Typography fontSize="small" component="div">
            {row.JobStart}
        </Typography>
    );
};

JobStart.propTypes = {
    row: PropTypes.object.isRequired
};
