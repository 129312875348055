/**
 * Created by mark on 1/3/2019.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Field, LookupValueEditor } from 'client-shared/components/form';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxSubcontractor';
import inputModel from 'cb-schema/inputmodelfull/CxSubcontractor';
import {
    EmailEditor,
    emailValidationSchema,
    PhoneEditor,
    phoneValidationSchema,
    AddressEditor,
    addressValidationSchema,
    ContactEditor,
    contactValidationSchema,
    CurrentLifespanEditor,
    currentLifespanValidationSchema
} from 'client-shared/components/form';
import { object, string, number } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import { Supplytypeseditor, supplytypesValidationSchema } from './containers/Supplytypeseditor';
import cloneDeep from 'lodash/cloneDeep';
import { UserContext } from 'client-shared/utility';
import format from 'date-fns/fp/format';
import { get } from 'lodash/fp';

// initialize model properties.
const validationSchema = object({
    CompanyType: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Emails: emailValidationSchema,
    PhoneNumbers: phoneValidationSchema,
    Address: addressValidationSchema,
    Contacts: contactValidationSchema,
    SupplyTypes: supplytypesValidationSchema,
    CurrentLifespan: currentLifespanValidationSchema
});

const Form = (props) => {
    const { classes, data } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Field
                    type="text"
                    label="Type"
                    name="CompanyType"
                    component={LookupValueEditor}
                    className={classes.selectField}
                    objectType="CompanyType"
                />
                <Field
                    type="text"
                    label="Subcontractor #"
                    name="AccountCode"
                    component={TextField}
                    className={classes.textField}
                />
                <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
                <Field type="checkbox" Label={{ label: 'Preferred' }} name="Preferred" component={CheckboxWithLabel} />
                <div>
                    <Field
                        type="color"
                        label="ForeColor"
                        name="ForeColor"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="color"
                        label="BackColor"
                        name="BackColor"
                        component={TextField}
                        className={classes.textField}
                    />
                </div>
                <div>
                    <Field type="text" name="CurrentLifespan" component={CurrentLifespanEditor} classes={classes} />
                </div>
                <Field type="text" label="Emails" name="Emails" component={EmailEditor} classes={classes} />
                <Field type="text" label="Phone #" name="PhoneNumbers" component={PhoneEditor} classes={classes} />
                <Field type="text" label="Address" name="Address" component={AddressEditor} classes={classes} />
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
                <Field type="text" label="Contacts" name="Contacts" component={ContactEditor} classes={classes} />
                <Field
                    type="text"
                    label="SupplyTypes"
                    name="SupplyTypes"
                    component={Supplytypeseditor}
                    classes={classes}
                    resourceSubtypes={data.cxResourceSubtypes}
                />
            </form>
        </React.Fragment>
    );
};

export const EditCxSubcontractor = ({ open, formQueryValues, onClose }) => {
    const [user] = useContext(UserContext);
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.CompanyType = { Id: '0', _DisplayName: '' };
    initialValues.Address.Autocode = true;
    initialValues.Address.State = get('congistics.defaultCompany.Address.State', user);
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.End = '2999-01-01T00:00:00';
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    return (
        <DialogForm
            open={open}
            title="Subcontractor"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxSubcontractor"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxSubcontractor.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
