import { gql } from '@apollo/client';
import { get } from 'lodash/fp';

export const OPTIONSCACHE = 'toolBarOptions';
export const SCHEDULECACHE = 'scheduleCacheOfSelectedItems';
export const TOOLBARCACHE = 'toolbarCacheOfSelectedItems';
export const ROUTECACHE = 'routeCacheOfSelectedItems';
export const GRIDCACHE = 'gridCacheOfSelectedItems';
export const HEADERCACHE = 'headerCacheOfSelectedItems';
export const CONFLICTCACHE = 'conflictIds';

// handle Map in json stringify
// function replacer(key, value) {
//     if (value instanceof Map) {
//         return {
//             dataType: 'Map',
//             value: Array.from(value.entries()) // or with spread: value: [...value]
//         };
//     } else {
//         return value;
//     }
// }
//
// // handle Map in json parse
// function reviver(key, value) {
//     if (typeof value === 'object' && value !== null) {
//         if (value.dataType === 'Map') {
//             return new Map(value.value);
//         }
//     }
//     return value;
// }

const _query = (key) => gql`
    query {
        ${key} @client {
            Id
            data
        }
    }
`;

export const watchCache = (client, key) => {
    return client.watchQuery({ query: _query(key), fetchPolicy: 'cache-only' });
};

// export const readCache = (client, key, initialData) => {
//     const _initialData = {
//         [key]: { Id: key, data: JSON.stringify(initialData, replacer), __typename: key }
//     };
//     let data;
//     try {
//         data = client.readQuery({ query: _query(key), fetchPolicy: 'cache-only' });
//     } catch (error) {
//         client.writeQuery({
//             query: _query(key),
//             data: _initialData
//         });
//         data = client.readQuery({ query: _query(key) });
//         return initialData;
//     }
//     if (!data) return [];
//     return JSON.parse(get('data', data[key]), reviver);
// };
//
// export const writeCache = (client, key, data) => {
//     const _data = {
//         [key]: { Id: key, data: JSON.stringify(data, replacer), __typename: key }
//     };
//
//     client.writeQuery({
//         query: _query(key),
//         data: _data,
//         fetchPolicy: 'cache-only'
//     });
//     return data;
// };

export const readCache = (client, key, initialData) => {
    const _initialData = {
        [key]: { Id: key, data: initialData, __typename: key }
    };
    let data;
    try {
        data = client.readQuery({ query: _query(key), fetchPolicy: 'cache-only' });
    } catch (error) {
        client.writeQuery({
            query: _query(key),
            data: _initialData
        });
        //data = client.readQuery({ query: _query(key) });
        return initialData;
    }
    if (!data) return [];
    return get('data', data[key]);
};

export const writeCache = (client, key, data) => {
    const _data = {
        [key]: { Id: key, data: data, __typename: key }
    };

    client.writeQuery({
        query: _query(key),
        data: _data,
        fetchPolicy: 'cache-only'
    });
    return data;
};
