import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field, LookupValueEditor, UnitCostEditor, unitCostValidationSchema } from '../index';
import { array, number, object } from 'yup';
import { Section } from 'client-shared/components/Section';
import Stack from '@mui/material/Stack';

const rates = {
    Id: 0,
    RateType: { Id: 0, _DisplayName: '' },
    UnitCost: {
        Unit: { Id: '3', _DisplayName: 'hour' },
        Cost: {
            Unit: { Id: '12', _DisplayName: 'dollar' }
        }
    }
};

export const ratesValidationSchema = array().of(
    object({
        RateType: object({
            Id: number().moreThan(0, 'Required')
        }),
        UnitCost: unitCostValidationSchema
    })
);

export const RatesEditor = ({ form, field, classes, type, children, label, ...other }) => {
    return (
        <Section title={label}>
            <FieldArray
                name={field.name}
                render={(arrayHelpers) => {
                    return (
                        <React.Fragment>
                            {field.value.map((contact, index) => (
                                <Stack key={index} direction="row" spacing={2}>
                                    <Field
                                        type="text"
                                        label={label}
                                        name={`${field.name}.${index}.RateType`}
                                        component={LookupValueEditor}
                                        className={classes.selectField}
                                        objectType="RateType"
                                        initialize={false}
                                    />
                                    <Field
                                        type="text"
                                        label="Unit Cost"
                                        name={`${field.name}.${index}.UnitCost`}
                                        component={UnitCostEditor}
                                        classes={classes}
                                    />
                                    <div>
                                        <button type="button" onClick={() => arrayHelpers.remove(index)}>
                                            -
                                        </button>
                                    </div>
                                </Stack>
                            ))}
                            <button
                                type="button"
                                onClick={() => {
                                    arrayHelpers.push(rates);
                                }}
                            >
                                +
                            </button>
                        </React.Fragment>
                    );
                }}
            />
        </Section>
    );
};

RatesEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};
