import { gql } from 'graphql-tag';
import { CxTimeEntryFragment } from 'client-shared/utility';
import { TagFragment, CustomPropertyFragment } from 'client-shared/utility/fragments';

export const FormQuery = gql`
    ${CxTimeEntryFragment}
    ${TagFragment}
    query ($filters: [FilterInput], $methodsByItem: [methodsByItemInput]) {
        cxTimeEntrys(filters: $filters, methodsByItem: $methodsByItem) {
            ...CxTimeEntryFragment
            Tags {
                ...TagFragment
            }
        }
        cxCustomPropertys(objectType: "CustomProperty", filters: [{ name: "SupportedTypes", values: ["TimeEntry"] }]) {
            ${CustomPropertyFragment}
        }
    }
`;
