/**
 * Created by mark on 1/22/2020.
 */
import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { jobEvent as dataEventQuery } from 'client-shared/gqlhelpers/dataEventQueries';
import format from 'date-fns/fp/format';
import { UserContext } from '../../utility';
import { get } from 'lodash/fp';
import { gridQuery as dataQuery } from '../../utility/timekeepingHelpers';
const { object, number, date } = require('yup');

const validationSchema = object({
    _start: date().required('Required'),
    _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(61, 'Must be less that 61 days')
});

export const Filter = (other) => {
    const [user] = useContext(UserContext);
    let initialValues = {
        _start: format('yyyy-MM-dd', new Date()),
        _periods: 8,
        manager_id: [get('congistics.user.Party.Id', user)],
        scheduled: 'Scheduled',
        range: [],
        range__child__timesheets: [],
        range__child__taskdays: []
    };

    const fieldDefinitions = [
        {
            type: 'text',
            label: 'Name',
            name: 'string__displayName',
            component: 'TextField',
            className: 'shortTextField'
        },
        {
            type: 'text',
            name: 'scheduled',
            component: 'ScheduledEditor',
            initialValue: 'All'
        },
        {
            type: 'text',
            label: 'Status',
            name: 'jobStatus_id',
            component: 'LookupValueEditor',
            className: 'shortSelectField',
            multiple: true,
            initialValue: [],
            objectType: 'JobStatus'
        },
        {
            type: 'date',
            label: 'Start',
            name: '_start',
            component: 'TextField',
            className: 'shortTextField',
            initialValue: format('yyyy-MM-dd', new Date())
        },
        {
            type: 'number',
            label: 'Days',
            name: '_periods',
            component: 'TextField',
            className: 'shortTextField',
            initialValue: 7
        },
        {
            type: 'text',
            label: 'Manager',
            name: 'manager_id',
            component: 'AutoComplete',
            className: 'selectField',
            optionsQuery: `
                query ($searchString: String) {
                    cxJobs(
                        objectType: "Job"
                        filters: [{ name: "String", values: ["Manager.DisplayName", $searchString] }]
                    ) {
                        Manager {
                            Id
                            DisplayName
                        }
                    }
                }
            `,
            multiple: true,
            initialValue: [],
            getOptions: (options) => {
                return options.map((option) => ({
                    Id: option.Manager.Id,
                    DisplayName: option.Manager.DisplayName
                }));
            },
            optionLabelProperty: 'DisplayName'
        }
    ];

    return (
        <FilterFormFromJson
            filterFormDefinition={{
                title: 'Timekeeping',
                dataQuery,
                validationSchema,
                dataEventQueries: [dataEventQuery],
                onData: (data, values, c, rawData) => {
                    return data;
                },
                fieldDefinitions,
                initialValues,
                enableShowAll: false
            }}
            {...other}
        />
    );
};
