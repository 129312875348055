/**
 * Created by mark on 1/22/2020.
 */
import React from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { timeEntryEvent as dataEventQuery } from 'client-shared/gqlhelpers/dataEventQueries';
import format from 'date-fns/fp/format';
import { map } from 'lodash/fp';
const { object, number, date } = require('yup');

const dataQuery = `
    query ( $filters: [FilterInput]) {
    cxTimeEntrys (filters: $filters) {
        Id
        Hours
        ApprovedByOwner
        IsVoid
        Owner{
            Id
        }
        JobActivity {
            Job {
                Id
                AccountCode
                DisplayName
                JobCosts {
                        Id
                        DisplayName
                    }
                }
            Id
        DisplayName
        }
        Party {
            Id
            DisplayName
        }
        Approvals {
            AsOf
            Approver {
                Id
                DisplayName
             }
            }
        EntryType {
            Id
            DisplayName
            BackColor
        }
        JobCosts {
            Id
            DisplayName
        }
        CurrentLifespan {
            Start
            End
         }
        }
    }
`;

const validationSchema = object({
    _start: date().required('Required'),
    _periods: number().moreThan(0, 'Must be more than 0 days')
});

const filterFormDefinition = {
    title: 'Time Entry Approval',
    dataQuery,
    validationSchema,
    dataEventQueries: [dataEventQuery],
    fieldDefinitions: [
        {
            type: 'date',
            label: 'Start',
            name: '_start',
            component: 'TextField',
            className: 'shortTextField',
            initialValue: format('yyyy-MM-dd', new Date())
        },
        {
            type: 'number',
            label: 'Days',
            name: '_periods',
            component: 'TextField',
            className: 'shortTextField',
            initialValue: 7
        },
        {
            type: 'text',
            label: 'Resources',
            name: 'party_id',
            component: 'AutoComplete',
            className: 'selectField',
            optionsQuery: `
                          query CxEmployeesQuery($searchString: String) {
                            cxEmployees(objectType: "Employee", filters: [{ name: "String", values: ["Name", $searchString] }]) {
                                Person {
                                    Id
                                 DisplayName
                                }
                            }
                        }
                    `,
            minLength: 0,
            multiple: true,
            getOptions: (options) => {
                return map((option) => ({ Id: option.Person.Id, _DisplayName: option.Person.DisplayName }), options);
            },
            initialValue: []
        }
    ]
};

export const Filter = (other) => (
    <FilterFormFromJson
        filterFormDefinition={{
            ...filterFormDefinition,
            initialValues: {
                _start: format('yyyy-MM-dd', new Date()),
                _periods: 7,
                HasItems: 'Approvals',
                range: []
            }
        }}
        {...other}
    />
);
