import React from 'react';
import PropTypes from 'prop-types';
import { useSelected } from 'client-shared/utility';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import get from 'lodash/fp/get';

export const DisplayName = ({ item, selected }) => {
    return (
        <Typography
            component="div"
            fontSize="small"
            sx={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-line',
                margin: 0,
                padding: 0,
                '&::first-line': {
                    color: get('ForeColor', item) || '#000000',
                    backgroundColor: selected ? 'Gainsboro' : get('BackColor', item) || '#FFFFFF'
                }
            }}
        >
            {item.DisplayName}
        </Typography>
    );
};

// display and select items.
export const SelectableItem = ({ cacheName, selectedItemProperties, item, component = DisplayName }) => {
    const [selected, toggleSelected] = useSelected([item], cacheName);
    const handleSelected = (item) => {
        toggleSelected([item], selectedItemProperties);
    };

    return (
        <Box component="span" sx={{ cursor: 'pointer', margin: 0, padding: 0 }} onClick={() => handleSelected(item)}>
            {component({ item, selected })}
        </Box>
    );
};

SelectableItem.propTypes = {
    cacheName: PropTypes.string.isRequired,
    selectedItemProperties: PropTypes.object,
    item: PropTypes.object.isRequired,
    component: PropTypes.func
};
