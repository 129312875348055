import { curry, get, contains } from 'lodash/fp';
import saveAs from 'file-saver';
import qs from 'query-string';

/**
 * Takes the current server location
 * http(s)://hey.com and returns
 * ws(s)://hey.com/graphql
 * @param href
 * @returns {string}
 */
export function makeGraphqlUrl(href) {
    if (process.env.REACT_APP_SERVER) return process.env.REACT_APP_SERVER;
    let url = new URL('/graphql', href);
    url.protocol = url.protocol.replace('http', 'ws');
    return url.href;
}

/**
 * Error handler for fetch calls.  Expects the error message to be json
 * returned in the response
 * @param response
 * @returns {Promise<unknown>}
 */
export const handleFetchErrors = (response) => {
    if (response.ok) return response;

    return response.json().then((e) => {
        throw e;
    });
};

export const getQueryParam = (k, location) => get(k, qs.parse(get('search', location)));

export const toJson = (res) => res.json();
export const toBlob = (res) => res.blob();

export const saveFile = curry((name, blob) => {
    return saveAs(blob, name);
});

export const mobilePart = () => (contains('/mobile/', window.location.pathname) ? '/mobile' : '');
