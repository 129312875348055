import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String, $filters: [FilterInput]) {
        cxLookupValues(objectType: $objectType, filters: $filters) {
            ObjectType
            Id
            Name
            Description
            ForeColor
            BackColor
            Active
            Default
        }
    }
`;
