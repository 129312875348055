import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { fieldToTextField } from 'formik-mui';
import format from 'date-fns/fp/format';
import { parseDate } from 'client-shared/utility';

/**
 * Time editor to edit only the time portion of a dateTime in YYYY-MM-DDT00:00:00 format, preserving the date value.
 * @param form
 * @param field
 * @param onChange -  optional change handler
 * @param other
 * @returns {*}
 * @constructor
 */
export const TimeEditor = ({ form, field, onChange, ...other }) => {
    const handleChange = (event) => {
        // value will be blank when the user is typing a new date.
        if (event.target.value === '') {
            return null;
        }
        const date = format('yyyy-MM-dd', parseDate(field.value));
        const dateTime = format("yyyy-MM-dd'T'HH:mm:00", parseDate(`${date}T${event.target.value}`));
        form.setFieldValue(field.name, dateTime);
        if (onChange) {
            onChange(event, dateTime);
        }
    };
    // strip off the value to make this an uncontrolled component, otherwise, the date
    // field will reset itself on every keystroke.
    const { value, ...fieldToTextFieldProps } = fieldToTextField({ form, field, ...other });
    return (
        <TextField
            {...fieldToTextFieldProps}
            defaultValue={format('HH:mm', parseDate(field.value))}
            onChange={handleChange}
        />
    );
};

TimeEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func // optional change handler
};
