/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxWithLabel } from 'formik-mui';
import { Field, FileEditor, AutoComplete } from 'client-shared/components/form';
import { useTheme } from '@mui/styles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ListItemText from '@mui/material/ListItemText';

import { parseDate } from 'client-shared/utility';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxBatch';
import inputModel from 'cb-schema/inputmodelfull/CxBatch';
import { object, array } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import { get, map } from 'lodash/fp';
import cloneDeep from 'lodash/cloneDeep';
import format from 'date-fns/format';
import CxBatch from 'cb-schema/jsonschema/CxBatch';
import Typography from '@mui/material/Typography';

// initialize model properties.
const initialValues = cloneDeep(model);
initialValues['file'] = '';
const validationSchema = object({
    Attachments: array().required('Required')
});

const Form = (props) => {
    const importTypes = get('properties.ImportType.enum', CxBatch);
    const theme = useTheme();
    const { classes, values } = props;
    return (
        <React.Fragment>
            <form autoComplete="off">
                <Field
                    type="text"
                    label="Type"
                    name="ImportType"
                    component={AutoComplete}
                    className={classes.selectField}
                    options={importTypes}
                    disableClearable
                />
                <Field type="text" label="File" name="Attachments" component={FileEditor} mimeTypes=".csv, text/csv" />
                <List>
                    {map(
                        (each) => (
                            <ListItem key={each.Id}>
                                <ListItemIcon>
                                    {each.Errors.length > 0 ? (
                                        <ErrorIcon
                                            style={{
                                                fill: theme.palette.error.dark
                                            }}
                                        />
                                    ) : (
                                        <CheckCircleIcon
                                            style={{
                                                fill: theme.palette.success.dark
                                            }}
                                        />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={each.SummaryMessage}
                                    secondary={
                                        <React.Fragment>
                                            <ul>
                                                {map(
                                                    (_e) => (
                                                        <li>{_e.Message}</li>
                                                    ),
                                                    each.Errors
                                                )}
                                            </ul>
                                            <Typography component="div" variant="body2" color="textPrimary">
                                                {format(parseDate(each.End), 'yyyy-MM-dd hh:mm')}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        ),
                        values.RecentRuns
                    )}
                </List>
                <Field Label={{ label: 'Overwrite Existing' }} name="Overwrite" component={CheckboxWithLabel} />
            </form>
        </React.Fragment>
    );
};

export const EditCxBatch = ({ open, formQueryValues, onClose }) => {
    return (
        <DialogForm
            open={open}
            title="Import"
            validationSchema={validationSchema}
            Form={Form}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxBatch"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxBatch.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
