import { gql } from '@apollo/client';
import React, { useEffect, useState, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { map, get, reject, isNil, pipe, curry, set, groupBy, values } from 'lodash/fp';
import { pipeP, zipLists } from 'global-shared/utils/utils';
import { ObjectsMapped } from './index';
import { UserContext } from 'client-shared/utility';
import { v4 } from 'uuid';

export const LocateEntities = ({ data, filter }) => {
    const [mappable, setMappable] = useState([]);
    const [user] = useContext(UserContext);
    const client = useApolloClient();

    const items = map(remap(get('congistics.configValues.equipment_correlator', user)), data);
    const localData = doLocal(items);

    const chooseMapping = curry((filter, localItems, remoteItems) => {
        /* eslint-disable default-case*/
        switch (filter) {
            case 'schedule':
                return setMappable(aggregateItems(localItems));
            case 'gps':
                return setMappable(aggregateItems(remoteItems));
            case 'both':
                return setMappable(aggregateItems(zipLists('Id', localItems, remoteItems)));
        }
    });

    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps*/
        pipeP(
            fetchData,
            get('trackEntities'),
            reject((each) => isNil(get('Location', each))),
            chooseMapping(filter, localData)
        )();
    }, [data]);

    const fetchData = async (_) => {
        const result = await client.query({
            query: dataQuery,
            fetchPolicy: 'network-only',
            variables: { items }
        });
        return result.data;
    };
    return <ObjectsMapped data={mappable} initial={[doInitial(user)]} />;
};
const remap = curry((key, payload) => {
    const typeMap = {
        CxEquipment: 'AccountCode'
    };
    key = key || typeMap[get('__typename', payload)];
    const Id = get(key, payload);

    return {
        Id,
        payload
    };
});

const dataQuery = gql`
    query trackEntities($items: [TrackableQuery]) {
        trackEntities(items: $items) {
            Id
            Location {
                Lat
                Lng
            }
            payload
        }
    }
`;

const aggregateDescriptions = (_in) => {
    if (_in.length === 1) return _in[0];
    const desc = map(get('payload.mapLabel'), _in);
    return set('__typename', 'AggregateTrackableResult', set('payload.mapLabel', desc, _in[0]));
};

const aggregateItems = pipe(
    groupBy((each) => `${each.Location.Lat}_${each.Location.Lng}`),
    values,
    map(aggregateDescriptions)
);

const doInitial = (user) => ({
    Id: v4(),
    Location: {
        Lat: get('congistics.defaultCompany.Address.Geocode.Lat', user),
        Lng: get('congistics.defaultCompany.Address.Geocode.Lng', user),
        when: {
            datetime: new Date().toISOString()
        }
    },
    __typename: 'TrackableResult',
    payload: {
        mapLabel: get('congistics.defaultCompany.Name', user)
    }
});

const doLocal = pipe(
    reject((each) => 0 === get('payload.Location.Address.Geocode.Lat', each)),
    map((each) => ({
        ...each,
        Location: {
            Lat: get('payload.Location.Address.Geocode.Lat', each),
            Lng: get('payload.Location.Address.Geocode.Lng', each),
            when: {
                datetime: new Date().toISOString()
            }
        },
        __typename: 'TrackableResult'
    }))
);
