import { gql } from '@apollo/client';
import { curry, get, first, map, pipe, join } from 'lodash/fp';
import upperFirst from 'lodash/upperFirst';
import { cleanObject, omitEmptyProperties, pickFromTemplate } from '../utility/pickfromtemplate.js';
import { pipeP } from 'global-shared/utils/utils';

export const getGraphqlHelper = (clazz) => {
    if (clazz) return require(`cb-schema/minimalinputfragment/${clazz}`);
};

export const getJsonSchema = (clazz) => {
    if (clazz) return require(`cb-schema/jsonschema/${clazz}.json`);
};

export const trimToInputModel = curry((clazz, _in) => {
    if (!_in) return;
    if (!clazz) clazz = `Cx${_in.ObjectType}`;
    /* eslint-disable no-throw-literal */
    if (!clazz) throw 'No clazz specified';

    return pickFromTemplate(_in, require(`cb-schema/inputmodel/${clazz}.json`));
});

export const hasProperty = (type, propName) => {
    const jsonSchema = getJsonSchema(type);
    return get(`properties.${propName}`, jsonSchema);
};

/**
 * Pass in an object with an ID and a generated helper.
 */
export const mutateOne = curry(async (client, helper, fragments, payload) => {
    return pipeP(
        get('Id'),
        fetchMinimalViableEntity(client, helper, fragments),
        cleanObject,
        (_in) => ({ ..._in, ...payload }),
        mutateEntity(client, helper, fragments),
        get(['data', helper.saveMutation]),
        first,
        cleanObject
    )(payload);
});

export const isData = ({ loading, error, data }) => {
    if (loading) {
        return false;
    }
    if (error) {
        return false;
    }
    return Object.keys(data).length;
};

export const fetchMinimalViableEntity = curry(async (client, helper, fragments, id) => {
    const { fragmentName, fragment, query, objectType } = helper;
    const res = await _fetchEntity(client, [{ fragmentName, fragment }, ...fragments], query, objectType, {
        filters: [{ name: 'Id', values: [`${id}`] }]
    });
    return pipe(get(['data', query]), first)(res);
});

export const fetchMinimalEntity = curry(async (client, helper, fragments, id) => {
    const { query, objectType, entityClass } = helper;
    const res = await _fetchEntity(client, [makeMinimalFragment(entityClass), ...fragments], query, objectType, {
        filters: [{ name: 'Id', values: [`${id}`] }]
    });
    return pipe(get(['data', query]), first)(res);
});

export const fetchMinimalEntityFiltered = curry(async (client, helper, fragments, filters, id) => {
    const { query, objectType, entityClass } = helper;
    const res = await _fetchEntity(client, [makeMinimalFragment(entityClass), ...fragments], query, objectType, {
        filters: [{ name: 'Id', values: [`${id}`] }, ...filters]
    });
    return pipe(get(['data', query]), first)(res);
});

export const fetchLookupEntities = curry(async (client, helper, fragments, id) => {
    const { fragmentName, fragment, query, objectType } = helper;
    const res = await _fetchEntity(client, [{ fragmentName, fragment }, ...fragments], query, objectType, {
        filters: [{ name: 'Id', values: [`${id}`] }]
    });
    return pipe(get(['data', query]), first)(res);
});

export const _fetchEntity = async (client, fragments, query, objectType, vars) =>
    client.query({
        query: fetchQuery(fragments, query, objectType),
        fetchPolicy: 'network-only',
        variables: vars
    });

/**
 * @param client
 * @param fetchObject - the shape of the returned data, defaults to
 *        {Id
 *        ObjectType
 *        }
 *
 *
 * @param objectName - name of graphql interface such as CxJob, CxLookupValue
 * @param data
 * @param methodsByItem
 * @param filters
 * @param objectType - optional type of object Job, JobSubType, other. CxLookupValue can have many objectTypes even though
 * the objectName if CxLookupValue. For all other objects, the objectType can be derived from the objectName so this
 * parameter is optional.
 * @returns {Promise <?| void>}
 */
export const evalQuery = async (
    client,
    fetchObject,
    objectName,
    data = [],
    methodsByItem = [],
    filters = [],
    objectType
) => {
    if (!get('length', data) && !get('length', methodsByItem)) {
        return Promise.resolve();
    }
    const operation = 'edit';
    const objectNameBase = objectName.slice(2);
    const mutationName = `cx${objectNameBase}s_onSave`;
    const inputType = `Cx${upperFirst(objectNameBase)}Input`;
    objectType = objectType || objectNameBase;

    const fetchedObject =
        fetchObject ||
        `
             Id
            ObjectType
    `;

    const mutation = gql`
        mutation x (
            $operation: String,
            $objectType: String,
            $methodsByItem: [methodsByItemInput],
            $filters: [FilterInput],
            $data: [${inputType}]
        ) {
            ${mutationName} (
            operation: $operation,
            objectType: $objectType,
            methodsByItem: $methodsByItem,
            filters: $filters,
            data: $data
            ) {
             ${fetchedObject}
        }
        }
    `;
    return client.mutate({
        mutation,
        variables: {
            operation,
            objectType,
            filters,
            methodsByItem,
            data
        }
    });
    //.catch(pLog); // error needs to bubble up
};

export const deleteQuery = curry(async (client, objectName, objectType, data) => {
    const operation = 'delete';
    const objectNameBase = objectName.slice(2);
    const mutationName = `cx${objectNameBase}s_onDelete`;
    objectType = objectType || objectNameBase;

    const mutation = gql`
        mutation x (
            $operation: String,
            $objectType: String,
            $data: [String]
        ) {
            ${mutationName} (
            operation: $operation,
            objectType: $objectType,
            data: $data
            ) {
            Id
            ObjectType
        }
        }
    `;
    return client.mutate({
        mutation,
        variables: {
            operation,
            objectType,
            data
        }
    });
});

export const mutateEntity = curry((client, helper, fragments, payload) => {
    const { fragmentName, fragment, saveMutation, clazz } = helper;
    return _mutateEntity(client, [{ fragmentName, fragment }, ...fragments], saveMutation, clazz, {
        objectType: helper.objectType,
        data: [payload],
        operation: 'edit'
    });
});

export const _mutateEntity = (client, fragments, queryName, inputType, vars) =>
    client.mutate({
        mutation: editMutation(fragments, queryName, inputType),
        fetchPolicy: 'no-cache',
        variables: vars
    });

export const fetchQuery = (
    fragments,
    queryName,
    inputType
) => gql`query ($objectType: String = "${inputType}", $filters: [FilterInput]) {
        ${queryName}(objectType: $objectType, filters: $filters) {
        ${templateFragmentNames(fragments)}
        }
    }
    ${templateFragments(fragments)}
   `;

export const inlineFetchQuery = (
    queryName,
    inputType,
    fragment
) => gql`query ($objectType: String = "${inputType}", $filters: [FilterInput]) {
    ${queryName}(objectType: $objectType, filters: $filters) {
        ${fragment}
    }
}
`;

export const editMutation = (fragments, queryName, inputType) => gql`mutation ($objectType: String, 
    $data: [${inputType}], $operation: String="edit") {
    ${queryName}(objectType: $objectType, data: $data, operation: $operation) {
          ${templateFragmentNames(fragments)}
    }
}
    ${templateFragments(fragments)}
`;

export const makeSetOperation = ({ ids, properties, preserve }) => {
    if (!preserve) properties = omitEmptyProperties(properties);
    const methods = [
        {
            name: 'set',
            args: {
                properties
            }
        }
    ];
    return {
        filters: [
            {
                name: 'Id',
                values: ids.map((id) => id.toString())
            }
        ],
        methodsByItem: [
            {
                indices: [],
                methods: methods
            }
        ],
        items: []
    };
};

/**
 * A more general version of the makeSet function
 * @param method
 * @param ids
 * @param properties
 * @param preserve
 * @returns {{filters: [{values: *, name: string}], methodsByItem: [{indices: *[], methods: [{args: {properties}, name}]}], items: *[]}}
 */
export const makeVerbOperation = (method, { ids, args }) => {
    const methods = [
        {
            name: method,
            args
        }
    ];
    if (!ids) ids = [];
    return {
        filters: [
            {
                name: 'Id',
                values: ids.map((id) => id.toString())
            }
        ],
        methodsByItem: [
            {
                indices: [],
                methods: methods
            }
        ],
        items: []
    };
};

export const templateFragmentNames = pipe(
    map('fragmentName'),
    map((each) => `... ${each} `),
    join(' ')
);

export const makeMinimalFragment = (on) => ({
    fragmentName: 'MINIMAL',
    fragment: `fragment MINIMAL on ${on} {
      Id
      DisplayName
    }`
});

export const templateFragments = pipe(map('fragment'), join(' '));
