import { gql, useApolloClient } from '@apollo/client';
import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles, useTheme } from '@mui/styles';
import { GroupingState, IntegratedGrouping, IntegratedSummary, SummaryState } from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableFixedColumns,
    TableGroupRow,
    TableSummaryRow
} from '@devexpress/dx-react-grid-material-ui';
import {
    get,
    values,
    fromPairs,
    pick,
    omit,
    map,
    pipe,
    find,
    groupBy,
    toPairs,
    flatten,
    sortBy,
    identity,
    pickBy,
    isEmpty,
    nth,
    sum,
    sumBy,
    first,
    reject,
    getOr
} from 'lodash/fp';
import { cbDateToDhtmlx, setF, transformPropertyArray, groupV, pipeP, pLog } from 'global-shared/utils/utils';
import { Filter } from './Filter';
import format from 'date-fns/fp/format';
import {
    useQuery,
    cleanObject,
    getRange,
    Mutation,
    plusDays,
    parseDate,
    usePrivilege,
    UserContext
} from 'client-shared/utility';
import { TimeEntryLegend, ChipMe } from 'client-shared/components/timekeeping/TimeEntryLegend';
import { Detail, useDetail } from './Detail';
import { evalQuery, trimToInputModel } from 'client-shared/gqlhelpers/queryHelpers';
import { useNotifications } from 'client-shared/components/notifier2';

const query = gql`
    query ($objectType: String = "LookupValue", $filters: [FilterInput]) {
        cxLookupValues(objectType: $objectType, filters: $filters) {
            Id
            BackColor
            Name
            __typename
        }
    }
`;

export const approveTimeEntries = async (client, entries, partyId) => {
    await evalQuery(client, null, 'CxTimeEntry', entries, [
        {
            indices: [],
            methods: [{ name: 'approve', args: { partyIds: [partyId] } }]
        }
    ]);
};

export const unapproveTimeEntries = async (client, entries, partyId) => {
    await evalQuery(client, null, 'CxTimeEntry', entries, [
        {
            indices: [],
            methods: [{ name: 'unapprove', args: { partyIds: [partyId] } }]
        }
    ]);
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw'
    },
    carsChip: {
        margin: theme.spacing(0.5)
    },
    headerColumn: {
        flexBasis: '100%',
        textAlign: 'center'
    },
    heading: {
        fontSize: theme.typography.pxToRem(18)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    }
}));

const EntryPoint = () => {
    const [user] = useContext(UserContext);
    return (
        <React.Fragment>
            <Filter>
                {({ values: variables, data: timeEntries }) => {
                    return (
                        <InnerTable {...{ variables, timeEntries, partyId: get('congistics.user.Party.Id', user) }} />
                    );
                }}
            </Filter>
        </React.Fragment>
    );
};
export default EntryPoint;

const InnerTable = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const client = useApolloClient();
    const [showDetail, toggleDetail] = useDetail();
    const model = useQuery(query, { objectType: 'TimeEntryType' });
    const [privilege] = usePrivilege('TimeSheet');
    const { openSBTransientF } = useNotifications();

    if (model.loading) return 'Loading...';
    if (model.error) return 'Error...';

    const messages = {
        sums: 'Total'
    };

    const summaryCalculator = (type, rows, getValue) => {
        if (type === 'sum') {
            if (!rows.length) return 0;
            return pipe(map(getValue), map(get('Hours')), sum)(rows) || 0;
        }

        return IntegratedSummary.defaultCalculator(type, rows, getValue);
    };

    const mutator = new Mutation(client);

    const { timeEntries, variables, partyId } = props;
    const range = [variables._start, plusDays(variables._periods, variables._start)];

    const columns = pipe(
        getRange,
        map((each) => ({ name: each, title: each })),
        (_in) => [{ name: 'employee', title: 'Employee' }, ..._in] //{ name: 'total', title: 'Total' }
    )(range[0], variables._periods);

    const summaryCols = pipe(
        getRange,
        map((each) => ({ columnName: each, type: 'sum' }))
    )(range[0], variables._periods);

    const xtensions = map(
        (each) =>
            each.name === 'employee'
                ? { columnName: each.name, width: '30%' }
                : { columnName: each.name, align: 'center', wordWrapEnabled: true },
        columns
    );

    const HighlightedCell = ({ value, style, column, row, ...restProps }) => {
        let _style = {
            backgroundColor: get('EntryType.BackColor', value),
            cursor: value ? 'pointer' : 'default'
        };

        const voidStyle = {
            'text-decoration': 'line-through'
        };

        if (get('IsVoid', value)) _style = Object.assign(_style, voidStyle);

        return (
            <Table.Cell
                {...restProps}
                onClick={() =>
                    value &&
                    !privilege &&
                    toggleDetail(mutate, {
                        row,
                        column,
                        value
                    })
                }
                style={{
                    ..._style,
                    ...style
                }}
            >
                {get('Id', value)
                    ? `${get('Hours', value)} hours 
                ${get('JobActivity.Job.DisplayName', value)} ${getOr('', 'JobCosts.0.DisplayName', value)} `
                    : '-'}
            </Table.Cell>
        );
    };

    const Cell = (props) => {
        const { column } = props;
        if (column.name !== 'employee') {
            return <HighlightedCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    const constructRows = (timeSheets) => {
        return pipe(groupBy('Party.Id'), values, map(makeRow), flatten)(timeSheets);
    };

    const resourceTotals = makeTotals(timeEntries);
    const rows = constructRows(timeEntries);
    const approvers = gatherApprovers(timeEntries);
    const myApprovals = gatherMyApprovals(partyId, approvers);
    const entriesForResource = gatherEntries(timeEntries);
    const entryTypes = groupBy('Id', get('data.cxLookupValues', model));

    const GroupCellContent = ({ row, ...restProps }) => (
        <TableGroupRow.Content {...restProps}>
            {row.value} &nbsp;
            <ChipMe {...{ entryTypes, resourceTotals }} value={row.value} />
            <span style={{ paddingLeft: '10px' }}>
                <strong> Approved by: </strong>
            </span>
            {map(
                (o) => (
                    <Tooltip key={o.Approver.Id} title={o.AsOf}>
                        <span style={{ marginRight: '5px' }}>
                            {o.Approver.DisplayName}: {format('MMM dd', parseDate(o.AsOf))}
                        </span>
                    </Tooltip>
                ),
                get(row.value, approvers)
            )}
            {!privilege && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={myApprovals[row.key]}
                            onChange={() => toggleApproval(myApprovals[row.key], row.value)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Approve"
                />
            )}
        </TableGroupRow.Content>
    );

    const timeEntryToInputObject = pipe(
        cleanObject,
        transformPropertyArray(pick('Id'), 'Approvals'),
        transformPropertyArray(pick('Id'), 'JobCosts'),
        setF('JobCosts', reject({ Id: -1 })),
        omit('ApprovedByOwner'),
        omit('Approvals'),
        omit('Owner'),
        setF('JobActivity', pick('Id')),
        setF('Party', pick('Id')),
        setF('EntryType', pick('Id'))
    );
    const mutate = (a) => {
        if (a) _mutate(a);
    };

    const toggleApproval = (currentState, resource) =>
        pipeP(
            (_in) => get(_in, entriesForResource),
            map(cleanObject),
            map(trimToInputModel('CxTimeEntry')),
            pLog,
            (_in) =>
                currentState ? unapproveTimeEntries(client, _in, partyId) : approveTimeEntries(client, _in, partyId)
        )(resource).catch(openSBTransientF({ variant: 'warning' }));

    const _mutate = (a) =>
        mutator
            .save('CxTimeEntry', [timeEntryToInputObject(a)], undefined, undefined, undefined)
            .catch(openSBTransientF({ variant: 'warning' }));

    return (
        <Paper className={classes.root}>
            <TimeEntryLegend noScheduled={true} entries={get('data.cxLookupValues', model)} />
            <Detail
                state={showDetail}
                left="Save"
                data={{ entryTypes: get('data.cxLookupValues', model) }}
                hide={toggleDetail}
            />
            <Grid rows={rows} columns={columns} getRowId={get('Id')}>
                <GroupingState grouping={[{ columnName: 'employee' }]} />
                <SummaryState groupItems={summaryCols} />
                <IntegratedGrouping messages={messages} />
                <IntegratedSummary messages={messages} calculator={summaryCalculator} />
                <Table cellComponent={Cell} columnExtensions={xtensions} />
                <TableHeaderRow />
                <TableGroupRow messages={messages} contentComponent={GroupCellContent} />
                <TableSummaryRow messages={messages} />
                <TableFixedColumns leftColumns={['employee']} />
            </Grid>
        </Paper>
    );
};

const unbucket = (_in) => {
    const f = (_in, n) =>
        pipe(
            map(([k, v]) => [k, nth(n, v)]),
            fromPairs,
            pickBy(identity),
            toPairs
        )(_in);

    const res = [];
    let i = 0;
    while (true) {
        const o = f(_in, i);
        if (isEmpty(o)) return res;
        i++;
        res.push(o);
    }
};

const makeTotals = pipe(
    groupBy((each) => get('Party.DisplayName', each)),
    toPairs,
    map(groupV('EntryType.Id')),
    map(([k, v]) => {
        const total = map(([_k, _v]) => [_k, sumBy('Hours', reject({ IsVoid: true }, _v))], v);
        return [k, total];
    }),
    fromPairs
);

const gatherApprovers = pipe(
    groupBy((each) => get('Party.DisplayName', each)),
    toPairs,
    map(([k, v]) => [k, get('Approvals', first(v))]),
    fromPairs
);

const gatherMyApprovals = (myId, _in) =>
    pipe(
        toPairs,
        map(([k, v]) => [k, !!find((each) => myId === get('Approver.Id', each), v)]),
        fromPairs
    )(_in);

const gatherEntries = pipe(groupBy((each) => get('Party.DisplayName', each)));

const makeRow = pipe(
    groupBy((each) => cbDateToDhtmlx(get('CurrentLifespan.Start', each))),
    toPairs,
    map(([k, v]) => [k, sortBy('EntryType.Id', v)]),
    unbucket,
    map((keyed) => ({
        Id: get('0.1.Id', keyed),
        employee: get('0.1.Party.DisplayName', keyed),
        ...fromPairs(keyed)
    }))
);
