/**
 * Created by mark on 2/11/17.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { flow, sortBy, keys } from 'lodash/fp';
import { groupBy, groupNameParse, MISSING } from '../utility';
const map = require('lodash/fp/map').convert({ cap: false });

export const GroupBy = ({ items, groupByCriteria, filter, children }) => {
    const groupedItems = groupBy(items, groupByCriteria, filter);
    const groupedChildren = flow(
        keys,
        sortBy([]),
        map((groupName, index) => {
            const group = groupNameParse(groupName);
            // skip the missing group. these are items that do not contain all of the properties in the groupByCriteria.
            if (group[MISSING]) {
                return null;
            }
            return (
                <React.Fragment key={groupName}>
                    {children(groupedItems[groupName], group, groupName + index)}
                </React.Fragment>
            );
        })
    )(groupedItems);

    return groupedChildren;
};

GroupBy.propTypes = {
    items: PropTypes.array.isRequired,
    groupByCriteria: PropTypes.object,
    filter: PropTypes.func
};
