/**
 * Created by mark on 1/3/2019.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Field, LookupValueEditor } from 'client-shared/components/form';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxEmployee';
import inputModel from 'cb-schema/inputmodelfull/CxEmployee';
import {
    EmailEditor,
    emailValidationSchema,
    PhoneEditor,
    phoneValidationSchema,
    AddressEditor,
    addressValidationSchema,
    ContactEditor,
    contactValidationSchema,
    DateEditor,
    CurrentLifespanEditor,
    UnitQuantityEditor,
    currentLifespanValidationSchema,
    unitQuantityValidationSchema,
    AutoComplete,
    TagEditor,
    tagValidationSchema,
    ResourceSubtypeEditor,
    TimedLookupValueEditor,
    timedLookupValueValidationSchema,
    RatesEditor,
    ratesValidationSchema
} from 'client-shared/components/form';
import { object, string, number } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { UserContext } from 'client-shared/utility';
import format from 'date-fns/fp/format';
import { AttachmentButton } from 'client-shared/components/attachments/button';
import { Section } from 'client-shared/components/Section';
import { gql } from 'graphql-tag';
import { flow, get, join, map } from 'lodash/fp';
import { addDays } from 'date-fns/fp';
import TextFieldMUI from '@mui/material/TextField';

const validationSchema = object({
    LastName: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    FirstName: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    AccountCode: string().min(1, 'Too Short').max(65, 'Too Long'),
    ResourceSubtype: object({
        Id: number().moreThan(0, 'Required')
    }),
    Owner: object({
        Id: number().moreThan(0, 'Required')
    }),
    CurrentLifespan: currentLifespanValidationSchema,
    PreviousExperience: unitQuantityValidationSchema,
    Emails: emailValidationSchema,
    PhoneNumbers: phoneValidationSchema,
    Address: addressValidationSchema,
    Contacts: contactValidationSchema,
    Tags: tagValidationSchema,
    Rates: ratesValidationSchema,
    Licenses: timedLookupValueValidationSchema,
    Unions: timedLookupValueValidationSchema
    //Divisions: array().required('Required')
});

const Form = (props) => {
    const { classes, data, values } = props;
    const crews = flow(
        map((crew) => get('DisplayName', crew)),
        join(', ')
    )(values.Crews);
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                {/*                <button onClick={() => {*/}
                {/*                    const recipientEmail = 'user@example.com';*/}
                {/*                    const emailSubject = 'Hello from JavaScript!';*/}
                {/*                    const emailBody = 'This is the content of my email.';*/}

                {/*// Open the default email client*/}
                {/*                    window.location.href = `mailto:${recipientEmail}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;*/}
                {/*                }}>email</button>*/}
                <div>
                    <AttachmentButton pk={values.Id} objectType="CxEmployee" />
                    <Field
                        type="text"
                        label="Employee #"
                        name="AccountCode"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="text"
                        label="Subtype"
                        name="ResourceSubtype"
                        component={ResourceSubtypeEditor}
                        className={classes.selectField}
                        family={['Labor']}
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Last Name"
                        name="LastName"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="text"
                        label="First Name"
                        name="FirstName"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="text"
                        label="Middle Name"
                        name="MiddleName"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="date"
                        label="Birth Date"
                        name="BirthDate"
                        component={DateEditor}
                        className={classes.textField}
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Owner"
                        name="Owner"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String) {
                                cxPartys(
                                    objectType: "Party"
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "ObjectType", values: ["Company", "Subcontractor"] }
                                    ]
                                ) {
                                    Id
                                    _DisplayName: DisplayName
                                }
                            }
                        `}
                        optionLabelProperty="_DisplayName"
                    />
                    <Field
                        type="text"
                        label="Equipment"
                        name="Auxiliaries"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String, $start: String) {
                                cxEquipments(
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Range", values: [$start] }
                                    ]
                                ) {
                                    Id
                                    _DisplayName: DisplayName
                                }
                            }
                        `}
                        optionLabelProperty="_DisplayName"
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00", new Date()),
                            end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                        multiple
                        initialize={false}
                    />
                    <Field
                        type="checkbox"
                        Label={{ label: 'Temporary' }}
                        name="Temporary"
                        component={CheckboxWithLabel}
                    />
                    <Field type="checkbox" Label={{ label: 'Mover' }} name="IsMover" component={CheckboxWithLabel} />
                </div>
                <div>
                    <Field
                        type="color"
                        label="ForeColor"
                        name="ForeColor"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="color"
                        label="BackColor"
                        name="BackColor"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="text"
                        label="Divisions"
                        name="Divisions"
                        component={LookupValueEditor}
                        className={classes.selectField}
                        objectType="Division"
                        initialize={false}
                        multiple
                    />
                    <Section title="Lifespan">
                        <Field type="text" name="CurrentLifespan" component={CurrentLifespanEditor} classes={classes} />
                    </Section>
                </div>
                <div>
                    <Field type="text" label="Emails" name="Emails" component={EmailEditor} classes={classes} />
                    <Field type="text" label="Phone #" name="PhoneNumbers" component={PhoneEditor} classes={classes} />
                    <Field type="text" label="Address" name="Address" component={AddressEditor} classes={classes} />
                    <Field type="text" label="Contacts" name="Contacts" component={ContactEditor} classes={classes} />
                </div>
                <div>
                    <Section title="Skills and Costs">
                        <div>
                            <Field
                                type="text"
                                label="Previous Experience"
                                name="PreviousExperience"
                                component={UnitQuantityEditor}
                                classes={classes}
                                unitTypes={['Time']}
                            />
                            <Field type="text" label="Rates" name="Rates" component={RatesEditor} classes={classes} />
                            <Field
                                type="text"
                                label="Minority"
                                name="Minority"
                                component={LookupValueEditor}
                                className={classes.selectField}
                                objectType="Minority"
                                multiple
                                initialize={false}
                            />
                            <Field
                                type="text"
                                label="Languages"
                                name="Languages"
                                component={LookupValueEditor}
                                className={classes.selectField}
                                objectType="Language"
                                multiple
                                initialize={false}
                            />
                            <Field
                                type="text"
                                label="Skills"
                                name="Skills"
                                component={LookupValueEditor}
                                className={classes.selectField}
                                objectType="Skill"
                                multiple
                                initialize={false}
                            />
                            <Field
                                type="text"
                                label="Labor Classes"
                                name="LaborClassification"
                                component={LookupValueEditor}
                                className={classes.selectField}
                                objectType="LaborClass"
                                multiple
                                initialize={false}
                            />
                            <Field
                                type="text"
                                label="Training"
                                name="TrainingCourses"
                                component={LookupValueEditor}
                                className={classes.selectField}
                                objectType="Training"
                                multiple
                                initialize={false}
                            />
                            <Field
                                type="text"
                                label="Gender"
                                name="Gender"
                                component={LookupValueEditor}
                                className={classes.selectField}
                                objectType="Gender"
                                initialize={false}
                            />
                            <Field
                                type="text"
                                label="Machines"
                                name="Machines"
                                component={AutoComplete}
                                className={classes.selectField}
                                optionsQuery={gql`
                                    query ($searchString: String) {
                                        cxResourceTypes(
                                            filters: [
                                                { name: "String", values: ["DisplayName", $searchString] }
                                                { name: "Family", values: ["Equipment"] }
                                            ]
                                        ) {
                                            Id
                                            _DisplayName: DisplayName
                                        }
                                    }
                                `}
                                initialize={false}
                                optionLabelProperty="_DisplayName"
                                multiple
                            />
                            <Field
                                type="text"
                                label="Licenses"
                                name="Licenses"
                                component={TimedLookupValueEditor}
                                classes={classes}
                                objectType="License"
                            />
                            <Field
                                type="text"
                                label="Unions"
                                name="Unions"
                                component={TimedLookupValueEditor}
                                classes={classes}
                                objectType="Union"
                            />
                        </div>
                    </Section>
                </div>
                <Field
                    type="text"
                    name="Tags"
                    component={TagEditor}
                    classes={classes}
                    customProperties={data.cxCustomPropertys}
                />
                <TextFieldMUI
                    label="Crews"
                    sx={{ margin: '5px' }}
                    value={crews}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: true
                    }}
                />
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxEmployee = ({ open, formQueryValues, onClose }) => {
    const [user] = useContext(UserContext);
    // initialize model properties.
    let initialValues = cloneDeep(model);
    delete initialValues.Attachments;
    initialValues.Owner = {
        Id: get('congistics.defaultCompany.Id', user),
        _DisplayName: get('congistics.defaultCompany.Name', user)
    };
    initialValues.ResourceSubtype = { Id: '0', _Name: '' };
    initialValues.Address.Autocode = true;
    initialValues.Address.State = get('congistics.defaultCompany.Address.State', user);
    initialValues.PreviousExperience.Unit = { Id: '2', _DisplayName: 'year' };
    initialValues.BirthDate = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.End = '2999-01-01T00:00:00';
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    return (
        <DialogForm
            open={open}
            title="Employee"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxEmployee"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxEmployee.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
