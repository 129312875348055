/**
 * Created by mark on 11/25/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxHoliday';
import inputModel from 'cb-schema/inputmodelfull/CxHoliday';
import { DateEditor } from 'client-shared/components/form';
import { object, string } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import format from 'date-fns/fp/format';

const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Holiday: string().required('Required')
});

const Form = (props) => {
    const { classes } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Field
                    type="date"
                    label="Holiday Date"
                    name="Holiday"
                    component={DateEditor}
                    className={classes.textField}
                />
                <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
                <Field
                    type="text"
                    label="Description"
                    name="Description"
                    component={TextField}
                    className={classes.textField}
                />
            </form>
        </React.Fragment>
    );
};
export const EditCxHoliday = ({ open, formQueryValues, onClose }) => {
    let initialValues = cloneDeep(model);
    initialValues.Holiday = format("yyyy-MM-dd'T'00:00:00", new Date());
    delete initialValues.CurrentLifespan;
    return (
        <DialogForm
            open={open}
            title="Holiday"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxHoliday"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxHoliday.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
