import React, { useState, useEffect } from 'react';
import { gql, useApolloClient } from '@apollo/client';
/* eslint-disable react-hooks/exhaustive-deps*/
import { watchQueries, isData } from 'client-shared/utility';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

let differentiator = 0;

const errorQuery = gql`
    query {
        error @client {
            message
            differentiator
        }
    }
`;

export const showError = (client, message) => {
    client.writeQuery({
        query: errorQuery,
        data: {
            error: { message: message, differentiator: differentiator++ }
        }
    });
};

export const ShowError = () => {
    const [message, setMessage] = useState(null);
    const client = useApolloClient();

    useEffect(() => {
        const observable = watchQueries(client, [errorQuery]);
        const subscription = observable.subscribe((data) => {
            if (!isData(data)) {
                return;
            }
            // the watchQueries for the event will return one property. rename it to error.
            const { [Object.keys(data.data)[0]]: error } = data.data;
            setMessage(error.message);
        });
        return () => subscription.unsubscribe();
    }, []);

    const handleClose = () => {
        client.writeQuery({
            query: errorQuery,
            data: {
                error: { message: '', differentiator: differentiator++ }
            }
        });
    };

    if (!message) {
        return null;
    }

    return (
        <Dialog
            open={message !== null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'Warning'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
