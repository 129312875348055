import mime from 'mime';

const { gql } = require('graphql-tag');
const { groupByFp } = require('./groupby');

const {
    sortBy,
    flow,
    get,
    filter,
    find,
    flatMap,
    omit,
    reduce,
    curry,
    mapKeys,
    pickBy,
    pipe,
    startsWith,
    intersection,
    keys,
    join,
    uniqBy,
    differenceBy,
    slice,
    values,
    split,
    isEmpty,
    reject,
    isNull,
    zipWith,
    groupBy,
    flatten
} = require('lodash/fp');
const { object, number, string, array, date } = require('yup');
const { createFilters } = require('./queryfilter');
const { Intervals, parseDate, daysInRange, getRangeStartEnd, formatStandard } = require('./dateutilities');
// todo: fp round version has capped airity of 1, tried convert cap false.
const roundNoFp = require('lodash/round');
const { fragments } = require('./fragments');
const {
    format,
    addDays,
    subDays,
    startOfMonth,
    endOfMonth,
    differenceInDays,
    differenceInMinutes,
    getDaysInMonth
} = require('date-fns/fp');
const map = require('lodash/fp/map').convert({ cap: false });
const { getConfigValue } = require('./getconfigvalue');
const round = curry((precision, number) => roundNoFp(number, precision));
const { getReportOptions, getReportFragment } = require('./reporthelpers');

const { getSharableAttachmentLink } = require('client-shared/components/attachments/useAttachments');

/**
 * instantiate the build function
 * @param user - user object containing cached filter text strings.
 * @param path - property path to filter text string in user object.
 * @param file - property name of file in path.
 * @param other - additional props to pass in build function.
 * @returns {null|any}
 */
export const getFilter = (user, path, file, other) => {
    const filterText = get(`${path}["${file}"]`, user);
    if (!filterText) {
        return null;
    }
    try {
        /* eslint-disable no-eval */
        const filter = eval(`(${filterText})`);
        return instantiateBuild(user, filter, other);
    } catch (error) {
        console.log(`check your build() filter ${path} ${file} ${error}`);
        throw new Error(error);
    }
};

export const instantiateBuild = (user, buildFunction, other = {}) =>
    buildFunction({
        gql,
        format,
        addDays,
        subDays,
        startOfMonth,
        endOfMonth,
        differenceInDays,
        differenceInMinutes,
        getDaysInMonth,
        groupByFp,
        sortBy,
        flow,
        pipe,
        get,
        filter,
        find,
        intersection,
        map,
        flatMap,
        omit,
        reduce,
        join,
        uniqBy,
        differenceBy,
        slice,
        values,
        split,
        isEmpty,
        reject,
        isNull,
        zipWith,
        groupBy,
        flatten,
        object,
        number,
        string,
        array,
        date,
        createFilters,
        Intervals,
        parseDate,
        daysInRange,
        getRangeStartEnd,
        formatStandard,
        round,
        user,
        keys,
        fragments,
        getConfigValue,
        getReportOptions,
        getReportFragment,
        getSharableAttachmentLink,
        mime,
        ...other
    });

/**
 * Return only local filter properties, those starting with _
 * allow for overriding
 */
export const extractLocalConfigs = pipe(
    pickBy((_, k) => startsWith('_', k)),
    mapKeys((k) => k.slice(1))
);

// export const addAll = (_in) => [{ Id: 'All', Name: 'All' }, ..._in];
