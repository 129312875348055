import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { pipe, get, values, map, filter, keyBy } from 'lodash/fp';
import { UserContext } from 'client-shared/utility';
import { IconSet } from 'client-shared/components/iconSet';
import { extractToplevelFeature } from 'global-shared/menus';
import { useNavigate } from 'react-router-dom';

export const MainMenu = (props) => {
    const navigate = useNavigate();
    const gotoRoute = (route) => (_) => navigate(route);
    const [user] = useContext(UserContext);
    if (!user) return null;
    const privileges = get('congistics.user.Features', user);
    const topLevelPermissions = keyBy(extractToplevelFeature, privileges);

    const mainMenu = pipe(
        get('customerInfo.ux.menus.desktopMenu'),
        values,
        filter((each) => topLevelPermissions[each.route])
    )(user);
    return (
        <List>
            <ListItem divider={true} button key="/" onClick={gotoRoute('/')}>
                <ListItemIcon>{IconSet('HomeIcon')}</ListItemIcon>
                <ListItemText primary="Overview" />
            </ListItem>
            {map(
                (each) => (
                    <ListItem divider={true} button key={each.route} onClick={gotoRoute(`/${each.route}`)}>
                        <ListItemIcon>{IconSet(each.icon)}</ListItemIcon>
                        <ListItemText primary={each.name} />
                    </ListItem>
                ),
                mainMenu
            )}
            <ListItem divider={true} button key="help" onClick={() => window.open(`https://support.controlboard.app`)}>
                <ListItemIcon>{IconSet('HelpIcon')}</ListItemIcon>
                <ListItemText primary="Help" />
            </ListItem>
        </List>
    );
};
