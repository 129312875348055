import { flow, map, filter, split, toLower, isArray, toString, replace } from 'lodash/fp';
import { getRangeStartEnd, parseDate } from './dateutilities';
import { format, addDays } from 'date-fns/fp';

const toBoolean = (value) => {
    if (typeof value === 'boolean') {
        return value;
    } else if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
    } else {
        return false;
    }
};

const createFilter = (property, value = [], values) => {
    let _filter = {
        name: undefined,
        values: []
        //properties: [],
        //child: undefined,
        //invert: false,
        //skipifempty: false,
        //passifmissing: false,
        //all: false
    };

    const symbols = split('__', property);
    while (true) {
        let symbol = symbols.shift();
        if (!symbol) {
            break;
        }
        symbol = toLower(symbol);

        switch (symbol) {
            case 'properties':
                _filter['properties'] = flow(replace(/_/g, '.'), split('$'))(symbols.shift());
                break;
            case 'child':
                _filter[symbol] = symbols.shift();
                break;
            case 'invert':
            case 'skipifempty':
            case 'passifmissing':
            case 'all':
                _filter[symbol] = toBoolean(symbols.shift());
                break;
            case 'string':
                _filter.name = symbol;
                _filter.values = [replace(/_/g, '.', symbols.shift()), toString(value)];
                break;
            case 'range': {
                if (values._start === '') {
                    break;
                }
                let { start, end } = getRangeStartEnd(values._start, values._periods, {
                    interval: values._interval,
                    periodMultiple: values._stratify ? 7 : undefined,
                    absoluteStart: values._absoluteStart
                });
                end = values._end || end;
                _filter.name = symbol;
                _filter.values = [start, flow(parseDate, addDays(1), format('yyyy-MM-dd'))(end)];
                break;
            }
            case 'scheduled': {
                let { start, end } = getRangeStartEnd(values._start, values._periods, {
                    interval: values._interval,
                    periodMultiple: values._stratify ? 7 : undefined,
                    absoluteStart: values._absoluteStart
                });
                _filter.name = symbol;
                _filter.values =
                    toLower(value) === 'all' || !value
                        ? []
                        : [toLower(value), start, flow(parseDate, addDays(1), format('yyyy-MM-dd'))(end)];
                break;
            }
            case 'unchanged':
                _filter = value;
                break;
            default:
                symbol = symbol === 'defaultdivisionsid' ? 'divisions.id' : symbol;
                if (isArray(value)) {
                    value = flow(
                        map((value) => (value.Id ? toString(value.Id) : toString(value))),
                        filter((value) => value !== 'All')
                    )(value);
                } else {
                    value =
                        value !== undefined
                            ? value.Id
                                ? [toString(value.Id)]
                                : toLower(value) === 'all'
                                  ? []
                                  : [toString(value)]
                            : [];
                }
                _filter.name = replace(/_/g, '.', symbol);
                _filter.values = value;
        }
    }
    return _filter;
};

export const createFilters = (values) => {
    const filters = [];
    let objectType;
    for (const property in values) {
        if (property[0] === '_') {
            continue;
        }
        if (property === 'objectType') {
            objectType = values[property];
            continue;
        }
        try {
            const filter = createFilter(property, values[property], values);
            if (!filter.name) {
                continue;
            }
            filters.push(filter);
        } catch (error) {
            console.log('createFilter ', error);
            throw new Error(error);
        }
    }
    return objectType ? { objectType: objectType, filters: filters } : { filters: filters };
};
