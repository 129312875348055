import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { instantiateBuild } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import { allocationEvent as dataEventQuery } from 'client-shared/gqlhelpers/dataEventQueries';
import { gridQuery as dataQuery } from '../../utility/timekeepingHelpers';

import { get, getOr, includes, reject, map, set, filter, size } from 'lodash/fp';

function build(props) {
    const { format, object, number, date } = props;
    const validationSchema = object({
        _start: date().required('Required'),
        _periods: number().moreThan(0, 'Must be more than 0 days')
    });

    return {
        title: 'Crew Timekeeping',
        dataQuery,
        validationSchema,
        dataEventQueries: [dataEventQuery],
        divisionFilter: true,
        initialValues: {
            _start: format('yyyy-MM-dd', new Date()),
            range: [],
            range__child__timesheets: [],
            range__child__taskdays: []
        },
        fieldDefinitions: [
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'number',
                label: 'Days',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'text',
                label: 'Manager',
                name: '_crew_manager_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: `
                query ($searchString: String) {
                    cxCrews(
                        objectType: "Crew"
                        filters: [{ name: "String", values: ["Manager.DisplayName", $searchString] }]
                    ) {
                        Manager {
                            Id
                            DisplayName
                        }
                    }
                }
            `,
                multiple: false,
                initialValue: [],
                getOptions: (options) => {
                    return options.map((option) => ({
                        Id: option.Manager.Id,
                        DisplayName: option.Manager.DisplayName
                    }));
                },
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Foreman',
                name: '_crew_foreman_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: `
                query ($searchString: String) {
                    cxCrews(
                        objectType: "Crew"
                        filters: [{ name: "String", values: ["Foreman.DisplayName", $searchString] }]
                    ) {
                        Foreman {
                            Id
                            DisplayName
                        }
                    }
                }
            `,
                multiple: false,
                initialValue: [],
                getOptions: (options) => {
                    return options.map((option) => ({
                        Id: option.Foreman.Id,
                        DisplayName: option.Foreman.DisplayName
                    }));
                },
                optionLabelProperty: 'DisplayName'
            }
        ]
    };
}

export const filterTaskDays = (taskDays, key, id) => filter((o) => get(key, o) === id, taskDays);
export const filterTimesheets = (timeSheets, key, id) => filter((o) => get(key, o) === id, timeSheets);

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);

    const CONFIG_restrict_to = includes(user.congistics.user.Level.Name, ['Standard', 'Administrator'])
        ? false
        : getOr(false, ['congistics', 'configValues', 'accounting.timesheet-crews.restrict_to'], user);

    const filterFormDefinition = instantiateBuild(user, build);
    if (CONFIG_restrict_to)
        filterFormDefinition.fieldDefinitions = reject(
            (each) => includes(each.name, ['_crew_manager_id', '_crew_foreman_id']),
            filterFormDefinition.fieldDefinitions
        );
    return (
        <FilterFormFromJson
            enableShowAll={false}
            filterFormDefinition={{
                ...filterFormDefinition,
                enableShowAll: false,
                onData: (data, values, c, rawData) => {
                    if (CONFIG_restrict_to) {
                        let role = CONFIG_restrict_to.toLowerCase();
                        if (role === 'manager') role = 'Manager';
                        if (role === 'foreman') role = 'Foreman';
                        const userId = get('congistics.user.ResourceId', user);
                        data = map(
                            (each) => set('TaskDays', filterTaskDays(each.TaskDays, `Crew.${role}.Id`, userId), each),
                            data
                        );
                        data = map(
                            (each) =>
                                set(
                                    'TimeSheets',
                                    filterTimesheets(each.TimeSheets, `Crews.0.${role}.Id`, userId),
                                    each
                                ),
                            data
                        );
                        return data;
                    }
                    if (size(values._crew_manager_id) > 0) {
                        const managerId = get('_crew_manager_id.0.Id', values);
                        data = map(
                            (each) =>
                                set('TaskDays', filterTaskDays(each.TaskDays, 'Crew.Manager.Id', managerId), each),
                            data
                        );
                        data = map(
                            (each) =>
                                set(
                                    'TimeSheets',
                                    filterTimesheets(each.TimeSheets, 'Crews.0.Manager.Id', managerId),
                                    each
                                ),
                            data
                        );
                    } else if (size(values._crew_foreman_id) > 0) {
                        const foremanId = get('_crew_foreman_id.0.Id', values);
                        data = map(
                            (each) =>
                                set('TaskDays', filterTaskDays(each.TaskDays, 'Crew.Foreman.Id', foremanId), each),
                            data
                        );
                        data = map(
                            (each) =>
                                set(
                                    'TimeSheets',
                                    filterTimesheets(each.TimeSheets, 'Crews.0.Foreman.Id', foremanId),
                                    each
                                ),
                            data
                        );
                    }

                    return data;
                }
            }}
        >
            {children}
        </FilterFormFromJson>
    );
};
