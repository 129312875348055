import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    TableHeaderRow,
    TableSelection,
    VirtualTable,
    TableSummaryRow,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
    TableColumnResizing,
    // TableColumnVisibility,
    // ColumnChooser
    TableFixedColumns
} from '@devexpress/dx-react-grid-material-ui';
import {
    SelectionState,
    SortingState,
    IntegratedSorting,
    SummaryState,
    IntegratedSummary,
    IntegratedSelection,
    GroupingState,
    IntegratedGrouping
} from '@devexpress/dx-react-grid';

import { get, find, map, flow, join, isArray, flowRight as compose, split } from 'lodash/fp';
import { TextFormatterProvider } from './TextFormatterProvider';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import Button from '@mui/material/Button';
import saveAs from 'file-saver';
import IconButton from '@mui/material/IconButton';
import { ExcelIcon } from 'client-shared/components/Icons';
import { Mutation, parseDate, usePrivilege, writeCache, GRIDCACHE, useColumnWidths } from 'client-shared/utility';
import { useApolloClient } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import format from 'date-fns/fp/format';
import Box from '@mui/material/Box';
import { withEditor } from 'client-shared/entities/withEditor';
import { getEditor } from 'client-shared/entities';
import { withGrid } from 'client-shared/grids/withGrid';
import { Editor } from '../../../entities/index';

const getObjectType = (values, row) => {
    const objectType = get('ObjectType', row);
    return objectType ? objectType : get('objectType', values);
};
const getEditorName = (objectType, editForm) => {
    console.log('-----------------', objectType);
    return getEditor(`EditCx${objectType}`) ? `EditCx${objectType}` : editForm;
};

const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
};

const getColumns = (columns) =>
    columns.map((column) => {
        let values; // define local variable here, otherwise, values = get(column.name, row)
        // returns undefined. must be compiler scope rule I can't figure out.
        return {
            ...column,
            getCellValue: (row) => {
                switch (column.formatterType) {
                    case 'ArrayFormatter': {
                        values = get(column.name, row);
                        const separator = get('separator', column) || ', ';
                        if (!isArray(values)) {
                            throw new Error(`${column.name} is not an array: ${values} ${JSON.stringify(row)}`);
                        }
                        return column.arrayPropertyName
                            ? flow(
                                  map((value) => get(column.arrayPropertyName, value)),
                                  join(separator)
                              )(values)
                            : join(separator, values);
                    }
                    case 'CustomFormatter': {
                        if (!column.formatterFunction) {
                            console.log('CustomFormatter missing formatterFunction');
                            return;
                        }
                        values = get(column.name, row);
                        return column.formatterFunction(values, row, column);
                    }
                    case 'DateFormatter': {
                        if (!column.format) {
                            column.format = 'yyyy-MM-dd';
                            //console.log('DateFormatter missing dateFormat');  //Maybe a default is nice!
                            //return;
                        }
                        const { zone = '' } = column;
                        values = get(column.name, row);
                        if (!values) return '';
                        return format(column.format, parseDate(`${values}${zone}`));
                    }
                    case 'ButtonFormatter': {
                        return;
                    }
                    case 'TagFormatter': {
                        const [first] = split('.', column.name);
                        return get(first, row);
                    }
                    default:
                        return get(column.name, row);
                }
            }
        };
    });

const getRowId = (row) => {
    return get('key', row) !== undefined ? get('key', row) : row.Id.toString();
};
const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} />;

/**
 * create a devextreme grid for optional CRUD operations.
 * @param title - grid title used to name persisted column widths.
 * @param columns - grid columns in devextreme format.
 * @param rows - rows to display.
 * @param values - values from the filterForm that opened this grid to determine the objectType for editing.
 * @param editForm - the name of the editForm to open. this is used if the editForm name can not be derived from the objectType.
 * @param bulkEditForm - optional name of makeSetOperation form to use for editing more than one row at a time.
 * @param enableDeletion - optional flag to enable row deletion.
 * @param columnExtensions - optional columnExtensions applied to the grid in devextreme format.
 * @param actionButtons - optional buttons to display across the top of the grid.
 * @param totalItems - optional collection of columns to total in grid totalItems format
 * [{ columnName: 'Hours', type: 'sum' }, ...]
 * scroll jittering when the rows are wider than usual.
 * this can differ from the grid row id in cases where say, child rows are displayed but we want to edit the parent.
 * @param sorting - optional collection of the default columns to sort by.
 * [{columnName: string, direction: 'asc'|'desc'}, ...];
 * @param onRefresh - function used to refresh the items in the grid after a CRUD operation. this typically invokes the
 * associated parent filter to retrieve new data for the grid.
 * @param rowStyle - optional function to set the row style such as the background color.
 * @returns {JSX.Element}
 * @constructor
 */
const CrudGrid_ = ({
    title,
    columns,
    rows,
    values,
    editForm,
    bulkEditForm,
    enableNew = true,
    enableDeletion,
    columnExtensions = [],
    actionButtons = [],
    totalItems = [],
    onRefresh,
    rowStyle = (row) => undefined,
    defaultSorting,
    freezeLeftColumns,
    wrapColumnHeaders = false
}) => {
    const exporterRef = useRef(null);
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');

    const [selectedIds, setSelectedIds] = useState([]);
    const [columnWidths, setColumnWidths] = useColumnWidths(`${title}_columnwidths`, columns);

    const sorting = defaultSorting
        ? defaultSorting
        : [
              {
                  columnName: columns[0].name,
                  direction: 'asc'
              }
          ];

    const [editor, setOpenEditor] = useState({ editor: undefined, props: {} });
    const onClose = useRef(() => null);
    const onOpenEditor = (editor, props, onCloseCallBack) => {
        onClose.current = onCloseCallBack;
        setOpenEditor({ editor: editor, props: props });
    };

    const handleCloseEditor = (props) => {
        setOpenEditor({ editor: undefined, props: {} });
        if (onClose.current) {
            onClose.current(props);
        }
    };

    const handleOpenEditor = (form, values, row, ids = ['-1']) => {
        const objectType = getObjectType(values, row);

        const editorName = getEditorName(objectType, form);
        // open the form by objectType, otherwise, use the editForm
        const formQueryValues = {
            objectType: objectType,
            filters: [{ name: 'Id', values: ids }]
        };
        onOpenEditor(editorName, { formQueryValues: formQueryValues, ids: ids, row: row }, onRefresh);
    };

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const RowComponent = ({ selectByRowClick, highlighted, ...other }) => {
        //const classes = useStyles();
        return (
            <VirtualTable.Row
                {...other}
                style={{ backgroundColor: highlighted ? 'darkgray' : 'none' }}
                onClick={() => {
                    if (!editForm) {
                        return;
                    }
                    const id = get('tableRow.row.Id', other);
                    const row = find({ Id: id }, rows) || {};
                    setSelectedIds([id]);
                    // onOpenGrid('CxAllocations', {
                    //     firstTimeValues: { job_id: id }
                    // })
                    handleOpenEditor(editForm, values, row, [id]);
                }}
            />
        );
    };

    const CellComponent = (props) => {
        const style = { ...props.style, ...rowStyle(props.row) };
        return <VirtualTable.Cell {...props} style={style} />;
    };

    const tableHeaderRowCellComponent = (wrapColumnHeaders) => (props) => {
        const wrap = wrapColumnHeaders ? { wordWrap: 'break-word', whiteSpace: 'pre-line' } : undefined;
        const style = { ...props.style, ...wrap };
        return <TableHeaderRow.Cell {...props} style={style} />;
    };

    const TotalCellComponent = (props) => {
        const style = { ...props.style, ...rowStyle(props.row) };
        return <TableSummaryRow.TotalCell {...props} style={style} />;
    };

    const GroupCellComponent = (props) => {
        const style = { ...props.style, ...rowStyle(props.row) };
        return <TableSummaryRow.GroupCell {...props} style={style} />;
    };

    const handleSelected = (ids) => {
        setSelectedIds(ids);
        writeCache(client, GRIDCACHE, ids);
    };

    const handleNew = () => {
        handleOpenEditor(editForm, values);
    };

    const handleBulkEdit = () => {
        const objectType = getObjectType(values);
        const editorName = getEditorName(objectType, bulkEditForm);
        // open the form by objectType, otherwise, use the editForm
        const formQueryValues = {
            objectType: objectType,
            filters: [{ name: 'Id', values: ['-1'] }]
        };
        onOpenEditor(editorName, { formQueryValues: formQueryValues, ids: selectedIds }, (props) => {
            handleSelected([]);
            onRefresh();
        });
    };

    const handleDelete = () => {
        let objectType = values ? values.objectType : undefined;
        if (!objectType && !editForm) {
            throw new Error('No objectType or editForm, deletion will be skipped');
        }
        objectType = objectType ? objectType : editForm.slice(6);
        //const selectedItems = getSelectedItems(client, GRIDCACHE);
        const id = selectedIds.length === 1 ? get('0', selectedIds) : -1;
        const mutation = new Mutation(client, privilege);
        mutation
            .delete(editForm.slice(4), [id], objectType)
            .then(() => {
                setSelectedIds([]);
                onRefresh();
            })
            .catch(() => null);
    };

    const NewButton = () => (
        <Button variant="contained" color="primary" size="small" default onClick={() => handleNew()}>
            New
        </Button>
    );

    const DeleteButton = () => (
        <IconButton style={{ marginRight: '20px' }} size="small" onClick={() => handleDelete()}>
            <DeleteIcon />
        </IconButton>
    );

    const BulkEditButton = () => (
        <Button variant="contained" color="primary" size="small" default onClick={() => handleBulkEdit()}>
            Bulk Edit
        </Button>
    );

    const ExportButton = () => (
        <IconButton size="small" onClick={() => startExport()}>
            <ExcelIcon />
        </IconButton>
    );

    const DecoratedTextEditorProvider = (props) => compose([withEditor, withGrid])(TextFormatterProvider)(props);
    return (
        <React.Fragment>
            {enableDeletion && selectedIds.length === 1 && <DeleteButton />}
            <ExportButton />
            {/*<span style={{ marginLeft: '25px', marginTop: '3px' }}>*/}
            {/*    <ButtonGroup size="small">*/}
            {enableNew === true && editForm && <NewButton />}
            {bulkEditForm && selectedIds.length > 0 && <BulkEditButton />}
            {actionButtons && actionButtons}
            {/*</ButtonGroup>*/}
            {/*</span>*/}
            <Editor open={!!editor.editor} form={editor.editor} onClose={handleCloseEditor} {...editor.props} />
            <div>
                <Box
                    sx={{
                        height: 'calc(100vh - 180px)',
                        width: '99%',
                        '& tbody tr:nth-of-type(odd)': {
                            backgroundColor: 'ghostwhite'
                        },
                        '& tbody td': {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'lightgray',
                            padding: '5px'
                        },
                        '& .TableSelectCell-cell': {
                            padding: 0
                        }
                    }}
                >
                    <Grid rows={rows} columns={getColumns(columns)} getRowId={getRowId} rootComponent={Root}>
                        {totalItems.length !== 0 && <DragDropProvider />}
                        <SortingState defaultSorting={sorting} />
                        <GroupingState />
                        <SummaryState totalItems={totalItems} groupItems={totalItems} />
                        <SelectionState selection={selectedIds} onSelectionChange={(ids) => handleSelected(ids)} />
                        <IntegratedSorting />
                        <IntegratedGrouping />
                        <IntegratedSummary />
                        <IntegratedSelection />
                        {/*<EditingState />*/}
                        <DecoratedTextEditorProvider columns={columns} />

                        <VirtualTable
                            height={'auto'}
                            columnExtensions={columnExtensions}
                            cellComponent={CellComponent}
                        />
                        <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
                        <TableSelection
                            highlightRow
                            showSelectionColumn={!!bulkEditForm || !!enableDeletion}
                            rowComponent={RowComponent}
                            showSelectAll={!!bulkEditForm}
                        />
                        <TableHeaderRow
                            showSortingControls
                            cellComponent={tableHeaderRowCellComponent(wrapColumnHeaders)}
                        />
                        {/*<TableColumnVisibility/>*/}
                        {/*<Toolbar/>*/}
                        {/*<ColumnChooser/>*/}
                        {totalItems.length !== 0 && <TableGroupRow />}
                        {totalItems.length !== 0 && <Toolbar />}
                        {totalItems.length !== 0 && <GroupingPanel />}
                        {totalItems.length !== 0 && (
                            <TableSummaryRow
                                totalCellComponent={TotalCellComponent}
                                groupCellComponent={GroupCellComponent}
                            />
                        )}
                        <TableFixedColumns leftColumns={freezeLeftColumns} />
                    </Grid>
                </Box>
                <GridExporter
                    ref={exporterRef}
                    rows={rows}
                    columns={getColumns(columns)}
                    getRowId={getRowId}
                    onSave={onSave}
                />
            </div>
        </React.Fragment>
    );
};

CrudGrid_.propTypes = {
    title: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    editForm: PropTypes.string,
    bulkEditForm: PropTypes.string,
    enableNew: PropTypes.bool,
    enableDeletion: PropTypes.bool,
    columnExtensions: PropTypes.array,
    actionButtons: PropTypes.array,
    totalItems: PropTypes.array,
    defaultSorting: PropTypes.array,
    freezeLeftColumns: PropTypes.array,
    wrapColumnHeaders: PropTypes.bool,
    onRefresh: PropTypes.func.isRequired,
    rowStyle: PropTypes.func
};

export const CrudGrid = CrudGrid_;
