import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import {
    getFilter,
    UserContext,
    parseDate,
    Mutation,
    pickFromTemplateFp,
    useColumnWidths
} from 'client-shared/utility';
import { get, flatMap, keyBy, filter, sortBy, isNaN, flow } from 'lodash/fp';
import { format } from 'date-fns/fp';
import { /*AutoCompleteEditorProvider,*/ TextEditorProvider } from 'client-shared/components/crudgrid';
import { Report } from 'client-shared/components/Report';
import Paper from '@mui/material/Paper';
import {
    EditingState,
    CustomTreeData,
    TreeDataState,
    SortingState,
    IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
    Grid as GridDevExpress,
    VirtualTable,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
    TableColumnResizing,
    TableTreeColumn,
    TableFixedColumns,
    TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import inputModelCxResourceSubtypeForecast from 'cb-schema/inputmodelfull/CxResourceSubtypeForecast';
import inputModelCxForecast from 'cb-schema/inputmodelfull/CxForecast';
import { useApolloClient } from '@apollo/client';
import { IconButton, Tooltip } from '@mui/material';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
const map = require('lodash/fp/map').convert({ cap: false });

const AddButton = ({ onExecute }) => (
    <div style={{ textAlign: 'center' }}>
        <Button color="primary" onClick={onExecute} title="Create new row">
            New
        </Button>
    </div>
);

const EditButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} title="Edit row" size="small">
        <EditIcon fontSize="small" />
    </IconButton>
);

const DeleteButton = ({ onExecute }) => (
    <IconButton
        onClick={() => {
            // eslint-disable-next-line
            if (window.confirm('Are you sure you want to delete this row?')) {
                onExecute();
            }
        }}
        title="Delete row"
        size="small"
    >
        <DeleteIcon fontSize="small" />
    </IconButton>
);

const CommitButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} title="Save changes" size="small">
        <SaveIcon fontSize="small" />
    </IconButton>
);

const CancelButton = ({ onExecute }) => (
    <IconButton color="secondary" onClick={onExecute} title="Cancel changes" size="small">
        <CancelIcon fontSize="small" />
    </IconButton>
);

const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton
};

const Command = ({ id, onExecute, ...other }) => {
    const CommandButton = commandComponents[id];
    return <CommandButton onExecute={onExecute} />;
};

const tableEditColumnCellComponent =
    (values) =>
    ({ row, children, ...other }) => {
        if (row.__typename !== 'CxResourceSubtypeForecast') {
            return <VirtualTable.Cell {...other} />;
        }
        if (values._interval.toLowerCase() !== row.Interval.toLowerCase()) {
            return <VirtualTable.Cell {...other} />;
        }
        return <VirtualTable.Cell {...other}>{children}</VirtualTable.Cell>;
    };

const AlertDialog = ({ title = '', open, onOpen, action = () => null }) => {
    return (
        <Dialog open={open} onClose={() => onOpen(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogActions>
                <Button onClick={() => onOpen(false)}>No</Button>
                <Button
                    onClick={async () => {
                        await action();
                        onOpen(false);
                    }}
                    autoFocus
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AlertDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    action: PropTypes.func
};

const getRowId = (row) => row.id;

const getRows = (cxForecasts) => {
    const rows = map((cxForecast) => {
        const subtypeForecast = map((subtypeForecast) => {
            const reportIntervals = keyBy(
                (value) => `reportInterval_${format('yyyy-MM-dd', parseDate(value.Range.Start))}`,
                subtypeForecast.ReportIntervals
            );
            return {
                Interval: cxForecast.Interval,
                ...subtypeForecast,
                id: `${cxForecast.Id}:${subtypeForecast.Id}`,
                ...reportIntervals,
                Unit: cxForecast.Unit
            };
        }, cxForecast.SubtypeForecasts);
        const reportIntervals = keyBy(
            (value) => `reportInterval_${format('yyyy-MM-dd', parseDate(value.Range.Start))}`,
            cxForecast.ReportIntervals
        );

        cxForecast = {
            ...cxForecast,
            id: `${cxForecast.Id}`,
            ...reportIntervals,
            SubtypeForecasts: subtypeForecast
        };
        //cxForecast.SubtypeForecasts = subtypeForecast

        return cxForecast;
    }, cxForecasts);
    return sortBy(['Id'], rows);
};

const MakeGenerics = ({ values, row, onAction, onOpen, onSubmit }) => {
    const client = useApolloClient();
    const makeGenericAllocations = [
        {
            methods: [
                {
                    name: 'MakeGenericAllocations',
                    args: {
                        useIntervals: false
                    }
                }
            ]
        }
    ];
    return (
        <Tooltip title="Make Generics">
            <IconButton
                size="small"
                onClick={() => {
                    onAction('Are you sure you want to create new generic allocations?', () =>
                        onSubmit({
                            client,
                            object: row.__typename,
                            data: [
                                pickFromTemplateFp(
                                    row.__typename === 'CxForecast'
                                        ? inputModelCxForecast
                                        : inputModelCxResourceSubtypeForecast,
                                    row
                                )
                            ],
                            methodsByItem: makeGenericAllocations
                        })
                    );
                    onOpen(true);
                }}
            >
                <ScheduleSendIcon fontSize="small" color="secondary" />
            </IconButton>
        </Tooltip>
    );
};

MakeGenerics.propTypes = {
    values: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    onAction: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

const getCols = (client, values, range, onOpen, onAction) => {
    const cols = [
        {
            name: 'id',
            title: 'Id'
            // getCellValue: (row) => {
            //     return row.__typename === 'CxForecast'
            //         ? get('id', row)
            //         : get('JobActivity.id', row);
            // }
        },
        {
            name: 'JobActivity',
            title: 'Job',
            getCellValue: (row) => {
                const start =
                    row.__typename === 'CxForecast'
                        ? get('Job.CurrentLifespan.Start', row)
                        : get('JobActivity.CurrentLifespan.Start', row);
                const end =
                    row.__typename === 'CxForecast'
                        ? get('Job.CurrentLifespan.End', row)
                        : get('JobActivity.CurrentLifespan.End', row);
                const job =
                    row.__typename === 'CxForecast'
                        ? get('Job.DisplayName', row)
                        : get('JobActivity._DisplayName', row);
                return (
                    <React.Fragment>
                        {job}
                        <div>
                            {format('MM/dd/yyyy', parseDate(start))}-{format('MM/dd/yyyy', parseDate(end))}
                        </div>
                        {row.__typename === 'CxResourceSubtypeForecast' && (
                            <div>
                                <div>Subtype: {get('ResourceSubtype._DisplayName', row)}</div>
                                <div>Shift: {get('Shift._DisplayName', row)}</div>
                                <Report
                                    reportProperties={['PlannedLaborDurationVsForecastedLaborDuration']}
                                    reportObject={get('Report', row)}
                                    unit={get('Unit', row)}
                                />
                            </div>
                        )}
                    </React.Fragment>
                );
            }
        },
        {
            name: 'Amount',
            title: '# Resources'
        },
        {
            name: 'EstimatedTime',
            title: 'Estimated Time',
            getCellValue: (row) => {
                return row.__typename === 'CxForecast'
                    ? `${Math.round(
                          get('Report.ForecastedLaborDuration', row) / get('Unit.Scalar', row)
                      )} ${get('Unit.Name', row)}`
                    : `${get('EstimatedTime', row)} ${get('Unit.Name', row)}`;
            }
        },
        {
            name: 'Start',
            title: 'Start',
            getCellValue: (row) => {
                return row.__typename === 'CxForecast'
                    ? ''
                    : format('yyyy-MM-dd', parseDate(get('CurrentLifespan.Start', row)));
            }
        },
        {
            name: 'End',
            title: 'End',
            getCellValue: (row) => {
                return row.__typename === 'CxForecast'
                    ? ''
                    : format('yyyy-MM-dd', parseDate(get('CurrentLifespan.End', row)));
            }
        },
        {
            name: 'Total',
            title: 'Total',
            getCellValue: (row) => {
                return (
                    <Report
                        reportProperties={values._reportProperties}
                        reportObject={get(`SummaryInterval.Report`, row)}
                        label={true}
                        unit={get('Unit', row)}
                    />
                );
            }
        }
    ];
    const reportIntervals = map((date) => {
        return {
            name: `reportInterval_${date}`,
            title: date,
            getCellValue: (row) => {
                return (
                    <Report
                        reportProperties={values._reportProperties}
                        reportObject={get(`reportInterval_${date}.Report`, row)}
                        label={true}
                        unit={get('Unit', row)}
                    />
                );
            }
        };
    }, range);
    return [...cols, ...reportIntervals];
};

const getColumnExtensions = (values, range) => {
    return map((date) => {
        return {
            columnName: `reportInterval_${date}`,
            wordWrapEnabled: true,
            width: '100px'
        };
    }, range);
};

/*
objectName, data = [], methodsByItem = [], filters = [], objectType, fetchObject, offlineLinkOpen
 */
const handleSubmit = ({ client, object = 'CxResourceSubtypeForecast', data, methodsByItem, properties }) => {
    const mutation = new Mutation(client);
    return mutation
        .save(object, data, methodsByItem, undefined, undefined, properties)
        .catch((error) => console.log('save error', error));
};

const handleDelete = (client, ids) => {
    const mutation = new Mutation(client);
    return mutation
        .delete(
            'CxResourceSubtypeForecast',
            map((id) => id.split(':')[1], ids)
        )
        .catch((error) => console.log('delete error', error));
};

/**
 * component to display a header cell in the grid.
 * @param availableEmployees - true if available employees should be calculated and displayed.
 * @param column - column title and name.
 * @param items - collection of allocations.
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderCell = ({ availableEmployees, column, items }) => {
    // const allocations = filter((allocation) => allocation.TaskDays.get(column.name), items);
    // return (
    //     <Typography fontSize="small" component={'div'}>
    //         <span style={{ fontSize: '10px' }}>
    //             <div>{column.title}</div>
    //             {availableEmployees ? (
    //                 <HeaderTotals start={column.name} allocations={allocations} />
    //             ) : (
    //                 <Totals allocations={allocations} start={column.name} />
    //             )}
    //         </span>
    //     </Typography>
    // );
};

HeaderCell.propTypes = {
    column: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired
};

const getEditingStateColumnExtensions = (values, range) => {
    return [
        { columnName: 'JobActivity', editingEnabled: false },
        { columnName: 'Total', editingEnabled: false },
        {
            columnName: 'Start',
            createRowChange: (row, value) => ({ CurrentLifespan: { ...row.CurrentLifespan, Start: value } })
        },
        {
            columnName: 'End',
            createRowChange: (row, value) => ({ CurrentLifespan: { ...row.CurrentLifespan, End: value } })
        },
        ...map((date) => {
            return {
                columnName: `reportInterval_${date}`,
                editingEnabled: false
            };
        }, range)
    ];
};

const Grid = ({ title, values, rows, columns, range }) => {
    const client = useApolloClient();
    const [columnWidths, setColumnWidths] = useColumnWidths(`${title}_columnwidths`, columns);
    const commitChanges = ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
            changedRows = [
                ...rows,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row
                }))
            ];
        }
        if (changed) {
            changedRows = flow(
                flatMap((forecast) => forecast.SubtypeForecasts),
                filter((row) => changed[row.id]),
                map((row) => ({ ...row, ...changed[row.id] }))
            )(rows);
            changedRows = map((changedRow) => {
                return pickFromTemplateFp(inputModelCxResourceSubtypeForecast, changedRow);
            }, changedRows);
            if (changedRows.length) {
                handleSubmit({ client, data: changedRows });
            }
        }
        if (deleted) {
            if (deleted.length) {
                handleDelete(client, deleted);
            }
        }
        // setRows(changedRows);
    };
    const getChildRows = (row, rootRows) => {
        if (row) {
            return row.SubtypeForecasts ? row.SubtypeForecasts : null;
        }
        return rootRows;
    };
    return (
        <Paper
            sx={{
                height: 'calc(100vh - 210px)',
                width: `calc(100vw - 20px)`
            }}
        >
            <GridDevExpress rows={rows} columns={columns} getRowId={getRowId}>
                {/*<AutoCompleteEditorProvider*/}
                {/*    for={['JobActivity']}*/}
                {/*    optionsQuery={gql`*/}
                {/*        query ($searchString: String, $id: String) {*/}
                {/*            cxJobActivitys(*/}
                {/*                objectType: "jobactivity"*/}
                {/*                filters: [*/}
                {/*                    { name: "String", values: ["DisplayName", $searchString] }*/}
                {/*                    { name: "Job.Id", values: [$id] }*/}
                {/*                ]*/}
                {/*            ) {*/}
                {/*                Id*/}
                {/*                _DisplayName: DisplayName*/}
                {/*            }*/}
                {/*        }*/}
                {/*    `}*/}
                {/*    queryVariables={(row) => {*/}
                {/*        return { id: get('Job.Id', row) || '-1' };*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<AutoCompleteEditorProvider*/}
                {/*    for={['ResourceSubtype']}*/}
                {/*    optionsQuery={gql`*/}
                {/*        query ($searchString: String) {*/}
                {/*            cxResourceTypes(*/}
                {/*                objectType: "resourcesubtype"*/}
                {/*                filters: [*/}
                {/*                    { name: "String", values: ["DisplayName", $searchString] }*/}
                {/*                    { name: "ResourceType.Family", values: ["Labor"] }*/}
                {/*                ]*/}
                {/*            ) {*/}
                {/*                Id*/}
                {/*                _DisplayName: DisplayName*/}
                {/*            }*/}
                {/*        }*/}
                {/*    `}*/}
                {/*/>*/}
                <TextEditorProvider
                    type="number"
                    for={['Amount', 'EstimatedTime']}
                    sx={{ width: '75px' }}
                    onChange={(value, onValueChange, column, row) => {
                        const n = parseFloat(value);
                        if (isNaN(n)) {
                            onValueChange(value);
                            return;
                        }
                        onValueChange(n);
                    }}
                />
                <TextEditorProvider type="date" for={['Start', 'End']} sx={{ width: '125px' }} />
                <TreeDataState />
                <CustomTreeData getChildRows={getChildRows} />
                <EditingState
                    onCommitChanges={commitChanges}
                    columnExtensions={getEditingStateColumnExtensions(values, range)}
                />
                <SortingState defaultSorting={[{ columnName: 'id', direction: 'asc' }]} />
                <IntegratedSorting />
                <VirtualTable height={'calc(100vh - 250px)'} columnExtensions={getColumnExtensions(values, range)} />
                <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
                <TableColumnVisibility defaultHiddenColumnNames={['id']} />
                <TableHeaderRow />
                <TableEditRow />
                <TableEditColumn
                    width={100}
                    // showAddCommand
                    showEditCommand
                    showDeleteCommand
                    commandComponent={Command}
                    cellComponent={tableEditColumnCellComponent(values)}
                />
                <TableTreeColumn for="JobActivity" />
                <TableFixedColumns
                    leftColumns={[
                        TableEditColumn.COLUMN_TYPE,
                        'JobActivity',
                        'Amount',
                        'EstimatedTime',
                        'Start',
                        'End',
                        'Total'
                    ]}
                />
            </GridDevExpress>
        </Paper>
    );
};

Grid.propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    range: PropTypes.array.isRequired
};

const FilterFormFromJsonRenderFunction = ({ title, values, data }) => {
    const client = useApolloClient();
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState({ title: '', fn: undefined });
    const handleAction = (title, action) => {
        setAction({ title: title, fn: action });
    };
    // const range = getRange(values._start, values._periods, { interval: values._interval });
    const range = map(
        (reportInterval) => format('yyyy-MM-dd', parseDate(reportInterval.Range.Start)),
        get('0.SubtypeForecasts.0.ReportIntervals', data)
    );
    const rows = getRows(data);
    const columns = getCols(client, values, range, setOpen, handleAction);
    return (
        <div>
            <AlertDialog title={action.title} open={open} onOpen={setOpen} action={action.fn} />
            <Grid title={title} values={values} rows={rows} columns={columns} range={range} />
        </div>
    );
};

export const JobForecast = () => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.pages', 'jobforecast.js');
    return (
        <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
            {({ values, data, onSubmit }) => {
                return (
                    <FilterFormFromJsonRenderFunction title={filterFormDefinition.title} values={values} data={data} />
                );
            }}
        </FilterFormFromJson>
    );
};
