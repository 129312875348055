import React, { useContext } from 'react';
import { Filter } from './Filter';
import { first, flatMap, get, identity, keyBy, map, pipe, sortBy, split } from 'lodash/fp';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { withEditor } from 'client-shared/entities/withEditor';
import Scheduler, { getAllocationColors, getConfig, spanToIntraDayEvent } from 'client-shared/components/timeline';
import {
    AllocationEventQuery,
    extractLocalConfigs,
    plusDays,
    todayAt,
    useCollectionSubscription,
    usePrivilege,
    UserContext
} from 'client-shared/utility';
import { AllocationsQuery } from './queries.js';
import { dateToCBDateExact, mapWithKey, templateFullContext } from 'global-shared/utils/utils';
import { evalQuery } from 'client-shared/gqlhelpers/queryHelpers';
import { useApolloClient } from '@apollo/client';
import Fab from '@mui/material/Fab';

const Index = () => {
    const [user] = useContext(UserContext);
    const CONFIG_event_template = get(['congistics', 'configValues', 'schedule.dailyschedule.event_template'], user);
    return (
        <React.Fragment>
            <Filter>
                {({ values: variables, data: resources }) => {
                    const [shiftStart, shiftEnd] = [
                        todayAt(variables._start, variables._startTime),
                        todayAt(variables._start, variables._endTime)
                    ];
                    const config = getConfig(extractLocalConfigs(variables), {
                        y_property: 'section_id',
                        scrollable: variables._scrollable,
                        shiftStart,
                        shiftEnd
                    });
                    return (
                        <InnerScheduler
                            {...{
                                config,
                                variables,
                                resources,
                                user,
                                eventTemplate: CONFIG_event_template,
                                shiftStart,
                                shiftEnd
                            }}
                        />
                    );
                }}
            </Filter>
        </React.Fragment>
    );
};

export default Index;

const InnerScheduler_ = (props) => {
    const { config, variables, resources, onOpenEditor, shiftStart, shiftEnd, eventTemplate } = props;
    variables.start = variables._start;
    variables.end = plusDays(variables._periods, variables.start);
    variables.resourceIds = map(get('Id'), resources);
    const client = useApolloClient();
    const { data: allocations, event } = useCollectionSubscription({
        dataQuery: AllocationsQuery,
        eventQueries: [AllocationEventQuery],
        values: variables
    });

    const [privilege] = usePrivilege('Allocation');

    const resourceMap = keyBy('Id', resources);

    const _config = { ...config, ...{ y_unit: pipe(sortBy(false), map(mapTop))(resources), end: shiftEnd } };

    const allocationToEvent = (_in) =>
        mapWithKey(
            (each, idx) => ({
                ...spanToIntraDayEvent(get('Range', each)),
                allocationId: _in.Id,
                JobActivityId: get('JobActivity.Id', _in),
                o: _in,
                id: `${_in.Id}_${each.Principal.Id}_${idx}`,
                resourceId: each.Principal.Id,
                originalResourceId: each.Principal.Id,
                section_id: `${each.Principal.Id}`,
                text: eventTemplate ? templateFullContext(eventTemplate, _in) : `${get('Job.DisplayName', _in)}`,
                ...getAllocationColors(variables, _in)
            }),
            _in.ResourceSpans
        );

    const rez = flatMap(allocationToEvent, allocations);

    function editCallback(e, more) {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: [first(split('_', e))] }]
            }
        });
    }

    async function onEmptyClick(date, e) {
        const endTime = new Date(date.getTime() + 60 * 60 * 1000);
        const el = e.target.closest('.dhx_matrix_line');
        const resourceId = get('dataset.sectionId', el);
        if (!resourceId) return;
        onOpenEditor('EditCxAllocation', {
            initialValues: {
                CurrentLifespan: {
                    Start: dateToCBDateExact(date),
                    End: dateToCBDateExact(endTime)
                },
                ResourceSpans: [
                    {
                        Principal: { Id: resourceId, _DisplayName: get([resourceId, 'DisplayName'], resourceMap) },
                        Range: {
                            Start: dateToCBDateExact(date),
                            End: dateToCBDateExact(endTime)
                        },
                        Amount: 1,
                        Auxiliaries: []
                    }
                ]
            },
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    }

    const schedulerEvents = {
        onEventChanged,
        onEmptyClick,
        onBeforeEventChanged: (ev, e, is_new, original) => !privilege
    };

    async function onEventChanged(id, event) {
        const { items, methodsByItem } = eventToMutation(event);
        await evalQuery(client, null, 'CxAllocation', items, methodsByItem).catch(() => {});
    }

    const openEditor = (e) => {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    };

    const schedulerProps = {
        editCallback,
        onEventDeleted: identity,
        event
    };
    //if (rez.length < 1) return <p>Doing...</p>;

    return (
        <Box pb={5}>
            <Scheduler
                height="100%"
                width="100%"
                startDate={shiftStart}
                title="Allocation Timeline"
                data={rez}
                timelineConfig={_config}
                {...schedulerProps}
                {...schedulerEvents}
            />
            <Fab color="primary" aria-label="add" style={{ float: 'right', marginRight: 10 }} onClick={openEditor}>
                <AddIcon />
            </Fab>
        </Box>
    );
};

const InnerScheduler = withEditor(InnerScheduler_);

export const mapTop = (_in) => {
    return {
        key: get('Id', _in),
        label: `${get('DisplayName', _in)}/${get('ResourceType.DisplayName', _in)}`,
        BackColor: get('BackColor', _in),
        ForeColor: get('ForeColor', _in)
    };
};

export const prepareTimelineDate = (ev) => ({
    start: dateToCBDateExact(ev.start_date),
    end: dateToCBDateExact(ev.end_date)
});

export const eventToMutation = (event) => {
    const methodsByItem = [];
    const _allocation = { Id: event.allocationId, JobActivity: { Id: event.JobActivityId } };
    methodsByItem.push({
        indices: [0],
        methods: [{ name: 'setRange', args: prepareTimelineDate(event) }]
    });
    if (event.section_id !== event.resourceId)
        methodsByItem.push({
            indices: [0],
            methods: [
                {
                    name: 'replaceResources',
                    args: {
                        originalIds: [event.resourceId],
                        newIds: [event.section_id]
                    }
                }
            ]
        });
    return {
        methodsByItem: methodsByItem,
        items: [_allocation]
    };
};
