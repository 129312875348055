import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { instantiateBuild, UserContext } from 'client-shared/utility';
import { gql } from 'graphql-tag';

function build(props) {
    const { format, get, flatMap, object, number, date, user, Intervals, fragments, omit, map, getReportOptions } =
        props;
    const validationSchema = object({
        _start: date().required('Required'),
        _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(100, 'Must be less than 100 days')
    });

    const dataQuery = gql`
        ${fragments.CxAllocationFragment}
        query ($filters: [FilterInput]) {
            cxAllocations(objectType: "Allocation", filters: $filters) {
                ...CxAllocationFragment
            }
        }
    `;

    return {
        title: 'Crew Grid',
        dataQuery: dataQuery,
        dataEventQueries: [fragments.AllocationEventQuery],
        sourceMutationObjectTypes: [],
        validationSchema: validationSchema,
        divisionFilter: true,
        enableShowAll: false,
        filterProperties: ['Allocation.Crew.DisplayName', 'Allocation.Job.DisplayName', 'Allocation.Job.AccountCode'],
        sortProperties: ['Allocation.Crew.DisplayName'],
        filterOnServer: false,
        namedFilters: true,
        initialValues: {
            _periods: 6,
            ActualCrew: 'true',
            range: [],
            range__child__taskdays: [],
            _absoluteStart: true
        },
        onData: (data) => {
            // create one item per taskDay with allocation properties
            return flatMap((allocation) => {
                const a = omit(['TaskDays'], allocation);
                return map((taskDay) => {
                    return {
                        Allocation: a,
                        ...taskDay
                    };
                }, allocation.TaskDays);
            }, data);
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Interval',
                name: '_interval',
                component: 'AutoComplete',
                options: [Intervals.DAYS, Intervals.WEEKS, Intervals.MONTHS],
                className: 'shortSelectField',
                initialValue: 'Days'
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'number',
                label: 'Periods',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'text',
                label: 'Manager',
                name: 'job_manager_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxCaches(
                            objectType: "Cache"
                            filters: [{ name: "String", values: ["JobManagers.DisplayName", $searchString] }]
                        ) {
                            JobManagers {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => get('0.JobManagers', options),
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Resource Divisions',
                name: 'resources_divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: '_Name',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Orientation',
                name: '_orientation',
                component: 'AutoComplete',
                optionLabelProperty: 'Name',
                options: ['Row', 'Column'],
                className: 'selectField',
                initialValue: 'row'
            },
            {
                type: 'checkbox',
                Label: { label: 'Summary' },
                name: '_summary',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'checkbox',
                Label: { label: 'Stratify' },
                name: '_stratify',
                component: 'CheckboxWithLabel',
                initialValue: false
            },
            {
                type: 'text',
                label: 'Skip Days',
                name: '_skipDays',
                component: 'AutoComplete',
                className: 'selectField',
                options: [
                    { Id: 6, _DisplayName: 'Saturday' },
                    { Id: 0, _DisplayName: 'Sunday' }
                ],
                optionIdProperty: 'Id',
                optionLabelProperty: '_DisplayName',
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Interval Metrics',
                name: '_reportIntervalProperties',
                component: 'AutoComplete',
                options: getReportOptions(user),
                className: 'longTextField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id',
                multiple: true,
                sort: false,
                initialValue: ['LaborCount', 'ScheduledLaborCount', 'AvailableLaborCount', 'LaborUtilization']
            },
            {
                type: 'checkbox',
                Label: { label: 'Display Description' },
                name: '_description',
                component: 'CheckboxWithLabel',
                initialValue: true
            },
            {
                type: 'checkbox',
                Label: { label: 'Export Labels' },
                name: '_label',
                component: 'CheckboxWithLabel',
                initialValue: true
            }
        ]
    };
}

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
