/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
/*eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    TableTreeColumn,
    TableFixedColumns,
    TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';
import { TreeDataState, CustomTreeData, SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import { get, flatMap } from 'lodash/fp';
import { getDay, isSameDay } from 'date-fns/fp';
import { parseDate, query, useColumnWidths, writeCache, CONFLICTCACHE } from 'client-shared/utility';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { HolidayQuery } from './query';
import { useApolloClient } from '@apollo/client';
import { IconButton, Box } from '@mui/material';

const map = require('lodash/fp/map').convert({ cap: false });

const styles = {
    headerCell: {
        backgroundColor: '#F8F8F8',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'lightgray',
        //  height: '53px',
        padding: '0px'
    },
    headerWeekendCell: {
        backgroundColor: '#e7e7e7',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'lightgray',
        //  height: '53px',
        padding: '0px'
    }
};

const isWeekEndHoliday = (holidays = [], columnName) => {
    const holiday = holidays.find((holiday) => isSameDay(parseDate(columnName), parseDate(holiday.Holiday)));
    return [0, 6].includes(getDay(parseDate(columnName))) || holiday;
};

const tableHeaderRowCellComponent =
    ({ expanded, columns, holidays, onExpand = () => null, render = (props) => props.column.title }) =>
    (props) => {
        const style = Object.assign(
            {},
            props.style,
            isWeekEndHoliday(holidays, props.column.name) ? styles.headerWeekendCell : styles.headerCell
        );
        if (props.column.name === get('0.name', columns)) {
            const Icon = expanded ? (
                <ExpandLess sx={{ fontSize: '17px' }} />
            ) : (
                <ChevronRight sx={{ fontSize: '17px' }} />
            );
            return (
                <TableHeaderRow.Cell {...props} style={style}>
                    <IconButton size="small" onClick={onExpand}>
                        {Icon}
                    </IconButton>{' '}
                    {props.column.title}
                    {props.children}
                </TableHeaderRow.Cell>
            );
        }
        return (
            <TableHeaderRow.Cell {...props} style={style}>
                {render(props)}
                {props.children}
            </TableHeaderRow.Cell>
        );
    };

const tableHeaderRowSortLabelComponent =
    ({ sortColumnNames }) =>
    ({ onSort, column, direction }) => {
        if (!sortColumnNames.includes(column.name)) {
            return null;
        }
        const handleSort = (event, direction = 'asc') => {
            onSort({ direction: direction, keepOther: event.shiftKey });
        };

        return (
            <React.Fragment>
                {!direction && (
                    <SortByAlphaIcon
                        fontSize="small"
                        sx={{ color: '#D3D3D3' }}
                        onClick={(event) => handleSort(event)}
                    />
                )}
                {direction === 'asc' && (
                    <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ color: '#A9A9A9' }}
                        onClick={(event) => handleSort(event, 'desc')}
                    />
                )}
                {direction === 'desc' && (
                    <ArrowUpwardIcon
                        fontSize="small"
                        sx={{ color: '#A9A9A9' }}
                        onClick={(event) => handleSort(event, null)}
                    />
                )}
            </React.Fragment>
        );
    };

// const comparator = (a, b) => {
//     const valueA = get(a.props.columnName, a.props.row);
//     const valueB = get(b.props.columnName, b.props.row);
//     if (!valueA) {
//         throw new Error(`Missing sort property ${a.props.columnName}`);
//     }
//     if (!valueB) {
//         throw new Error(`Missing sort property ${b.props.columnName}`);
//     }
//     if (valueA === valueB) {
//         return 0;
//     }
//     return valueA < valueB ? -1 : 1;
// };

const getIntegratedSortingColumnExtensions = (columnNames, comparator) => {
    return map((columnName) => ({ columnName: columnName, compare: comparator }), columnNames);
};

const handleExpandAll = (expandedRowIds, rows, setExpandedRowIds) => {
    if (expandedRowIds.length !== rows.length) {
        const ids = map((row) => row.Id, rows);
        setExpandedRowIds(ids);
        return;
    }
    setExpandedRowIds([]);
};

export const Schedule = ({
    title = 'set title in schedulegrid',
    rows,
    columns,
    fixedColumnNames,
    estimatedRowHeight = 53,
    sortColumnNames = [],
    getChildRows,
    getRowId,
    getCellValue,
    tableHeaderCell,
    tableCellComponent
}) => {
    const client = useApolloClient();
    const [columnWidths, setColumnWidths] = useColumnWidths(`${title}_columnwidths`, columns);
    const [expandedRowIds, setExpandedRowIds] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const tableTreeColumnName = get('0.name', columns);
    const integratedSortingColumnExtensions = getIntegratedSortingColumnExtensions(sortColumnNames);
    const conflictIds =
        get('0.ObjectType', rows) === 'ResourceType'
            ? flatMap((row) => map((resource) => ({ Id: resource.Id, ObjectType: 'Resource' }), row.Resources), rows)
            : rows;
    writeCache(client, CONFLICTCACHE, conflictIds);
    useEffect(() => {
        (async () => {
            // get the form values from the local cache if true.
            const { data } = await query(client, HolidayQuery);
            setHolidays(get('cxHolidays', data));
        })();
    }, []);

    const memoizedTableCell = useCallback((row, column) => {
        return tableCellComponent(row, column);
    }, []);
    return (
        <Box
            sx={{
                width: '100%',
                '& tbody tr:nth-of-type(odd)': {
                    backgroundColor: 'ghostwhite'
                },
                '& tbody td': {
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'lightgray',
                    padding: '0px'
                }
            }}
        >
            <Grid rows={rows} columns={columns} getRowId={getRowId} getCellValue={getCellValue}>
                <SortingState />
                <TreeDataState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
                <CustomTreeData getChildRows={getChildRows} />
                <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                <VirtualTable
                    //messages={tableMessages}
                    estimatedRowHeight={estimatedRowHeight}
                    height={'calc(100vh - 160px)'}
                    cellComponent={memoizedTableCell}
                />
                <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
                <TableHeaderRow
                    showSortingControls={true}
                    cellComponent={tableHeaderRowCellComponent({
                        expanded: expandedRowIds.length === rows.length,
                        columns: columns,
                        holidays: holidays,
                        onExpand: () => handleExpandAll(expandedRowIds, rows, setExpandedRowIds),
                        render: tableHeaderCell
                    })}
                    sortLabelComponent={tableHeaderRowSortLabelComponent({ sortColumnNames: sortColumnNames })}
                />
                <TableTreeColumn
                    for={tableTreeColumnName}
                    expandButtonComponent={({ visible, expanded, onToggle }) => {
                        if (!visible) {
                            return null;
                        }
                        const Icon = expanded ? (
                            <ExpandLess sx={{ fontSize: '17px' }} />
                        ) : (
                            <ChevronRight sx={{ fontSize: '17px' }} />
                        );
                        return (
                            <IconButton size="small" onClick={onToggle}>
                                {Icon}
                            </IconButton>
                        );
                    }}
                />
                <TableFixedColumns leftColumns={fixedColumnNames} />
            </Grid>
        </Box>
    );
};

Schedule.propTypes = {
    title: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    fixedColumnNames: PropTypes.array,
    estimatedRowHeight: PropTypes.number,
    sortColumnNames: PropTypes.array,
    getRowId: PropTypes.func.isRequired,
    getChildRows: PropTypes.func.isRequired,
    getCellValue: PropTypes.func.isRequired,
    tableHeaderCell: PropTypes.func,
    tableCellComponent: PropTypes.func
};

export const FlatSchedule = ({
    title = 'set title in schedulegrid',
    rows,
    columns,
    fixedColumnNames,
    estimatedRowHeight = 53,
    sortColumnNames = [],
    sortComparator,
    getRowId,
    getCellValue,
    tableHeaderCell,
    tableCellComponent
}) => {
    const client = useApolloClient();
    const [columnWidths, setColumnWidths] = useColumnWidths(`${title}_columnwidths`, columns);
    const [holidays, setHolidays] = useState([]);
    const integratedSortingColumnExtensions = getIntegratedSortingColumnExtensions(sortColumnNames, sortComparator);
    useEffect(() => {
        (async () => {
            const { data } = await query(client, HolidayQuery);
            setHolidays(get('cxHolidays', data));
        })();
    }, []);
    const memoizedTableCell = useCallback((row, column) => {
        return tableCellComponent(row, column);
    }, []);
    return (
        <Box
            sx={{
                width: '100%',
                '& tbody tr:nth-of-type(odd)': {
                    backgroundColor: 'ghostwhite'
                },
                '& tbody td': {
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'lightgray',
                    padding: '0px'
                }
            }}
        >
            <Grid rows={rows} columns={columns} getRowId={getRowId} getCellValue={getCellValue}>
                <SortingState />
                <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                <VirtualTable
                    //messages={tableMessages}
                    estimatedRowHeight={estimatedRowHeight}
                    height={'calc(100vh - 160px)'}
                    cellComponent={memoizedTableCell}
                />
                <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
                <TableHeaderRow
                    showSortingControls={true}
                    cellComponent={tableHeaderRowCellComponent({
                        holidays: holidays,
                        render: tableHeaderCell
                    })}
                    sortLabelComponent={tableHeaderRowSortLabelComponent({ sortColumnNames: sortColumnNames })}
                />
                <TableFixedColumns leftColumns={fixedColumnNames} />
            </Grid>
        </Box>
    );
};

FlatSchedule.propTypes = {
    title: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    fixedColumnNames: PropTypes.array,
    estimatedRowHeight: PropTypes.number,
    sortColumnNames: PropTypes.array,
    sortComparator: PropTypes.func,
    getRowId: PropTypes.func.isRequired,
    getCellValue: PropTypes.func.isRequired,
    tableHeaderCell: PropTypes.func,
    tableCellComponent: PropTypes.func
};
