/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Description } from 'client-shared/components/serverobjects/Description';

export const JobActivity = ({ jobActivity, ...other }) => {
    return (
        <span {...other}>
            {jobActivity.DisplayName}
            <Description description={jobActivity.Description} />
        </span>
    );
};

JobActivity.propTypes = {
    jobActivity: PropTypes.object.isRequired
};
