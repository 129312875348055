import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { fitBounds } from 'google-map-react/utils';
import PropTypes from 'prop-types';
import GoogleMap from 'google-map-react';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { map, get, pipe, reject, isEmpty } from 'lodash/fp';
import { defaultTo } from 'global-shared/utils/utils';
import { createLocation, getBoundingBox } from 'global-shared/utils/geo';
import { useWindowDimensions } from 'client-shared/utility';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(36)
    }
}));

export const noLoc = (_in) => get('props.lat', _in) === 0 || get('props.lng', _in) === 0;

export const PinForType = (props) => {
    const { types, _in } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const { mapping, pin } = types(_in);
    return (
        <Tooltip className={classes.tooltip} title={mapping(_in)} arrow interactive="true" key={get('Id', _in)}>
            {pin}
        </Tooltip>
    );
};

export const ObjectsMapped = (props) => {
    const { data, initial } = props;
    const { width, height } = useWindowDimensions();
    const pins = pipe(
        map((_in) => {
            const { locationPath } = objectMappings(_in);
            return (
                <PinForType
                    types={objectMappings}
                    _in={_in}
                    key={get('Id', _in)}
                    lat={get([...locationPath, 'Lat'], _in)}
                    lng={get([...locationPath, 'Lng'], _in)}
                />
            );
        }),
        reject(noLoc)
    )(isEmpty(data) ? initial : data);
    const bounds = getBoundingBox(
        map((each) => createLocation(get('props.lat', each), get('props.lng', each)), pins),
        2000
    );

    const { center, zoom } = fitBounds(bounds, { width, height });
    if (center.lat === 0) return <p>loading</p>;
    console.log('<<<<<<<<', center, zoom);

    return <CCMap {...{ center, zoom }}>{pins}</CCMap>;
};

ObjectsMapped.propTypes = {
    data: PropTypes.array.isRequired
};

export const CCMap = (props) => {
    const { center, zoom } = props;
    const { children } = props;
    return (
        <GoogleMap
            options={{ mapTypeControl: true }}
            center={center}
            zoom={zoom}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        >
            {children}
        </GoogleMap>
    );
};

const withReturn = (value) => {
    if (!value) return null;
    return (
        <React.Fragment key={Math.floor(Math.random() * 1000)}>
            {value}
            <br />
        </React.Fragment>
    );
};

const emptyNone = (path, o) => defaultTo('', get(path), o);
export const templateAddress = (address) => {
    const res = `${emptyNone('City', address)}, ${emptyNone('State.Name', address)}${emptyNone(
        get('ZipCode'),
        address
    )}`;
    return res !== ', ' ? res : null;
};

export const summarizeAllocations = map(([name, items]) => `${name}: ${items.length}`);

export const objectMappings = (cx) => {
    const _objectMappings = {
        CxJob: {
            mapping: (obj) => (
                <React.Fragment>
                    <Typography color="inherit">
                        {obj.DisplayName}: {obj.AccountCode}
                    </Typography>
                    {withReturn(get('Address.Street', obj))}
                    {withReturn(get('Address.StreetDetails', obj))}
                    {withReturn(templateAddress(obj.Address))}
                    {map(withReturn, summarizeAllocations(get('allocations', obj)))}
                </React.Fragment>
            ),
            pin: <EmojiFlagsIcon style={{ fill: `${cx.ForeColor}` }} />,
            locationPath: ['Address', 'Geocode']
        },
        TrackableResult: {
            mapping: (obj) => (
                <React.Fragment>
                    <Typography color="inherit">{obj.payload.mapLabel}</Typography>
                </React.Fragment>
            ),
            pin: <LocationOnIcon fontSize="small" style={{ fill: `${get('payload.ForeColor', cx)}` }} />,
            locationPath: ['Location']
        },
        AggregateTrackableResult: {
            mapping: (obj) => (
                <React.Fragment>
                    <Typography color="inherit">
                        <ul>
                            {map(
                                (each) => (
                                    <li>{each}</li>
                                ),
                                obj.payload.mapLabel
                            )}
                        </ul>
                    </Typography>
                </React.Fragment>
            ),
            pin: <LocationOnIcon style={{ fill: `${get('payload.ForeColor', cx)}`, fontSize: 60 }} />,
            locationPath: ['Location']
        }
    };
    //console.log( '&&&', get(get('__typename', cx), _objectMappings))
    return get(get('__typename', cx), _objectMappings);
};
