import React, { useState } from 'react';
import { curry } from 'lodash/fp';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2)
    }
}));

export function Busy({ isShowing }) {
    const classes = useStyles();
    return isShowing ? (
        <div className={classes.root}>
            <CircularProgress size={'1.5rem'} />
        </div>
    ) : null;
}

export const useBusy = () => {
    const [isShowing, setIsShowing] = useState(false);
    const show = (visible) => {
        setIsShowing(visible);
    };
    const showP = curry((visible, _in) => {
        setIsShowing(visible);
        return _in;
    });
    return [isShowing, show, showP];
};
