import { cbDateToDhtmlx, dhtmlxDateToCb, setF } from 'global-shared/utils/utils';
import { forEach, get, pick, pickBy, pipe, set, startsWith, toPairs } from 'lodash/fp';
import addDays from 'date-fns/fp/addDays';
import isSameDay from 'date-fns/fp/isSameDay';
import differenceInHours from 'date-fns/fp/differenceInHours';
import { formatMidnight, parseDate, plusDays } from 'client-shared/utility';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { isFunction } from 'lodash';

/**
 *
 * These are the configs and the formik form for customizing the look and feel of
 * the scheduler component.
 */
export const dailyCfgProto = {
    section_autoheight: false,
    start_on_monday: true,
    name: 'timeline',
    x_date: '%j',
    x_step: 1,
    x_size: '7*days',
    y_property: 'id',
    render: 'tree',
    folder_dy: 30,
    scrollable: true,
    dx: 300
};

export const minutelyCfgProto = {
    name: 'timeline',
    x_unit: 'minute',
    x_date: '%H:%i',
    x_step: 30,
    x_size: 12,
    x_start: 0,
    x_length: 48,
    render: 'bar'
};

export const weeklyConfigProto = {
    section_autoheight: false,
    name: 'timeline',
    x_unit: 'week',
    x_date: '%M-%j',
    x_step: 1,
    x_size: 30,
    render: 'tree',
    y_property: 'id',
    folder_dy: 30,
    dx: 300
};

export const monthlyCfgProto = {
    section_autoheight: false,
    start_on_monday: true,
    name: 'timeline',
    x_unit: 'month',
    x_date: '%M',
    x_step: 1,
    x_size: 24,
    x_start: 0,
    x_length: 24,
    column_width: 200,
    y_property: 'id',
    render: 'tree',
    folder_dy: 30,
    dx: 300
};

export const baseConfigs = {
    day: dailyCfgProto,
    week: weeklyConfigProto,
    month: monthlyCfgProto,
    minute: minutelyCfgProto
};

export const getConfig = (state, xtra = {}) =>
    pipe(fiX_size, setUpHourlyScheduling, adjustWidth)({ ...baseConfigs[state.x_unit], ...state, ...xtra });

/**
 * take an event and return something looks like an entity with a CurrentLifespan
 * @param _in
 * @returns {{CurrentLifespan: {Start: string, End: string}, Id: *}}
 */
export const eventToEntity = (_in) => ({
    Id: _in.id,
    CurrentLifespan: {
        Start: dhtmlxDateToCb(_in.start_date),
        End: dhtmlxDateToCb(_in.end_date)
    }
});

/**
 * Take an entity with a CurrentLifespan and return a base event
 * @param _in
 * @returns {{}}
 */
export const entityToEvent = (_in) => {
    const start_date = parseDate(cbDateToDhtmlx(get(['CurrentLifespan', 'Start'], _in)));
    const end_date = plusDays(1, cbDateToDhtmlx(get(['CurrentLifespan', 'End'], _in)));
    const res = {
        id: get('Id', _in),
        start_date,
        end_date,
        original: { start_date, end_date },
        originalSpan: [get(['CurrentLifespan', 'Start'], _in), get(['CurrentLifespan', 'End'], _in)]
    };
    return { ...res, ...adjustTimelineColors(_in) };
    /*if (_in.ForeColor) mset('color', res, _in.ForeColor);
    //mset('color', res, 'black')
    return res; */
};

export const effectiveEntityToEvent = (_in) => {
    const start_date = parseDate(cbDateToDhtmlx(get(['EffectiveLifespan', 'Start'], _in)));
    const end_date = plusDays(1, cbDateToDhtmlx(get(['EffectiveLifespan', 'End'], _in)));
    const res = {
        id: get('Id', _in),
        start_date,
        end_date,
        original: { start_date, end_date },
        originalSpan: [get(['EffectiveLifespan', 'Start'], _in), get(['EffectiveLifespan', 'End'], _in)]
    };
    //if (_in.ForeColor) mset('color', res, _in.ForeColor);

    //mset('color', res, 'black')
    //return res
    return { ...res, ...adjustTimelineColors(_in) };
};

export const spanToEvent = (_in) => {
    const start_date = parseDate(cbDateToDhtmlx(get(['Start'], _in)));
    const end_date = plusDays(1, cbDateToDhtmlx(get(['End'], _in)));
    return {
        start_date,
        end_date,
        original: { start_date, end_date },
        originalSpan: [get(['Start'], _in), get(['End'], _in)],
        ...adjustTimelineColors(_in)
    };
};

export const spanToIntraDayEvent = (_in) => {
    const start_date = parseDate(get(['Start'], _in));
    const end_date = parseDate(get(['End'], _in));
    return {
        start_date,
        end_date,
        original: { start_date, end_date },
        originalSpan: [get(['Start'], _in), get(['End'], _in)]
    };
};

/**
 * Correctly handle the event resizing behavior
 * @param _in an event structure
 * @returns {*} the value you were passed in
 */
export const adjustTimes = (_in) => {
    const originalDuration = differenceInHours(_in.original.start_date, _in.original.end_date);
    const newDuration = differenceInHours(_in.start_date, _in.end_date);
    const startDiff = differenceInHours(_in.original.start_date, _in.start_date);

    if (newDuration === originalDuration) {
        if (startDiff <= 0 && startDiff > -5)
            return pipe(setF('start_date', addDays(1)), setF('end_date', addDays(1)))(_in);

        return _in;
    }

    if (isSameDay(_in.start_date, _in.original.start_date)) {
        return setF('end_date', addDays(1), _in);
    }

    /*    if (!isSameDay(_in.end_date, _in.original.end_date)) {
        console.log('hello');
        return setF('end_date', addDays(1), _in);
    }*/

    return _in;
};

/**
 * Adjust the timeline date endpoints and package for
 * graphql eval mutation
 */
export const prepareTimelineDate = pipe(
    pick(['start_date', 'end_date', 'original']),
    adjustTimes,
    ({ start_date, end_date }) => ({ start: formatMidnight(start_date), end: formatMidnight(end_date) })
);

//TODO Do this differently (hide some information for a change).
/**
 * A bit of hack to correctly set the x_size dep
 */
export const fiX_size = (cfg) => {
    const xSizes = { week: 7, month: 30, day: 1, minute: 1 };
    const newSize = Math.round(cfg.x_size / xSizes[cfg.x_unit]);
    return set('x_size', newSize, cfg);
};

export const setUpHourlyScheduling = (cfg) => {
    if (cfg.x_unit !== 'minute') return cfg;
    const startHours = cfg.shiftStart.getHours();
    const endHours = cfg.shiftEnd.getHours();
    cfg.x_size = 1 + (endHours - startHours) * 2;
    if (cfg.shiftEnd.getMinutes() > 0) cfg.x_size++;
    if (cfg.shiftEnd.getMinutes() > 30) cfg.x_size++;
    cfg.x_start = startHours * 2;
    return cfg;
};

export const adjustWidth = (cfg) => {
    //console.log(cfg)
    if (!cfg.scrollable) return cfg;
    cfg.column_width = cfg.column_width_ || 200;
    return cfg;
};

export function getCalendarDays(variables) {
    return differenceInCalendarDays(parseDate(variables.end), parseDate(variables._start)) + 1;
}

export const mapActivity = (jobId) => (_in) => {
    return {
        key: `${jobId}_${get('Id', _in)}`,
        label: get('DisplayName', _in)
    };
};

/**
 Somme functions for overriding the control properties
     **/
export const stripProps = pipe(
    pickBy(isFunction),
    pickBy((v, k) => startsWith('on', k)),
    toPairs
);

export function setupEvents(scheduler, props) {
    scheduler.listeners = stripProps(props).map(([name, fn]) => scheduler.attachEvent(name, fn));
}

export function clearEvents(scheduler) {
    forEach(scheduler.detachEvent, scheduler.listeners);
}

export function getAllocationColors(vees, _in) {
    let which;
    if (vees._barColor === '_') which = _in;
    else if (vees._barColor === 'ActionStatus') {
        which = { ForeColor: _in.ForeColor, BackColor: get(`${vees._barColor}.BackColor`, _in) };
    } else {
        which = {
            ForeColor: get(`${vees._barColor}.ForeColor`, _in),
            BackColor: get(`${vees._barColor}.BackColor`, _in)
        };
    }
    //console.log(vees._barColor, '>><<<>', which);
    return adjustTimelineColors(which);
}

export function adjustTimelineColorsFlat(ForeColor, BackColor) {
    return adjustTimelineColors({ ForeColor, BackColor });
}
export function adjustTimelineColors(item) {
    //console.log(item)
    if (!item.hasOwnProperty('ForeColor')) return {};
    if (item.ForeColor === '#000000' && item.BackColor === '#FFFFFF') {
        return { color: '#000000', textColor: '#FFFFFF' };
    }
    if (item.ForeColor !== '#000000' && item.BackColor === '#FFFFFF') {
        return { color: item.ForeColor, textColor: '#FFFFFF' };
    }

    return { color: item.BackColor, textColor: item.ForeColor };
}
