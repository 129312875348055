import React, { useContext, useState } from 'react';
import { FilterFormGridFromJson } from 'client-shared/components/filterbar';
import { CrudGrid } from 'client-shared/components/crudgrid';
import { getFilter } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import Button from '@mui/material/Button';
import { SMSBatchSend } from 'client-shared/components/SMSBatchSend';
import { SMSFilter } from './SMSFilter';

export const TruckRoutes = ({ history, location = {}, match, staticContext, ...other }) => {
    const [user] = useContext(UserContext);
    const [openForm, setOpenForm] = useState();

    const handleOpen = (form) => {
        setOpenForm(form);
    };

    const handleClose = () => {
        setOpenForm(undefined);
    };

    const TextSchedule = (
        <Button
            key={1}
            size="small"
            onClick={() => {
                handleOpen('SMSBatchSend');
            }}
        >
            Text Schedule
        </Button>
    );

    const filterFormGridDefinition = getFilter(user, 'customerInfo.ux.pages', 'truckroutes.js');
    if (!filterFormGridDefinition) return null;
    filterFormGridDefinition.actionButtons = [TextSchedule];

    return (
        <div>
            <SMSBatchSend open={openForm === 'SMSBatchSend'} onClose={handleClose} Filter={SMSFilter} />
            <div style={{ width: '98vw' }}>
                <FilterFormGridFromJson
                    filterFormGridDefinition={filterFormGridDefinition}
                    CrudGrid={CrudGrid}
                    {...location.state}
                    {...other}
                />
            </div>
        </div>
    );
};
