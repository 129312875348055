import React from 'react';
import PropTypes from 'prop-types';
import { get, flatMap, omit, filter, difference, find } from 'lodash/fp';
import { gridHeaderItemStyle, gridItemStyle } from '../styles';
import { Report } from 'client-shared/components/Report';
import { ResourceName } from 'client-shared/components/serverobjects/ResourceName';
import { getRangeRows, GridDashBoard } from '../dashboardhelpers';
import { isSameDay } from 'date-fns/fp';
import { parseDate, reportExcel } from 'client-shared/utility';
const map = require('lodash/fp/map').convert({ cap: false });
const gridRowHeaderItem = gridHeaderItemStyle({ textAlign: 'left', whiteSpace: 'pre-line' });
const gridItem = gridItemStyle();

const groupByDisplayName = {
    name: 'DisplayName'
};

export const addExcelRow =
    (_columns = []) =>
    ({ row, rowNumber, columns, workSheet, addExcelCell }) => {
        columns = [..._columns, ...columns];
        let workSheetRow = workSheet.addRow(Array(columns.length).fill(''));
        row.totals = false;
        workSheetRow.eachCell((cell, colNumber) => {
            const column = get(colNumber - 1, columns);
            const colName = get('name', column);
            cell.alignment = { wrapText: true };
            addExcelCell({ row, rowNumber, column, colNumber, colName, cell });
        });
        if (!row.headerRow) {
            return;
        }
        row.totals = true;
        workSheetRow = workSheet.addRow(Array(columns.length).fill(''));
        workSheetRow.eachCell((cell, colNumber) => {
            const column = get(colNumber - 1, columns);
            const colName = get('name', column);
            cell.alignment = { wrapText: true };
            addExcelCell({ row, rowNumber, column, colNumber, colName, cell });
        });
    };

/**
 * populate each cell of the spreadsheet.
 * @param values
 * @param allocations
 * @returns {(function(*, *, *, *): (*|undefined))|*}
 */
const addExcelCell = (values, reportIntervals) => {
    return ({ row, rowNumber, column, colNumber, colName, cell }) => {
        cell.alignment = { wrapText: true };
        if (row.headerRow) {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DCDCDC' } };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: {
                    style: 'thin'
                }
            };
            cell.value = row.totals ? 'Total' : get('title', column);
            if (isNaN(new Date(colName))) {
                return;
            }
            const reportInterval = find(
                (reportInterval) => isSameDay(parseDate(reportInterval.Range.Start), parseDate(colName)),
                reportIntervals
            );
            if (row.totals) {
                cell.value = {
                    richText: [
                        ...reportExcel({
                            reportProperties: values._reportIntervalProperties,
                            reportObject: get('Report', reportInterval),
                            unit: { Name: 'Hrs', Scalar: 60 },
                            label: values._label
                        })
                    ]
                };
                return;
            }
            cell.value = {
                richText: [
                    {
                        font: { bold: true },
                        text: `${colName}\n`
                    }
                ]
            };
            return;
        }
        let color = get('items.0.ForeColor', row) ? get('items.0.ForeColor', row).slice(1) : '000000';
        let backColor = get('items.0.BackColor', row) ? get('items.0.BackColor', row).slice(1) : 'ffffff';
        // if (colName === 'DisplayName') {
        //     cell.font = { color: { argb: color } };
        //     cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
        //     cell.border = {
        //         top: { style: 'medium' },
        //         left: { style: 'medium' },
        //         bottom: { style: 'medium' },
        //         right: { style: 'medium' }
        //     };
        //     cell.value = get('items.0.DisplayName', row);
        //     return;
        // }
        cell.font = { color: { argb: color } };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
        cell.border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' }
        };
        cell.value = get(`items.0.${colName}`, row);
        if (isNaN(new Date(colName))) {
            return;
        }
        const intervals = row.intervalData.get(colName);
        const filteredIntervals = filter((interval) => interval.__typename === 'CxReportInterval', intervals);
        if (!filteredIntervals.length) {
            return;
        }
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
        cell.border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' }
        };
        cell.value = {
            richText: reportExcel({
                reportProperties: values._reportProperties,
                reportObject: get('0.Report', filteredIntervals),
                unit: { Name: 'Hrs', Scalar: 60 },
                label: values._label
            })
        };
    };
};

/**
 * called by the GridDashBoard to render detail rows.
 * @param rangeRow - an object that contains the data for the row in the property, intervalData.
 * see dashBoardhelpers.js for details.
 * @param values - user entered values from the filter bar.
 * @returns {JSX.Element}
 * @constructor
 */
const row =
    (values) =>
    ({ rangeRow }) => {
        const row = map((date, index) => {
            return <Cell key={index} values={values} intervals={rangeRow.intervalData.get(date)} />;
        }, rangeRow.getRange());
        return (
            <React.Fragment>
                <div style={gridRowHeaderItem}>
                    <ResourceName resource={get(`items.0`, rangeRow)} />
                    {/*{get(`items.0.DisplayName`, rangeRow)}*/}
                </div>
                {row}
            </React.Fragment>
        );
    };

/**
 * called by Row to render a cell for a day, week, month, or other period.
 * @param taskDays - all taskDays for the row and period.
 * @param values - user entered values from the filter bar.
 * @parma displayKeys - summary properties to display
 * @returns {JSX.Element}
 * @constructor
 */
const Cell = ({ values, intervals }) => {
    return (
        <div style={gridItem}>
            <Report
                reportProperties={values._reportProperties}
                reportObject={get('0.Report', intervals)}
                label={true}
                unit={{ Name: 'Hrs', Scalar: 60 }}
            />
        </div>
    );
};

Cell.propTypes = {
    values: PropTypes.object.isRequired,
    intervals: PropTypes.array.isRequired
};

const getSkipDays = (days) =>
    days
        ? difference(
              [0, 1, 2, 3, 4, 5, 6],
              map((day) => day.Day, days)
          )
        : [];

const getNormalizedRanges = (data) =>
    flatMap((item) => {
        return map((reportInterval) => {
            return {
                ...omit(['ReportIntervals'], item),
                ...reportInterval
            };
        }, item.ReportIntervals);
    }, data);

export const Grid = ({ values, data, _addExcelRow = addExcelRow() }) => {
    const rangeRows = getRangeRows(
        {
            groupByCriteria: groupByDisplayName,
            datePropertyName: 'Range.Start',
            start: values._start,
            periods: values._periods,
            interval: values._interval,
            skipDays: getSkipDays(get('_shift._Days', values)),
            stratify: values._stratify
        },
        getNormalizedRanges(data)
    );
    return (
        <GridDashBoard
            values={{ ...values, start: values._start }}
            rangeRows={rangeRows}
            periods={values._periods}
            interval={values._interval}
            skipDays={values._skipDays}
            stratify={values._stratify}
            orientation={values._orientation}
            row={row(values)}
            addExcelCell={addExcelCell}
            addExcelRow={_addExcelRow}
        />
    );
};
