import { gql } from '@apollo/client';
import { unSelectAll } from './useselected';
import { SCHEDULECACHE, TOOLBARCACHE, ROUTECACHE, HEADERCACHE } from './readwritecache';
import { evalQuery } from '../gqlhelpers/queryHelpers';
import { cleanObject } from './pickfromtemplate';

/* eval methods are documented in: C:\work\src\congistics\cbrd\docs\internal\api\eval.md*/

export class Mutation {
    constructor(client, privilege) {
        this.client = client;
        this.privilege = privilege;
    }

    unSelect = () => {
        unSelectAll(this.client, SCHEDULECACHE);
        unSelectAll(this.client, TOOLBARCACHE);
        unSelectAll(this.client, ROUTECACHE);
        unSelectAll(this.client, HEADERCACHE);
    };

    /**
     *
     * @param objectName - name of graphql interface such as CxJob, CxLookupValue
     * @param data
     * @param methodsByItem
     * @param filters
     * @param objectType - optional type of object Job, JobSubType, other. CxLookupValue can have many objectTypes even though
     * the objectName is CxLookupValue. For all other objects, the objectType can be derived from the objectName so this
     * parameter is optional.
     * @returns {Promise<T | void>}
     */
    async save(objectName, data = [], methodsByItem = [], filters = [], objectType, fetchObject, offlineLinkOpen) {
        this.unSelect();
        if (offlineLinkOpen) {
            await offlineLinkOpen();
        }
        // return evalQuery(this.client, fetchObject, objectName, cleanObject(data), methodsByItem, filters, objectType);
        return evalQuery(this.client, fetchObject, objectName, cleanObject(data), methodsByItem, filters, objectType);
    }

    delete(objectName, data = [], objectType) {
        const objectNameBase = objectName.slice(2);
        const mutationName = `cx${objectNameBase}s_onDelete`;
        objectType = objectType || objectNameBase;
        const mutation = gql`
            mutation x (
                $objectType: String,
                $data: [String]
            ) {
                ${mutationName} (
                    objectType: $objectType,
                    data: $data
                ) {
                    Id
                    ObjectType
                }
            }
        `;
        this.unSelect();
        return this.client.mutate({
            mutation: mutation,
            variables: {
                objectType: objectType,
                data: data
            }
        });
    }
}
