import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Field } from 'client-shared/components/form';

export const ScheduledEditor = ({ field, classes, label = 'Scheduled', ...other }) => {
    return (
        <Field
            type="text"
            label={label}
            name={field.name}
            component={AutoComplete}
            className={classes.selectField}
            options={['All', 'Scheduled', 'Unscheduled', 'Conflicts', 'NoConflicts']}
            // optionLabelProperty={'Name'}
            // optionIdProperty={'Id'}
            disableClearable
        />
    );
};

ScheduledEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string
};
