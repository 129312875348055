import { Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { curry, get } from 'lodash/fp';

const transitoryStyle = {};

export function useNotifications() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = (key) => (
        <Fragment>
            <Button
                onClick={() => {
                    closeSnackbar(key);
                }}
            >
                ✖️
            </Button>
        </Fragment>
    );

    const permanentStyle = {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
        },
        persist: true,
        action
    };

    const openSBTransient = (message, opts) => enqueueSnackbar(message.toString(), { ...transitoryStyle, ...opts });
    const openSBTransientF = curry((opts, message) =>
        enqueueSnackbar(message.toString(), { ...transitoryStyle, ...opts })
    );
    const openSBTransientP = curry((path, opts, payload) => {
        openSBTransient(get(path, payload), opts);
        return payload;
    });
    const openSBPermanent = (message, opts) => enqueueSnackbar(message.toString(), { ...permanentStyle, ...opts });

    return {
        openSBTransient,
        openSBTransientP,
        openSBTransientF,
        openSBPermanent
    };
}
