/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, LookupValueEditor, ResourceSubtypeEditor } from 'client-shared/components/form';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { DialogForm } from 'client-shared/components/editform';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxMaterial';
import inputModel from 'cb-schema/inputmodelfull/CxMaterial';
import { object, number } from 'yup';
import { InventoryEditor, inventoryValidationSchema } from './components/InventoryEditor';
import cloneDeep from 'lodash/cloneDeep';
import { AttachmentButton } from 'client-shared/components/attachments/button';
import { get } from 'lodash/fp';
import TextFieldMUI from '@mui/material/TextField';

const validationSchema = object({
    // Owner: object({
    //     Id: number().moreThan(0, 'Required')
    // }),
    ResourceSubtype: object({
        Id: number().moreThan(0, 'Required')
    }),
    Unit: object({
        Id: number().moreThan(0, 'Required')
    }),
    Inventory: inventoryValidationSchema
});

const Form = (props) => {
    const { classes, setFieldValue, values } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <AttachmentButton pk={values.Id} objectType="CxMaterial" />
                <Field
                    type="text"
                    label="Resource Subtype"
                    name="ResourceSubtype"
                    component={ResourceSubtypeEditor}
                    className={classes.selectField}
                    family={['Material']}
                    onResourceTypeChange={(value) => {
                        setFieldValue(`_ResourceType.Unit`, get('Unit', value));
                    }}
                />
                <TextFieldMUI
                    label="Unit"
                    value={get('_ResourceType.Unit.Name', values)}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: true
                    }}
                />
                <Field
                    type="text"
                    label="Material #"
                    name="AccountCode"
                    component={TextField}
                    className={classes.textField}
                />
                <Field type="checkbox" Label={{ label: 'Reusable' }} name="Reusable" component={CheckboxWithLabel} />
                {/* <Section title="Lifespan">
                    <Field type="text" name="CurrentLifespan" component={CurrentLifespanEditor} classes={classes} />
                </Section>*/}
                <Field
                    type="text"
                    label="Divisions"
                    name="Divisions"
                    component={LookupValueEditor}
                    className={classes.selectField}
                    objectType="Division"
                    initialize={false}
                    multiple
                />
                <Field
                    type="text"
                    label="Inventory"
                    name="Inventory.Items"
                    component={InventoryEditor}
                    classes={classes}
                />
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxMaterial = ({ open, formQueryValues, onClose }) => {
    // initialize model properties.
    let initialValues = cloneDeep(model);
    delete initialValues.Attachments;
    initialValues._ResourceType = {
        Unit: {
            Name: ''
        }
    };
    initialValues.ResourceSubtype = {
        Id: '0',
        _Name: ''
    };
    //initialValues.Owner.Id = get('congistics.defaultCompany.Id', user);
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    return (
        <DialogForm
            open={open}
            title="Material"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxMaterial"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxMaterial.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
