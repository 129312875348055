import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { AutoComplete, Field, ResourceSubtypeEditor } from 'client-shared/components/form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { array, number, object } from 'yup';
import { UserContext } from 'client-shared/utility/context';
import { get } from 'lodash/fp';
import { Section } from 'client-shared/components/Section';
import { TextField } from 'formik-mui';
import TextFieldMUI from '@mui/material/TextField';
import { gql } from 'graphql-tag';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getConfigValue } from 'client-shared/utility';

export const subtypeQuantitiesValidationSchema = array().of(
    object({
        Supplier: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        ResourceSubtype: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        Amount: number().min(0.0001, 'Must be greater than 0').max(999999999, 'Must be less than 999,999,999')
    })
);

export const SubtypeQuantitiesEditor = ({
    form,
    field,
    classes,
    label = '',
    data,
    disabled,
    cache = false,
    ...other
}) => {
    const [user] = useContext(UserContext);
    const config_configValues = getConfigValue('config.accounting.timeentries', user);
    const family = get('ResourceSubtype.Family', config_configValues) || [];
    const partyType = get('Supplier.PartyType', config_configValues) || ['Plant', 'Subcontractor'];
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;

    const model = {
        Supplier: {
            // Id: get('congistics.defaultCompany.Id', user),
            // _DisplayName: get('congistics.defaultCompany.Name', user)
        },
        _ResourceType: {
            Unit: {
                Name: ''
            }
        },
        ResourceSubtype: {
            Id: '0',
            _Name: ''
        },
        Amount: 1,
        Description: ''
    };

    // test
    return (
        <Section title="Tickets">
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((value, index) => {
                                    return (
                                        <div key={index}>
                                            <Field
                                                type="text"
                                                label="Supplier"
                                                name={`${field.name}.${index}.Supplier`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                optionsQuery={gql`
                                                    query ($searchString: String, $partyType: [String]) {
                                                        cxPartys(
                                                            filters: [
                                                                {
                                                                    name: "String"
                                                                    values: ["DisplayName", $searchString]
                                                                }
                                                                { name: "PartyType", values: $partyType }
                                                            ]
                                                        ) {
                                                            Id
                                                            DisplayName
                                                        }
                                                    }
                                                `}
                                                optionLabelProperty="DisplayName"
                                                queryVariables={() => ({
                                                    partyType: partyType
                                                })}
                                                disabled={disabled}
                                                initialize={false}
                                                cache={cache}
                                            />
                                            <Field
                                                type="text"
                                                label="Subtype"
                                                name={`${field.name}.${index}.ResourceSubtype`}
                                                component={ResourceSubtypeEditor}
                                                className={classes.shortSelectField}
                                                family={family}
                                                onResourceTypeChange={(value) => {
                                                    form.setFieldValue(
                                                        `${field.name}.${index}._ResourceType.Unit`,
                                                        get('Unit', value)
                                                    );
                                                }}
                                                disabled={disabled}
                                                cache={cache}
                                            />
                                            <Field
                                                type="number"
                                                label="Amount"
                                                name={`${field.name}.${index}.Amount`}
                                                component={TextField}
                                                className={classes.extraShortTextField}
                                                disabled={disabled}
                                            />
                                            <TextFieldMUI
                                                label="Unit"
                                                value={get('_ResourceType.Unit.Name', value)}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                className={classes.extraShortTextField}
                                                disabled={disabled}
                                            />
                                            <div>
                                                <Field
                                                    type="text"
                                                    label="Slip Number / Description"
                                                    name={`${field.name}.${index}.Description`}
                                                    component={TextField}
                                                    className={classes.textField}
                                                    multiline
                                                    variant="outlined"
                                                    margin="normal"
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                    form.setFieldTouched(field.name, true);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

SubtypeQuantitiesEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    cache: PropTypes.bool
};
