import { gql } from 'graphql-tag';
import { CustomPropertyFragment, TagFragment } from 'client-shared/utility/fragments';

export const FormQuery = gql`
    ${TagFragment}
    query ($objectType: String = "Equipment", $filters: [FilterInput]) {
        cxEquipments(objectType: $objectType, filters: $filters) {
            Model
            Make
            SerialNumber
            Year
            Height {
                Unit {
                    Id
                    _DisplayName: DisplayName
                }
                Quantity
            }
            Weight {
                Unit {
                    Id
                    _DisplayName: DisplayName
                }
                Quantity
            }
            Width {
                Unit {
                    Id
                    _DisplayName: DisplayName
                }
                Quantity
            }
            Capacity {
                Unit {
                    Id
                    _DisplayName: DisplayName
                }
                Quantity
            }
            Certifications {
                Id
                LookupValue {
                    Id
                    _DisplayName: DisplayName
                }
                CurrentLifespan {
                    Start
                    End
                }
                Description
            }
            Maintenance {
                Id
                LookupValue {
                    Id
                    _DisplayName: DisplayName
                }
                CurrentLifespan {
                    Start
                    End
                }
                Description
            }
            MoveNotes
            Temporary
            Owner {
                Id
                _Name: Name
            }
            Auxiliaries {
                Id
                _DisplayName: DisplayName
            }
            ResourceSubtype {
                Id
                _Name: Name
            }
            Rates {
                Id
                RateType {
                    Id
                    _DisplayName: DisplayName
                }
                UnitCost {
                    Unit {
                        Id
                        _DisplayName: DisplayName
                    }
                    Cost {
                        Unit {
                            Id
                            _DisplayName: DisplayName
                        }
                        Quantity
                    }
                }
            }
            ForeColor
            BackColor
            AccountCode
            CurrentLifespan {
                Start
                End
            }
            Id
            Description
            Divisions {
                Id
                _DisplayName: DisplayName
            }
            Tags {
                ...TagFragment
            }
            _Location: Location {
                Name
                Address {
                    Id
                    Street
                    StreetDetails
                    City
                    State {
                        Id
                        _Description: Description
                    }
                    ZipCode
                    Geocode {
                        Id
                        Lat
                        Lng
                    }
                }
            }
            ReturnFromCurrentLocation
            Crews {
                DisplayName
            }
        }
        cxCustomPropertys(objectType: "CustomProperty", filters: [{ name: "SupportedTypes", values: ["Equipment"] }]) {
            ${CustomPropertyFragment}
        }
    }
`;
