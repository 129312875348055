import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AutocompleteMUI from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from 'client-shared/utility';
import sortBy from 'lodash/sortBy';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '200px'
    }
}));

const AutoCompleteEditor = ({
    multiple,
    optionsQuery,
    minLength,
    disableClearable,
    queryVariables,
    onChange,
    getOptionLabel,
    value = '',
    onValueChange,
    column,
    row,
    ...other
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [variables, setVariables] = useState({ searchString: '', ...queryVariables({ value, ...row }) });
    const fetchData = open && variables.searchString.length >= minLength;
    const { loading, data } = useQuery(optionsQuery, variables, fetchData);
    const getOptions = () => {
        if (!data) {
            return [];
        }
        if (loading === false) {
            let options = data[Object.keys(data)[0]];
            return sortBy(options, [getOptionLabel]);
        }
        return [];
    };

    const handleInputChange = (event, value) => {
        setVariables({ searchString: value, ...queryVariables({ value, ...row }) });
    };
    return (
        <AutocompleteMUI
            multiple={multiple}
            value={value}
            open={open}
            options={getOptions()}
            loading={false}
            noOptionsText={`Type to search`}
            onOpen={() => {
                setVariables({ ...variables, ...queryVariables({ value, ...row }) });
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={handleInputChange}
            // value can be from the options collection ({id: n, _DisplayName: "sss", ...}) or it can be from the
            // defaultValue ("sss" or null).
            onChange={(event, value) => {
                // check if the native, onValueChange should be overridden.
                if (onChange) {
                    onChange(value, onValueChange, column, row);
                    return;
                }
                // onValueChange(value ? getOptionLabel(value) : value);
                onValueChange(value);
            }}
            // getOptionSelected={(option, value) => {
            //     return option.Id === value.Id;
            // }}
            getOptionLabel={getOptionLabel}
            disableClearable={disableClearable}
            renderInput={(params) => (
                <TextField
                    className={classes.root}
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {fetchData && loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
            {...other}
        />
    );
};

export const AutoCompleteEditorProvider = ({
    multiple,
    optionsQuery,
    minLength = 0,
    disableClearable,
    queryVariables = (values) => undefined,
    onChange,
    getOptionLabel = (option) => (option._DisplayName ? option._DisplayName : option),
    ...other
}) => {
    return (
        <DataTypeProvider
            editorComponent={(props) =>
                AutoCompleteEditor({
                    multiple,
                    optionsQuery,
                    minLength,
                    disableClearable,
                    queryVariables,
                    onChange,
                    getOptionLabel,
                    ...props
                })
            }
            {...other}
        />
    );
};

AutoCompleteEditorProvider.propTypes = {
    multiple: PropTypes.bool,
    optionsQuery: PropTypes.object.isRequired,
    minLength: PropTypes.number,
    disableClearable: PropTypes.bool,
    queryVariables: PropTypes.func,
    onChange: PropTypes.func,
    getOptionLabel: PropTypes.func
};
