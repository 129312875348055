import { DragDropBase, CellDragDropMixin, AllocationDragDropMixin, ActionsMixin } from 'client-shared/utility';
import { format, addDays, differenceInCalendarDays } from 'date-fns/fp';
import { /*get,*/ flow } from 'lodash/fp';
import { parseDate } from 'client-shared/utility';

class DragDrop extends DragDropBase {
    pasteResourceOnCell({ selectedItemProperties, resource, toolBarOptions, selected }) {
        return {
            methodsByItem: [],
            items: null
        };
    }

    pasteResourceTypeOnCell({ selectedItemProperties, resource, toolBarOptions, selected }) {
        return {
            methodsByItem: [],
            items: null
        };
    }

    cutPasteAllocationOnCell({ selectedItemProperties, targetProperties, allocation, toolBarOptions, selected }) {
        const methodsByItem = [];
        let _allocation = {
            Id: selected ? allocation.Id : 0,
            JobActivity: { Id: allocation.JobActivity.Id },
            ObjectType: 'Allocation',
            Name: allocation.Name,
            Shift: { Id: allocation.Shift.Id },
            Description: allocation.Description,
            Tags: allocation.Tags,
            JobCosts: allocation.JobCosts
        };

        if (toolBarOptions.copy) {
            _allocation.Id = 0;
        }
        if (_allocation.Id === 0) {
            _allocation.Tags = _allocation.Tags.map((tag) => ({ ...tag, Id: 0 }));
        }
        if (targetProperties.ObjectType === 'ResourceType') {
            return {
                methodsByItem: [],
                items: null
            };
        }

        methodsByItem.push({
            indices: [0],
            methods: [
                {
                    name: 'paste',
                    args: {
                        allocationId: allocation.Id,
                        start: flow(
                            parseDate,
                            format("yyyy-MM-dd'T'00:00:00")
                        )(selectedItemProperties.CurrentLifespan.Start),
                        end: selected
                            ? flow(
                                  parseDate,
                                  addDays(1),
                                  format("yyyy-MM-dd'T'00:00:00")
                              )(allocation.CurrentLifespan.End)
                            : flow(
                                  parseDate,
                                  addDays(1),
                                  format("yyyy-MM-dd'T'00:00:00")
                              )(selectedItemProperties.CurrentLifespan.End),
                        target: flow(
                            parseDate,
                            format("yyyy-MM-dd'T'00:00:00")
                        )(targetProperties.CurrentLifespan.Start),
                        copy: toolBarOptions.copy
                    }
                }
            ]
        });
        // the selectedItemProperties contains a Resource property if dragged from the resource schedule.
        // if dragged from the job schedule, it contains a JobActivity.
        if (selectedItemProperties.Resource) {
            methodsByItem.push({
                indices: [0],
                methods: [
                    {
                        name: 'replaceResources',
                        args: {
                            originalIds: [selectedItemProperties.Resource.Id],
                            newIds: [targetProperties.Resource.Id],
                            start: flow(
                                parseDate,
                                format("yyyy-MM-dd'T'00:00:00")
                            )(targetProperties.CurrentLifespan.Start),
                            end: flow(
                                parseDate,
                                addDays(1),
                                format("yyyy-MM-dd'T'00:00:00")
                            )(targetProperties.CurrentLifespan.Start)
                        }
                    }
                ]
            });
            if (selected) {
                delete methodsByItem[1].methods[0].args.start;
                delete methodsByItem[1].methods[0].args.end;
            }
        }
        if (selectedItemProperties.JobActivity) {
            methodsByItem.push({
                indices: [0],
                methods: [
                    {
                        name: 'addResources',
                        args: {
                            resourceIds: [targetProperties.Resource.Id],
                            start: flow(
                                parseDate,
                                format("yyyy-MM-dd'T'00:00:00")
                            )(targetProperties.CurrentLifespan.Start),
                            end: flow(
                                parseDate,
                                addDays(1),
                                format("yyyy-MM-dd'T'00:00:00")
                            )(targetProperties.CurrentLifespan.Start)
                        }
                    }
                ]
            });
            if (selected) {
                delete methodsByItem[1].methods[0].args.start;
                delete methodsByItem[1].methods[0].args.end;
            }
        }

        return {
            methodsByItem: methodsByItem,
            items: [_allocation]
        };
    }

    cutPasteTaskDayOnCell({ selectedItemProperties, targetProperties, taskDay, toolBarOptions, selected }) {
        const _taskDay = {
            ParentId: 0, // add taskDay to allocation.
            Id: toolBarOptions.copy ? 0 : taskDay.Id,
            Principals: [{ Id: targetProperties.Resource.Id }],
            CurrentLifespan: {}
        };
        const days = flow(
            parseDate,
            differenceInCalendarDays(parseDate(taskDay.CurrentLifespan.Start))
        )(targetProperties.CurrentLifespan.Start);
        _taskDay.CurrentLifespan.Start = flow(
            parseDate,
            addDays(days),
            format("yyyy-MM-dd'T'HH:mm:00")
        )(taskDay.CurrentLifespan.Start);
        _taskDay.CurrentLifespan.End = flow(
            parseDate,
            addDays(days),
            format("yyyy-MM-dd'T'HH:mm:00")
        )(taskDay.CurrentLifespan.End);
        const methods = [
            {
                name: 'addTaskDays',
                args: {
                    taskDays: [_taskDay]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: methods
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: taskDay.JobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    // pasteResourceOnCell({ targetProperties, resource, toolBarOptions, selected }) {
    pasteJobActivityOnCell({ targetProperties, jobActivity, toolBarOptions, selected }) {
        if (targetProperties.ResourceType) {
            return {
                methodsByItem: [],
                items: null
            };
            // const genericAllocation = [
            //     {
            //         name: 'setSpans',
            //         args: {
            //             resourceQuantitySpans: [
            //                 {
            //                     //SupplierId: targetProperties.defaultCompanyId,
            //                     ResourceSubtypeId: get('ResourceType.ResourceSubtypes.0.Id', targetProperties),
            //                     Amount: 1,
            //                     Range: {
            //                         start: flow(
            //                             parseDate,
            //                             format("yyyy-MM-dd'T'00:00:00")
            //                         )(targetProperties.CurrentLifespan.Start),
            //                         end: flow(
            //                             parseDate,
            //                             addDays(1),
            //                             format("yyyy-MM-dd'T'00:00:00")
            //                         )(targetProperties.CurrentLifespan.Start)
            //                     },
            //                     ResourceIds: []
            //                 }
            //             ]
            //         }
            //     }
            // ];
            // return {
            //     methodsByItem: [
            //         {
            //             indices: [0],
            //             methods: genericAllocation
            //         }
            //     ],
            //     items: [
            //         {
            //             Id: 0,
            //             JobActivity: { Id: jobActivity.Id },
            //             ObjectType: 'Allocation'
            //         }
            //     ]
            // };
        }
        const resourceAllocation = [
            {
                name: 'setSpans',
                args: {
                    resourceSpans: [
                        {
                            Principal: {
                                Id: targetProperties.Resource.Id
                            },
                            Range: {
                                start: flow(
                                    parseDate,
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start),
                                end: flow(
                                    parseDate,
                                    addDays(1),
                                    format("yyyy-MM-dd'T'00:00:00")
                                )(targetProperties.CurrentLifespan.Start)
                            },
                            Auxiliaries: [
                                {
                                    Id:
                                        targetProperties.Auxiliaries.length === 1
                                            ? targetProperties.Auxiliaries[0].Id
                                            : undefined
                                }
                            ]
                        }
                    ]
                }
            }
        ];
        return {
            methodsByItem: [
                {
                    indices: [0],
                    methods: resourceAllocation
                }
            ],
            items: [
                {
                    Id: 0,
                    JobActivity: { Id: jobActivity.Id },
                    ObjectType: 'Allocation'
                }
            ]
        };
    }

    // /**
    //  * paste jobActivity by creating new allocations from the resource in the target cell.
    //  * @param targetProperties
    //  * @param jobActivities
    //  * @returns {*}
    //  * @private
    //  */
    // pasteJobActivityOnCell2({ selectedItemProperties, targetProperties, jobActivity, toolBarOptions, selected }) {
    //     // for now, skip drop on ResourceType. This should create a generic allocation in the future.
    //     if (targetProperties.ResourceType) {
    //         return {
    //             methodsByItem: [],
    //             items: null
    //         };
    //     }
    //     const methods = [
    //         {
    //             name: 'addResources',
    //             args: {
    //                 resourceIds: [targetProperties.Resource.Id],
    //                 start: flow(parseDate, format("yyyy-MM-dd'T'00:00:00"))(targetProperties.CurrentLifespan.Start),
    //                 end: flow(
    //                     parseDate,
    //                     addDays(1),
    //                     format("yyyy-MM-dd'T'00:00:00")
    //                 )(targetProperties.CurrentLifespan.Start)
    //             }
    //         }
    //     ];
    //     return {
    //         methodsByItem: [
    //             {
    //                 indices: [0],
    //                 methods: methods
    //             }
    //         ],
    //         items: [
    //             {
    //                 Id: 0,
    //                 JobActivity: { Id: jobActivity.Id },
    //                 ObjectType: 'Allocation'
    //             }
    //         ]
    //     };
    // }

    /**
     *  Shift allocations for resources.
     * @param daysToShift - number of days to shift.
     * @param shiftAll - shift all allocations across resources
     * @param selectedItems - list of  allocations, and taskDays to shift.
     */
    shiftAllocation({ daysToShift, shiftAll, selectedItems }) {
        const shift = ({ selectedItemProperties, daysToShift, resourceIds }) => {
            const methods = [
                {
                    name: 'move',
                    args: {
                        source: format(
                            "yyyy-MM-dd'T'00:00:00",
                            parseDate(selectedItemProperties.CurrentLifespan.Start)
                        ),
                        daysToShift: daysToShift
                        // resourceIds: resourceIds
                    }
                }
            ];
            // get all future allocations that contain the resource
            return {
                filters: [
                    {
                        name: 'Range',
                        values: [
                            format("yyyy-MM-dd'T'00:00:00", parseDate(selectedItemProperties.CurrentLifespan.Start)),
                            '2999-01-01T00:00:00'
                        ]
                    },
                    {
                        name: 'ResourceIds',
                        values: resourceIds
                    }
                ],
                methodsByItem: [
                    {
                        indices: [],
                        methods: methods
                    }
                ],
                items: []
            };
        };

        selectedItems.forEach((selectedItem) => {
            const { selectedItemProperties /*item, selected*/ } = selectedItem;
            const v = shift({
                selectedItemProperties: selectedItemProperties,
                daysToShift: daysToShift,
                // to move all resources based on shiftAll, pass undefined. ignore this for now.
                resourceIds: [selectedItemProperties.Resource.Id]
            });
            this.save('CxAllocation', v.items, v.methodsByItem, v.filters).catch((error) => console.log(error));
        });
    }
}

export class CellDragDrop extends CellDragDropMixin(DragDrop) {}
export class AllocationDragDrop extends AllocationDragDropMixin(DragDrop) {}
export class Actions extends ActionsMixin(DragDrop) {}
