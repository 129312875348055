import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import { getSelectedItems, SCHEDULECACHE } from 'client-shared/utility';
import { SelectableItem } from 'client-shared/components/SelectableItem';
import StopIcon from '@mui/icons-material/Stop';
import { Hover } from 'client-shared/components/Hover';
import { get } from 'lodash/fp';
import { Typography } from '@mui/material';

const styles = {
    cell: {
        maxHeight: '250px',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    taskDay: {
        paddingLeft: '20px',
        margin: 0,
        backgroundColor: 'transparent'
        //borderStyle: 'solid',
        //borderWidth: '1px',
        //borderColor: 'lightgray',
        //width: 'fit-content'
    }
};

export const GridTaskDays = ({
    client,
    targetProperties,
    taskDays,
    cellDragDrop,
    sortOrder = ['ActionType', 'DisplayName'],
    onOpenEditor
}) => {
    const _display =
        (foreColor, backColor, actionStatusBackColor) =>
        ({ item, selected }) => {
            return (
                <div style={{ display: 'flex' }}>
                    {actionStatusBackColor && <StopIcon fontSize="small" style={{ color: actionStatusBackColor }} />}
                    <Typography
                        component="span"
                        fontSize="small"
                        sx={{
                            overflow: 'hidden',
                            margin: 0,
                            padding: 0,
                            color: get('ForeColor', item) || '#000000',
                            backgroundColor: selected ? 'Gainsboro' : get('BackColor', item) || '#FFFFFF'
                        }}
                    >
                        <span>
                            {`${item.Job.DisplayName}:${item.JobActivity.DisplayName} ${
                                get('Auxiliaries.0.DisplayName', item) || ''
                            }`}{' '}
                        </span>
                    </Typography>
                    <div>
                        <Hover message={item.Description} />
                    </div>
                </div>
            );
        };
    if (!taskDays.length) {
        return null;
    }

    return (
        <div style={styles.cell}>
            {sortBy(taskDays, sortOrder).map((taskDay) => {
                return (
                    <div
                        key={`${taskDay.Id}`}
                        style={styles.taskDay}
                        onDoubleClick={() =>
                            onOpenEditor(`EditCx${taskDay.ObjectType}`, {
                                open: true,
                                formQueryValues: {
                                    filters: [{ name: 'Id', values: [taskDay.Id] }]
                                }
                            })
                        }
                        draggable={true}
                        onDragStart={(ev) => {
                            const items = getSelectedItems(client, SCHEDULECACHE, [taskDay], targetProperties);
                            cellDragDrop.handleDrag(items)(ev);
                        }}
                    >
                        <SelectableItem
                            item={taskDay}
                            cacheName={SCHEDULECACHE}
                            selectedItemProperties={targetProperties}
                            component={_display(
                                taskDay.ForeColor,
                                taskDay.BackColor,
                                taskDay.ConflictIds.length ? '#FF0000' : taskDay.ActionStatus.BackColor
                            )}
                        />
                    </div>
                );
            })}
        </div>
    );
};

GridTaskDays.propTypes = {
    client: PropTypes.object.isRequired,
    targetProperties: PropTypes.object.isRequired,
    taskDays: PropTypes.array,
    cellDragDrop: PropTypes.func.isRequired,
    sortOrder: PropTypes.array,
    display: PropTypes.func,
    onOpenEditor: PropTypes.func
};
