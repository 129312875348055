import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { AutoComplete } from 'client-shared/components/form';
import { string, number, array, boolean } from 'yup';
import { Section } from 'client-shared/components/Section';
import { TextField } from 'formik-mui';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { get, flow, keys, entries } from 'lodash/fp';
const map = require('lodash/fp/map').convert({ cap: false });

export const recurranceValidationSchema = {
    _recur: boolean(),
    _interval: string().when('_recur', {
        is: true,
        then: (schema) => schema.required('Required')
    }),
    _increment: number().when('_recur', {
        is: true,
        then: (schema) => schema.moreThan(0, 'Must be greater than 0').required('Required')
    }),
    _days: array().when('_recur', {
        is: true,
        then: (schema) => schema.min(1, 'At least one day is required')
    })
};

const DaysOfWeekEditor = ({ form, field, classes, label = '' }) => {
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    const [state, setState] = React.useState({
        Su: false,
        M: false,
        T: false,
        W: false,
        Th: false,
        F: false,
        Sa: false
    });

    useEffect(() => {
        const days = [];
        map((day, index) => (day[1] ? days.push(index) : null), entries(state));
        form.setFieldValue(field.name, days);
        form.setFieldTouched(field.name, true);
    }, [state]);

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    return (
        <FormControl error={showError}>
            <FormGroup row>
                {flow(
                    keys,
                    map((day, key) => (
                        <FormControlLabel
                            key={key}
                            control={<Checkbox checked={state[day]} onChange={handleChange} name={day} />}
                            label={day}
                        />
                    ))
                )(state)}
            </FormGroup>
            <FormHelperText>{fieldError}</FormHelperText>
        </FormControl>
    );
};

export const RecurranceEditor = ({ form, field, classes, label = '' }) => {
    const { values, setFieldValue } = form;
    const x = [...Array(31).keys()].map((i) => ({ Id: i + 1, _DisplayName: `${i + 1}` }));
    return (
        <Section title="Repeat">
            <div>
                <Field
                    type="text"
                    label="Interval"
                    name="_interval"
                    component={AutoComplete}
                    className={classes.selectField}
                    options={['Weeks', 'Months']}
                    disableClearable
                    onChange={(event, value) => {
                        setFieldValue('_days', []);
                    }}
                />
                <Field
                    type="number"
                    label={`Repeat every ${values._increment} ${values._interval}`}
                    name="_increment"
                    component={TextField}
                    className={classes.textField}
                    inputProps={{ min: 1, max: 100 }}
                />
                {values._interval === 'Weeks' && (
                    <Field type="text" name="_days" component={DaysOfWeekEditor} classes={classes} />
                )}
                {values._interval === 'Months' && (
                    <Field
                        type="text"
                        label="Days"
                        name="_days"
                        component={AutoComplete}
                        className={classes.selectField}
                        options={x}
                        multiple
                        allOptions={false}
                        sort={false}
                    />
                )}
            </div>
        </Section>
    );
};

RecurranceEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string
};
