/**
 * Return an avatar based on the initials of a name
 * @param name
 * @returns {{sx: {bgcolor: string}, children: string}}
 */
import { isEmpty } from 'lodash/fp';

export function smsAvatar(from, name) {
    if (!isEmpty(name))
        return {
            sx: {
                bgcolor: stringToColor(name)
            },
            children: `${name.split(' ')[1][0]}${name.split(' ')[0][0]}`
        };

    return {
        alt: `Avatar n°${from.NumericPhoneNumber + 1}`,
        src: `https://avatarz.controlboard.app/40/${from.NumericPhoneNumber}@adorable.png`
    };
}

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
