/**
 * Created by mark on 8/16/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { object, date, ref } from 'yup';
import { FormContainer } from 'client-shared/components/form';
import { usePrivilege } from 'client-shared/utility';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const validationSchema = object({
    start: date().test('match', 'At least one date is required', function (start) {
        const end = this.resolve(ref('end'));
        return start !== undefined || end !== undefined;
    }),
    end: date().test('match', 'At least one date is required', function (end) {
        const start = this.resolve(ref('start'));
        return start !== undefined || end !== undefined;
    })
});

const Form = (props) => {
    const { classes, values, setFieldValue } = props;
    return (
        <form noValidate autoComplete="off">
            {values.changeTimes === false && (
                <Field
                    type="date"
                    label="Start"
                    name="start"
                    component={TextField}
                    className={classes.dateTimeField}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            )}
            {values.changeTimes === true && (
                <Field
                    type="datetime-local"
                    label="Start"
                    name="start"
                    component={TextField}
                    className={classes.dateTimeField}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            )}
            {values.changeTimes === false && (
                <Field
                    type="date"
                    label="End"
                    name="end"
                    component={TextField}
                    className={classes.dateTimeField}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            )}
            {values.changeTimes === true && (
                <Field
                    type="datetime-local"
                    label="End"
                    name="end"
                    component={TextField}
                    className={classes.dateTimeField}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            )}
            <Field
                type="checkbox"
                Label={{ label: 'Change Times' }}
                name="changeTimes"
                component={CheckboxWithLabel}
                onClick={(event) => {
                    let start;
                    let end;
                    if (values.start) {
                        start = event.target.checked ? `${values.start}T00:00` : values.start.slice(0, 10);
                    }
                    if (values.end) {
                        end = event.target.checked ? `${values.end}T00:00` : values.end.slice(0, 10);
                    }
                    setFieldValue('start', start);
                    setFieldValue('end', end);
                }}
            />
        </form>
    );
};

export const EditSetRangeAllocation = ({ actions, open, onClose }) => {
    // const client = useApolloClient()
    const [privilege] = usePrivilege('Allocation');
    // let selectedItems = getSelectedItems(client, SCHEDULECACHE);
    let _submitForm;
    const getSubmitForm = (submitForm) => {
        _submitForm = submitForm;
    };
    const submitForm = () => {
        if (_submitForm) {
            _submitForm();
        }
    };
    return (
        <Dialog open={open}>
            <DialogContent>
                This will set the start and end date range on all selected allocations. To change the times, check
                Change Times.
            </DialogContent>
            <DialogContent>
                <FormContainer
                    Form={Form}
                    validationSchema={validationSchema}
                    initialValues={{ start: '', end: '', changeTimes: false }}
                    data={{}}
                    onSubmit={(formValues) => {
                        actions.handleSetRangeAllocation(formValues);
                        onClose();
                    }}
                    getSubmitForm={getSubmitForm}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitForm} color="primary" disabled={privilege}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditSetRangeAllocation.propTypes = {
    actions: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
