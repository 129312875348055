import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, reduce, entries, flow, sortBy, filter, join, map } from 'lodash/fp';
import { useApolloClient } from '@apollo/client';
import { query, createFilters, parseDate } from 'client-shared/utility';
import { Typography } from '@mui/material';
import { format } from 'date-fns/fp';
import { gql } from 'graphql-tag';

const dataQuery = gql`
    query ($filters: [FilterInput]) {
        cxTimeEntrys(filters: $filters) {
            Id
            Punch
            Minutes
            JobCosts {
                DisplayName
                Description
            }
            PunchStart
        }
    }
`;

export const Duration = ({ minutes }) => {
    const hours = Math.floor(minutes / 60);
    const _minutes = minutes % 60;
    return <span style={{ fontWeight: 'bold', paddingLeft: '10px' }}>{`${hours}h ${_minutes}m`}</span>;
};

export const TotalsDisplay = ({ values }) => {
    const client = useApolloClient();
    const [total, setTotal] = useState({});
    useEffect(() => {
        (async () => {
            // get the filters
            values.isvoid = false;
            const filters = createFilters(values);
            const { data } = await query(client, dataQuery, filters, true);
            setTotal(totals(data[Object.keys(data)[0]]));
        })();
    }, [client, values]);

    const totalsDisplay = flow(
        entries,
        sortBy(([key]) => key),
        (values) => {
            return values;
        },
        map(([key, value]) => (
            <div key={key}>
                {`${key}:`}
                <Duration minutes={value} />
            </div>
        ))
    )(total);
    return (
        <Typography variant="caption" display="block" gutterBottom>
            {totalsDisplay}
        </Typography>
    );
};

TotalsDisplay.propTypes = {
    values: PropTypes.object.isRequired
};

export const totals = (timeEntries) => {
    const reducer = (previousValue, currentValue) => {
        previousValue[get('JobCost.0.DisplayName', currentValue)] =
            previousValue[get('JobCost.0.DisplayName', currentValue)] || 0;
        previousValue[get('JobCost.0.DisplayName', currentValue)] += currentValue.Minutes;
        previousValue['Total'] += currentValue.Minutes;
        return previousValue;
    };
    return reduce(reducer, { Total: 0 }, timeEntries);
};

export const PunchOutWarning = ({ values }) => {
    const client = useApolloClient();
    const [timeEntries, setTimeEntries] = useState([]);
    useEffect(() => {
        (async () => {
            // get the filters
            const filters = createFilters(values);
            const { data } = await query(client, dataQuery, filters, true);
            setTimeEntries(filter((timePunch) => timePunch.Punch === 'PunchedIn', data[Object.keys(data)[0]]));
        })();
    }, [client, values]);
    if (!timeEntries.length) {
        return null;
    }
    const dates = flow(
        map((timeEntry) => format('PPpp', parseDate(timeEntry.PunchStart))),
        join(', ')
    )(timeEntries);

    return (
        <Typography variant="caption" display="block" gutterBottom sx={{ color: 'red' }}>
            You are punched in on {dates}. Please go back to this date (filter on top) and punch out.
        </Typography>
    );
};

/**
 * if this filter returns true for any item in the group, the entire group of taskDays is true.
 * @param scope - determines what data a particular user can view. manager can view everything,
 * job can view the jobs the user manages.
 * @param user
 * @param showAll
 * @returns {function(*): (boolean|*)}
 */
export const filterComparator = (scope, user, showAll) => (taskDay) => {
    // exclude all tasks whose status name begins with an asterisk or is inactive.
    if (get('ActionStatus.Name.0', taskDay) === '*' || get('ActionStatus.StatusType', taskDay) === 'Inactive') {
        return false;
    }
    // display everything to an administrator or mobile manager user.
    if (showAll || scope === 'manager') {
        return true;
    }

    // display all jobs managed by the logged in user.
    if (scope === 'job' && get('Party.Id', user) === get('Allocation.Job.Manager.Id', taskDay)) {
        return true;
    }
    // only display the currently logged in user tasks.
    return taskDay.ObjectType === 'ActualTaskDay' && get('Principals.0.Id', taskDay) === get('ResourceId', user);
};
