import { gql } from '@apollo/client';
import { any, curry, get, map, reject, size } from 'lodash/fp';
import { CxTextMessageFragment } from '../../utility/fragments';
import { evalQuery, getGraphqlHelper, makeVerbOperation } from '../../gqlhelpers/queryHelpers';

const mutation = gql`
    mutation sendSms($data: SmsInput!) {
        sendSms(data: $data)
    }
`;

/**
 * Runs the mutation that sends an sms
 * client: an Apollo Client
 * domain: the customer domain, eg localhost, customer1.controlboard.app
 * from: the number from (you can get this from the user context for now
 * to: the number to
 * text: the text
 */
export const sendSMS = curry((client, customer, sender, recipient, text) =>
    client.mutate({
        mutation,
        variables: {
            data: {
                customer,
                sender,
                recipient,
                text
            }
        }
    })
);

export const markMessageAsSeen = curry((client, partyId, messages) => {
    const unread = reject((each) => any((e) => get('Reader.Id', e) === partyId, get('Readers', each)), messages);
    const ids = map(get('Id'), unread);
    if (size(ids) === 0) return;
    const GQL = getGraphqlHelper('CxTextMessage');
    const { methodsByItem, filters } = makeVerbOperation('addReader', {
        ids: ids,
        args: { partyId },
        preserve: true
    });
    evalQuery(client, 'Id', GQL.entityClass, [], methodsByItem, filters);
});

export const textMessageQuery = gql`
    ${CxTextMessageFragment}
    query NewMessages($filters: [FilterInput]) {
        cxTextMessages(filters: $filters) {
            ...CxTextMessageFragment
        }
    }
`;
