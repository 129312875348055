import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { EditShiftAllocation } from './EditShiftAllocation';
import { EditSetRangeAllocation } from './EditSetRangeAllocation';
import { EditSetActionStatusAllocation } from './EditSetActionStatusAllocation';
import { EditCopyAllocation } from './EditCopyAllocation';
import { EditAutoSchedule } from './EditAutoSchedule';
import { EditResolveConflicts } from './EditResolveConflicts';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import StartIcon from '@mui/icons-material/Start';
import DoneIcon from '@mui/icons-material/Done';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { ToolBarOptions } from './ToolBarOptions';
import { SMSBatchSend } from 'client-shared/components/SMSBatchSend';
import { SMSFilter } from './smsfilter';
import { undo } from 'client-shared/components/undo';
import { useApolloClient } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import { withEditor } from 'client-shared/entities/withEditor';
import { get } from 'lodash/fp';
import { getConfigValue, UserContext } from 'client-shared/utility';

const ActionIconButton = React.forwardRef(({ children, ...other }, ref) => (
    <IconButton ref={ref} size="large" {...other}>
        {children}
    </IconButton>
));

const ToolBarActions_ = ({ actions, onOpenEditor }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const userId = get('userId', user);
    const config_configValue = getConfigValue('config.toolbaractions', user);
    const autoSchedule = get('AutoSchedule', config_configValue);

    const [openForm, setOpenForm] = useState(null);

    const handleOpen = (form) => {
        setOpenForm(form);
    };

    const handleClose = () => {
        setOpenForm(null);
    };

    return (
        <div>
            <EditShiftAllocation actions={actions} open={openForm === 'EditShiftAllocation'} onClose={handleClose} />
            <EditSetRangeAllocation
                actions={actions}
                open={openForm === 'EditSetRangeAllocation'}
                onClose={handleClose}
            />
            <EditSetActionStatusAllocation
                actions={actions}
                open={openForm === 'EditSetActionStatusAllocation'}
                onClose={handleClose}
            />
            <SMSBatchSend open={openForm === 'SmsBatchSend'} onClose={handleClose} Filter={SMSFilter} />
            <EditCopyAllocation actions={actions} open={openForm === 'EditCopyAllocation'} onClose={handleClose} />
            <EditAutoSchedule actions={actions} open={openForm === 'EditAutoSchedule'} onClose={handleClose} />
            <EditResolveConflicts actions={actions} open={openForm === 'EditResolveConflicts'} onClose={handleClose} />
            <Paper elevation={1}>
                <Tooltip title="Change allocation date range">
                    <ActionIconButton
                        onClick={() => {
                            handleOpen('EditSetRangeAllocation');
                        }}
                    >
                        <SettingsEthernetIcon />
                    </ActionIconButton>
                </Tooltip>
                <Tooltip title="Create a new job">
                    <ActionIconButton
                        onClick={() => {
                            onOpenEditor('EditCxJob', {
                                formQueryValues: {
                                    filters: [{ name: 'Id', values: ['-1'] }]
                                }
                            });
                        }}
                    >
                        <AddIcon />
                    </ActionIconButton>
                </Tooltip>
                <Tooltip title="Shift allocations one or more days">
                    <ActionIconButton
                        onClick={() => {
                            handleOpen('EditShiftAllocation');
                        }}
                    >
                        <StartIcon />
                    </ActionIconButton>
                </Tooltip>
                <Tooltip title="Text schedule to field personnel">
                    <ActionIconButton
                        onClick={() => {
                            handleOpen('SmsBatchSend');
                        }}
                    >
                        <TextsmsOutlinedIcon />
                    </ActionIconButton>
                </Tooltip>
                <Tooltip title="Copy a range of allocations to another day">
                    <ActionIconButton
                        onClick={() => {
                            handleOpen('EditCopyAllocation');
                        }}
                    >
                        <ContentCopyIcon />
                    </ActionIconButton>
                </Tooltip>
                <Tooltip title="Set allocation status">
                    <ActionIconButton
                        onClick={() => {
                            handleOpen('EditSetActionStatusAllocation');
                        }}
                    >
                        <DoneIcon />
                    </ActionIconButton>
                </Tooltip>
                <Tooltip title="Undo deleted allocations">
                    <ActionIconButton
                        onClick={() => {
                            undo(client, userId);
                        }}
                    >
                        <UndoIcon />
                    </ActionIconButton>
                </Tooltip>
                {autoSchedule && (
                    <Tooltip title="Auto schedule selected jobs and resources">
                        <ActionIconButton
                            onClick={() => {
                                handleOpen('EditAutoSchedule');
                            }}
                        >
                            <AutoFixHighIcon />
                        </ActionIconButton>
                    </Tooltip>
                )}
                <Tooltip title="Resolve Conflicts">
                    <ActionIconButton
                        onClick={() => {
                            handleOpen('EditResolveConflicts');
                        }}
                    >
                        <EventBusyIcon />
                    </ActionIconButton>
                </Tooltip>
                <ToolBarOptions sx={{ paddingLeft: '18px' }} />
            </Paper>
        </div>
    );
};

ToolBarActions_.propTypes = {
    actions: PropTypes.object.isRequired
};

export const ToolBarActions = withEditor(ToolBarActions_);
