import { gql } from 'graphql-tag';
export const FormQuery = gql`
    query ($filters: [FilterInput]) {
        cxJobStatuss(filters: $filters) {
            Id
            Name
            Description
            ForeColor
            BackColor
            ObjectType
            StatusType
            Active
            Default
        }
    }
`;
