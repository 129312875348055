import React from 'react';
import { FormContainer } from 'client-shared/components/form';
import { useApolloClient } from '@apollo/client';
import { DragDropBase, ActionsMixin, pickFromTemplate } from 'client-shared/utility';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';

export const withSave =
    (Component) =>
    ({ id, objectName, inputModel, initialValues, validationSchema }) => {
        const client = useApolloClient();
        const handleSubmit = (formValues) => {
            class Actions extends ActionsMixin(DragDropBase) {}
            const actions = new Actions(client);
            const _formValues = pickFromTemplate(formValues, inputModel);
            return actions.handleBulkEdit({
                objectName: objectName,
                ids: [id],
                properties: { ..._formValues }
            });
        };

        const Form = (props) => {
            const handleSave = () => {
                props.submitForm().catch((error) => console.log(error));
            };
            return (
                <form noValidate autoComplete="off">
                    <Component {...props} />
                    {/*<Button onClick={handleSave} color="primary" disabled={!props.dirty}>*/}
                    {/*    Save*/}
                    {/*</Button>*/}
                    <IconButton onClick={handleSave} color="primary" disabled={!props.dirty}>
                        <SaveIcon />
                    </IconButton>
                </form>
            );
        };
        return (
            <FormContainer
                Form={Form}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                elevation={0}
            />
        );
    };
