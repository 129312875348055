import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { curry } from 'lodash/fp';
import { pipeP } from 'global-shared/utils/utils';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const useModal = () => {
    const [state, setState] = useState({ visible: false, fn: toggle });
    /**
     * Pass in a handler function
     * @param fn
     */
    function toggle(fn) {
        setState(fixState(fn));
    }

    const fixState = curry((fn, current) => ({ visible: !current.visible, fn: fn ? pipeP(fn, toggle) : current.fn }));

    return [state, toggle];
};

export const DialogYesNo = ({ state, text, left, right }) =>
    state.visible
        ? ReactDOM.createPortal(
              <React.Fragment>
                  <Dialog
                      open={true}
                      style={{ zIndex: '10002' }}
                      //onClose={handleClose}
                      aria-describedby="alert-dialog-description"
                  >
                      <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                              {text || 'Do you want to?'}
                          </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={(_) => state.fn('left')} color="primary">
                              {left || 'Yes'}
                          </Button>
                          <Button onClick={(_) => state.fn('right')} color="primary" autoFocus>
                              {right || 'No'}
                          </Button>
                      </DialogActions>
                  </Dialog>
              </React.Fragment>,
              document.body
          )
        : null;

const useStyles = makeStyles((theme) => ({
    paper: {
        overflowY: 'unset'
    },
    customizedButton: {
        position: 'absolute',
        left: '95%',
        top: '0%',
        backgroundColor: 'lightgray',
        color: 'gray'
    }
}));
export const ParentDialog = ({ state, child }) => {
    const classes = useStyles();
    if (state.visible)
        return (
            <Dialog open={true} fullScreen={true} classes={{ paper: classes.paper }}>
                <DialogContent>{child}</DialogContent>
                <DialogActions>
                    <IconButton autoFocus onClick={state.fn()} color="primary" className={classes.customizedButton}>
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
                {/* Dialog content */}
            </Dialog>
        );
};
