import React, { useState, useEffect, useContext } from 'react';
import { Filter } from './Filter';
import { useApolloClient } from '@apollo/client';
import { evalQuery } from 'client-shared/gqlhelpers/queryHelpers';
import { map, get, keyBy, size, curry, sortBy, pipe, keys } from 'lodash/fp';
import { pLog, renameKeys } from 'global-shared/utils/utils';
import Scheduler, {
    getConfig,
    entityToEvent,
    prepareTimelineDate,
    getCalendarDays,
    adjustTimelineColorsFlat
} from 'client-shared/components/timeline';
import { extractLocalConfigs, getRangeStartEnd, usePrivilege, UserContext } from 'client-shared/utility';
import { withEditor } from 'client-shared/entities/withEditor';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { getShift } from 'client-shared/utility/authHelpers';

const Index = () => {
    return (
        <React.Fragment>
            <Filter>
                {({ values: variables, data: allocations, event, rawData }) => {
                    const { end } = getRangeStartEnd(variables._start, variables._periods, {
                        interval: variables._interval
                    });
                    variables.start = variables._start;
                    variables.end = end;
                    variables._periods = getCalendarDays(variables);
                    variables._x_unit = variables._interval.toLowerCase().slice(0, -1);
                    const config = getConfig(extractLocalConfigs(variables), {
                        x_size: variables._periods,
                        y_property: 'crewId',
                        scrollable: variables._scrollable
                    });
                    return <InnerScheduler {...{ config, variables, allocations, event, rawData }} />;
                }}
            </Filter>
        </React.Fragment>
    );
};
export default Index;

const InnerScheduler_ = (props) => {
    const { config, variables, allocations, onOpenEditor, event, rawData } = props;
    const client = useApolloClient();
    const [crews, setCrews] = useState([]);
    const [user] = useContext(UserContext);
    const _config = { ...config, ...{ y_unit: map(renameKeys({ DisplayName: 'label', Id: 'key' }), crews) } };
    _config.sort = (a, b) => {
        if (keys(get('sourceJob', b).length === 1)) return -1;
        else return +a.start_date > +b.start_date ? 1 : -1;
    };
    const [privilege] = usePrivilege('Allocation');
    console.log(allocations);

    useEffect(() => {
        const sortColumn = variables._orderByEffectiveLifespan ? 'EffectiveLifespan.End' : 'DisplayName';
        setCrews(sortBy(sortColumn, get('cxCrews', rawData)));
    }, [rawData, variables._orderByEffectiveLifespan]);

    function editCallback(e, more) {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: [e] }]
            }
        });
    }

    const openEditor = (e) => {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    };

    async function onEmptyClick(date, e) {
        const el = e.target.closest('.dhx_matrix_line');
        const crewId = get('dataset.sectionId', el);

        if (!crewId) return;
        const [Start, End] = getShift(date, user);
        onOpenEditor('EditCxAllocation', {
            initialValues: {
                CurrentLifespan: {
                    Start,
                    End
                },
                ResourceSpans: [
                    {
                        Principal: { Id: crewId, _DisplayName: crewMap[crewId]['DisplayName'] },
                        Range: { Start, End },
                        Auxiliaries: []
                    }
                ]
            },
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    }

    async function onEventChanged(id, event) {
        const { items, methodsByItem } = eventToMutation(event);
        await evalQuery(client, null, 'CxAllocation', items, methodsByItem).catch(() => {});
    }

    function onContextMenu(e) {
        return false;
    }

    const schedulerEvents = {
        onEventChanged,
        onContextMenu,
        onEventDeleted: pLog,
        onAfterFolderToggle: pLog,
        onBeforeEventChanged: (ev, e, is_new, original) => !privilege,
        onEmptyClick
    };

    const schedulerProps = {
        editCallback,
        drag: 'target',
        master: true,
        event
    };
    if (size(crews) < 1) return <p>No results match your filter settings...</p>;
    const crewMap = keyBy('Id', crews);
    return (
        <Box pb={5}>
            <Scheduler
                height="calc(100vh - 225px)"
                width="100%"
                title="Crew Timeline"
                startDate={get('start', variables)}
                data={pipe(map(allocationToEvent(variables)))(allocations)}
                timelineConfig={_config}
                {...schedulerProps}
                {...schedulerEvents}
            />
            <Fab color="primary" aria-label="add" style={{ float: 'right', marginRight: 10 }} onClick={openEditor}>
                <AddIcon />
            </Fab>
        </Box>
    );
};

const InnerScheduler = withEditor(InnerScheduler_);

export const allocationToEvent = curry((ctx, _in) => ({
    text: get('Job.DisplayName', _in),
    JobActivityId: get('JobActivity.Id', _in),
    crewId: get('Crew.Id', _in),
    originalCrewId: get('Crew.Id', _in),
    sourceJob: get('Job', _in),
    totalHours: ctx._mergedHours ? get('TotalMergedHours', _in) : get('TotalHours', _in),
    ...entityToEvent(_in),
    ...adjustTimelineColorsFlat(
        ctx._crewColor ? get('Crew.ForeColor', _in) : get('Job.ForeColor', _in),
        ctx._crewColor ? get('Crew.BackColor', _in) : get('Job.BackColor', _in)
    )
}));

export const eventToMutation = (event) => {
    const methodsByItem = [];
    const _allocation = {
        Id: event.id,
        JobActivity: { Id: event.JobActivityId },
        Crew: { Id: event.crewId }
    };
    methodsByItem.push({
        indices: [0],
        methods: [{ name: 'setRange', args: prepareTimelineDate(event) }]
    });
    if (event.crewId !== event.originalCrewId)
        methodsByItem.push({
            indices: [0],
            methods: [
                {
                    name: 'replaceResources',
                    args: {
                        originalIds: [event.originalCrewId],
                        newIds: [event.crewId]
                    }
                }
            ]
        });
    return {
        methodsByItem: methodsByItem,
        items: [_allocation]
    };
};
