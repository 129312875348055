import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from 'react-router-dom';
import { IconSet } from './iconSet';
import { UxContext } from 'client-shared/utility/context';
import { map } from 'lodash/fp';
import Stack from '@mui/material/Stack';

const Enlist = (props) => {
    const [, toggleMenu] = useContext(UxContext);

    const navigate = useNavigate();
    //console.log(navigate)
    const { items, name, direction = 'column' } = props;

    const gotoRoute = (route) => (_) => {
        toggleMenu(false)();
        navigate(route);
    };
    if (!items) return null;

    return (
        <List component={Stack} direction={direction}>
            {name && (
                <Typography component="h1" variant="subtitle2">
                    {name}
                </Typography>
            )}
            {map(
                ({ name, route, icon }) => (
                    <ListItem button key={route} onClick={gotoRoute(route)}>
                        {icon && <ListItemIcon>{IconSet(icon)}</ListItemIcon>}
                        <ListItemText primary={name} />
                    </ListItem>
                ),
                items
            )}
        </List>
    );
};

export default Enlist;
