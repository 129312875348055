/**
 * Created by mark on 12/13/2021.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxDocumentType';
import inputModel from 'cb-schema/inputmodelfull/CxDocumentType';
import { object, string } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';

const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required')
});

const Form = (props) => {
    const { classes } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <div>
                    <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="color"
                        label="ForeColor"
                        name="ForeColor"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field
                        type="color"
                        label="BackColor"
                        name="BackColor"
                        component={TextField}
                        className={classes.textField}
                    />
                    <Field type="checkbox" Label={{ label: 'Active' }} name="Active" component={CheckboxWithLabel} />
                    <Field type="checkbox" Label={{ label: 'Default' }} name="Default" component={CheckboxWithLabel} />
                    <Field
                        type="checkbox"
                        Label={{ label: 'Hidden from Mobile Users' }}
                        name="Private"
                        component={CheckboxWithLabel}
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxDocumentType = ({ open, formQueryValues, onClose }) => {
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.Private = false;
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    //initialValues.Default = false
    return (
        <DialogForm
            open={open}
            title="Document Types"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxDocumentType"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxDocumentType.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
