import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { round } from 'lodash';
import { getSelectedReportProperties, Types } from 'client-shared/utility';
const map = require('lodash/fp/map').convert({ cap: false });

/**
 * return the report property values as a collection formatted for a react cell.
 * @param reportProperties
 * @param reportObject
 * @param unit
 * @param summaryIntervalValues
 * @returns {*[]}
 */
export const Report = ({ reportProperties, reportObject, unit, summaryIntervalValues, label = true, style }) => {
    const formattedTotals = map((reportProperty) => {
        if (!reportProperty) {
            return null;
        }
        if (!reportObject) {
            return null;
        }
        const reportLabel = label ? `${reportProperty.label}:` : '';
        const reportValue =
            reportProperty.type === Types.STRING
                ? reportProperty.value
                : round(reportProperty.value, 2).toLocaleString();
        return (
            <div
                style={{ color: reportProperty.type.color(reportProperty.value) }}
                key={reportProperty.key}
            >{`${reportLabel} ${reportProperty.type.display(reportValue)}`}</div>
        );
    }, getSelectedReportProperties({ reportProperties: reportProperties, reportObject, unit, summaryIntervalValues }));
    return (
        <Typography fontSize="small" component="div" sx={style}>
            {formattedTotals}
        </Typography>
    );
};

Report.propTypes = {
    reportProperties: PropTypes.array,
    reportObject: PropTypes.object,
    unit: PropTypes.object,
    summaryIntervalValues: PropTypes.object,
    label: PropTypes.bool,
    style: PropTypes.object
};
