import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/fp/get';
import { Field } from 'client-shared/components/form';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { AutoComplete } from './AutoComplete';
import { object, string, number } from 'yup';
import { Section } from 'client-shared/components/Section';
import { Directions } from 'client-shared/components/serverobjects/Directions';
import { gql } from 'graphql-tag';

const StatesQuery = gql`
    query ($searchString: String) {
        cxLookupValues(objectType: "State", filters: [{ name: "String", values: ["DisplayName", $searchString] }]) {
            Id
            _Description: Description
        }
    }
`;

export const addressValidationSchema = object()
    .nullable(true)
    .shape({
        Street: string(),
        City: string(),
        State: object({
            Id: number().required('Required')
        })
            .nullable()
            .required('Required'),
        Geocode: object({
            Lat: number().required('Required - must be at least 0'),
            Lng: number().required('Required - must be at least 0')
        })
    });

export const AddressEditor = ({ form, field, classes, oneLineFormat, disabled = false, children }) => {
    // useEffect(() => {});
    // if (!field.value) {
    //     return (
    //         <Section title="Address">
    //             <button
    //                 type="button"
    //                 onClick={() => {
    //                     form.setFieldValue(field.name, CxAddress);
    //                 }}
    //             >
    //                 +
    //             </button>
    //         </Section>
    //     );
    // }
    if (!field.value) {
        return null;
    }
    return (
        <Section title="Address">
            <div style={oneLineFormat ? { display: 'flex', alignItems: 'center' } : {}}>
                <div>
                    <Field
                        type="text"
                        label="Address Line 1"
                        name={`${field.name}.Street`}
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String) {
                                cxAddresss(filters: [{ name: "String", values: ["Street", $searchString] }]) {
                                    Id
                                    Autocode
                                    Street
                                    StreetDetails
                                    City
                                    State {
                                        Id
                                        _Description: Description
                                    }
                                    ZipCode
                                    Geocode {
                                        Id
                                        Lat
                                        Lng
                                    }
                                }
                            }
                        `}
                        freeSolo={true}
                        optionLabelProperty="Street"
                        // getOptionLabel={option => {
                        //     return get('Street', option) || ''
                        // }}
                        onChange={(event, value) => {
                            if (value) {
                                form.setFieldValue(field.name, { ...value, Id: 0 });
                            }
                        }}
                        //disableClearable={true}
                        minLength={3}
                        initialize={false}
                        disabled={disabled}
                    />

                    {/*<Field*/}
                    {/*    type="text"*/}
                    {/*    label="Address Line 1"*/}
                    {/*    name={`${field.name}.Street`}*/}
                    {/*    component={TextField}*/}
                    {/*    className={classes.textField}*/}
                    {/*    disabled={disabled}*/}
                    {/*/>*/}
                </div>
                <div>
                    <Field
                        type="text"
                        label="Address Line 2"
                        name={`${field.name}.StreetDetails`}
                        component={TextField}
                        className={classes.textField}
                        disabled={disabled}
                    />
                </div>
                <Field
                    type="text"
                    label="City"
                    name={`${field.name}.City`}
                    component={TextField}
                    className={classes.textField}
                    disabled={disabled}
                />
                <Field
                    type="text"
                    label="State"
                    name={`${field.name}.State`}
                    component={AutoComplete}
                    className={classes.shortTextField}
                    optionsQuery={StatesQuery}
                    optionLabelProperty="_Description"
                    disabled={disabled}
                    disableClearable
                />
                <Field
                    type="text"
                    label="Zip Code"
                    name={`${field.name}.ZipCode`}
                    component={TextField}
                    className={classes.shortTextField}
                    disabled={disabled}
                />
                <Field
                    type="number"
                    label="Latitude"
                    name={`${field.name}.Geocode.Lat`}
                    component={TextField}
                    className={classes.shortTextField}
                    // onChange={(e) =>
                    //     !e.target.value
                    //         ? form.setFieldValue(`${field.name}.Geocode.Lat`, 0)
                    //         : form.setFieldValue(`${field.name}.Geocode.Lat`, Number(e.target.value))
                    // }
                    disabled={disabled}
                />
                <Field
                    type="number"
                    label="Longitude"
                    name={`${field.name}.Geocode.Lng`}
                    component={TextField}
                    className={classes.shortTextField}
                    // onChange={(e) =>
                    //     !e.target.value
                    //         ? form.setFieldValue(`${field.name}.Geocode.Lng`, 0)
                    //         : form.setFieldValue(`${field.name}.Geocode.Lng`, Number(e.target.value))
                    // }
                    disabled={disabled}
                />
                <Field
                    type="checkbox"
                    Label={{ label: 'Geocode automatically' }}
                    name={`${field.name}.Autocode`}
                    component={CheckboxWithLabel}
                    disabled={disabled}
                />
            </div>
            <Directions geocode={get(`${field.name}.Geocode`, form.values)} target="_blank" />
        </Section>
    );
};

AddressEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    oneLineFormat: PropTypes.bool
};
