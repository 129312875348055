import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { Section } from 'client-shared/components/Section';
import { Description } from 'client-shared/components/serverobjects/Description';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';

export const DescriptionEditor = ({ form, field, classes, label = '', children, disabled = true, ...other }) => {
    const [edit, setEdit] = useState({
        edit: true,
        icon: <VisibilityIcon fontSize="small" />
    });

    const handleEdit = () => {
        setEdit({
            edit: !edit.edit,
            icon: edit.edit ? <EditIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />
        });
    };
    return (
        <React.Fragment>
            <Section
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" style={{ fontSize: 'small' }}>
                            {label}
                        </Typography>{' '}
                        <IconButton onClick={handleEdit}>{edit.icon}</IconButton>
                    </div>
                }
            >
                {edit.edit && (
                    <Field
                        type="text"
                        name={field.name}
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                        disabled={disabled}
                    />
                )}
                {!edit.edit && <Description description={field.value} />}
            </Section>
        </React.Fragment>
    );
};

DescriptionEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string
};
