import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Autocomplete as AutocompleteMUI,
    /*CircularProgress,*/ Checkbox,
    TextField,
    FormControl,
    Box
} from '@mui/material';
import {
    flow,
    sortBy,
    uniqBy,
    uniq,
    get,
    map,
    filter,
    join,
    truncate as truncateFp,
    find,
    isString,
    isArray,
    isEmpty,
    isObject,
    isNil,
    intersectionBy
} from 'lodash/fp';
import { useApolloClient } from '@apollo/client';
import { fieldToAutocomplete } from 'formik-mui';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { Accordion } from 'client-shared/components/Accordion';
import { query } from 'client-shared/utility';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;
const optionStyle = { margin: 0, padding: 0, /* height:'30px',*/ fontSize: '12px' };

// check if empty object, empty array, or empty string.
const isNothing = (value) => {
    if (isString(value)) return value === '';
    if (isArray(value)) return value.length === 0;
    if (isObject(value)) return isEmpty(value);
    return false;
};

const RenderInput = ({
    params,
    label,
    badges = false,
    multiple,
    getOptionLabel,
    field,
    form,
    data,
    truncate,
    fieldError,
    textFieldProps
}) => {
    let values;
    if (badges) {
        return (
            <TextField
                {...params}
                variant="standard"
                label={label}
                error={get(field.name, form.touched) && !!get(field.name, form.errors)}
                helperText={fieldError(field.name)}
            />
        );
    }
    if (multiple) {
        values = map((value) => {
            return getOptionLabel(value, data);
        }, field.value);
        values = filter((value) => value !== 'All' && value !== '', values);
        values = values.length ? join(', ', values) : '';
        params.InputProps.startAdornment = <div>{truncateFp({ length: truncate }, values)}</div>;
    }
    return (
        <TextField
            {...params}
            autoComplete="off"
            label={label}
            //variant="standard"
            error={get(field.name, form.touched) && !!get(field.name, form.errors)}
            helperText={fieldError(field.name)}
            {...textFieldProps}
            InputProps={{
                ...params.InputProps,
                // style: { fontSize: '12px' },
                endAdornment: (
                    <React.Fragment>
                        {/*{fetchData && loading ? <CircularProgress color="inherit" size={20} /> : null}*/}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                )
            }}
        />
    );
};
RenderInput.propTypes = {
    params: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    badges: PropTypes.bool.isRequired,
    multiple: PropTypes.bool.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    truncate: PropTypes.number.isRequired,
    fieldError: PropTypes.func.isRequired,
    textFieldProps: PropTypes.object
};

/**
 *use cases:
 * used for an options array of enums where the field value is set to one of the enums.
 * formik field.value: 'abc'
 *  <Field
         type="text"
         label="Action Status Type"
         name="StatusType"
         component={AutoComplete}
         className={classes.selectField}
         options={['abc', 'def', ...]}
     />

 * used for an options array of key,value pairs where the field value is set to an object with only the key.
 * formik field.value: {Id: '1'}
 *  <Field
         type="text"
         label="Action Status Type"
         name="StatusType.Id"
         component={AutoComplete}
         className={classes.selectField}
         options={[{Id: '1', _DisplayName: 'abc'}, {Id: '2', _DisplayName: 'def'}, ...]}
         optionIdProperty="Id"
         optionLabelProperty="_DisplayName"
     />

 * used for an options array of key,value pairs where the field value is set to an object with the key and value.
 * formik field.value: {Id: '1', _DisplayName: 'abc'}
 *  <Field
         type="text"
         label="Action Status Type"
         name="StatusType"
         component={AutoComplete}
         className={classes.selectField}
         options={[{Id: '1', _DisplayName: 'abc'}, {Id: '2', _DisplayName: 'def'}, ...]
         optionLabelProperty="_DisplayName"
     />

 * used for an options array of key,value pairs where the field value is set to an array of one
 * object with the key and value. the initial formik field value should be an array of 0 or 1 value.
 * formik field.value: [{Id: '1', _DisplayName: 'abc'}]
 *  <Field
         type="text"
         label="Action Status Type"
         name="StatusType"
         component={AutoComplete}
         className={classes.selectField}
         options={[{Id: '1', _DisplayName: 'abc'}, {Id: '2', _DisplayName: 'def'}, ...]
         optionLabelProperty="_DisplayName"
     />

 * used for an options array of key,value pairs where the field value is set to an array of multiple
 * objects, each with the key and value.
 * formik field.value: [{Id: '1', _DisplayName: 'abc'}, {Id: '2', _DisplayName: 'def'}, ...]
 *  <Field
         type="text"
         label="Action Status Type"
         name="StatusTypes"
         component={AutoComplete}
         className={classes.selectField}
         options={[{Id: '1', _DisplayName: 'abc'}, {Id: '2', _DisplayName: 'def'}, ...]
         optionLabelProperty="_DisplayName"
         multiple
     />

 * used for an options array of key,value pairs where the field value is set to an array of one
 * object with the key and value. the options array is populated from a query.
 * formik field.value: [{Id: '1', _DisplayName: 'abc'}]
 *  <Field
         type="text"
         label="Action Status Type"
         name="StatusType"
         component={AutoComplete}
         className={classes.selectField}
         optionsQuery={gql`
            query ($searchString: String) {
                cxActionStatuss(
                    objectType: "ActionStatus"
                    filters: [
                        { name: "String", values: ["DisplayName", $searchString] }
                    ]
                ) {
                    Id
                    _DisplayName: DisplayName
                }
            }
        `}
         optionLabelProperty="_DisplayName"
     />


 * @param optionLabelProperty - options can be objects (e.g. {Id: '1', DisplayName: 'abc'}), this is the path name of
 * the object property that is displayed in the dropdown list.
 * @param optionsQuery - grapqhl query to retrieve option values. invoked with at least one
 * argument: (searchString, ...queryVariables). this is invoked each time the user types in the autocomplete input field.
 * query ($searchString: String) {
        cxEmployees(objectType: "Employee", filters: [{ name: "String", values: ["DisplayName", $searchString] }]) {
            Person {
                Id
                Name
            }
        }
    }
 * @param queryVariables - optional function that returns additional variables passed to the optionsQuery. invoked
 * with one argument: (formik.values)
 * @param minLength - minimum number of characters the user must enter before invoking the optionsQuery.
 * @param className - css class name applied to the input field.
 * @param getOptions - optional function that returns the reformatted options collection. invoked with one argument:
 * (options). this is often used to reformat the options returned by the optionsQuery.
 * @param optionIdProperty - optional path name to option property (e.g. 'Id'). if supplied then the formik field
 * value should contain a scalar value (e.g. '1')  that is used to lookup the option label from the options
 * by optionIdProperty and optionLabelProperty.
 * ** standard AutoComplete options are below with custom implementation.
 * @param onChange - optional function called when the user selects an option. invoked with four arguments:
 * (event, values, reason, details).
 * @param groupBy - optional function that groups the options under the returned string. invoked with one argument:
 * (options).
 * @param getOptionLabel - optional function that returns the string value for a given option. it's used to fill the
 * input and the dropdown list displayed to users. it is invoked with one argument: (option).
 * @param truncate - optional number used to truncate the field value when multiple is true.
 * @param textFieldProps - optional properties applied to renderinput textfield of autocomplete.
 * @param initialize - optional boolean that determines if the field should be initialized when no
 * field value is provided.
 * @param cache - optional boolen that if true, get the options from the local cache, not the server.
 * @param allOptions - optional boolean that if false, excludes the All option when multiple is true.
 * @param hide - optional boolean to hide the input control when the options are < 2.
 * @param sort - optional boolean that indicates if options should be sorted.
 * @param badges - display selected options as badges on the input field.
 * @param props - options passed to the material-ui AutoComplete.
 * @returns {*}
 * @constructor
 */
export const AutoComplete = ({
    optionLabelProperty = '_DisplayName',
    optionIdProperty,
    optionsQuery,
    queryVariables = (values) => undefined,
    minLength = 0,
    className,
    getOptions = (options) => options,
    onChange = (event, values, reason, details) => undefined,
    groupBy,
    getOptionLabel,
    truncate = 50,
    textFieldProps,
    initialize = true,
    cache = false,
    allOptions = true,
    hide = false,
    sort = true,
    isEmptyField = (value) => isNothing(value),
    disableClearable = false,
    badges = true,
    ...props
}) => {
    const { form, field, label, multiple = false, options = [], freeSolo = false } = props;
    const client = useApolloClient();
    const [open, setOpen] = useState(false);
    const [variables, setVariables] = useState({ searchString: '' });
    const [data, setData] = useState(options);
    // true - select all, false - unselect all, undefined - standard processing.
    const [all, setAll] = useState({});
    const _groupBy = groupBy || (() => '');

    const setSelectedGroups = () => {
        const selectedGroups = {};
        const groups = flow(
            map((option) => _groupBy(option)),
            uniq
        )(data);
        groups.forEach((group) => {
            // get all options in the group.
            const selectedOptions = filter((option) => _groupBy(option) === group, data);
            const selected = intersectionBy(
                (option) => {
                    return isObject(option) ? get(optionIdProperty, option) : option;
                },
                selectedOptions,
                field.value
            );
            selectedGroups[group] = selected.length > 0;
        });
        setAll({ ...all, ...selectedGroups });
    };

    const handleAllClick = (group) => {
        if (!get(group, all)) {
            // group was selected
            // form.setFieldValue(field.name, data, false);
            form.setFieldValue(field.name, getSelectedOptions(data, field, optionIdProperty), false);
        } else {
            // all was unselected, just not toggled yet.
            form.setFieldValue(field.name, [], false);
        }
        setAll({ ...all, [group]: !get(group, all) });
    };

    const handleGroupClick = (group) => {
        // get selected options for the group.
        const selectedOptions = filter((option) => _groupBy(option) === group, data);
        // remove selectedOptions from the field value options.
        // let options = filter((option) => {
        //     const option1 = getOptionLabel(option);
        //     return !find((option) => getOptionLabel(option) === option1, selectedOptions);
        // }, field.value);
        let options = filter((option) => {
            return !find((selectedOption) => get(optionIdProperty, selectedOption) === option, selectedOptions);
        }, field.value);
        if (!get(group, all)) {
            // set field for filtered data.
            options = [...options, ...selectedOptions];
        }
        form.setFieldValue(field.name, getSelectedOptions(options, field, optionIdProperty), false);
        setAll({ ...all, [group]: !get(group, all) });
    };

    const fieldError = (fieldName) => {
        const errors = get(fieldName, form.errors);
        if (isArray(errors)) {
            return errors.map((error) => (typeof error === 'object' ? Object.values(error).join(', ') : error));
        }
        return typeof errors === 'object' ? Object.values(errors).join(', ') : errors;
    };

    /**
     * look up the label from the options by identifier.
     * @param option
     * @param options
     * @param optionIdProperty
     * @param optionLabelProperty
     * @returns {_.LodashGet1x2<unknown>|_.LodashGet1x2<*>|string}
     */
    const lookupOptionLabel = (option, options, optionIdProperty, optionLabelProperty) => {
        /* eslint-disable eqeqeq*/
        const label = find((o) => {
            return (
                get(optionIdProperty, o) ==
                (get(optionIdProperty, option) !== undefined ? get(optionIdProperty, option) : option)
            );
        }, options);
        if (!label) {
            console.log(`option: ${JSON.stringify(option)} not found in options: ${JSON.stringify(options)}`);
        }
        return !isNil(get(optionLabelProperty, label))
            ? get(optionLabelProperty, label)
            : !isNil(get(optionLabelProperty, option))
              ? get(optionLabelProperty, option)
              : '';
        //return get(optionLabelProperty, label);
    };

    const _getOptionLabel = (option) => {
        if (isEmptyField(option)) {
            return '';
        }
        // if optionIdProperty then lookup option label.
        if (optionIdProperty) {
            const label = lookupOptionLabel(option, data, optionIdProperty, optionLabelProperty);
            if (!isString(label)) {
                console.log(`Check the AutoComplete optionLabelProperty on field ${field.name}, there is 
                no matching property in the options ${JSON.stringify(option)}`);
            }
            return label;
        }
        // handle special case where autocomplete is not in multiple mode, but the option is an array.
        // this can happen if the formik field value is an array (e.g. Resource = [{Id: '123', Name: 'abc'}])
        // that is empty or contains one element.
        if (isArray(option)) {
            const label = !isNil(get(`0.${optionLabelProperty}`, option))
                ? get(`0.${optionLabelProperty}`, option)
                : '';
            if (!isString(label)) {
                throw new Error(`Check the AutoComplete optionLabelPropertyon field ${field.name} , there is 
                no matching property in the option ${JSON.stringify(option)} or data ${JSON.stringify(data)}`);
            }
            return label;
        }
        const label = !isNil(get(optionLabelProperty, option)) ? get(optionLabelProperty, option) : option;
        if (!isString(label)) {
            throw new Error(`Check the AutoComplete optionLabelProperty ${optionLabelProperty} on field ${field.name}, 
            there is no matching property in the option ${JSON.stringify(option)}`);
        }
        return label;
    };

    getOptionLabel = getOptionLabel || _getOptionLabel;

    /**
     * function to return the selected options to store in the formik field. this
     * shapes the returned options to match the shape of the formik field
     * @param selectedOptions - selected option (i.e. not array if not multiple option is false) or selected options.
     * @param field - formik field.
     * @param optionIdProperty
     * @returns {*[]|_.LodashMap1x1<unknown, _.LodashGet1x1<object, keyof object>>|*}
     */
    const getSelectedOptions = (selectedOptions, field, optionIdProperty) => {
        // only keep selectedOptions that are in the list of all options if array.
        if (isArray(selectedOptions)) {
            selectedOptions = filter((option) => getOptionLabel(option), selectedOptions);
        }
        // if not multiple mode, but the field value is an array, return an array since the selectedOption will
        // be an object.
        if (isArray(field.value) && !isArray(selectedOptions)) {
            return selectedOptions ? [selectedOptions] : [];
        }
        // if optionIdProperty is supplied, return that property from the selectedOptions.
        if (optionIdProperty) {
            if (isArray(selectedOptions)) {
                // the selection object can be an object or the value of the option id [{Id: '123', Name: 'abc'}, ...]
                return map(
                    (selectedOption) =>
                        !isNil(get(optionIdProperty, selectedOption))
                            ? get(optionIdProperty, selectedOption)
                            : selectedOption,
                    selectedOptions
                );
            }
            return get(optionIdProperty, selectedOptions);
        }
        return selectedOptions;
    };
    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        if (optionsQuery) {
            return;
        }
        setData(options);
    }, [options]);

    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        let unmounted = false;
        if (!optionsQuery) {
            return;
        }
        // do not fetch data if autocomplete is not open and the field contains data and initialize is false and
        // cache is false.
        if (!(open || isEmptyField(field.value) || initialize || cache)) {
            return;
        }

        if (variables.searchString.length < minLength) {
            return;
        }
        (async () => {
            try {
                const { data } = await query(
                    client,
                    optionsQuery,
                    {
                        ...variables,
                        ...queryVariables(form.values)
                    },
                    cache
                );

                // const options = getOptions(data[Object.keys(data)[0]], field, form, data);
                const options = flow(
                    uniqBy((option) => `${_groupBy(option)}${getOptionLabel(option)}`),
                    sort ? sortBy([_groupBy, getOptionLabel]) : (data) => data
                )(getOptions(data[Object.keys(data)[0]], field, form, data));
                if (!unmounted) {
                    setData(options);
                }
            } catch (error) {
                console.log(error);
                //throw new Error(error)
            }
        })();
        return () => {
            unmounted = true;
        };
    }, [/*field.value,*/ open, variables, JSON.stringify(queryVariables(form.values))]);

    // initialize form field value.
    useEffect(() => {
        setSelectedGroups();
        if (!data.length) {
            return;
        }
        if (!initialize) {
            return;
        }

        // initialize field.value if empty and object is new (i.e. id is zero).
        if (isEmptyField(field.value) && get('Id', form.values) == 0) {
            form.setFieldValue(
                field.name,
                getSelectedOptions(
                    get('0', sortBy([(option) => (option._Default === true ? -1 : 1), getOptionLabel], data)),
                    field,
                    optionIdProperty
                ),
                false
            );
        }
    }, [/*field.value,*/ JSON.stringify(data)]);

    const handleInputChange = (event, value, reason) => {
        if (value === variables.searchString) {
            return;
        }
        if (!(disableClearable || cache)) {
            setVariables({ searchString: value });
        }
        // free solo will set the formik field to the user entered input value. handleChange will only fire
        // if the user selects an item from the dropdown or the user clears the input value.
        if (freeSolo) {
            form.setFieldValue(field.name, value === '' && initialize ? null : value, false);
            form.setFieldTouched(field.name, true);
        }
    };

    // set the formik field from the autocomplete control
    const handleChange = (event, selectedOptions, reason) => {
        if (freeSolo) {
            onChange(event, selectedOptions);
            return;
        }
        form.setFieldTouched(field.name, true);
        // if option all was selected/unselected (e.g. it is in values), do nothing since already handled in handleAllClick().
        if (!find((selectedOption) => get('Id', selectedOption) === 'All', selectedOptions)) {
            form.setFieldValue(field.name, getSelectedOptions(selectedOptions, field, optionIdProperty), false);
        }
        onChange(event, selectedOptions);
    };
    //console.log(',,,,,,,,,,,,', field);
    if (multiple && !field.value) {
        console.log(field);
        throw new Error(`AutoComplete field ${field.name} is defined as multiple but its value 
        ${JSON.stringify(form.initialValues)} is not an array. Add an initial array value to the field such as [].`);
    }
    // do not display field if only one option.
    if (hide && data.length < 2) {
        return null;
    }

    return (
        <FormControl>
            <AutocompleteMUI
                {...fieldToAutocomplete(props)}
                className={className}
                disableCloseOnSelect={multiple}
                autoSelect={false}
                size="small"
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                isOptionEqualToValue={(option, value) => {
                    if (optionIdProperty) {
                        return get(optionIdProperty, option) === value;
                    }
                    return getOptionLabel(option) === getOptionLabel(value);
                }}
                options={
                    multiple && !groupBy && allOptions
                        ? [
                              { Id: 'All', [optionLabelProperty]: 'All' },
                              ...flow(
                                  uniqBy((option) => `${_groupBy(option)}${getOptionLabel(option)}`),
                                  sort ? sortBy([_groupBy, getOptionLabel]) : (data) => data
                              )(data)
                          ]
                        : flow(
                              uniqBy((option) => `${_groupBy(option)}${getOptionLabel(option)}`),
                              sort ? sortBy([_groupBy, getOptionLabel]) : (data) => data
                          )(data)
                }
                loading={false}
                noOptionsText={`Type to search for ${label}`}
                //onInputChange={debounce(500, (event, value) => handleInputChange(event, value))}
                onInputChange={handleInputChange}
                onChange={handleChange}
                getOptionLabel={getOptionLabel}
                groupBy={_groupBy}
                //freeSolo={freeSolo}
                disableClearable={disableClearable}
                limitTags={2}
                disableListWrap
                renderInput={(params) => (
                    <RenderInput
                        params={params}
                        label={label}
                        form={form}
                        field={field}
                        badges={badges}
                        multiple={multiple}
                        getOptionLabel={getOptionLabel}
                        data={data}
                        fieldError={fieldError}
                        textFieldProps={textFieldProps}
                        truncate={truncate}
                    />
                )}
                renderGroup={({ group, children, ...other }) => {
                    if (!group) {
                        return children;
                    }
                    return (
                        <Accordion
                            key={group}
                            items={
                                <li>
                                    <Box component="span" sx={optionStyle}>
                                        {multiple && (
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={!!get(group, all)}
                                                onClick={() => handleGroupClick(group)}
                                            />
                                        )}
                                        {group}
                                    </Box>
                                </li>
                            }
                        >
                            <div style={{ marginLeft: '10px' }}>{children}</div>
                        </Accordion>
                    );
                }}
                renderOption={(props, option, { selected }) => {
                    if (multiple) {
                        if (option.Id === 'All') {
                            return (
                                <li {...props}>
                                    <Box component="span" sx={optionStyle}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={!!get('all', all)}
                                            onClick={() => handleAllClick('all')}
                                        />
                                        {getOptionLabel(option, data)}
                                    </Box>
                                </li>
                            );
                        }
                        return (
                            <li {...props}>
                                <Box component="span" sx={optionStyle}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {getOptionLabel(option, data)}
                                </Box>
                            </li>
                        );
                    }
                    return (
                        <li {...props}>
                            <Box key={option.Id} component="span" sx={optionStyle}>
                                {getOptionLabel(option, data)}
                            </Box>
                        </li>
                    );
                }}
            />
        </FormControl>
    );
};

AutoComplete.propTypes = {
    optionLabelProperty: PropTypes.string,
    optionIdProperty: PropTypes.string,
    optionsQuery: PropTypes.object,
    queryVariables: PropTypes.func,
    minLength: PropTypes.number,
    className: PropTypes.string,
    getOptions: PropTypes.func,
    onChange: PropTypes.func,
    groupBy: PropTypes.func,
    getOptionLabel: PropTypes.func,
    truncate: PropTypes.number,
    textFieldProps: PropTypes.object,
    initialize: PropTypes.bool,
    cache: PropTypes.bool,
    allOptions: PropTypes.bool,
    hide: PropTypes.bool,
    sort: PropTypes.bool,
    isEmptyField: PropTypes.func,
    disableClearable: PropTypes.bool
};
