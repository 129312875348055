import { gql } from '@apollo/client';
import { first, get, values, pipe, set, keyBy, filter, reject, pick, omit, curry } from 'lodash/fp';
import { cleanObject, getSingleDateRange } from 'client-shared/utility';
import * as timesheetQL from 'cb-schema/minimalinputfragment/CxTimeSheet';
import timeEntryProto from 'cb-schema/inputmodel/CxTimeEntry.json';
import { evalQuery, inlineFetchQuery, trimToInputModel } from 'client-shared/gqlhelpers/queryHelpers';
import { hasCostCodes } from './index';
import { onTest, pLog, setF, transformPropertyArray } from 'global-shared/utils/utils';

const itemQuery = ` Id
            ObjectType
            Description
           Tags {
                    Id
                    CustomProperty {
                        Id
                        _Name: Name
                       _AttributeType: AttributeType
                       CustomLookups {
                        Id
                        _Name: Name
                        _Description: Description
                        _DisplayName: DisplayName
                    }
                  }
                    Value
                    ArrayValue
                }
              _CustomProperties: CustomProperties {
                    Id
                    _Name: Name
                    _AttributeType: AttributeType
                            CustomLookups {
                        Id
                        _Name: Name
                        _Description: Description
                        _DisplayName: DisplayName
                    }
                 } 
                 
           Forms {
            Attachments {
                Key
                DisplayName
                LastModified
                Creator {
                    Name
                }
            }
            Id
            DisplayName
            Completed
            HasAttachments
            TemplateForm {
                Id
            }
           }
            Job {
                Id
                DisplayName
                JobActivities {
                    Id
                    DisplayName
                }
                Manager {
                Id
                Description
                }
                JobCosts {
                        Id
                        Name
                }
            }
            Crews {
                Id,
                DisplayName
            }
            CurrentLifespan {
                Start
                End
            }
            Entries {
                ApprovedByOwner
                Approvals {
                    Approver {
                        Id
                    }
                    AsOf 
            }
                Owner {
                    Id
                }
                Id
                IsVoid
                JobActivity {
                    Id
                    DisplayName
                }

                Party {
                    Id
                    DisplayName
                }
                JobCosts {
                    Id
                    Name
                }
                Hours
                EntryType {
                    Id
                    ObjectType
                    BackColor
                    Name
                }
            }
            TemplateFormPool {
                Id
                Name
                DisplayName
                Description
                Key
                MultiInstance
                Required
            }
            ScheduledEntries {
                Owner {
                    Id
                }
                Id
                JobActivity {
                    Id
                    DisplayName
                }
                
                Party {
                    Id
                    DisplayName
                }
                JobCosts {
                    Id
                    Name
                }
                Hours
                EntryType {
                    Id
                    ObjectType
                    BackColor
                    Name
                }
            }`;

export const emptyQL = gql`
    mutation x(
        $operation: String
        $objectType: String
        $methodsByItem: [methodsByItemInput]
        $filters: [FilterInput]
        $data: [CxTimeSheetInput]
    ) {
        cxTimeSheets_onSave(
            operation: $operation
            objectType: $objectType
            methodsByItem: $methodsByItem
            filters: $filters
            data: $data
        ) {
         ${itemQuery}
        }
    }
`;

const fetchForDate = async (client, projectId, forDate, crewId) => {
    let filters = [
        { name: 'Range', values: values(getSingleDateRange(forDate)) },
        { name: 'Job.Id', values: projectId }
    ];

    if (crewId) filters.push({ name: 'Crews.Id', values: [crewId] });

    const result = await client.query({
        query: inlineFetchQuery(timesheetQL.query, timesheetQL.objectType, itemQuery),
        fetchPolicy: 'no-cache',
        variables: {
            filters
        }
    });
    console.log(result);
    return first(get('data.cxTimeSheets', result));
};

export const initializeSheet = async (client, setter, projectId, forDate, crewId) => {
    let sheet1 = await fetchForDate(client, projectId, forDate, crewId);
    if (sheet1) {
        sheet1 = sheet1;
        sheet1.allEntries = [...reject('IsVoid', get('Entries', sheet1)), ...get('ScheduledEntries', sheet1)];
        sheet1.voided = filter('IsVoid', get('Entries', sheet1));
        setter(sheet1);
        return;
    }

    let obj = {
        Id: '0',
        Job: {
            Id: `${projectId}`
        },
        CurrentLifespan: getSingleDateRange(forDate)
    };

    if (crewId) obj = set('Resources', [{ Id: crewId }], obj);
    const result = await client.mutate({
        mutation: emptyQL,
        variables: {
            operation: 'edit',
            filters: [],
            data: [obj]
        }
    });
    const sheet = first(get('data.cxTimeSheets_onSave', result));
    sheet.allEntries = [...get('Entries', sheet), ...get('ScheduledEntries', sheet)];
    setter(sheet);
};

const removeEntriesWithoutCostCodes = (sheet) => {
    if (!hasCostCodes(sheet)) return set('ScheduledEntries', [], sheet);
    return sheet;
};

export const mutateTimesheet = curry(async (client, setter, sheet) => {
    try {
        const result = await client.mutate({
            mutation: emptyQL,
            variables: {
                operation: 'edit',
                filters: [],
                data: [cleanObject(sheet)]
            }
        });
        const res = first(get('data.cxTimeSheets_onSave', result));
        res.allEntries = [...reject('IsVoid', get('Entries', res)), ...get('ScheduledEntries', res)];
        setter(res);
    } catch {
        //swallow
    }
});

export const approveTimesheet = async (partyId, client, setter, sheet) => {
    const result = await evalQuery(
        client,
        itemQuery,
        'CxTimeSheet',
        [trimToInputModel('CxTimeSheet', sheet)],
        [
            {
                indices: [0],
                methods: [
                    {
                        name: 'approve',
                        args: {
                            partyIds: [partyId]
                        }
                    }
                ]
            }
        ]
    );

    const res = first(get('data.cxTimeSheets_onSave', result));
    res.allEntries = [...reject('IsVoid', get('Entries', res)), ...get('ScheduledEntries', res)];
    setter(res);
};

export const createEmptyEntry = (timesheet) => (model, row, column) => {
    if (model) return set('Id', 0, model);
    let entry = get('ScheduledEntries.0', timesheet) || get('Entries.0', timesheet);
    if (!entry) {
        entry = _createEmptyEntry(timesheet.CurrentLifespan, get('Job.Manager.Id', timesheet));
    }
    const costCodeMap = keyBy('JobCost.Id', get('Job.JobCosts', timesheet));
    const columnCostCode = costCodeMap[get('name', column)];
    return pipe(
        set('Id', '0'),
        onTest(() => columnCostCode, set('JobCosts', [get('JobCost', columnCostCode)]))
        //omit('JobActivity'),
    )(entry);
};

export const timeEntryToInputObject = pipe(
    transformPropertyArray(pick('Id'), 'Approvals'),
    transformPropertyArray(pick('Id'), 'JobCosts'),
    setF('JobCosts', reject({ Id: -1 })),
    omit('ApprovedByOwner'),
    omit('Approvals'),
    omit('Owner'),
    setF('JobActivity', pick('Id')),
    setF('Party', pick('Id')),
    setF('EntryType', pick('Id')),
    pLog
);

export const cleanTimesheet = curry((crewId, sheet, entries) =>
    pipe(
        set('Entries', entries),
        onTest(() => crewId, set('Resources.Id', crewId)),
        onTest((_in) => get('Crews.0.Id', _in), set('Resources.Id', get('Crews.0.Id', sheet))),
        omit([
            'allEntries',
            'ScheduledEntries',
            'voided',
            'Description',
            'Tags',
            '__typename',
            'ObjectType',
            'Crews',
            'TemplateFormPool',
            'Forms'
        ]),
        setF('Job', pick('Id'))
        // transformPropertyArray(pick('Id'), 'Forms')
    )(sheet)
);

export const _createEmptyEntry = (CurrentLifespan, ownerId) => {
    return pipe(
        set('EntryType.Id', ''),
        set('CurrentLifespan', CurrentLifespan),
        set('JobActivity.Id', ''),
        set('Hours', 0),
        set('JobCosts.0.Id', {})
        //pLog
    )(timeEntryProto);
};
