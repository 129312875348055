/**
 * Created by mark on 8/16/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { object } from 'yup';
import { FormContainer } from 'client-shared/components/form';
import { usePrivilege } from 'client-shared/utility';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export const validationSchema = object({});

const Form = (props) => {
    const { classes } = props;
    return (
        <form noValidate autoComplete="off">
            <Field
                type="number"
                label="Number of Days to Shift"
                name="daysToShift"
                component={TextField}
                className={classes.shortTextField}
                inputProps={{ min: -30, max: 30 }}
            />
            <Field
                type="checkbox"
                Label={{ label: 'Shift Entire Job', labelPlacement: 'start' }}
                name="shiftAll"
                component={CheckboxWithLabel}
                sx={{ paddingLeft: '25px' }}
            />
        </form>
    );
};

export const EditShiftAllocation = ({ actions, open, onClose }) => {
    const [privilege] = usePrivilege('Allocation');
    let _submitForm;
    const getSubmitForm = (submitForm) => {
        _submitForm = submitForm;
    };
    const submitForm = () => {
        if (_submitForm) {
            _submitForm();
        }
    };
    return (
        <Dialog open={open}>
            <DialogContent>
                This will shift all selected resources on a job. To shift everything on a job, check Shift Entire Job.
            </DialogContent>
            <DialogContent>
                <FormContainer
                    Form={Form}
                    initialValues={{ daysToShift: 1, shiftAll: false }}
                    data={{}}
                    onSubmit={(formValues) => {
                        actions.handleShiftAllocation(formValues);
                        onClose();
                    }}
                    getSubmitForm={getSubmitForm}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitForm} color="primary" disabled={privilege}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditShiftAllocation.propTypes = {
    actions: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
