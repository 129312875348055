import React, { useState } from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import { get } from 'lodash/fp';
import { uploadFile } from '../../attachments/attachments';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import DownloadIcon from '@mui/icons-material/Download';
import { useNotifications } from 'client-shared/components/notifier2';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { woExtension } from 'global-shared/utils/files';
import { pFetch } from '../../../utility/authHelpers';
import { handleFetchErrors, saveFile, toBlob } from '../../../utility/httpHelpers';

const useStyles = makeStyles((theme) => ({
    file: {
        marginTop: theme.spacing(2)
    },
    download: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(3)
    }
}));

/**
 * FileEditor uploads a file to aws.
 * @param form
 * @param {string} field
 * @param  bucketName {(string|boolean)} if a string that name will be used as the key.
 * a string in the form foo* will prefix an auto generated key: prefix__UUID.ext
 * False indicates the server should provide a unique name.
 * True means keep the original filename
 * @param mimeTypes
 * @param onChange -  optional change handler
 * @param other
 * @returns {*}
 * @constructor
 */
export const TemplateFormFileEditor = ({ form, field, onChange, mimeTypes, ...other }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    const [fileName, setFile] = useState(field.value ? 'Change File' : 'Upload File');
    const { openSBTransientP } = useNotifications();

    const downloadForm = (e) =>
        pFetch(`/api/file/${encodeURIComponent(form.values.Key)}`)
            .then(handleFetchErrors)
            .then(toBlob)
            .then(saveFile(form.values.Key))
            .catch(openSBTransientP('message', { variant: 'error' }));

    const handleChange = (event) => {
        if (event.target.value === '') return null;
        const upload = event.currentTarget.files[0];
        setFile(upload.name);
        const niceName = `__forms__/${woExtension(upload.name)}*`;
        return uploadFile(openSBTransientP, niceName, upload).then((res) =>
            form.setFieldValue(field.name, get('0.Key', res))
        );
    };
    const buttonColor = get('errors.file', form) ? 'secondary' : 'primary';

    // const { value /*...fieldToTextFieldProps*/ } = fieldToTextField({ form, field, ...other });
    return (
        <FormControl error={showError}>
            <label htmlFor="upload-form">
                <input
                    id="upload-form"
                    name="upload-form"
                    style={{ display: 'none' }}
                    type="file"
                    onChange={handleChange}
                    accept={mimeTypes}
                />
                <Chip
                    size="medium"
                    color={buttonColor}
                    className={classes.file}
                    label={fileName}
                    clickable={true}
                    aria-label="Upload the form template"
                />
                {form.values.Key && (
                    <IconButton color="secondary" onClick={downloadForm} aria-label="Download the form template">
                        <DownloadIcon />
                    </IconButton>
                )}
            </label>
            <FormHelperText>{fieldError}</FormHelperText>
        </FormControl>
    );
};

TemplateFormFileEditor.propTypes = {
    form: PropTypes.object.isRequired,
    onChange: PropTypes.func // optional change handler
};
