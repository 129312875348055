import React, { useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import jsreport from 'jsreport-browser-client-dist';
import { pipe, split, last } from 'lodash/fp';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { getFilter, UserContext } from 'client-shared/utility';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const Index = () => {
    // ms
    const theme = useTheme();
    const classes = useStyles(theme);
    const location = useLocation();
    const reportKey = pipe(split('/'), last)(location.pathname);
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.reports.specs', reportKey);
    const container = useRef(null);

    const renderReport = (results) => {
        jsreport.serverUrl = process.env.REACT_APP_REPORT_SERVER || window.location.origin;
        const reportRequest = {
            template: { name: filterFormDefinition.reportTemplate },
            data: { data: results }
        };

        jsreport
            .renderAsync(reportRequest)
            .then((res) => {
                // res.download('myreport.pdf');
                // res.openInWindow({ title: 'myreport' });

                let iframe = document.createElement('iframe');
                const url = res.toObjectURL();
                iframe.src = url;
                iframe.setAttribute('name', 'report');
                iframe.style.width = '100%';
                iframe.style.height = '80vh';
                iframe.style.display = 'block';

                // clear placeholder
                while (container.current.firstChild) {
                    container.current.removeChild(container.current.firstChild);
                }
                container.current.appendChild(iframe);
                //URL.revokeObjectURL(url);
            })
            .catch((error) => console.log('Error in report rendering:', error));
    };
    return (
        <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
            {({ values, data, rawData }) => {
                if (!data.length) {
                    return (
                        <div ref={container} style={{ height: '80vh' }} id="reportPlaceholder">
                            <p>No results...</p>
                        </div>
                    );
                }
                return (
                    <div className={classes.root}>
                        <div ref={container} style={{ height: '80vh' }} id="reportPlaceholder">
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                            {renderReport({
                                domain: user.customerInfo.customer,
                                variables: values,
                                data: data,
                                rawData: rawData
                            })}
                        </div>
                    </div>
                );
            }}
        </FilterFormFromJson>
    );
};

export const Report = Index;
