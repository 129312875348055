import React, { useContext, forwardRef } from 'react';
import { FilterFormGridFromJson } from 'client-shared/components/filterbar';
import { getFilter } from 'client-shared/utility';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { UserContext } from 'client-shared/utility';
const CxLookupValue = require('cb-schema/jsonschema/CxLookupValue');
const CxBatch = require('cb-schema/jsonschema/CxBatch');
const CxTemplateForm = require('cb-schema/jsonschema/CxTemplateForm');
const CxChange = require('cb-schema/jsonschema/CxChange');

const gridDefinitions = {
    CxActionStatuss: 'cxactionstatuss.js',
    CxAllocations: 'cxallocations.js',
    CxCostCodes: 'cxcostcodes.js',
    CxCompanys: 'cxcompanys.js',
    CxContacts: 'cxcontacts.js',
    CxBatch: 'cxbatches.js',
    CxCrews: 'cxcrews.js',
    CxEmployees: 'cxemployees.js',
    CxEquipments: 'cxequipments.js',
    CxHolidays: 'cxholidays.js',
    CxJobs: 'cxjobs.js',
    CxJobsNotes: 'cxjobsnotes.js',
    CxLookupValues: 'cxlookupvalues.js',
    CxApplicationlicenses: 'cxapplicationlicenses.js',
    CxMaterials: 'cxmaterials.js',
    CxResourceTypes: 'cxresourcetypes.js',
    CxShifts: 'cxshifts.js',
    CxSubcontractors: 'cxsubcontractors.js',
    CxTemplateForms: 'cxtemplateforms.js',
    CxPlants: 'cxplants.js',
    CxTaskDays: 'cxtaskdays.js',
    CxUsers: 'cxusers.js',
    CxToDos: 'cxtodos.js',
    CxCustomPropertys: 'cxcustompropertys.js',
    CxTimeEntrys: 'cxtimeentrys.js',
    CxConfigValues: 'cxconfigvalues.js',
    EmployeePayroll: 'employeepayroll.js',
    FieldSchedule: 'fieldschedule.js',
    ADPPayroll: 'adppayroll.js',
    EquipmentBilling: 'equipmentbilling.js',
    SubtypeQuantities: 'subtypequantities.js',
    CxTimeEntryTypes: 'cxtimeentrytypes.js',
    CxForecasts: 'cxforecasts.js',
    CxForms: 'cxforms.js',
    CxPartyGroups: 'cxpartygroups.js',
    CxJobCosts: 'cxjobcosts.js',
    CxJobCostsSummary: 'cxjobcostssummary.js',
    CxChanges: 'cxchanges.js',
    CxTextMessages: 'cxtextmessages.js',
    ActiveProjects: 'activeprojects.js',
    CxTemplateJobActivitys: 'cxtemplatejobactivitys.js'
};

export const getGrids = (user, more) => {
    const components = {};
    for (const [key, file] of Object.entries(gridDefinitions)) {
        components[key] = ({ history, location = {}, match, staticContext, ...other }) => {
            const filterFormGridDefinition = getFilter(user, 'customerInfo.ux.grids', file, {
                CxLookupValue,
                CxBatch,
                CxTemplateForm,
                CxChange,
                ...more
            });
            if (!filterFormGridDefinition) return null;
            filterFormGridDefinition.filterFormDefinition = {
                ...filterFormGridDefinition.filterFormDefinition,
                ...location.state,
                ...other
            };
            return (
                <div style={{ width: '98vw' }}>
                    <FilterFormGridFromJson filterFormGridDefinition={filterFormGridDefinition} />
                </div>
            );
        };
    }
    return components;
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const GridDialog = ({ open, gridName, title, onClose, ...other }) => {
    const [user] = useContext(UserContext);
    const grids = getGrids(user);
    const Grid = grids[gridName];
    if (!Grid) {
        return null;
    }
    return (
        <Dialog
            style={{ zIndex: '1100' }}
            // disableBackdropClick
            disableEscapeKeyDown
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <DialogContent>{open && gridName && <Grid {...other} />}</DialogContent>
        </Dialog>
    );
};

GridDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    gridName: PropTypes.string,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired
};
