import { gql } from 'graphql-tag';
import { TagFragment, CustomPropertyFragment } from 'client-shared/utility/fragments';

export const FormQuery = gql`
    ${TagFragment}
    query ($objectType: String = "Employee", $filters: [FilterInput]) {
        cxEmployees(objectType: $objectType, filters: $filters) {
            BirthDate
            LastName
            FirstName
            MiddleName
            PreviousExperience {
                Unit {
                    Id
                    _DisplayName: DisplayName
                }
                Quantity
            }
            IsMover
            Address {
                Id
                Autocode
                Street
                StreetDetails
                City
                State {
                    Id
                    _Description: Description
                }
                ZipCode
                Geocode {
                    Id
                    Lat
                    Lng
                }
            }
            PhoneNumbers {
                Id
                Name
                PhoneNumber
            }
            Emails {
                Id
                Name
                Email
            }
            Contacts {
                Id
                Name
                Title
                PhoneNumbers {
                    Id
                    Name
                    PhoneNumber
                }
                Emails {
                    Id
                    Name
                    Email
                }
                Address {
                    Id
                    Street
                    StreetDetails
                    City
                    State {
                        Id
                        _Description: Description
                    }
                    ZipCode
                    Geocode {
                        Id
                        Lng
                        Lat
                    }
                }
            }
            Minority {
                Id
                _DisplayName: DisplayName
            }
            Gender {
                Id
                _DisplayName: DisplayName
            }
            Unions {
                Id
                LookupValue {
                    Id
                    _DisplayName: DisplayName
                }
                CurrentLifespan {
                    Start
                    End
                }
                Description
            }
            Licenses {
                Id
                LookupValue {
                    Id
                    _DisplayName: DisplayName
                }
                CurrentLifespan {
                    Start
                    End
                }
                Description
            }
            Machines {
                Id
                _DisplayName: DisplayName
            }
            Languages {
                Id
                _DisplayName: DisplayName
            }
            TrainingCourses {
                Id
                _DisplayName: DisplayName
            }
            Skills {
                Id
                _DisplayName: DisplayName
            }
            LaborClassification {
                Id
                _DisplayName: DisplayName
            }
            Temporary
            Owner {
                Id
                _DisplayName: DisplayName
            }
            Auxiliaries {
                Id
                _DisplayName: DisplayName
            }
            ResourceSubtype {
                Id
                _Name: Name
            }
            Rates {
                Id
                RateType {
                    Id
                    _DisplayName: DisplayName
                }
                UnitCost {
                    Unit {
                        Id
                        _DisplayName: DisplayName
                    }
                    Cost {
                        Unit {
                            Id
                            _DisplayName: DisplayName
                        }
                        Quantity
                    }
                }
            }
            ForeColor
            BackColor
            AccountCode
            CurrentLifespan {
                Start
                End
            }
            Id
            Description
            DisplayName
            Divisions {
                Id
                _DisplayName: DisplayName
            }
            Tags {
                ...TagFragment
            }
            Crews {
                DisplayName
            }
        }
        cxCustomPropertys(objectType: "CustomProperty", filters: [{ name: "SupportedTypes", values: ["Employee"] }]) {
            ${CustomPropertyFragment}
        }
    }
`;
