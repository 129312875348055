/**
 *
 *
 * Copyright 2002-2022 Congistics Corporation.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, AutoComplete } from 'client-shared/components/form';
import { object, number } from 'yup';
import { withSave } from 'client-shared/components/withSave';
import gql from 'graphql-tag';

export const ActionStatusEditor = ({ id, objectName, ActionStatus }) => {
    const validationSchema = object({
        ActionStatus: object({
            Id: number().moreThan(0, 'Required')
        })
    });
    const Form = withSave((props) => (
        <Field
            type="text"
            label="Action Status"
            name="ActionStatus"
            component={AutoComplete}
            className={props.classes.shortTextField}
            optionsQuery={gql`
                query ($searchString: String) {
                    cxActionStatuss(filters: [{ name: "String", values: ["DisplayName", $searchString] }]) {
                        Id
                        _DisplayName: DisplayName
                    }
                }
            `}
            optionLabelProperty="_DisplayName"
            disableClearable
        />
    ));
    return (
        <Form
            id={id}
            objectName={objectName}
            inputModel={{ ActionStatus: { Id: 0 } }}
            initialValues={{ ActionStatus }}
            validationSchema={validationSchema}
        />
    );
};

ActionStatusEditor.propTypes = {
    id: PropTypes.string.isRequired,
    objectName: PropTypes.string.isRequired,
    ActionStatus: PropTypes.object
};
