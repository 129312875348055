import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Totals } from 'client-shared/components/totals';

export const ResourceSummary = ({ targetProperties, items, row }) => {
    return (
        <Typography fontSize="small">
            <span style={{ fontSize: '10px' }}>
                <Totals
                    allocations={items}
                    start={targetProperties.CurrentLifespan.Start}
                    counter={(taskDay) => {
                        if (taskDay.ResourceType.Id === targetProperties.ResourceType.Id) {
                            return taskDay.Amount;
                        }
                        return 0;
                    }}
                    totalResources={row.Resources.length}
                    displayKeys={['Total', 'Available', 'Generic', 'Need']}
                />
            </span>
        </Typography>
    );
};

ResourceSummary.propTypes = {
    targetProperties: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    row: PropTypes.object.isRequired
};
