import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "Forecast", $filters: [FilterInput]) {
        cxForecasts(objectType: $objectType, filters: $filters) {
            ObjectType
            Id
            Job {
                Id
                _DisplayName: DisplayName
            }
            Unit {
                Id
                _DisplayName: DisplayName
            }
            Interval
            Description
            DisplayName
            SubtypeForecasts {
                Id
                _ResourceType: ResourceType {
                    Unit {
                        Name
                    }
                }
                ResourceSubtype {
                    Id
                    _Name: Name
                }
                JobActivity {
                    Id
                    _DisplayName: DisplayName
                    _CurrentLifespan: CurrentLifespan {
                        Start
                        End
                    }
                }
                EstimatedTime
                Amount
                Shift {
                    Id
                    _DisplayName: DisplayName
                }
                CurrentLifespan {
                    Start
                    End
                }
                IntervalAmounts {
                    Amount
                    Range {
                        Start
                        End
                    }
                }
            }
        }
    }
`;
