import React from 'react';
import PropTypes from 'prop-types';
import { pickFromTemplate, DragDropBase, ActionsMixin, usePrivilege, omitEmptyProperties } from 'client-shared/utility';
import cloneDeep from 'lodash/cloneDeep';
import Button from '@mui/material/Button';
import { makeStyles, useTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import DrawerMUI from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { EditForm } from './EditForm';
import { useApolloClient } from '@apollo/client';

const drawerWidth = 350;

const handleSubmit =
    ({ client, objectName, objectType, ids, inputModel }) =>
    (properties, formikBag, touched) => {
        if (ids.length === 0) return;
        let _properties = cloneDeep(properties);
        _properties = pickFromTemplate(_properties, inputModel);
        _properties = omitEmptyProperties(_properties);
        //_properties = cleanObject2(_properties); // ugly
        class Actions extends ActionsMixin(DragDropBase) {}
        const actions = new Actions(client);
        return actions.handleBulkEdit({
            objectName: objectName,
            ids: ids,
            properties: _properties,
            objectType: objectType,
            touched: touched
        });
    };

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        justifyContent: 'flex-start'
    }
}));

/**
 * Displays a dialog formik form for bulk editing.
 * @param open - true, opens the form dialog
 * @param Form - formik snippet that contains the editor components.
 * @param validationSchema - optional yup validation schema to validate the form components.
 * @param initialValues - object containing initial values passed to form controls. these are updated by the controls.
 * @param formQuery - optional graphql query document to retrieve data used by the form controls. pass as the data
 * parameter to the Form snippet. if data is returned, this also overwrites the initialValues with the returned data.
 * @param formQueryValues - optional values passed to the form query
 * @param objectName - name of object to persist on the server.
 * @param inputModel - object properties to persist, all others are ignored.
 * @param ids - ids to bulk edit.
 * @param onClose - optional function to close the dialogue and post process.
 * @param transform - optional function to pre-process data before being persisted. one argument is passed
 * to transform which should return the pre-processed data.
 * @param onSubmit - optional function to persist data to the server, otherwise, default function will be used.
 * @param other
 * @returns {string|*}
 * @constructor
 */

export const DrawerForm = ({
    open,
    Form,
    validationSchema,
    initialValues,
    formQuery,
    formQueryValues,
    objectName,
    inputModel,
    ids,
    onClose,
    onSubmit
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const client = useApolloClient();
    const [privilege] = usePrivilege(objectName.slice(2));

    const WrappedForm = (props) => {
        const handleSave = () => {
            if (privilege) {
                return;
            }
            props
                .submitForm()
                .then(onClose)
                .catch((error) => console.log(error));
        };

        return (
            <React.Fragment>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={onClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <Button onClick={handleSave} color="primary" disabled={!props.dirty}>
                        Save
                    </Button>
                </div>
                <Divider />
                <div className={classes.drawerHeader}>
                    <Form {...props} />
                </div>
            </React.Fragment>
        );
    };
    return (
        <DrawerMUI
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
                paper: classes.drawerPaper
            }}
        >
            <EditForm
                Form={WrappedForm}
                validationSchema={validationSchema}
                initialValues={initialValues}
                formQuery={formQuery}
                formQueryValues={formQueryValues}
                objectName={objectName}
                onSubmit={
                    onSubmit ||
                    handleSubmit({ client, objectName, objectType: formQueryValues.objectType, ids, inputModel })
                }
            />
        </DrawerMUI>
    );
};

DrawerForm.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    Form: PropTypes.func.isRequired,
    validationSchema: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    formQuery: PropTypes.object.isRequired,
    formQueryValues: PropTypes.object,
    objectName: PropTypes.string.isRequired,
    inputModel: PropTypes.object.isRequired,
    ids: PropTypes.array,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func
};
