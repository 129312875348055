/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, LookupValueEditor, ResourceSubtypeEditor } from 'client-shared/components/form';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxCrew';
import inputModel from 'cb-schema/inputmodelfull/CxCrew';
import { AutoComplete, CurrentLifespanEditor, currentLifespanValidationSchema } from 'client-shared/components/form';
import { object, string, number } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import { CrewMemberEditor, crewMemberValidationSchema } from './components/crewmembereditor';
import cloneDeep from 'lodash/cloneDeep';
import { format, addDays } from 'date-fns/fp';
import { flow } from 'lodash/fp';
import { parseDate } from 'client-shared/utility';
import { Section } from 'client-shared/components/Section';
import { gql } from 'graphql-tag';

const transform = (data) => {
    if (!data.Manager || data.Manager.Id === 0) {
        data.Manager = { Id: data.Foreman.Id };
    }
    return data;
};

const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Foreman: object({
        Id: number().moreThan(0, 'Required')
    }),
    ResourceType: object({
        Id: number().moreThan(0, 'Required')
    }),
    ResourceSubtype: object({
        Id: number().moreThan(0, 'Required')
    }),
    Resources: crewMemberValidationSchema,
    CurrentLifespan: currentLifespanValidationSchema
    //Divisions: array().required('Required')
});

const Form = (props) => {
    const { classes } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Field
                    type="text"
                    label="Crew #"
                    name="AccountCode"
                    component={TextField}
                    className={classes.textField}
                />
                <Field
                    type="text"
                    label="Subtype"
                    name="ResourceSubtype"
                    component={ResourceSubtypeEditor}
                    className={classes.selectField}
                    family={['Group']}
                />
                <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
                <Field
                    type="text"
                    label="Foreman"
                    name="Foreman"
                    component={AutoComplete}
                    className={classes.selectField}
                    optionsQuery={gql`
                        query ($searchString: String, $start: String) {
                            cxEmployees(
                                filters: [
                                    { name: "String", values: ["DisplayName", $searchString] }
                                    { name: "Range", values: [$start] }
                                ]
                            ) {
                                Id
                                _DisplayName: DisplayName
                            }
                        }
                    `}
                    optionLabelProperty="_DisplayName"
                    queryVariables={() => ({
                        start: format("yyyy-MM-dd'T'00:00", new Date()),
                        end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                    })}
                />
                <Field
                    type="text"
                    label="Manager"
                    name="Manager"
                    component={AutoComplete}
                    className={classes.selectField}
                    optionsQuery={gql`
                        query ($searchString: String, $start: String) {
                            cxEmployees(
                                filters: [
                                    { name: "String", values: ["DisplayName", $searchString] }
                                    { name: "Range", values: [$start] }
                                ]
                            ) {
                                Id
                                _DisplayName: DisplayName
                            }
                        }
                    `}
                    optionLabelProperty="_DisplayName"
                    queryVariables={() => ({
                        start: format("yyyy-MM-dd'T'00:00", new Date()),
                        end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                    })}
                    initialize={false}
                />
                <Section title="Lifespan">
                    <Field type="text" name="CurrentLifespan" component={CurrentLifespanEditor} classes={classes} />
                </Section>
                <Field
                    type="color"
                    label="ForeColor"
                    name="ForeColor"
                    component={TextField}
                    className={classes.textField}
                />
                <Field
                    type="color"
                    label="BackColor"
                    name="BackColor"
                    component={TextField}
                    className={classes.textField}
                />
                <Field
                    type="text"
                    label="Divisions"
                    name="Divisions"
                    component={LookupValueEditor}
                    className={classes.selectField}
                    objectType="Division"
                    initialize={false}
                    multiple
                />

                <Field
                    type="checkbox"
                    Label={{ label: 'Update Future Allocations' }}
                    name="UpdateFutureAllocations"
                    component={CheckboxWithLabel}
                    style={{ paddingLeft: '10px' }}
                />
                <Field type="text" label="Members" name="Resources" component={CrewMemberEditor} classes={classes} />
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxCrew = ({ open, formQueryValues, onClose }) => {
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.ResourceSubtype = { Id: '0', _Name: '' };
    initialValues.Foreman = { Id: '0', _DisplayName: '' };
    initialValues.Manager = {};
    initialValues.Contact = { Id: '0', _label: '' };
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'00:00:00", new Date());
    initialValues.CurrentLifespan.End = '2999-01-01T00:00:00';
    initialValues.UpdateFutureAllocations = false;

    return (
        <DialogForm
            open={open}
            title="Crew"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={{
                ...formQueryValues,
                start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                end: flow(addDays(1), format("yyyy-MM-dd'T'00:00:00"))(parseDate(new Date()))
            }}
            objectName="CxCrew"
            inputModel={inputModel}
            onClose={onClose}
            transform={transform}
        />
    );
};

EditCxCrew.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
