import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { TextField } from 'formik-mui';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Field, AutoComplete, AddressEditor, addressValidationSchema } from 'client-shared/components/form';
import { array, date, object } from 'yup';
import { Section } from 'client-shared/components/Section';
import { format, addDays, isWithinInterval } from 'date-fns/fp';
import { get, flow } from 'lodash/fp';
import { PickupEditor, pickupValidationSchema } from './PickupEditor';
import { CxJobsQuery } from '../queries.js';
import { parseDate } from 'client-shared/utility';
// import isWithinInterval from 'date-fns/isWithinInterval';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import { UserContext } from 'client-shared/utility';

export const stopsValidationSchema = array()
    .min(1, 'At Least One Stop Is Required')
    .of(
        object({
            // Start: date().required('Required'),
            Start: date().test('match', 'The start time must be within the time of the route.', function (start) {
                const CurrentLifespan = this.from[1].value.CurrentLifespan; // get the grandparent object this.from[1]
                try {
                    // return isWithinInterval(new Date(start), {
                    //     start: new Date(CurrentLifespan.Start),
                    //     end: new Date(CurrentLifespan.End)
                    // });
                    return isWithinInterval(
                        {
                            start: new Date(CurrentLifespan.Start),
                            end: new Date(CurrentLifespan.End)
                        },
                        new Date(start)
                    );
                } catch (error) {
                    return false;
                }
            }),
            Address: addressValidationSchema,
            Dropoffs: pickupValidationSchema,
            Pickups: pickupValidationSchema
        })
    );

export const StopsEditor = ({ form, field, classes, label = '', data, ...other }) => {
    const [user] = useContext(UserContext);
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    const model = {
        Id: 0,
        Start: format("yyyy-MM-dd'T'08:00:00", parseDate(form.values.CurrentLifespan.Start)),
        Jobs: [],
        Address: {
            Id: 0,
            Autocode: true,
            Street: 'unk',
            StreetDetails: '',
            State: get('congistics.defaultCompany.Address.State', user),
            City: 'unk',
            ZipCode: '',
            Geocode: {
                Id: 0,
                Lat: 0,
                Lng: 0,
                Source: 'Action'
            }
        },
        Dropoffs: [],
        Pickups: []
    };

    return (
        <Section title="Stops">
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((activity, index) => {
                                    return (
                                        <Section key={index}>
                                            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                                <Field
                                                    type="datetime-local"
                                                    label="Start"
                                                    name={`${field.name}.${index}.Start`}
                                                    component={TextField}
                                                    className={classes.dateTimeField}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                                <Field
                                                    type="text"
                                                    label="Job"
                                                    name={`${field.name}.${index}.Jobs`}
                                                    component={AutoComplete}
                                                    className={classes.selectField}
                                                    optionLabelProperty="_DisplayName"
                                                    optionsQuery={CxJobsQuery}
                                                    queryVariables={() => ({
                                                        start: format("yyyy-MM-dd'T'00:00", new Date()),
                                                        end: flow(
                                                            addDays(90),
                                                            format("yyyy-MM-dd'T'00:00:00")
                                                        )(new Date())
                                                    })}
                                                    onChange={(event, value) => {
                                                        if (!value) {
                                                            return;
                                                        }
                                                        form.setFieldValue(`${field.name}.${index}.Address`, {
                                                            ...get('_Address', value),
                                                            Id: 0
                                                        });
                                                    }}
                                                    initialize={false}
                                                />
                                                <div>
                                                    <div>
                                                        <Field
                                                            type="text"
                                                            label="Dropoffs"
                                                            name={`${field.name}.${index}.Dropoffs`}
                                                            component={PickupEditor}
                                                            classes={classes}
                                                            data={data}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Field
                                                            type="text"
                                                            label="Pickups"
                                                            name={`${field.name}.${index}.Pickups`}
                                                            component={PickupEditor}
                                                            classes={classes}
                                                            data={data}
                                                        />
                                                    </div>
                                                </div>

                                                <div style={{ flexBasis: '100%', height: 0 }} />
                                                <Field
                                                    type="text"
                                                    label="Address"
                                                    name={`${field.name}.${index}.Address`}
                                                    component={AddressEditor}
                                                    classes={classes}
                                                    oneLineFormat={true}
                                                />
                                                {/*<div style={{ flexBasis: '100%', height: 0 }} />*/}
                                                {/*<Field*/}
                                                {/*    type="text"*/}
                                                {/*    label="Name"*/}
                                                {/*    name={`${field.name}.${index}.Name`}*/}
                                                {/*    component={TextField}*/}
                                                {/*    className={classes.textField}*/}
                                                {/*/>*/}
                                                {/*<Field*/}
                                                {/*    type="text"*/}
                                                {/*    label="Description"*/}
                                                {/*    name={`${field.name}.${index}.Description`}*/}
                                                {/*    component={TextField}*/}
                                                {/*    className={classes.textField}*/}
                                                {/*/>*/}
                                                <IconButton
                                                    onClick={() => {
                                                        arrayHelpers.remove(index);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                {index > 0 && (
                                                    <IconButton
                                                        onClick={() => {
                                                            arrayHelpers.swap(index, index - 1);
                                                        }}
                                                    >
                                                        <ArrowUpwardIcon />
                                                    </IconButton>
                                                )}
                                                {index < field.value.length - 1 && (
                                                    <IconButton
                                                        onClick={() => {
                                                            arrayHelpers.swap(index, index + 1);
                                                        }}
                                                    >
                                                        <ArrowDownwardIcon />
                                                    </IconButton>
                                                )}
                                            </div>
                                        </Section>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

StopsEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    data: PropTypes.object
};
