import { gql, useApolloClient } from '@apollo/client';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';

import Avatar from '@mui/material/Avatar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Fab from '@mui/material/Fab';
import MoreVert from '@mui/icons-material/MoreVert';

import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { UserContext } from 'client-shared/utility';
import { get, map, pipe, orderBy, find, startsWith, size, filter } from 'lodash/fp';
import { pipeP, syncCollections, makeSMSNumber } from 'global-shared/utils/utils';
import { formatDistanceStrict } from 'date-fns';
import { setState } from 'client-shared/utility/reactHelpers';
import { watchQueries, isData } from 'client-shared/utility';
import { markMessageAsSeen, sendSMS, textMessageQuery } from './smsHelpers';
import anchorme from 'anchorme';
import { useNotifications } from 'client-shared/components/notifier2';
import { TextMessageEventQuery } from '../../utility';
import { _CxTextMessageFragment } from '../../utility/fragments';
import { addDays } from 'date-fns/fp';
import { serverDate } from '../../utility/dateutilities';
import { smsAvatar } from '../../utility/avatars';

const DRAWER_WIDTH = 320;
const interlocutorQuery = gql`
    query GetThreads($filters: [FilterInput]) {
        cxPartyGroups(filters: $filters) {
            Id
            NumericGroupNumber
            Messages {
                ${_CxTextMessageFragment}
            }
        }
        cxCaches {
            SMSNumbers {
                Name
                NumericPhoneNumber
            }
        }
    }
`;

const noInterlocutorQuery = gql`
    query GetPartyGroup($filters: [FilterInput]) {
        cxPartyGroups(filters: $filters) {
            Id
            NumericGroupNumber
        }
        cxCaches(filters: $filters) {
            SMSNumbers {
                Name
                NumericPhoneNumber
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0
    },
    drawerPaper: {
        width: DRAWER_WIDTH
    },
    topList: {
        'padding-bottom': 100
    },
    earlier: {
        width: '100%'
    }
}));

export const formatMessage = (_in) => {
    const text = anchorme(_in, { attributes: [{ name: 'target', value: 'blank' }] });
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
};

export function Chat(props) {
    const { chatState, toggleChat } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const { visible, interlocutor, smsNumber } = chatState;
    const [messages, setMessages] = useState([]);
    const [numbers, setNumbers] = useState([]);
    const [range, setRange] = useState([addDays(-14, new Date()), addDays(2, new Date())]);
    const [text, setText] = useState(chatState.text);
    const { openSBTransient } = useNotifications();
    const scollToRef = useRef();
    const hasRunOnce = useRef(false);
    const ii = useRef(interlocutor);

    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps*/
        setText(chatState.text);
        ii.current = get('interlocutor.number', chatState);
        pipeP(
            fetchData,
            setState(setMessages, get('cxPartyGroups.0.Messages')),
            setState(setNumbers, (_in) =>
                map(
                    (each) => ({
                        label: `${each.Name === '' ? 'External ' : each.Name} ${each.NumericPhoneNumber}`,
                        number: each.NumericPhoneNumber
                    }),
                    get('cxCaches.0.SMSNumbers', _in)
                )
            )
        )();
    }, [interlocutor, range]);

    useEffect(() => {
        markMessageAsSeen(client, get('congistics.user.Party.Id', user), messages);
        if (hasRunOnce.current) return;
        if (size(messages) < 8) return;
        if (!scollToRef.current) return;
        scollToRef.current.scrollIntoView();
        hasRunOnce.current = true;
    }, [messages]);

    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps*/
        const observable = watchQueries(client, [TextMessageEventQuery]);
        const subscription = observable.subscribe((data) => {
            if (isData(data)) {
                pipeP(
                    get('data.textMessageEvent'),
                    map(get('Dependencies.0.Id')),
                    fetchById,
                    get('data.cxTextMessages'),
                    filter(
                        (each) => get('From.NumericPhoneNumber', each) === ii.current || get('Outbound', each) === true
                    ),
                    (changes) => setMessages(syncCollections('Id', changes)),
                    () => scollToRef.current && scollToRef.current.scrollIntoView()
                )(data);
            }
        });
        return (_) => subscription.unsubscribe();
    }, []);

    const fetchData = async () => {
        if (!user) return [];
        if (!interlocutor) {
            return get('data', await _fetchDataNoInterlocutor());
        }
        return get('data', await _fetchData());
    };

    const _fetchData = async () => {
        return await client.query({
            fetchPolicy: 'network-only',
            query: interlocutorQuery,
            variables: {
                filters: [
                    {
                        name: 'NumericPhoneNumber',
                        values: [interlocutor.number],
                        child: 'Recipients'
                    },
                    {
                        name: 'From.NumericPhoneNumber, To.NumericPhoneNumber',
                        properties: 'From.NumericPhoneNumber, To.NumericPhoneNumber',
                        values: [interlocutor.number],
                        child: 'Messages'
                    },
                    {
                        name: 'NumericGroupNumber',
                        values: [smsNumber]
                    },
                    {
                        name: 'Range',
                        properties: 'Sent',
                        values: [serverDate(range[0]), serverDate(range[1])],
                        child: 'Messages'
                    }
                ]
            }
        });
    };

    const fetchById = async (ids) => {
        return await client.query({
            query: textMessageQuery,
            fetchPolicy: 'network-only',
            variables: {
                filters: [{ name: 'Id', values: ids }]
            }
        });
    };

    const _fetchDataNoInterlocutor = async () => {
        return await client.query({
            fetchPolicy: 'network-only',
            query: noInterlocutorQuery,
            variables: {
                filters: [
                    {
                        name: 'NumericGroupNumber',
                        values: [smsNumber]
                    }
                ]
            }
        });
    };

    const fetchMoreData = async () => {
        //const more = await fetchData(offset);
        //setMessages(syncCollections('id', reverse(get(['fetchedPagedSql', 'items'], more))));
        //setNextOffset(get(['fetchedPagedSql', 'nextOffset']));
        setRange([addDays(-15, range[0]), range[1]]);
    };

    function sendSMSs() {
        if (!text) {
            openSBTransient('Type in some text', { variant: 'error' });
            return;
        }
        if (!interlocutor) {
            openSBTransient('Enter or select a phone number', { variant: 'error' });
            return;
        }
        sendSMS(client, user.customerInfo.customer, smsNumber, interlocutor.number, text)
            .then((_) => setText(''))
            .catch((e) => console.log);
    }

    function formatTime(to, _in) {
        let dt = new Date();
        if (_in.To.NumericPhoneNumber !== to && _in.IsReceived) {
            dt = new Date(_in.Received + 'Z');
            return String.fromCharCode(10003) + formatDistanceStrict(dt, new Date());
        }
        if (_in.To.NumericPhoneNumber !== to && !_in.IsReceived) {
            return String.fromCharCode(9747) + 'Not received';
        }
        if (_in.To.NumericPhoneNumber === to) {
            dt = new Date(_in.Sent + 'Z');
        }

        return formatDistanceStrict(dt, new Date());
    }

    function handleChange(e, v) {
        if (!e) return;
        if (v) {
            const target = find({ label: v }, numbers);

            if (target) return toggleChat(true, smsNumber, target)(e);
            return;
        }
        let candidate = makeSMSNumber(e.currentTarget.value);
        if (startsWith('1', candidate)) candidate = candidate.substring(1);

        if (candidate) toggleChat(true, smsNumber, { label: candidate, number: candidate })(e);
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => option.label
    });

    if (!smsNumber) return null;
    return (
        <Drawer
            variant="persistent"
            anchor="right"
            className={classes.drawer}
            open={visible}
            classes={{
                paper: classes.drawerPaper
            }}
        >
            <div>
                <ListItem>
                    <IconButton onClick={toggleChat(false)}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <Autocomplete
                        id="combo-box-demo"
                        options={numbers || [{ label: 'foo' }]}
                        value={interlocutor}
                        freeSolo
                        filterOptions={filterOptions}
                        onInputChange={handleChange}
                        style={{ width: DRAWER_WIDTH }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Phone Numbers"
                                autoComplete="on"
                                onChange={handleChange}
                                margin="normal"
                                fullWidth
                            />
                        )}
                    />
                </ListItem>
            </div>
            <List className={classes.topList} style={{ paddingBottom: '40px' }}>
                <ListItem alignItems="center" button={true} onClick={(_) => fetchMoreData()}>
                    <ListItemText
                        secondary={` From ${serverDate(range[0])}${String.fromCharCode(8212)}${serverDate(range[1])}`}
                    />
                </ListItem>
                <ListItem alignItems="center" button={true} onClick={(_) => fetchMoreData()}>
                    <Button size="small" variant="contained" className={classes.earlier} endIcon={<MoreVert />}>
                        Earlier...
                    </Button>
                </ListItem>
                {pipe(
                    orderBy(['Sent'], ['asc']),
                    map((each) => (
                        <Tooltip
                            title={'Sent on: ' + new Date(each.Sent + 'Z').toLocaleString()}
                            key={each.Id}
                            followCursor
                        >
                            <ListItem>
                                <ListItemText
                                    disableTypography
                                    primary={formatMessage(each.Text)}
                                    secondary={formatTime(smsNumber, each)}
                                />
                                {!each.Outbound && (
                                    <ListItemAvatar>
                                        <ListItemAvatar>
                                            <Avatar {...smsAvatar(each.From, get('Sender.DisplayName', each))} />
                                        </ListItemAvatar>
                                    </ListItemAvatar>
                                )}
                            </ListItem>
                        </Tooltip>
                    ))
                )(messages)}
                <p ref={scollToRef}></p>
            </List>
            <Box zIndex="tooltip" className={classes.stickToBottom}>
                <Paper>
                    <TextField
                        style={{ width: DRAWER_WIDTH - 50 }}
                        //label="Enter Your sms"
                        placeholder="Enter Your sms"
                        multiline
                        margin="normal"
                        value={text}
                        onInput={(e) => setText(e.target.value)}
                    />
                    <Fab
                        className={classes.fab}
                        color="primary"
                        size="small"
                        component="span"
                        aria-label="add"
                        onClick={sendSMSs}
                    >
                        <AddIcon />
                    </Fab>
                </Paper>
            </Box>
        </Drawer>
    );
}
