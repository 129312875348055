import { gql } from 'graphql-tag';
import { TagFragment } from 'client-shared/utility/fragments';

export const FormQuery = gql`
    ${TagFragment}
    query ($objectType: String = "TaskDay", $filters: [FilterInput]) {
        cxTaskDays(objectType: $objectType, filters: $filters) {
            ParentId
            ActionType
            Id
            ObjectType
            ConflictIds
            ShiftSupervisor
            Description
            ForeColor
            BackColor
            Job {
                Id
                _DisplayName: DisplayName
            }
            JobActivity {
                Id
                _DisplayName: DisplayName
            }
            CurrentLifespan {
                Start
                End
            }
            Principals {
                Id
                _DisplayName: DisplayName
                _ObjectType: ObjectType
                _ResourceType: ResourceType {
                    Family
                    Unit {
                        Name
                    }
                }
                _HasAuxiliaries: HasAuxiliaries
            }
            Auxiliaries {
                Id
                _DisplayName: DisplayName
            }
            Amount
            Filled
            #            ActionStatus {
            #                Id
            #                _DisplayName: DisplayName
            #            }
            Description
            _Job: Job {
                Id
                Name
            }
            Tags {
                ...TagFragment
            }
        }
        cxCustomPropertys(
            objectType: "CustomProperty"
            filters: [{ name: "SupportedTypes", values: ["ActualTaskDay"] }]
        ) {
            Id
            _Name: Name
            _AttributeType: AttributeType
        }
    }
`;

export const CxResourcesQuery = gql`
    query ($searchString: String, $start: String, $end: String, $objectType: [String] = [], $id: [String] = []) {
        cxResources(
            objectType: "Resource"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
                { name: "ObjectType", values: $objectType }
                { name: "Id", values: $id }
                { name: "ResourceType.Allocatable", values: ["true"] }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            _ObjectType: ObjectType
            _ResourceType: ResourceType {
                Family
                Unit {
                    Name
                }
            }
            _Auxiliaries: Auxiliaries {
                Id
                _DisplayName: DisplayName
            }
        }
    }
`;

export const CxJobsQuery = gql`
    query ($searchString: String, $start: String, $end: String) {
        cxJobs(
            objectType: "Job"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            _JobActivities: JobActivities {
                Id
                _DisplayName: DisplayName
            }
        }
    }
`;
