import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns/fp';
import { Section } from 'client-shared/components/Section';
import { get, flow, join, map, sortBy } from 'lodash/fp';
import { parseDate } from 'client-shared/utility';
import Typography from '@mui/material/Typography';
import { TextField } from 'formik-mui';
import { Field } from 'client-shared/components/form';

//
export const NoteEditor = ({ form, field, classes, label = '' }) => {
    const noteHistory = flow(
        sortBy('AsOf'),
        map((history) => {
            return `${format('P', parseDate(history.AsOf))} ${history.Party.DisplayName}: ${history.Note}`;
        }),
        join(', ')
    )(get('values.NoteHistory', form) || []);
    return (
        <Section title={label} elevation={0}>
            <Typography variant="body2" style={{ fontSize: 'small' }}>
                {noteHistory}
            </Typography>
            <Field
                type="text"
                name={field.name}
                component={TextField}
                className={classes.textBoxField}
                multiline
                variant="outlined"
                margin="normal"
                disabled={false}
            />
        </Section>
    );
};

NoteEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string
};
