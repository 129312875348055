/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxPartyGroup';
import inputModel from 'cb-schema/inputmodelfull/CxPartyGroup';
import { AutoComplete, Field } from 'client-shared/components/form';
import { object } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import { ForwardingEmailsEditor, forwardingEmailsValidationSchema } from './FowardingEmailsEditor';
import { gql } from 'graphql-tag';

const validationSchema = object({
    ForwardingEmails: forwardingEmailsValidationSchema
});

const Form = (props) => {
    const { classes } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <Field
                    type="text"
                    label="Name"
                    name="DisplayName"
                    component={TextField}
                    className={classes.longTextField}
                    disabled={true}
                />
                <Field type="text" name="ForwardingEmails" component={ForwardingEmailsEditor} classes={classes} />
                <Field
                    type="text"
                    label="Users"
                    name="Parties"
                    component={AutoComplete}
                    style={{ width: '750px' }}
                    optionsQuery={gql`
                        query ($searchString: String) {
                            cxUsers(
                                objectType: "User"
                                filters: [
                                    { name: "String", values: ["DisplayName", $searchString] }
                                    { name: "Level", values: ["Administrator", "Standard"] }
                                    { name: "Active", values: ["true"] }
                                ]
                            ) {
                                Party {
                                    Id
                                }
                                DisplayName
                            }
                        }
                    `}
                    optionLabelProperty="DisplayName"
                    getOptions={(options) => {
                        return options.map((option) => ({
                            Id: option.Party.Id,
                            DisplayName: option.DisplayName
                        }));
                    }}
                    multiple={true}
                    initialize={true}
                />
            </form>
        </React.Fragment>
    );
};

export const EditCxPartyGroup = ({ open, formQueryValues, onClose, ...other }) => {
    return (
        <DialogForm
            open={open}
            title="PartyGroup"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={model}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxPartyGroup"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxPartyGroup.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
