import React from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { makeStyles, useTheme } from '@mui/styles';
import { getOr, curry, size, filter, set } from 'lodash/fp';
import { Grid, Table, TableHeaderRow, TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';
import { get, map, pipe } from 'lodash/fp';
import { Filter } from './Filter';
import { getRange } from 'client-shared/utility/dateutilities';
import { keyByLifeSpan, mapStatus } from '../../utility/timekeepingHelpers';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    carsChip: {
        margin: theme.spacing(0.5)
    },
    headerColumn: {
        flexBasis: '100%',
        textAlign: 'center'
    },
    heading: {
        fontSize: theme.typography.pxToRem(18)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    legend: { listStyle: 'none' },
    legendItem: { display: 'inline' }
}));

export const EntryPoint = () => {
    return (
        <React.Fragment>
            <Filter>
                {({ values: variables, data: jobs }) => {
                    if (size(jobs) > 0) return <InnerTable {...{ variables, jobs }} />;
                    return <CircularProgress color="inherit" size={20} />;
                }}
            </Filter>
        </React.Fragment>
    );
};
export default EntryPoint;

const genRows = (range) => {
    return pipe(
        map((each) => ({ name: each, title: each })),
        (_in) => [{ name: 'job', title: 'Job' }, ..._in]
    )(range);
};

const buildDataPie = curry((range, job) => {
    const timeSheets = pipe(filter({ Crews: [] }), map(mapStatus), keyByLifeSpan)(job.TimeSheets);
    const taskDays = pipe(filter({ Crew: null }), map(set('Status', 'scheduled')), keyByLifeSpan)(job.TaskDays);
    const emptyDays = pipe(
        map((Start) => ({ Status: '—', CurrentLifespan: { Start } })),
        keyByLifeSpan
    )(range);
    //console.log(job)
    const items = { ...emptyDays, ...taskDays, ...timeSheets };

    return {
        Id: get('Id', job),
        job: get('DisplayName', job),
        ...items
    };
});

const InnerTable = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { jobs, variables } = props;

    const range = getRange(variables._start, variables._periods);
    const columns = genRows(range);

    const xtensions = map(
        (each) =>
            each.name === 'job' ? { columnName: each.name, width: '30%' } : { columnName: each.name, align: 'center' },
        columns
    );

    const toRender = pipe(map(buildDataPie(range)))(jobs);
    console.log(toRender, columns);

    return (
        <Paper>
            <Grid rows={toRender} columns={columns} getRowId={get('Id')}>
                <TimeEntryLegend classes={classes} />
                <Table cellComponent={Cell} columnExtensions={xtensions} />
                <TableHeaderRow />
                <TableFixedColumns leftColumns={['job']} />
            </Grid>
        </Paper>
    );
};

const TimeEntryLegend = ({ classes, ...rest }) => {
    return (
        <ul className={classes.legend}>
            <li key="notscheduled" className={classes.legendItem}>
                <Chip style={{ backgroundColor: 'white' }} label="not started" variant="outlined" />
            </li>
            <li key="inprogress" className={classes.legendItem}>
                <Chip style={{ backgroundColor: '#64b5f6' }} label="in progress" />
            </li>
            <li key="submitted" className={classes.legendItem}>
                <Chip style={{ backgroundColor: '#81c784' }} label="submitted" />
            </li>
            <li key="approved" className={classes.legendItem}>
                <Chip style={{ backgroundColor: '#388e3c' }} label="approved" />
            </li>
            {/*            <li key="needs_correction" className={classes.legendItem}>
                <Chip style={{ backgroundColor: '#e57373' }} label="needs correction" />
            </li>*/}
        </ul>
    );
};

const HighlightedCell = ({ value, style, column, row, ...restProps }) => {
    const navigate = useNavigate();
    return (
        <Table.Cell
            {...restProps}
            onClick={(_) => navigate(`${row.Id}/${column.name}`)} // console.log(column, row)}
            style={{
                backgroundColor: getOr('white', get('Status', value), {
                    'in progress': '#64b5f6',
                    submitted: '#81c784',
                    approved: '#388e3c',
                    'needs correction': 'e57373'
                }),
                cursor: 'pointer',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'lightgray',
                ...style
            }}
        >
            {getOr('not started', 'Status', value)}
        </Table.Cell>
    );
};

const Cell = (props) => {
    const { column } = props;
    if (column.name !== 'job') {
        return <HighlightedCell {...props} />;
    }
    return <Table.Cell {...props} />;
};
