import { gql } from '@apollo/client';
/**
 * Created by mark on 12/12/2020.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { object } from 'yup';
import { AutoComplete, FormContainer } from 'client-shared/components/form';
import { usePrivilege } from 'client-shared/utility';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { get } from 'lodash/fp';

export const validationSchema = object({});

const Form = (props) => {
    const { classes } = props;
    return (
        <form noValidate autoComplete="off">
            <Field
                type="text"
                label="Action Status"
                name="actionStatus"
                component={AutoComplete}
                className={classes.selectField}
                optionsQuery={gql`
                    query {
                        cxActionStatuss(objectType: "ActionStatus") {
                            Id
                            _DisplayName: DisplayName
                        }
                    }
                `}
                optionLabelProperty="_DisplayName"
            />
        </form>
    );
};

export const EditSetActionStatusAllocation = ({ actions, open, onClose }) => {
    const [privilege] = usePrivilege('Allocation');
    let _submitForm;
    const getSubmitForm = (submitForm) => {
        _submitForm = submitForm;
    };
    const submitForm = () => {
        if (_submitForm) {
            _submitForm();
        }
    };

    return (
        <Dialog open={open}>
            <DialogContent>This will set the action status on all selected allocations.</DialogContent>
            <DialogContent>
                <FormContainer
                    Form={Form}
                    initialValues={{ actionStatus: { Id: '-1', _DisplayName: '' } }}
                    // data={data}
                    onSubmit={(formValues) => {
                        actions.handleSetActionStatusAllocation(get('actionStatus', formValues));
                        onClose();
                    }} // binded call
                    getSubmitForm={getSubmitForm}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitForm} color="primary" disabled={privilege}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditSetActionStatusAllocation.propTypes = {
    actions: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
