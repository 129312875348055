import { get } from 'lodash/fp';
import { evalQuery, getGraphqlHelper, makeVerbOperation, hasProperty } from '../../gqlhelpers/queryHelpers';
import { pFetch } from '../../utility/authHelpers';
import { fetchJSON } from 'global-shared/utils/http';

const formItemQuery = `
        Id
        Forms {
        Id
           Attachments{
            Id
            Key
            Name
            Description
            Creator {
            Id
            DisplayName
           }
           Completed
           LastModified
           Approvals {
                Approver {
                Id
                DisplayName
                }
                Completed
                AsOf
             }
        }
        }
        Attachments{
            Id
            Key
            Name
            Description
            Creator {
            Id
            DisplayName
           }
           Completed
           LastModified
           Approvals {
                Approver {
                Id
                DisplayName
                }
                Completed
                AsOf
             }
            DocumentTypes {
                Id
                Name
            }
        }  
        `;

const itemQuery = `
        Id
        Attachments{
            Id
            Key
            Name
            Description
            Creator {
            Id
            DisplayName
           }
           Completed
           LastModified
           Approvals {
                Approver {
                Id
                DisplayName
                }
                Completed
                AsOf
             }
            DocumentTypes {
                Id
                Name
            }
        }  
        `;

const fixQuery = (clazz) => (hasProperty(clazz, 'Forms') ? formItemQuery : itemQuery);

export const useAttachments = (client, clazz) => {
    const GQL = getGraphqlHelper(clazz);
    const deleteAttachment = async (entityId, documentId) => {
        if (!GQL) return new Error(`${clazz} has no minimal input fragment`);
        const { methodsByItem, filters } = makeVerbOperation('RemoveAttachment', {
            ids: [entityId],
            args: { documentId },
            preserve: true
        });
        return (
            evalQuery(client, fixQuery(clazz), GQL.entityClass, [], methodsByItem, filters)
                //.then(pLog)
                .then(get(`data.${GQL.saveMutation}.0`))
        );
    };
    const addAttachments = async (entityId, documents) => {
        if (!GQL) return new Error(`${clazz} has no minimal input fragment`);
        const { methodsByItem, filters } = makeVerbOperation('AddAttachments', {
            ids: [entityId],
            args: { documents },
            preserve: true
        });
        return await evalQuery(client, fixQuery(clazz), GQL.entityClass, [], methodsByItem, filters).then(
            get(`data.${GQL.saveMutation}.0`)
        );
    };

    return [addAttachments, deleteAttachment];
};

export const getSharableAttachmentLink = async (key) =>
    pFetch(`/api/file/url/${key}`)
        .then(fetchJSON)
        .then((res) => pFetch(`/api/file/shorten/${encodeURIComponent(res.url)}`))
        .then(fetchJSON);
