/**
 * Created by mark on 1/3/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxCostCode';
import inputModel from 'cb-schema/inputmodelfull/CxCostCode';
import { object, string } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import { UnitCostEditor, unitCostValidationSchema } from 'client-shared/components/form';
import cloneDeep from 'lodash/cloneDeep';

const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long').required('Required'),
    Description: string().required('Required'),
    UnitCost: unitCostValidationSchema
});

const Form = (props) => {
    const { classes } = props;
    return (
        <form noValidate autoComplete="off">
            <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
            <Field
                type="text"
                label="Description"
                name="Description"
                component={TextField}
                className={classes.textField}
            />
            <Field type="text" label="Unit Cost" name="UnitCost" component={UnitCostEditor} classes={classes} />
            <Field
                type="color"
                label="ForeColor"
                name="ForeColor"
                component={TextField}
                className={classes.textField}
            />
            <Field
                type="color"
                label="BackColor"
                name="BackColor"
                component={TextField}
                className={classes.textField}
            />
            <Field type="checkbox" Label={{ label: 'Global' }} name="Global" component={CheckboxWithLabel} />
        </form>
    );
};

export const EditCxCostCode = ({ open, formQueryValues, onClose }) => {
    let initialValues = cloneDeep(model);
    initialValues.ForeColor = '#000000';
    initialValues.BackColor = '#FFFFFF';
    initialValues.UnitCost = {
        Unit: {
            Id: 0,
            _DisplayName: ''
        },
        Cost: {
            Unit: {
                Id: 12,
                _DisplayName: 'dollar'
            },
            Quantity: 1
        }
    };
    return (
        <DialogForm
            open={open}
            title="Cost Code"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxCostCode"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxCostCode.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
