import React from 'react';
import { FilterResourceTypes } from './FilterResourceTypes';
import { FilterJobCosts } from './FilterJobCosts';
import { FilterForecasts } from './FilterForecasts';
import { FilterTotalMetricsGraph } from './FilterTotalMetricsGraph';
import { Grid, addExcelRow } from './Grid';
import { Graph } from './Graph';
import { get } from 'lodash/fp';

export const ResourceTypesGrid = () => {
    return (
        <FilterResourceTypes>
            {({ values, data }) => {
                values = { ...values, resourcetype_id: values.id, contacts_id__all__true: values._contacts_id };
                return <Grid values={values} data={data} />;
            }}
        </FilterResourceTypes>
    );
};

export const JobCostsGrid = () => {
    return (
        <FilterJobCosts>
            {({ values, data }) => {
                values = { ...values, contacts_id__all__true: values._contacts_id };
                return <Grid values={values} data={data} />;
            }}
        </FilterJobCosts>
    );
};

export const ForeCastsGrid = () => {
    const columns = [
        { title: 'Manager', name: 'Job.Manager.DisplayName' },
        { title: 'Status', name: 'Job.JobStatus.DisplayName' }
    ];
    return (
        <FilterForecasts>
            {({ values, data }) => {
                values = { ...values, forecast_id: values.id, contacts_id__all__true: values._contacts_id };
                return <Grid values={values} data={data} addExcelRow={addExcelRow(columns)} />;
            }}
        </FilterForecasts>
    );
};

export const TotalMetricsGraph = () => {
    return (
        <FilterTotalMetricsGraph>
            {({ values, data }) => {
                return (
                    <div
                        style={{
                            //height: '5000px',
                            width: `calc(100vw)`
                        }}
                    >
                        <Graph values={values} reportIntervals={get('0.ReportIntervals', data)} />
                    </div>
                );
            }}
        </FilterTotalMetricsGraph>
    );
};
