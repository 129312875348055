import React from 'react';
import PropTypes from 'prop-types';
import { CellDragDrop } from './dragdrop';
import { Hover } from 'client-shared/components/Hover';
import HistoryIcon from '@mui/icons-material/History';
import ListIcon from '@mui/icons-material/List';
import format from 'date-fns/fp/format';
import { get } from 'lodash/fp';
import { Expiration } from 'client-shared/components/Expiration';
import { SCHEDULECACHE } from 'client-shared/utility';
import { SelectableItem } from 'client-shared/components/SelectableItem';

export const Job = ({ row, values, onOpenEditor, onOpenGrid }) => {
    const cellDragDrop = new CellDragDrop();
    return (
        <div
            style={{ display: 'flex', alignItems: 'center', whiteSpace: 'normal', overflowWrap: 'break-word' }}
            draggable={true}
            onDragStart={cellDragDrop.handleDrag([
                {
                    selectedItemProperties: null,
                    item: row._root ? row.JobActivities[0] : row, // if dragging job, return first job activity.
                    cacheName: null,
                    selected: true
                }
            ])}
            onDoubleClick={() =>
                onOpenEditor('EditCxJob', {
                    open: row._root,
                    formQueryValues: {
                        filters: [{ name: 'Id', values: [row.Id] }]
                    }
                })
            }
        >
            <SelectableItem
                item={{
                    Id: row.Id,
                    ForeColor: row.ForeColor,
                    BackColor: row.BackColor,
                    DisplayName: row.DisplayName
                }}
                cacheName={SCHEDULECACHE}
                selectedItemProperties={values}
            />
            <Expiration
                expirationsToCheck={[{ message: 'Ending in', expirationDate: get('CurrentLifespan.End', row) }]}
            />
            <Hover message={row.Description} />
            {row._root && (
                <div>
                    <HistoryIcon
                        sx={{ fontSize: '17px' }}
                        onClick={() =>
                            onOpenGrid('CxAllocations', {
                                firstTimeValues: { job_id: [{ Id: row.Id, _DisplayName: row.DisplayName }] }
                            })
                        }
                    />
                    <ListIcon
                        onClick={() =>
                            onOpenGrid('CxToDos', {
                                firstTimeValues: {
                                    status_id: [],
                                    job_id: [{ Id: row.Id, _DisplayName: row.DisplayName }],
                                    resources_id: [],
                                    start: format('yyyy-MM-dd', new Date()),
                                    _periods: 100
                                },
                                formValues: {
                                    Job: {
                                        Id: row.Id,
                                        _DisplayName: row.DisplayName
                                    }
                                }
                            })
                        }
                    />
                </div>
            )}
        </div>
    );
};

Job.propTypes = {
    row: PropTypes.object.isRequired,
    onOpenEditor: PropTypes.func.isRequired,
    onOpenGrid: PropTypes.func.isRequired
};
