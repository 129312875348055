import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import Enlist from '../components/Enlist';
import Typography from '@mui/material/Typography';
import { set, get, map } from 'lodash'; //we want the destructive ones, do not merge into the following!
import { isArrayLikeObject, forEach } from 'lodash/fp';
import TopNavigationBar from './TopNavbar';


const useStyles = makeStyles(() => ({
    root: {
        marginTop: 15
    },
    faves: {
        marginBottom: 15
    },
    grow: {
        flexGrow: 1
    },
    menuItem: {
        width: '200px'
    },
    item: {
        marginTop: 15
    },
    menuContainer: {
        transitionDuration: '0.3s',
        width: '20hv',
        backgroundColor: '#FAFAFA'
    }
}));

function Index(props) {
    const classes = useStyles();
    const { items, smartMenu } = props;
    //const witPaths = addPath(path, items.items);
    return (
        <div key={items.name} className={classes.root}>
            <Typography component="h1" variant="h4"></Typography>
            <TopNavigationBar {...{ smartMenu }} />
            <Grid container spacing={3}>
                {map(items.items, (each, i) => (
                    <Grid item md={4} xs={8} key={i} className={classes.item}>
                        <Container className={classes.menuContainer} elevation={0}>
                            <Enlist {...each} />
                        </Container>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export const addPath = (path, items) => {
    forEach((v) => {
        isArrayLikeObject(v['items']) ? addPath(path, v['items']) : set(v, 'route', `${path}/${get(v, 'route')}`);
    }, items);
    return items;
};

export default Index;
