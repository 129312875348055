import React, { Component } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import { pipe, get, map, flatten, fromPairs, getOr, keyBy, pick } from 'lodash/fp';
import { renameKeys } from 'global-shared/utils/utils';
import isWeekend from 'date-fns/isWeekend';
import { Scheduler } from 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css';
import 'dhtmlx-scheduler/codebase/overrides.css';
import 'dhtmlx-scheduler/codebase/api';
import { formatStandard, parseDate } from 'client-shared/utility/dateutilities';
import { clearEvents, setupEvents } from './helpers';

export default class Index extends Component {
    initScheduler() {
        const { timelineConfig, data, event, startDate } = this.props;
        clearEvents(this.scheduler);
        setupEvents(this.scheduler, this.props);

        const eventz = this.scheduler.getEvents();
        if (eventz.length > 0 && event) {
            this.scheduler.clearAll();
            this.scheduler.parse(data);
            this.scheduler.setCurrentView();
            return;
        }

        this.scheduler.templates.tooltip_text = function (start, end, ev) {
            //console.log(ev)
            let rollover =
                ev.text +
                '<br/><b>Start:</b> ' +
                formatStandard(ev.originalSpan[0]) +
                '<br/><b>End:</b> ' +
                formatStandard(ev.originalSpan[1]);

            if (ev.totalHours) rollover += `<br/> <b>${ev.totalHours}</b> hours`;
            return rollover;
        };

        this.scheduler.templates.event_class = function (start, end, ev) {
            const dys = { 26: 'tight', 36: 'comfortable', 46: 'big' };
            return getOr('comfortable', timelineConfig.dy, dys);
        };

        this.scheduler.createTimelineView({ ...timelineConfig });

        if (timelineConfig.x_unit === 'day')
            this.scheduler.templates.timeline_scale_date = (e, t, a) => {
                return format(new Date(e), 'eeeee-d');
            };

        this.scheduler.templates.timeline_cell_class = function (evs, date, section) {
            if (isWeekend(date)) return 'is_weekend'; // css class which will be assigned
        };

        const tooltips = pullFolders(timelineConfig.y_unit);

        this.scheduler.templates.timeline_scale_label = (e, t, a) => {
            const toolTip = get(e, tooltips);
            const fColor = a.ForeColor || '#000000';
            const bColor = a.BackColor || '#FFFFFF';

            if (get('tooltip', toolTip))
                return `<span title="${get('tooltip', toolTip)}" style="color:${fColor};background-color: ${bColor};" class="resource" data-id="${e}">${t}</span>`;
            return `<span class="resource" style="color:${fColor};background-color: ${bColor};" data-id="${e}">${t}</span>`;
        };
        this.scheduler.init(this.schedulerContainer, parseDate(startDate), 'timeline');
        this.scheduler.clearAll();
        this.scheduler.parse(data);
        this.scheduler.render();
    }

    componentDidUpdate() {
        this.initScheduler();
    }
    componentDidMount() {
        //console.log('first load', this.props);
        const { editCallback } = this.props;

        this.scheduler = Scheduler.getSchedulerInstance();
        const _scheduler = this.scheduler;

        _scheduler.config.xml_date = '%Y-%m-%d %H:%i';
        _scheduler.config.dblclick_create = false;
        _scheduler.config.drag_create = false;
        _scheduler.config.touch = true;
        _scheduler.config.full_day = true;
        //_scheduler.config.header=[]

        _scheduler.plugins({
            timeline: true,
            treetimeline: true,
            pdf: true,
            tooltip: true
        });

        this.scheduler.showLightbox = editCallback;

        this.initScheduler();
    }

    componentWillUnmount() {
        this.scheduler.destructor();
        this.scheduler = null;
    }

    render() {
        const { treeView, width, title, dropHandler } = this.props;

        const dropEvents = { onDragOver: (e) => e.preventDefault(), onDrop: dropHandler };

        function printMe(foo) {
            const header = `<h1>${title}</h1>
        <h2>${format(foo._min_date, 'd MMM yyyy')} - ${format(subDays(foo._max_date, 1), 'd MMM yyyy')} </h2>`;
            foo.exportToPDF({ header, orientation: 'landscape', format: 'A4', landscape: true });
        }

        return (
            <div
                ref={(input) => {
                    this.schedulerContainer = input;
                }}
                className="widget-box dhx_cal_container"
                style={{ height: 'calc(100vh - 250px)', width }}
                {...dropEvents}
            >
                <div className="dhx_cal_navline">
                    {treeView && (
                        <React.Fragment>
                            <Tooltip title="Close all" aria-label="Close all">
                                <IconButton aria-label="close" onClick={() => this.scheduler.closeAllSections()}>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Open all" aria-label="Open all">
                                <IconButton aria-label="open" onClick={() => this.scheduler.openAllSections()}>
                                    <KeyboardArrowUpIcon />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )}
                    <Tooltip title="Save to pdf" aria-label="Save to pdf">
                        <IconButton aria-label="open" onClick={() => printMe(this.scheduler)}>
                            <PictureAsPdfIcon />
                        </IconButton>
                    </Tooltip>
                    {/*           <div className="dhx_cal_prev_button">&nbsp;</div>
                <div className="dhx_cal_next_button">&nbsp;</div>
                <div className="dhx_cal_today_button" /> */}
                    <div className="dhx_cal_date" />
                </div>
                <div className="dhx_cal_header" />
                <div className="dhx_cal_data" />
            </div>
        );
    }
}
Scheduler.propTypes = {
    treeView: PropTypes.bool,
    drag: PropTypes.oneOf(['source', 'target']),
    editCallback: PropTypes.func.isRequired,
    onEventChanged: PropTypes.func.isRequired,
    onEventDeleted: PropTypes.func.isRequired,
    onViewChange: PropTypes.func,
    dropHandler: PropTypes.func
};

/**
 * Helpers
 */

export const updateEvent = (resources, target) => (id, event) => {
    const scheduler = target.current.get();
    let other = scheduler.getEvent(id);
    other.start_date = event.start_date;
    other.end_date = event.end_date;
    other.text = get([event.section_id, 'label'], resources);

    scheduler.updateEvent(id);
};

const pullFolders = pipe(map(pick(['key', 'tooltip'])), keyBy('key'));

export const enKeyResources = pipe(
    map(get('children')),
    flatten,
    map((each) => {
        return [[get('key', each)], each];
    }),
    fromPairs
);

export const gQlKVtoScheduler = renameKeys({
    Id: 'key',
    Name: 'label'
});

export const gQlKVtoSchedulerChild = (_in) => ({
    key: `${_in.JobId}_${get('JobActivity.Id', _in)}`,
    label: get('JobActivity.Name', _in)
});
