import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useCollectionSubscription } from 'client-shared/utility';
import { createFilters } from 'client-shared/utility/queryfilter';
import { sortBy, cloneDeep, get, isString, isFunction } from 'lodash/fp';
import { ResetFilters } from './ErrorBoundary';
import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import LinearProgress from '@mui/material/LinearProgress';

/**
 * retrieve data from the server as a collection and optionally,
 * update the collection when an event is received by the dataEventQueries passed to this component.
 * if the collection is to be kept up-to-date through events, then it must contain an Id property. the Id property
 * must be part of the dataQuery filter and is used to retrieve only the items that have changed.
 * @param values - values to use for filtering when there are no firstTimeValues.
 * @param dataQuery - graphql query document or function that returns graphql query document  to retrieve data from the
 * server. it is passed filter parameters derived from the formik snippet values.
 * @param dataEventQueries - optional graphql query documents to listen for events that trigger an update to the collection.
 * @param sourceMutationObjectTypes - optional string array of objectTypes of mutations that generated the event
 * dependencies. this can be used to listen for job events that were generated from a job mutation and/or an
 * allocation mutation.
 * @param sortProperties - optional array of full property path (e.g. ["ResourceType.Name", "DisplayName"]) to sort by.
 * @param cache - optional boolean that determines whether the graphql will cache results using a fetchPolicy.
 * @param onFilter - optional function to modify the filter passed to the dataQuery.
 * @param onData - optional function to transform the data from the dataQuery. for example, flatMap the data
 * from the server before sending it to a grid. this is executed here so that the match filter works on the transformed
 * data.
 * @param onMethodsByItem - optional function to add eval methods to the query.
 * @param onFormatters - optional function to add formatters to the query.
 * @param children - component children called by a render children with values, signature call.
 * @return {string | *}
 */
export const FilterQuery = ({
    title,
    style,
    values,
    dataQuery,
    dataEventQueries,
    sourceMutationObjectTypes,
    sortProperties = ['DisplayName'],
    cache,
    onFilter = createFilters,
    onData = (data, values, client, rawData) => data,
    onMethodsByItem = (values, client) => ({ methodsByItem: [] }),
    onFormatters = (values, client) => ({ formatters: [] }),
    children
}) => {
    const client = useApolloClient();
    const [forceQuery, setForceQuery] = useState(0);
    const [newData, setNewData] = useState({ counter: 0, data: [], rawData: [] });
    const [filterError, setFilterError] = useState(false);
    const [quickFilter, setQuickFilter] = useState();
    const getQueryString = (values) => {
        if (isFunction(dataQuery)) {
            return dataQuery(values);
        }
        return isString(dataQuery) && dataQuery !== ''
            ? gql`
                  ${dataQuery}
              `
            : dataQuery;
    };

    let queryValues = values;
    if (values._showAll) {
        queryValues = { defaultdivisionsid: values.defaultdivisionsid, _showAll: values._showAll };
    }
    if (!values._showAll && quickFilter) {
        queryValues = quickFilter;
    }
    const handleRefresh = () => {
        setForceQuery(forceQuery + 1);
    };
    const data = useCollectionSubscription({
        dataQuery: getQueryString(values),
        eventQueries: dataEventQueries,
        values: queryValues,
        sourceMutationObjectTypes: sourceMutationObjectTypes,
        onFilter: onFilter,
        forceQuery: forceQuery,
        cache: cache,
        onMethodsByItem: onMethodsByItem,
        onFormatters: onFormatters
    });

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        (async () => {
            if (data.error) {
                let message = get('error.message', data);
                if (message.includes('Filter construction')) {
                    setFilterError(true);
                    return;
                }
            }
            let _data = await onData(data.data, values, client, data.rawData);
            setNewData({
                counter: data.counter,
                data: sortBy(sortProperties, _data),
                rawData: data.rawData,
                event: data.event,
                loading: data.loading
            });
        })();
    }, [data.counter, style]);

    /* eslint-disable react-hooks/exhaustive-deps */
    const childrenMemo = useMemo(() => {
        return children({
            values: cloneDeep(values),
            data: newData,
            setQuickFilter: setQuickFilter,
            onRefresh: handleRefresh
        });
    }, [newData.counter, style]);

    if (filterError) {
        return <ResetFilters title={title} />;
    }
    // if (data.loading && !quickFilter) {
    //     return (
    //         // <Box sx={{ zIndex: 1000000 }}>
    //         <LinearProgress />
    //         // </Box>
    //     );
    // }
    return (
        <React.Fragment>
            {data.loading && <LinearProgress />}
            {childrenMemo}
        </React.Fragment>
    );
};

FilterQuery.propTypes = {
    title: PropTypes.string,
    style: PropTypes.object,
    values: PropTypes.object.isRequired,
    dataQuery: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.func.isRequired
    ]),
    dataEventQueries: PropTypes.array,
    sortProperties: PropTypes.array,
    sourceMutationObjectTypes: PropTypes.array,
    cache: PropTypes.bool,
    onFilter: PropTypes.func,
    onData: PropTypes.func,
    onMethodsByItem: PropTypes.func,
    onFormatters: PropTypes.func
};
