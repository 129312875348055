import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { UnitEditor } from './UnitEditor';
import { object, number } from 'yup';
/*
{
    "Unit": {
        "Id": "12"
    },
    "Quantity": 0
}
 */

export const unitQuantityValidationSchema = object({
    Quantity: number()
        .typeError('Must be quantity')
        .min(0, 'Invalid negative value')
        .max(99999999999, 'Must be less than 99,999,999,999')
        .required('Required'),
    Unit: object()
        .when('Quantity', {
            is: (value) => value > 0,
            then: () =>
                object({
                    Id: number().moreThan('0', 'Required')
                })
                    .nullable()
                    .required('Required')
        })
        .nullable()
});

export const UnitQuantityEditor = ({ form, field, classes, label = '', unitTypes = [], ...other }) => {
    return (
        <React.Fragment>
            <Field
                type="number"
                label={label}
                name={`${field.name}.Quantity`}
                component={TextField}
                className={classes.extraShortTextField}
            />
            <Field
                type="text"
                label="Unit"
                name={`${field.name}.Unit`}
                component={UnitEditor}
                classes={classes}
                unitTypes={unitTypes}
            />
        </React.Fragment>
    );
};

UnitQuantityEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    unitTypes: PropTypes.array
};
