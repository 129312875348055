import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field, ResourceSubtypeEditor } from 'client-shared/components/form';
import { array, number, object } from 'yup';
import { Section } from 'client-shared/components/Section';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const model = {
    ObjectType: 'SupplyType',
    ResourceSubtype: {
        Id: '0',
        _Name: ''
    },
    UnitCosts: [],
    Id: 0,
    Description: '',
    //Tags: [],
    __typename: 'CxSupplyType'
};

// const model = {
//     ResourceSubtype: {
//         Id: 0
//     },
//     Id: 0,
//     Name: '',
//     Description: ''
// };

export const supplytypesValidationSchema = array().of(
    object({
        ResourceSubtype: object({
            Id: number().moreThan(0, 'Required')
        })
    })
);

export const Supplytypeseditor = ({ form, field, classes, label = '' }) => {
    return (
        <Section title="Supply Types">
            <FieldArray
                name={field.name}
                render={(arrayHelpers) => {
                    return (
                        <React.Fragment>
                            {field.value.map((supplyType, index) => {
                                return (
                                    <div key={index}>
                                        <Field
                                            type="text"
                                            label="Resource Subtype"
                                            name={`${field.name}.${index}.ResourceSubtype`}
                                            component={ResourceSubtypeEditor}
                                            className={classes.selectField}
                                            family={[]}
                                        />
                                        {/*<Field*/}
                                        {/*    type="text"*/}
                                        {/*    label="Accrual #"*/}
                                        {/*    name={`${field.name}.${index}.AccrualNumber`}*/}
                                        {/*    component={TextField}*/}
                                        {/*    className={classes.textField}*/}
                                        {/*/>*/}
                                        <IconButton
                                            onClick={() => {
                                                arrayHelpers.remove(index);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                );
                            })}
                            <IconButton
                                onClick={() => {
                                    arrayHelpers.push(model);
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </React.Fragment>
                    );
                }}
            />
        </Section>
    );
};

Supplytypeseditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string
};
