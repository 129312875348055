import { gql } from 'graphql-tag';
import { TagFragment } from 'client-shared/utility/fragments';

export const FormQuery = gql`
    ${TagFragment}
    query ($objectType: String = "ResourceType", $filters: [FilterInput]) {
        cxResourceTypes(objectType: $objectType, filters: $filters) {
            Id
            Name
            Description
            Family
            Unit {
                Id
                _DisplayName: DisplayName
            }
            #MoveVehicle
            ForeColor
            BackColor
            SubtypeQuantities {
                Amount
                Description
                _ResourceType: ResourceType {
                    Unit {
                        Name
                    }
                }
                ResourceSubtype {
                    Id
                    _Name: Name
                }
            }
            ResourceSubtypes {
                Id
                Name
                Description
            }
            Allocatable
            Tags {
                ...TagFragment
            }
            Rates {
                Id
                RateType {
                    Id
                    _DisplayName: DisplayName
                }
                UnitCost {
                    Unit {
                        Id
                        _DisplayName: DisplayName
                    }
                    Cost {
                        Unit {
                            Id
                            _DisplayName: DisplayName
                        }
                        Quantity
                    }
                }
            }
        }
        cxCustomPropertys(
            objectType: "CustomProperty"
            filters: [{ name: "SupportedTypes", values: ["ResourceType"] }]
        ) {
            Id
            _Name: Name
            _AttributeType: AttributeType
        }
    }
`;
