/**
 * A complete set of application
 */
import { filter, includes, pipe } from 'lodash/fp';
import { getAllTopLevelSmartMenus, extractRoutes} from 'global-shared/menus';


export const generateSmartQwickMenu = (_in, menus) => {


    const urls = getAllTopLevelSmartMenus(_in);

    return matchRouteWithMenuItem(urls, menus);

};

export function matchRouteWithMenuItem(urls, items) {
    const withRoutes = extractRoutes(items);
    return pipe(filter((each) => includes(each.route, urls)))(withRoutes);
}
