import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { TextField } from 'formik-mui';
import {
    Field,
    AutoComplete,
    ResourceSubtypeEditor,
    CurrentLifespanEditor,
    currentLifespanValidationSchema
} from 'client-shared/components/form';
import { array, number, object } from 'yup';
import { get } from 'lodash/fp';
import { Section } from 'client-shared/components/Section';
import { gql } from 'graphql-tag';
import { FormControl, FormHelperText, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { parseDate } from 'client-shared/utility';
import { format } from 'date-fns/fp';
import { IntervalAmountsEditor, intervalAmountsValidationSchema } from './IntervalAmountEditor';

export const resourceSubtypeForecastValidationSchema = array()
    // .min(1, 'At Least One Subtype Forecast Is Required')
    .of(
        object({
            JobActivity: object({
                Id: number().moreThan(0, 'Required')
            })
                .nullable()
                .required('Required'),
            ResourceSubtype: object({
                Id: number().moreThan(0, 'Required')
            })
                .nullable()
                .required('Required'),
            Shift: object({
                Id: number().moreThan(0, 'Required')
            })
                .nullable()
                .required('Required'),
            CurrentLifespan: currentLifespanValidationSchema,
            IntervalAmounts: intervalAmountsValidationSchema
        })
    );

export const ResourceSubTypeForecastEditor = ({ form, field, classes }) => {
    const { setFieldValue } = form;
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    const model = {
        Id: 0,
        // ParentId: 0,
        JobActivity: { Id: '0', _DisplayName: '' },
        ResourceSubtype: {
            Id: '0',
            _Name: ''
        },
        Shift: {},
        Amount: 1,
        EstimatedTime: 0,
        CurrentLifespan: {
            Start: '',
            End: ''
        },
        IntervalAmounts: []
    };
    return (
        <Section title="Resource Subtype Forecast">
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((activity, index) => {
                                    return (
                                        <Section key={index}>
                                            <Typography fontSize="12px">
                                                {format(
                                                    'MM/dd/yyyy',
                                                    parseDate(get('JobActivity._CurrentLifespan.Start', activity))
                                                )}
                                                -
                                                {format(
                                                    'MM/dd/yyyy',
                                                    parseDate(get('JobActivity._CurrentLifespan.End', activity))
                                                )}
                                            </Typography>
                                            <Field
                                                type="text"
                                                label="Job Activity"
                                                name={`${field.name}.${index}.JobActivity`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                optionLabelProperty="_DisplayName"
                                                optionsQuery={gql`
                                                    query ($searchString: String, $id: String) {
                                                        cxJobActivitys(
                                                            filters: [
                                                                {
                                                                    name: "String"
                                                                    values: ["DisplayName", $searchString]
                                                                }
                                                                { name: "Job.Id", values: [$id] }
                                                            ]
                                                        ) {
                                                            Id
                                                            _DisplayName: DisplayName
                                                            CurrentLifespan {
                                                                Start
                                                                End
                                                            }
                                                        }
                                                    }
                                                `}
                                                queryVariables={(values) => ({
                                                    id: get('Job.Id', values) || '-1'
                                                })}
                                                onChange={(event, value) => {
                                                    if (value) {
                                                        setFieldValue(
                                                            `${field.name}.${index}.CurrentLifespan`,
                                                            get('CurrentLifespan', value)
                                                        );
                                                        setFieldValue(
                                                            `${field.name}.${index}.JobActivity._CurrentLifespan`,
                                                            get('CurrentLifespan', value)
                                                        );
                                                    }
                                                }}
                                                disableClearable
                                            />
                                            <Field
                                                type="text"
                                                label="Subtype"
                                                name={`${field.name}.${index}.ResourceSubtype`}
                                                component={ResourceSubtypeEditor}
                                                className={classes.shortSelectField}
                                                family={['Labor', 'Group']}
                                            />
                                            <Field
                                                type="text"
                                                label="Shift"
                                                name={`${field.name}.${index}.Shift`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                optionsQuery={gql`
                                                    query ($searchString: String) {
                                                        cxShifts(
                                                            filters: [
                                                                {
                                                                    name: "String"
                                                                    values: ["DisplayName", $searchString]
                                                                }
                                                            ]
                                                        ) {
                                                            Id
                                                            _DisplayName: DisplayName
                                                            _Default: Default
                                                        }
                                                    }
                                                `}
                                                optionLabelProperty="_DisplayName"
                                                disableClearable
                                                initialize={true}
                                            />
                                            <Field
                                                type="number"
                                                label="Amount"
                                                name={`${field.name}.${index}.Amount`}
                                                component={TextField}
                                                className={classes.extraShortTextField}
                                            />
                                            <Field
                                                type="number"
                                                label={`Estimated ${get('values.Unit._DisplayName', form)}s`}
                                                name={`${field.name}.${index}.EstimatedTime`}
                                                component={TextField}
                                                className={classes.shortTextField}
                                            />
                                            <Section title="Lifespan">
                                                <Field
                                                    type="text"
                                                    name={`${field.name}.${index}.CurrentLifespan`}
                                                    component={CurrentLifespanEditor}
                                                    classes={classes}
                                                />
                                            </Section>
                                            <Field
                                                type="text"
                                                label="Interval Amounts"
                                                name={`${field.name}.${index}.IntervalAmounts`}
                                                component={IntervalAmountsEditor}
                                                classes={classes}
                                                interval="Months"
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            {/*{index > 0 && (*/}
                                            {/*    <IconButton*/}
                                            {/*        onClick={() => {*/}
                                            {/*            arrayHelpers.swap(index, index - 1);*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        <ArrowUpwardIcon />*/}
                                            {/*    </IconButton>*/}
                                            {/*)}*/}
                                            {/*{index < field.value.length - 1 && (*/}
                                            {/*    <IconButton*/}
                                            {/*        onClick={() => {*/}
                                            {/*            arrayHelpers.swap(index, index + 1);*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        <ArrowDownwardIcon />*/}
                                            {/*    </IconButton>*/}
                                            {/*)}*/}
                                        </Section>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

ResourceSubTypeForecastEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    data: PropTypes.object
};
