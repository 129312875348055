import React, { useContext, useEffect } from 'react';
import { Filter } from './Filter';
import {
    map,
    get,
    curry,
    identity,
    first,
    split,
    pipe,
    keyBy,
    sortBy,
    filter,
    find,
    flatMap,
    size,
    take
} from 'lodash/fp';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { withEditor } from 'client-shared/entities/withEditor';
import Scheduler, {
    getAllocationColors,
    getConfig,
    mapActivity,
    spanToIntraDayEvent
} from 'client-shared/components/timeline';
import {
    extractLocalConfigs,
    plusDays,
    useCollectionSubscription,
    AllocationEventQuery,
    UserContext,
    usePrivilege
} from 'client-shared/utility';
import { AllocationsQuery } from './queries.js';
import { dateToCBDateExact, templateFullContext } from 'global-shared/utils/utils';
import { evalQuery } from 'client-shared/gqlhelpers/queryHelpers';
import { useApolloClient } from '@apollo/client';
import Fab from '@mui/material/Fab';
import { todayAt } from 'client-shared/utility';
import isBefore from 'date-fns/fp/isBefore';
import isAfter from 'date-fns/fp/isAfter';
import isSameDay from 'date-fns/fp/isSameDay';
import { useNotifications } from 'client-shared/components/notifier2';

const Index = () => {
    const [user] = useContext(UserContext);
    const CONFIG_event_template = get(['congistics', 'configValues', 'schedule.dailyschedule.event_template'], user);
    return (
        <React.Fragment>
            <Filter>
                {({ values: variables, data: jobs }) => {
                    const [shiftStart, shiftEnd] = [
                        todayAt(variables._start, variables._startTime),
                        todayAt(variables._start, variables._endTime)
                    ];
                    const config = getConfig(extractLocalConfigs(variables), {
                        y_property: 'section_id',
                        scrollable: variables._scrollable,
                        shiftStart,
                        shiftEnd,
                        render: 'tree'
                    });

                    return (
                        <InnerScheduler
                            {...{
                                config,
                                variables,
                                jobs,
                                user,
                                eventTemplate: CONFIG_event_template,
                                shiftStart,
                                shiftEnd
                            }}
                        />
                    );
                }}
            </Filter>
        </React.Fragment>
    );
};

export default Index;

const InnerScheduler_ = (props) => {
    const { config, variables, jobs, onOpenEditor, shiftStart, shiftEnd, eventTemplate } = props;
    variables.start = variables._start;
    variables.end = plusDays(variables._periods, variables.start);
    variables['job.Ids'] = map(get('Id'), jobs);
    const client = useApolloClient();
    const { data: allocations, event } = useCollectionSubscription({
        dataQuery: AllocationsQuery,
        eventQueries: [AllocationEventQuery],
        values: variables
    });

    const { openSBPermanent } = useNotifications();
    const [privilege] = usePrivilege('Allocation');

    useEffect(() => {
        if (jobs.length > 100)
            openSBPermanent(
                "There's a limit to 200 jobs running down the left side.  Adjust your filters to show fewer.",
                { variant: 'warning' }
            );
    }, [jobs]);

    const jobMap = keyBy('Id', take(200, jobs));
    // const fixWhite = (_in) => (_in === '#FFFFFF' ? '#000000' : _in);

    const allocationToEvent = (_in) => ({
        ...spanToIntraDayEvent(get('CurrentLifespan', _in)),
        allocationId: _in.Id,
        JobActivityId: get('JobActivity.Id', _in),
        o: _in,
        id: `${_in.Id}_${_in.Job.Id}`,
        activityId: _in.Job.Id,
        originalActivity: _in.Job.Id,
        section_id: `${get('Job.Id', _in)}_${get('JobActivity.Id', _in)}`,
        text: eventTemplate
            ? templateFullContext(eventTemplate, _in)
            : `${get('DisplayName', _in)}: ${get('JobActivity.DisplayName', _in)}`,
        ...getAllocationColors(variables, _in)
    });

    const jobActivities = map(get('JobActivity.Id'), allocations);
    const activityMap = keyBy('Id', flatMap(get('JobActivities'), take(200, jobs)));
    const schedulerData = map(allocationToEvent, allocations);

    const _config = {
        ...config,
        ...{
            y_unit: pipe(sortBy(false), map(mapTreeTop(shiftStart, jobActivities)))(jobs.slice(0, 200)),
            end: shiftEnd
        }
    };

    function editCallback(e, more) {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: [first(split('_', e))] }]
            }
        });
    }

    async function onEmptyClick(date, e) {
        const endTime = new Date(date.getTime() + 60 * 60 * 1000);
        const el = e.target.closest('.dhx_matrix_line');
        const [jobId, activityId] = split('_', get('dataset.sectionId', el));
        if (!jobId) return;
        onOpenEditor('EditCxAllocation', {
            initialValues: {
                CurrentLifespan: {
                    Start: dateToCBDateExact(date),
                    End: dateToCBDateExact(endTime)
                },
                Job: {
                    Id: jobId,
                    _DisplayName: jobMap[jobId]['DisplayName']
                },
                JobActivity: {
                    Id: activityId,
                    _DisplayName: activityMap[activityId]['DisplayName']
                }
            },
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    }

    const schedulerEvents = {
        onEventChanged,
        onEmptyClick,
        onBeforeEventChanged: (ev, e, is_new, original) => !privilege
    };

    async function onEventChanged(id, event) {
        const { items, methodsByItem } = eventToMutation(event);
        try {
            await evalQuery(client, null, 'CxAllocation', items, methodsByItem);
        } catch (e) {
            console.log(e);
        }
    }

    const openEditor = (e) => {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    };

    const schedulerProps = {
        editCallback,
        onEventDeleted: identity,
        event
    };
    //if (schedularData.length < 1) return <p>Doing...</p>;
    console.log(size(schedulerData));
    //return (<p>ok</p>)
    return (
        <Box pb={5}>
            <Scheduler
                height="100%"
                width="100%"
                startDate={shiftStart}
                title="Allocation Timeline"
                treeView={true}
                data={schedulerData}
                timelineConfig={_config}
                {...schedulerProps}
                {...schedulerEvents}
            />
            <Fab color="primary" aria-label="add" style={{ float: 'right', marginRight: 10 }} onClick={openEditor}>
                <AddIcon />
            </Fab>
        </Box>
    );
};

const InnerScheduler = withEditor(InnerScheduler_);

export const mapTop = (_in) => ({
    key: get('Id', _in),
    label: `${get('DisplayName', _in)}}`,
    tooltip: `${get('DisplayName', _in)}`
});

const isInRange = (targetDay, candidate) => {
    const startDate = new Date(get('CurrentLifespan.Start', candidate));
    const endDate = new Date(get('CurrentLifespan.End', candidate));
    if (!isSameDay(startDate, targetDay) && isAfter(targetDay, startDate)) return false;
    if (!isSameDay(endDate, targetDay) && isBefore(targetDay, endDate)) return false;
    //console.log(candidate.DisplayName, isAfter(startDate, targetDay), targetDay, startDate)
    return true;
};

const filterActivity = curry((shiftStart, active, candidate) => {
    if (find((each) => candidate.Id === each, active)) return true;
    if (!isInRange(shiftStart, candidate)) return false;
    if (candidate.Completed) return false;
    return true;
});
/**
 * This is complicated, sorry
 */
export const mapTreeTop = curry((shiftStart, scheduledActivities, _in) => {
    const children = pipe(
        filter(filterActivity(shiftStart, scheduledActivities)),
        map(mapActivity(get('Id', _in)))
    )(get('JobActivities', _in));

    return {
        key: get('Id', _in),
        label: get('DisplayName', _in),
        open: true,
        tooltip: `${get('DisplayName', _in)}
        ${get('Description', _in)}`,
        children
    };
});

export const prepareTimelineDate = (ev) => ({
    start: dateToCBDateExact(ev.start_date),
    end: dateToCBDateExact(ev.end_date)
});
export const eventToMutation = (event) => {
    const [jobId, activityId] = event.section_id.split('_');
    const _allocation = { Id: event.allocationId, JobActivity: { Id: activityId }, Job: { Id: jobId } };
    return {
        methodsByItem: [
            {
                indices: [0],
                methods: [{ name: 'setRange', args: prepareTimelineDate(event) }]
            }
        ],
        items: [_allocation]
    };
};
