import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { CxJobsQuery, FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxAllocation';
import inputModel from 'cb-schema/inputmodelfull/CxAllocation';
import {
    Field,
    CurrentLifespanTimeEditor,
    currentLifespanTimeValidationSchema,
    AutoComplete,
    TagEditor,
    tagValidationSchema,
    AddressEditor
} from 'client-shared/components/form';
import { object, string, number } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import { ResourceSpansEditor, resourceSpansValidationSchema } from './ResourceSpansEditor';
import { ResourceQuantitySpansEditor, resourceQuantitySpansValidationSchema } from './ResourceQuantitySpansEditor';
import { RecurranceEditor, recurranceValidationSchema } from './RecurranceEditor';
import cloneDeep from 'lodash/cloneDeep';
import { pickFromTemplateFp, parseDate, Mutation, query } from 'client-shared/utility';
import { flow, sortBy, get, map, flatMap } from 'lodash/fp';
import { format, addDays } from 'date-fns/fp';
import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { withEditor } from '../withEditor';

/*
 * string **interval** -- Mandatory. Valid values are "Weeks" or "Months".
 * DateTime **end** -- Mandatory. Recurrence will cease after this date.
 * List<int> **days** -- Optional. If **interval** is "Weeks", the day or days of the week you want it to recur. If "Months", the day or days of the month. If omitted, the weekday or day of the month of Allocation.CurrentLifespan.Start will be used.
 * int **increment** -- The number of periods by which the recurrence will be separated. It must be > 0. The default is 1. An increment of, say, 3 means that the recurrence will take place every three weeks or three months, depending on the value of **interval**.
 */

const getMethods = (allocation, touched) => {
    let methods = [];
    const setRange = {
        name: 'setRange',
        args: {
            start: allocation._changeTimes
                ? allocation.CurrentLifespan.Start
                : format("yyyy-MM-dd'T'00:00:00", parseDate(allocation.CurrentLifespan.Start)),
            end: allocation._changeTimes
                ? allocation.CurrentLifespan.End
                : flow(addDays(1), format("yyyy-MM-dd'T'00:00:00"))(parseDate(allocation.CurrentLifespan.End))
        }
    };

    if (touched.DayCount) {
        delete setRange.args.end;
        setRange.args.days = allocation.DayCount;
    }

    const setShift = {
        name: 'setShift',
        args: {
            shiftId: allocation.Shift.Id
        }
    };

    const setResourceSpans = {
        name: 'setSpans',
        args: {
            resourceSpans: map(
                (resourceSpan) => ({
                    Principal: { Id: resourceSpan.Principal.Id },
                    Range: {
                        Start: resourceSpan.Range.Start,
                        End: resourceSpan.Range.End
                    },
                    Amount: resourceSpan.Amount,
                    Auxiliaries: map((auxiliary) => ({ Id: auxiliary.Id }), resourceSpan.Auxiliaries)
                }),
                allocation.ResourceSpans
            )
        }
    };

    const setResourceQuantitySpans = {
        name: 'setSpans',
        args: {
            resourceQuantitySpans: map(
                (resourceQuantitySpan) => ({
                    SupplierId: resourceQuantitySpan.Supplier.Id,
                    ResourceSubtypeId: resourceQuantitySpan.ResourceSubtype.Id,
                    Amount: resourceQuantitySpan.Amount,
                    Range: resourceQuantitySpan.Range
                }),
                allocation.ResourceQuantitySpans
            )
        }
    };

    const addResources = {
        name: 'addResources',
        args: {
            resourceIds: flatMap(
                (resourceQuantitySpan) => map((resourceId) => resourceId.Id, resourceQuantitySpan.ResourceIds),
                allocation.ResourceQuantitySpans
            )
        }
    };
    const recur = {
        name: 'Recur',
        args: {
            interval: allocation._interval,
            end: flow(addDays(1), format("yyyy-MM-dd'T'00:00:00"))(parseDate(allocation.CurrentLifespan.End)),
            days:
                allocation._interval === 'Weeks' ? allocation._days : map((day) => day._DisplayName, allocation._days),
            increment: allocation._increment
        }
    };

    // remove properties that are set by the evals.
    delete allocation.Shift;
    delete allocation.CurrentLifespan;
    delete allocation.TaskDays;
    if (!allocation._recur) {
        methods.push(setResourceSpans);
        methods.push(setResourceQuantitySpans);
    }
    methods.push(addResources);
    if (touched.CurrentLifespan || touched.DayCount) {
        methods.push(setRange);
    }
    if (touched.Shift) {
        // set the shift first if a new allocation so it doesn't override the range in the setspan call.
        if (allocation.Id === 0) {
            methods.unshift(setShift);
        } else {
            methods.push(setShift);
        }
    }

    if (allocation._recur) {
        methods.push(recur);
    }

    return {
        operation: 'edit',
        methodsByItem: [
            {
                indices: [0],
                methods: methods
            }
        ],
        items: [pickFromTemplateFp(inputModel, allocation)]
    };
};

const handleCopy = (client, formQueryValues) => async (values) => {
    const { data } = await query(client, FormQuery, {
        ...formQueryValues,
        methodsByItem: [
            {
                methods: [
                    {
                        name: 'copyasnew'
                    }
                ]
            }
        ]
    });
    return { ...values, ...get('cxAllocations.0', data) };
};

const handleSubmit = (client) => (formValues, formikBag, touched) => {
    const allocation = cloneDeep(formValues);
    const mutation = new Mutation(client);
    const { methodsByItem, items } = getMethods(allocation, touched);
    // todo: may not need the .catch below. check this.
    return mutation.save('CxAllocation', items, methodsByItem).catch((error) => console.log('save error', error));
};

// do not day cap.
const validationSchema = object({
    Name: string().min(2, 'Too Short').max(65, 'Too Long'),
    ActionStatus: object({
        Id: number().moreThan(0, 'Required')
    }),
    Shift: object({
        Id: number().moreThan(0, 'Required')
    }),
    Job: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    JobActivity: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    CurrentLifespan: currentLifespanTimeValidationSchema,
    DayCount: number().moreThan(-1, 'Can not be negative'),
    ResourceSpans: resourceSpansValidationSchema,
    ResourceQuantitySpans: resourceQuantitySpansValidationSchema,
    Tags: tagValidationSchema,
    ...recurranceValidationSchema
});

const Form_ = ({ onOpenEditor, ...props }) => {
    const { classes, data, setFieldValue, values } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <div>
                    <Field type="text" label="Name" name="Name" component={TextField} className={classes.textField} />
                    <Field
                        type="text"
                        label="Display Name"
                        name="DisplayName"
                        component={TextField}
                        className={classes.textField}
                        disabled={true}
                    />
                    <Field
                        type="text"
                        label="Action Status"
                        name="ActionStatus"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String) {
                                cxActionStatuss(filters: [{ name: "String", values: ["DisplayName", $searchString] }]) {
                                    Id
                                    _DisplayName: DisplayName
                                    _Default: Default
                                }
                            }
                        `}
                        optionLabelProperty="_DisplayName"
                        disableClearable
                    />
                    <Field
                        type="text"
                        label="Shift"
                        name="Shift"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String) {
                                cxShifts(filters: [{ name: "String", values: ["DisplayName", $searchString] }]) {
                                    Id
                                    _DisplayName: DisplayName
                                    _Default: Default
                                }
                            }
                        `}
                        optionLabelProperty="_DisplayName"
                        disableClearable
                    />
                    <Field
                        type="checkbox"
                        Label={{ label: 'Return' }}
                        name="ReturnToOwner"
                        component={CheckboxWithLabel}
                        style={{ paddingLeft: '30px' }}
                    />
                    <Field
                        type="checkbox"
                        Label={{ label: 'Lock' }}
                        name="Locked"
                        component={CheckboxWithLabel}
                        style={{ paddingLeft: '30px' }}
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Job"
                        name="Job"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="_DisplayName"
                        optionsQuery={CxJobsQuery}
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00", new Date()),
                            end: flow(addDays(365), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                        onChange={(event, value) => {
                            if (value) {
                                setFieldValue('JobActivity', get('_JobActivities.0', value));
                                setFieldValue('Job._Address', get('_Address', value));
                            } else {
                                setFieldValue('JobActivity', { Id: '0', _DisplayName: '' });
                            }
                        }}
                    />
                    <input
                        style={{ margin: '20px 25px 0px 0px' }}
                        type="button"
                        value="..."
                        onClick={() => {
                            onOpenEditor(
                                'EditCxJob',
                                {
                                    formQueryValues: {
                                        filters: [{ name: 'Id', values: [get('Job.Id', values)] }]
                                    }
                                },
                                (value) => {
                                    setFieldValue('Job', { Id: get('Id', value), _DisplayName: get('Name', value) });
                                    setFieldValue('JobActivity', {
                                        Id: get('JobActivities.0.Id', value),
                                        _DisplayName: get('JobActivities.0.Name', value)
                                    });
                                    setFieldValue('Job._Address', get('Address', value));
                                }
                            );
                        }}
                    />
                    <Field
                        type="text"
                        label="Job Activity"
                        name="JobActivity"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="_DisplayName"
                        optionsQuery={gql`
                            query ($searchString: String, $id: String) {
                                cxJobActivitys(
                                    objectType: "JobActivity"
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Job.Id", values: [$id] }
                                    ]
                                ) {
                                    Id
                                    _DisplayName: DisplayName
                                }
                            }
                        `}
                        queryVariables={(values) => ({
                            id: get('Job.Id', values) || '-1'
                        })}
                        disableClearable
                    />
                </div>
                <div>
                    <Field type="text" name="CurrentLifespan" component={CurrentLifespanTimeEditor} classes={classes} />
                    {!values._recur && (
                        <Field
                            type="number"
                            label="# Days"
                            name="DayCount"
                            component={TextField}
                            className={classes.extraShortTextField}
                            inputProps={{ min: 1, max: 365 * 2 }}
                        />
                    )}
                    <Field
                        type="checkbox"
                        Label={{ label: 'Change Times' }}
                        name="_changeTimes"
                        component={CheckboxWithLabel}
                        style={{ marginLeft: '50px' }}
                    />
                    <Field
                        type="checkbox"
                        Label={{ label: 'Repeat' }}
                        name="_recur"
                        component={CheckboxWithLabel}
                        style={{ marginLeft: '50px' }}
                    />
                    {values._recur && (
                        <Field type="text" /*name="ResourceSpans"*/ component={RecurranceEditor} classes={classes} />
                    )}
                </div>
                <Field type="text" name="ResourceSpans" component={ResourceSpansEditor} classes={classes} />
                <Field
                    type="text"
                    name="ResourceQuantitySpans"
                    component={ResourceQuantitySpansEditor}
                    classes={classes}
                    data={data}
                />
                <Field
                    type="text"
                    name="Tags"
                    component={TagEditor}
                    classes={classes}
                    customProperties={data.cxCustomPropertys}
                />
                <Field
                    type="text"
                    label="Address"
                    name="Job._Address"
                    component={AddressEditor}
                    classes={classes}
                    oneLineFormat={true}
                    disabled={true}
                />
                <div>
                    <Field
                        type="text"
                        label="Job Cost"
                        name="JobCosts"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="DisplayName"
                        initialize={false}
                        optionsQuery={gql`
                            query ($searchString: String, $id: String) {
                                cxJobs(
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Id", values: [$id] }
                                    ]
                                ) {
                                    GlobalJobCosts {
                                        Id
                                        DisplayName
                                    }
                                }
                            }
                        `}
                        queryVariables={(values) => ({
                            id: get('Job.Id', values) || '-1'
                        })}
                        getOptions={(options) => {
                            return get('0.GlobalJobCosts', options);
                        }}
                    />
                </div>
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxAllocation = ({ open, formQueryValues, initialValues, onClose }) => {
    const client = useApolloClient();
    const Form = withEditor(Form_);
    // initialize model properties.
    let _initialValues = cloneDeep(model);
    //delete _initialValues.Crew;
    _initialValues.DayCount = 1;
    _initialValues.Job = {
        Id: '0',
        _DisplayName: '',
        _Address: {
            Id: 0,
            Street: '',
            StreetDetails: '',
            City: '',
            State: {
                Id: 0,
                _Description: ''
            },
            ZipCode: '',
            Geocode: {
                Id: 0,
                Lat: 0,
                Lng: 0
            }
        }
    };
    _initialValues.JobActivity = { Id: '0', _DisplayName: '' };
    _initialValues.ActionStatus = {};
    _initialValues.Shift = {};
    _initialValues.CurrentLifespan = {
        Start: format("yyyy-MM-dd'T'08:00:00", new Date()),
        End: format("yyyy-MM-dd'T'17:00:00", new Date())
    };
    _initialValues._changeTimes = false;
    _initialValues.ResourceSpans = [];
    _initialValues.ResourceQuantitySpans = [];
    _initialValues = { ..._initialValues, ...initialValues };
    _initialValues.ReturnToOwner = false;
    _initialValues.Locked = false;
    _initialValues._recur = false;
    _initialValues._interval = 'Weeks';
    _initialValues._days = [];
    _initialValues._increment = 1;
    const onData = (initialValues) => {
        if (!initialValues) {
            return initialValues;
        }
        initialValues.ResourceSpans = sortBy(['Range.Start', 'Principal.Id'], initialValues.ResourceSpans);
        return initialValues;
    };
    return (
        <DialogForm
            open={open}
            title="Allocation"
            Form={(props) => <Form {...props} />}
            validationSchema={validationSchema}
            initialValues={_initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxAllocation"
            inputModel={inputModel}
            allowDelete={true}
            onClose={onClose}
            onSubmit={handleSubmit(client)}
            onCopy={handleCopy(client, formQueryValues)}
            onData={onData}
        />
    );
};

EditCxAllocation.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    initialValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
