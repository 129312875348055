/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';

export const JobName = ({ job, ...other }) => {
    return (
        <span
            style={{
                color: get('ForeColor', job),
                backgroundColor: get('BackColor', job),
                wordWrap: 'break-word',
                whiteSpace: 'pre-line'
            }}
            {...other}
        >
            {get('DisplayName', job)}
        </span>
    );
};

JobName.propTypes = {
    job: PropTypes.object
};
