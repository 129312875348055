import { gql } from '@apollo/client';
import { curry } from 'lodash/fp';

export const putKkv = curry((client, { k, kk, data }) =>
    client.mutate({
        mutation: put,
        variables: {
            data: {
                k,
                kk,
                data
            }
        }
    })
);

export const deleteKkv = curry((client, k, kk) =>
    client.mutate({
        mutation: remove,
        variables: {
            k,
            kk
        }
    })
);

export const deleteK = curry((client, k) =>
    client.mutate({
        mutation: remove,
        variables: {
            k
        }
    })
);

export const getKkv = curry(async (client, k, kk) => {
    const result = await client.query({
        fetchPolicy: 'network-only',
        query: get,
        variables: {
            k,
            kk
        }
    });
    return result.data;
});

export const getK = curry(async (client, k) => {
    const result = await client.query({
        fetchPolicy: 'network-only',
        query: get,
        variables: {
            k
        }
    });
    return result.data;
});

const put = gql`
    mutation kkvPut($data: KKVInput!) {
        kkvPut(data: $data)
    }
`;

const remove = gql`
    mutation kkvDelete($k: String!, $kk: String) {
        kkvDelete(k: $k, kk: $kk)
    }
`;

const get = gql`
    query kkvFetch($k: String!, $kk: String) {
        kkvFetch(k: $k, kk: $kk) {
            k
            kk
            data
        }
    }
`;
