import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popper, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

export const Hover = ({ message, HoverIcon = DescriptionIcon }) => {
    const [hover, setHover] = useState({ anchorEl: null, message: null });
    //const id = open ? 'simple-popper' : undefined;
    const handleHover = (event) => {
        if (!event) {
            setHover({ anchorEl: null, message: null });
            return;
        }
        setHover({ anchorEl: event.currentTarget, message: message });
    };
    return (
        <React.Fragment>
            <Popper sx={{ zIndex: 1000 }} open={Boolean(hover.anchorEl)} anchorEl={hover.anchorEl}>
                <Typography
                    fontSize="small"
                    sx={{
                        wordBreak: 'break-all',
                        maxWidth: '200px',
                        border: '1px solid',
                        padding: '5px',
                        backgroundColor: 'white'
                    }}
                    component="div"
                >
                    {message}
                </Typography>
            </Popper>
            {message ? (
                <HoverIcon
                    sx={{ fontSize: '15px', marginRight: '3px', marginLeft: '3px' }}
                    onMouseEnter={(event) => {
                        handleHover(event, message);
                    }}
                    onMouseLeave={() => handleHover()}
                />
            ) : null}
        </React.Fragment>
    );
};

Hover.propTypes = {
    message: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.element.isRequired,
        PropTypes.node.isRequired
    ]),
    HoverIcon: PropTypes.func
};
