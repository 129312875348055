/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';
import { JobName } from 'client-shared/components/serverobjects/JobName';
import { Address } from 'client-shared/components/serverobjects/Address';
import { Description, NoteMobileEditor } from 'client-shared/components/serverobjects/Description';
import { Contacts } from 'client-shared/components/serverobjects/Contacts';
import { Directions } from 'client-shared/components/serverobjects/Directions';
import { AttachmentButton } from 'client-shared/components/attachments/button';
import { Formwidget } from 'client-shared/components/attachableforms/formwidget';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    button: {
        paddingBottom: theme.spacing(1)
    }
}));

export const Job = ({ job, ...other }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <span {...other}>
            <JobName job={job} />
            <div>
                <Address address={job.Address} />
            </div>
            <Contacts className={classes.button} contacts={[job.Manager]} />
            <Contacts className={classes.button} contacts={job.Contacts} />
            <AttachmentButton className={classes.button} pk={parseInt(job.Id)} objectType="CxJob" />
            <Directions className={classes.button} geocode={job.Address.Geocode} />
            <Description description={job.Description} />
            <NoteMobileEditor
                id={job.Id}
                objectName="cxJob"
                CurrentNote={job.CurrentNote}
                NoteHistory={job.NoteHistory}
            />
            <Formwidget entityId={job.Id} clazz="CxJob" />
        </span>
    );
};

Job.propTypes = {
    job: PropTypes.object.isRequired
};
