import Observable from 'zen-observable';
const map = require('lodash/fp/map').convert({ cap: false });

// const _observableConcat = (observables) =>
//     new Observable(observer => {
//         observables.forEach(observable => {
//             observable.subscribe(data => {
//                 observer.next(data);
//             });
//         });
//     });

const _observableConcat = (observables) =>
    new Observable((observer) => {
        map((observable, index) => {
            observable.subscribe((data) => {
                data.index = index;
                observer.next(data);
            });
        }, observables);
    });

/**
 * watch several observable queries (i.e. apollo graphql queries that watch the cache for changes using watchQuery).
 * this function returns one observable that returns data from any of the queries that fire.
 * usage: const observable = watchQueries(client, [query1, ...]);
 * observable.subscribe(({data, loading, error}) => console.log(loading, error, data));
 * @param client
 * @param watchQueries
 * @returns {Observable|*}
 */
export const watchQueries = (client, watchQueries) => {
    const observableQueries = map(
        (watchQuery) =>
            client.watchQuery({
                query: watchQuery,
                fetchPolicy: 'cache-only'
            }),
        watchQueries
    );
    return _observableConcat(observableQueries);
};
