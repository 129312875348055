import { gql } from 'graphql-tag';
import { TagFragment, CustomPropertyFragment } from 'client-shared/utility/fragments';

export const FormQuery = gql`
    ${TagFragment}
    query ($objectType: String = "Job", $filters: [FilterInput], $methodsByItem: [methodsByItemInput]) {
        cxJobs(objectType: $objectType, filters: $filters, methodsByItem: $methodsByItem) {
            ObjectType
            Id
            AccountCode
            Name
            DisplayName
            Description
            ForeColor
            BackColor
            CurrentNote
            NoteHistory {
                AsOf
                Party {
                    DisplayName
                }
                Note
            }
            Priority {
                Id
                _DisplayName: DisplayName
            }
            Client {
                Id
                _DisplayName: DisplayName
            }
            Types {
                Id
                _DisplayName: DisplayName
            }
            Subtypes {
                Id
                _DisplayName: DisplayName
            }
            CurrentLifespan {
                Start
                End
            }
            Certifications {
                Id
                LookupValue {
                    Id
                    _DisplayName: DisplayName
                }
                CurrentLifespan {
                    Start
                    End
                }
                Description
            }
            Owner {
                Id
            }
            Manager {
                Id
                _DisplayName: DisplayName
            }
            JobStatus {
                Id
                _DisplayName: DisplayName
            }
            DefaultShift {
                Id
                _DisplayName: DisplayName
            }
            Divisions {
                Id
                _DisplayName: DisplayName
            }
            StatusDue
            ForeColor
            BackColor
            EstimatedLaborHours
            TotalCost {
                Unit {
                    Id
                    _DisplayName: DisplayName
                }
                Quantity
            }
            ContractValue {
                Unit {
                    Id
                    _DisplayName: DisplayName
                }
                Quantity
            }
            JobActivities {
                Id
                Name
                Job {
                    Id
                }
                Completed
                DefaultShift {
                    Id
                    _DisplayName: DisplayName
                }
                Description
                CurrentLifespan {
                    Start
                    End
                }
                ForeColor
                BackColor
                Tags {
                    Id
                    CustomProperty {
                        Id
                        _Name: Name
                        _AttributeType: AttributeType
                    }
                    Value
                }
                EstimatedLaborHours
            }
            JobCosts {
                Id
                Name
                ObjectType
                UnitCost {
                    Unit {
                        Id
                        DisplayName
                    }
                    Cost {
                        Unit {
                            Id
                            DisplayName
                        }
                        Quantity
                    }
                }
                Cost {
                    Unit {
                        Id
                        DisplayName
                    }
                    Quantity
                }
                CostCode {
                    UnitCost {
                        Unit {
                            Id
                            DisplayName
                        }
                        Cost {
                            Unit {
                                Id
                                DisplayName
                            }
                            Quantity
                        }
                    }
                    Id
                    Name
                    DisplayName
                    Description
                }
                TotalAmount
                ExpectedAmount
                Description
                CompletedAmount
            }
            Contacts {
                Id
                Name
                Title
                PhoneNumbers {
                    Id
                    Name
                    PhoneNumber
                }
                Emails {
                    Id
                    Name
                    Email
                }
            }
            Address {
                Id
                Autocode
                Street
                StreetDetails
                City
                State {
                    Id
                    _Description: Description
                }
                ZipCode
                Geocode {
                    Id
                    Lat
                    Lng
                }
            }
            Tags {
                ...TagFragment
            }
        }
        CustomPropertysJob: cxCustomPropertys(
            objectType: "CustomProperty"
            filters: [{ name: "SupportedTypes", values: ["Job"] }]
        ) {
                  ${CustomPropertyFragment}
        }
        CustomPropertysJobActivity: cxCustomPropertys(
            objectType: "CustomProperty"
            filters: [{ name: "SupportedTypes", values: ["JobActivity"] }]
        ) {
                       ${CustomPropertyFragment}
        }
    }
`;

export const CxEmployeesQuery = gql`
    query ($searchString: String, $start: String, $end: String) {
        cxEmployees(
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "Range", values: [$start, $end] }
            ]
        ) {
            Person {
                Id
                _DisplayName: DisplayName
            }
        }
    }
`;

export const CxPartysQuery = gql`
    query ($searchString: String) {
        cxPartys(
            objectType: "Party"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "ObjectType", values: ["Company", "Subcontractor"] }
            ]
        ) {
            Id
            _DisplayName: DisplayName
            Address {
                Id
                Street
                StreetDetails
                City
                State {
                    Id
                    _Description: Description
                }
                ZipCode
            }
        }
    }
`;
