import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field, ResourceSubtypeEditor } from 'client-shared/components/form';
import { array, number, object } from 'yup';
import { Section } from 'client-shared/components/Section';
import { TextField } from 'formik-mui';
import TextFieldMUI from '@mui/material/TextField';
import { get } from 'lodash/fp';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const model = {
    Id: 0,
    _ResourceType: {
        Unit: {
            Name: ''
        }
    },
    ResourceSubtype: {
        Id: '0',
        _Name: ''
    },
    Amount: 1
};

export const capacitiesValidationSchema = array().of(
    object({
        ResourceSubtype: object({
            Id: number().moreThan(0, 'Required')
        }),
        Amount: number().min(1, 'Must be greater than 0').max(999999999, 'Must be less than 999,999,999')
    })
);

export const CapacitiesEditor = ({ form, field, classes, ...other }) => {
    return (
        <Section title="Capacities">
            <FieldArray
                name={field.name}
                render={(arrayHelpers) => {
                    return (
                        <React.Fragment>
                            {field.value.map((value, index) => {
                                return (
                                    <div key={index}>
                                        <Field
                                            type="text"
                                            label="Subtype"
                                            name={`${field.name}.${index}.ResourceSubtype`}
                                            component={ResourceSubtypeEditor}
                                            className={classes.selectField}
                                            family={['Material']}
                                            onResourceTypeChange={(value) => {
                                                form.setFieldValue(
                                                    `${field.name}.${index}._ResourceType.Unit`,
                                                    get('Unit', value)
                                                );
                                            }}
                                        />
                                        <Field
                                            type="number"
                                            label="Amount"
                                            name={`${field.name}.${index}.Amount`}
                                            component={TextField}
                                            className={classes.textField}
                                        />
                                        <TextFieldMUI
                                            label="Unit"
                                            value={get('_ResourceType.Unit.Name', value)}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                        <IconButton
                                            onClick={() => {
                                                arrayHelpers.remove(index);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                );
                            })}
                            <IconButton
                                onClick={() => {
                                    arrayHelpers.push(model);
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </React.Fragment>
                    );
                }}
            />
        </Section>
    );
};

CapacitiesEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};
