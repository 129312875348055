import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "Batch", $filters: [FilterInput]) {
        cxBatchs(objectType: $objectType, filters: $filters) {
            Id
            ImportType
            SummaryMessage
            Errors {
                Message
                Severity
            }
            RecentRuns {
                Id
                Start
                End
                SummaryMessage
                Errors {
                    Message
                    Severity
                }
            }
            Overwrite
            __typename
        }
    }
`;
