import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { pickFromTemplate, Mutation, usePrivilege } from 'client-shared/utility';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/fp/get';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import DialogMUI from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { EditForm } from './EditForm';
import { useApolloClient } from '@apollo/client';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Retrieves form data from the dbserver or cache and displays a dialog formik form for editing.
 * @param open - true, opens the form dialog
 * @param title - title at top of form.
 * @param Form - formik snippet that contains the editor components.
 * @param validationSchema - optional yup validation schema to validate the form object.
 * @param initialValues - object containing initial values passed to form components. these are updated by
 * the components.
 * @param formQuery - graphql query document to retrieve data used by the form components. pass as the data
 * parameter to the Form snippet. if data is returned, this also overwrites the initialValues with the returned data.
 * @param formQueryValues - optional values passed to the form query.
 * @param objectName - name of object to persist on the server.
 * @param inputModel - object properties to persist, all others are ignored.
 * @param closeOnSave - true if dialog should close when save is clicked, otherwise, a Close button is displayed.
 * @param allowDelete - true if the user can delete the object.
 * @param onCopy - copy function to make a copy of the object. should return copy of values.
 * @param queryName - optional name of query (e.g. cxCompanys) in the data object returned from the server.
 * @param showActions - true if default dialog actions should be shown.
 * @param cache - optional boolean that determines whether the graphql will cache results using a fetchPolicy.
 * @param onClose - optional function to close the dialogue and post process.
 * @param transform - optional function to pre-process data before being persisted. data, touched is passed
 * to transform which should return the pre-processed data.
 * @param onSubmit - optional function to persist data to the server, otherwise, default function will be used.
 * @param onData - optional function to process the data before sending it to formik.
 * @returns {string|*}
 * @constructor
 */

export const DialogForm = ({
    open,
    title,
    Form,
    validationSchema,
    initialValues,
    formQuery,
    formQueryValues,
    objectName,
    inputModel,
    closeOnSave = true,
    allowDelete = false,
    onCopy,
    queryName,
    showActions = true,
    cache,
    onClose,
    transform = (data) => data,
    onSubmit,
    onData
}) => {
    const client = useApolloClient();
    const [privilege] = usePrivilege(objectName.slice(2));
    const [values, setValues] = useState(formQueryValues);

    useEffect(() => {
        setValues(formQueryValues);
    }, [formQueryValues]);

    const submit = async (formValues, formikBag, touched) => {
        let _formValues = cloneDeep(formValues);
        _formValues = transform(_formValues, touched);
        _formValues = pickFromTemplate(_formValues, inputModel);
        //_formValues = omitEmptyProperties(_formValues);
        const mutation = new Mutation(client);
        return mutation.save(objectName, [_formValues], undefined, undefined, values.objectType);
    };

    // call the mutation to persist values. the mutation can persist values on the dbserver or the local cache if
    // offline. the dialog form will be refreshed with new values and remain open if closeOnSave is false.
    // when offline, the save mutation will not return until online again so the dialog must be closed
    // prior to the save mutation.
    const handleSubmit = async (formValues, formikBag, touched) => {
        onSubmit = onSubmit || submit;
        // const online = await isOnline();
        // if (!online && closeOnSave) {
        //     onClose();
        // }
        return onSubmit(formValues, formikBag, touched).then(({ data }) => {
            const { [Object.keys(data)[0]]: mutationReturnValues } = data;
            if (closeOnSave) {
                onClose({ ...formValues, ...get('0', mutationReturnValues) });
                return;
            }
            // refresh form with new value but only if one value was returned. this is not for bulk edit.
            if (mutationReturnValues.length === 1) {
                setValues({ ...formQueryValues, filters: [{ name: 'Id', values: [mutationReturnValues[0].Id] }] });
            }
        });
    };

    const WrappedForm = (props) => {
        const handleSave = () => {
            if (privilege) {
                //return;
            }
            props.submitForm().catch((error) => console.log(error));
        };

        const handleDelete = () => {
            const id = get('values.Id', props);
            if (!id) {
                return;
            }
            if (privilege) {
                return;
            }
            const mutation = new Mutation(client);
            mutation
                .delete(objectName, [id], values.objectType)
                .then(() => onClose())
                .catch((error) => console.log(error));
        };

        const handleReset = () => {
            props.setValues(initialValues);
            //setValues({ filters: [{ name: 'id', values: ['-1'] }] });
        };

        const handleCopy = async () => {
            const values = await onCopy(props.values);
            props.setValues(values);
        };

        return (
            <React.Fragment>
                <DialogTitle>{title}</DialogTitle>
                {showActions && (
                    <DialogActions>
                        <Button
                            onClick={() => {
                                onClose(get('values', props));
                            }}
                            color="primary"
                        >
                            {props.dirty ? 'Cancel' : 'Close'}
                        </Button>
                        <Button onClick={handleReset} color="primary">
                            New
                        </Button>
                        {onCopy && (
                            <Button onClick={handleCopy} color="primary">
                                Copy
                            </Button>
                        )}
                        <Button onClick={handleSave} color="primary" disabled={!props.dirty}>
                            Save
                        </Button>
                        {allowDelete && (
                            <Button onClick={handleDelete} color="primary">
                                Delete
                            </Button>
                        )}
                    </DialogActions>
                )}
                <DialogContent>
                    <Form {...props} />
                </DialogContent>
            </React.Fragment>
        );
    };

    return (
        <DialogMUI
            // disableBackdropClick
            disableEscapeKeyDown
            style={{ zIndex: '1100' }}
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <EditForm
                Form={WrappedForm}
                validationSchema={validationSchema}
                initialValues={initialValues}
                formQuery={formQuery}
                formQueryValues={values}
                objectName={objectName}
                queryName={queryName}
                cache={cache}
                onSubmit={handleSubmit}
                onData={onData}
            />
        </DialogMUI>
    );
};

DialogForm.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    Form: PropTypes.func.isRequired,
    validationSchema: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    formQuery: PropTypes.object.isRequired,
    formQueryValues: PropTypes.object,
    objectName: PropTypes.string.isRequired,
    inputModel: PropTypes.object.isRequired,
    closeOnSave: PropTypes.bool,
    allowDelete: PropTypes.bool,
    queryName: PropTypes.string,
    showActions: PropTypes.bool,
    cache: PropTypes.bool,
    onClose: PropTypes.func,
    transform: PropTypes.func,
    onSubmit: PropTypes.func,
    onData: PropTypes.func,
    onCopy: PropTypes.func
};
