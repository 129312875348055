import React, { useContext } from 'react';
import { instantiateBuild, UserContext } from 'client-shared/utility';
import { FilterFormFromJson } from 'client-shared/components/filterbar';

function build(props) {
    const {
        gql,
        format,
        get,
        object,
        number,
        date,
        user,
        getRangeStartEnd,
        flow,
        parseDate,
        getReportOptions,
        getReportFragment,
        addDays,
        createFilters,
        uniqBy
    } = props;
    const validationSchema = object({
        _start: date().required('Required'),
        _periods: number().moreThan(0, 'Must be more than 0 days').lessThan(100, 'Must be less than 100 days')
    });

    const dataQuery = (cxReportFragment) => gql`
        query ($filters: [FilterInput], $methodsByItem: [methodsByItemInput]) {
            cxCaches(filters: $filters, methodsByItem: $methodsByItem) {
                ReportIntervals {
                    Report {
                        ${cxReportFragment}
                    }
                    Range {
                        Start
                        End
                    }
                    __typename
                }
            }
        }
    `;

    return {
        title: 'Total Metrics Graph',
        dataQuery: (values) =>
            dataQuery(
                getReportFragment(
                    uniqBy(
                        (value) => value,
                        [...values._reportIntervalPropertiesLine, ...values._reportIntervalPropertiesBar]
                    )
                )
            ),
        // dataEventQueries: [fragments.JobEventQuery],
        sourceMutationObjectTypes: [],
        validationSchema: validationSchema,
        divisionFilter: false,
        enableShowAll: false,
        namedFilters: true,
        initialValues: {
            // range__child__taskdays: [],
            // range__child__allocations: []
            _absoluteStart: true
        },
        onFilter: (values) => {
            values = {
                ...values,
                contacts_id__child__resources__all__true: values.contacts_id__all_true
            };
            return createFilters(values);
        },
        onMethodsByItem: async (values, client) => {
            const rangeStartEnd = getRangeStartEnd(values._start, values._periods, {
                interval: values._interval,
                absoluteStart: values._absoluteStart
            });
            const methodsByItem = [
                {
                    methods: [
                        {
                            name: 'MakeReportIntervals',
                            args: {
                                interval: values._interval,
                                start: rangeStartEnd.start,
                                end: flow(addDays(1), format('yyyy-MM-dd'))(parseDate(rangeStartEnd.end)),
                                shiftId: get('_shift.Id', values)
                            }
                        }
                    ]
                }
            ];
            return { methodsByItem: methodsByItem };
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Interval',
                name: '_interval',
                component: 'AutoComplete',
                options: ['Days', 'Weeks', 'Months'],
                className: 'shortSelectField',
                initialValue: 'Days',
                optionLabelProperty: 'Name'
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'number',
                label: 'Periods',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'text',
                label: 'Forecast Name',
                name: 'forecasts_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxForecasts(filters: [{ name: "String", values: ["Name", $searchString] }]) {
                            Id
                            DisplayName
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Job',
                name: 'job_id',
                component: 'AutoComplete',
                className: 'selectField',
                queryVariables: (values) => {
                    const rangeStartEnd = getRangeStartEnd(values._start, values._periods, { interval: 'Days' });
                    return {
                        start: rangeStartEnd.start,
                        end: rangeStartEnd.end
                    };
                },
                optionsQuery: gql`
                    query ($searchString: String, $start: String, $end: String) {
                        cxJobs(
                            filters: [
                                { name: "String", values: ["DisplayName", $searchString] }
                                { name: "range", values: [$start, $end] }
                            ]
                        ) {
                            Id
                            DisplayName
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                optionLabelProperty: 'DisplayName'
            },
            {
                type: 'text',
                label: 'Status',
                name: 'job_jobStatus_id',
                component: 'LookupValueEditor',
                className: 'selectField',
                multiple: true,
                initialValue: [],
                objectType: 'JobStatus'
            },
            {
                type: 'text',
                label: 'Shift',
                name: '_shift',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: '_DisplayName',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxShifts(
                            objectType: "Shift"
                            filters: [{ name: "String", values: ["DisplayName", $searchString] }]
                        ) {
                            Id
                            _DisplayName: DisplayName
                            _Days: Days {
                                Day
                            }
                        }
                    }
                `
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'text',
                label: 'Line Metrics',
                name: '_reportIntervalPropertiesLine',
                component: 'AutoComplete',
                options: getReportOptions(user, false, ['time']),
                className: 'longTextField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id',
                multiple: true,
                sort: false,
                initialValue: ['LaborCapacity']
            },
            {
                type: 'text',
                label: 'Bar Metrics',
                name: '_reportIntervalPropertiesBar',
                component: 'AutoComplete',
                options: getReportOptions(user, false, ['time']),
                className: 'longTextField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id',
                multiple: true,
                sort: false,
                initialValue: ['ScheduledLaborDurationPlusUnfilledLaborDuration']
            },
            {
                type: 'text',
                label: 'Contacts',
                name: 'contacts_id__all_true',
                component: 'AutoComplete',
                className: 'selectField',
                optionsQuery: gql`
                    query ($searchString: String) {
                        cxCaches(
                            objectType: "cache"
                            filters: [{ name: "String", values: ["Contacts.DisplayName", $searchString] }]
                        ) {
                            Contacts {
                                Id
                                DisplayName
                            }
                        }
                    }
                `,
                multiple: true,
                initialValue: [],
                getOptions: (options) => {
                    return get('0.Contacts', options);
                },
                optionLabelProperty: 'DisplayName'
            }
        ]
    };
}

export const FilterTotalMetricsGraph = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
