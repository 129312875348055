import { gql } from 'graphql-tag';

export const JobEventSubscription = gql`
    subscription {
        jobEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const JobActivityEventSubscription = gql`
    subscription {
        jobActivityEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const TextMessageEventSubscription = gql`
    subscription {
        textMessageEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const AllocationEventSubscription = gql`
    subscription {
        allocationEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const TaskDayEventSubscription = gql`
    subscription {
        taskDayEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const ResourceEventSubscription = gql`
    subscription {
        resourceEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const TimeEntryEventSubscription = gql`
    subscription {
        timeEntryEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const ForecastEventSubscription = gql`
    subscription {
        forecastEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const ResourceSubtypeForecastEventSubscription = gql`
    subscription {
        resourceSubtypeForecastEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const PartyEventSubscription = gql`
    subscription {
        partyEvent {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
        }
    }
`;

export const JobEventQuery = gql`
    query {
        jobEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const JobActivityEventQuery = gql`
    query {
        jobActivityEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const TextMessageEventQuery = gql`
    query {
        textMessageEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const AllocationEventQuery = gql`
    query {
        allocationEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const TaskDayEventQuery = gql`
    query {
        taskDayEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const ResourceEventQuery = gql`
    query {
        resourceEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const TimeEntryEventQuery = gql`
    query {
        timeEntryEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const ForecastEventQuery = gql`
    query {
        forecastEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const ResourceSubtypeForecastEventQuery = gql`
    query {
        resourceSubtypeForecastEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const PartyEventQuery = gql`
    query {
        partyEvent @client {
            EventId
            Dependencies {
                Id
                SourceMutationObjectType
            }
            ObjectType
            differentiator
        }
    }
`;

export const CustomPropertyFragment = `
    Id
    _Name: Name
    _AttributeType: AttributeType
    CustomLookups {
        Id
        _Name: Name
        _Description: Description
        _DisplayName: DisplayName
    }
`;

export const TagFragment = gql`
    fragment TagFragment on CxAttribute {
        Id
        Value
        ArrayValue
        DisplayValue
        CustomProperty {
            Id
            _Name: Name
            _AttributeType: AttributeType
            CustomLookups {
                Id
                _Name: Name
                _Description: Description
                _DisplayName: DisplayName
            }
        }
    }
`;

export const CxTimeEntryFragment = gql`
    fragment CxTimeEntryFragment on CxTimeEntry {
        Id
        Party {
            Id
            DisplayName
        }
        _Job: Job {
            Id
            DisplayName
        }
        JobActivity {
            Id
            DisplayName
        }
        CurrentLifespan {
            Start
            End
        }
        JobCosts {
            Id
            Description
            DisplayName
        }
        EntryType {
            Id
            DisplayName
        }
        Minutes
        Punch
        PunchStart
        PunchEnd
        Description
        IsVoid
        Approvals {
            Approver {
                Id
            }
            AsOf
        }
        Guid
        StartLocation {
            Id
            Lat
            Lng
            Description
        }
        EndLocation {
            Id
            Lat
            Lng
            Description
        }
        Attachments {
            Id
            Key
        }
        Auxiliaries {
            Id
            DisplayName
        }
        LaborClass {
            Id
            DisplayName
        }
        SubtypeQuantities {
            Supplier {
                Id
                DisplayName
            }
            _ResourceType: ResourceType {
                Unit {
                    Name
                }
            }
            ResourceSubtype {
                Id
                _Name: Name
            }
            Amount
            Description
        }
    }
`;

// the entrytype is not needed for punches, and the write to cache fails in offline mode if it is in the fragment
// but not the data. there is no good default for the entrytype, so it is not included in the data.
export const CxTimeEntryPunchFragment = gql`
    fragment CxTimeEntryPunchFragment on CxTimeEntry {
        Id
        Party {
            Id
            DisplayName
        }
        _Job: Job {
            Id
            DisplayName
        }
        JobActivity {
            Id
            DisplayName
        }
        CurrentLifespan {
            Start
            End
        }
        JobCosts {
            Id
            Description
            DisplayName
        }
        Minutes
        Punch
        PunchStart
        PunchEnd
        Description
        IsVoid
        Approvals {
            Approver {
                Id
            }
            AsOf
        }
        Guid
        StartLocation {
            Id
            Lat
            Lng
            Description
        }
        EndLocation {
            Id
            Lat
            Lng
            Description
        }
        Attachments {
            Id
            Key
        }
        Auxiliaries {
            Id
            DisplayName
        }
    }
`;

export const CxReportFragment = gql`
    fragment CxReportFragment on CxReport {
        WorkingDays
        WorkingDuration
        LaborCount
        ScheduledLaborDuration
        ScheduledLaborCost {
            Quantity
        }
        ScheduledEquipmentDuration
        ScheduledEquipmentCost {
            Quantity
        }
        PlannedLaborDuration
        PlannedLaborCost {
            Quantity
        }
        ActualLaborDuration
        ActualLaborCost {
            Quantity
        }
        ForecastedLaborDuration
        ForecastedLaborCost {
            Quantity
        }
        __typename
    }
`;

export const CxTaskDayFragment = gql`
    fragment CxTaskDayFragment on CxTaskDay {
        Allocation {
            Id
            ActionType
            DisplayName
            Description
            ActionStatus {
                Id
                DisplayName
            }
            ActualCrew
            Crew {
                Id
                DisplayName
                ForeColor
                BackColor
            }
            Job {
                Id
                DisplayName
                ForeColor
                BackColor
                AccountCode
                Manager {
                    Id
                    DisplayName
                    PhoneNumbers {
                        Name
                        PhoneNumber
                    }
                }
                Contacts {
                    Id
                    DisplayName
                    PhoneNumbers {
                        Name
                        PhoneNumber
                    }
                }
                Address {
                    Street
                    StreetDetails
                    City
                    State {
                        Name
                    }
                    ZipCode
                    Geocode {
                        Lat
                        Lng
                    }
                }
                Description
                NoteHistory {
                    AsOf
                    Party {
                        DisplayName
                    }
                    Note
                }
            }
            JobActivity {
                Id
                DisplayName
                Description
            }
            Tags {
                Name
                Value
            }
        }
        Id
        ActionType
        Amount
        ObjectType
        DisplayName
        ForeColor
        BackColor
        ActionStatus {
            BackColor
        }
        SMSNumbers {
            Name
            PhoneNumber
        }
        Description
        CurrentLifespan {
            Start
            End
        }
        ConflictIds
        ResourceType {
            Family
            DisplayName
            Unit {
                Name
            }
        }
        Principals {
            Id
            DisplayName
            ForeColor
            BackColor
        }
        Tags {
            Name
            Value
        }
        Auxiliaries {
            Id
            DisplayName
            ObjectType
            ResourceType {
                Family
                DisplayName
                Unit {
                    Name
                }
            }
        }
        __typename
    }
`;

export const CxJobFragment = gql`
    fragment CxJobFragment on CxJob {
        Id
        ForeColor
        BackColor
        DisplayName
        AccountCode
        Description
        ObjectType
        Divisions {
            Name
        }
        CurrentLifespan {
            Start
            End
        }
        JobActivities {
            Id
            DisplayName
            Completed
            SortOrder
            ObjectType
            ForeColor
            BackColor
            Description
            CurrentLifespan {
                Start
                End
            }
        }
        TotalCost {
            Quantity
        }
        NoteHistory {
            AsOf
            Party {
                DisplayName
            }
            Note
        }
        Manager {
            Id
            Name
            PhoneNumbers {
                Name
                PhoneNumber
            }
        }
        Contacts {
            Id
            Name
            PhoneNumbers {
                Name
                PhoneNumber
            }
        }
        Address {
            Street
            StreetDetails
            City
            State {
                Name
            }
            ZipCode
            Geocode {
                Lat
                Lng
            }
        }
    }
`;

export const CxAllocationFragment = gql`
    fragment CxAllocationFragment on CxAllocation {
        Id
        DisplayName
        Description
        ActionType
        Resources {
            Id
        }
        ActionStatus {
            Id
            DisplayName
        }
        Crew {
            Id
            DisplayName
            ForeColor
            BackColor
        }
        Job {
            Id
            DisplayName
            ForeColor
            BackColor
            AccountCode
            Types {
                DisplayName
            }
            Manager {
                Id
                DisplayName
                PhoneNumbers {
                    Name
                    PhoneNumber
                }
            }
            Contacts {
                Id
                DisplayName
                PhoneNumbers {
                    Name
                    PhoneNumber
                }
            }
            Address {
                Street
                StreetDetails
                City
                State {
                    Name
                }
                ZipCode
                Geocode {
                    Lat
                    Lng
                }
            }
            Description
            NoteHistory {
                AsOf
                Party {
                    DisplayName
                }
                Note
            }
        }
        JobActivity {
            Id
            DisplayName
            Description
        }
        Tags {
            Name
            Value
        }
        TaskDays {
            ObjectType
            ParentId
            Id
            ActionType
            DisplayName
            ShiftSupervisor
            ForeColor
            BackColor
            SMSNumbers {
                Name
                PhoneNumber
            }
            CurrentLifespan {
                Start
                End
            }
            Principals {
                Id
                DisplayName
                ForeColor
                BackColor
            }
            Description
            ActionStatus {
                Name
                BackColor
                StatusType
            }
            ConflictIds
            Resources {
                Id
            }
            ResourceType {
                Family
                DisplayName
                Unit {
                    Name
                }
            }
            Auxiliaries {
                Id
                DisplayName
                ObjectType
                ResourceType {
                    Id
                    Family
                    Unit {
                        Name
                    }
                }
            }
            Amount
            Tags {
                Name
                Value
            }
        }
    }
`;

export const CxAllocationScheduleFragment = gql`
    fragment CxAllocationScheduleFragment on CxAllocation {
        ObjectType
        Id
        Name
        DisplayName
        AllocationDays {
            Start
            ActionType
            PlannedAmount
            FilledAmount
        }
        Crew {
            Id
        }
        ActionType
        ForeColor
        BackColor
        Shift {
            Id
        }
        ActionStatus {
            Id
            BackColor
            Default
        }
        Description
        Job {
            Id
            DisplayName
            ForeColor
            BackColor
            Description
        }
        JobActivity {
            Id
            DisplayName
            ForeColor
            BackColor
        }
        CurrentLifespan {
            Start
            End
        }
        Tags {
            Id
            Value
            CustomProperty {
                Id
            }
        }
        Resources {
            Id
            ResourceType {
                Id
            }
        }
        ResourceQuantitySpans {
            ResourceType {
                Id
            }
        }
        Forecast {
            Id
        }
        JobCosts {
            Id
        }
        TaskDays {
            ShiftSupervisor
            ObjectType
            ActionType
            ParentId
            Id
            ConflictIds
            DisplayName
            ForeColor
            BackColor
            IsCrewMember
            Principals {
                Id
            }
            Resources {
                Id
            }
            ResourceType {
                Id
                Family
                Unit {
                    Name
                }
            }
            ActionStatus {
                BackColor
            }
            Job {
                Id
            }
            JobActivity {
                Id
            }
            CurrentLifespan {
                Start
                End
            }
            ResourceType {
                Id
            }
            Supplier {
                Id
            }
            ResourceSubtype {
                Id
            }
            Amount
            Description
            Auxiliaries {
                Id
                DisplayName
                ObjectType
                ResourceType {
                    Id
                    Family
                    Unit {
                        Name
                    }
                }
            }
        }
    }
`;

export const _CxTextMessageFragment = `
        Id
        Outbound
        Received
        IsReceived
        Sent
        From {
            NumericPhoneNumber
            Name
        }
        Readers {
            Reader {
                Id
        
             }
        }
        Sender {
            Id
            DisplayName
        }
        To {
            NumericPhoneNumber
        }
        Text
`;

export const CxTextMessageFragment = gql`
    fragment CxTextMessageFragment on CxTextMessage {
       ${_CxTextMessageFragment}
    }
`;

export const fragments = {
    JobEventSubscription: JobEventSubscription,
    JobActivityEventSubscription: JobActivityEventSubscription,
    AllocationEventSubscription: AllocationEventSubscription,
    TaskDayEventSubscription: TaskDayEventSubscription,
    ResourceEventSubscription: ResourceEventSubscription,
    TimeEntryEventSubscription: TimeEntryEventSubscription,
    ForecastEventSubscription: ForecastEventSubscription,
    ResourceSubtypeForecastEventSubscription: ResourceSubtypeForecastEventSubscription,
    PartyEventSubscription: PartyEventSubscription,
    JobEventQuery: JobEventQuery,
    JobActivityEventQuery: JobActivityEventQuery,
    AllocationEventQuery: AllocationEventQuery,
    TaskDayEventQuery: TaskDayEventQuery,
    ResourceEventQuery: ResourceEventQuery,
    TimeEntryEventQuery: TimeEntryEventQuery,
    ForecastEventQuery: ForecastEventQuery,
    ResourceSubtypeForecastEventQuery: ResourceSubtypeForecastEventQuery,
    PartyEventQuery: PartyEventQuery,
    CxTimeEntryFragment: CxTimeEntryFragment,
    CxTimeEntryPunchFragment: CxTimeEntryPunchFragment,
    CxJobFragment: CxJobFragment,
    CxAllocationFragment: CxAllocationFragment,
    CxReportFragment: CxReportFragment,
    CxTaskDayFragment: CxTaskDayFragment,
    CxAllocationScheduleFragment: CxAllocationScheduleFragment,
    CxTextMessageFragment,
    TextMessageEventQuery,
    TextMessageEventSubscription
};
