import React, { useContext, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { find, get, pipe, set, size } from 'lodash/fp';
import { UserContext } from 'client-shared/utility';
import { buildQuickMenu } from 'global-shared/menus';
import Loading from './loading';
import { Link } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { JobCalendar } from 'client-shared/pages/jobcalendar';
import Alert from '@mui/material/Alert';
import { gql, useApolloClient } from '@apollo/client';
import TopNavigationBar from 'client-shared/components/TopNavbar';
const CxActionStatus = require('cb-schema/jsonschema/CxActionStatus');
//import CxActionStatus from 'cb-schema/jsonschema/CxActionStatus';

/**
 * You have to render this as a function otherwise the grid doesn't pick it us a
 * valid thing to render
 * @param hideDefault
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const RenderAppGreeting = ({ appMsg }) => {
    const __html = get('text', appMsg);
    const severity = get('severity', appMsg) || 'info';
    return (
        <Alert severity={severity}>
            <div dangerouslySetInnerHTML={{ __html }}></div>
        </Alert>
    );
};

export const PartyNumberRenderer = ({ user, setUserContext }) => {
    const partyGroups = get('congistics.user.Party.PartyGroups', user);
    const currentPartyGroup = get('congistics.user.currentPartyGroup', user);
    const [partyGroupId] = useState(get('Id', currentPartyGroup));

    //const [u, setUserContext] = useContext(UserContext);
    const handleChange = (event) => {
        const newGroup = find({ Id: event.target.value }, partyGroups);
        const newUser = pipe(
            set('congistics.user.currentPartyGroup', newGroup),
            set('congistics.user.smsNumber', newGroup.NumericGroupNumber)
        )(user);
        setUserContext(newUser);
    };
    if (partyGroups.length === 1)
        return <Typography variant="subtitle2">sms number: {get('congistics.user.smsNumber', user)}</Typography>;
    if (partyGroups.length > 1)
        return (
            <Box sx={{ minWidth: 120, marginTop: '10px' }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">SMS Group</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={partyGroupId}
                        label="SMS"
                        onChange={handleChange}
                    >
                        {partyGroups.map((each) => (
                            <MenuItem value={each.Id} key={each.Id}>
                                {each.DisplayName}: {each.NumericGroupNumber}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        );
    return <Typography variant="subtitle2">No SMS</Typography>;
};
export const OverView = (props) => {
    const [user, setUserContext] = useContext(UserContext);
    const client = useApolloClient();
    const [appMsg, setAppMsg] = useState();
    const query = gql`
        query getGreeting {
            kkvFetch(k: "appMessage") {
                k
                kk
                data
            }
        }
    `;
    useEffect(() => {
        async function fetchData() {
            const res = await client.query({
                fetchPolicy: 'network-only',
                query
            });
            if (get('data.kkvFetch.0.data', res)) setAppMsg(get('data.kkvFetch.0.data', res));
        }
        fetchData().catch(console.log);
    });

    const quickMenu = buildQuickMenu(get('customerInfo.ux.menus', user), get('congistics.user.Features', user));

    const quickSmart = get('congistics.user.quickMenu', user);
    const menu = size(quickMenu) > 0 ? quickMenu : quickSmart;

    const ResponsiveGridLayout = WidthProvider(Responsive);

    const layout = {
        lg: [
            { i: 'quickMenu', x: 3, y: 0, w: 9, h: 0.5, margin: [100, 10], static: true },
            { i: 'greeting', x: 3, y: 0, w: 9, h: 0.75, isDraggable: false },
            { i: 'hero', x: 3, y: 1, w: 9, h: 5.8, isDraggable: false },
            { i: 'announcements', x: 0, y: 1, w: 3, h: 2, isDraggable: false },
            { i: 'appInfo', x: 0, y: 0, w: 3, h: 1.25, isDraggable: false }
        ],
        md: [
            { i: 'quickMenu', x: 3, y: 0, w: 7, h: 0.5, static: true },
            { i: 'greeting', x: 3, y: 0, w: 7, h: 1.25, isDraggable: false },
            { i: 'hero', x: 3, y: 2, w: 7, h: 5.8, isDraggable: false },
            { i: 'announcements', x: 0, y: 1, w: 3, h: 2, isDraggable: false },
            { i: 'appInfo', x: 0, y: 0, w: 3, h: 1.25, isDraggable: false }
        ],
        sm: [
            { i: 'quickMenu', x: 0, y: 0, w: 8, h: 0.5, static: true },
            {
                i: 'greeting',
                x: 0,
                y: 0,
                w: 8,
                h: 1,
                static: true
            },
            { i: 'hero', x: 0, y: 0, w: 8, h: 3.5, isDraggable: false },
            /*
            { i: 'announcements', x:0, y:1000, w: 4, h: 2.75, isDraggable: false },

*/
            { i: 'appInfo', x: 9, y: 1, w: 8, h: 2.75, isDraggable: false }
        ],
        xs: [
            { i: 'quickMenu', x: 0, y: 0, w: 8, h: 0.5, static: true },
            { i: 'greeting', x: 0, y: 0, w: 8, h: 1, isDraggable: false },
            { i: 'hero', x: 0, y: 0, w: 8, h: 4, isDraggable: false },
            /*
            { i: 'announcements', x:0, y:1000, w: 4, h: 2.75, isDraggable: false },
*/
            { i: 'appInfo', x: 0, y: 1, w: 8, h: 2, isDraggable: false }
        ],

        xxs: [
            { i: 'quickMenu', x: 0, y: 0, w: 8, h: 0.5, static: true },
            { i: 'greeting', x: 0, y: 0, w: 8, h: 1.5, isDraggable: false },
            { i: 'hero', x: 0, y: 0, w: 8, h: 4, isDraggable: false },
            /*
                        { i: 'announcements', x:0, y:1000, w: 4, h: 2.75, isDraggable: false },
            */
            { i: 'appInfo', x: 0, y: 1, w: 8, h: 2, isDraggable: false }
        ]
    };
    if (!user) return <Loading />;

    return (
        <>
            <ResponsiveGridLayout
                className="layout"
                layouts={layout}
                autoSize={true}
                compactType="vertical"
                breakpoints={{ lg: 1200, md: 880, sm: 601, xs: 400, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 8, xs: 8, xxs: 8 }}
            >
                {size(menu) >= 1 && (
                    <div key="quickMenu">
                        <TopNavigationBar smartMenu={menu} />
                    </div>
                )}

                <Box
                    component="div"
                    key="announcements"
                    display={{ xs: 'none', sm: 'none', md: 'block' }}
                    style={{ paddingRight: '20px' }}
                >
                    <Typography variant="subtitle2">Support: feedback@congistics.com or 203 529-1212</Typography>
                    <Typography variant="subtitle1">What’s New August 1, 2024</Typography>
                    <ul className="ul">
                        <li className="li">
                            <Link to="view/totalmetricsgraph">Graphing for metrics</Link> has been added. Daily, weekly,
                            and monthly intervals can be chosen, bar and line graphs can be combined, and a wide variety
                            of metrics are available. A good choice to get started is to try Labor Capacity as a line
                            vs. Schd Lbr + Unfilled Lbr as a bar.
                        </li>
                        <li className="li">
                            Recurring allocations have been introduced. By checking Repeat in the Allocation Editor, you
                            can now set an allocation to recur by week or by month, for as long as you like.
                        </li>
                        <li className="li">
                            Metrics now respect the filters that are set on the screen from which they are called. For
                            example, if a particular division is chosen in the Divisions filter on the Job Schedule, the
                            metrics shown will reflect only jobs and resources that belong to that division.
                        </li>
                        <li className="li">
                            Estimating labor hours by job activity as well as by job is now supported. These estimates
                            are reflected in the Forecasted metrics by both job activity and job.
                        </li>
                        <li className="li">
                            Custom text messaging options have also been enhanced, and it is now possible to add links
                            to job attachments directly to text messages. Text messages, which were formerly restricted
                            to the US and Canada, can now be sent to most countries in the Western Hemisphere.
                        </li>
                        <li className="li">
                            A MultiLookup custom property type has been added, allowing a choice of more than one option
                            from a defined picklist.
                        </li>
                        <li className="li">
                            The <Link to="reports/dailyworkorder-report">Daily Work Order</Link> reports have been
                            consolidated into one and enhanced.
                        </li>
                        <li className="li">
                            The administrative <Link to="admin/changes">Changes</Link> grid now includes variances, so
                            for updates you can view the old and new values of any properties that have changed.
                        </li>
                    </ul>
                    <div key="appInfo">
                        <Typography variant="subtitle2">User: {get('userId', user)}</Typography>
                        <Typography variant="subtitle2">Environment: {get('customerInfo.CBD_ENV', user)}</Typography>
                        <Typography variant="subtitle2">Node build: {get('customerInfo.BUILD_HASH', user)}</Typography>
                        <Typography variant="subtitle2">
                            Application version: {get('congistics.configValues.appversion', user)}
                        </Typography>
                        <Typography variant="subtitle2">
                            Database version: {get('congistics.configValues.dbversion', user)}
                        </Typography>
                        <Typography variant="subtitle2">Schema version: {get('$version', CxActionStatus)}</Typography>
                        <PartyNumberRenderer user={user} setUserContext={setUserContext} />
                        <p></p>
                    </div>
                </Box>

                {appMsg && (
                    <div key="greeting">
                        <RenderAppGreeting appMsg={appMsg} />
                    </div>
                )}

                {size(menu) === 0 && !appMsg && (
                    <div key="greeting">
                        <Alert severity="info">
                            Welcome to ControlBoard&reg;! To start scheduling, open the menu at the left to the job,
                            labor/equipment, or crew schedule screen. Drag and drop resources to jobs or jobs to
                            resources to create a schedule. Demonstration videos are available on the{' '}
                            <a
                                href="https://support.controlboard.app/topic/6/ways-to-schedule"
                                target="_blank"
                                rel="noreferrer"
                            >
                                support site.
                            </a>
                        </Alert>
                    </div>
                )}

                <div key="hero">
                    <JobCalendar
                        title="Monthly Job Calendar"
                        widgetConfig={{
                            header: ['date', 'prev', 'today', 'next'],
                            //style: "width:100%; height:100%;"
                            //min-height": 'calc(~"100vh - 1000px");',
                            height: '900px'
                        }}
                    />
                </div>
            </ResponsiveGridLayout>
        </>
    );
};
