import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { fieldToTextField } from 'formik-mui';
import { number } from 'yup';

export const durationValidationSchema = number()
    .moreThan(0, 'Required')
    .lessThan(1441, 'Must be less than or equal 24 hours');

/**
 * duration is total minutes. get the hours part.
 * @param duration
 * @returns {number}
 */
const getHoursFromDuration = (duration) => {
    return Math.floor(duration / 60);
};

/**
 * duration is total minutes. get the minutes part.
 * @param duration
 * @returns {number}
 */
const getMinutesFromDuration = (duration) => {
    return duration % 60;
};

/**
 * Duration editor to edit the hours and minutes of a duration as separate fields.
 * @param form
 * @param field
 * @param onChange -  optional change handler
 * @param other
 * @returns {*}
 * @constructor
 */
export const DurationEditor = ({ form, field, onChange, ...other }) => {
    const [hours, setHours] = useState(getHoursFromDuration(field.value));
    const [minutes, setMinutes] = useState(getMinutesFromDuration(field.value));

    useEffect(() => {
        //console.log(field.value);
        setHours(getHoursFromDuration(field.value));
        setMinutes(getMinutesFromDuration(field.value));
    }, [field.value]);

    const handleChangeHours = (event) => {
        let _hours = parseInt(event.target.value);
        _hours = isNaN(_hours) ? 0 : _hours;
        // setHours(_hours);
        form.setFieldValue(field.name, _hours * 60 + minutes, false);
        // form.setFieldValue(field.name, (_hours || 0) * 60 + minutes, false);
    };

    const handleChangeMinutes = (event) => {
        let _minutes = parseInt(event.target.value);
        _minutes = isNaN(_minutes) ? 0 : _minutes;
        // setMinutes(_minutes);
        form.setFieldValue(field.name, hours * 60 + _minutes);
    };

    const { value, ...fieldToTextFieldProps } = fieldToTextField({ form, field, ...other });
    return (
        <React.Fragment>
            <TextField
                {...fieldToTextFieldProps}
                label="Hours"
                type="number"
                inputProps={{ min: 0, max: 24 }}
                value={hours}
                onChange={handleChangeHours}
            />
            <TextField
                {...fieldToTextFieldProps}
                label="Minutes"
                type="number"
                inputProps={{ min: 0, max: 59 }}
                value={minutes}
                onChange={handleChangeMinutes}
            />
        </React.Fragment>
    );
};

DurationEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func // optional change handler
};
