import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Filter } from './Filter';
import { get, merge, keyBy, values as valuesFp, filter, flow, uniqBy, sortBy, join } from 'lodash/fp';
import { gridHeaderItemStyle, gridItemStyle } from '../styles';
import { DateTime } from 'client-shared/components/serverobjects/DateTime';
import { TaskDays } from 'client-shared/components/serverobjects/TaskDays2';
import { displayTotals, mergedTaskDaysTotals, MergedTaskDaysTotals } from 'client-shared/components/totals';
import { GridDashBoard, getRangeRows } from '../dashboardhelpers';
import { ResourcesQuery } from './queries';
import { parseDate, daysInRange, query, createFilters, groupNameParse } from 'client-shared/utility';
import { isWithinInterval } from 'date-fns/fp';
import { Typography, Box } from '@mui/material';
import { pipeP } from 'global-shared/utils/utils';
import { useApolloClient } from '@apollo/client';
const map = require('lodash/fp/map').convert({ cap: false });
const gridColumnHeaderItem = gridHeaderItemStyle({ marginTop: '10px' });
const gridRowHeaderItem = gridHeaderItemStyle({ textAlign: 'left' });
const gridItem = gridItemStyle();

const groupByResourceType = {
    name: 'ResourceType.DisplayName'
};

/**
 * populate each cell of the spreadsheet.
 * @param values
 * @param allocations
 * @returns {(function(*, *, *, *): (*|undefined))|*}
 */
const addExcelCell = (values, reportIntervals) => {
    return ({ row, rowNumber, column, colNumber, colName, cell }) => {
        cell.alignment = { wrapText: true };
        const accumulator = mergedTaskDaysTotals({
            mergedTaskDays: row.intervalData.get(colName),
            totalResources: row.totalResourcesByDate ? row.totalResourcesByDate.get(colName) : undefined,
            workDays: daysInRange(colName, 1, {
                interval: values._interval,
                skipDays: values._skipDays
            })
        });
        // if date row, return the date and counts.
        if (row.headerRow) {
            cell.value = `${colName}\n${displayTotals(accumulator)}`;
            return;
        }
        if (colName === 'DisplayName') {
            cell.value = get('items.0.ResourceType.DisplayName', row);
            return;
        }

        if (values._summary) {
            cell.value = displayTotals(accumulator);
            return;
        }
        cell.value = flow(
            uniqBy('DisplayName'),
            map((item) => {
                return item.DisplayName;
            }),
            sortBy([]),
            join('\n')
        )(row.intervalData.get(colName));
    };
};

/**
 * called by the GridDashBoard to render the row header.
 * @param rangeRow - an object that contains the data for the row in the property.
 * see dashBoardhelpers.js for details.
 * @param values - user entered values from the filter bar.
 * @returns {JSX.Element}
 * @constructor
 */
const rowHeader =
    (values, reportIntervals) =>
    ({ rangeRow }) => {
        if (!rangeRow) {
            return null;
        }
        const range = rangeRow.getRange();
        const dateRow = map((date) => {
            const taskDays = rangeRow.intervalData.get(date);
            return (
                <div style={gridColumnHeaderItem} key={date}>
                    <DateTime dateTime={date} dateTimeFormat="EEEEEE, MM/dd/yyyy" />
                    {values._summary && (
                        <div>
                            <MergedTaskDaysTotals
                                mergedTaskDays={taskDays}
                                // totalResources={rangeRow.totalResources}
                            />
                        </div>
                    )}
                </div>
            );
        }, range);
        return (
            <React.Fragment>
                <div style={gridColumnHeaderItem}>Type</div>
                {dateRow}
            </React.Fragment>
        );
    };

// ColumnsHeader.propTypes = {
//     rangeRow: PropTypes.object.isRequired,
//     values: PropTypes.object.isRequired
// };

/**
 * called by the GridDashBoard to render detail rows.
 * @param rangeRow - an object that contains the data for the row in the property, intervalData.
 * see dashBoardhelpers.js for details.
 * @param values - user entered values from the filter bar.
 * @returns {JSX.Element}
 * @constructor
 */
const row =
    (values) =>
    ({ rangeRow }) => {
        const range = rangeRow.getRange();
        const row = map((date) => {
            // get the taskDays for this date.
            const taskDays = rangeRow.intervalData.get(date);
            const totalResources = rangeRow.totalResourcesByDate ? rangeRow.totalResourcesByDate.get(date) : 0;
            const workDays = daysInRange(date, 1, { interval: values._interval, skipDays: values._skipDays });
            return (
                <div style={gridItem} key={date}>
                    <Cell taskDays={taskDays} values={values} totalResources={totalResources} workDays={workDays} />
                </div>
            );
        }, range);
        return (
            <React.Fragment>
                <div style={gridRowHeaderItem}>{get('name', groupNameParse(rangeRow.id))}</div>
                {row}
            </React.Fragment>
        );
    };
/**
 *
 * called by Row to render a cell for a day, week, month, or other period.
 * @param taskDays - all taskDays for the row and period.
 * @param values - user entered values from the filter bar.
 * @param totalResources - totalResource in the interval for this date.
 * @param workDays - number of days in the interval for this date.
 * @returns {JSX.Element}
 * @constructor
 */
const Cell = ({ taskDays, values, totalResources, workDays }) => {
    return (
        <div style={gridItem}>
            {!values._summary && <TaskDays taskDays={taskDays} />}
            {values._summary && (
                <MergedTaskDaysTotals
                    mergedTaskDays={taskDays}
                    totalResources={totalResources}
                    workDays={workDays}
                    displayKeys={[
                        'Generic',
                        'Subcontractor',
                        'Filled',
                        'Total',
                        'Scheduled',
                        'Capacity',
                        'Utilization'
                    ]}
                />
            )}
        </div>
    );
};

Cell.propTypes = {
    taskDays: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    totalResources: PropTypes.number.isRequired,
    workDays: PropTypes.number.isRequired
};

const getTotalResourcesByDate = (range, resources) => {
    const totalResourcesByDate = map((date) => {
        const resourcesForDate = filter(
            (resource) =>
                isWithinInterval(
                    {
                        start: parseDate(resource.CurrentLifespan.Start.slice(0, 10)),
                        end: parseDate(resource.CurrentLifespan.End.slice(0, 10))
                    },
                    parseDate(date)
                ),
            resources
        );
        return [date, resourcesForDate.length];
    }, range);
    return new Map(totalResourcesByDate);
};

const getRows = async (client, values, rangeRows) => {
    // note, all resources for the family are returned and not filtered by type.
    // the types are matched by taskDay resource type below.
    const variables = {
        resourcetype_family: values.ResourceType_Family__child__Taskdays,
        _start: values._start,
        _interval: values._interval,
        _periods: values._periods,
        divisions_id: values.divisions_id
    };
    const filters = createFilters(variables);

    const data = await query(client, ResourcesQuery, filters);

    const resources = get('data.cxResources', data);
    const range = rangeRows[0].getRange();
    const resourceRangeRows1 = getRangeRows(
        {
            groupByCriteria: groupByResourceType,
            datePropertyName: undefined,
            start: values._start,
            periods: values._periods,
            interval: values._interval
        },
        resources
    );
    const resourceRangeRows = map((resourceRangeRow1) => {
        return {
            ...resourceRangeRow1,
            ...{ totalResourcesByDate: getTotalResourcesByDate(range, resourceRangeRow1.items) }
        };
    }, resourceRangeRows1);
    let merged = merge(keyBy('id', resourceRangeRows), keyBy('id', rangeRows));
    merged = valuesFp(merged);
    return merged;
};

const Grid = ({ values, data }) => {
    const client = useApolloClient();
    const [rangeRows, setRangeRows] = useState([]);
    /*eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        pipeP(
            getRangeRows({
                groupByCriteria: groupByResourceType,
                datePropertyName: 'CurrentLifespan.Start',
                start: values._start,
                periods: values._periods,
                interval: values._interval,
                skipDays: values._skipDays,
                stratify: values._stratify
            }),
            (rangeRows) => getRows(client, values, rangeRows),
            setRangeRows
        )(data);
    }, [values, data]);
    return (
        <GridDashBoard
            values={values}
            rangeRows={rangeRows}
            periods={values._periods}
            interval={values._interval}
            skipDays={values._skipDays}
            stratify={values._stratify}
            orientation={values._orientation}
            rowHeader={rowHeader}
            row={row(values)}
            addExcelCell={addExcelCell}
        />
    );
};

Grid.propTypes = {
    values: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired
};

export const ResourceUtilizationGrid = () => {
    return (
        <Filter>
            {({ values, data }) => {
                return (
                    <React.Fragment>
                        <Grid values={values} data={data} />
                        <Typography variant="caption" display="block" gutterBottom className="no-print">
                            <Box sx={{ fontStyle: 'italic', m: 1 }}>
                                Scheduled=(allocated + planned resources), Capacity=(total resources * days in period) +
                                subcontractors, Utilization=scheduled/capacity
                            </Box>
                        </Typography>
                    </React.Fragment>
                );
            }}
        </Filter>
    );
};
