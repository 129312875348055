import { gql } from 'graphql-tag';

export const FormQuery = gql`
    query ($objectType: String = "Holiday", $filters: [FilterInput]) {
        cxHolidays(objectType: $objectType, filters: $filters) {
            Id
            Holiday
            Name
            Description
        }
    }
`;
