import { parseDate } from './dateutilities';
import { forEach, get, map, reduce } from 'lodash/fp';
import { gql } from '@apollo/client';
import { format } from 'date-fns/fp';

/**
 *
 * @param range - array of dates to be scheduled
 * @param jobs - collection of jobs and requirements.
 * @param resources - collection of resources, constraints, and capacities.
 * @returns {[undefined,{},{},{}]}
 */
export const createSchedule = ({ range, jobs, resources, maxResourcesAssignedToJob = 1 }) => {
    const scheduledDays = {};
    const totalHoursByResource = {};
    const totalCountByJobByDay = {};
    const totalHoursByResourceByDay = {};
    range.forEach((day) => {
        scheduledDays[day] = [];
    });
    for (const job in jobs) {
        totalCountByJobByDay[job] = {};
        for (const day in scheduledDays) {
            totalCountByJobByDay[job][day] = jobs[job].unavailableDays[day] ? jobs[job].unavailableDays[day] : 0;
        }
    }
    for (const resource in resources) {
        totalHoursByResourceByDay[resource] = {};
        for (const day in scheduledDays) {
            totalHoursByResourceByDay[resource][day] = 0;
        }
    }
    // Assign resources to jobs based on resource type, total hours, and weekly capacity
    for (const day in scheduledDays) {
        for (const job in jobs) {
            for (const resource in resources) {
                const resourceType = jobs[job].type;
                if (
                    !(
                        !resources[resource].unavailableDays.includes(day) &&
                        resources[resource].type === resourceType &&
                        resources[resource].capacity > 0 &&
                        totalHoursByResourceByDay[resource][day] < 8
                    )
                ) {
                    continue;
                }
                if (totalCountByJobByDay[job][day] >= maxResourcesAssignedToJob) {
                    break;
                }

                if (resource && resources[resource].capacity > 0 && jobs[job].requiredCapacity > 0) {
                    const hoursToWork = Math.min(
                        8,
                        jobs[job].requiredCapacity,
                        resources[resource].capacity,
                        8 - totalHoursByResourceByDay[resource][day]
                    ); // Maximum 8 hours per day
                    scheduledDays[day].push({ job, resource, hours: hoursToWork });
                    resources[resource].capacity = resources[resource].capacity - hoursToWork;
                    jobs[job].requiredCapacity -= hoursToWork;
                    totalHoursByResource[resource] = (totalHoursByResource[resource] || 0) + hoursToWork;
                    totalHoursByResourceByDay[resource][day] += hoursToWork;
                    totalCountByJobByDay[job][day]++;
                }
            }
        }
    }

    // Calculate total hours worked by job
    const totalHoursByJob = {};
    for (const day in scheduledDays) {
        for (const { job, hours } of scheduledDays[day]) {
            totalHoursByJob[job] = (totalHoursByJob[job] || 0) + hours;
        }
    }
    // Print the results
    return [scheduledDays, totalHoursByJob, totalHoursByResource, totalHoursByResourceByDay];
};

// Initialize the schedule
// const range = [
//     '2023-12-25',
//     '2023-12-26',
//     '2023-12-27',
//     '2023-12-28',
//     '2023-12-29',
//     '2023-12-30'
// ];

// const resources = {
//     1: { type: 'grading', capacity: 24, unavailableDays: ['2023-12-29'] },
//     2: {
//         type: 'any',
//         capacity: 48,
//         unavailableDays: []
//     },
//     3: {
//         type: 'any',
//         capacity: 48,
//         unavailableDays: []
//     },
//     4: {
//         type: 'any',
//         capacity: 48,
//         unavailableDays: []
//     }
// };

// Define jobs and their requirements
// const jobs = {
//     joba: { type: 'any', requiredCapacity: 4, unavailableDays: [] },
//     jobb: { type: 'any', requiredCapacity: 16, unavailableDays: {'2023-12-25': 1} }
// };

// const [scheduledDays, totalHoursByJob, totalHoursByResource, totalHoursByResourceByDay] = createSchedule({
//     range,
//     jobs,
//     resources
// });
// console.log(scheduledDays);

export const getResourcesToSchedule = async ({ client, resourceIds, start, end, capacity = 40 }) => {
    const { data } = await client.query({
        query: gql`
            query ($resourceIds: [String], $start: String, $end: String) {
                cxResources(
                    objectType: "Resource"
                    filters: [
                        { name: "Id", values: $resourceIds }
                        { name: "range", values: [$start, $end], child: "taskdays", skipifempty: false }
                    ]
                ) {
                    Id
                    ResourceType {
                        Id
                    }
                    TaskDays {
                        CurrentLifespan {
                            Start
                            End
                        }
                    }
                }
            }
        `,
        variables: { resourceIds: resourceIds, start: start, end: end },
        fetchPolicy: 'no-cache'
    });
    const resources = {};
    forEach((resource) => {
        resources[resource.Id] = {
            type: 'any',
            capacity: capacity,
            unavailableDays: map(
                (taskDay) => format('yyyy-MM-dd', parseDate(taskDay.CurrentLifespan.Start)),
                resource.TaskDays
            )
        };
    }, data.cxResources);
    return resources;
};

export const getJobsToSchedule = async ({ client, jobIds, start, end, requiredCapacity = 40 }) => {
    const { data } = await client.query({
        query: gql`
            query ($jobIds: [String], $start: String, $end: String) {
                cxJobs(
                    objectType: "Job"
                    filters: [
                        { name: "Id", values: $jobIds }
                        { name: "range", values: [$start, $end], child: "taskdays", skipifempty: false }
                    ]
                ) {
                    Id
                    JobActivities {
                        Id
                    }
                    Report {
                        EstimatedForecastedLaborDuration
                        ScheduledLaborDuration
                    }
                    TaskDays {
                        CurrentLifespan {
                            Start
                            End
                        }
                    }
                }
            }
        `,
        variables: { jobIds: jobIds, start: start, end: end },
        fetchPolicy: 'no-cache'
    });
    const jobs = {};
    forEach((job) => {
        jobs[get('JobActivities.0.Id', job)] = {
            type: 'any',
            requiredCapacity: requiredCapacity,
            // unavailableDays: map(
            //     (taskDay) => format('yyyy-MM-dd', parseDate(taskDay.CurrentLifespan.Start)),
            //     job.TaskDays
            // )
            unavailableDays: reduce(
                (accumulator, taskDay) => {
                    const day = format('yyyy-MM-dd', parseDate(taskDay.CurrentLifespan.Start));
                    accumulator[day] = accumulator[day] ? accumulator[day] + 1 : 1;
                    return accumulator;
                },
                {},
                job.TaskDays
            )
        };
    }, data.cxJobs);
    return jobs;
};
