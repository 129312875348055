/* eslint-disable react-hooks/exhaustive-deps*/
import { useState, useEffect } from 'react';
import { isData } from './isdata';
import { readCache, writeCache, watchCache } from './readwritecache';
import { useApolloClient } from '@apollo/client';
import { filter, map, get, find } from 'lodash/fp';

/**
 * return true if the items match.
 * @returns {boolean}
 * @private
 * @param item1
 * @param item2
 */

const _comparator = (item1, item2) => {
    return item1.Id === item2.Id && item1.ObjectType === item2.ObjectType;
};

/**
 * return true if the element matches an item in the selectedList.
 * @param selectedItems
 * @returns {function(*=): *}
 * @private
 */
const _compareSelectedItems = (selectedItems) => (element) => {
    return find((selectedItems) => _comparator(selectedItems.item, element), selectedItems);
};

/**
 * return true if the element matches an item in the list.
 * @param items
 * @returns {function(*): *}
 * @private
 */
const _compareItems = (items) => (selectedItem) => {
    return find((item) => _comparator(item, selectedItem.item), items);
};

export const unSelectAll = (client, cacheName) => {
    writeCache(client, cacheName, []);
};

/**
 * combine item with selected items in the cache and return the list of items to select. some of the items will
 * already be selected, some will not as indicated by the selected flag.
 * @param client
 * @param cacheName
 * @param items
 * @param selectedItemProperties
 * @returns {*[]}
 */
export const getSelectedItems = (client, cacheName, items = [], selectedItemProperties) => {
    let selectedItems = readCache(client, cacheName, []);
    const itemsNotSelected = filter((item) => !_compareSelectedItems(selectedItems)(item), items);
    // remove all items from list of selected items.
    //selectedItems = selectedItems.filter(selectedItem => !compareItems(items)(selectedItem));
    return [
        ...selectedItems,
        // ...itemsAlreadySelected.map(item => ({
        //     selectedItemProperties: selectedItemProperties,
        //     item: item,
        //     cacheName: cacheName,
        //     selected: true
        // })),
        ...map(
            (item) => ({
                selectedItemProperties: selectedItemProperties,
                item: item,
                cacheName: cacheName,
                selected: false
            }),
            itemsNotSelected
        )
    ];
};

/**
 * return true if all of the items are found in the cache.
 * @param client
 * @param cacheName
 * @param items
 * @param compareSelectedItems
 * @returns {boolean}
 */
export const isSelected = (client, cacheName, items, compareSelectedItems = _compareSelectedItems) => {
    const selectedItems = readCache(client, cacheName, []);
    // two empty arrays should return false.
    if (items.length === 0 && selectedItems.length === 0) {
        return false;
    }
    // return false if any item is not found in selectedItems.
    return !find((item) => !compareSelectedItems(selectedItems)(item), items);
};

/**
 * user defined hook to track selected items in a reactive cache. if an item matches the cache, it is selected.
 * @param items - object to select.
 * @param cacheName - name of key in cache to store selected items.
 * @param compareSelectedItems
 * @returns {any[]}
 */
export const useSelected = (items, cacheName, compareSelectedItems = _compareSelectedItems) => {
    const client = useApolloClient();
    const [selected, setSelected] = useState(isSelected(client, cacheName, items, compareSelectedItems));

    useEffect(() => {
        const observable = watchCache(client, cacheName);
        const subscription = observable.subscribe((data) => {
            if (isData(data)) {
                //const selectedItems = JSON.parse(data.data[cacheName].data);
                const selectedItems = get(`data.${cacheName}.data`, data);
                // if an item is not in the selectedItems list, then this is not selected.
                if (!items.length || find((item) => !compareSelectedItems(selectedItems)(item), items)) {
                    setSelected(false);
                } else {
                    // all items are in the selectedItems list.
                    // do not render items where render is false.
                    // if (get('0.selectedItemProperties.render', selectedItems) === false) {
                    //     return;
                    // }
                    setSelected(true);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, []);

    /**
     * if items are not selected, add them to the list of selected items. if items are selected, remove them from the
     * list of selected items. this toggles an item between unselected and selected.
     * @param items
     * @param selectedItemProperties
     * @param compareItems
     */
    const toggleSelectedItems = (items, selectedItemProperties, compareItems = _compareItems) => {
        let selectedItems = readCache(client, cacheName, []);

        // remove items from the list of selectedItems.
        selectedItems = filter((selectedItem) => !compareItems(items)(selectedItem), selectedItems);

        // if not selected, add items to list of selected items.
        if (!selected) {
            selectedItems = [
                ...selectedItems,
                ...map(
                    (item) => ({
                        selectedItemProperties: selectedItemProperties,
                        item: item,
                        cacheName: cacheName,
                        selected: true
                    }),
                    items
                )
            ];
        }
        return writeCache(client, cacheName, selectedItems);
        //setSelected(!selected);
    };

    /**
     * set the cache to the items passed. this does not toggle items.
     * @param items
     * @param selectedItemProperties
     * @param compareItems
     */
    const setSelectedItems = (items, selectedItemProperties) => {
        const selectedItems = [
            ...map(
                (item) => ({
                    selectedItemProperties: selectedItemProperties,
                    item: item,
                    cacheName: cacheName,
                    selected: true
                }),
                items
            )
        ];
        writeCache(client, cacheName, selectedItems);
        //setSelected(!selected);
    };
    return [selected, toggleSelectedItems, setSelectedItems];
};
