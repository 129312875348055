import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Filter } from './Filter';
import { get, map } from 'lodash/fp';
import { LocateEntities } from 'client-shared/components/mapping/LocateEntities';

const useStyles = makeStyles((theme) => ({
    root: { width: '100%', height: '80vh' }
}));

export const ShowEquipmentMap = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <Filter>
            {({ values: variables, data: cxEquipment }) => {
                return (
                    <div className={classes.root}>
                        <LocateEntities data={map(enLabel, cxEquipment)} filter={get('_locationSource', variables)} />
                    </div>
                );
            }}
        </Filter>
    );
};

const enLabel = (_in) => ({ ..._in, mapLabel: `${_in.DisplayName}: ${_in.Location.Name}` });
