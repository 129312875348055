import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field, LookupValueEditor, CurrentLifespanEditor, currentLifespanValidationSchema } from '../index';
import model from 'cb-schema/emptymodel/CxTimedLookupValue';
import { array, number, object } from 'yup';
import { Section } from 'client-shared/components/Section';
import { flow } from 'lodash/fp';
import Stack from '@mui/material/Stack';
import { parseDate } from 'client-shared/utility';

import { format, addYears } from 'date-fns/fp';
import { TextField } from 'formik-mui';

const timedLookupValue = {
    ...model,
    ...{
        LookupValue: { Id: 0, _DisplayName: '' },
        CurrentLifespan: {
            Start: format("yyyy-MM-dd'T'00:00:00", new Date()),
            End: flow(parseDate, addYears(1), format("yyyy-MM-dd'T'00:00:00"))(new Date())
        }
    }
};

export const timedLookupValueValidationSchema = array().of(
    object({
        LookupValue: object({
            Id: number().moreThan(0, 'Required')
        }),
        CurrentLifespan: currentLifespanValidationSchema
    })
);

export const TimedLookupValueEditor = ({ form, field, classes, type, children, objectType, label, ...other }) => {
    return (
        <Section title={label}>
            <FieldArray
                name={field.name}
                render={(arrayHelpers) => {
                    return (
                        <React.Fragment>
                            {field.value.map((contact, index) => (
                                <Stack key={index} direction="row" spacing={2}>
                                    <Field
                                        type="text"
                                        label={label}
                                        name={`${field.name}.${index}.LookupValue`}
                                        component={LookupValueEditor}
                                        className={classes.selectField}
                                        objectType={objectType}
                                        initialize={false}
                                    />
                                    <Field
                                        type="text"
                                        name={`${field.name}.${index}.CurrentLifespan`}
                                        component={CurrentLifespanEditor}
                                        classes={classes}
                                    />
                                    <Field
                                        type="text"
                                        label="Description"
                                        name={`${field.name}.${index}.Description`}
                                        component={TextField}
                                        className={classes.textField}
                                        multiline
                                        variant="outlined"
                                        margin="normal"
                                    />
                                    <div>
                                        <button type="button" onClick={() => arrayHelpers.remove(index)}>
                                            -
                                        </button>
                                    </div>
                                </Stack>
                            ))}
                            <button
                                type="button"
                                onClick={() => {
                                    arrayHelpers.push(timedLookupValue);
                                }}
                            >
                                +
                            </button>
                        </React.Fragment>
                    );
                }}
            />
        </Section>
    );
};

TimedLookupValueEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};
