import React from 'react';
import { useApolloClient } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropBase, ActionsMixin } from 'client-shared/utility';
import { usePrivilege } from 'client-shared/utility';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '5px',
        width: '100px'
    }
}));

export const ToolBarDelete = () => {
    const classes = useStyles();
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    class Actions extends ActionsMixin(DragDropBase) {}
    const actions = new Actions(client, privilege);
    return (
        <Tooltip title="Drop allocations here to delete them. Click here to unselect items.">
            <IconButton
                className={classes.button}
                aria-label="Delete"
                onDrop={(ev) => actions.handleDeleteDrop(ev)}
                onDragOver={actions.allowDrop}
                onClick={actions.unSelect}
            >
                <DeleteIcon fontSize="large" />
            </IconButton>
        </Tooltip>
    );
};
