import React, { Component } from 'react';
import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css';
import PropTypes from 'prop-types';
import { clearEvents, setupEvents } from '../timeline';
import { Scheduler } from 'dhtmlx-scheduler';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';

export default class Calendar extends Component {
    initScheduler() {
        const { data, date, view, values } = this.props;
        this.scheduler.config.start_on_monday = !values._startWeekSunday;
        this.scheduler.updateView(date || new Date(), view || 'month');
        this.scheduler.clearAll();
        this.scheduler.parse(data);
        this.scheduler.plugins({
            tooltip: true
        });

        clearEvents(this.scheduler);
        setupEvents(this.scheduler, this.props);
    }

    componentDidMount() {
        const { title } = this.props;

        const {
            containerFill,
            header = [
                'day',
                'week',
                'month',
                { html: 'To PDF', click: () => printMe(this.scheduler) },
                'minicalendar',
                'date',
                'prev',
                'today',
                'next'
            ]
        } = this.props;

        this.scheduler = Scheduler.getSchedulerInstance();

        const _scheduler = this.scheduler;
        _scheduler.skin = 'material';
        _scheduler.config.header = header;

        //scheduler.config.readonly = true;

        _scheduler.plugins({
            minical: true,
            container_autoresize: containerFill,
            pdf: true
        });

        const { editCallback, date, view } = this.props;
        _scheduler.init(this.schedulerContainer, date || new Date(), view || 'month');
        // _scheduler.showLightbox = (id) => editCallback(_scheduler.getEvent(id));
        _scheduler.attachEvent('onClick', (id) => {
            editCallback(_scheduler.getEvent(id));
            return false;
        });

        this.initScheduler();

        function printMe(foo) {
            const header = `<h1>${title}</h1>
         <h2>${format(foo._min_date, 'd MMM yyyy')} - ${format(subDays(foo._max_date, 1), 'd MMM yyyy')} </h2>`;
            foo.exportToPDF({ header, orientation: 'landscape', format: 'A4', landscape: true });
        }
    }

    componentDidUpdate() {
        this.initScheduler();
    }

    render() {
        const { width = '100%', height = 'calc(100vh - 250px)' } = this.props;

        return (
            <div
                ref={(input) => {
                    this.schedulerContainer = input;
                }}
                className="widget-box dhx_cal_container"
                style={{ height: height, width: width }}
            ></div>
        );
    }
}

Calendar.propTypes = {
    editCallback: PropTypes.func,
    onEventChanged: PropTypes.func,
    onEventDeleted: PropTypes.func,
    onViewChange: PropTypes.func,
    dropHandler: PropTypes.func,
    containerFill: PropTypes.bool,
    header: PropTypes.array
};
