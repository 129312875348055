import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { values, uniq, find, startCase, get } from 'lodash/fp';
import { getSelectedReportProperties } from 'client-shared/utility';
import * as echarts from 'echarts';
import { format } from 'date-fns/fp';
const map = require('lodash/fp/map').convert({ cap: false });

const getSeries = (reportIntervalPropertiesLine, reportIntervalPropertiesBar, reportIntervals = []) => {
    const series = {};
    const getType = (key, reportIntervalPropertiesLine) => {
        return find((reportIntervalProperty) => reportIntervalProperty === key, reportIntervalPropertiesLine)
            ? 'line'
            : 'bar';
    };
    map((reportInterval) => {
        map(
            (report) => {
                if (!series[report.label]) {
                    series[report.label] = {
                        name: report.label,
                        data: [],
                        type: getType(report.key, reportIntervalPropertiesLine)
                    };
                }
                series[report.label].data.push([
                    format('yyyy-MM-dd', reportInterval.Range.Start),
                    Math.round(report.value)
                ]);
            },
            getSelectedReportProperties({
                reportProperties: uniq([...reportIntervalPropertiesLine, ...reportIntervalPropertiesBar]),
                reportObject: get('Report', reportInterval),
                unit: { Name: 'Hrs', Scalar: 60 }
            })
        );
    }, reportIntervals);
    return values(series);
};

const getOptions = (values, series) => {
    const options = {
        toolbox: {
            feature: {
                dataZoom: {}, // Enable data zoom
                dataView: {}, // Enable data view
                //restore: {}, // Enable restore
                saveAsImage: {} // Enable save as image
            },
            right: '30%' // Center the toolbox horizontally
        },
        title: {
            text: `Metrics by ${startCase(values._interval)}`,
            right: 'center',
            textStyle: {
                color: '#333',
                fontSize: 18,
                fontWeight: 'bold',
                fontFamily: 'sans-serif'
                // Other styling options...
            }
        },
        legend: {
            orient: 'horizontal',
            bottom: '0%'
        },
        xAxis: {
            type: 'time',
            axisLabel: {
                formatter: {
                    // Display year and month information on the first data of a year
                    day: '{d}\n{monthStyle|{MMM}}',
                    year: '{yearStyle|{yyyy}}\n{monthStyle|{MMM}}',
                    month: '{yearStyle|{yyyy}}\n{monthStyle|{MMM}}'
                }
            }
        },
        yAxis: {},
        series: series,
        tooltip: {
            trigger: 'axis'
        }
    };
    return options;
};

/**
 * render a graph that displays items grouped by various properties (e.g. job, resource, resource type, other)
 * by period (e.g. day, week, month, other) where each grouping is a row and each interval is a column.
 * @param rangeRows - a collection of rangeRow objects that contain intervalData. created by getRangeRow().
 * @param periods - number of intervals
 * @param interval - string - 'days', 'weeks', 'months'
 */
export const Graph = ({ values, reportIntervals }) => {
    const series = getSeries(
        values._reportIntervalPropertiesLine,
        values._reportIntervalPropertiesBar,
        reportIntervals
    );
    return <RenderGraph options={getOptions(values, series)} />;
};

Graph.propTypes = {
    values: PropTypes.object.isRequired,
    reportIntervals: PropTypes.array
};

export const RenderGraph = ({ options }) => {
    const chartRef = useRef(null);
    const chart = useRef(null);
    useEffect(() => {
        if (chartRef.current !== null) {
            chart.current = echarts.init(chartRef.current);
            chart.current.setOption(options);
        }
        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            chart.current.resize();
        }
        window.addEventListener('resize', resizeChart);

        // Fetch initial data (if needed)
        // Example: fetchInitialData().then(data => chart.setOption({ series: data }));
        return () => {
            chart.current.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [options]);

    // useEffect(() => {
    //     chart.current.setOption(options);
    //     // Initialize your chart options here
    //     // Example: const options = { ... };
    // }, [options]);

    return <div ref={chartRef} style={{ width: '100%', height: 'calc(100vh - 180px)' }} />;
};

RenderGraph.propTypes = {
    options: PropTypes.object.isRequired
};
