import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Schedule } from 'client-shared/components/schedulegrid';
import { GridAllocations } from 'client-shared/components/schedulegrid/GridAllocations';
import {
    getColumns,
    getCellValue,
    withAllocations,
    Layout,
    Scroll,
    HeaderCell
} from 'client-shared/components/schedulegrid/schedulehelpers';
import { withEditor } from 'client-shared/entities/withEditor';
import { withGrid } from 'client-shared/grids/withGrid';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { TabsMenu } from 'client-shared/components/TabsMenu';
import {
    getFilter,
    usePrivilege,
    parseDate,
    UserContext,
    useReportTotals,
    getConfigValue,
    reportExcel
} from 'client-shared/utility';
import { flow, get, filter, flatMap, sortBy, flowRight as compose, reduce, find } from 'lodash/fp';
import { JobSummary } from './JobSummary';
import { Job } from './Job';
import { Report } from 'client-shared/components/Report';
import { JobStart } from './JobStart';
import { CellDragDrop, AllocationDragDrop, Actions } from './dragdrop';
import { totals, displayTotals } from 'client-shared/components/totals';
import { Divider } from '@mui/material';
import { ClipBoard } from 'client-shared/components/schedulegrid/ClipBoard';
import { ToolBar } from 'client-shared/components/schedulegrid/ToolBar';
import { ToolBarActions } from 'client-shared/components/schedulegrid/ToolBarActions';
import { ToolBarDelete } from 'client-shared/components/schedulegrid/ToolBarDelete';
import { ExcelExport } from 'client-shared/components/ExcelExport';
import { useApolloClient } from '@apollo/client';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { format, isSameDay } from 'date-fns/fp';

const map = require('lodash/fp/map').convert({ cap: false });

/**
 * comparison function to filter allocations for the row.
 * @param row
 * @param columnName
 * @param allocation
 * @returns {unknown}
 */
const comparator = (row, columnName, allocation) => {
    const key = row._root ? 'Job.Id' : 'JobActivity.Id';
    if (get(key, allocation) !== row.Id) {
        return false;
    }
    // check if allocation contains taskDays for columnName date.
    return allocation.TaskDays.get(columnName);
};

/**
 * get the rows to display in the grid.
 * @param jobs
 * @returns {*}
 */
const getRows = (data) => {
    const jobs = map(
        (job) => ({
            _root: true,
            ...job,
            JobStart: format('yyyy-MM-dd', parseDate(job.CurrentLifespan.Start)),
            JobActivities: map(
                (jobActivity) => ({
                    ...jobActivity,
                    Job: { Id: job.Id },
                    JobStart: format('yyyy-MM-dd', parseDate(jobActivity.CurrentLifespan.Start))
                }),
                job.JobActivities
            )
        }),
        data
    );
    return sortBy('DisplayName', jobs);
};

/**
 * get the columns to display in the grid.
 * @param values
 * @returns {[{name: string, title: string},...*]}
 */
const getCols = (values) => {
    const cols = [
        {
            name: 'Job',
            title: 'Job'
        }
    ];
    if (values._jobStart) {
        cols.push({
            name: 'JobStart',
            title: 'Job Start'
        });
    }
    if (get('_reportProperties.length', values)) {
        cols.push({
            name: 'Total',
            title: 'Total Metrics'
        });
    }
    cols.push(
        ...getColumns({
            values
        })
    );
    return cols;
};

/**
 * get the rows to export.
 * @param values
 * @param jobs
 * @returns {*}
 */
const getExcelRows = (jobs, values) => {
    if (values._rowDetail) {
        return [{ headerRow: true }, ...jobs];
    }
    const rows = flatMap((job) => {
        const jobActivities = map((jobActivity) => {
            return {
                ...jobActivity,
                job: job
            };
        }, job.JobActivities);
        return [job, ...jobActivities];
    }, jobs);
    return [{ headerRow: true }, ...rows];
};

/**
 * get the columns to export.
 * @param columns
 * @returns {[{name: string, title: string},...*]}
 */
const getExcelCols = (columns) => {
    // add additional columns to export.
    columns = [
        { title: 'ObjectType', name: 'ObjectType' },
        { title: '_job', name: '_job' },
        { title: '_jobActivity', name: '_jobActivity' },
        ...columns
    ];
    columns.splice(4, 0, { title: 'Description', name: 'Description' });
    return columns;
};

const genericTotal = (taskDays) => {
    const total = reduce(
        (totals, taskDay) => {
            if (taskDay.ActionType === 'Generic') {
                return (totals += taskDay.Amount);
            }
            return totals;
        },
        0,
        taskDays
    );
    return total ? total : '';
};

/**
 * populate each row of the spreadsheet.
 * @param values
 * @param allocations
 * @returns {(function(*, *, *, *): (*|undefined))|*}
 */
const addExcelCell = (values, allocations, reportIntervals) => {
    return ({ row, rowNumber, column, colNumber, colName, cell }) => {
        cell.alignment = { wrapText: true };
        // add total row
        if (row.headerRow) {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DCDCDC' } };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: {
                    style: 'thin'
                }
            };
            cell.value = get('title', column);
            if (isNaN(new Date(colName))) {
                return;
            }
            const reportInterval = find(
                (reportInterval) => isSameDay(parseDate(reportInterval.Range.Start), parseDate(colName)),
                reportIntervals
            );
            cell.value = {
                richText: [
                    {
                        font: { bold: true },
                        text: `${colName}\n`
                    },
                    ...reportExcel({
                        reportProperties: values._reportIntervalProperties,
                        reportObject: get('Report', reportInterval),
                        unit: { Name: 'Hrs', Scalar: 60 }
                    })
                ]
            };
            return;
        }
        let color = get('ForeColor', row) ? get('ForeColor', row).slice(1) : '00000';
        let backColor = get('BackColor', row) ? get('BackColor', row).slice(1) : 'FFFFFF';
        if (colName === 'ObjectType') {
            cell.value = row.ObjectType;
            return;
        }
        if (colName === '_job') {
            cell.value = row._root ? row.DisplayName : row.job.DisplayName;
            return;
        }
        if (colName === '_jobActivity') {
            cell.value = row.DisplayName;
            return;
        }
        if (colName === 'Job') {
            cell.font = { color: { argb: color } };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
            cell.value = row._root ? row.DisplayName : `    ${row.DisplayName}`;
            return;
        }
        if (colName === 'Description') {
            cell.value = row.Description;
            return;
        }
        if (colName === 'Total') {
            cell.value = {
                richText: reportExcel({
                    reportProperties: values._reportProperties,
                    reportObject: row.Report,
                    unit: { Name: 'Hrs', Scalar: 60 }
                })
            };
            return;
        }
        if (!values._rowDetail && row._root) {
            flow(
                filter((allocation) => comparator(row, colName, allocation)),
                (allocations) => {
                    const accumulator = totals({ allocations: allocations, start: colName });
                    cell.value = displayTotals(accumulator);
                }
            )(allocations);
            return;
        }
        flow(
            filter((allocation) => comparator(row, colName, allocation)),
            map((allocation) => {
                return {
                    font: { color: { argb: get('ForeColor', allocation).slice(1) } },
                    text: `${allocation.DisplayName} ${genericTotal(allocation.TaskDays.get(colName))}\n`
                };
            }),
            (richText) => {
                if (!richText.length) {
                    return;
                }
                color = color === '000000' ? 'f0f0f0' : color;
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
                cell.border = {
                    top: { style: 'medium' },
                    left: { style: 'medium' },
                    bottom: { style: 'medium' },
                    right: { style: 'medium' }
                };
                cell.value = { richText: richText };
            }
        )(allocations);
    };
};

const getTargetProperties = (row, columnName) => {
    if (row._root) {
        return {
            _root: true,
            Job: {
                // Id: row.ObjectType === 'Job' ? row.JobActivities[0].Id : undefined
                Id: row.Id
            },
            JobActivity: {
                Id: get('JobActivities.0.Id', row)
            },
            CurrentLifespan: {
                Start: columnName,
                End: columnName
            }
        };
    }
    return {
        Job: {
            Id: row.Job.Id
        },
        JobActivity: {
            Id: row.Id
        },
        CurrentLifespan: {
            Start: columnName,
            End: columnName
        }
    };
};

const TableCellComponent = ({ onOpenEditor, ...other }) => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    const targetProperties = getTargetProperties(other.row, other.column.name);
    const cellDragDrop = new CellDragDrop(client, privilege, targetProperties, onOpenEditor);
    return (
        <VirtualTable.Cell
            {...other}
            onDrop={(event) => {
                cellDragDrop.handleCellDrop(event);
            }}
            onDragOver={['JobStart', 'Total'].includes(other.column.name) ? undefined : cellDragDrop.allowDrop}
        />
    );
};

/**
 * component to display a cell in the grid.
 * @param row - a row from the grid.
 * @param columnName - name of column
 * @param items - collection of allocations
 * @param rowDetail - false if only totals are displayed on the job row.
 * @param onOpenEditor - function to open an edit form.
 * @param onOpenGrid - function to display a grid.
 * @returns {JSX.Element|undefined}
 * @constructor
 */
const Cell = ({ values, row, columnName, items, onOpenEditor, onOpenGrid }) => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    const targetProperties = getTargetProperties(row, columnName);
    const cellDragDrop = new CellDragDrop(client, privilege, targetProperties, onOpenEditor);
    if (columnName === 'Job') {
        return <Job row={row} values={values} onOpenEditor={onOpenEditor} onOpenGrid={onOpenGrid} />;
    }
    if (columnName === 'JobStart') {
        return <JobStart row={row} />;
    }
    if (columnName === 'Total') {
        return (
            <Report
                reportProperties={values._reportProperties}
                reportObject={row.Report}
                unit={{ Name: 'Hrs', Scalar: 60 }}
            />
        );
    }

    if (row._root) {
        if (!values._rowDetail) {
            return (
                <JobSummary
                    client={client}
                    items={items}
                    targetProperties={targetProperties}
                    cellDragDrop={cellDragDrop}
                />
            );
        }

        return (
            <div
                onDrop={(event) => {
                    cellDragDrop.handleCellDrop(event);
                }}
                onDragOver={cellDragDrop.allowDrop}
            >
                <JobSummary
                    client={client}
                    items={items}
                    targetProperties={targetProperties}
                    cellDragDrop={cellDragDrop}
                />
                <GridAllocations
                    client={client}
                    values={values}
                    privilege={privilege}
                    items={items}
                    targetProperties={targetProperties}
                    cellDragDrop={cellDragDrop}
                    AllocationDragDrop={AllocationDragDrop}
                    onOpenEditor={onOpenEditor}
                />
            </div>
        );
    }
    return (
        <div
            onDrop={(event) => {
                cellDragDrop.handleCellDrop(event);
            }}
            onDragOver={cellDragDrop.allowDrop}
        >
            <GridAllocations
                client={client}
                values={values}
                privilege={privilege}
                items={items}
                targetProperties={targetProperties}
                cellDragDrop={cellDragDrop}
                AllocationDragDrop={AllocationDragDrop}
                onOpenEditor={onOpenEditor}
            />
        </div>
    );
};

Cell.propTypes = {
    values: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    columnName: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    onOpenEditor: PropTypes.func.isRequired,
    onOpenGrid: PropTypes.func.isRequired
};

const Grid_ = ({
    values,
    onSubmit,
    excelRows,
    excelColumns,
    filterFormDefinition,
    rows,
    columns,
    allocations,
    onOpenEditor,
    onOpenGrid
}) => {
    const [reportIntervals] = useReportTotals({
        reportProperties: values._reportIntervalProperties,
        periods: values._periods,
        start: values._start,
        stratify: false,
        interval: 'Days',
        values,
        actionstatus_id: values._actionStatus,
        actiontype: values._actionType
    });
    return (
        <React.Fragment>
            <Scroll values={values} onSubmit={onSubmit} />
            <ExcelExport
                rows={excelRows}
                getColumns={() => excelColumns}
                addExcelCell={addExcelCell(values, allocations, reportIntervals)}
            />
            <Schedule
                title={filterFormDefinition.title}
                rows={rows}
                columns={columns}
                sortColumnNames={['JobStart']}
                getRowId={(row) => {
                    return row._root ? row.Id : `c${row.Id}${row.Job.Id}`;
                }}
                getChildRows={(row, rootRows) => {
                    if (row) {
                        if (row._root) {
                            return row.JobActivities;
                        }
                        return null;
                    }
                    return rootRows;
                }}
                getCellValue={getCellValue({
                    items: allocations,
                    comparator: comparator,
                    render: ({ row, columnName, items }) => {
                        return (
                            <Cell
                                values={values}
                                row={row}
                                columnName={columnName}
                                items={items}
                                onOpenEditor={onOpenEditor}
                                onOpenGrid={onOpenGrid}
                            />
                        );
                    }
                })}
                tableHeaderCell={(props) => {
                    return (
                        <HeaderCell
                            values={values}
                            column={props.column}
                            items={allocations}
                            reportIntervals={reportIntervals}
                        />
                    );
                }}
                tableCellComponent={(props) => {
                    return <TableCellComponent onOpenEditor={onOpenEditor} {...props} />;
                }}
                fixedColumnNames={['Job', 'JobStart', 'Total']}
            />
        </React.Fragment>
    );
};

const Grid = compose([withGrid, withEditor, withAllocations])(Grid_);

const Schedule_ = () => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.pages', 'jobschedule.js');
    filterFormDefinition.onData = getRows;
    return (
        <div>
            <FilterFormFromJson filterFormDefinition={filterFormDefinition}>
                {({ values, data, event, onSubmit }) => {
                    const rows = data;
                    const columns = getCols(values);
                    const excelRows = getExcelRows(rows, values);
                    const excelColumns = getExcelCols(columns);
                    values._jobDivisionIds = map((division_id) => division_id.Id, get('divisions_id', values)); // filters allocations
                    values._allocationFamily = values._family ? (values._family === 'All' ? [] : [values._family]) : [];
                    return (
                        <Grid
                            values={values}
                            onSubmit={onSubmit}
                            excelRows={excelRows}
                            excelColumns={excelColumns}
                            filterFormDefinition={filterFormDefinition}
                            rows={rows}
                            columns={columns}
                        />
                    );
                }}
            </FilterFormFromJson>
        </div>
    );
};

const Tools = () => {
    const client = useApolloClient();
    const [privilege] = usePrivilege('Allocation');
    const [user] = useContext(UserContext);
    const config_configValue = getConfigValue('config.schedule.jobschedule.toolbar', user) || [];
    const labels = get('labels', config_configValue);
    const actions = new Actions(client, privilege);
    return (
        <ToolBar>
            <div>
                <ToolBarDelete />
            </div>
            <ToolBarActions actions={actions} />
            <TabsMenu labels={labels} />
            <Divider />
            <ClipBoard />
        </ToolBar>
    );
};

// export const JobSchedule = () => <Layout Schedule={compose([withGrid, withEditor])(Schedule_)} ToolBar={Tools} />;
export const JobSchedule = () => <Layout Schedule={Schedule_} ToolBar={Tools} />;
