import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import { CheckboxWithLabel } from 'formik-mui';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import { TextField } from 'formik-mui';
import { Formik, Form, ErrorMessage, FieldArray } from 'formik';
import { Field, AutoComplete } from 'client-shared/components/form';
import { get, curry, pipe } from 'lodash/fp';
import { useStyles } from 'client-shared/components/form/components';
import { useTheme } from '@mui/styles';

export const useDetail = () => {
    const [state, setState] = useState({ visible: false, fn: toggle, values: [] });

    /**
     * Pass in a handler function
     * @param fn
     */
    function toggle(fn, values) {
        setState(fixState(fn, values));
    }

    const fixState = curry((fn, values, current) => ({
        visible: !current.visible,
        fn: fn ? pipe(fn, toggle) : current.fn,
        values
    }));
    return [state, toggle];
};

export const Detail = ({ state, left, data, hide }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const column = get('values.column', state);
    const model = get('values.value', state);
    return state.visible
        ? ReactDOM.createPortal(
              <React.Fragment>
                  <Formik
                      initialValues={model}
                      onSubmit={(_in, { setSubmitting }) => {
                          setSubmitting(false);
                          state.fn(_in);
                      }}
                  >
                      {({ handleSubmit }) => (
                          <Form>
                              <Dialog
                                  open={true}
                                  maxWidth="sm"
                                  fullWidth={true}
                                  onClose={() => state.fn(null)}
                                  aria-describedby="alert-dialog-description"
                              >
                                  <DialogTitle>
                                      <Link
                                          component={RouterLink}
                                          color="inherit"
                                          to={`/accounting/timesheet/${model.JobActivity.Job.Id}/${column.name}`}
                                      >
                                          {column.name} {model.Party.DisplayName}
                                      </Link>
                                  </DialogTitle>
                                  <DialogContent>
                                      <DialogContentText>
                                          {model.JobActivity.Job.DisplayName} / {model.JobActivity.DisplayName}
                                      </DialogContentText>
                                      <FieldArray
                                          name="items"
                                          render={() => (
                                              <React.Fragment>
                                                  <Field
                                                      type="number"
                                                      label="Hours"
                                                      name={`Hours`}
                                                      component={TextField}
                                                      className={classes.megaShortTextField}
                                                  />
                                                  <ErrorMessage name={'Hours'} component="div" />
                                                  <Field
                                                      type="text"
                                                      label="Job Cost"
                                                      name={`JobCosts[0].Id`}
                                                      className={classes.shortSelectField}
                                                      component={AutoComplete}
                                                      options={[
                                                          { Id: -1, DisplayName: 'none' },
                                                          ...get('JobCosts', model.JobActivity.Job)
                                                      ]}
                                                      optionLabelProperty="DisplayName"
                                                      optionIdProperty="Id"
                                                  />
                                                  <ErrorMessage name="JobCosts.Id" component="div" />
                                                  <Field
                                                      type="text"
                                                      label="Entry Type"
                                                      name={`EntryType.Id`}
                                                      className={classes.shortSelectField}
                                                      component={AutoComplete}
                                                      options={data.entryTypes}
                                                      optionLabelProperty="Name"
                                                      optionIdProperty="Id"
                                                  />
                                                  <ErrorMessage name="EntryType.Id" component="div" />
                                                  <Field
                                                      component={CheckboxWithLabel}
                                                      type="checkbox"
                                                      name="IsVoid"
                                                      Label={{ label: 'Void' }}
                                                  />
                                              </React.Fragment>
                                          )}
                                      />
                                  </DialogContent>
                                  <DialogActions>
                                      <Button
                                          onClick={() => {
                                              hide(() => {});
                                          }}
                                          color="primary"
                                      >
                                          Cancel
                                      </Button>
                                      <Button onClick={handleSubmit} color="primary">
                                          {left || 'Yes'}
                                      </Button>
                                  </DialogActions>
                              </Dialog>
                          </Form>
                      )}
                  </Formik>
              </React.Fragment>,
              document.body
          )
        : null;
};
