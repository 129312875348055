/**
 *
 *
 * Copyright 2002-2020 Congistics Corporation
 */
import React from 'react';
import PropTypes from 'prop-types';
import { parseDate } from 'client-shared/utility';
import format from 'date-fns/fp/format';

export const DateTime = ({ dateTime, dateTimeFormat = 'yyyy-MM-dd', ...other }) => {
    return <span {...other}>{format(dateTimeFormat, parseDate(dateTime))}</span>;
};

DateTime.propTypes = {
    dateTime: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.instanceOf(Date).isRequired]),
    dateTimeFormat: PropTypes.string
};
