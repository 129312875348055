import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter, flow, get, map, cloneDeep, find } from 'lodash/fp';
import {
    adjustToInterval,
    AllocationEventQuery,
    getRange,
    groupCollection,
    parseDate,
    createFilters,
    SCHEDULECACHE,
    useSelected,
    unSelectAll
} from 'client-shared/utility';
import { addDays, format, isSameDay, parseISO, subDays } from 'date-fns/fp';
import { useCollectionSubscription, HEADERCACHE } from 'client-shared/utility';
import { AllocationsQuery } from './query';
import SplitPane from 'react-split-pane';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Typography, Button } from '@mui/material';
import { Report } from 'client-shared/components/Report';
import { useApolloClient } from '@apollo/client';

export const getIndexAllocations = (allocations) => {
    const groupByInterval = {
        start: (item) => {
            return adjustToInterval(get('CurrentLifespan.Start', item));
        }
    };

    const indexedAllocations = cloneDeep(allocations);
    // convert allocation taskdays into a grouped map by day.
    return map((allocation) => {
        allocation.TaskDays = groupCollection(groupByInterval, allocation.TaskDays);
        return allocation;
    }, indexedAllocations);
};

/**
 * convert map to array.
 * @param map
 * @returns {*[]}
 */
export const mapToArray = (map) => [].concat(...[...map.values()]);

export const withAllocations = (Component) => (props) => {
    const { values, onFormatters } = props;
    const filters = createFilters({
        _start: values._start,
        //days: values.days,
        _periods: values._periods,
        _interval: values._interval,
        Resources_Divisions_Id: values._resourceDivisionIds,
        Job_Divisions_Id: values._jobDivisionIds,
        TaskDays_ResourceType_Family: values._allocationFamily,
        JobActivity_Name: values.name__child__jobactivities,
        ActionStatus_Id: values._actionStatus,
        ActionType: values._actionType,
        range: [],
        range__child__taskdays: [],
        string__Job_DisplayName: values._displayName
    });
    const handleFilters = (values) => {
        const filters = [...values.filters, { name: 'id', values: values.id || [] }];
        return { filters: filters };
    };
    const { data: allocations } = useCollectionSubscription({
        dataQuery: AllocationsQuery,
        eventQueries: [AllocationEventQuery],
        values: filters,
        onFilter: handleFilters,
        onFormatters: onFormatters
    });
    if (!allocations) {
        return <div>Loading...</div>;
    }
    return <Component allocations={getIndexAllocations(allocations)} {...props} />;
};

export const getColumns = ({ values }) => {
    return [
        ...getRange(values._start, values._periods).map((date, index) => ({
            name: `${format('yyyy-MM-dd', parseISO(date))}`,
            title: format('EEE MM/dd/yyyy', parseISO(date)),
            width: 350
        }))
    ];
};

export const getCellValue =
    ({ items, comparator, render }) =>
    (row, columnName) => {
        const cellItems = filter((item) => comparator(row, columnName, item), items);
        return render({ row: row, columnName: columnName, items: cellItems });
    };

// zindex is necessary in chrome.
export const Layout = ({ ToolBar, Schedule }) => {
    const SIZE = 275;
    const [size, setSize] = useState(SIZE);
    return (
        <SplitPane
            minSize={100}
            maxSize={400}
            defaultSize={SIZE}
            split="vertical"
            onDragFinished={(size) => {
                setSize(size);
            }}
            style={{ height: 'calc(100vh - 100px)', overflow: 'visible', zIndex: 100 }}
        >
            <div>
                <ToolBar />
            </div>
            <div
                style={{
                    height: 'calc(100vh - 210px)',
                    width: `calc(100vw - ${size + 20}px)`
                }}
            >
                <Schedule size={size} />
            </div>
        </SplitPane>
    );
};

Layout.propTypes = {
    ToolBar: PropTypes.func.isRequired,
    Schedule: PropTypes.func.isRequired
};

export const Scroll = ({ values, onSubmit }) => {
    return (
        <span>
            <IconButton
                onClick={() => {
                    const v = {
                        ...values,
                        _start: flow(parseDate, subDays(values._periods), format('yyyy-MM-dd'))(values._start)
                    };
                    onSubmit(v);
                }}
            >
                <ChevronLeft />
            </IconButton>
            <IconButton
                onClick={() => {
                    const v = {
                        ...values,
                        _start: flow(parseDate, addDays(values._periods), format('yyyy-MM-dd'))(values._start)
                    };
                    onSubmit(v);
                }}
            >
                <ChevronRight />
            </IconButton>
        </span>
    );
};

Scroll.propTypes = {
    values: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
};

/**
 * component to display a header cell in the grid.
 * @param availableEmployees - true if available employees should be calculated and displayed.
 * @param column - column title and name.
 * @param items - collection of allocations.
 * @returns {JSX.Element}
 * @constructor
 */
export const HeaderCell = ({ values, column, items, reportIntervals }) => {
    const client = useApolloClient();
    const allocations = filter((allocation) => allocation.TaskDays.get(column.name), items);
    const [selectedHeader, toggleSelectedHeader] = useSelected([{ Id: column.title }], HEADERCACHE);
    const [, toggleSelected] = useSelected(allocations, SCHEDULECACHE);
    const reportInterval = find(
        (reportInterval) => isSameDay(parseDate(reportInterval.Range.Start), parseDate(column.name)),
        reportIntervals
    );
    if (isNaN(new Date(column.name))) {
        return (
            <Typography fontSize="small" component="div">
                {column.title}
            </Typography>
        );
    }
    return (
        <Typography fontSize="small" component="div">
            <Button
                size="small"
                sx={{ backgroundColor: selectedHeader ? 'Gainsboro' : 'transparent' }}
                onClick={() => {
                    unSelectAll(client, HEADERCACHE);
                    unSelectAll(client, SCHEDULECACHE);
                    toggleSelectedHeader([{ Id: column.title }]);
                    if (!selectedHeader) {
                        toggleSelected(allocations, {
                            CurrentLifespan: {
                                Start: column.name
                            },
                            render: false
                        });
                    }
                }}
            >
                {column.title}
            </Button>
            <Report
                reportProperties={values._reportIntervalProperties}
                reportObject={get('Report', reportInterval)}
                label={true}
                unit={{ Name: 'Hrs', Scalar: 60 }}
                style={{ fontSize: '10px' }}
            />
        </Typography>
    );
};

HeaderCell.propTypes = {
    values: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    reportIntervals: PropTypes.array
};
