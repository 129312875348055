import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { map, get, sumBy } from 'lodash/fp';

const useStyles = makeStyles((theme) => ({
    root: {
        listStyle: 'none'
    },
    legend: { listStyle: 'none' },
    legendItem: { display: 'inline' }
}));

export const ChipMe = ({ entryTypes, resourceTotals, value }) => {
    const timez = get(value, resourceTotals);
    const total = sumBy('1', timez);
    return (
        <React.Fragment>
            {map(
                ([k, v]) => (
                    <Tooltip key={k} title={get([k, 0, 'Name'], entryTypes)}>
                        <Chip style={{ backgroundColor: get([k, 0, 'BackColor'], entryTypes) }} label={`---${v}`} />
                    </Tooltip>
                ),
                timez
            )}
            <Tooltip key="total" title="Total">
                <Chip style={{ backgroundColor: 'white' }} label={total} variant="outlined" />
            </Tooltip>
        </React.Fragment>
    );
};

export const TimeEntryLegend = ({ entries, noScheduled }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <ul className={classes.legend}>
            {[
                !noScheduled && (
                    <li key="scheduled" className={classes.legendItem}>
                        <Chip style={{ backgroundColor: 'white' }} label="Scheduled" variant="outlined" />
                    </li>
                ),
                ...map(
                    (each) => (
                        <li key={each.Id} className={classes.legendItem}>
                            <Chip style={{ backgroundColor: each.BackColor }} label={each.Name} />
                        </li>
                    ),
                    entries
                )
            ]}
        </ul>
    );
};
