import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { instantiateBuild } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';

function build(props) {
    const { gql, format, get, user, date, object } = props;
    const validationSchema = object({
        _start: date().required('Required')
    });
    const dataQuery = `
        query ($filters: [FilterInput]) {
            cxEquipments(filters: $filters) {
                Id
                AccountCode
                DisplayName
                SerialNumber
                ForeColor
                Location {
                    Name
                    Address {
                        Geocode {
                            Lat
                            Lng
                        }
                    }
                }
            }
        }
    `;

    const dataEventQuery = gql`
        query {
            resourceEvent @client {
                EventId
                Dependencies {
                    Id
                    SourceMutationObjectType
                }
                ObjectType
                differentiator
            }
        }
    `;

    return {
        title: 'Equipment Map',
        dataQuery,
        validationSchema,
        dataEventQueries: [dataEventQuery],
        initialValues: {
            _start: format('yyyy-MM-dd', new Date()),
            _periods: 7,
            range: []
        },
        fieldDefinitions: [
            {
                type: 'text',
                label: 'Name',
                name: 'string__displayName',
                component: 'TextField',
                className: 'textField'
            },
            {
                type: 'text',
                name: 'scheduled',
                component: 'ScheduledEditor',
                initialValue: 'All'
            },
            {
                type: 'text',
                label: 'Type',
                name: 'resourceType_id',
                component: 'AutoComplete',
                optionsQuery: `
                    query ($searchString: String) {
                        cxResourceTypes(
                            filters: [
                                { name: "String", values: ["DisplayName", $searchString] }
                                { name: "Family", values: ["Equipment"] }
                            ]
                        ) {
                            Id
                            Name
                        }
                    }
                `,
                className: 'selectField',
                multiple: true,
                optionLabelProperty: 'Name',
                initialValue: []
            },
            {
                type: 'text',
                label: 'Divisions',
                name: 'divisions_id',
                component: 'AutoComplete',
                className: 'selectField',
                optionLabelProperty: 'DisplayName',
                options: get('congistics.user.Divisions', user),
                multiple: true,
                initialValue: []
            },
            {
                type: 'date',
                label: 'Start',
                name: '_start',
                component: 'TextField',
                className: 'textField',
                initialValue: format('yyyy-MM-dd', new Date())
            },
            {
                type: 'number',
                label: 'Days',
                name: '_periods',
                component: 'TextField',
                className: 'textField',
                initialValue: 7
            },
            {
                type: 'text',
                label: 'Location Source',
                name: '_locationSource',
                component: 'AutoComplete',
                initialValue: 'schedule',
                options: [
                    { Id: 'schedule', Name: 'Schedule' },
                    { Id: 'gps', Name: 'GPS' },
                    { Id: 'both', Name: 'Both' }
                ],
                className: 'shortSelectField',
                optionLabelProperty: 'Name',
                optionIdProperty: 'Id'
            }
        ]
    };
}

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = instantiateBuild(user, build);
    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
