import { gql } from '@apollo/client';
/**
 * Created by mark on 11/20/2019.
 */
import React from 'react';
import { Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { AutoComplete, LookupValueEditor, ScheduledEditor } from 'client-shared/components/form';
import { map } from 'lodash/fp';

const components = {
    TextField,
    CheckboxWithLabel,
    AutoComplete,
    LookupValueEditor,
    ScheduledEditor
};

const truncate = {
    selectField: 22,
    shortSelectField: 15
};

export const initialValuesFromJson = (fieldDefinitions) => {
    const initialValues = {};
    fieldDefinitions.forEach((fieldDefinition) => {
        initialValues[fieldDefinition.name] =
            fieldDefinition.initialValue !== undefined
                ? fieldDefinition.initialValue
                : fieldDefinition.options
                ? 'All'
                : '';
    });
    return initialValues;
};

export const fieldsFromJson = (fieldDefinitions, data, classes, values) => {
    return map((fieldDefinition) => {
        if (!fieldDefinition) {
            return null;
        }
        const { component, className, optionsQuery, initialValue, disableClearable, ...other } = fieldDefinition;
        switch (component) {
            case 'ScheduledEditor':
            case 'TextField':
            case 'CheckboxWithLabel':
                return (
                    <Field
                        {...other}
                        key={fieldDefinition.name}
                        component={components[component]}
                        className={classes[className]}
                        classes={classes}
                    />
                );
            case 'LookupValueEditor':
            case 'AutoComplete':
                return (
                    <Field
                        {...other}
                        truncate={truncate[className]}
                        key={fieldDefinition.name}
                        component={components[component]}
                        optionsQuery={
                            optionsQuery
                                ? gql`
                                      ${optionsQuery}
                                  `
                                : undefined
                        }
                        className={classes[className]}
                        initialize={false}
                        disableClearable={disableClearable || false}
                        active={[]}
                        hide={false}
                    />
                );
            default:
                break;
        }
        return null;
    }, fieldDefinitions);
};

export const filterFieldsFromJson =
    (fieldDefinitions) =>
    ({ data, classes }) => {
        return (
            <form noValidate autoComplete="off">
                {fieldsFromJson(fieldDefinitions, data, classes)}
            </form>
        );
    };
