import React from 'react';
import PropTypes from 'prop-types';
import { Filter } from './Filter';
import { map, get, flow, uniqBy, sortBy, find } from 'lodash/fp';
import { gridHeaderItemStyle, gridItemStyle } from '../styles';
import { TaskDaysByJob, taskDaysByJobExcel } from 'client-shared/components/serverobjects/TaskDays2';
import { JobName } from 'client-shared/components/serverobjects/JobName';
import { getRangeRows, GridDashBoard } from '../dashboardhelpers';
import { ResourceName } from 'client-shared/components/serverobjects/ResourceName';
import { GroupBy } from 'client-shared/components/GroupBy';
import { Description } from 'client-shared/components/serverobjects/Description';
import { parseDate, reportExcel, sliceFp } from 'client-shared/utility';
import { isSameDay } from 'date-fns/fp';

const gridRowHeaderItem = gridHeaderItemStyle({ textAlign: 'left' });
const gridItem = gridItemStyle();
const groupByCrew = {
    name: 'Allocation.Crew.DisplayName'
};
const groupByJob = {
    job: 'Allocation.Job.DisplayName'
};

/**
 * populate each cell of the spreadsheet.
 * @param values
 * @param allocations
 * @returns {(function(*, *, *, *): (*|undefined))|*}
 */
const addExcelCell = (values, reportIntervals) => {
    return ({ row, rowNumber, column, colNumber, colName, cell }) => {
        cell.alignment = { wrapText: true };
        if (row.headerRow) {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DCDCDC' } };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: {
                    style: 'thin'
                }
            };
            cell.value = get('title', column);
            if (isNaN(new Date(colName))) {
                return;
            }
            const reportInterval = find(
                (reportInterval) => isSameDay(parseDate(reportInterval.Range.Start), parseDate(colName)),
                reportIntervals
            );
            cell.value = {
                richText: [
                    {
                        font: { bold: true },
                        text: `${colName}\n`
                    },
                    ...reportExcel({
                        reportProperties: values._reportIntervalProperties,
                        reportObject: get('Report', reportInterval),
                        unit: { Name: 'Hrs', Scalar: 60 },
                        label: values._label
                    })
                ]
            };
            return;
        }
        let color = sliceFp(1, undefined, get('items.0.Allocation.Crew.ForeColor', row));
        let backColor = sliceFp(1, undefined, get('items.0.Allocation.Crew.BackColor', row));
        if (colName === 'DisplayName') {
            cell.font = { color: { argb: color } };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
            cell.border = {
                top: { style: 'medium' },
                left: { style: 'medium' },
                bottom: { style: 'medium' },
                right: { style: 'medium' }
            };
            cell.value = get('items.0.Allocation.DisplayName', row);
            return;
        }
        if (isNaN(new Date(colName))) {
            return;
        }
        const intervalData = row.intervalData.get(colName);
        if (!intervalData.length) {
            return;
        }
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
        cell.border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' }
        };
        if (values._summary) {
            cell.value = {
                richText: flow(
                    uniqBy('Allocation.Job.DisplayName'),
                    map((taskDay) => {
                        const color = sliceFp(1, undefined, get('ForeColor', taskDay));
                        return {
                            font: { color: { argb: color } },
                            text: `${taskDay.Allocation.Job.DisplayName}\n`
                        };
                    }),
                    sortBy([])
                )(intervalData)
            };
            return;
        }
        cell.value = {
            richText: taskDaysByJobExcel({ taskDays: intervalData, description: values._description })
        };
    };
};

/**
 * called by the GridDashBoard to render detail rows.
 * @param rangeRow - an object that contains the data for the row in the property, intervalData.
 * see dashBoardhelpers.js for details.
 * @param values - user entered values from the filter bar.
 * @returns {JSX.Element}
 * @constructor
 */
const row =
    (values) =>
    ({ rangeRow }) => {
        const row = map((date) => {
            // get the taskDays for this date.
            const taskDays = rangeRow.intervalData.get(date);
            return (
                <div style={gridItem} key={date}>
                    <Cell taskDays={taskDays} values={values} />
                </div>
            );
        }, rangeRow.getRange());
        return (
            <React.Fragment>
                <div style={gridRowHeaderItem}>
                    <ResourceName resource={get(`items.0.Allocation.Crew`, rangeRow)} />
                </div>
                {row}
            </React.Fragment>
        );
    };

/**
 * called by Row to render a cell for a day, week, month, or other period.
 * @param taskDays - all taskDays for the row and period.
 * @param values - user entered values from the filter bar.
 * @returns {JSX.Element}
 * @constructor
 */
const Cell = ({ taskDays, values }) => {
    return (
        <div style={gridItem}>
            {!values._summary && <TaskDaysByJob taskDays={taskDays} description={values._description} />}
            {values._summary && <Jobs taskDays={taskDays} values={values} />}
        </div>
    );
};

Cell.propTypes = {
    taskDays: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired
};

const Jobs = ({ taskDays, values }) => {
    return (
        // iterate jobs
        <GroupBy
            items={taskDays}
            groupByCriteria={groupByJob}
            // filter={(taskDay) => taskDay.ObjectType === 'ActualTaskDay'}
        >
            {(taskDays, group, index) => {
                return (
                    <div>
                        <JobName job={get('0.Allocation.Job', taskDays)} />
                        {values._description && <Description description={get('0.Allocation.Description', taskDays)} />}
                    </div>
                );
            }}
        </GroupBy>
    );
};

export const CrewGrid = () => {
    return (
        <Filter>
            {({ values, data }) => {
                return (
                    <GridDashBoard
                        values={values}
                        rangeRows={getRangeRows(
                            {
                                groupByCriteria: groupByCrew,
                                datePropertyName: 'CurrentLifespan.Start',
                                start: values._start,
                                periods: values._periods,
                                interval: values._interval,
                                skipDays: values._skipDays,
                                stratify: values._stratify
                            },
                            data
                        )}
                        periods={values._periods}
                        interval={values._interval}
                        skipDays={values._skipDays}
                        stratify={values._stratify}
                        orientation={values._orientation}
                        row={row(values)}
                        addExcelCell={addExcelCell}
                    />
                );
            }}
        </Filter>
    );
};
