import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AutocompleteMUI from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField } from 'formik-mui';
import { Formik, Form, ErrorMessage, FieldArray } from 'formik';
import { AutoComplete, Field } from '../../components/form';
import { map, get, curry, pipe, set, find, groupBy, values, filter, flatten } from 'lodash/fp';
import { useStyles } from '../../components/form/components';
import { useTheme } from '@mui/styles';
import { mapWithKey, pLog } from 'global-shared/utils/utils';
import { object, array, number } from 'yup';
import { useApolloClient } from '@apollo/client';
import { deleteQuery } from '../../gqlhelpers/queryHelpers';
import { cleanTimesheet, mutateTimesheet, timeEntryToInputObject } from './crudz';

const validationSchemaForPrincipal = object({
    principal: object().required('Select someone tp pay!').nullable(),
    items: array()
        .of(
            object({
                Hours: number().typeError('0-24 hours!').required('0-24 hours!').min(0).max(24)
            })
        )
        .min(1)
});

const validationSchema = object({
    items: array().of(
        object({
            Hours: number().typeError('0-24 hours!').required('0-24 hours!').min(0).max(24)
        })
    )
});

export const Detail = ({ state, left, data, hide, setSheet }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const row = get('values.row', state);
    const column = get('values.column', state);

    const principals = get('values.principals', state);
    const model = get('values.value.0', state);
    const makeModel = get('values.modelFn', state);

    const client = useApolloClient();

    const initialValues = principals
        ? { items: [makeModel(model, row, column)], principal: null }
        : { items: get('values.value', state), principal: null };
    const originalValues = get('values.value', state);
    const hasLiveValues = find((_in) => _in.Id !== '0', originalValues);

    const replaceResource = curry((doAll, n, o) => {
        const codeCode = get('0.CostCodes.0.Id', n);
        const resource = get('0.Party', o);
        const voidedItems = filter('IsVoid', o);
        const selectedResource = get('0.Party.Id', n);
        if (resource.Id === selectedResource) return [];
        if (voidedItems.length > 0) return voidedItems;
        const otherCostCode = filter((_in) => get('CostCodes.0.Id', _in) !== codeCode, o);
        const newItems = map(set('Id', '0'), map(set('Party', resource), n));
        if (!doAll) return [...otherCostCode, ...newItems];
        return newItems;
    });

    const applyToSheet = curry((doAll, items) => {
        //console.log('^^^^^', data.sheet)
        const selectedResource = get('0.Party.Id', items);
        const deletedItems = filter((_in) => _in.Party.Id !== selectedResource && !_in.IsVoid, data.sheet.Entries);
        if (deletedItems.length > 0) deleteQuery(client, 'CxTimeEntry', null, map(get('Id'), deletedItems));

        const byResource = groupBy('Party.Id', data.sheet.allEntries);
        const newEntries = map(replaceResource(doAll, items), values(byResource));
        pipe(
            map(timeEntryToInputObject),
            pLog,
            map(set('CurrentLifespan', data.sheet.CurrentLifespan)),
            cleanTimesheet(false, data.sheet),
            //pLog,
            mutateTimesheet(client, setSheet)
        )([...items, ...flatten(newEntries)]);
        hide();
    });
    return state.visible
        ? ReactDOM.createPortal(
              <React.Fragment>
                  <Formik
                      initialValues={initialValues}
                      validationSchema={principals ? validationSchemaForPrincipal : validationSchema}
                      onSubmit={(_in, { setSubmitting }) => {
                          setSubmitting(false);
                          const items = _in.principal
                              ? map(set('Party', get('principal', _in)), get('items', _in))
                              : get('items', _in);
                          state.fn({ originalValues, items });
                      }}
                  >
                      {({ setFieldValue, handleSubmit, values }) => (
                          <Form>
                              <Dialog
                                  open={true}
                                  maxWidth="sm"
                                  fullWidth={true}
                                  onClose={() => state.fn(null)}
                                  aria-describedby="alert-dialog-description"
                              >
                                  {hasLiveValues && (
                                      <DialogActions>
                                          {!principals && get('items.0.Party.DisplayName', values)}
                                          <div style={{ flex: '1 0 0' }} />

                                          {/*
                                          <Button
                                              onClick={() => applyToSheet(false, get('items', values))}
                                              color="secondary"
                                          >
                                              Apply to Cost Code
                                          </Button>
                                  <Button
                                              onClick={() => applyToSheet(true, get('items', values))}
                                              color="secondary"
                                          >
                                              Apply to Sheet
                                          </Button>
                                          <div style={{ flex: '1 0 0' }} />*/}
                                      </DialogActions>
                                  )}
                                  <DialogContent>
                                      {principals && (
                                          <AutocompleteMUI
                                              id="principal.Id"
                                              name="principal"
                                              options={principals}
                                              getOptionLabel={get('DisplayName')}
                                              onChange={(e, value) => {
                                                  setFieldValue('principal', value);
                                              }}
                                              renderInput={(params) => (
                                                  <Field
                                                      component={TextField}
                                                      {...params}
                                                      name="principal"
                                                      label="Principal"
                                                      variant="outlined"
                                                      fullWidth
                                                  />
                                              )}
                                          />
                                      )}
                                      <FieldArray
                                          name="items"
                                          render={(arrayHelpers) => (
                                              <React.Fragment>
                                                  {mapWithKey(
                                                      (each, index) => (
                                                          <div key={index}>
                                                              <Field
                                                                  type="number"
                                                                  label="Hours"
                                                                  name={`items.${index}.Hours`}
                                                                  component={TextField}
                                                                  className={classes.megaShortTextField}
                                                              />
                                                              <ErrorMessage
                                                                  name={`items.${index}.Hours`}
                                                                  component="div"
                                                              />

                                                              <Field
                                                                  type="text"
                                                                  label="Activity"
                                                                  name={`items.${index}.JobActivity.Id`}
                                                                  className={classes.shortSelectField}
                                                                  component={AutoComplete}
                                                                  options={get('sheet.Job.JobActivities', data)}
                                                                  optionLabelProperty="DisplayName"
                                                                  optionIdProperty="Id"
                                                              />
                                                              <Field
                                                                  type="text"
                                                                  label="Cost Code"
                                                                  name={`items.${index}.JobCosts[0].Id`}
                                                                  className={classes.shortSelectField}
                                                                  component={AutoComplete}
                                                                  options={[
                                                                      { Id: -1, Name: 'none' },
                                                                      ...data.sheet.Job.JobCosts
                                                                  ]}
                                                                  optionLabelProperty="Name"
                                                                  optionIdProperty="Id"
                                                              />
                                                              <ErrorMessage name="JobCost.Id" component="div" />
                                                              <Field
                                                                  type="text"
                                                                  label="Entry Type"
                                                                  name={`items.${index}.EntryType.Id`}
                                                                  className={classes.shortSelectField}
                                                                  component={AutoComplete}
                                                                  options={data.entryTypes}
                                                                  optionLabelProperty="Name"
                                                                  optionIdProperty="Id"
                                                              />
                                                              <ErrorMessage name="EntryType.Id" component="div" />

                                                              <IconButton
                                                                  aria-label="delete"
                                                                  size="medium"
                                                                  onClick={() => arrayHelpers.remove(index)}
                                                              >
                                                                  <DeleteIcon fontSize="inherit" />
                                                              </IconButton>
                                                          </div>
                                                      ),
                                                      get('items', values)
                                                  )}
                                                  <IconButton
                                                      aria-label="Add"
                                                      size="medium"
                                                      onClick={() => arrayHelpers.push(makeModel(model, row, column))}
                                                  >
                                                      <AddIcon fontSize="inherit" />
                                                  </IconButton>
                                              </React.Fragment>
                                          )}
                                      />
                                  </DialogContent>
                                  <DialogActions>
                                      <Button
                                          onClick={() => {
                                              hide(() => {});
                                          }}
                                          color="primary"
                                      >
                                          Cancel
                                      </Button>
                                      <Button onClick={handleSubmit} color="primary">
                                          {left || 'Yes'}
                                      </Button>
                                  </DialogActions>
                              </Dialog>
                          </Form>
                      )}
                  </Formik>
              </React.Fragment>,
              document.body
          )
        : null;
};
