import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Field } from 'client-shared/components/form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import {
    CurrentLifespanTimeEditor,
    currentLifespanTimeValidationSchema,
    AutoComplete
} from 'client-shared/components/form';
import { array, number, object } from 'yup';
import { CxResourcesQuery, CxAuxiliariesQuery } from './queries.js';
import { format, addDays } from 'date-fns/fp';
import { flow, get } from 'lodash/fp';
import { parseDate } from 'client-shared/utility';
import { Section } from 'client-shared/components/Section';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { TextField } from 'formik-mui';

export const resourceSpansValidationSchema = array()
    .when('ResourceQuantitySpans', (ResourceQuantitySpans, schema) => {
        return ResourceQuantitySpans.length
            ? schema.min(0)
            : schema.min(1, 'At Least One Resource or Resource Subtypes Quantity Is Required');
    })
    .of(
        object({
            Principal: object({
                Id: number().moreThan(0, 'Required')
            })
                .nullable()
                .required('Required'),
            Range: currentLifespanTimeValidationSchema,
            Amount: number().min(0.0001, 'Must be greater than 0').max(999999999, 'Must be less than 999,999,999')
        })
    );

export const ResourceSpansEditor = ({ form, field, classes, label = '', ...other }) => {
    const fieldError = Array.isArray(get(field.name, form.errors)) ? '' : get(field.name, form.errors);
    const showError = get(field.name, form.touched) && !!fieldError;
    const model = {
        // ResourceId: 0,
        Principal: { Id: 0, _DisplayName: '', _ObjectType: undefined, _HasAuxiliaries: undefined },
        Range: {
            Start: format("yyyy-MM-dd'T'HH:mm", parseDate(form.values.CurrentLifespan.Start)),
            End: format("yyyy-MM-dd'T'HH:mm", parseDate(form.values.CurrentLifespan.End))
        },
        Amount: 1,
        Auxiliaries: []
    };
    return (
        <Section title="Resources">
            <FormControl error={showError}>
                <FieldArray
                    name={field.name}
                    render={(arrayHelpers) => {
                        return (
                            <React.Fragment>
                                {field.value.map((value, index) => {
                                    return (
                                        <div key={index}>
                                            <Field
                                                type="text"
                                                label="Resource"
                                                name={`${field.name}.${index}.Principal`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                optionLabelProperty="_DisplayName"
                                                optionsQuery={CxResourcesQuery}
                                                groupBy={(option) => option._ObjectType}
                                                queryVariables={() => ({
                                                    start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                                                    end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date()),
                                                    objectType: ['Employee', 'Equipment', 'Material', 'Crew']
                                                })}
                                            />
                                            <Field
                                                type="text"
                                                name={`${field.name}.${index}.Range`}
                                                component={CurrentLifespanTimeEditor}
                                                classes={classes}
                                            />
                                            <Field
                                                type="number"
                                                label="Amount"
                                                name={`${field.name}.${index}.Amount`}
                                                component={TextField}
                                                className={classes.extraShortTextField}
                                                inputProps={{ min: 0, max: 1, step: 0.25 }}
                                            />
                                            <Field
                                                type="text"
                                                label="Auxiliary Resources"
                                                name={`${field.name}.${index}.Auxiliaries`}
                                                component={AutoComplete}
                                                className={classes.selectField}
                                                optionLabelProperty="_DisplayName"
                                                optionsQuery={CxAuxiliariesQuery}
                                                queryVariables={(values) => ({
                                                    start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                                                    end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date()),
                                                    objectType: get('Principals[0]._HasAuxiliaries', values)
                                                        ? []
                                                        : ['Employee', 'Equipment'],
                                                    id: get('Principals[0]._HasAuxiliaries', values)
                                                        ? [value.Principals[0].Id]
                                                        : []
                                                })}
                                                multiple
                                                getOptions={(options) => {
                                                    if (!options.length) {
                                                        return [];
                                                    }
                                                    if (get('Principals[0]._HasAuxiliaries', value)) {
                                                        return options[0]._Auxiliaries;
                                                    }
                                                    return options;
                                                }}
                                                initialize={false}
                                                allOptions={false}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                    form.setFieldTouched(field.name, true);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            arrayHelpers.push(model);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
                <FormHelperText>{fieldError}</FormHelperText>
            </FormControl>
        </Section>
    );
};

ResourceSpansEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string
};
