import React, { Suspense, useContext, useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Auth from '@aws-amplify/auth';
import { get, pipe, split, filter, join, take } from 'lodash/fp';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { UserContext, UxContext } from 'client-shared/utility';
import { MainMenu } from './routes';
import { SmsNotifier } from 'client-shared/components/sms/smsNotifier';
import { Chat } from 'client-shared/components/sms/chat';
import { Attachments } from 'client-shared/components/attachments/attachments';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import Image from 'images/controlboard_transparent.png';
import { Outlet } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        height: 44,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    toolbar: {
        minHeight: 36
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: -drawerWidth
    },
    appBarBottom: {
        top: 'auto',
        bottom: 0
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    grow: {
        flexGrow: 1
    },
    menuItem: {
        width: '200px'
    },
    menuContainer: {
        transitionDuration: '0.3s'
    }
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

function Workspace(props) {
    const { history } = props;
    const location = useLocation();
    const theme = useTheme();
    const classes = useStyles(theme);
    const [open, setOpen] = useState(false);
    const [anchorEl] = useState(null);
    const [chatState, setChatState] = useState({
        visible: false,
        interlocutor: null,
        smsNumber: null,
        text: ''
    });

    const [user] = useContext(UserContext);

    /**
     *  visible: hides and shows the chat window
     *  interlocutor: an object representing the other end of the conversation that looks like
     *  {
     *      number: sms number in the form 12223334444
     *      label: some label to display
     *  }
     *  text: initial text to display in the input field
     **/
    const toggleChat = (visible, smsNumber, interlocutor, text) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setChatState({ ...chatState, visible, smsNumber, interlocutor, text });
    };

    const toggleMenu = (visible) => (event) => setOpen(visible);

    function handleProfileMenuOpen(event) {
        sessionStorage.removeItem('impersonating');
        Auth.signOut();
    }

    const isMenuOpen = Boolean(anchorEl);

    const path = pipe(
        split('/'),
        filter((each) => each !== ''),
        filter((each) => isNaN(each)),
        (_in) => _in.map((each, i, o) => [each, '/' + join('/', take(i + 1, o))])
    )(location.pathname);
    // console.log(user);
    return (
        <UxContext.Provider value={[toggleChat, toggleMenu]}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: open
                    })}
                >
                    <Toolbar disableGutters={!open} variant="dense">
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={
                                //get('permissions.hideMainMenu', user) ? (_) => history.push('/') : toggleMenu(true)
                                toggleMenu(true)
                            }
                            className={classNames(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>

                        {/*<img src={Image} style={{ width: '12vw', height: 'auto', 'margin-top': '10px' }} />*/}
                        {/*<img src={Image2} style={{ width: '12vw', height: 'auto', 'margin-top': '10px' }} />*/}
                        {/*<img src={Image3} style={{ width: '12vw', height: 'auto', 'margin-top': '10px' }} />*/}
                        <Typography variant="h6" color="inherit" style={{ paddingTop: '10px' }}>
                            <img alt="" src={Image} style={{ width: '160px' }} />
                        </Typography>
                        <SmsNotifier />
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <IconButton
                                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <Avatar
                                    alt={get('username', user)}
                                    src={get('cognito.attributes.picture', user)}
                                    sx={{ width: 34, height: 34 }}
                                />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>

                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={toggleMenu(false)}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <MainMenu {...{ history }} />
                </Drawer>
                <main
                    className={classNames(classes.content, {
                        [classes.contentShift]: open
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <Breadcrumbs
                        separator="›"
                        arial-label="crumb"
                        sx={{
                            'margin-top': -38,
                            'margin-left': 15
                        }}
                    >
                        <LinkRouter color="inherit" to="/" arial-label="home">
                            Home
                        </LinkRouter>
                        {path.map((each, index) => {
                            const last = index === path.length - 1;
                            return last ? (
                                <Typography color="text.primary" key={each[0]} arial-label={each[0]}>
                                    {each[0]}
                                </Typography>
                            ) : (
                                <LinkRouter color="inherit" to={each[1]} key={each[0]} arial-label={each[0]}>
                                    {each[0]}
                                </LinkRouter>
                            );
                        })}
                    </Breadcrumbs>
                    <Suspense
                        fallback={
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                        }
                    >
                        <Outlet />
                        {/*<AppRoutes  />*/}
                    </Suspense>
                </main>
                <Chat {...{ chatState, toggleChat }} />
                <Attachments />
            </div>
        </UxContext.Provider>
    );
}

export default Workspace;
