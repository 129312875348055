import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { TextField } from 'formik-mui';
import { TextField as TextFieldMUI } from '@mui/material';
import { Field, AutoComplete, UnitCostEditor, unitCostValidationSchema } from 'client-shared/components/form';
import { array, number, object } from 'yup';
import { Section } from 'client-shared/components/Section';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { gql } from 'graphql-tag';
import { get } from 'lodash/fp';

const model = {
    Id: 0,
    Name: '',
    UnitCost: {
        Cost: {
            Unit: {
                Id: 12,
                DisplayName: 'dollar'
            },
            Quantity: 0
        },
        Unit: {
            Id: 0,
            DisplayName: ''
        }
    },
    CostCode: {
        Id: 0,
        DisplayName: ''
    },
    TotalAmount: 0,
    ExpectedAmount: 0,
    Cost: {
        Unit: {
            Id: 12,
            DisplayName: 'dollar'
        },
        Quantity: 0
    },
    Description: ''
};

export const jobCostsValidationSchema = array().of(
    object({
        CostCode: object({
            Id: number().moreThan(0, 'Required')
        })
            .nullable()
            .required('Required'),
        UnitCost: unitCostValidationSchema
    })
);

export const JobCostsEditor = ({ form, field, classes, label = '', ...other }) => {
    return (
        <Section title="Job Costs">
            <FieldArray
                name={field.name}
                render={(arrayHelpers) => {
                    return (
                        <React.Fragment>
                            {field.value.map((jobCost, index) => {
                                return (
                                    <div key={index}>
                                        <Field
                                            type="text"
                                            label="Cost Code"
                                            name={`${field.name}.${index}.CostCode`}
                                            component={AutoComplete}
                                            className={classes.selectField}
                                            optionLabelProperty="DisplayName"
                                            optionsQuery={gql`
                                                query ($searchString: String) {
                                                    cxCostCodes(
                                                        objectType: "CostCode"
                                                        filters: [
                                                            { name: "String", values: ["DisplayName", $searchString] }
                                                        ]
                                                    ) {
                                                        Id
                                                        Name
                                                        DisplayName
                                                        Description
                                                        UnitCost {
                                                            Unit {
                                                                Id
                                                                DisplayName
                                                            }
                                                            Cost {
                                                                Unit {
                                                                    Id
                                                                    DisplayName
                                                                }
                                                                Quantity
                                                            }
                                                        }
                                                    }
                                                }
                                            `}
                                            onChange={(event, value) => {
                                                if (!value) {
                                                    return;
                                                }
                                                form.setFieldValue(`${field.name}.${index}.Name`, get('Name', value));
                                                form.setFieldValue(
                                                    `${field.name}.${index}.Description`,
                                                    get('Description', value)
                                                );
                                                form.setFieldValue(
                                                    `${field.name}.${index}.UnitCost`,
                                                    get('UnitCost', value)
                                                );
                                            }}
                                            disableClearable
                                        />
                                        <Field
                                            type="text"
                                            label="Name"
                                            name={`${field.name}.${index}.Name`}
                                            component={TextField}
                                            className={classes.textField}
                                        />
                                        <Field
                                            type="text"
                                            label="Description"
                                            name={`${field.name}.${index}.Description`}
                                            component={TextField}
                                            className={classes.textField}
                                        />
                                        <Field
                                            type="text"
                                            label="Unit Cost"
                                            name={`${field.name}.${index}.UnitCost`}
                                            component={UnitCostEditor}
                                            classes={classes}
                                            displayName={'DisplayName'}
                                        />
                                        {/*<Field*/}
                                        {/*    type="number"*/}
                                        {/*    label="Budgeted $"*/}
                                        {/*    name={`${field.name}.${index}.Cost.Quantity`}*/}
                                        {/*    component={TextField}*/}
                                        {/*    className={classes.shortTextField}*/}
                                        {/*/>*/}
                                        {/*<Field*/}
                                        {/*    type="number"*/}
                                        {/*    label="Cost Per Unit $"*/}
                                        {/*    name={`${field.name}.${index}.UnitCost.Quantity`}*/}
                                        {/*    component={TextField}*/}
                                        {/*    className={classes.shortTextField}*/}
                                        {/*/>*/}
                                        <Field
                                            type="number"
                                            label="Total Quantity"
                                            name={`${field.name}.${index}.TotalAmount`}
                                            component={TextField}
                                            className={classes.shortTextField}
                                        />
                                        <TextFieldMUI
                                            type="number"
                                            label="Total Cost $"
                                            value={jobCost.UnitCost.Cost.Quantity * jobCost.TotalAmount}
                                            className={classes.shortTextField}
                                            disabled={true}
                                        />
                                        <IconButton
                                            onClick={() => {
                                                arrayHelpers.remove(index);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                );
                            })}
                            <IconButton
                                onClick={() => {
                                    arrayHelpers.push(model);
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </React.Fragment>
                    );
                }}
            />
        </Section>
    );
};

JobCostsEditor.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string
};
