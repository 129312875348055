import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Filter } from './Filter';
import { useApolloClient } from '@apollo/client';
import { evalQuery } from 'client-shared/gqlhelpers/queryHelpers';
import { first, flatMap, get, getOr, last, map, reduce, sortBy, split, keyBy, curry } from 'lodash/fp';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

import { mapWithKey, pLog, sortByArray } from 'global-shared/utils/utils';
import Scheduler, {
    entityToEvent,
    getCalendarDays,
    getConfig,
    mapActivity,
    prepareTimelineDate,
    spanToEvent
} from 'client-shared/components/timeline';
import {
    AllocationEventQuery,
    extractLocalConfigs,
    getRangeStartEnd,
    useCollectionSubscription,
    usePrivilege,
    UserContext
} from 'client-shared/utility';
import { AllocationsQuery } from './queries.js';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import uniq from 'lodash/fp/uniq';
import { withEditor } from 'client-shared/entities/withEditor';
import { getShift } from 'client-shared/utility/authHelpers';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const Index = () => {
    const [user] = useContext(UserContext);
    //const CONFIG_event_template = get(['congistics', 'configValues', 'plan.allocationTimeline.event_template'], user);
    return (
        <Filter>
            {({ values: variables, data: jobs }) => {
                const { end } = getRangeStartEnd(variables._start, variables._periods, {
                    interval: variables._interval
                });
                variables.start = variables._start;
                variables.end = end;
                variables._periods = getCalendarDays(variables);
                variables._x_unit = variables._interval.toLowerCase().slice(0, -1);
                const config = getConfig(extractLocalConfigs(variables), {
                    x_size: variables._periods,
                    y_property: 'section_id',
                    scrollable: variables._scrollable
                    //readonly: !variables._showResourceSpans
                });
                return <InnerScheduler {...{ config, variables, jobs }} />;
            }}
        </Filter>
    );
};
export default Index;

const InnerScheduler_ = (props) => {
    const { config, variables, jobs, onOpenEditor } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const { data: allocations, event } = useCollectionSubscription({
        dataQuery: AllocationsQuery,
        eventQueries: [AllocationEventQuery],
        values: { start: variables.start, end: variables.end }
    });
    console.log(variables);
    const mapFn = get('_showResourceSpans', variables) ? rangeToEvent : allocationToEvent;
    const [privilege] = usePrivilege('Allocation');

    const CONFIG_show_resourceName = getOr(
        false,
        ['congistics', 'configValues', 'plan.allocationTimeline.show_resourceName'],
        user
    );

    const sortedAllocations = sortBy('CurrentLifespan.Start', allocations);
    const jobOrder = uniq(map('Job.Id', sortedAllocations));

    const orderedJobs = variables._orderByAllocations ? sortByArray(jobs, jobOrder, get('Id')) : jobs;
    const jobMap = keyBy('Id', jobs);
    const activityMap = keyBy('Id', flatMap(get('JobActivities'), jobs));
    const _config = { ...config, ...{ y_unit: map(mapToTop, orderedJobs) } };

    function editCallback(e, more) {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: [first(split('_', e))] }]
            }
        });
    }

    const openEditor = (e) => {
        onOpenEditor('EditCxAllocation', {
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    };

    async function onEmptyClick(date, e) {
        const el = e.target.closest('.dhx_matrix_line');
        const [jobId, activityId] = split('_', get('dataset.sectionId', el));
        if (!jobId) return;
        const [Start, End] = getShift(date, user);
        onOpenEditor('EditCxAllocation', {
            initialValues: {
                CurrentLifespan: {
                    Start,
                    End
                },
                Job: {
                    Id: jobId,
                    _DisplayName: jobMap[jobId]['DisplayName']
                },
                JobActivity: {
                    Id: activityId,
                    _DisplayName: activityMap[activityId]['DisplayName']
                }
            },
            formQueryValues: {
                filters: [{ name: 'Id', values: ['0'] }]
            }
        });
    }

    async function onEventChanged(id, event) {
        const { items, methodsByItem } = eventToMutation(event);
        await evalQuery(client, null, 'CxAllocation', items, methodsByItem).catch(() => {});
    }

    function onContextMenu(e) {
        // scheduler.current.get().closeAllSections();
    }

    const schedulerEvents = {
        onEventChanged,
        onContextMenu,
        onEventDeleted: pLog,
        onBeforeEventChanged: (ev, e, is_new, original) => !privilege && !get('_showResourceSpans', variables),
        onEmptyClick
    };

    const schedulerProps = {
        editCallback,
        drag: 'target',
        master: true,
        event
    };

    const rangeToEvent = curry((ctx, _in) => {
        return mergeSpans(
            mapWithKey((each, idx) => {
                return {
                    ...spanToEvent(get('Range', each)),
                    allocationId: _in.Id,
                    JobActivityId: get('JobActivity.Id', _in),
                    id: `${_in.Id}_${each.Principal.Id}_${idx}`,
                    resourceId: each.Principal.Id,
                    originalResourceId: each.Principal.Id,
                    section_id: `${get('Job.Id', _in)}_${get('JobActivity.Id', _in)}`,
                    text: CONFIG_show_resourceName ? each.Principal.DisplayName : _in.DisplayName
                };
            }, _in.ResourceSpans)
        );
    });

    if (jobs.length < 1) return <p>No jobs match your filter settings...</p>;
    return (
        <Box className={classes.root} pb={5}>
            <Scheduler
                height="calc(100vh - 225px)"
                width="100%"
                startDate={get('start', variables)}
                treeView={true}
                title="Allocation Timeline"
                data={flatMap(mapFn(variables), sortedAllocations)}
                timelineConfig={_config}
                {...schedulerProps}
                {...schedulerEvents}
            />
            <Fab color="primary" aria-label="add" style={{ float: 'right', marginRight: 10 }} onClick={openEditor}>
                <AddIcon />
            </Fab>
        </Box>
    );
};

const InnerScheduler = withEditor(InnerScheduler_);

export const allocationToEvent = curry((ctx, _in) => {
    return {
        text: get('DisplayName', _in),
        JobActivityId: get('JobActivity.Id', _in),
        section_id: `${get('Job.Id', _in)}_${get('JobActivity.Id', _in)}`,
        original_allocation: `${get('Job.Id', _in)}_${get('JobActivity.Id', _in)}`,
        totalHours: ctx._mergedHours ? get('TotalMergedHours', _in) : get('TotalHours', _in),
        //color: get('JobActivity.ForeColor', _in),
        ...entityToEvent(_in)
    };
});

const mergeSpans = (_in) =>
    reduce(
        (accum, each) => {
            const nth = last(accum);
            if (differenceInCalendarDays(each.start_date, get('end_date', nth)) < 1) nth.end_date = each.end_date;
            else accum.push(each);
            return accum;
        },
        [],
        _in
    );

export const mapToTop = (_in) => {
    const children = map(mapActivity(get('Id', _in)), get('JobActivities', _in));
    return {
        key: get('Id', _in),
        label: get('DisplayName', _in),
        open: true,
        tooltip: `${get('DisplayName', _in)} 
        ${get('Description', _in)}`,
        children
    };
};

export const eventToMutation = (event) => {
    const [jobId, activityId] = event.section_id.split('_');
    const _allocation = { Id: event.id, JobActivity: { Id: activityId }, Job: { Id: jobId } };
    return {
        methodsByItem: [
            {
                indices: [0],
                methods: [{ name: 'setRange', args: prepareTimelineDate(event) }]
            }
        ],
        items: [_allocation]
    };
};
