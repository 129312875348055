/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { EditingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableEditRow, TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import Paper from '@mui/material/Paper';
import { TextEditorProvider } from 'client-shared/components/crudgrid';
import { getK, putKkv, deleteKkv } from 'client-shared/utility';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { useNotifications } from 'client-shared/components/notifier2';

const filterFormDefinition = {
    title: 'Kkv List',
    dataQuery: '',
    divisionFilter: false,
    enableShowAll: false,
    fieldDefinitions: [
        {
            type: 'text',
            label: 'Enter first few characters of user id',
            name: 'k',
            component: 'TextField',
            className: 'textField'
        }
    ]
};

const columns = [
    {
        name: 'k',
        title: 'K'
    },
    { name: 'kk', title: 'KK' },
    { name: 'data', title: 'Data' }
];

const tableColumnExtensions = [
    { columnName: 'k', width: '15%' },
    { columnName: 'kk', width: '20%' },
    { columnName: 'data', width: 'auto', wordWrapEnabled: true }
];

const Kkvs = ({ k }) => {
    const client = useApolloClient();
    const [rows, setRows] = useState([]);
    const { openSBTransient } = useNotifications();

    /*eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        getK(client, `${k}%`).then(({ kkvFetch }) => {
            setRows(kkvFetch.map((kkv, index) => ({ ...kkv, id: index, data: JSON.stringify(kkv.data) })));
        });
    }, [k]);

    const commitChanges = ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
            const addedRows = added.map((row, index) => ({
                id: startingAddedId + index,
                ...row
            }));
            changedRows = [...rows, ...addedRows];
            handleSave(addedRows);
        }
        if (changed) {
            changedRows = rows.map((row) => {
                return changed[row.id] ? { ...row, ...changed[row.id] } : row;
            });
            const ids = Object.keys(changed);
            handleSave(changedRows.filter((changedRow) => ids.indexOf(changedRow.id.toString()) !== -1));
        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            changedRows = rows.filter((row) => !deletedSet.has(row.id));
            handleDelete(rows.filter((row) => deletedSet.has(row.id)));
        }
        setRows(changedRows);
    };

    const handleSave = (rows) => {
        rows.forEach((row) => {
            putKkv(client, { ...row, data: JSON.parse(row.data) })
                .then(() => {
                    openSBTransient('Kkvs list saved', { variant: 'success' });
                })
                .catch((error) => console.log(error));
        });
    };

    const handleDelete = (rows) => {
        rows.forEach((row) => {
            deleteKkv(client, row.k, row.kk)
                .then(() => {
                    openSBTransient('Kkvs list deleted', { variant: 'success' });
                })
                .catch((error) => console.log(error));
        });
    };

    const getRowId = (row) => row.id;
    return (
        <Paper>
            <Grid rows={rows} columns={columns} getRowId={getRowId}>
                <TextEditorProvider for={['message']} />
                <EditingState onCommitChanges={commitChanges} />
                <Table columnExtensions={tableColumnExtensions} />
                <TableHeaderRow />
                <TableEditRow />
                <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
            </Grid>
        </Paper>
    );
};

Kkvs.propTypes = {
    kkvs: PropTypes.array.isRequired
};

export const EditKkvList = () => {
    if (!filterFormDefinition) return null;
    return (
        <FilterFormFromJson
            filterFormDefinition={{
                ...filterFormDefinition,
                initialValues: {}
            }}
        >
            {({ values }) => {
                return <Kkvs k={values.k ? values.k : 'abc'} />;
            }}
        </FilterFormFromJson>
    );
};
