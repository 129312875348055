import React from 'react';
import PropTypes from 'prop-types';
import { Filter } from './Filter';
import { get, flow, /* sortBy,*/ uniqBy, flatMap, omit, filter, find } from 'lodash/fp';
import { gridHeaderItemStyle, gridItemStyle } from '../styles';
import { JobName } from 'client-shared/components/serverobjects/JobName';
import { ResourceName } from 'client-shared/components/serverobjects/ResourceName';
import { Description } from 'client-shared/components/serverobjects/Description';
import { Report } from 'client-shared/components/Report';
import { getRangeRows, GridDashBoard } from '../dashboardhelpers';
import { parseDate, reportExcel } from 'client-shared/utility';
import { GroupBy } from 'client-shared/components/GroupBy';
import { isSameDay } from 'date-fns/fp';

const map = require('lodash/fp/map').convert({ cap: false });
const gridRowHeaderItem = gridHeaderItemStyle({ textAlign: 'left', whiteSpace: 'pre-line' });
const gridItem = gridItemStyle();

const groupByResource = {
    name: 'data.DisplayName'
};

const groupByJob = {
    job: 'Allocation.Job.DisplayName'
};

/**
 * populate each cell of the spreadsheet.
 * @param values
 * @param allocations
 * @returns {(function(*, *, *, *): (*|undefined))|*}
 */
const addExcelCell = (values, reportIntervals) => {
    return ({ row, rowNumber, column, colNumber, colName, cell }) => {
        cell.alignment = { wrapText: true };
        if (row.headerRow) {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DCDCDC' } };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: {
                    style: 'thin'
                }
            };
            cell.value = get('title', column);
            if (isNaN(new Date(colName))) {
                return;
            }
            const reportInterval = find(
                (reportInterval) => isSameDay(parseDate(reportInterval.Range.Start), parseDate(colName)),
                reportIntervals
            );
            cell.value = {
                richText: [
                    {
                        font: { bold: true },
                        text: `${colName}\n`
                    },
                    ...reportExcel({
                        reportProperties: values._reportIntervalProperties,
                        reportObject: get('Report', reportInterval),
                        unit: { Name: 'Hrs', Scalar: 60 },
                        label: values._label
                    })
                ]
            };
            return;
        }
        let color = get('items.0.data.ForeColor', row).slice(1);
        let backColor = get('items.0.data.BackColor', row).slice(1);
        if (colName === 'DisplayName') {
            cell.font = { color: { argb: color } };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
            cell.border = {
                top: { style: 'medium' },
                left: { style: 'medium' },
                bottom: { style: 'medium' },
                right: { style: 'medium' }
            };
            cell.value = get('items.0.data.DisplayName', row);
            return;
        }
        if (isNaN(new Date(colName))) {
            return;
        }
        const intervals = row.intervalData.get(colName);
        if (values._summary) {
            const filteredIntervals = filter((interval) => interval.__typename === 'CxReportInterval', intervals);
            cell.value = {
                richText: reportExcel({
                    reportProperties: values._reportProperties,
                    reportObject: get('0.Report', filteredIntervals),
                    unit: { Name: 'Hrs', Scalar: 60 },
                    label: values._label
                })
            };
            return;
        }
        const filteredIntervals = filter((interval) => interval.__typename === 'CxTaskDay', intervals);
        if (!filteredIntervals.length) {
            return;
        }
        // cell.value = {
        //     richText: taskDaysByJobExcel({
        //         taskDays: map((filteredInterval) => filteredInterval.taskDay, filteredIntervals)
        //     })
        // };
        cell.value = {
            richText: flow(
                map((filteredInterval) => filteredInterval.taskDay),
                uniqBy('Allocation.Job.DisplayName'),
                map((taskDay) => {
                    const color = get('Allocation.Job.ForeColor', taskDay).slice(1);
                    return {
                        font: { color: { argb: color } },
                        text: `${get('Allocation.Job.DisplayName', taskDay)} ${
                            get('Auxiliaries.0.DisplayName', taskDay) || ''
                        }\n`
                    };
                })
            )(filteredIntervals)
        };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: backColor } };
        cell.border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' }
        };
    };
};

/**
 * called by the GridDashBoard to render detail rows.
 * @param rangeRow - an object that contains the data for the row in the property, intervalData.
 * see dashBoardhelpers.js for details.
 * @param values - user entered values from the filter bar.
 * @returns {JSX.Element}
 * @constructor
 */
const row =
    (values) =>
    ({ rangeRow }) => {
        if (values._totalOnly) {
            return null;
        }
        const row = map((date, index) => {
            return <Cell key={index} values={values} intervals={rangeRow.intervalData.get(date)} />;
        }, rangeRow.getRange());
        return (
            <React.Fragment>
                <div style={gridRowHeaderItem}>
                    <JobName job={get(`items.0.data`, rangeRow)} />
                </div>
                {row}
            </React.Fragment>
        );
    };

/**
 * called by Row to render a cell for a day, week, month, or other period.
 * @param taskDays - all taskDays for the row and period.
 * @param values - user entered values from the filter bar.
 * @parma displayKeys - summary properties to display
 * @returns {JSX.Element}
 * @constructor
 */
const Cell = ({ values, intervals }) => {
    const typeName = values._summary ? 'CxReportInterval' : 'CxTaskDay';
    const filteredIntervals = filter((interval) => interval.__typename === typeName, intervals);
    if (filteredIntervals.length === 0) {
        return <div style={gridItem} />;
    }
    if (values._summary) {
        return (
            <div style={gridItem}>
                <Report
                    reportProperties={values._reportProperties}
                    reportObject={get('0.Report', filteredIntervals)}
                    label={true}
                    unit={{ Name: 'Hrs', Scalar: 60 }}
                />
            </div>
        );
    }
    const taskDays = flow(
        map((filteredInterval) => filteredInterval.taskDay),
        uniqBy((taskDay) => `${taskDay.ActionType}${taskDay.DisplayName}`)
    )(filteredIntervals);
    return (
        <div style={gridItem}>
            <GroupBy items={taskDays} groupByCriteria={groupByJob}>
                {(taskDays, group, index) => {
                    return (
                        <div>
                            <JobName job={get('0.Allocation.Job', taskDays)} />{' '}
                            <ResourceName resource={get('0.Auxiliaries.0', taskDays)} />
                            <Description description={get('0.Allocation.Description', taskDays)} />
                        </div>
                    );
                }}
            </GroupBy>
        </div>
    );
};

Cell.propTypes = {
    values: PropTypes.object.isRequired,
    intervals: PropTypes.array.isRequired
};

const Grid = ({ values, data }) => {
    data = flatMap((resource) => {
        return map(
            (interval) => {
                if (interval.__typename === 'CxTaskDay') {
                    return {
                        data: omit(['ReportIntervals', 'TaskDays'], resource),
                        Range: interval.CurrentLifespan,
                        taskDay: omit(['CurrentLifespan'], interval),
                        __typename: interval.__typename
                    };
                }
                return {
                    data: omit(['ReportIntervals', 'TaskDays'], resource),
                    ...interval
                };
            },
            [...resource.ReportIntervals, ...resource.TaskDays]
        );
    }, data);
    const rangeRows = getRangeRows(
        {
            groupByCriteria: groupByResource,
            datePropertyName: 'Range.Start',
            start: values._start,
            periods: values._periods,
            interval: values._interval,
            skipDays: values._skipDays,
            stratify: values._stratify
        },
        data
    );
    return (
        <GridDashBoard
            values={values}
            rangeRows={rangeRows}
            periods={values._periods}
            interval={values._interval}
            skipDays={values._skipDays}
            stratify={values._stratify}
            orientation={values._orientation}
            row={row(values)}
            scheduledOnly={!values._summary}
            addExcelCell={addExcelCell}
        />
    );
};

export const ResourceGrid = () => {
    // example template literal with newline: ${DisplayName}${Manager.DisplayName}<%= '\n' %>${Address.Street}
    return (
        <Filter>
            {({ values, data }) => {
                data = map((resource) => {
                    resource.TaskDays = filter((taskDay) => {
                        if (!values._crewAllocations && taskDay.Allocation.ActualCrew) {
                            return false;
                        }
                        return true;
                    }, resource.TaskDays);
                    return resource;
                }, data);
                if (values._multipleAssignmentsOnly) {
                    data = filter(
                        (resource) => uniqBy((taskDay) => taskDay.Allocation.Job.Id, resource.TaskDays).length > 1,
                        data
                    );
                }
                return <Grid values={values} data={data} />;
            }}
        </Filter>
    );
};
