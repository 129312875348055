import { useState } from 'react';
import { curry, pipe } from 'lodash/fp';

/**
 * Common state logic for a form that blah blah blah
 * @returns {[*,toggle]}
 */
export const useImperative = () => {
    const [state, setState] = useState({ visible: false, fn: toggle, values: [] });

    /**
     * Pass in a handler function
     * @param fn
     */
    function toggle(fn, values) {
        setState(fixState(fn, values));
    }

    const fixState = curry((fn, values, current) => ({
        visible: !current.visible,
        fn: fn ? pipe(fn, toggle) : current.fn,
        values
    }));
    return [state, toggle];
};
