import React, { useRef, useEffect, useContext, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { get, map, find, isEmpty } from 'lodash/fp';
import ReactDOM from 'react-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { pFetch } from '../../utility/authHelpers';
import { handleFetchErrors, toBlob, toJson, mobilePart } from '../../utility/httpHelpers';
import { useNotifications } from '../notifier2';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useApolloClient } from '@apollo/client';
import { evalQuery, getGraphqlHelper, makeVerbOperation } from '../../gqlhelpers/queryHelpers';
import { UserContext } from '../../utility';
import { useAttachments } from '../attachments/useAttachments';

import SplitButton from '../SplitButton';
import { formatStandard } from '../../utility/dateutilities';

const useStyles = makeStyles((theme) => ({
    sm: { width: '50vw', height: '80vh' },
    lg: { width: '90vw', height: '100vh' },
    viewer: { width: '100%', height: '100%' }
}));

const getBaseDocument = (state) => {
    const attachment = get('values.attachment', state);
    const currentFormTemplate = get('values.formTemplate', state);
    if (!attachment) return get('Key', currentFormTemplate);
    return get('Key', attachment) || get('Key', currentFormTemplate);
};

export const FormEditor = ({ state }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const viewer = useRef(null);
    const nameField = useRef(null);
    const { openSBTransientP } = useNotifications();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const currentFormTemplate = get('values.formTemplate', state);
    const currentForm = get('oneForm', currentFormTemplate);
    const currentAttachment = get('values.attachment', state);
    const currentName = getCurrentName();
    const baseDocument = getBaseDocument(state);
    const client = useApolloClient();
    const [_addUpdateAttachment] = useAttachments(client, 'CxForm');
    const GQL = getGraphqlHelper('CxDocument');
    const [user] = useContext(UserContext);

    const [, setCompleteState] = useState(false);
    const [saveState, setSaveState] = useState(false);
    const [instance, setInstance] = useState(null);

    const currentParty = get('congistics.user.Party.Id', user);

    function getCurrentName() {
        if (currentAttachment) return currentAttachment.Name;
        if (currentForm) return currentForm.Name;
        if (currentFormTemplate) return currentFormTemplate.Name;
    }

    const toggleCompletion = async (newState) => {
        const [blob] = await extractFormBlob(instance);
        await replaceBlob(openSBTransientP, blob, baseDocument);
        const { methodsByItem, filters } = makeVerbOperation(newState, {
            ids: [currentAttachment.Id],
            args: { partyIds: [currentParty] },
            preserve: true
        });
        return evalQuery(client, 'Id', GQL.entityClass, [], methodsByItem, filters);
    };
    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        if (!currentFormTemplate && !currentAttachment) return;
        setSaveState(false);
        setCompleteState(false);
        loadData();
        async function loadData() {
            const formContents = await pFetch(`/api/file/${encodeURIComponent(baseDocument)}`)
                .then(handleFetchErrors)
                .then(toBlob);
            WebViewer(
                {
                    licenseKey:
                        'Congistics Corporation (congistics.com):OEM:ControlBoard::B+:AMS(20231204):C8A52C7D0427560AF360B13AC9A2537860617FE5B978BD3BED3C6B611A4C2ED66AFAB6F5C7',
                    path: `${mobilePart()}/webviewer/lib`
                },
                viewer.current
            ).then((instance) => {
                instance.UI.loadDocument(formContents, { filename: `${get('Name', currentFormTemplate)}.pdf` });
                const { disableFeatures, Feature, UI } = instance;
                setInstance(instance);
                /*     disableFeatures([Feature.Ribbons]);
                UI.setToolbarGroup('toolbarGroup-Insert');
                disableFeatures([Feature.FilePicker]);
                disableFeatures([Feature.Annotations]);
                disableFeatures([Feature.OutlineEditing]); */
                //disableFeatures([Feature.PageNavigation]);
                disableFeatures([Feature.Ribbons]);
                UI.setToolbarGroup('toolbarGroup-View');
                disableFeatures([Feature.NotesPanel]);
                disableFeatures([Feature.FilePicker]);
                disableFeatures([Feature.MultiTab]);
                UI.disableElements(['leftPanel', 'leftPanelButton']);
                UI.disableElements(['selectToolButton']);
                UI.disableElements(['searchButton']);
                if (fullScreen) UI.setZoomLevel(1.5);
                //instance.UI.disableElements(['menuButton']);

                UI.setHeaderItems(function (header) {
                    //header.delete(3);

                    header.push({
                        type: 'customElement',
                        render: () => <Slider label={currentName} myRef={nameField} />
                    });
                });
                setCompleteState(Boolean(currentAttachment));
                setSaveState(true);
            });
        }
    }, [state.values]);

    const persistPDF = async () => {
        const Name = nameField.current.value;
        const [blob, FileHash] = await extractFormBlob(instance);
        if (currentAttachment) {
            await replaceBlob(openSBTransientP, blob, baseDocument);
            await _addUpdateAttachment(currentForm.Id, [{ ...currentAttachment, FileHash, Name }]);
            return state.fn(['completed']);
        }
        const res = await uploadBlob(openSBTransientP, `${currentFormTemplate.Name}*`, blob, baseDocument);
        if (currentForm) {
            await _addUpdateAttachment(get('Id', currentForm), [{ Id: 0, ...res[0], FileHash, Name }]);
            state.fn(['completed']);
        } else
            state.fn([
                {
                    Id: '0',
                    Attachments: [{ Id: 0, ...res[0], FileHash, Name }],
                    Name: currentFormTemplate.Name,
                    TemplateForm: { Id: currentFormTemplate.Id }
                }
            ]);
    };

    const getApprovalChoices = () => {
        if (!currentAttachment) return [];
        return isApprovedByUser(currentParty, currentAttachment) ? ['Save and Unapprove'] : ['Save and Approve'];
    };

    //console.log('=========', currentAttachment);

    return state.visible
        ? ReactDOM.createPortal(
              <Dialog
                  open={true}
                  maxWidth="xl"
                  style={{ zIndex: '10002' }}
                  fullScreen={fullScreen}
                  //onClose={handleClose}
                  aria-describedby="alert-dialog-description"
              >
                  <DialogContent>
                      <div className={fullScreen ? classes.lg : classes.sm}>
                          <div className={classes.viewer} ref={viewer}></div>
                      </div>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={() => state.fn(['right'])} color="primary" autoFocus>
                          Cancel
                      </Button>
                      {!isEmpty(get('Approvals', currentAttachment)) && (
                          <paper style={{ 'line-height': '.25' }}>
                              <p style={{ 'font-size': '90%', 'padding-left': '20px' }}>Approvals</p>
                              {map(
                                  (each) => (
                                      <p style={{ 'font-size': '80%', 'padding-left': '30px' }} key={each.Id}>
                                          {formatStandard(each.AsOf)}: {each.Approver.DisplayName}
                                      </p>
                                  ),
                                  get('Approvals', currentAttachment)
                              )}
                          </paper>
                      )}

                      {/*                    <Button onClick={() => toggleCompletion(getApprovalToggle())} color="primary" disabled={!completeState}>
                          {getApprovalToggle()}
                      </Button>*/}
                      <div style={{ flex: '1 0 0' }} />

                      {/*   <Button onClick={() => persistPDF()} color="primary" disabled={!saveState}>
                          Save
                      </Button>*/}
                      <SplitButton
                          options={['Save', ...getApprovalChoices()]}
                          disabled={!saveState}
                          callBack={(res) => {
                              if (res === 'Save') persistPDF();
                              if (res === 'Save and Unapprove') toggleCompletion('unapprove').then(persistPDF);
                              if (res === 'Save and Approve') toggleCompletion('approve').then(persistPDF);
                          }}
                      />
                  </DialogActions>
              </Dialog>,
              document.body
          )
        : null;
};

export const EditCxForm = ({ open, row, onClose }) => {
    return (
        <FormEditor
            state={{
                visible: open,
                fn: onClose,
                values: { attachment: row.originalAttachment, formTemplate: { oneForm: row.form } }
            }}
            left="Cancel"
            right="OK"
        />
    );
};

export const extractFormBlob = async (viewer) => {
    const { documentViewer, annotationManager } = viewer.Core;
    const doc = documentViewer.getDocument();
    const xfdfString = await annotationManager.exportAnnotations();
    const hash = await digestMessage(xfdfString);
    const data = await doc.getFileData({ xfdfString });
    const arr = new Uint8Array(data);
    return [new Blob([arr], { type: 'application/pdf' }), hash];
};

const isApprovedByUser = (partyId, attachment) =>
    find((each) => each.Approver.Id === partyId, get('Approvals', attachment));

export const uploadBlob = (notifier, name, blob, orginalName) => {
    const body = new FormData();
    body.append('file', blob, orginalName);
    body.append('originalFile', name);
    return pFetch('/api/file', {
        method: 'POST',
        body
    })
        .then(toJson)
        .then((json) => [json, orginalName])
        .catch(notifier('message', { variant: 'error' }));
};

export const replaceBlob = (notifier, blob, orginalName) => {
    const body = new FormData();
    body.append('file', blob, orginalName);
    return pFetch('/api/file', {
        method: 'PUT',
        body
    })
        .then(toJson)
        .then((json) => [json, orginalName])
        .catch(notifier('message', { variant: 'error' }));
};

async function digestMessage(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hash = await crypto.subtle.digest('SHA-256', data);
    return new TextDecoder('utf-8').decode(hash);
}

const Slider = ({ label, myRef }) => {
    return <input type="text" defaultValue={label} ref={myRef}></input>;
};
