import React from 'react';
import PropTypes from 'prop-types';
import { useSelected, getSelectedItems, SCHEDULECACHE } from 'client-shared/utility';
import Typography from '@mui/material/Typography';
import { map } from 'lodash/fp';
import { Totals } from 'client-shared/components/totals';

export const JobSummary = ({ client, targetProperties, items, cellDragDrop }) => {
    const [selected, toggleSelected] = useSelected(items, SCHEDULECACHE);
    return (
        <div
            draggable={true}
            onDragStart={(ev) => {
                let allocations = getSelectedItems(client, SCHEDULECACHE, items, targetProperties);
                // turn selected off so only one day is copied.
                allocations = map(
                    (allocation) => ({
                        ...allocation,
                        selected: false
                    }),
                    allocations
                );
                cellDragDrop.handleDrag(allocations)(ev);
            }}
            onDrop={(event) => {
                cellDragDrop.handleCellDrop(event);
            }}
            onDragOver={cellDragDrop.allowDrop}
        >
            <Typography
                fontSize="small"
                sx={{ backgroundColor: selected ? 'Gainsboro' : 'transparent' }}
                onClick={() => toggleSelected(items, targetProperties)}
            >
                <span style={{ fontSize: '10px' }}>
                    <Totals allocations={items} start={targetProperties.CurrentLifespan.Start} />
                </span>
            </Typography>
        </div>
    );
};

JobSummary.propTypes = {
    client: PropTypes.object.isRequired,
    targetProperties: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    cellDragDrop: PropTypes.object.isRequired
};
