/**
 * Created by mark on 8/27/2021
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxConfigValue';
import inputModel from 'cb-schema/inputmodelfull/CxConfigValue';
import { object, string } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';

const validationSchema = object({
    Key: string().min(2, 'Too Short').max(65, 'Too Long').required('Required')
});

const Form = (props) => {
    const { classes /*data*/ } = props;
    return (
        <form noValidate autoComplete="off">
            <Field type="text" label="Key" name="Key" component={TextField} className={classes.longTextField} />
            <Field
                type="text"
                label="Value"
                name="Value"
                component={TextField}
                className={classes.textBoxField}
                multiline
                variant="outlined"
                margin="normal"
            />
            <Field
                type="text"
                label="Description"
                name="Description"
                component={TextField}
                className={classes.textField}
            />
        </form>
    );
};

export const EditCxConfigValue = ({ open, formQueryValues, onClose }) => {
    let initialValues = cloneDeep(model);
    initialValues.Editable = true;
    initialValues.Required = false;
    return (
        <DialogForm
            open={open}
            title="Config Value"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxConfigValue"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxConfigValue.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
