/**
 * Created by mark on 2/28/2021.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { number, object } from 'yup';
import { FormContainer } from 'client-shared/components/form';
import { usePrivilege, parseDate } from 'client-shared/utility';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { flow } from 'lodash/fp';
import { format, addDays } from 'date-fns/fp';

export const validationSchema = object({
    days: number().min(1, 'Must be greater than 0').required('Required')
});

const Form = (props) => {
    const { classes } = props;
    return (
        <form noValidate autoComplete="off">
            <Field
                type="date"
                label="Target Date"
                name="start"
                component={TextField}
                className={classes.dateTimeField}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <Field
                type="number"
                label="Days"
                name="days"
                component={TextField}
                className={classes.extraShortTextField}
            />
        </form>
    );
};

export const EditCopyAllocation = ({ actions, open, onClose }) => {
    const [privilege] = usePrivilege('Allocation');
    let _submitForm;
    const getSubmitForm = (submitForm) => {
        _submitForm = submitForm;
    };
    const submitForm = () => {
        if (_submitForm) {
            _submitForm();
        }
    };
    return (
        <Dialog open={open}>
            <DialogContent>
                This will copy all selected allocations, beginning with their start, to the target date. To copy
                multiple days of multi-day allocations, set Days to the number of days you wish to copy.
            </DialogContent>
            <DialogContent>
                <FormContainer
                    Form={Form}
                    validationSchema={validationSchema}
                    initialValues={{
                        start: flow(parseDate, addDays(1), format('yyyy-MM-dd'))(new Date()),
                        days: 1
                    }}
                    data={{}}
                    onSubmit={(formValues) => {
                        actions.handleCopyAllocation(formValues);
                        onClose();
                    }}
                    getSubmitForm={getSubmitForm}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitForm} color="primary" disabled={privilege}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditCopyAllocation.propTypes = {
    actions: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
