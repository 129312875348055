/**
 * Created by mark on 2/11/17.
 */

import lodashGroupBy from 'lodash/groupBy';
import { curry, get, fromPairs, reduce, flow, toPairs, sortBy, pick, keys } from 'lodash/fp';
import { getRange } from './dateutilities';

export const MISSING = '{missing}';
export const NOGROUP = 'nogroup:&nogroup';
export const FIELDVALUESEPARATOR = '$&';
export const FIELDSEPARATOR = '$!';

/**
 * construct a string of all property values in the groupByCriteria.
 * the groupByCriteria object can contain one or more properties to groupBy, this enables multi-property grouping.
 * this returns a string (if valueOnly is false):
 * <criterianame><FIELDVALUESEPARATOR><itemvalue><FIELDSEPARATOR>...
 * @param item - object from collection being grouped
 * @param groupByCriteria - object of key value pairs used to create the group name :
 * {criterianame: propertyName|function,...} where the criterianame can be any valid variable name, and
 * the value is the full path name of the collection property or a function that has the signature
 * (item) => value to group on
 * @param valueOnly - true if the returned string should be <itemvalue> only.
 * @returns {string}
 * @private
 */
const _groupName = (item, groupByCriteria, valueOnly = false) => {
    const nameValues = Object.entries(groupByCriteria).map((groupNamePair) => {
        let value;
        if (typeof groupNamePair[1] === 'function') {
            value = groupNamePair[1](item);
        } else {
            value = get(groupNamePair[1], item);
        }
        // if property is missing from the item, put into missing group.
        if (!value) {
            return MISSING + FIELDVALUESEPARATOR + MISSING;
        }
        return valueOnly ? value : groupNamePair[0] + FIELDVALUESEPARATOR + value;
    });
    return nameValues.join(FIELDSEPARATOR);
};

/**
 * parse a string that was created by _groupName into its constituent parts.
 * return an object of name, value pairs. {<criterianame>: <itemvalue>, ...}
 * @param groupNameString - string of concatenated name value pairs.
 * @returns {Object|*} - object of property name, value pairs.
 */
export const groupNameParse = (groupNameString) => {
    const nameValueStrings = groupNameString.split(FIELDSEPARATOR);

    const groupByCriteria = nameValueStrings.map((nameValueString) => {
        return nameValueString.split(FIELDVALUESEPARATOR);
    });
    return fromPairs(groupByCriteria);
};

/**
 * group a collection by its properties specified in the groupByCriteria.
 * @param collection - collection to be grouped
 * @param groupByCriteria  - object containing collection properties to group by.
 * @param filter  - function to filter grouped items, (item) => boolean.
 * @param valueOnly - boolean, if true, the criterianame will be the groupedpropertyvalue only.
 * @returns {Object|*} - object containing groupNameStrings as properties whose value is a collection of grouped items.
 */
export const groupBy = (collection, groupByCriteria, filter, valueOnly) => {
    const groupByFunction = groupByCriteria
        ? (item) => _groupName(item, groupByCriteria, valueOnly)
        : (item) => NOGROUP;
    const groups = lodashGroupBy(collection, groupByFunction);
    let keep = true;

    // remove groups that do not pass the filter test. this is different from filtering only items in the
    // items array. the group is kept if at least one occurrence of the filtered item is in the group.
    if (filter) {
        for (let group in groups) {
            keep = groups[group].find(filter);
            if (!keep) {
                delete groups[group];
            }
        }
    }
    return groups;
};

export const groupByFp = curry((groupByCriteria, filter, valueOnly, items) => {
    return groupBy(items, groupByCriteria, filter, valueOnly);
});

/**
 * group a collection by an interval date and return a key,value Map where the key is the interval date and
 * the value is a collection that matches the interval date.
 * @param start - start date of range.
 * @param periods - number of periods (e.g. days, weeks, months, other).
 * @param groupByCriteria -
 * @param options - {
 *   interval - date-fns interval (e.g. days, weeks, months, other)
 *   skipDays - days of week to exclude - 0-sunday...6-saturday. only applies to interval days.
 *   weekStartsOn - applies only to interval weeks, 0-sunday...6-saturday
 * }
 * @param collection - collection to be grouped by interval within the range.
 * @returns {Map<unknown, unknown>}
 */
export const groupCollectionByInterval = curry((start, periods, groupByCriteria, options, collection) => {
    const range = getRange(start, periods, options);
    const rangeObject = reduce(
        (obj, v) => {
            obj[v] = [];
            return obj;
        },
        {},
        range
    );

    return flow(
        groupByFp(groupByCriteria, null, true),
        pick(keys(rangeObject)),
        (object) => {
            //const groupedItems = groupByFp(groupByCriteria, null, true, items)
            //const pickedGroupedItems = pick(keys(rangeObject), groupedItems)
            return { ...rangeObject, ...object };
        },
        toPairs,
        sortBy((keyValuePair) => {
            return keyValuePair[0];
        }),
        (keyValuePair) => new Map(keyValuePair)
    )(collection);
});

/**
 * group a collection and return a key,value Map where the key is the groupedByCriteria and
 * the value is a collection that matches the criteria. This is just like groupByFp but returns a sorted Map
 * instead of an object.
 * @param groupByCriteria -
 * @param collection - collection to be grouped by groupByCriteria.
 * @returns {Map<unknown, unknown>}
 */
export const groupCollection = curry((groupByCriteria, collection) => {
    return flow(
        groupByFp(groupByCriteria, null, true),
        toPairs,
        sortBy((keyValuePair) => {
            return keyValuePair[0];
        }),
        (keyValuePair) => new Map(keyValuePair)
    )(collection);
});
