import React, {useState, useEffect, useContext} from 'react';
import {gql, useApolloClient} from '@apollo/client';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {EditingState} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow, TableEditRow, TableEditColumn} from '@devexpress/dx-react-grid-material-ui';
import {
    Paper,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Grid as GridMUI,
    Alert
} from '@mui/material';
import {sendSMS} from 'client-shared/components/sms/smsHelpers';
import {TextEditorProvider, AutoCompleteEditorProvider} from 'client-shared/components/crudgrid';
import {UserContext, unSelectAll, usePrivilege, parseDate, SCHEDULECACHE, groupByFp} from 'client-shared/utility';
import {format} from 'date-fns/fp';
import {sortBy, map, join, flow, forEach, filter, toLower, includes} from 'lodash/fp';
import {useNotifications} from 'client-shared/components/notifier2';

export const CxPartysQuery = gql`
    query CxPartysQuery($searchString: String, $start: String) {
        cxPartys(
            objectType: "Party"
            filters: [
                { name: "String", values: ["DisplayName", $searchString] }
                { name: "ObjectType", values: ["Party", "Company", "Subcontractor"] }
                { name: "Range", values: [$start] }
            ]
        ) {
            Id
            Name
            SMSNumbers {
                Name
                PhoneNumber
            }
        }
    }
`;
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    textArea: {
        margin: theme.spacing(1),
        width: 400
    }
}));

const columns = [
    {
        name: 'phoneNumber',
        title: 'Phone Number'
    },
    {name: 'name', title: 'Name'},
    {name: 'message', title: 'SMS Message'}
];

const tableColumnExtensions = [
    {columnName: 'phoneNumber', width: '15%'},
    {columnName: 'name', width: '20%'},
    {columnName: 'message', width: 'auto', wordWrapEnabled: true}
];

const getRowId = (row) => row.id;

const editingColumnExtensions = [
    {
        columnName: 'name',
        createRowChange: (row, value) => {
            // if the value is null, the user cleared the autocomplete name field.
            value = value ? value : {Name: undefined, SMSNumbers: []};
            // reformat the value to match the properties of the grid rows.
            return {name: value.Name, phoneNumber: value.SMSNumbers.length ? value.SMSNumbers[0].PhoneNumber : ''};
        }
    }
];

/**
 * display a grid of sms messages.
 * @param messages - array of sms message in the form
 * {
 *        id: 1,
 *        phoneNumber: '1234',
 *        name: 'john,
 *        start: '2020-01-01', // sorts the messages so they are sent in chronological order
 *        message: 'sms message body'
 * }
 * @returns {JSX.Element}
 * @constructor
 */
const SMSBatchSendGrid = ({messages, generalOnly = false}) => {
    const classes = useStyles();
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const [privilege] = usePrivilege('Allocation');
    const [rows, setRows] = useState(messages);
    const [generalMessage, setGeneralMessage] = useState('');
    const [generalMessageOnly, setGeneralMessageOnly] = useState(generalOnly);
    const [groupMessages, setGroupMessages] = useState(false);
    const [missingPhoneNumber, setMissingPhoneNumber] = useState()
    const {openSBTransient} = useNotifications();

    /* eslint-disable  react-hooks/exhaustive-deps */
    useEffect(() => {
        setRows(messages);
    }, [messages]);

    useEffect(() => {
        const missingPhone = filter(message => !message.phoneNumber, rows);
        setMissingPhoneNumber(missingPhone.length)
    }, [rows]);

    const commitChanges = ({added, changed, deleted}) => {
        let changedRows;
        if (added) {
            const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
            changedRows = [
                ...rows,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row
                }))
            ];
        }
        if (changed) {
            changedRows = rows.map((row) => (changed[row.id] ? {...row, ...changed[row.id]} : row));
        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            changedRows = rows.filter((row) => !deletedSet.has(row.id));
        }
        setRows(changedRows);
    };
    const groupByPhoneNumber = {
        phoneNumber: 'phoneNumber'
    };

    const handleSend = (rows) => {
        const errors = [];
        const messages = groupMessages
            ? flow(
                groupByFp(groupByPhoneNumber, null, null),
                map((groupedMessage) => {
                    const message = flow(
                        map((message) => message.message),
                        join('\n\n')
                    )(groupedMessage);
                    return {...groupedMessage[0], ...{message: message}};
                })
            )(rows)
            : rows;
        flow(
            sortBy(['phoneNumber', 'start']),
            forEach((message) => {
                if (typeof message.phoneNumber === 'string' && message.phoneNumber !== '') {
                    let phoneNumber = message.phoneNumber.replace(/-/g, '');
                    if (phoneNumber.charAt(0) !== '1') {
                        phoneNumber = `1${phoneNumber}`;
                    }
                    sendSMS(
                        client,
                        user.customerInfo.customer,
                        user.congistics.user.smsNumber,
                        phoneNumber,
                        `${generalMessage} ${generalMessageOnly ? '' : message.message}`
                    ).catch((error) => {
                        errors.push(error);
                        console.log(error);
                    });
                }
            })
        )(messages);
        unSelectAll(client, SCHEDULECACHE);
        if (errors.length) {
            openSBTransient(join(' ', errors), {variant: 'warning'});
            return;
        }
        openSBTransient('Schedule sent', {variant: 'success'});
    };
    const SendButton = () => (
        <div>
            <Button variant="contained" color="primary" default onClick={() => handleSend(rows)} disabled={privilege}>
                Send Schedule
            </Button>
            {missingPhoneNumber &&
                <Alert severity="warning">{missingPhoneNumber} messages are missing phone numbers and will not be
                    sent</Alert>}
        </div>
    );

    return (
        <Paper>
            <Typography variant="body2">SMS Number: {user.congistics.user.smsNumber}</Typography>
            <GridMUI container className={classes.root} spacing={2}>
                <GridMUI item xs={2}>
                    <SendButton/>
                </GridMUI>
                <GridMUI item xs={6}>
                    <TextField
                        label="General message"
                        multiline
                        maxrows="4"
                        value={generalMessage}
                        onChange={(event) => setGeneralMessage(event.target.value)}
                        className={classes.textArea}
                    />
                </GridMUI>
                <GridMUI item xs={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={generalMessageOnly}
                                onChange={(event) => setGeneralMessageOnly(event.target.checked)}
                                color="primary"
                            />
                        }
                        label="General Only"
                    />
                </GridMUI>
                <GridMUI item xs={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={groupMessages}
                                onChange={(event) => setGroupMessages(event.target.checked)}
                                color="primary"
                            />
                        }
                        label="Group Messages"
                    />
                </GridMUI>
            </GridMUI>
            <Grid rows={rows} columns={columns} getRowId={getRowId}>
                <TextEditorProvider multiline for={['message']}/>
                <AutoCompleteEditorProvider
                    for={['name']}
                    optionsQuery={CxPartysQuery}
                    queryVariables={() => {
                        return {start: format("yyyy-MM-dd'T'00:00:00", parseDate())};
                    }}
                    getOptionLabel={(option) => {
                        // this is the option from the column itself.
                        if (!option.SMSNumbers) {
                            return option;
                        }
                        // this is the option from an item from the query without phone numbers.
                        if (option.SMSNumbers.length === 0) {
                            return option.Name;
                        }
                        // this is the option from an item from the query with phone numbers.
                        return `${option.Name} ${option.SMSNumbers[0].PhoneNumber}`;
                    }}
                    onChange={(value, onValueChange) => onValueChange(value)}
                />
                <EditingState onCommitChanges={commitChanges} columnExtensions={editingColumnExtensions}/>
                <Table columnExtensions={tableColumnExtensions}/>
                <TableHeaderRow/>
                <TableEditRow/>
                <TableEditColumn showAddCommand showEditCommand showDeleteCommand/>
            </Grid>
        </Paper>
    );
};

SMSBatchSendGrid.propTypes = {
    messages: PropTypes.array.isRequired,
    generalOnly: PropTypes.bool
};

export const SMSBatchSend = ({open, onClose, Filter}) => {
    return (
        <Dialog open={open} fullWidth maxWidth={'lg'}>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <DialogContent>
                {' '}
                <Filter>
                    {({data, values, ...other}) => {
                        //console.log(values);
                        //const messages = getSMSMessages(rows, literalTemplate);
                        return <SMSBatchSendGrid messages={data} generalOnly={values._generalOnly}/>;
                    }}
                </Filter>
            </DialogContent>
        </Dialog>
    );
};

SMSBatchSend.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    Filter: PropTypes.func.isRequired
};

export const formatContacts = (contacts = [], label = '', titleFilter) => {
    const contactTemplate = (contact) => {
        if (!contact) {
            return '';
        }
        return `${contact.Name} ${flow(
            map((phoneNumber) => phoneNumber.PhoneNumber),
            join(', ')
        )(contact.PhoneNumbers)}`;
    };

    const x = flow(
        filter((contact) => {
            return titleFilter ? flow(toLower, includes(toLower(titleFilter)))(contact.Title) : true;
        }),
        map((contact) => contactTemplate(contact)),
        join(', '),
        (contacts) => {
            return contacts.length ? `${label}${contacts}` : '';
        }
    )(contacts);
    return x;
};
