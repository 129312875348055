/**
 * time clock to punch in and out of jobs with offline support.
 */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'client-shared/utility/context';
import { gql } from '@apollo/client';
import { Field, FileEditor, currentLifespanTimeValidationSchema, AutoComplete } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxTimeEntry';
import inputModel from 'cb-schema/inputmodelfull/CxTimeEntry';
import { object, number, array } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { format, addDays, differenceInMinutes } from 'date-fns/fp';
import { get, flow, isEmpty, filter } from 'lodash/fp';
import {
    Mutation,
    parseDate,
    fragments,
    getTimeEntryMethods,
    createFilters,
    offlineLinkOpen,
    getConfigValue
} from 'client-shared/utility';
import { useApolloClient } from '@apollo/client';
import { v4 } from 'uuid';
import PunchClockOutlinedIcon from '@mui/icons-material/PunchClockOutlined';
import { Button } from '@mui/material';
import isOnline from 'is-online';

const PUNCHTIME = '2000-01-01T00:00:00';

const dataQuery = gql`
    ${fragments.CxTimeEntryPunchFragment}
    query ($filters: [FilterInput]) {
        scheduledTimeEntrys(filters: $filters) {
            ...CxTimeEntryPunchFragment
        }
    }
`;

let validationSchema = object({
    _Job: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    JobActivity: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    // JobCosts: array()
    //     .min(1, 'At Least One Cost Code Is Required')
    //     .of(
    //         object({
    //             Id: number().moreThan(0, 'Required')
    //         })
    //     )
    //     .nullable()
    //     .required('Required'),
    CurrentLifespan: currentLifespanTimeValidationSchema
    // _approved: bool().oneOf([true], 'Required')
});

const getPosition = (options = { timeout: 1600, enableHighAccuracy: false, maximumAge: 1000 * 60 * 2 }) => {
    if (!navigator.geolocation) {
        return Promise.resolve({ coords: { latitude: 0, longitude: 0 } });
    }
    return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options));
};

/**
 * update/insert the scheduledTimeEntrys collection in the local graphql cache for offline editing.
 * properties to be saved to the server are queued using the graphql apollo-link-queue-persist.
 * The timeEntry mutation is queued, which contains values from a single scheduledTimeEntrys item read from
 * the local graphql cache. New timeEntries are simply initialized here.
 * @param client
 * @param values
 * @returns {(function(*, *, *): Promise<T|void|undefined>)|*}
 */
const handleSubmit = (client, values, onClose, config_configValues) => async (formValues, formikBag, touched) => {
    const mutation = new Mutation(client);
    let geolocationCoordinates;
    try {
        geolocationCoordinates = await getPosition();
    } catch (error) {
        geolocationCoordinates = {
            GeolocationPositionError: error,
            coords: { latitude: 0, longitude: 0 }
        };
        if (error.code === 1) {
            alert(`Enable your gps location for controlboard in your browser settings.`);
            if (get('GPS.required', config_configValues)) {
                return;
            }
        }
    }
    let timeEntry = cloneDeep(formValues);
    timeEntry._geolocationCoordinates = geolocationCoordinates;
    // if (!timeEntry._action) {
    switch (timeEntry.Punch) {
        case null:
        case '':
            timeEntry.Punch = 'PunchedIn';
            timeEntry.PunchStart = format("yyyy-MM-dd'T'HH:mm:00", new Date());
            timeEntry.StartLocation = {
                Id: '0',
                Lat: timeEntry._geolocationCoordinates.coords.latitude,
                Lng: timeEntry._geolocationCoordinates.coords.longitude,
                Description: timeEntry._geolocationCoordinates.GeolocationPositionError
                    ? timeEntry._geolocationCoordinates.GeolocationPositionError.message
                    : ''
            };
            timeEntry.EndLocation = {
                Id: '0',
                Lat: 0,
                Lng: 0,
                Description: ''
            };
            break;
        case 'PunchedIn':
            timeEntry.Punch = 'PunchedOut';
            timeEntry.PunchEnd = format("yyyy-MM-dd'T'HH:mm:00", new Date());
            timeEntry.Minutes = differenceInMinutes(parseDate(timeEntry.PunchStart), parseDate(timeEntry.PunchEnd));
            timeEntry.EndLocation = {
                Id: '0',
                Lat: timeEntry._geolocationCoordinates.coords.latitude,
                Lng: timeEntry._geolocationCoordinates.coords.longitude,
                Description: timeEntry._geolocationCoordinates.GeolocationPositionError
                    ? timeEntry._geolocationCoordinates.GeolocationPositionError.message
                    : ''
            };
            break;
        case 'PunchedOut':
            timeEntry.Punch = 'PunchedIn';
            timeEntry.PunchStart = format("yyyy-MM-dd'T'HH:mm:00", new Date());
            timeEntry.PunchEnd = PUNCHTIME;
            timeEntry.Id = '0';
            timeEntry.Guid = '';
            timeEntry.StartLocation = {
                Id: '0',
                Lat: timeEntry._geolocationCoordinates.coords.latitude,
                Lng: timeEntry._geolocationCoordinates.coords.longitude,
                Description: timeEntry._geolocationCoordinates.GeolocationPositionError
                    ? timeEntry._geolocationCoordinates.GeolocationPositionError.message
                    : ''
            };
            timeEntry.EndLocation = {
                Id: '0',
                Lat: 0,
                Lng: 0,
                Description: ''
            };
            break;
        default:
            break;
    }
    // }

    try {
        if (!isEmpty(timeEntry.Guid)) {
            client.writeFragment({
                id: `CxTimeEntry:${timeEntry.Guid}`,
                fragment: fragments.CxTimeEntryPunchFragment,
                data: timeEntry
            });
        } else {
            timeEntry.Guid = get('Guid', timeEntry) || v4();
            const filters = createFilters(values);
            const data = client.readQuery({
                query: dataQuery,
                variables: filters
            });
            const { scheduledTimeEntrys } = data;
            client.writeQuery({
                query: dataQuery,
                data: {
                    // Contains the data to write
                    scheduledTimeEntrys: [...scheduledTimeEntrys, timeEntry]
                },
                variables: filters
            });
        }
        const online = await isOnline();
        if (!online) {
            onClose();
        }
        const { methodsByItem, items } = getTimeEntryMethods(timeEntry, touched);
        return await mutation.save(
            'CxTimeEntry',
            items,
            methodsByItem,
            undefined,
            undefined,
            undefined,
            offlineLinkOpen
        );
    } catch (error) {
        console.log('save error', error);
    }
};

const Clock = ({ formatString = 'pp', ...other }) => {
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => setDate(new Date()), 1000);
        return () => clearInterval(interval);
    }, []);
    return <span {...other}>{format(formatString, date)}</span>;
};

Clock.propTypes = {
    formatString: PropTypes.string
};

const InOutTime = ({ timeEntry, ...other }) => {
    switch (timeEntry.Punch) {
        case null:
        case '':
            return null;
        case 'PunchedIn':
            return <span {...other}>{`In: ${format('p', parseDate(timeEntry.PunchStart))}`}</span>;
        case 'PunchedOut':
            return (
                <span {...other}>{`In: ${format('p', parseDate(timeEntry.PunchStart))} Out: ${format(
                    'p',
                    parseDate(timeEntry.PunchEnd)
                )}`}</span>
            );
        default:
            return null;
    }
};

InOutTime.propTypes = {
    timeEntry: PropTypes.object.isRequired
};

const form = (onClose, config_configValues) => (props) => {
    const { classes, values, setFieldValue } = props;
    const [jobActivities, setJobActivities] = useState([]);
    // const disabled = !!get('Punch', values)
    const disabled = false;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <div>
                    <div>
                        <div>
                            <Clock formatString="PP" />
                        </div>
                        <Field
                            type="text"
                            label="Job"
                            name="_Job"
                            component={AutoComplete}
                            className={classes.selectField}
                            optionLabelProperty="DisplayName"
                            optionsQuery={gql`
                                query ($searchString: String, $start: String, $end: String) {
                                    cxJobs(
                                        objectType: "Job"
                                        filters: [
                                            { name: "String", values: ["Name", $searchString] }
                                            { name: "Range", values: [$start, $end] }
                                        ]
                                    ) {
                                        Id
                                        DisplayName
                                        JobActivities {
                                            Id
                                            DisplayName
                                        }
                                        GlobalJobCosts {
                                            Id
                                            DisplayName
                                        }
                                    }
                                }
                            `}
                            queryVariables={() => ({
                                start: format("yyyy-MM-dd'T'00:00", new Date()),
                                end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                            })}
                            onChange={(event, value) => {
                                setFieldValue('JobCosts', []);
                                if (value) {
                                    setJobActivities(get('JobActivities', value));
                                    setFieldValue('JobActivity', get('JobActivities.0', value));
                                } else {
                                    setJobActivities([]);
                                    setFieldValue('JobActivity', { Id: 0, DisplayName: '' });
                                }
                            }}
                            disabled={disabled}
                            cache={true}
                        />
                    </div>
                    <div>
                        <Field
                            type="text"
                            label="Job Activity"
                            name="JobActivity"
                            component={AutoComplete}
                            className={classes.selectField}
                            optionLabelProperty="DisplayName"
                            options={jobActivities}
                            disabled={disabled}
                            disableClearable
                            cache={true}
                        />
                    </div>
                    <div>
                        <Field
                            type="text"
                            label="Job Costs"
                            name="JobCosts"
                            component={AutoComplete}
                            className={classes.selectField}
                            optionLabelProperty="DisplayName"
                            optionsQuery={gql`
                                query ($start: String, $end: String) {
                                    cxJobs(objectType: "Job", filters: [{ name: "Range", values: [$start, $end] }]) {
                                        Id
                                        GlobalJobCosts {
                                            Id
                                            DisplayName
                                        }
                                    }
                                }
                            `}
                            queryVariables={() => ({
                                start: format("yyyy-MM-dd'T'00:00", new Date()),
                                end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                            })}
                            getOptions={(options) => {
                                options = filter((options) => options.Id === values._Job.Id, options);
                                return get('0.GlobalJobCosts', options) || [];
                            }}
                            disabled={disabled}
                            cache={true}
                        />
                    </div>
                    {get('Auxiliaries.display', config_configValues) && (
                        <div>
                            <Field
                                type="text"
                                label="Equipment"
                                name="Auxiliaries"
                                component={AutoComplete}
                                className={classes.selectField}
                                optionLabelProperty="DisplayName"
                                optionsQuery={gql`
                                    query ($searchString: String, $start: String, $end: String) {
                                        cxEquipments(
                                            filters: [
                                                { name: "String", values: ["Name", $searchString] }
                                                { name: "Range", values: [$start, $end] }
                                            ]
                                        ) {
                                            Id
                                            DisplayName
                                        }
                                    }
                                `}
                                queryVariables={(values) => ({
                                    start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                                    end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                                })}
                                multiple
                                initialize={false}
                                disabled={disabled}
                                cache={true}
                            />
                        </div>
                    )}
                    <div>
                        <Field
                            type="text"
                            label="Description"
                            name="Description"
                            component={TextField}
                            className={classes.textBoxField}
                            multiline
                            variant="outlined"
                            margin="normal"
                            disabled={disabled}
                        />
                    </div>
                    <div>
                        <Field type="text" label="File" name="Attachments" component={FileEditor} />
                    </div>
                    {/*<div style={{ padding: '5px' }}>*/}
                    {/*    <InOutTime timeEntry={values} />*/}
                    {/*</div>*/}
                    <div style={{ marginTop: '1rem' }}>
                        <Button
                            variant="contained"
                            sx={{
                                borderRadius: 35,
                                backgroundColor: [null, '', 'PunchedOut'].includes(values.Punch) ? 'green' : 'red',
                                //padding: "18px 36px",
                                fontSize: '14px'
                            }}
                            onClick={async () => {
                                return props.submitForm().catch((error) => console.log(error));
                            }}
                            startIcon={<PunchClockOutlinedIcon />}
                        >
                            {[null, '', 'PunchedOut'].includes(values.Punch) ? 'Punch In' : 'Punch Out'}
                            <Clock style={{ paddingLeft: '5px' }} />
                        </Button>
                        <Button
                            style={{
                                borderRadius: 35,
                                fontSize: '14px'
                            }}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};

/**
 *
 * @param open - if true, open form dialog.
 * @param formQueryValues - object containing the timeentry query filter to retrieve one timeentry by guid.
 * @param onClose - function to set the open arg to false.
 * @param values - object containing the form query values from the crud grid of timeentries. this is used
 * to update the list of timeentries in the client cache.
 * @returns {JSX.Element}
 * @constructor
 */
export const EditCxTimeEntryMobilePunch = ({ open, formQueryValues, onClose, values }) => {
    const client = useApolloClient();
    const [user] = useContext(UserContext);
    const config_configValues = getConfigValue('config.mobile.mobiletimepunches', user);
    if (get('Auxiliaries.required', config_configValues)) {
        validationSchema = validationSchema.shape({
            Auxiliaries: array()
                .min(1, 'Equipment Is Required')
                .of(
                    object({
                        Id: number().moreThan(0, 'Required')
                    })
                )
                .nullable()
                .required('Required')
        });
    }
    // initialize model properties.
    let initialValues = cloneDeep(model);
    initialValues.Approvals = [];
    delete initialValues.Owner;
    initialValues.Party = {
        Id: get('congistics.user.Party.Id', user),
        DisplayName: get('congistics.user.Party.Name', user)
    };
    initialValues.JobActivity = {
        Id: '0',
        DisplayName: ''
    };
    initialValues._Job = {
        Id: '0',
        DisplayName: ''
    };
    initialValues._partyId = get('congistics.user.Party.Id', user);
    initialValues.JobCosts = [];
    initialValues.Punch = '';
    initialValues.PunchStart = PUNCHTIME;
    initialValues.PunchEnd = PUNCHTIME;
    // initialValues._action = undefined;
    initialValues.Auxiliaries = [];
    const onData = async (initialValues) => {
        // set properties for virtual timeentries because they do not contain valid values.
        if (!initialValues) {
            return initialValues;
        }

        delete initialValues.LaborClass;
        delete initialValues.StartDistance;
        delete initialValues.EndDistance;
        delete initialValues.Created;
        delete initialValues.Hours;
        delete initialValues.EntryType;
        delete initialValues.Cost;
        initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'00:00:00", new Date());
        initialValues.CurrentLifespan.End = flow(addDays(1), format("yyyy-MM-dd'T'00:00:00"))(new Date());
        initialValues._approved = true;
        initialValues._geolocationCoordinates = undefined;
        return initialValues;
    };

    return (
        <DialogForm
            open={open}
            title="Time Punch"
            Form={form(onClose, config_configValues)}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxTimeEntry"
            queryName="timeEntry"
            showActions={false}
            inputModel={inputModel}
            cache={true}
            onClose={onClose}
            onData={onData}
            onSubmit={handleSubmit(client, values, onClose, config_configValues)}
        />
    );
};

EditCxTimeEntryMobilePunch.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
