/**
 * Created by mark on 11/25/2019.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Field, AutoComplete, LookupValueEditor } from 'client-shared/components/form';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { FormQuery } from './queries.js';
import { useApolloClient } from '@apollo/client';
import model from 'cb-schema/emptymodel/CxUser';
import inputModel from 'cb-schema/inputmodelfull/CxUser';
import { object, number, string, array } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { gql } from 'graphql-tag';
import { get, map, flatMap } from 'lodash/fp';
import { getFilter } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { evalQuery, getGraphqlHelper, makeVerbOperation } from '../../gqlhelpers/queryHelpers';

const getFeatures = (level = '', featureGroups, levelFeatures) => {
    level = level.replace('-', '');
    if (!get(level, levelFeatures)) {
        return [];
    }
    const features = flatMap(
        (featureGroup) => {
            return map((feature) => ({ group: featureGroup.group, ...feature }), featureGroup.features);
        },
        get(level, levelFeatures)
    );
    return features;
};

const validationSchema = object({
    Party: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required(),
    Level: object({
        Id: number().moreThan(0, 'Required')
    }),
    Features: array().min(1, 'Required'),
    Login: string().email('Invalid e-mail').required('Required')
    //Divisions: array().required('Required')
});

const resetUserPW = (client, user) => {
    const GQL = getGraphqlHelper('CxUser');
    const { methodsByItem, filters } = makeVerbOperation('ResetPassword', {
        ids: [user.Id],
        preserve: true
    });
    evalQuery(client, null, GQL.entityClass, [], methodsByItem, filters);
};

const Form = (props) => {
    const [user] = useContext(UserContext);
    const client = useApolloClient();
    const { classes, setFieldValue, values } = props;

    const { featureGroups, levelFeatures } = getFilter(user, 'customerInfo.ux.feature-groups', 'index.js');
    return (
        <form noValidate autoComplete="off">
            <div>
                <Field type="checkbox" Label={{ label: 'Active' }} name="Active" component={CheckboxWithLabel} />
                <Field
                    type="text"
                    label="Employee"
                    name="Party"
                    component={AutoComplete}
                    className={classes.selectField}
                    optionsQuery={gql`
                        query ($searchString: String) {
                            cxEmployees(
                                objectType: "Employee"
                                filters: [{ name: "String", values: ["DisplayName", $searchString] }]
                            ) {
                                Person {
                                    Id
                                    DisplayName
                                    Emails {
                                        Email
                                    }
                                }
                            }
                        }
                    `}
                    getOptions={(options) => {
                        return options.map((option) => ({
                            Id: option.Person.Id,
                            _DisplayName: option.Person.DisplayName,
                            _Email: option.Person.Emails.length ? option.Person.Emails[0].Email : ''
                        }));
                    }}
                    optionLabelProperty="_DisplayName"
                    onChange={(event, value) => {
                        setFieldValue('Login', value ? value._Email : '');
                    }}
                    disableClearable
                />
                <Field
                    type="text"
                    label="Level"
                    name="Level"
                    component={AutoComplete}
                    className={classes.selectField}
                    optionsQuery={gql`
                        query ($searchString: String) {
                            cxLevels(filters: [{ name: "String", values: ["DisplayName", $searchString] }]) {
                                Id
                                _DisplayName: DisplayName
                            }
                        }
                    `}
                    optionLabelProperty="_DisplayName"
                    disableClearable
                    onChange={(event, value) => {
                        setFieldValue('Features', []);
                    }}
                />
                <Field
                    type="text"
                    label="Features"
                    name="Features"
                    component={AutoComplete}
                    className={classes.longTextField}
                    options={getFeatures(get('Level._DisplayName', values), featureGroups, levelFeatures)}
                    multiple
                    optionLabelProperty="_DisplayName"
                    optionIdProperty="route"
                    disableClearable
                    initialize={false}
                    allOptions={false}
                    groupBy={(option) => option.group}
                    onChange={(event, value) => {}}
                    badges={false}
                />
                <Field type="text" label="Email" name="Login" component={TextField} className={classes.textField} />
                <Field
                    type="text"
                    label="Divisions"
                    name="Divisions"
                    component={LookupValueEditor}
                    className={classes.selectField}
                    objectType="Division"
                    multiple
                    disableClearable
                    initialize={false}
                />
                {/*<Field*/}
                {/*    type="text"*/}
                {/*    label="Party Group"*/}
                {/*    name="PartyGroups"*/}
                {/*    component={AutoComplete}*/}
                {/*    className={classes.selectField}*/}
                {/*    optionsQuery={gql`*/}
                {/*        query ($searchString: String) {*/}
                {/*            cxPartyGroups(*/}
                {/*                objectType: "PartyGroup"*/}
                {/*                filters: [{ name: "String", values: ["DisplayName", $searchString] }]*/}
                {/*            ) {*/}
                {/*                Id*/}
                {/*                _DisplayName: DisplayName*/}
                {/*            }*/}
                {/*        }*/}
                {/*    `}*/}
                {/*    optionLabelProperty="_DisplayName"*/}
                {/*    disableClearable*/}
                {/*/>*/}
            </div>
            <div>
                <Field
                    type="text"
                    label="Description"
                    name="Description"
                    component={TextField}
                    className={classes.textBoxField}
                    multiline
                    variant="outlined"
                    margin="normal"
                />
            </div>
            {values.Id !== '0' && values.PasswordStatus !== 'ExternalProvider' && (
                <Tooltip title="Resets the user pw to 'incontrol'">
                    <Button onClick={() => resetUserPW(client, values)} color="primary">
                        Reset User password
                    </Button>
                </Tooltip>
            )}
        </form>
    );
};

export const EditCxUser = ({ open, formQueryValues, onClose }) => {
    let initialValues = cloneDeep(model);
    initialValues.Party = {
        Id: '0',
        _DisplayName: ''
    };
    initialValues.Level = {
        Id: '0',
        _DisplayName: ''
    };
    initialValues.Features = [];
    initialValues.Login = '';
    initialValues.Active = true;
    return (
        <DialogForm
            open={open}
            title="User"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={formQueryValues}
            objectName="CxUser"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxUser.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
