/**
 * Created by mark on 11/25/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Field, ResourceSubtypeEditor } from 'client-shared/components/form';
import { TextField } from 'formik-mui';
import { FormQuery, CxJobsQuery } from './queries.js';
import model from 'cb-schema/emptymodel/CxTaskDay';
import inputModel from 'cb-schema/inputmodelfull/CxTaskDay';
import { DateEditor, TimeEditor, currentLifespanTimeValidationSchema } from 'client-shared/components/form';
import { object, number } from 'yup';
import { DialogForm } from 'client-shared/components/editform';
import cloneDeep from 'lodash/cloneDeep';
import { parseDate, get24HourEndDate } from 'client-shared/utility';
import { addDays, format } from 'date-fns/fp';
import { Section } from 'client-shared/components/Section';
import { flow, get } from 'lodash/fp';
import TextFieldMUI from '@mui/material/TextField';
import { gql } from 'graphql-tag';

const validationSchema = object({
    Job: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    JobActivity: object({
        Id: number().moreThan(0, 'Required')
    })
        .nullable()
        .required('Required'),
    // ActionStatus: object({
    //     Id: number().moreThan(0, 'Required')
    // }),
    Supplier: object({
        Id: number().moreThan(0, 'Required')
    }),
    ResourceSubtype: object({
        Id: number().moreThan(0, 'Required')
    }),
    CurrentLifespan: currentLifespanTimeValidationSchema,
    Amount: number().min(0.0001, 'Must be greater than 0').max(999999999, 'Must be less than 999,999,999')
});

const Form = (props) => {
    const { classes, values, setFieldValue } = props;
    return (
        <React.Fragment>
            <form noValidate autoComplete="off">
                <div>
                    <Field
                        type="text"
                        label="Job"
                        name="Job"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="_DisplayName"
                        optionsQuery={CxJobsQuery}
                        queryVariables={() => ({
                            start: format("yyyy-MM-dd'T'00:00", new Date()),
                            end: flow(addDays(90), format("yyyy-MM-dd'T'00:00:00"))(new Date())
                        })}
                        onChange={(event, value) => {
                            if (value) {
                                setFieldValue('JobActivity', get('_JobActivities.0', value));
                            }
                        }}
                    />
                    <Field
                        type="text"
                        label="Job Activity"
                        name="JobActivity"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionLabelProperty="_DisplayName"
                        optionsQuery={gql`
                            query ($searchString: String, $id: String) {
                                cxJobActivitys(
                                    objectType: "JobActivity"
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "Job.Id", values: [$id] }
                                    ]
                                ) {
                                    Id
                                    _DisplayName: DisplayName
                                }
                            }
                        `}
                        queryVariables={(values) => ({
                            id: get('Job.Id', values) || '-1'
                        })}
                        disableClearable
                    />
                    {/*<Field*/}
                    {/*    type="text"*/}
                    {/*    label="Status"*/}
                    {/*    name="ActionStatus"*/}
                    {/*    component={AutoComplete}*/}
                    {/*    className={classes.selectField}*/}
                    {/*    optionsQuery={gql`*/}
                    {/*        query ($searchString: String) {*/}
                    {/*            cxActionStatuss(filters: [{ name: "String", values: ["DisplayName", $searchString] }]) {*/}
                    {/*                Id*/}
                    {/*                _DisplayName: DisplayName*/}
                    {/*            }*/}
                    {/*        }*/}
                    {/*    `}*/}
                    {/*    optionLabelProperty="_DisplayName"*/}
                    {/*    disableClearable*/}
                    {/*/>*/}
                </div>
                <div>
                    <Field
                        type="text"
                        label="Supplier"
                        name="Supplier"
                        component={AutoComplete}
                        className={classes.selectField}
                        optionsQuery={gql`
                            query ($searchString: String) {
                                cxCompanys(
                                    objectType: "Party"
                                    filters: [
                                        { name: "String", values: ["DisplayName", $searchString] }
                                        { name: "PartyType", values: ["Plant", "Subcontractor", "Company"] }
                                    ]
                                ) {
                                    Id
                                    _DisplayName: DisplayName
                                }
                            }
                        `}
                        optionLabelProperty="_DisplayName"
                    />
                    <Field
                        type="text"
                        label="Subtype"
                        name="ResourceSubtype"
                        component={ResourceSubtypeEditor}
                        className={classes.selectField}
                        family={[]}
                        onResourceTypeChange={(value) => {
                            setFieldValue('_ResourceType.Unit', get('Unit', value));
                        }}
                    />
                </div>
                <Field type="number" label="Amount" name="Amount" component={TextField} className={classes.textField} />
                <TextFieldMUI
                    label="Unit"
                    value={get('_ResourceType.Unit.Name', values)}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: true
                    }}
                />
                <div>
                    <Section title="Lifespan">
                        <Field
                            type="date"
                            label="Date"
                            name="CurrentLifespan.Start"
                            component={DateEditor}
                            className={classes.dateTimeField}
                            onChange={(event, newDateTime) => {
                                const endDateTime = get24HourEndDate(newDateTime, values.CurrentLifespan.End);
                                setFieldValue('CurrentLifespan.End', format("yyyy-MM-dd'T'HH:mm", endDateTime));
                            }}
                        />
                        <Field
                            type="time"
                            label="Start"
                            name="CurrentLifespan.Start"
                            component={TimeEditor}
                            className={classes.dateTimeField}
                            onChange={(event, newDateTime) => {
                                const endDateTime = get24HourEndDate(newDateTime, values.CurrentLifespan.End);
                                setFieldValue('CurrentLifespan.End', format("yyyy-MM-dd'T'HH:mm", endDateTime));
                            }}
                        />
                        <Field
                            type="time"
                            label="End"
                            name="CurrentLifespan.End"
                            component={TimeEditor}
                            className={classes.dateTimeField}
                            onChange={(event, newDateTime) => {
                                const endDateTime = get24HourEndDate(values.CurrentLifespan.Start, newDateTime);
                                setFieldValue('CurrentLifespan.End', format("yyyy-MM-dd'T'HH:mm", endDateTime));
                            }}
                        />
                    </Section>
                </div>
                <div>
                    <Field
                        type="text"
                        label="Description"
                        name="Description"
                        component={TextField}
                        className={classes.textBoxField}
                        multiline
                        variant="outlined"
                        margin="normal"
                    />
                </div>
            </form>
        </React.Fragment>
    );
};

export const EditCxVirtualTaskDay = ({ open, formQueryValues, onClose }) => {
    let initialValues = cloneDeep(model);
    delete initialValues.ResourceType;
    delete initialValues.Principals;
    delete initialValues.Cost;
    initialValues.Job = { Id: '0', _DisplayName: '' };
    initialValues.JobActivity = { Id: '0', _DisplayName: '' };
    initialValues.CurrentLifespan.Start = format("yyyy-MM-dd'T'08:00:00", new Date());
    initialValues.CurrentLifespan.End = format("yyyy-MM-dd'T'17:00:00", new Date());
    // initialValues.ActionStatus = { Id: '0', _DisplayName: '' };
    initialValues.Supplier = { Id: '0', _DisplayName: '' };
    initialValues._ResourceType = {
        Unit: {
            Name: ''
        }
    };
    initialValues.ResourceSubtype = {
        Id: '0',
        _Name: ''
    };
    initialValues.Amount = 1;

    return (
        <DialogForm
            open={open}
            title="Generic Task"
            Form={Form}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formQuery={FormQuery}
            formQueryValues={{
                ...formQueryValues,
                start: format("yyyy-MM-dd'T'00:00:00", new Date()),
                end: flow(parseDate, addDays(1), format("yyyy-MM-dd'T'00:00:00"))(new Date())
            }}
            objectName="CxTaskDay"
            inputModel={inputModel}
            onClose={onClose}
        />
    );
};

EditCxVirtualTaskDay.propTypes = {
    open: PropTypes.bool.isRequired,
    formQueryValues: PropTypes.object,
    onClose: PropTypes.func.isRequired
};
