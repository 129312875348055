import React, { useContext } from 'react';
// import { get, map, pipe, curry } from 'lodash/fp';
import { Field as FieldFormik } from 'formik';
// import { Select } from 'formik-mui';
// import { MenuItem, FormControl, InputLabel } from '@mui/material';
import { UserContext } from 'client-shared/utility/context';

// export const filterSelect = curry((label, fieldName, ctx) => (
//     <FormControl>
//         <InputLabel shrink={true}>{label}</InputLabel>
//         <FieldFormik type="text" name={`__filters.${fieldName}`} component={Select}>
//             <MenuItem value={'All'}>All</MenuItem>
//             {pipe(
//                 get(fieldName),
//                 map((each) => <MenuItem value={each.Id}>{each.Name}</MenuItem>)
//             )(ctx)}
//         </FieldFormik>
//     </FormControl>
// ));

export const Field = (props) => {
    const [, , privilege] = useContext(UserContext);
    return <FieldFormik disabled={privilege} {...props} />;
};
