import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { get, join, filter } from 'lodash/fp';
import Search from '@mui/icons-material/Search';
import { DebounceInput } from 'react-debounce-input';
import Tooltip from '@mui/material/Tooltip';

/**
 * match item properties to matchString.
 * @param properties - paths to object properties
 * @param matchString - comma seperated list of strings to match
 * @param item - object containing the properties and values to match
 * @returns {boolean}
 */
const match = (properties = [], matchString, item) => {
    if (!properties.length) {
        return true;
    }
    for (const property of properties) {
        let value;
        if (property.charAt(0) === '@') {
            const properties = property.slice(1).split('.');
            value = get(properties[0], item)
                .map((v) => get(properties[1], v))
                .join(' ');
        } else {
            value = get(property, item);
        }
        if (typeof value !== 'string') {
            continue;
        }
        // if (get(property, item).toLowerCase().includes(matchString.toLowerCase())) {
        //     return true;
        // }
        let matchExpression = matchString.replace(/\*/g, '\\*');
        matchExpression = matchExpression.replace(/,\s?/g, '|');
        const re = new RegExp(matchExpression, 'i');
        if (re.test(value)) {
            return true;
        }
    }
    return false;
};

const getFilter = (matchString, filterProperties) => {
    const properties = join(',', filterProperties);
    if (matchString === '') {
        return undefined;
    }
    return {
        unchanged: {
            name: 'string',
            values: [properties, matchString]
        }
    };
};

const MatchInput = (props) => {
    return (
        <TextField
            className="no-print"
            autoCapitalize="off"
            placeholder={'Type here to search'}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="end">
                        <Tooltip title="Enter text to search">
                            <Search />
                        </Tooltip>
                    </InputAdornment>
                )
            }}
            {...props}
        />
    );
};

/**
 * filter data locally or by string matching on the dbserver.
 * @param values
 * @param filterProperties
 * @param data
 * @param filterOnServer
 * @param setQuickFilter
 * @param children
 * @returns {JSX.Element|*}
 * @constructor
 */
export const FilterSearch = ({
    values,
    filterProperties = [],
    data,
    filterOnServer = true,
    setQuickFilter,
    children
}) => {
    const [matchString, setMatchString] = useState('');

    const handleChange = (event) => {
        if (values._showAll || !filterOnServer) {
            setMatchString(event.target.value);
            return;
        }
        setQuickFilter(getFilter(event.target.value, filterProperties));
    };

    if (!filterProperties.length) {
        return children(data.data);
    }

    const filteredData =
        filterOnServer && !values._showAll
            ? data.data
            : filter((item) => match(filterProperties, matchString, item), data.data);
    return (
        <React.Fragment>
            {filterProperties && (
                <DebounceInput
                    className="no-print"
                    minLength={2}
                    debounceTimeout={300}
                    onChange={handleChange}
                    element={MatchInput}
                />
            )}
            {children(filteredData)}
        </React.Fragment>
    );
};

FilterSearch.propTypes = {
    filterProperties: PropTypes.array,
    data: PropTypes.object.isRequired,
    filterOnServer: PropTypes.bool,
    setQuickFilter: PropTypes.func.isRequired
};
