import React, { useContext } from 'react';
import { FilterFormFromJson } from 'client-shared/components/filterbar';
import { getFilter } from 'client-shared/utility';
import { UserContext } from 'client-shared/utility';

export const Filter = ({ children }) => {
    const [user] = useContext(UserContext);
    const filterFormDefinition = getFilter(user, 'customerInfo.ux.pages', 'crew-timeline.js');

    return <FilterFormFromJson filterFormDefinition={filterFormDefinition}>{children}</FilterFormFromJson>;
};
